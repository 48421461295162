import React from "react";
import ListView from "./partials/ListView";
import Filters from "./partials/Filters/";
import PropTypes from "prop-types";
import Breadcrumbs from "@layout/Breadcrumbs";
export default class ListConferences extends React.Component {
  constructor(props) {
    super(props);
    this.loading = this.props.loading;
    this.searchRef = null;

    this.state = {
      showSearch: false,
      view: "list",
    };
  }

  componentDidMount() {
    this.props.loadEvents({
      rpp: 20,
      p: 1,
      search: "",
      status: "",
      archived: false,
      orderBy: "startDate",
    });
  }

  render() {
    const { events, meta, fetching, criteria } = this.props;
    return (
      <div className="list-events-main">
        <Filters
          person={this.props.person}
          roleMode={this.props.roleMode}
          search={this.props.search}
          loadEvents={this.props.loadEvents}
          personId={this.props.personId}
          showModal={this.props.showModal}
          hideModal={this.props.hideModal}
          user={this.props.user}
          data={{ events, meta, fetching, criteria }}
        />
        <>
          {this.state.view === "list" && (
            <div className={`${this.state.view}-view`}>
              {this.props.roleMode && (
                <Breadcrumbs
                  options={[
                    {
                      name: "Staff",
                      link: "/administrator/staff",
                    },
                    {
                      name: `${this.props.person?.firstName} ${this.props.person?.lastName}`,
                    },
                    {
                      name: "Events",
                    },
                  ]}
                />
              )}
              <ListView
                filtered={this.props.filtered}
                fetching={this.props.fetching}
                data={{ events, meta, fetching, criteria }}
                toggleIsActive={this.props.toggleIsActive}
                deleteEvent={this.props.deleteItem}
                loadEvents={this.props.loadEvents}
                hideModal={this.props.hideModal}
                page={this.props.page}
                roleMode={this.props.roleMode}
                roleTypes={this.props.roleTypes}
                changeRole={this.props.changeRole}
                user={this.props.user}
                loadPolicies={this.props.loadPolicies}
                showNormalModal={this.props.showNormalModal}
                duplicateEvent={this.props.duplicateEvent}
                addNotification={this.props.addNotification}
                updateEvent={this.props.updateEvent}
              />
            </div>
          )}
        </>
      </div>
    );
  }
}

ListConferences.propTypes = {
  loading: PropTypes.bool,
  loadEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  personFetching: PropTypes.bool.isRequired,
  criteria: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
  sortEvents: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  toggleIsActive: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  roleMode: PropTypes.bool,
  roleTypes: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  personId: PropTypes.string,
  changeRole: PropTypes.func,
  person: PropTypes.object,
  filtered: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  loadPolicies: PropTypes.func.isRequired,
  showNormalModal: PropTypes.func,
  duplicateEvent: PropTypes.func,
  addNotification: PropTypes.func,
  updateEvent: PropTypes.func,
  page: PropTypes.object,
};

ListConferences.defaultProps = {
  organisations: [],
  roleMode: false,
  roleTypes: false,
  personId: null,
  person: {},
  changeRole: () => {},
  filtered: false,
  personFetching: false,
  user: {},
  loadPolicies: () => {},
};
