import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderMenu from "@layout/HeaderMenu";
import { fullScreen, showModal, hideModal, changePage } from "@actions";
import PropTypes from "prop-types";
import User from "@components/user/";
import UserNotifications from "@components/usernotifications/";
import { useLocation } from "react-router-dom";
import Logo from "../../icons/Logo";
import { useNavigate } from "react-router-dom";
const VHeader = (props) => {
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];
  const isOnAdminPanel = route === "administrator";
  const isOnScanScreen = route === "events";
  return (
    <div className="header-wrapper">
      <div className="logo">
        {isOnAdminPanel ? (
          <Link to="/administrator/events">
            <Logo />
          </Link>
        ) : isOnScanScreen ? (
          <Logo />
        ) : (
          <Link to="/judge/abstracts">
            <Logo />
          </Link>
        )}
      </div>
      <div className="center menu">
        <HeaderMenu
          smallScreen={props.smallScreen}
          location={props.page.location}
          user={props.appuser}
          showModal={props.showModal}
          hideModal={props.hideModal}
          editModal={props.editModal}
          changePage={props.changePage}
          navigate={useNavigate()}
          selected={isOnAdminPanel ? pathname?.split("/")[2] : null}
          page={props.page}
        />
      </div>
      {isOnScanScreen && (
        <div className="scanning-actions">
          <button
            style={{ color: "white" }}
            className="btn transparent"
            onClick={props.toggleFullScreen}
          >
            Full Screen
          </button>
        </div>
      )}
      {!isOnScanScreen && (
        <div className="right-side">
          <User smallScreen={props.smallScreen} />
          <UserNotifications />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFullScreen: () => {
      dispatch(fullScreen());
    },
    showModal: (content) => {
      dispatch(showModal(content));
    },
    changePage: (options) => {
      dispatch(changePage(options));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};

const Header = connect(mapStateToProps, mapDispatchToProps)(VHeader);

VHeader.propTypes = {
  pageType: PropTypes.string,
  page: PropTypes.object,
  toggleFullScreen: PropTypes.func.isRequired,
  user: PropTypes.object,
  changePage: PropTypes.func,
  showModal: PropTypes.func,
  editModal: PropTypes.func,
  hideModal: PropTypes.func,
  appuser: PropTypes.object.isRequired,
  smallScreen: PropTypes.bool,
};

VHeader.defaultProps = {
  showModal: () => {},
  editModal: () => {},
  hideModal: () => {},
};

export default Header;
