import { combineReducers } from "redux";
import { page } from "@reducers/page";
import api from "@reducers/api";
import editmenu from "@reducers/editmenu";
import notifications from "@reducers/notifications";
import actionBar from "@reducers/actionBar";
import users from "@reducers/users";
import rooms from "@reducers/rooms";
import appuser from "@reducers/appuser";
import { updateFetchingState } from "../helpers";

// the following is global state that tracks the apps fetch state
const _ = (state = { fetchingQueue: [] }, action) => {
  return Object.assign({}, state, {
    fetchingQueue: updateFetchingState(state, action),
  });
};

const rootReducer = combineReducers({
  _,
  api,
  page,
  notifications,
  actionBar,
  editmenu,
  users,
  rooms,
  appuser,
});

export default rootReducer;
