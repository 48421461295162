import React from "react";
import PropTypes from "prop-types";
export default function InfoMessage(props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        marginTop: -100,
      }}
    >
      <div
        style={{
          width: 73,
          height: 73,
          borderRadius: "100%",
          background: "#D0D5DD",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 28,
          color: "#fff",
        }}
      >
        {props.type == "info" && (
          <span className="icon-notification-info-1"></span>
        )}
        {props.type == "warning" && (
          <span className="icon-notification-1"></span>
        )}
        {props.type == "error" && (
          <span className="icon-notification-error-1"></span>
        )}
      </div>
      {props.title && (
        <p
          style={{
            marginTop: 20,
            marginBottom: 0,
            fontSize: 16,
            fontWeight: 500,
            color: "#0F0E11",
          }}
        >
          {props.title}
        </p>
      )}
      {props.subtitle && (
        <p
          style={{
            marginTop: 4,
            fontSize: 14,
            fontWeight: 400,
            color: "#928F98",
          }}
        >
          {props.subtitle}
        </p>
      )}
    </div>
  );
}
InfoMessage.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
