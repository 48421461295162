import React from "react";
import PropTypes from "prop-types";
import { fontOptions } from "./dragNDropConfig";
import Barcode from "react-barcode";

const DragAndDrop = (props) => {
  // GET THE STYLE FOR DND
  const getStyles = (left, top, isDragging) => {
    const transform = `translate3d(${left}px, ${top}px, 0)`;
    return {
      position: "absolute",
      transform,
      WebkitTransform: transform,
      opacity: isDragging ? 0 : 1,
      height: isDragging ? 0 : "",
    };
  };

  const fontSize = (boxKey) => {
    switch (boxKey) {
      case "name": {
        return props.formValue.fontSizeName;
      }
      case "id": {
        return props.formValue.fontSizeId;
      }
      case "cert_text": {
        return props.formValue.fontSizeCertText;
      }
      case "selectedField_1": {
        return props.formValue.fieldFontSize_1;
      }
      case "selectedField_2": {
        return props.formValue.fieldFontSize_2;
      }
    }
    return "";
  };

  const getBoxContent = (boxKey) => {
    switch (boxKey) {
      case "name": {
        if (props.formValue.nameOrder === "first") {
          if (props.formValue.sameLine) {
            return <div>FirstName LastName</div>;
          } else {
            return (
              <div
                className="name-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>FirstName</div>
                <div>LastName</div>
              </div>
            );
          }
        } else if (props.formValue.nameOrder === "last") {
          if (props.formValue.sameLine) {
            return <div>LastName FirstName</div>;
          } else {
            return (
              <div
                className="name-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>LastName</div>
                <div>FirstName</div>
              </div>
            );
          }
        }
        return "";
      }
      case "id": {
        return "00000";
      }
      case "barcode": {
        return (
          <div className="user-barcode">
            <Barcode
              displayValue={true}
              width={3 * props.formValue.scale}
              height={
                parseInt(props.formValue.barcodeHeight ?? 10) *
                props.formValue.scale
              }
              value={"12345"}
            />
          </div>
        );
      }
      case "cert_text": {
        return props.formValue.certText?.length > 0
          ? props.formValue.certText
          : props.boxes[boxKey].title;
      }
      case "selectedField_1": {
        return `${props.boxes[boxKey].title}${
          props.formValue.selectedField_1?.length > 0
            ? ` - ${props.parsePolicyFields()[props.formValue.selectedField_1]}`
            : ""
        }`;
      }
      case "selectedField_2": {
        return `${props.boxes[boxKey].title}${
          props.formValue.selectedField_2?.length > 0
            ? ` - ${props.parsePolicyFields()[props.formValue.selectedField_2]}`
            : ""
        }`;
      }
    }
    return null;
  };

  const showBox = (boxKey) => {
    switch (boxKey) {
      case "name": {
        return props.formValue.showName;
      }
      case "id": {
        return props.formValue.showId;
      }
      case "barcode": {
        return props.formValue.showBarcode;
      }
      case "cert_text": {
        return props.formValue.showCertText;
      }
      case "selectedField_1": {
        return props.formValue.showExtraField_1;
      }
      case "selectedField_2": {
        return props.formValue.showExtraField_2;
      }
    }
    return false;
  };

  return (
    <div className="image-wrapper">
      <div className="image-container">
        <div
          ref={props.drop}
          style={{
            height: props.imageHeight,
            width: props.imageWidth,
          }}
          className="drag-wrapper"
        >
          {Object.keys(props.boxes).map(
            (key) =>
              showBox(key) && (
                <div
                  className={`${props.centerBox(key) ? "center-drag" : ""}`}
                  key={`drag-${key}`}
                  ref={props.boxes[key].drag}
                  style={{
                    ...getStyles(
                      props.boxes[key].left,
                      props.boxes[key].top,
                      props.boxes[key].isDragging
                    ),
                    width: (() => {
                      // case centered key not cert
                      if (props.centerBox(key) && key !== "cert_text") {
                        return "100%";
                      }
                      // case not centered key not cert
                      if (!props.centerBox(key) && key !== "cert_text") {
                        return "max-content";
                      }
                      // case centered key cert
                      if (props.centerBox(key) && key === "cert_text") {
                        return `${props.formValue.certWidth}%`;
                      }
                      // case not centered key cert
                      if (!props.centerBox(key) && key === "cert_text") {
                        return `${props.formValue.certWidth}%`;
                      }
                    })(),
                    display:
                      !props.centerBox(key) && key === "cert_text"
                        ? "flex"
                        : "",
                    alignItems:
                      !props.centerBox(key) && key === "cert_text"
                        ? "center"
                        : "",
                    justifyContent:
                      !props.centerBox(key) && key === "cert_text"
                        ? "flex-start"
                        : "center",
                  }}
                  role="DraggableBox"
                >
                  <div
                    style={{
                      cursor: "move",
                      color: "black",
                    }}
                    role={props.boxes[key].preview ? "BoxPreview" : "Box"}
                  >
                    <div
                      style={{
                        border: "1px dashed gray",
                        backgroundColor: "#ffffff85",
                        color: props.formValue.fontColor,
                        fontSize: `${fontSize(key) * props.formValue.scale}px`,
                        fontFamily: `${
                          fontOptions[props.formValue.fontFamily].font
                        }, ${
                          fontOptions[props.formValue.fontFamily].subFamily
                        }`,
                        width:
                          props.centerBox(key) && key === "cert_text"
                            ? `${props.formValue.certWidth}%`
                            : "",
                        overflowWrap: "anywhere",
                        textAlign:
                          !props.centerBox(key) && key === "cert_text"
                            ? "left"
                            : "center",
                      }}
                      className="inner-drag-text"
                    >
                      {getBoxContent(key)}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        {props.formValue.fileUpload || props.imgSource ? (
          props.formValue.hasOwnProperty("policyGroup") ? (
            <img
              style={{
                height: props.imageHeight,
                width: props.imageWidth,
                minHeight: props.imageHeight,
                minWidth: props.imageWidth,
                objectFit: props.formValue.scaleToFit ? "fill" : "contain",
              }}
              src={props.imgSource ?? props.formValue.fileUpload}
            />
          ) : (
            <div className="badge-image-wrapper">
              <img
                style={{
                  height: props.formValue.scaleToFit ? props.imageHeight : "",
                  width: props.imageWidth,
                  minHeight: props.formValue.scaleToFit
                    ? props.imageHeight
                    : "",
                  minWidth: props.formValue.scaleToFit ? props.imageWidth : "",
                  objectFit: props.formValue.scaleToFit ? "fill" : "contain",
                }}
                src={props.imgSource ?? props.formValue.fileUpload}
              />
            </div>
          )
        ) : (
          <div
            style={{
              height: props.imageHeight,
              width: props.imageWidth,
            }}
          />
        )}
      </div>
    </div>
  );
};

DragAndDrop.propTypes = {
  boxes: PropTypes.object.isRequired,
  formValue: PropTypes.object.isRequired,
  drop: PropTypes.func.isRequired,
  centerBox: PropTypes.func.isRequired,
  parsePolicyFields: PropTypes.func,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  imgSource: PropTypes.string || PropTypes.object,
};

export default DragAndDrop;
