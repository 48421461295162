import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Colors from "./partials/Colors";
import Logo from "./partials/Logo";

const Branding = () => {
  const tabs = [
    { key: "colors", label: "Colors" },
    { key: "logo", label: "Logo" },
    // { key: "assets", label: "Assets" },
  ];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <React.Fragment>
      <div className="top-menu">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </div>
      {tabs[activeTab].key === "colors" && <Colors></Colors>}
      {tabs[activeTab].key === "logo" && <Logo></Logo>}
    </React.Fragment>
  );
};

export default Branding;
