import { PROMPT_HAS_CHANGES } from "@actions";

export const CHANGE_SECTION = "CHANGE_SECTION";
export const CHANGE_TAB = "CHANGE_TAB";
export const ADJUST_SECTION = "ADJUST_SECTION";
export const RESET_MENU = "RESET_MENU";
export const RESTORE_SECTIONS = "RESTORE_SECTIONS";

const sectionsURLMap = {
  basic_info: "basic-setup",
  registration: "participants",
  schedule: "schedule",
  accommodation: "accommodation",
  abstracts: "abstracts",
  products: "products",
  client_builder: "client-panel",
  communication: "communication",
  statistics: "statistics",
  payments: "payments",
  exhibition: "exhibition-hall",
  settings: "other-settings",
};

export const restoreSections = (event) => (dispatch) => {
  dispatch({
    type: RESTORE_SECTIONS,
    response: { data: event },
  });
};

export const resetMenu = () => {
  return {
    type: RESET_MENU,
  };
};

export const changeSection = (section) => {
  return (dispatch, getState) => {
    const state = getState();
    let tab = null;

    const newSection = state.editmenu.sections[section];
    if (Object.keys(newSection.items).length > 0) {
      tab = Object.keys(newSection.items)[0];
    }

    state.page.navigate(
      `/administrator/events/${state.page.params.eventId}/${sectionsURLMap[section]}`
    );

    if (state.page.hasChanges) {
      dispatch({
        type: PROMPT_HAS_CHANGES,
        changeType: "section",
        section,
        tab,
        fakeChild: null,
        page: null,
      });
    } else {
      dispatch({
        type: CHANGE_SECTION,
        section,
        tab,
        fakeChild: null,
      });
    }
  };
};

export const adjustSection = (section, action) => {
  return {
    type: ADJUST_SECTION,
    section,
    action,
  };
};

export const changeTab = (tab, fakeChild = null) => {
  return (dispatch, getState) => {
    const state = getState();

    if (state.page.hasChanges) {
      return new Promise((resolve) => {
        dispatch({
          type: PROMPT_HAS_CHANGES,
          changeType: "tab",
          section: null,
          tab,
          fakeChild: null,
          page: null,
        });
        resolve(true);
      });
    } else {
      return new Promise((resolve) => {
        dispatch({
          type: CHANGE_TAB,
          tab,
          fakeChild,
        });
        resolve(true);
      });
    }
  };
};
