import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";
import { updateEvent } from "@actions";
import { ChromePicker } from "react-color";
import { setPrimaryColor } from "@helpers/color-palette-helper";
import SvgReset from "@app_icons/Reset";

const Colors = (props) => {
  const defaultColor = "#3790FE";

  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [color, setColor] = React.useState(
    props.event.clientPanelSettings.general.colors.primary
  );
  const [previewColors, setPreviewColors] = React.useState(
    setPrimaryColor(color)
  );

  const setColorForPreview = (color) => {
    setColor(color);
    setPreviewColors(setPrimaryColor(color));
  };

  return (
    <div className="panel-content-card branding-content">
      <div className="event-color-picker-wrapper">
        <div className="color-picker-title">Client color</div>
        <div className="color-picker-container">
          <div
            className="color-selector"
            onClick={() => setColorPickerOpen(true)}
          >
            <div className="color-indicator" style={{ backgroundColor: color }}>
              {colorPickerOpen && (
                <React.Fragment>
                  <div
                    className="backdrop"
                    onClick={(e) => {
                      setColorPickerOpen(false);
                      e.stopPropagation();
                    }}
                  ></div>
                  <ChromePicker
                    color={color}
                    onChange={(color) => {
                      setColorForPreview(color.hex);
                    }}
                  />
                </React.Fragment>
              )}
            </div>
            {`Primary color: ${color}`}
          </div>
          <div
            className="reset-color-button"
            onClick={() => {
              setColorForPreview(defaultColor);
            }}
          >
            <SvgReset />
            Reset to default colors
          </div>
        </div>
      </div>
      <div className="preview-colors">
        <div className="preview-title">Primary shades preview</div>
        <div className="preview-shades">
          {previewColors.map((previewColor, i) => {
            return (
              <div
                key={`preview-shade-${i} shade-${previewColor}`}
                className="preview-indicator"
                style={{ backgroundColor: previewColor }}
              />
            );
          })}
        </div>
      </div>
      <SubmitContainer
        fetching={props.fetching}
        onSubmit={() => {
          props.updateEvent(props.event.id, {
            generalColorPrimary: color,
          });
        }}
        onCancel={() => {
          setColorForPreview(
            props.event.clientPanelSettings.general.colors.primary
          );
        }}
        disabled={
          props.fetching ||
          color === props.event.clientPanelSettings.general.colors.primary
        }
        cancelDisabled={
          color === props.event.clientPanelSettings.general.colors.primary
        }
        submitText="Save Changes"
      />
    </div>
  );
};

Colors.propTypes = {
  updateEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    policies: state.api.accesspolicies?.list?.data,
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEvent: (eventId, event) => dispatch(updateEvent(eventId, event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Colors);
