import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Select2 from "@layout/Select2";
import { DraggableArea } from "react-draggable-tags";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

const { v4: uuidv4 } = require("uuid");

const TagsInput = (props) => {
  const [contentEditable, setContentEditable] = useState(false);
  const [editDropdownValues, setEditDropdownValues] = useState(null);
  const [tagValue, setTagValue] = React.useState("");

  // Memoized tags collection based on props
  const tagsCollection = useMemo(() => {
    return (
      props.tags?.reduce(
        (prev, { id, key, value: content }) => [
          ...prev,
          {
            id: uuidv4(),
            key: key || uuidv4(),
            dropdownValueID: id,
            content,
            undraggable: contentEditable,
          },
        ],
        []
      ) || []
    );
  }, [props.tags, contentEditable]);

  useEffect(() => {
    setEditDropdownValues(tagToValue(tagsCollection));
  }, [tagsCollection]);
  // Convert tags to values
  const tagToValue = (tags) => {
    return tags.map((tag) => ({
      id: tag.dropdownValueID,
      key: tag.key,
      value: tag.content,
    }));
  };

  // Remove a tag
  const removeTag = (id) => {
    const newValues = tagToValue(tagsCollection);
    const newTags = newValues.filter((tag) => tag.id !== id);
    props.onChange(newTags);
  };

  // Edit a tag
  const editTag = (id, newValue) => {
    const newValues = JSON.parse(JSON.stringify(editDropdownValues));
    const tagToEdit = newValues.find((tag) => tag.id === id);
    tagToEdit.value = newValue;

    setEditDropdownValues(newValues);
  };

  // Apply edit changes
  const applyEdit = () => {
    if (!editDropdownValues) return;
    const allNewValues = editDropdownValues.map((tag) => tag.value);
    const uniqueValues = [
      ...new Set(allNewValues.filter((v) => v !== "All Others")), // all others is a reserved word
    ];

    if (allNewValues.length !== uniqueValues.length) return;
    props.onChange(editDropdownValues);
  };

  // Add a new tag
  const addTag = () => {
    const newTagValue = tagValue.trim();
    if (!newTagValue.length) return;
    const newValues = tagToValue(tagsCollection);

    setTagValue("");

    if (
      newValues.some((tag) => tag.content === newTagValue) ||
      newValues.some((tag) => tag.value === newTagValue) ||
      newTagValue === "All Others" //this is a reserved word
    ) {
      return;
    }
    const key = uuidv4();

    props.onChange([...newValues, { id: key, key, value: newTagValue }]);
  };

  // Toggle contentEditable state
  const toggleContentEditable = (e) => {
    e.preventDefault();
    if (contentEditable) applyEdit();
    setContentEditable(!contentEditable);
  };
  // Prepare CRM options
  const crmOptions =
    props.crmTags &&
    props.crmTags
      .filter((tag) => !props?.tags?.map((v) => v.key).includes(tag.key))
      .reduce((prev, next) => ({ ...prev, [next.key]: next.value }), {});
  return (
    <div className="tags-input">
      <div className="main">
        <DraggableArea
          tags={tagsCollection}
          render={({ tag }) => {
            return (
              <div className="tag">
                {props.crmTags &&
                  props.crmTags.find((v) => v.key === tag.key)?.value !==
                    tag.content && (
                    <Tooltip
                      title={`The CRM value of this option is ${
                        props.crmTags.find((v) => v.key === tag.key)?.value
                      }`}
                    >
                      <InfoIcon style={{ cursor: "pointer" }} />
                    </Tooltip>
                  )}
                {contentEditable ? (
                  <input
                    defaultValue={tag.content}
                    onChange={(e) =>
                      editTag(tag.dropdownValueID, e.target.value)
                    }
                  />
                ) : (
                  <span>{tag.content}</span>
                )}

                <span
                  className="icon-close"
                  onClick={() => removeTag(tag.dropdownValueID)}
                ></span>
              </div>
            );
          }}
          onChange={(tags) => {
            const newValue = tagToValue(tags);
            props.onChange([...newValue]);
          }}
        />
      </div>
      <div className="inputs">
        {!props.crmTags ? (
          <div
            style={{
              display: "flex",
              borderTop: "1px solid #f0f0f4",
              paddingTop: 5,
              gap: 5,
              height: 40,
            }}
          >
            <input
              placeholder="Type an option..."
              value={tagValue}
              style={{ borderRadius: 4 }}
              onChange={(e) => setTagValue(e.target.value)}
              onKeyUp={(e) => {
                e.preventDefault();
                if (e.key === "Enter" || e.key === "Tab") {
                  addTag();
                }
              }}
            />
            <button
              style={{ width: 100, borderRadius: 4 }}
              onClick={(e) => {
                e.preventDefault();
                addTag();
              }}
            >
              + Add
            </button>
            <button
              style={{ width: 100, borderRadius: 4 }}
              onClick={toggleContentEditable}
            >
              {contentEditable
                ? editDropdownValues == null
                  ? "Cancel"
                  : "Apply"
                : "Edit"}
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              borderTop: "1px solid #f0f0f4",
              paddingTop: 5,
              gap: 5,
              height: 40,
            }}
          >
            <Select2
              menuPlacement={"top"}
              options={crmOptions}
              onChange={(val) => {
                const newValue = tagToValue(tagsCollection);
                props.onChange([
                  ...newValue,
                  { id: val, key: val, value: crmOptions[val] },
                ]);
              }}
              placeholder={"Select from available options..."}
              value={""}
              className="select2-tags-container"
              multi={false}
              isSearchable={true}
              isClearable={false}
            />
            <button
              style={{ width: "67px", borderRadius: 4 }}
              onClick={toggleContentEditable}
            >
              {contentEditable
                ? editDropdownValues == null
                  ? "Cancel"
                  : "Apply"
                : "Edit"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Define propTypes for the component
TagsInput.propTypes = {
  tags: PropTypes.array,
  crmTags: PropTypes.array,
  onChange: PropTypes.func,
};

// Export the component
export default TagsInput;
