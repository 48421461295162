import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import InnerPreset from "./partials/innerPreset";
import TableActions from "@layout/TableActions";
import Form from "@components/form";
import { Button } from "@layout/Buttons";
import { createContactLabel } from "../../../../../../helpers";
import moment from "moment-timezone";
import Tooltip from "@material-ui/core/Tooltip";
import { scheduledEmails } from "../../../../../../helpers/scheduled-email-helper";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  tooltip: {
    maxWidth: 550,
  },
};

const CustomTooltip = withStyles(styles)(Tooltip);

export default class Presets extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.changeState = this.changeState.bind(this);

    this.columns = {
      id: {
        name: "ID",
      },
      name: {
        name: "Email Name",
      },
      scheduledToSend: {
        name: "Scheduled to send",
      },
      scheduledSent: {
        name: "Last scheduled sent",
      },
      // lastSent: {
      //   name: "Last sent",
      // },
      // totalSent: {
      //   name: "Total sent emails",
      // },
      recipients: {
        name: "Recipients",
      },
      actions: {
        name: "",
      },
    };
    this.state = {
      emailId: false,
      mode: null,
    };
  }

  changeState() {
    this.setState({ mode: null });
    this.props.loadPresetEmails(this.props.orgId, this.props.eventId);
  }
  componentDidMount() {
    this.props.loadPresetEmails(this.props.orgId, this.props.eventId);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fakeChild !== "inner") {
      this.setState({ emailId: false, mode: null });
    }
  }
  returnPastSchedulIndication(schedulingDataArray, innerTable) {
    let hasDateInPast = false;

    if (innerTable) {
      schedulingDataArray = [schedulingDataArray];
    }

    schedulingDataArray.forEach((scheduling_data) => {
      if (scheduling_data.lastScheduledSent) return;
      if (scheduledEmails.isDateInThePast(scheduling_data, this.props.event)) {
        hasDateInPast = true;
      }
    });

    return hasDateInPast ? (
      <span className="past-date-warning icon-notification-1 table-positioner" />
    ) : (
      ""
    );
  }

  onRender(col, item) {
    switch (col.key) {
      case "id": {
        return <div className="id-column">{col.value}</div>;
      }
      case "name": {
        return (
          <div
            onClick={() =>
              this.setState({ emailId: item.id, mode: "edit" }, () =>
                this.props.changeTab("Presets", "inner")
              )
            }
            className="email-label-column"
          >
            {col.value}
          </div>
        );
      }
      case "lastSent": {
        return (
          <div className="id-column">
            {item.lastSentAt
              ? moment
                  .utc(item.lastSentAt, "YYYY-MM-DD HH:mm:ss")
                  .tz(this.props.event.timezone)
                  .format("Do MMMM  YY - HH:mm")
              : "N/A"}
          </div>
        );
      }
      case "scheduledToSend": {
        let unsentOriginalSchedulings = item.scheduling?.filter(
          (scheduling) => {
            if (scheduling.lastScheduledSent === null) {
              return true;
            } else {
              return false;
            }
          }
        );

        if (unsentOriginalSchedulings) {
          unsentOriginalSchedulings = unsentOriginalSchedulings.sort((a, b) =>
            scheduledEmails
              .parseDate(a, this.props.event)
              .diff(scheduledEmails.parseDate(b, this.props.event), "minutes")
          );

          const date = scheduledEmails.getSoonestDate(
            unsentOriginalSchedulings,
            this.props.event
          );

          let outputDate;
          if (date === "N/A") {
            return date;
          } else {
            outputDate = date.format("Do MMMM  YY - HH:mm");
          }

          return (
            <React.Fragment>
              <div className="view-all-scheduled">
                <CustomTooltip
                  title={
                    <React.Fragment>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="table-interactive scheduled-list"
                      >
                        <div className="list-view-header">
                          This email is scheduled to be sent on the following
                          dates:
                        </div>
                        {unsentOriginalSchedulings.map((scheduling) => {
                          if (scheduling.lastScheduledSent) return;
                          return (
                            <div key={`future-view-${scheduling.id}`}>
                              {`- ${scheduledEmails.computeScheduledEstimate(
                                scheduledEmails.parseDate(
                                  scheduling,
                                  this.props.event
                                ),
                                this.props.event
                              )}`}
                              {this.returnPastSchedulIndication(
                                scheduling,
                                "inner-table"
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  }
                >
                  <span>
                    {`${outputDate} `}
                    {this.returnPastSchedulIndication(
                      unsentOriginalSchedulings
                    )}
                  </span>
                </CustomTooltip>
              </div>
            </React.Fragment>
          );
        }
        return "N/A";
      }
      case "scheduledSent": {
        let sentOriginalSchedulings = item.scheduling?.filter((scheduling) => {
          if (scheduling.lastScheduledSent !== null) {
            return true;
          } else {
            return false;
          }
        });

        if (sentOriginalSchedulings) {
          sentOriginalSchedulings = sentOriginalSchedulings.sort((a, b) =>
            moment(b.lastScheduledSent, "YYYY-MM-DD HH:mm:ss").diff(
              moment(a.lastScheduledSent, "YYYY-MM-DD HH:mm:ss"),
              "seconds"
            )
          );

          if (sentOriginalSchedulings.length === 0) {
            return "N/A";
          }

          const timezonedDate = moment.utc(
            sentOriginalSchedulings[0].lastScheduledSent,
            "YYYY-MM-DD HH:mm:ss"
          );

          return (
            <React.Fragment>
              <div className="view-all-scheduled">
                <CustomTooltip
                  title={
                    <React.Fragment>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="table-interactive scheduled-list"
                      >
                        <div className="list-view-header">
                          This email has been sent by scheduling on the
                          following dates:
                        </div>
                        {sentOriginalSchedulings.map((scheduling) => {
                          if (!scheduling.lastScheduledSent) return;
                          return (
                            <div key={`future-view-${scheduling.id}`}>
                              {`- ${moment
                                .utc(
                                  scheduling.lastScheduledSent,
                                  "YYYY-MM-DD HH:mm"
                                )
                                .tz(this.props.event.timezone)
                                .format("Do MMMM  YY - HH:mm:ss")}`}
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  }
                >
                  <span>
                    {timezonedDate
                      .tz(this.props.event.timezone)
                      .format("YYYY-MM-DD HH:mm")}
                  </span>
                </CustomTooltip>
              </div>
            </React.Fragment>
          );
        }
        return "N/A";
      }
      case "recipients": {
        return (
          <div className="recipients-column">
            {item.contacts.slice(0, 2).map((child, index) => {
              return (
                <div key={`contact-item-${index}`} className="contact-item">
                  <Button disableRipple style={{ height: "24px" }}>
                    {createContactLabel(child, {
                      groups: this.props.policyGroups,
                    })}
                  </Button>
                </div>
              );
            })}
            {item.contacts.slice(2).length > 0 && (
              <div key={`contact-item-${2}`} className="contact-item">
                <Button disableRipple style={{ height: "24px" }}>
                  {`& ${item.contacts.slice(2).length} more...`}
                </Button>
              </div>
            )}
          </div>
        );
      }
      case "actions": {
        return (
          <TableActions
            entity="email"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <div className="form-container">
                  <h2> Edit {item.name} name </h2>
                  <Form
                    componentName="email"
                    formName="edit"
                    onSubmit={(data) => {
                      this.props.updateEmail(
                        this.props.orgId,
                        item.id,
                        data.value,
                        this.props.eventId
                      );
                    }}
                    value={{ name: item.name }}
                    onCancel={() => {
                      this.props.hideModal();
                    }}
                  />
                </div>
              )
            }
            onDelete={() => {
              this.props
                .deleteEmail(this.props.orgId, item.id, this.props.eventId)
                .then(() => {
                  this.props.loadPresetEmails(
                    this.props.orgId,
                    this.props.eventId
                  );
                });
            }}
          />
        );
      }
    }
  }

  render() {
    if (this.state.mode == "edit") {
      const email = this.props.presetList.filter(
        (preset) => preset.id == this.state.emailId
      )[0];
      return (
        <InnerPreset
          email={email}
          mode={this.state.mode}
          showName={true}
          {...this.props}
        />
      );
    } else if (this.state.mode == "create") {
      const email = {
        eventId: this.props.eventId,
        contacts: [],
        name: "Untitled",
        template: {
          html: "",
          name: "Untitled",
        },
      };
      return (
        <InnerPreset
          email={email}
          mode={this.state.mode}
          showName={true}
          {...this.props}
        />
      );
    }

    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <div className="right">
              <button
                type="button"
                className="btn create rounded"
                onClick={() => {
                  this.props.changeTab("Presets", "inner");
                  this.setState({ mode: "create" });
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <Table
            updating={this.props.fetching}
            columns={this.columns}
            onRender={this.onRender}
            items={this.props.presetList}
          />
        </div>
      </>
    );
  }
}
Presets.propTypes = {
  event: PropTypes.object.isRequired,
  loadPresetEmails: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  accessEvents: PropTypes.object.isRequired,
  presetList: PropTypes.any.isRequired,
  deleteEmail: PropTypes.func,
  switchPublicStatus: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  updateEmail: PropTypes.func,
  globalVars: PropTypes.any,
  createNewPresetEmail: PropTypes.func,
  dispatcher: PropTypes.any,
  sendEmail: PropTypes.func,
  changeTab: PropTypes.func,
  fakeChild: PropTypes.string,
  policyGroups: PropTypes.array,
  fetching: PropTypes.bool,
};
