/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import { insert, remove } from "@helpers";
import SingleRoom from "./SingleRoom/SingleRoom";
import Tooltip from "@material-ui/core/Tooltip";
import Confirm from "@layout/Confirm";
import moment from "moment";
import Switch from "@layout/Switch";
import Breadcrumbs from "@layout/Breadcrumbs";
export default class Rooms extends React.Component {
  constructor(props) {
    super(props);
    ["onRender", "toggleRoom", "onRenderColSpan", "addNewRoom"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );

    this.columns = {
      cosmetic: {
        name: "",
      },
      id: {
        name: "ID",
      },
      name: {
        name: "Room Name",
      },
      capacity: {
        name: "Capacity",
      },
      totalRoomReservations: {
        name: "Room Reservations",
      },
      totalNightsReservations: {
        name: "Night Reservations",
      },
      startDate: {
        name: "from",
      },
      endDate: {
        name: "to",
      },
      sellingPrice: {
        name: "Price (sell)",
      },
      active: {
        name: "AVAILABLE ONLINE",
      },
      actions: {
        name: "",
      },
    };

    this.state = {
      rooms: [],
      toggledCells: [],
      newRoom: false,
      selectedMode: "",
      selectedExisting: false,
      updating: false,
    };
  }

  addNewRoom(type) {
    this.setState({
      selectedMode: type,
    });
    if (type === "new") {
      this.setState({
        newRoom: true,
      });
    }
  }

  toggleRoom(room, trIndex) {
    if (room === false) {
      this.setState({ newRoom: false });
      return;
    }
    if (!trIndex) {
      this.state.rooms.map((x, i) => {
        if (x.id === room.id) {
          trIndex = i;
        }
      });
    }

    const toggledCells = [...this.state.toggledCells];
    const index = toggledCells.indexOf(room.id);
    let rooms = this.state.rooms;
    if (index !== -1) {
      toggledCells.splice(index, 1);
      rooms = remove(rooms, trIndex + 1);
    } else {
      toggledCells.push(room.id);
      rooms = insert(this.state.rooms, trIndex + 1, {
        colSpan: Object.keys(this.columns).length,
        room,
      });
    }

    this.setState({ toggledCells, rooms });
  }

  reToggle() {
    if (this.state.toggledCells.length === 0) return;

    let rooms = this.state.rooms;
    let internalCount = 0;

    this.state.rooms.map((room, trIndex) => {
      if (this.state.toggledCells.includes(room.id)) {
        rooms = insert(rooms, trIndex + internalCount + 1, {
          colSpan: Object.keys(this.columns).length,
          room,
        });
        internalCount++;
      }
    });

    this.setState({ rooms });
  }

  customActions(item, toggled) {
    if (this.props.mode === "orgHotels") {
      return null;
    }

    const unassignBody = (
      <div>
        <p>
          This action will unassign the <strong>{item.name}</strong> only for
          this event.
        </p>
        <p>
          You can reassign it anytime you want, but the related event data will
          be lost.
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    );

    return (
      <span className="confirm-holder">
        <Confirm
          onConfirm={() => {
            return this.props.unassignRoom(
              this.props.hotel.id,
              item.id,
              this.props.hotel.orgHotelId
            );
          }}
          body={unassignBody}
          className="delete-event"
          confirmText="Confirm Unassign"
          title="Unassign Action! Are you sure?"
          onShow={() => document.body.click()}
        >
          <Tooltip title="Unassign from event">
            <button type="button" className="btn circle">
              <span className="icon-all-out"></span>
            </button>
          </Tooltip>
        </Confirm>
      </span>
    );
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            <span className="icon-double-room"></span>
          </div>
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="hotel room"
            item={item}
            delete={false}
            onEdit={() =>
              this.props.showModal(<SingleRoom item={item} {...this.props} />)
            }
            onDelete={false}
            custom={(toggled) => this.customActions(item, toggled)}
          />
        );
      }

      case "startDate": {
        return moment(col.value.tz).format("DD-MM-YYYY");
      }

      case "endDate": {
        return moment(col.value.tz).format("DD-MM-YYYY");
      }

      case "active": {
        const {
          reorderHotelRoom,
          hotelId,
          getOrgRooms,
          getEventRooms,
          mode,
          hotel,
        } = this.props;
        return (
          <Switch
            id={`room-active-${item.id}`}
            isActive={col.value}
            isLive={true}
            onChange={(active) =>
              reorderHotelRoom({ active }, hotelId, item.id).then(() =>
                mode === "eventHotels"
                  ? getEventRooms(hotelId).then(() =>
                      this.props.addNotification(
                        "successfully updated room",
                        "success"
                      )
                    )
                  : getOrgRooms(mode, hotel.orgHotelId).then(() =>
                      this.props.addNotification(
                        "successfully updated room",
                        "success"
                      )
                    )
              )
            }
          />
        );
      }
    }
  }

  onRenderColSpan(item, trIndex) {
    return (
      <td key={`sessions-${trIndex}-colspan`} colSpan={item.colSpan}>
        <SingleRoom
          roomId={item.room.id}
          hotelId={this.props.hotel.id}
          onCancel={this.toggleRoom}
          trIndex={trIndex}
          mode={this.props.mode}
        />
      </td>
    );
  }

  componentDidMount() {
    const { hotelId, getOrgRooms, getEventRooms, mode, hotel } = this.props;
    getEventRooms(hotelId);
    getOrgRooms(mode, hotel.orgHotelId);
    this.setState({ rooms: this.props.rooms });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const stateRooms = [],
      nextPropsRooms = [];
    for (const room of this.state.rooms) {
      if (room.hasOwnProperty("id")) {
        stateRooms.push(room.id, room.active);
      }
    }
    for (const room of nextProps.rooms) {
      if (room.hasOwnProperty("id")) {
        nextPropsRooms.push(room.id, room.active);
      }
    }
    if (JSON.stringify(stateRooms) !== JSON.stringify(nextPropsRooms)) {
      this.setState(
        { rooms: nextProps.rooms, toggledCells: [], newRoom: false },
        () => this.reToggle()
      );
    }
    if (this.props.mode === "eventHotels") {
      this.setState(
        { rooms: nextProps.rooms, toggledCells: [], newRoom: false },
        () => this.reToggle()
      );
    }
  }

  render() {
    const { hotel, reorderHotelRoom } = this.props;
    const { rooms } = this.state;
    return (
      <>
        <div className="rooms-header tab-header">
          <div className="left"></div>
          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(<SingleRoom item={{}} {...this.props} />)
              }
            >
              Create
            </button>
          </div>
        </div>
        <div className="room-types form-container tab-content">
          <Breadcrumbs
            options={[
              { name: "Hotels", action: this.props.resetSelectedHotelId },
              {
                name: this.props.hotels.filter(
                  (hotel) => hotel.id == this.props.hotelId
                )[0].name,
              },
              { name: "Rooms" },
            ]}
          />

          <Table
            id={"hotel-rooms"}
            columns={this.columns}
            items={rooms}
            onRender={this.onRender}
            onRenderColSpan={this.onRenderColSpan}
            updating={this.props.fetching}
            onSortEnd={(index, item) => {
              reorderHotelRoom({ index }, hotel.id, item.id).then(() => {
                const { hotelId, getEventRooms } = this.props;
                getEventRooms(hotelId);
              });
            }}
          />
        </div>
      </>
    );
  }
}

Rooms.propTypes = {
  hotel: PropTypes.object.isRequired,
  unassignRoom: PropTypes.func.isRequired,
  getOrgRooms: PropTypes.func.isRequired,
  hotelId: PropTypes.number.isRequired,
  rooms: PropTypes.array.isRequired,
  deleteRoom: PropTypes.func.isRequired,
  reorderHotelRoom: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  getEventRooms: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  addNotification: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  resetSelectedHotelId: PropTypes.number,
  hotels: PropTypes.array,
};
