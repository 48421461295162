import { connect } from "react-redux";
import UndoMassiveEvents from "./UndoMassiveEvents";

const mapStateToProps = (state) => {
  return {
    massiveEvents: state.rooms.massiveEvents,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoMassiveEvents);
