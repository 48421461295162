import React, { useEffect, useRef } from "react";
import {} from "@helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideModal, savePrintingSettings, loadPolicies } from "@actions";
import { listGroups } from "@actions/policyGroups";
import KMBLoader from "@components/layout/KMBLoader";
import { useState } from "react";
import KMBDropzone from "@components/layout/KMBDropzone";
import Select from "@components/layout/Select2";
import NumberInput from "@components/layout/NumberInput";
import Switch from "@components/layout/Switch";
import SubmitContainer from "@components/layout/SubmitContainer";
import ColorPicker from "material-ui-color-picker";
import {
  prepDragAndDrop,
  paperSizes,
  fontOptions,
  findFont,
} from "./DragAndDrop/dragNDropConfig.js";
import { enableNativePrinting, disableNativePrinting } from "@actions";
import DragAndDrop from "./DragAndDrop/DragAndDrop";
import StayCurrentLandscapeIcon from "@material-ui/icons/StayCurrentLandscape";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import DymoPreview from "./DymoPreview/DymoPreview.js";
import { getTestBadge, printCard } from "@actions/users";
import JsBarcode from "jsbarcode";

const CardPrinting = (props) => {
  // LISTENER SETUP
  window.addEventListener(
    "afterprint",
    () => {
      onPrintFinished();
    },
    { once: true }
  );
  window.scriptCaller = () => {
    JsBarcode(".barcode").init();
    let loading = 0;
    const images = document.querySelectorAll(".whole-page-wrapper img");
    if (images.length === 0) {
      window.print();
    }
    images.forEach((element) => {
      if (element.complete) {
        loading++;
        if (images.length === loading) {
          loading = 0;
          window.print();
        }
      } else {
        element.onload = () => {
          loading++;
          if (images.length === loading) {
            loading = 0;
            window.print();
          }
        };
      }
    });
  };

  // CONFIGURATION
  const paperScreenBase = 350;
  const constantFactor_Y = useRef(0);
  const constantFactor_X = useRef(0);
  const { cardPrintingSettings: incomingState } = props.params.printingSettings;

  // FUNCTION THAT INITIALIZES THE PROCESSED BOXES STATE
  const initializeProcessedBoxes = (processedState) => {
    const _ret = {
      name: {
        top: getTopValue("nameStyle", 182),
        left: getLeftValue("nameStyle", 0, processedState.centerName),
        title: "Participant Name",
      },
      barcode: {
        top: getTopValue("barcodeStyle", 110),
        left: getLeftValue("barcodeStyle", 0, processedState.centerBarcode),
      },
      selectedField_1: {
        top: getTopValue("fieldConfig_1", 145),
        left: getLeftValue("fieldConfig_1", 0, processedState.centerField_1),
        title: "Extra Field #1 Value",
      },
      selectedField_2: {
        top: getTopValue("fieldConfig_2", 190),
        left: getLeftValue("fieldConfig_2", 0, processedState.centerField_2),
        title: "Extra Field #2 Value",
      },
    };

    return _ret;
  };

  // FUNCTION THAT INITIALIZES THE PROCESSED STATE
  const initializeProcessedState = () => {
    const ret = {
      showBarcode: incomingState.showBarcode ?? true,
      scaleToFit: incomingState.scaleToFit ?? false,
      sameLine: incomingState.sameLine ?? true,
      showName: incomingState.showName ?? true,
      nameOrder: incomingState.nameOrder ?? "first",
      barcodeHeight: parseInt(incomingState.barcodeHeight ?? 48),
      centerBarcode: incomingState.centerBarcode ?? true,
      centerName: incomingState.centerName ?? true,
      selectedPaperSize: incomingState.paperSize ?? "A4",
      orientation: incomingState.orientation ?? "landscape",
      useBanner: incomingState.useBanner ?? true,
      foldedPaper: incomingState.foldedPaper ?? true,
      fullWidth: incomingState.fullWidth ?? true,
      bannerUrl: incomingState.bannerUrl ?? "",
      gapSize: parseFloat(incomingState.gapSize ?? 0.5),
      fontFamily: findFont(incomingState.fontFamily),
      fontColor: incomingState.fontColor ?? "#000000",
      scale: incomingState.scale ?? 1,
      fontSizeName: parseInt(incomingState.nameStyle?.fontSize ?? 16),
      fileUpload:
        props.params.cardPrintingBanners?.length > 0
          ? props.params.cardPrintingBanners[0].url
          : null,
      showExtraField_1: incomingState.fieldConfig_1?.showField ?? false,
      showExtraField_2: incomingState.fieldConfig_2?.showField ?? false,
      extraFieldType_1: incomingState.fieldConfig_1?.fieldType ?? "",
      extraFieldType_2: incomingState.fieldConfig_2?.fieldType ?? "",
      centerField_1: incomingState.fieldConfig_1?.centerField ?? true,
      centerField_2: incomingState.fieldConfig_2?.centerField ?? true,
      selectedField_1: incomingState.fieldConfig_1?.selectedField ?? "",
      selectedField_2: incomingState.fieldConfig_2?.selectedField ?? "",
      fieldFontSize_1: parseInt(incomingState.fieldConfig_1?.fontSize ?? 16),
      fieldFontSize_2: parseInt(incomingState.fieldConfig_2?.fontSize ?? 16),
    };
    return ret;
  };

  // get the aspect ratio of papersize
  const getAspectRatio = () => {
    let r = 1.4142; // this is a fallback to the A-paper sizes aspect ratio
    paperSizes.forEach((paper) => {
      if (paper.id === formValue.selectedPaperSize) {
        r = paper.y / paper.x;
      }
    });
    return r;
  };

  // PREVENT BOXES FROM BE PLACED OUTSIDE THE LIMITS
  const calibrateOffset = (offset, offsetFromLeft) => {
    const max = offsetFromLeft
      ? constantFactor_X.current
      : constantFactor_Y.current;
    return offset < 0 || offset >= max ? 0 : offset;
  };

  //RETURN THE TOP VALUE FOR ANY DND OBJECT
  const getTopValue = (property, def) => {
    const intValue = parseInt(incomingState[property]?.top);
    if (isNaN(intValue)) return calibrateOffset(def);
    return calibrateOffset((intValue * constantFactor_Y.current) / 100);
  };

  //RETURN THE LEFT VALUE FOR ANY DND OBJECT
  const getLeftValue = (property, def, centered) => {
    if (centered) return 0;
    const intValue = parseInt(incomingState[property]?.left);
    if (isNaN(intValue)) return calibrateOffset(def, true);
    return calibrateOffset((intValue * constantFactor_X.current) / 100, true);
  };

  // CREATE THE FONT OPTION FOR THE SELECT COMPONENT
  const parseFontOptions = () => {
    const parsedFonts = {};
    Object.keys(fontOptions).forEach((key) => {
      parsedFonts[
        key
      ] = `${fontOptions[key].font} (${fontOptions[key].subFamily})`;
    });
    return parsedFonts;
  };

  //   CREATE THE PAPER OPTION FOR THE SELECT COMPONENT
  const parsePaperOptions = () => {
    const options = {};
    paperSizes.forEach((paperSize) => {
      if (
        // todo: temporarily remove these options
        // paperSize.id !== "A8" &&
        // paperSize.id !== "A7" &&
        paperSize.id !== "A6" &&
        paperSize.id !== "A5"
      ) {
        return;
      }
      options[paperSize.id] = paperSize.id;
    });
    return options;
  };

  // STATE MANAGEMENT
  const [certification, setCertification] = React.useState({
    settings: JSON.parse(props.cardPrintingSettings),
    printing: false,
    user: null,
    userSettings: null,
  });
  const [loading, setLoading] = React.useState(true);
  const [printerPreview, setPrinterPreview] = React.useState("regular");
  const [policyFields, setPolicyFields] = React.useState({});
  const [formValue, setFormValue] = React.useState(initializeProcessedState());
  const [boxes, setBoxes] = useState(initializeProcessedBoxes(formValue));
  const colorRef = React.useRef(formValue.fontColor);
  const [imgSource, setImgSource] = React.useState(null);
  const [imageHeight, setImageHeight] = React.useState();
  const [imageWidth, setImageWidth] = React.useState();

  useEffect(() => {
    if (formValue.orientation === "landscape") {
      // the 350 will be the width
      constantFactor_X.current = paperScreenBase * getAspectRatio();
      constantFactor_Y.current = paperScreenBase;
    } else {
      // 350 will be the height
      constantFactor_X.current = paperScreenBase;
      constantFactor_Y.current = paperScreenBase * getAspectRatio();
    }
    setImageHeight(constantFactor_Y.current);
    setImageWidth(constantFactor_X.current);
  }, [formValue]);

  useEffect(() => {
    if (incomingState)
      setBoxes(initializeProcessedBoxes(initializeProcessedState()));
  }, [incomingState]);

  React.useEffect(() => {
    let totalFields = {};
    props.loadPolicies(props.params.eventId).then((policiesDTO) => {
      policiesDTO.data.forEach((policy) => {
        totalFields = {
          ...totalFields,
          ...policy.settings,
          ...policy.extraSettings,
        };
      });
      setPolicyFields(totalFields);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    onChange("selectedPaperSize", formValue.selectedPaperSize);
  }, [formValue.scale]);

  const viewingDymo = () => printerPreview === "dymo";

  const onChange = (key, value) => {
    const newFormValue = { ...formValue };
    newFormValue[key] = value;

    if (key === "fontColor") {
      colorRef.current = value;
    }

    if (key.includes("selectedField_")) {
      Object.keys(policyFields).forEach((field) => {
        if (field === value) {
          newFormValue[`extraFieldType_${key.includes("1") ? 1 : 2}`] =
            policyFields[field].type;
        }
      });
    }

    if (key === "selectedPaperSize") {
      let newScale = 1;
      paperSizes.forEach((paperSize) => {
        if (paperSize.id === value) newScale = paperSize.scale;
      });
      newFormValue.scale = newScale;
    }

    if (key.includes("center")) {
      let parsedBoxKey = "";
      switch (key) {
        case "centerName": {
          parsedBoxKey = "name";
          break;
        }
        case "centerId": {
          parsedBoxKey = "id";
          break;
        }
        case "centerBarcode": {
          parsedBoxKey = "barcode";
          break;
        }
        case "centerField_1": {
          parsedBoxKey = "selectedField_1";
          break;
        }
        case "centerField_2": {
          parsedBoxKey = "selectedField_2";
          break;
        }
      }

      moveBox(
        parsedBoxKey,
        boxes[parsedBoxKey].left,
        boxes[parsedBoxKey].top,
        value
      );
    }
    setFormValue(newFormValue);
  };

  const centerBox = (boxKey) => {
    switch (boxKey) {
      case "name": {
        return formValue.centerName;
      }
      case "id": {
        return formValue.centerId;
      }
      case "barcode": {
        return formValue.centerBarcode;
      }
      case "selectedField_1": {
        return formValue.centerField_1;
      }
      case "selectedField_2": {
        return formValue.centerField_2;
      }
    }
    return "";
  };

  //   PREPARE THE FRONT SCHEMA FOR SUBMISSION AND CONVERT IT ACCORDINGLY
  const parseSendingForm = () => {
    return {
      nameStyle: {
        left: `${(100 * boxes["name"].left) / imageWidth}%`,
        top: `${100 * (boxes["name"].top / imageHeight)}%`,
        fontSize: `${formValue.fontSizeName}px`,
      },
      barcodeStyle: {
        left: `${(100 * boxes["barcode"].left) / imageWidth}%`,
        top: `${100 * (boxes["barcode"].top / imageHeight)}%`,
      },
      fieldConfig_1: {
        left: `${(100 * boxes["selectedField_1"].left) / imageWidth}%`,
        top: `${100 * (boxes["selectedField_1"].top / imageHeight)}%`,
        fontSize: `${formValue.fieldFontSize_1}px`,
        centerField: formValue.centerField_1,
        selectedField: formValue.selectedField_1,
        showField: formValue.showExtraField_1,
        fieldType: formValue.extraFieldType_1,
      },
      fieldConfig_2: {
        left: `${(100 * boxes["selectedField_2"].left) / imageWidth}%`,
        top: `${100 * (boxes["selectedField_2"].top / imageHeight)}%`,
        fontSize: `${formValue.fieldFontSize_2}px`,
        centerField: formValue.centerField_2,
        selectedField: formValue.selectedField_2,
        showField: formValue.showExtraField_2,
        fieldType: formValue.extraFieldType_2,
      },
      scaleToFit: formValue.scaleToFit ?? false,
      showBarcode: formValue.showBarcode ?? true,
      sameLine: formValue.sameLine ?? true,
      showName: formValue.showName ?? true,
      nameOrder: formValue.nameOrder ?? "first",
      barcodeHeight: parseInt(formValue.barcodeHeight ?? 48),
      centerBarcode: formValue.centerBarcode ?? true,
      centerName: formValue.centerName ?? true,
      paperSize: formValue.selectedPaperSize ?? "A4",
      orientation: formValue.orientation ?? "landscape",
      useBanner: formValue.useBanner ?? true,
      foldedPaper: formValue.foldedPaper ?? true,
      fullWidth: formValue.fullWidth ?? true,
      bannerUrl: formValue.bannerUrl ?? "",
      gapSize: parseFloat(formValue.gapSize ?? 0.5),
      fontFamily: `${fontOptions[formValue.fontFamily].font}, ${
        fontOptions[formValue.fontFamily].subFamily
      }`,
      fontColor: formValue.fontColor ?? "#000000",
      scale: formValue.scale ?? 1,
      fontSizeName: parseInt(formValue.nameStyle?.fontSize ?? 16),
    };
  };

  const onSave = () => {
    props.savePrintingSettings(
      parseSendingForm(),
      "cardPrintingSettings",
      props.params.eventId,
      (typeof formValue.fileUpload === "string"
        ? null
        : formValue.fileUpload) ?? [],
      undefined,
      "card"
    );
  };

  const onCancel = () => {
    const processedState = initializeProcessedState();
    colorRef.current = processedState.fontColor;
    setFormValue(processedState);
    setBoxes(initializeProcessedBoxes(processedState));
  };

  const parsePolicyFields = () => {
    const parsedFields = {};
    Object.keys(policyFields).forEach((fieldKey) => {
      if (fieldKey === "password") return;
      parsedFields[fieldKey] = policyFields[fieldKey].name;
    });
    return parsedFields;
  };

  const printFunction = () => {
    window.scriptCaller();
  };

  const onPrintFinished = () => {
    setCertification(
      Object.assign({}, certification, {
        printing: false,
        user: null,
        userSettings: null,
      })
    );
    props.disableNativePrinting();
  };

  const printCard = () => {
    if (printerPreview === "regular") {
      // generic native print
      props
        .getTestBadge(props.params.eventId, 0, 0, true, 10, false)
        .then((output) => {
          props.enableNativePrinting("badge", output.data.html, printFunction);
        });
    } else {
      // go dymo style
      const testUser = {
        id: 9999,
        firstName: "John",
        lastName: "Doe",
      };
      props.printCard(testUser);
    }
  };

  // CONFIGURE DRAG AND DROP
  const [moveBox, drop] = prepDragAndDrop(
    boxes,
    setBoxes,
    centerBox,
    formValue
  );

  return loading ? (
    <div className="container">
      <KMBLoader rows={15} padding={24} height={53} />
    </div>
  ) : (
    <div className="form-container cert-printing tab-content">
      <div className="card-editor-wrapper">
        <div className="card-drag-editor">
          <div className="printer-preview">
            <div className="preview-selector">
              <label>Preview printer result</label>
              <Select
                name="printerPreviewSelect"
                id="printerPreviewSelect"
                isClearable={false}
                options={{
                  dymo: "Dymo printer",
                  regular: "Regular printer",
                }}
                value={printerPreview}
                onChange={(value) => setPrinterPreview(value)}
              />
            </div>
            <div className="preview-test">
              <button
                type="submit"
                className="btn save rounded"
                onClick={() => {
                  printCard();
                }}
              >
                {"Test Print"}
              </button>
            </div>
          </div>
          {printerPreview === "regular" && (
            <DragAndDrop
              centerBox={centerBox}
              imageHeight={imageHeight}
              imageWidth={imageWidth}
              parsePolicyFields={parsePolicyFields}
              boxes={boxes}
              formValue={formValue}
              drop={drop}
              imgSource={imgSource}
            />
          )}
          {printerPreview === "dymo" && <DymoPreview formValue={formValue} />}
          <div className="editor-row">
            <div className="form-wrapper">
              <div className="field-type type-select kmb-select ">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    marginBottom: "4px",
                  }}
                >
                  Upload Badge Banner
                </label>
                <KMBDropzone
                  id={"cardPrintingBanners"}
                  styleType="simple"
                  max={1}
                  value={props.params.cardPrintingBanners}
                  onFileDelete={() => {
                    props.savePrintingSettings(
                      {},
                      "cardPrintingSettings",
                      props.params.eventId,
                      "delete",
                      props.params.cardPrintingBanners[0].id,
                      "card"
                    );
                    onChange("fileUpload", null);
                  }}
                  onDropAccepted={(file, deletefile) => {
                    if (deletefile) {
                      setImgSource(null);
                      setFormValue({
                        ...{ formValue },
                        fileUpload:
                          props.params.cardPrintingBanners.length > 0
                            ? props.params.cardPrintingBanners[0].url
                            : null,
                      });
                    } else if (window.FileReader && file) {
                      const fr = new FileReader();
                      fr.onload = () => {
                        onChange("fileUpload", file);
                        setImgSource(fr.result);
                      };
                      fr.readAsDataURL(file[0]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-editor-tools">
          <div className="tools-wrapper ">
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <label style={{ fontSize: "16px", fontWeight: 700 }}>
                    Paper options
                  </label>
                  <div className="divider"></div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Use Banner</label>
                      <div className="switch-holder">
                        <Switch
                          isDisabled={viewingDymo()}
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.useBanner}
                          onChange={(value) => onChange("useBanner", !!value)}
                        />
                      </div>
                    </div>

                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Use folded paper</label>
                      <div className="switch-holder">
                        <Switch
                          isDisabled={viewingDymo()}
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.foldedPaper}
                          onChange={(value) => onChange("foldedPaper", !!value)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        justifyContent: "space-between",
                      }}
                      className={`field-type type-text kmb-text-outter`}
                    >
                      <label>Gap between sides (cm)</label>
                      <NumberInput
                        disabled={!formValue.useBanner || viewingDymo()}
                        min={0}
                        step="0.1"
                        value={formValue.gapSize}
                        onChange={(value) => {
                          onChange("gapSize", value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Scale to fit</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          isDisabled={!formValue.useBanner || viewingDymo()}
                          id={`display-sponsor-types`}
                          isActive={formValue.scaleToFit}
                          onChange={(value) => onChange("scaleToFit", !!value)}
                        />
                      </div>
                    </div>
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Orientation</label>
                      <div className="icons-wrapper">
                        <div className="icon-container">
                          <StayCurrentLandscapeIcon
                            onClick={() => {
                              if (!viewingDymo()) {
                                onChange("orientation", "landscape");
                              }
                            }}
                            className={`${
                              formValue.orientation === "landscape"
                                ? "selected"
                                : ""
                            }
                            ${viewingDymo() ? "disabled" : ""}
                            icon-selector icon-landscape`}
                          />
                        </div>
                        <div className="icon-container">
                          <StayCurrentPortraitIcon
                            onClick={() => {
                              if (!viewingDymo()) {
                                onChange("orientation", "portrait");
                              }
                            }}
                            className={`${
                              formValue.orientation === "portrait"
                                ? "selected"
                                : ""
                            }
                            ${viewingDymo() ? "disabled" : ""}
                            icon-selector icon-landscape`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`field-type type-text`}>
                      <label>Paper Size</label>
                      <Select
                        name="paperSize"
                        id="paperSize"
                        isClearable={false}
                        disabled={viewingDymo()}
                        options={parsePaperOptions()}
                        value={formValue.selectedPaperSize}
                        onChange={(value) =>
                          onChange("selectedPaperSize", value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Show Name</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isDisabled={viewingDymo()}
                          isActive={viewingDymo() ? true : formValue.showName}
                          onChange={(value) => {
                            if (!viewingDymo()) {
                              onChange("showName", !!value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isDisabled={viewingDymo()}
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.centerName}
                          onChange={(value) => onChange("centerName", !!value)}
                        />
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Font size (px)</label>
                      <NumberInput
                        max={72}
                        min={0}
                        value={formValue.fontSizeName}
                        onChange={(value) => {
                          onChange("fontSizeName", value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Single Line</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          isActive={formValue.sameLine}
                          onChange={(value) =>
                            onChange("sameLine", Boolean(value))
                          }
                        />
                      </div>
                    </div>
                    <div className={`field-type`}>
                      <label>Name Order</label>
                      <Select
                        name="nameOrder"
                        id="nameOrder"
                        isClearable={false}
                        options={{
                          first: "FirstName LastName",
                          last: "LastName FirstName",
                        }}
                        value={formValue.nameOrder}
                        onChange={(value) => onChange("nameOrder", value)}
                      />
                    </div>
                    <div>{/* placeholder */}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Show Barcode</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isDisabled={viewingDymo()}
                          isActive={
                            viewingDymo() ? true : formValue.showBarcode
                          }
                          onChange={(value) => {
                            if (!viewingDymo()) {
                              onChange("showBarcode", !!value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          isDisabled={viewingDymo()}
                          id={`display-sponsor-types`}
                          isActive={formValue.centerBarcode}
                          onChange={(value) =>
                            onChange("centerBarcode", !!value)
                          }
                        />
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Height (px)</label>
                      <NumberInput
                        max={200}
                        min={1}
                        value={formValue.barcodeHeight}
                        onChange={(value) => {
                          onChange("barcodeHeight", value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="label-with-switch">
                    <label style={{ fontSize: "16px", fontWeight: 700 }}>
                      Extra Field #1
                    </label>
                    <div
                      className="switch-holder"
                      style={{ marginBottom: "4px" }}
                    >
                      <Switch
                        isDisabled={viewingDymo()}
                        isLive={true}
                        id={`display-sponsor-types`}
                        isActive={formValue.showExtraField_1}
                        onChange={(value) =>
                          onChange("showExtraField_1", !!value)
                        }
                      />
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="merged-row">
                    <div className={`field-type type-text`}>
                      <label>Select Field</label>
                      <Select
                        name="paperSize"
                        id="paperSize"
                        disabled={!formValue.showExtraField_1 || viewingDymo()}
                        isClearable={false}
                        options={parsePolicyFields()}
                        value={formValue.selectedField_1}
                        onChange={(value) => onChange("selectedField_1", value)}
                      />
                    </div>
                  </div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          isDisabled={
                            !formValue.showExtraField_1 || viewingDymo()
                          }
                          id={`display-sponsor-types`}
                          isActive={formValue.centerField_1}
                          onChange={(value) =>
                            onChange("centerField_1", !!value)
                          }
                        />
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Font size (px)</label>
                      <NumberInput
                        max={72}
                        disabled={!formValue.showExtraField_1 || viewingDymo()}
                        min={0}
                        value={formValue.fieldFontSize_1}
                        onChange={(value) => {
                          onChange("fieldFontSize_1", value);
                        }}
                      />
                    </div>
                    <div>{/* placeholder */}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="label-with-switch">
                    <label style={{ fontSize: "16px", fontWeight: 700 }}>
                      Extra Field #2
                    </label>
                    <div
                      className="switch-holder"
                      style={{ marginBottom: "4px" }}
                    >
                      <Switch
                        isLive={true}
                        isDisabled={viewingDymo()}
                        id={`display-sponsor-types`}
                        isActive={formValue.showExtraField_2}
                        onChange={(value) =>
                          onChange("showExtraField_2", !!value)
                        }
                      />
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="merged-row">
                    <div className={`field-type type-text`}>
                      <label>Select Field</label>
                      <Select
                        name="paperSize"
                        id="paperSize"
                        disabled={!formValue.showExtraField_2 || viewingDymo()}
                        isClearable={false}
                        options={parsePolicyFields()}
                        value={formValue.selectedField_2}
                        onChange={(value) => onChange("selectedField_2", value)}
                      />
                    </div>
                  </div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          isDisabled={
                            !formValue.showExtraField_2 || viewingDymo()
                          }
                          id={`display-sponsor-types`}
                          isActive={formValue.centerField_2}
                          onChange={(value) =>
                            onChange("centerField_2", !!value)
                          }
                        />
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Font size (px)</label>
                      <NumberInput
                        max={72}
                        disabled={!formValue.showExtraField_2 || viewingDymo()}
                        min={0}
                        value={formValue.fieldFontSize_2}
                        onChange={(value) => {
                          onChange("fieldFontSize_2", value);
                        }}
                      />
                    </div>
                    <div>{/* placeholder */}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group font-group">
                <div className="form-wrapper">
                  <label style={{ fontSize: "16px", fontWeight: 700 }}>
                    Font Options
                  </label>
                  <div className="divider"></div>
                  <div className="merged-row font-options">
                    <div
                      style={{
                        display: "flex",
                      }}
                      className={`field-type type-text`}
                    >
                      <label>Font Family</label>
                      <Select
                        className="flex-input"
                        disabled={viewingDymo()}
                        isClearable={false}
                        options={parseFontOptions()}
                        value={formValue.fontFamily}
                        onChange={(value) => {
                          onChange("fontFamily", value);
                        }}
                      />
                    </div>
                    <div key={"color"} className="color-picker-container">
                      <label>Font Color</label>
                      <ColorPicker
                        name="color"
                        value={colorRef.current}
                        defaultValue={"This is how the text will look like"}
                        onChange={(color) => {
                          onChange("fontColor", color);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitContainer onCancel={onCancel} onSubmit={onSave} />
    </div>
  );
};

CardPrinting.propTypes = {
  params: PropTypes.object.isRequired,
  savePrintingSettings: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loadPolicies: PropTypes.func,
  policyGroups: PropTypes.array,
  cardPrintingBanners: PropTypes.array,
  getTestBadge: PropTypes.func,
  enableNativePrinting: PropTypes.func,
  disableNativePrinting: PropTypes.func,
  printCard: PropTypes.func,
  cardPrintingSettings: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    policyGroups: state.api.policyGroups.list.data,
    cardPrintingSettings: state.api.events.edit.data.extra.cardPrintingSettings,
    params: {
      printingSettings: {
        cardPrintingSettings: JSON.parse(
          state.api.events.edit.data.extra.cardPrintingSettings
        ),
      },
      eventId: state.api.events.edit.data.id,
      cardPrintingBanners: state.api.events.edit.data.cardPrintingBanners,
    },
  };
};

export default connect(mapStateToProps, {
  savePrintingSettings,
  hideModal,
  listGroups,
  loadPolicies,
  getTestBadge,
  printCard,
  disableNativePrinting,
  enableNativePrinting,
})(CardPrinting);
