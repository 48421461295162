import React from "react";
import PropTypes from "prop-types";
import Address from "@components/form/partials/Address";
import KMBEditor from "@layout/KMBEditor";
import Select2 from "@layout/Select2";
import SubmitContainer from "@layout/SubmitContainer";

const EditComponent = (props) => {
  const [state, setState] = React.useState({
    item: JSON.parse(JSON.stringify(props.item)),
  });

  const getItem = (item) => {
    const allBucketOptionsUrl = props.bucket.exhibitorsBucket.reduce(
      (prev, next) => ({ ...prev, [next.id]: next.url }),
      {}
    );
    const bucketImageOptions = props.bucket.exhibitorsBucket
      .filter((item) => {
        return ["png", "jpg", "jpeg"].includes(item.extension);
      })
      .reduce((prev, next) => ({ ...prev, [next.id]: next.fileName }), {});
    const bucketVideoOptions = props.bucket.exhibitorsBucket
      .filter((item) => {
        return ["mp4", "avi"].includes(item.extension);
      })
      .reduce((prev, next) => ({ ...prev, [next.id]: next.fileName }), {});

    const bucketFileOptions = props.bucket.exhibitorsBucket.reduce(
      (prev, next) => ({ ...prev, [next.id]: next.fileName }),
      {}
    );

    switch (item.type) {
      case "image":
        return (
          <>
            <div className="form-group">
              <label>Image URL</label>
              <input
                className="form-control"
                disabled={state.item.content.id}
                value={state.item.content.url}
                onChange={(e) => {
                  const newState = { ...state };
                  state.item.content.url = e.target.value;
                  setState(newState);
                }}
                placeholder={"Type an image url..."}
              />
            </div>
            <div
              className="form-group"
              style={{ position: "relative", zIndex: 2 }}
            >
              <label>Image URL | Select an Image from the bucket</label>
              <Select2
                disabled={!state.item.content.id && state.item.content.url}
                options={bucketImageOptions}
                onChange={(val) => {
                  const newState = { ...state };
                  newState.item.content.id = val;
                  newState.item.content.url = allBucketOptionsUrl[val];
                  setState(newState);
                }}
                placeholder={"Select an image"}
                value={state.item.content.id}
                multi={false}
              />
            </div>
            <div className="form-group">
              <label>Action URL</label>
              <input
                className="form-control"
                value={state.item.content.actionUrl}
                disabled={state.item.content.actionId}
                onChange={(e) => {
                  const newState = { ...state };
                  state.item.content.actionUrl = e.target.value;
                  setState(newState);
                }}
                placeholder={"Type an image url..."}
              />
            </div>
            <div
              className="form-group"
              style={{ position: "relative", zIndex: 1 }}
            >
              <label>Action URL | Select a File from the bucket</label>
              <Select2
                disabled={
                  !state.item.content.actionId && state.item.content.actionUrl
                }
                options={bucketFileOptions}
                onChange={(val) => {
                  const newState = { ...state };
                  newState.item.content.actionUrl = allBucketOptionsUrl[val];
                  newState.item.content.actionId = val;
                  setState(newState);
                }}
                placeholder={"Select a file"}
                value={state.item.content.actionId}
                multi={false}
              />
            </div>
          </>
        );
      case "file":
        return (
          <>
            <div className="form-group">
              <label>Displayed Text</label>
              <input
                className="form-control"
                value={state.item.content.text}
                onChange={(e) => {
                  const newState = { ...state };
                  state.item.content.text = e.target.value;
                  setState(newState);
                }}
                placeholder={"Type the call to action text..."}
              />
            </div>
            <div className="form-group">
              <label>File URL</label>
              <input
                className="form-control"
                disabled={state.item.content.id}
                value={state.item.content.url}
                onChange={(e) => {
                  const newState = { ...state };
                  state.item.content.url = e.target.value;
                  setState(newState);
                }}
                placeholder={"Type a file url..."}
              />
            </div>
            <div
              className="form-group"
              style={{ position: "relative", zIndex: 2 }}
            >
              <label>File URL | Select a File from the bucket</label>
              <Select2
                disabled={!state.item.content.id && state.item.content.url}
                options={bucketFileOptions}
                onChange={(val) => {
                  const newState = { ...state };
                  newState.item.content.id = val;
                  newState.item.content.url = allBucketOptionsUrl[val];
                  setState(newState);
                }}
                placeholder={"Select a file"}
                value={state.item.content.id}
                multi={false}
              />
            </div>
          </>
        );
      case "html":
        return (
          <div className="form-group">
            <label>Please type the content of your text (*)</label>
            <div style={{ background: "whitesmoke", minHeight: 200 }}>
              <KMBEditor
                height={200}
                placeholder={"Type..."}
                rows={20}
                value={item.content?.value}
                onChange={(data) => {
                  const newState = { ...state };
                  newState.item.content.value = data;
                  setState(newState);
                }}
              />
            </div>
          </div>
        );
      case "video":
        return (
          <>
            <div className="form-group">
              <label>Video URL</label>
              <input
                className="form-control"
                disabled={state.item.content.id}
                value={state.item.content.url}
                onChange={(e) => {
                  const newState = { ...state };
                  state.item.content.url = e.target.value;
                  setState(newState);
                }}
                placeholder={"Type a video url..."}
              />
            </div>
            <div
              className="form-group"
              style={{ position: "relative", zIndex: 2 }}
            >
              <label>Video URL | Select a video from the bucket</label>
              <Select2
                disabled={!state.item.content.id && state.item.content.url}
                options={bucketVideoOptions}
                onChange={(val) => {
                  const newState = { ...state };
                  newState.item.content.id = val;
                  newState.item.content.url = allBucketOptionsUrl[val];
                  setState(newState);
                }}
                placeholder={"Select a video"}
                value={state.item.content.id}
                multi={false}
              />
            </div>
          </>
        );
      case "map":
        state.item.content.value = state.item.content.value || {
          address: "White Tower",
          hname: "",
          lat: 40.626446,
          lng: 22.948426,
        };
        return (
          <Address
            id={"exhibitor-map-component"}
            name={"exhibitor-map-component"}
            hasErrors={false}
            withMap={true}
            withName={false}
            value={state.item.content.value}
            onChange={({ location }) => {
              const newState = { ...state };
              newState.item.content.value = location;
              setState(newState);
            }}
          />
        );
      case "iframe":
        return (
          <div className="form-group">
            <label>Please type a URL (*)</label>
            <input
              className="form-control"
              value={state.item.content.url}
              onChange={(e) => {
                const newState = { ...state };
                state.item.content.url = e.target.value;
                setState(newState);
              }}
              placeholder={"Type a url..."}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="exhibitor-page-edit-component form-container">
      <h2>Edit exhibitors page component</h2>
      <div className="form-group" style={{ position: "relative", zIndex: 3 }}>
        <label>Please select a component type (*)</label>
        <Select2
          options={{
            map: "Map",
            html: "Text",
            iframe: "Iframe",
            video: "Video",
            image: "Image",
            file: "File",
          }}
          onChange={(val) => {
            const newState = { ...state };
            newState.item.type = val;
            newState.item.content = {};
            setState(newState);
          }}
          placeholder={"Select a type"}
          value={state.item.type}
          multi={false}
        />
      </div>
      {getItem(state.item)}
      <SubmitContainer
        submitText={"Preview"}
        onCancel={props.onCancel}
        onSubmit={() => props.onSave(state.item)}
      />
    </div>
  );
};

EditComponent.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
};
EditComponent.propTypes = {
  item: PropTypes.object.isRequired,
  bucket: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditComponent;
