import { api } from "@actions/ajax";
import { addNotification } from "@actions";
export const REQUEST_EXHIBITIONDIGITALSETTINGS_LISTSLOTS =
  "REQUEST_EXHIBITIONDIGITALSETTINGS_LISTSLOTS";
export const RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTSLOTS =
  "RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTSLOTS";
export const REQUEST_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES =
  "REQUEST_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES";
export const RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES =
  "RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES";
export const REQUEST_EXHIBITORS_LIST = "REQUEST_EXHIBITORS_LIST";
export const RECEIVED_EXHIBITORS_LIST = "RECEIVED_EXHIBITORS_LIST";
import { uploadFile } from ".";

export const loadExhibitionDigitalSlots = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitionDigitalSettings",
      action: "listSlots",
      params: { eventId },
      cache: false,
    };
    return dispatch(api(request));
  };
};

export const updateExhibitionDigitalSlots = (eventId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitionDigitalSettings",
      action: "editSlots",
      params: { eventId },
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then(() => {
        dispatch(loadExhibitionDigitalSlots(eventId));
      })
      .then((data) => {
        dispatch(
          addNotification("Digital Slots updated successfully!", "success")
        );
        return data;
      })
      .catch(console.error);
  };
};
export const updateExhibitionDigitalCategories = (eventId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitionDigitalSettings",
      action: "editCategories",
      params: { eventId },
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then(() => {
        dispatch(loadExhibitionCategories(eventId));
      })
      .then((data) => {
        dispatch(
          addNotification("Digital Categories updated successfully!", "success")
        );
        return data;
      });
  };
};

export const loadExhibitionCategories = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitionDigitalSettings",
      action: "listCategories",
      params: { eventId },
      cache: false,
    };
    return dispatch(api(request));
  };
};

export const loadExhibitors = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitors",
      action: "list",
      params: { eventId },
      cache: false,
    };
    return dispatch(api(request));
  };
};

export const updateExhibitor = (eventId, exhibitorId, data) => {
  return (dispatch) => {
    if (!data.exhibitorThumbnail) delete data.exhibitorThumbnail;
    if (!data.orgSponsorId) delete data.orgSponsorId;

    const filesToUpload = {
      exhibitorThumbnail: Object.assign([], data.exhibitorThumbnail),
    };

    if (
      data.exhibitorThumbnail &&
      typeof data.exhibitorThumbnail[0] !== "number"
    ) {
      data.exhibitorThumbnail[0] = data.exhibitorThumbnail[0].name;
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    let deletionAction = false;
    data.exhibitorThumbnail?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });

    if (data.active != null || data.active != null)
      data.active = Boolean(data.active);

    const request = {
      endpoint: "exhibitors",
      action: "update",
      params: { eventId, exhibitorId },
      body: {
        data,
      },
    };

    return dispatch(api(request)).then((data) => {
      if (!hasFiles) {
        dispatch(loadExhibitors(eventId));
        return data;
      } else if (!deletionAction) {
        dispatch(
          uploadFile(
            eventId,
            "exhibitorThumbnail",
            filesToUpload.exhibitorThumbnail[0],
            { exhibitorId: data.data.id },
            () => dispatch(loadExhibitors(eventId)),
            null,
            filesToUpload.exhibitorThumbnail.length
          )
        );
      } else {
        dispatch(loadExhibitors(eventId));
        return data;
      }
    });
  };
};

export const createExhibitor = (eventId, data) => {
  return (dispatch) => {
    const filesToUpload = {
      exhibitorThumbnail: Object.assign([], data.exhibitorThumbnail),
    };

    if (
      data.exhibitorThumbnail &&
      typeof data.exhibitorThumbnail[0] !== "number"
    ) {
      data.exhibitorThumbnail[0] = data.exhibitorThumbnail[0].name;
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    if (!data.orgSponsorId) delete data.orgSponsorId;
    if (!data.exhibitorThumbnail) delete data.exhibitorThumbnail;
    const request = {
      endpoint: "exhibitors",
      action: "create",
      params: { eventId },
      body: {
        data,
      },
    };
    return dispatch(api(request)).then((data) => {
      if (!hasFiles) {
        dispatch(loadExhibitors(eventId));
        return data;
      } else {
        dispatch(
          uploadFile(
            eventId,
            "exhibitorThumbnail",
            filesToUpload.exhibitorThumbnail[0],
            { exhibitorId: data.data.id },
            () => dispatch(loadExhibitors(eventId)),
            null,
            filesToUpload.exhibitorThumbnail.length
          )
        );
      }
    });
  };
};

export const deleteExhibitor = (eventId, exhibitorId) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitors",
      action: "delete",
      params: { eventId, exhibitorId },
      body: { data: {} },
    };
    return dispatch(api(request)).then(() => dispatch(loadExhibitors(eventId)));
  };
};

export const loadExhibitorPage = (eventId, exhibitorId) => {
  return (dispatch) => {
    const request = {
      endpoint: "exhibitors",
      action: "getPage",
      params: { eventId, exhibitorId },
      cache: false,
    };
    return dispatch(api(request));
  };
};

export const updateExhibitorPage = (eventId, exhibitorId, data) => {
  return (dispatch) => {
    if (!data.exhibitorThumbnail) delete data.exhibitorThumbnail;
    if (!data.orgSponsorId) delete data.orgSponsorId;
    const request = {
      endpoint: "exhibitors",
      action: "updatePage",
      params: { eventId, exhibitorId },
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then((data) => {
        dispatch(
          addNotification("Exhibirors Page updated successfully!", "success")
        );
        return data;
      })
      .then(() => dispatch(loadExhibitorPage(eventId, exhibitorId)));
  };
};

export const updateExhibitorBucket = (eventId, exhibitorId, data) => {
  return (dispatch) => {
    if (!data.exhibitorsBucket) delete data.exhibitorsBucket;

    const filesToUpload = {
      exhibitorsBucket: Object.assign([], data.exhibitorsBucket),
    };

    if (data.exhibitorsBucket?.length > 0) {
      data.exhibitorsBucket.forEach((exhibitionFile, i) => {
        if (typeof exhibitionFile !== "number") {
          data.exhibitorsBucket[i] = data.exhibitorsBucket[i].name;
        }
      });

      if (data.exhibitorsBucket.length > 1) {
        data.exhibitorsBucket = [data.exhibitorsBucket.join(",")];
      }
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    let deletionAction = false;
    data.exhibitorsBucket.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });

    const request = {
      endpoint: "exhibitors",
      action: "updateBucket",
      params: { eventId, exhibitorId },
      body: {
        data,
      },
    };

    const callback = () => {
      return dispatch(loadExhibitorPage(eventId, exhibitorId));
    };

    if (!hasFiles || deletionAction) {
      return dispatch(api(request)).then((data) => {
        dispatch(
          addNotification("Exhibirors Bucket updated successfully!", "success")
        );
        dispatch(loadExhibitorPage(eventId, exhibitorId));
        return data;
      });
    } else {
      return dispatch(api(request)).then(({ data }) => {
        const promiseArr = [];
        filesToUpload.exhibitorsBucket.forEach((file, i) => {
          if (
            filesToUpload.exhibitorsBucket[i] &&
            typeof filesToUpload.exhibitorsBucket[i] !== "number"
          ) {
            promiseArr.push(
              dispatch(
                uploadFile(
                  eventId,
                  "exhibitorsBucket",
                  filesToUpload.exhibitorsBucket[i],
                  {
                    exhibitorId,
                  },
                  callback,
                  null,
                  filesToUpload.exhibitorsBucket.length
                )
              )
            );
          }
        });

        return Promise.all(promiseArr)
          .then(() => {
            dispatch(
              addNotification(
                "Exhibirors Bucket updated successfully!",
                "success"
              )
            );
          })
          .catch((err) => {
            console.warn(err);
            return data;
          });
      });
    }
  };
};
