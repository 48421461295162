/* eslint-disable */
import React from "react";
import Select2 from "@layout/Select2";

import PropTypes from "prop-types";

export default class SearchUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || [],
      selectedFilter: props.filters?.options[0] || "",
    };
    this.onChange = this.onChange.bind(this);
  }

  handleFilterChange = (filter) => {
    this.setState({ selectedFilter: filter });
    if (this.props.filters.callback) {
      this.props.filters.callback(filter);
    }
  };

  onChange(value, { option: selectedOption }, inputValue, key) {
    this.setState({ value });
    if (this.props.onChange)
      this.props.onChange(this.props.name, value, { selectedOption });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({ value: nextProps.value });
    }
  }

  renderFilters() {
    if (!this.props.filters || !this.props.filters.options) return null;
    return (
      <div className="filters">
        {this.props.filters.options.map((filter, index) => (
          <span
            key={`${filter}-${index}`}
            onClick={() => this.handleFilterChange(filter)}
            style={{
              fontWeight:
                this.state.selectedFilter === filter ? "bold" : "normal",
              cursor: "pointer",
            }}
          >
            {filter}
            {index < this.props.filters.options.length - 1 ? " / " : ""}
          </span>
        ))}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderFilters()}
        <div className="searchuser-inner">
          <div
            className={`form-group${this.props.hasErrors ? " has-error" : ""}`}
          >
            <Select2
              placeholder={this.props.placeholder || "Select"}
              disabled={this.props.disabled || false}
              options={this.props.options}
              onChange={this.onChange}
              onSearch={this.props.onSearch}
              debounceOnChange={true}
              value={this.state.value}
              multi={this.props.multi || false}
            />
          </div>
        </div>
      </>
    );
  }
}

SearchUser.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.object.isRequired,
  hasErrors: PropTypes.bool,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  filters: PropTypes.shape({
    callback: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
};
