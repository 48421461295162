import React from "react";
import PropTypes from "prop-types";

export default class Api extends React.Component {
  constructor(props) {
    super(props);
  }

  copy(tokenType) {
    this[tokenType].select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.warn(err);
    }
  }

  render() {
    const props = this.props;

    return (
      <div className="tab-content form-container">
        <form action="">
          <div className="form-group">
            <div className="field-type type-token kmb-token">
              <label htmlFor="eventApiToken">Event Api Token</label>
              <div className="row">
                <div className="col-sm-6">
                  <span className="subtitle">
                    By using this token, you gain access to all the {'"GET"'}{" "}
                    endpoints.
                  </span>
                </div>
                <div className="col-sm-6 text-right">
                  <span
                    className="create-new"
                    onClick={() =>
                      props.updateEvent(props.event.id, { eventApiToken: true })
                    }
                  >
                    <span className="icon-link-1"></span>Create new
                  </span>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="eventApiToken"
                  id="eventApiToken"
                  readOnly={true}
                  ref={(ref) => (this.eventApiToken = ref)}
                  value={
                    props.event.settings.tokens.eventApiToken
                      ? props.event.settings.tokens.eventApiToken
                      : ""
                  }
                />
                <span
                  className="copy-token"
                  onClick={() =>
                    this.copy(
                      "eventApiToken",
                      props.event.settings.tokens.eventApiToken
                    )
                  }
                >
                  <span className="icon-copy-symbol"></span>Copy
                </span>
              </div>
            </div>
            <div className="help-block"></div>
          </div>
          <div className="form-group">
            <div className="field-type type-token kmb-token">
              <label htmlFor="adminApiToken">Admin Api Token</label>
              <div className="row">
                <div className="col-sm-6">
                  <span className="subtitle">
                    By using this token, you gain access to all endpoints.
                  </span>
                </div>
                <div className="col-sm-6 text-right">
                  <span
                    className="create-new"
                    onClick={() =>
                      props.updateEvent(props.event.id, { adminApiToken: true })
                    }
                  >
                    <span className="icon-link-1"></span>Create new
                  </span>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  ref={(ref) => (this.adminApiToken = ref)}
                  className="form-control"
                  name="adminApiToken"
                  id="adminApiToken"
                  readOnly={true}
                  value={
                    props.event.settings.tokens.adminApiToken
                      ? props.event.settings.tokens.adminApiToken
                      : ""
                  }
                />
                <span
                  className="copy-token"
                  onClick={() =>
                    this.copy(
                      "adminApiToken",
                      props.event.settings.tokens.eventApiToken
                    )
                  }
                >
                  <span className="icon-copy-symbol"></span>Copy
                </span>
              </div>
            </div>
            <div className="help-block"></div>
            <br />
          </div>
        </form>
      </div>
    );
  }
}
Api.propTypes = {
  event: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
};
