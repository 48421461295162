import React from "react";
import moment from "moment";
import { isNumeric, isOnline } from "@helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createRentDataForScan,
  createRentOrReturnDataForScan,
} from "@actions/rentables";
import { loadEvent } from "@actions";
import { getSingleRentable } from "../../../actions/rentables";
import Switch from "@layout/Switch";
import { scanningAuth } from "@actions/appuser";
class RentableScanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format("HH:mm:ss"),
      id: "",
      settingsOpen: false,
      userConfirmed: false,
      isOnline: true,
      currentRentable: null,
      isRenting: true,
      isAutoRentOrReturn: true,
    };

    this.timer = null;
    this.tick = this.tick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.toggleRenting = this.toggleRenting.bind(this);
  }

  toggleRenting() {
    this.setState((prevState) => ({
      isRenting: !prevState.isRenting,
    }));
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.tick();
    }, 1000);

    document.body.addEventListener("keyup", this.handleKeyPress);
  }
  tick() {
    this.setState({
      time: moment().format("HH:mm:ss"),
      onLine: isOnline(),
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.fullScreen) {
      this.main.focus();
    }
  }

  UNSAFE_componentWillMount() {
    const { eventId, rentableId } = this.props;
    this.props.loadEvent(this.props.eventId);
    this.props
      .getSingleRentable(eventId, rentableId)
      .then((rentableData) => {
        this.setState({
          currentRentable: rentableData,
        });
      })
      .catch((error) => {
        console.error("Failed to load rentable data:", error);
      });
  }

  handleKeyPress(e) {
    const { key } = e;

    const interestedGroup = ["Enter", "Delete", "Backspace"];

    if (!isNumeric(key) && !interestedGroup.includes(key)) {
      return;
    }

    if (interestedGroup.includes(key)) {
      // call submit or clear
      const userId = this.state.id;

      if (userId === "") {
        return;
      }
      const data = {
        eventUserId: userId,
      };
      if (this.state.isAutoRentOrReturn) {
        data.scanDate = new Date();
      } else {
        if (this.state.isRenting) {
          data.rentedAtDate = new Date();
        } else {
          data.returnedAtDate = new Date();
        }
      }

      this.setState(
        {
          id: "",
        },
        () => {
          const eventId = this.props.eventId;
          const rentableId = this.props.rentableId;
          if (key === "Enter") {
            if (this.state.isAutoRentOrReturn) {
              this.props
                .createRentOrReturnDataForScan(
                  eventId,
                  rentableId,
                  data,
                  userId
                )
                .catch(() => {
                  console.error("Error in data");
                });
              return;
            }

            this.props
              .createRentDataForScan(eventId, rentableId, data, userId)
              .catch(() => {
                console.error("Error in data");
              });
          }
        }
      );
    } else {
      // prepare number
      this.setState((prevState) => {
        return {
          id: `${prevState.id}${key}`,
        };
      });
    }
  }
  toggleSettings() {
    if (!this.state.settingsOpen) {
      document.body.removeEventListener("keyup", this.handleKeyPress);
      this.props
        .scanningAuth(() =>
          document.body.addEventListener("keyup", this.handleKeyPress)
        )
        .then(() => {
          this.setState({
            settingsOpen: true,
          });
        })
        .catch((err) => {
          console.warn(err);
        });
    } else {
      document.body.addEventListener("keyup", this.handleKeyPress);
      this.setState({
        settingsOpen: false,
      });
    }
  }

  render() {
    const notFound = false;
    const { activeScans } = this.props;
    const showWarning = !this.state.isOnline;
    return (
      <div
        className="container-fluid tab-content"
        tabIndex="-1"
        ref={(ref) => (this.main = ref)}
      >
        <div className="row top-bar">
          <div
            className="informations"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="room">
              <label>Rentable Name</label>

              <p>
                {this.state.currentRentable
                  ? this.state.currentRentable.name
                  : "Loading..."}
              </p>
            </div>
            <div className="time" style={{ width: "145px" }}>
              <label>Time</label>
              <p>{this.state.time}</p>
            </div>
            {/* <div className="time">
              <label>Available Items</label>
              <p>
                {this.state.currentRentable
                  ? `${this.state.currentRentable.availableQuantity} / ${this.state.currentRentable.quantity}`
                  : "Loading..."}
              </p>
            </div> */}
            <div
              className="time"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {this.state.isAutoRentOrReturn ? (
                <>
                  <label>Automatic Rent and Return</label>
                  <div
                    style={{
                      fontSize: "2rem",
                      color: "var(--success-color)",
                    }}
                  >
                    Enabled
                  </div>
                </>
              ) : (
                <>
                  <label>Renting or Returning</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <p
                      style={{
                        color: !this.state.isRenting
                          ? "var(--error-color)"
                          : "var(--black-tone-2)",
                        fontSize: "2rem",
                      }}
                    >
                      Returning
                    </p>
                    <Switch
                      isActive={this.state.isRenting}
                      onChange={this.toggleRenting}
                      color="primary"
                    />

                    <p
                      style={{
                        color: this.state.isRenting
                          ? "var(--success-color)"
                          : "var(--black-tone-2)",
                        fontSize: "2rem",
                      }}
                    >
                      Renting
                    </p>
                  </div>
                </>
              )}
            </div>
            {this.props.currentAccess &&
              (this.props.currentAccess.role === "organisation_admin" ||
                this.props.currentAccess.role === "event_admin") && (
                <div className="actions-container">
                  <div className="action-buttons confirm-holder">
                    <span
                      className={`fa fa-wrench settings-toggler${
                        this.state.settingsOpen ? " toggled" : ""
                      }`}
                      onClick={this.toggleSettings}
                    ></span>
                    <div
                      className={`inner-settings confirm-holder${
                        this.state.settingsOpen ? "" : " hidden"
                      }`}
                    >
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState((prevState) => ({
                            isAutoRentOrReturn: !prevState.isAutoRentOrReturn,
                          }));
                        }}
                      >
                        {this.state.isAutoRentOrReturn
                          ? "Enable Manual Rent"
                          : "Enable Auto Rent"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        {showWarning && (
          <div className="offline-panel row text-center">
            <div className="col-xs-12">
              <h4>
                Caution:
                {!this.state.onLine && (
                  <span> Network connection is lost. </span>
                )}
              </h4>
            </div>
          </div>
        )}

        <div className="scan-table  med-table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Rent/Return</th>
                <th>Rented/Return At</th>
              </tr>
            </thead>
            <tbody>
              {/* Todo enable this when we have the activescans */}
              {activeScans.length > 0 &&
                activeScans.map((currentScan, index) => {
                  if (currentScan.id === -1 || !currentScan.eventUserFullName) {
                    return null;
                  }
                  return (
                    <tr
                      className={`type-${
                        currentScan.returnedAtDate ? "exit" : "enter"
                      }`}
                      key={`scan-${index}-${currentScan.eventUserId}-${currentScan.returnedAtDate}-${currentScan.rentedAtDate}`}
                    >
                      <td>{currentScan.eventUserFullName}</td>

                      <td className="scan-type">
                        <div>
                          {currentScan.scanType === "offline"
                            ? "N/A"
                            : currentScan.returnedAtDate
                            ? "Returned"
                            : "Rented"}
                        </div>
                      </td>
                      <td>
                        {currentScan.returnedAtDate
                          ? moment(currentScan.returnedAtDate).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : moment(currentScan.rentedAtDate).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {notFound && (
          <div className="not-found">
            <span className="icon-valid"></span>
            User not found.
          </div>
        )}
      </div>
    );
  }
}

RentableScanning.propTypes = {
  eventId: PropTypes.string.isRequired,
  activeScans: PropTypes.array,
  currentRoom: PropTypes.object,
  getSingleRentable: PropTypes.func.isRequired,
  offlineScans: PropTypes.bool.isRequired,
  downloadFile: PropTypes.func.isRequired,
  sendHitsToServer: PropTypes.func.isRequired,
  scanningAuth: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  currentAccess: PropTypes.object,
  unlockScanner: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  apiUrl: PropTypes.string,
  statistics: PropTypes.any,
  addNotification: PropTypes.func,
  rentableId: PropTypes.string.isRequired,
  createRentDataForScan: PropTypes.func.isRequired,
  createRentOrReturnDataForScan: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRentOrReturnDataForScan: (eventId, rentableId, data, eventUserId) =>
      dispatch(
        createRentOrReturnDataForScan(eventId, rentableId, data, eventUserId)
      ),

    createRentDataForScan: (eventId, rentableId, data, eventUserId) =>
      dispatch(createRentDataForScan(eventId, rentableId, data, eventUserId)),
    scanningAuth: (cb) => dispatch(scanningAuth(cb)),

    loadEvent: (eventId) => dispatch(loadEvent(eventId)),
    getSingleRentable: (eventId, rentableId) =>
      dispatch(getSingleRentable(eventId, rentableId)),
  };
};
const mapStateToProps = (state) => {
  return {
    currentAccess: state.appuser.currentAccess,
    eventId: state.page.params.eventId,
    rentableId: state.page.params.rentableId,
    activeScans: state.api.rentables.activeScans || [],
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RentableScanning);
