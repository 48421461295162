import React from "react";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import { connect } from "react-redux";
import Email from "@layout/Email";
import { loadPolicies } from "@actions";
import Breadcrumbs from "@layout/Breadcrumbs";
import { sendQuestionnaire } from "@actions/questionnaire";
import { addNotification } from "@actions";
import SendNow from "@app_icons/SendNow";
import Confirm from "@layout/Confirm";
import { replaceSpanWithDataOriginalVar } from "../../../../../../../helpers";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.extraFields = [];
    this.state = {
      email: this.props.email,
    };
  }
  emailTemplateChanged(data) {
    const newEmail = { ...this.state.email };
    newEmail.template = data;
    newEmail.eventPolicyId = data.eventPolicyId;
    newEmail.locked = data.locked;
    this.setState({ email: newEmail });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.email) !== JSON.stringify(this.props.email)) {
      this.setState({ email: nextProps.email });
    }
  }
  componentDidMount() {
    this.props.loadPolicies(this.props.eventId);
  }

  createQuestionnaire() {
    const template = JSON.stringify({
      subject: this.state.subjectValue,
      html: this.state.questionnaireValue,
      replyTo: this.state.replyTo,
      bccAddress: this.state.bccAddress,
      preserveRecipients: this.state.preserveRecipients,
    });
    const questions = [];
    // Pairnw to key apo ta checkboxes epeidh exei thn morfh pou thelei to backend
    // Epishs elegxw ean to key auto uparxei sto policy pou exei epilexthei, ean uparxei tote to vazei sta questionsx
    Object.keys(this.state.checkboxes).forEach((key) => {
      if (
        this.state.checkboxes[key] === 1 &&
        this.props.accessPolicies[this.state.currentPolicy].extraSettings[key]
      ) {
        questions.push(key);
      }
    });

    const data = {
      name: this.state.titleValue,
      meta: questions,
      template,
      eventPolicyId: this.props.accessPolicies[this.state.currentPolicy].id,
    };
    if (!this.props.email) {
      data["contacts"] = this.props.contacts;
    }

    this.props
      .createQuestionnaire(
        this.props.eventId,
        data,
        this.props.email ? this.props.email.id : null,
        this.props.email ? true : false
      )
      .then(() => this.props.changeTab());
  }
  editContact(e, contact, contactIndex) {
    return this.props.contactModal(
      this.props.orgId,
      e.id,
      e,
      "editContact",
      contact,
      contactIndex,
      this.props.policyGroups.filter(
        (group) => group.eventPolicyId == this.state.email.eventPolicyId
      ),
      (data) => {
        this.setState(
          {
            email: {
              ...this.state.email,
              ...data,
            },
          },
          this.props.hideModal(false)
        );
      }
    );
  }
  addContact(e) {
    return this.props.contactModal(
      this.props.orgId,
      e.id,
      e,
      "createContact",
      {},
      null,
      this.props.policyGroups.filter(
        (group) => group.eventPolicyId == this.state.email.eventPolicyId
      ),
      (data) => {
        this.setState(
          {
            email: {
              ...this.state.email,
              ...data,
            },
          },
          this.props.hideModal(false)
        );
      }
    );
  }
  deleteContact(email, contactIndex) {
    const contacts = [...email.contacts];

    contacts.splice(contactIndex, 1);
    const template = JSON.stringify(this.props.email.template);
    const data = {
      template,
      contacts,
    };
    this.setState(
      {
        email: {
          ...this.state.email,
          contacts: data.contacts,
        },
      },
      this.props.hideModal(false)
    );
  }
  render() {
    if (this.props.fetching) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }
    const { email } = this.state;
    const mode = this.props.mode;
    const variables = JSON.parse(JSON.stringify(this.props.globalVars));

    return (
      <React.Fragment>
        {typeof this.props.accessPolicies !== "undefined" && email.id && (
          <div className="email-schedule-bar">
            <React.Fragment>
              <Confirm
                onConfirm={() => {
                  this.props.sendQuestionnaire(this.props.eventId, email.id);
                }}
                body={
                  <span>
                    This email will be sent to its recipients on its last saved
                    state. Make sure you have saved any unsaved changes before
                    you send.
                  </span>
                }
                confirmText="Yes, send email now!"
                title="Attention"
              >
                <button
                  type="submit"
                  className="btn save rounded email-save-button email-secondary"
                >
                  <div className="icon-wrapper">
                    <SendNow />
                  </div>
                  <div>Send email now</div>
                </button>
              </Confirm>
            </React.Fragment>
          </div>
        )}
        <div className="tab-content">
          <div className="container-fluid">
            <Breadcrumbs
              options={[
                {
                  name: "Questionnaire Emails",
                  action: () => this.props.changeTab("Questionnaires", null),
                },
                { name: email.name },
              ]}
            />
          </div>
          <Email
            questionnaire={true}
            defaultReplyTo={this.props.supportEmail}
            locked={this.state.email.locked}
            toggleLocked={(newValue) => {
              const newLockedVal = newValue ? true : false;
              this.setState({
                email: { ...this.state.email, locked: newLockedVal },
              });
            }}
            placeholder={`Type the content of the email. Use any variables you want, for dynamic injection.`}
            rows={50}
            template={{
              id: email.id,
              eventPolicyId: email.eventPolicyId,
              meta: email.meta,
              name: email.name,
              ...email.template,
            }}
            onChange={(data) => this.emailTemplateChanged(data)}
            variables={variables}
            contacts={email.contacts}
            addContact={() => this.addContact(email)}
            editContact={(email, contact, index) =>
              this.editContact(email, contact, index)
            }
            deleteContact={(index) => this.deleteContact(email, index)}
            onSave={() => {
              const { template, contacts, eventId, locked } = email;
              const name = template.name;
              const eventPolicyId =
                template.eventPolicyId || email.eventPolicyId;
              const meta = template.meta;
              delete template.name;
              delete template.eventPolicyId;
              delete template.meta;
              const newTemplate = { ...template };
              newTemplate.html = replaceSpanWithDataOriginalVar(
                newTemplate.html
              );
              const data = {
                name,
                template: JSON.stringify(newTemplate),
                contacts,
                eventPolicyId,
                meta,
                locked,
              };
              if (mode === "edit") {
                this.props.updateQuestionnaire(
                  data,
                  this.props.eventId,
                  email.id
                );
              } else {
                this.props.createQuestionnaire(eventId, data);
              }
            }}
            delete={false}
            accessPolicies={this.props.accessPolicies}
            showDeleteModal={mode === "edit"}
            showName={true}
            showLocked={true}
            contactVariables={{
              groups: this.props.policyGroups.filter(
                (group) => group.eventPolicyId == this.state.email.eventPolicyId
              ),
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
Questionnaire.propTypes = {
  eventId: PropTypes.number,
  getQuestionnaires: PropTypes.func,
  contactModal: PropTypes.any.isRequired,
  contacts: PropTypes.array.isRequired,
  createQuestionnaire: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  questionnaire: PropTypes.object,
  questionnairesList: PropTypes.any,
  updateQuestionnaire: PropTypes.func,
  globalVars: PropTypes.array,
  specificVars: PropTypes.array,
  loadPolicies: PropTypes.func.isRequired,
  accessPolicies: PropTypes.array.isRequired,
  fetching: PropTypes.bool,
  locked: PropTypes.bool,
  questionnaireVariables: PropTypes.array,
  email: PropTypes.string,
  orgId: PropTypes.number,
  policyGroups: PropTypes.array,
  hideModal: PropTypes.func,
  sendQuestionnaire: PropTypes.func,
  mode: PropTypes.string,
  supportEmail: PropTypes.string,
};
const mapStateToProps = (state) => {
  return {
    supportEmail: state.api.events.edit.data.supportEmail,
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    specificVars: state.api.events.questionnaires.meta.specific_vars,
    eventId: state.api.events.edit.data.id,
    contacts: state.api.events.tempQuestionnaireContacts,
    questionnairesList: state.api.events.questionnaires.data,
    accessPolicies: state.api.accesspolicies.list.data,
    fetching:
      state.api.accesspolicies.list.fetching ||
      state.api.events.questionnaires.fetching,
    questionnaireVariables:
      state.api.events.questionnaires.meta.email_variables,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadPolicies: (eventId) => {
      return dispatch(loadPolicies(eventId));
    },
    sendQuestionnaire: (orgId, emailid, eventId) => {
      return dispatch(sendQuestionnaire(orgId, emailid, eventId)).then(() => {
        dispatch(addNotification("successfully send email", "success"));
        return null;
      });
    },
  };
  return dispatcher;
};
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
