import React from "react";
import PropTypes from "prop-types";
import Select from "@layout/Select2";
import { isEmpty } from "@helpers";
import KMBLoader from "@layout/KMBLoader";
import { useAuth } from "../../routers/partials/authProvider";

export default function User(props) {
  const { setAuthenticated } = useAuth();
  const handleAction = (action) => {
    if (action === "logout") {
      setAuthenticated(false);
      props.logoutUser();
    } else if (action === "account") {
      props.page.navigate(`/administrator/myaccount`);
    } else {
      // role change
      if (action === "admin_panel") {
        props.page.navigate(`/administrator/events`);
      } else {
        props.page.navigate(`/judge/abstracts`);
      }
    }
  };

  const { user } = props;
  const options = {
    account: (
      <span>
        <span style={{ paddingRight: 10 }} className="icon-username"></span>
        My Account
      </span>
    ),
  };
  const _roles = new Set(
    Object.values(props.user.accessEvents).map((access) => access.role)
  );
  const hasAdminRole =
    _roles.has("organisation_admin") ||
    _roles.has("event_admin") ||
    _roles.has("event_policy_admin") ||
    _roles.has("event_secretary");
  const hasJudgeRole = _roles.has("event_judge");
  const urlPath = props.page.params["*"]?.split("/")[0];
  const userIsOnJudgeScreen = urlPath === "judge";
  const userIsOnAdminScreen = urlPath === "administrator";
  const userIsOnMyAccount =
    props.page.params["*"] === "administrator/myaccount";
  if (hasJudgeRole && hasAdminRole && userIsOnAdminScreen) {
    options["judge_panel"] = (
      <span>
        <span style={{ paddingRight: 10 }} className="icon-refresh"></span>
        Switch to Judge Panel
      </span>
    );
  }

  if (hasJudgeRole && hasAdminRole && userIsOnJudgeScreen) {
    options["admin_panel"] = (
      <span>
        <span style={{ paddingRight: 10 }} className="icon-refresh"></span>
        Switch to Admin Panel
      </span>
    );
  }

  if (
    hasJudgeRole &&
    !hasAdminRole &&
    !userIsOnJudgeScreen &&
    !userIsOnMyAccount
  ) {
    props.page.navigate(`/judge/abstracts`);
  }

  options.logout = (
    <span>
      <span style={{ paddingRight: 10 }} className="icon-all-out"></span>
      Logout
    </span>
  );

  return (
    <div className="user-wrapper">
      <div className="vertical-center">
        {isEmpty(user) ? (
          <KMBLoader rows={15} padding={24} height={53} />
        ) : (
          <Select
            hoverColor={"white"}
            isSearchable={false}
            border={"none"}
            isDropDownButton={true}
            controlBackground={"transparent"}
            color="white"
            placeholder={
              props.smallScreen ? (
                <i className="fas fa-user"></i>
              ) : (
                `${user.firstName} ${user.lastName}`
              )
            }
            options={options}
            onChange={handleAction}
            placeholderInsideSelect={false}
          />
        )}
      </div>
    </div>
  );
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  activeScreen: PropTypes.string,
  router: PropTypes.object,
  changeScreen: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool,
  page: PropTypes.object,
};
