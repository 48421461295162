import colorConverter from "./colors-helper";

const hslToHex = (h, s, l) => {
  const a = s * Math.min(l, 1 - l);
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const convertPrimary = (step, color) => {
  let finalLightnessChange = 0;
  const colorObj = colorConverter(color)(color);

  const arrayColorBright = [500, 400, 300, 200, 100, 50, 25];
  const stepper1 = (1 - colorObj.lightness) / (arrayColorBright.length + 1);

  const arrayColorDark = [700, 800, 900];
  const stepper2 = colorObj.lightness / (arrayColorDark.length + 1);

  const minStep = stepper1 < stepper2 ? stepper1 : stepper2;

  if (step < 600)
    finalLightnessChange = (arrayColorBright.indexOf(step) + 1) * minStep;
  else if (step > 600)
    finalLightnessChange = -1 * (arrayColorDark.indexOf(step) + 1) * minStep;

  return hslToHex(
    colorObj.hue,
    colorObj.sat,
    colorObj.lightness + finalLightnessChange
  );
};

export const setPrimaryColor = (newColor) => {
  return [
    ((color) => convertPrimary(25, color))(newColor),
    ((color) => convertPrimary(50, color))(newColor),
    ((color) => convertPrimary(100, color))(newColor),
    ((color) => convertPrimary(200, color))(newColor),
    ((color) => convertPrimary(300, color))(newColor),
    ((color) => convertPrimary(400, color))(newColor),
    ((color) => convertPrimary(500, color))(newColor),
    ((color) => convertPrimary(600, color))(newColor),
    ((color) => convertPrimary(700, color))(newColor),
    ((color) => convertPrimary(800, color))(newColor),
    ((color) => convertPrimary(900, color))(newColor),
  ];
};
