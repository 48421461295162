import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import Switch from "@layout/Switch";
import moment from "moment";
import SingleSubscription from "./partials/SingleSubscription/";

export default class Subscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.columns = {
      typeName: {
        name: "Type",
      },
      id: {
        name: "ID",
      },
      periodName: {
        name: "Period",
      },
      eventPolicyName: {
        name: "Policy Name",
      },
      startDate: {
        name: "Start Date",
      },
      endDate: {
        name: "End Date",
      },
      price: {
        name: "Price",
      },
      actions: {
        name: "",
      },
    };

    this.onRender = this.onRender.bind(this);
    this.state = {
      refresh: false,
    };
  }

  onRender(col, item, type) {
    switch (col.key) {
      case "active": {
        return (
          <Switch id={`subscription-active-${item.id}`} isActive={col.value} />
        );
      }

      case "actions": {
        if (type === "types") {
          return (
            <TableActions
              entity="subscription"
              item={item}
              onEdit={() =>
                this.props.showModal(
                  <SingleSubscription
                    eventId={this.props.eventId}
                    policyId={this.props.policy.id}
                    subscriptionId={item.id}
                    item={item}
                    type={"types"}
                  />
                )
              }
              onDelete={() =>
                this.props.deleteTypePeriod(
                  this.props.eventId,
                  item.id,
                  "types"
                )
              }
            />
          );
        } else if (type === "periods") {
          return (
            <TableActions
              entity="subscription"
              item={item}
              onEdit={() =>
                this.props.showModal(
                  <SingleSubscription
                    eventId={this.props.eventId}
                    policyId={this.props.policy.id}
                    subscriptionId={item.id}
                    item={item}
                    type={"periods"}
                  />
                )
              }
              onDelete={() =>
                this.props.deleteTypePeriod(
                  this.props.eventId,
                  item.id,
                  "periods"
                )
              }
            />
          );
        } else {
          return (
            <TableActions
              entity="subscription"
              item={item}
              onEdit={() =>
                this.props.showModal(
                  <SingleSubscription
                    eventId={this.props.eventId}
                    policyId={this.props.policy.id}
                    subscriptionId={item.id}
                  />
                )
              }
              onDelete={() =>
                this.props.deleteItem(this.props.eventId, item.id)
              }
            />
          );
        }
      }

      case "startDate":
      case "endDate": {
        return moment.utc(col.value.tz).format("DD MMMM Y HH:mm:ss");
      }
    }
  }

  componentDidMount() {
    this.props.getSubscriptions(
      this.props.eventId,
      "list",
      this.props.policy.id
    );
    this.props.getSubscriptions(
      this.props.eventId,
      "periods",
      this.props.policy.id
    );
  }

  render() {
    const periodCol = {
      id: {
        name: "Period ID",
      },
      name: {
        name: "Period Name",
      },

      startDate: {
        name: "Start Date",
      },
      endDate: {
        name: "End Date",
      },
      actions: {
        name: "",
      },
    };

    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleSubscription
                    eventId={this.props.eventId}
                    policyId={this.props.policy.id}
                    subscriptionId={null}
                    type={"periods"}
                  />
                )
              }
            >
              Create Period
            </button>
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleSubscription
                    eventId={this.props.eventId}
                    policyId={this.props.policy.id}
                    subscriptionId={null}
                  />
                )
              }
            >
              Create Subscription
            </button>
          </div>
        </div>
        <div className="tab-content">
          <h3>Subscriptions</h3>
          <Table
            id={"subscriptions"}
            columns={this.columns}
            items={this.props.subscriptions}
            onRender={(col, item) => this.onRender(col, item, "subscriptions")}
            updating={this.props.fetching}
            refresh={this.state.refresh}
          />
          <br />
          <h3>Periods</h3>
          <Table
            id={"periods"}
            columns={periodCol}
            items={this.props.periods}
            onRender={(col, item) => this.onRender(col, item, "periods")}
            updating={this.props.fetching}
            refresh={this.state.refresh}
          />
        </div>
      </>
    );
  }
}

Switch.defaultProps = {
  createTypePeriod: () => {},
};
Subscriptions.propTypes = {
  eventId: PropTypes.string.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  subscriptions: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  periods: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  createTypePeriod: PropTypes.func,
  deleteTypePeriod: PropTypes.func.isRequired,
  reorderSubscriptions: PropTypes.func,
  policy: PropTypes.object,
};
