/* eslint-disable no-console */
import React from "react";
import { connect } from "react-redux";
import {
  getJudgeAbstract,
  rateAbstract,
  commentAbstract,
} from "@actions/judge";
import { loadEventSchema } from "@actions/abstracts";
import PropTypes from "prop-types";
import moment from "moment";
import Input from "@layout/Input";
import {
  isEmpty,
  getAbstractInternalStatus,
  createAbstractFile,
} from "@helpers";
import KMBLoader from "@layout/KMBLoader";
import Confirm from "@layout/Confirm";
import Breadcrumbs from "@layout/Breadcrumbs";

class SingleAbstract extends React.Component {
  constructor(props) {
    super(props);
    ["onChange", "comment", "getFileClassName", "getAbstract"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );

    this.state = {
      ready: false,
      commentText: "",
      score: "",
      commentType: "",
    };
  }

  comment(commentType) {
    const { commentText } = this.state;
    const { commentAbstract, abstract, getJudgeAbstract } = this.props;
    const { id, eventId } = abstract;
    commentAbstract(eventId, id, {
      commentText,
      commentType,
      submitted: true,
    }).then(() =>
      getJudgeAbstract(eventId, id).then(() =>
        this.setState({ commentText: "" })
      )
    );
  }
  checkScoreRange(schema, score) {
    let errorMessage = "";
    if (score < schema?.minScore)
      errorMessage = "The score is ouside the score-range";
    if (score > schema?.maxScore)
      errorMessage = "The score is ouside the score-range";
    return errorMessage;
  }
  getAbstract(props) {
    const { eventId, abstractId } = props.page.params;
    if (!this.state.ready && eventId && abstractId) {
      const { getJudgeAbstract, loadEventSchema } = props;
      Promise.all([
        getJudgeAbstract(eventId, abstractId),
        loadEventSchema(eventId),
      ]).then(([abstract, schema]) => {
        this.setState({
          score: abstract.data.score,
          ready: true,
          schema: schema.data,
          errorMessage: this.checkScoreRange(schema.data, this.state.score),
        });
      });
    }
  }
  componentDidMount() {
    this.getAbstract(this.props);
  }

  onChange(e) {
    let errorMessage = "";
    const { name, value } = e.target;
    if (name === "score") {
      if (!isFinite(value)) return;
      errorMessage = this.checkScoreRange(this.state.schema, value);
    }
    this.setState({
      [name]: value,
      errorMessage,
    });
  }

  submit(type = false) {
    const { id, eventId } = this.props.abstract;
    const { score } = this.state;
    const rated = type === false;
    const data = { score, rated };
    this.props.rateAbstract(eventId, id, data);
  }
  checkIfScoreIsDefined() {
    return (
      this.state.score !== undefined &&
      this.state.score !== "" &&
      this.state.score !== null
    );
  }
  getFileClassName(extension) {
    switch (extension) {
      case "jpg":
      case "png":
      case "gif":
      case "bmp": {
        return "fa-file-image-o";
      }
      case "pdf": {
        return "fa-file-pdf-o";
      }
      case "doc":
      case "docx": {
        return "fa-file-word-o";
      }
      case "xls":
      case "xlsx":
      case "csv": {
        return "fa-file-excel-o";
      }
      default: {
        return "fa-file-o";
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.page.params.abstractId !== nextProps.page.params.abstractId
    ) {
      this.getAbstract(nextProps);
    }

    if (!isEmpty(nextProps.abstract)) {
      if (
        nextProps.abstract.score !== null &&
        nextProps.abstract.score !== this.state.score
      ) {
        this.setState({
          score: nextProps.abstract.score,
        });
      } else if (nextProps.abstract.maxScore < this.state.score) {
        this.setState({
          score: nextProps.abstract.maxScore,
        });
      }
    }
  }

  render() {
    const { abstract } = this.props;
    return (
      <div className="judge-listing">
        <div className="top-bar">
          <div>
            <div className="breadcrumbs left">
              <Breadcrumbs
                options={[
                  {
                    name: "Abstracts",
                    action: () => this.props.page.navigate("/judge/abstracts"),
                  },
                  {
                    name: (
                      <span className="abstract-name">
                        {abstract.name || ""}
                      </span>
                    ),
                  },
                ]}
              />
            </div>
            <div className="right">
              <div style={{ display: "flex" }} className="downloads">
                <span
                  onClick={async () =>
                    await createAbstractFile(this.props.abstract)
                  }
                  title="Download all"
                >
                  Download
                  <i className="fa fa-download" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        {!this.state.ready ? (
          <KMBLoader rows={15} padding={24} height={53} />
        ) : (
          <div className="list-view">
            <div className="main-wrapper form-container">
              <div className="group-container">
                <div className="form-group">
                  <label>Submitted on:</label>
                  <p>
                    {moment(abstract.updatedAt.tz).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
                <div className="form-group">
                  <label>Status:</label>
                  <p>{getAbstractInternalStatus(abstract)}</p>
                </div>
                <div className="form-group">
                  <label>Your score:</label>
                  <p>{abstract.score}</p>
                </div>
              </div>

              <br />
              <br />
              <div className="form-group">
                <label>Title</label>
                <p className="form-info">{abstract.name}</p>
              </div>
              <div className="group-container">
                <div className="form-group">
                  <label>Type</label>
                  <p className="form-info">
                    {
                      (
                        abstract.types.filter(
                          (type) => type.typeFrom === "user"
                        )[0] || { name: "N/A" }
                      )?.name
                    }
                  </p>
                </div>
                <div className="form-group">
                  <label htmlFor="">Topics</label>
                  <p className="form-info">
                    {abstract.topics.map((topic) => topic.name).join(", ")}
                  </p>
                </div>
              </div>
              <div className="form-group">
                <label>Abstract</label>
                <p className="form-info">{abstract.abstractText}</p>
              </div>
              <h3>Additional Information</h3>
              <div className="group-container two">
                {abstract.meta.map((d) => {
                  return (
                    <div key={`field-${d.key}`} className="form-group">
                      <label>{d.name}</label>
                      <p className="form-info">
                        {d.type === "yes_no"
                          ? d.value === "1"
                            ? "Yes"
                            : "No"
                          : d.value}
                      </p>
                    </div>
                  );
                })}
              </div>
              {abstract.abstractFiles.length > 0 && (
                <div className="files box">
                  <label htmlFor="">Attached Files</label>
                  <div>
                    {abstract.abstractFiles.map((f) => {
                      return (
                        <div
                          key={`file-${f.id}`}
                          className={`file file-${f.extension}`}
                        >
                          <a
                            href={f.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ wordBreak: "break-all" }}
                          >
                            <i
                              className={`fa ${this.getFileClassName(
                                f.extension
                              )}`}
                              aria-hidden="true"
                            ></i>
                            {f.fileName}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {(!!abstract.comments.length ||
                (abstract.rated !== 1 &&
                  abstract.status !== "awaiting_resubmission")) && (
                <h3>Comments and Revisions</h3>
              )}
              {[...abstract.comments].map((r) => {
                return (
                  <div key={`revision-${r.id}`} className="form-group">
                    <label>{r.updatedAt.tz}</label>
                    <Input disabled={true} value={r.commentText} />
                  </div>
                );
              })}
              {abstract.rated !== 1 &&
                abstract.status !== "awaiting_resubmission" && (
                  <div className="form-group">
                    <label>New comment or revision information</label>
                    <textarea
                      name="commentText"
                      id="commentText"
                      cols="30"
                      placeholder="Leave a comment for the submitter"
                      value={this.state.commentText}
                      onChange={this.onChange}
                      className="form-control"
                      rows="6"
                    ></textarea>
                    <div
                      className="group-container"
                      style={{ marginTop: 12, justifyContent: "flex-end" }}
                    >
                      <Confirm
                        onConfirm={() => this.comment("comment")}
                        body={
                          <span>
                            You are about to send a comment to the Author. Are
                            you sure?
                          </span>
                        }
                        confirmText="Yes, submit the comment!"
                        title="Send a Comment"
                      >
                        <button
                          className="btn"
                          disabled={!this.state.commentText}
                        >
                          Comment
                        </button>
                      </Confirm>
                      <Confirm
                        onConfirm={() => this.comment("revision_minor")}
                        body={
                          <span>
                            You are about to send this abstract for <u>minor</u>{" "}
                            revision. This will notify the author to update and
                            resubmit an updated version of the abstract. You
                            will not be able to make any further updates until
                            the resubmission is completed. You will be notified
                            when the abstract is resubmitted. Are you sure you
                            want to proceed?
                          </span>
                        }
                        confirmText="Yes, send for minor revision!"
                        title="Minor revision"
                      >
                        <button
                          className="btn btn-danger"
                          disabled={!this.state.commentText}
                        >
                          Minor Revision
                        </button>
                      </Confirm>
                      <Confirm
                        onConfirm={() => this.comment("revision_major")}
                        body={
                          <span>
                            You are about to send this abstract for <u>major</u>
                            revision. This will notify the author to update and
                            resubmit the abstract.You will not be able to make
                            any further updates until the resubmission is
                            completed. You will be notified when the abstract is
                            resubmitted. Are you sure you want to proceed? Are
                            you sure?
                          </span>
                        }
                        confirmText="Yes, send for major revision!"
                        title="Major revision"
                      >
                        <button
                          className="btn btn-danger"
                          disabled={!this.state.commentText}
                        >
                          Major Revision
                        </button>
                      </Confirm>
                    </div>
                  </div>
                )}
              {
                <div className="form-group judge-score">
                  <label>Score</label>
                  <div className="form-details">
                    <span style={{ paddingRight: 5 }}>
                      Score range:{" "}
                      <b>
                        {this.state.schema?.minScore} -{" "}
                        {this.state.schema?.maxScore}
                      </b>
                    </span>
                    <span style={{ paddingRight: 5 }}>
                      Passing Score: <b>{this.state.schema?.passingScore}</b>
                    </span>
                  </div>

                  <Input
                    style={{ width: "100%" }}
                    className="form-control"
                    onChange={this.onChange}
                    disabled={
                      abstract.rated == 1 ||
                      abstract.status == "awaiting_resubmission"
                    }
                    value={this.state.score}
                    name="score"
                    min={abstract?.minScore}
                    max={abstract?.maxScore}
                    step={0.1}
                  />
                  {this.checkIfScoreIsDefined() && (
                    <p
                      className={`${
                        this.state.errorMessage ||
                        this.state.score < this.state.schema?.passingScore
                          ? "error"
                          : "success"
                      }`}
                    >
                      {this.state.errorMessage ||
                        (this.state.score < this.state.schema?.passingScore
                          ? "You are rejecting this abstract"
                          : "You are approving this abstract")}
                    </p>
                  )}
                </div>
              }
              {abstract.rated !== 1 &&
                abstract.status !== "awaiting_resubmission" && (
                  <div className="submit-container form-group">
                    <button
                      className="btn cancel"
                      disabled={!!this.state.errorMessage}
                      onClick={() => this.submit("save")}
                    >
                      save as draft
                    </button>
                    <Confirm
                      onConfirm={() => this.submit()}
                      body={
                        <span>
                          You are about to finalize the rating procedure for
                          this abstract. This action is irreversible. Are you
                          sure?
                        </span>
                      }
                      confirmText="Yes, Rate this abstract!"
                      title="Attention"
                    >
                      <button
                        disabled={!!this.state.errorMessage}
                        className="btn save"
                      >
                        Submit
                      </button>
                    </Confirm>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

SingleAbstract.propTypes = {
  getJudgeAbstract: PropTypes.func.isRequired,
  abstract: PropTypes.object,
  rateAbstract: PropTypes.func.isRequired,
  commentAbstract: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  loadEventSchema: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    abstract: state.api.judge.showingAbstract.abstract,
    page: state.page,
  }),
  { getJudgeAbstract, loadEventSchema, rateAbstract, commentAbstract }
)(SingleAbstract);
