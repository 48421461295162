import React from "react";
import SelectCheckboxes from "@layout/SelectCheckboxes";
import PropTypes from "prop-types";
import { loadUsers } from "@actions/users";
import { connect } from "react-redux";
import SelectDateRange from "@layout/SelectDateRange";
import { getPayments } from "@actions/payments";
import SearchAndSelect from "@layout/SearchAndSelect";

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.resetRange = this.resetRange.bind(this);
    this.prevQuery = "";
    this.state = {
      closeAllSelects: false,
      reset: false,
      resetRange: false,
      closeRange: false,
      filters: {
        eventUserId: "",
        way: [],
        type: [],
        dateRange: false,
      },
      userName: "",
      userId: "",
      sponsorName: null,
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }
  onSelectChange(userId = null, userName = "") {
    this.setFilter("eventUserId", userId);
    this.setState({ userId, userName });
  }
  setFilter(filter, value) {
    this.setState(
      {
        filters: Object.assign({}, this.state.filters, {
          [filter]: value,
        }),
      },
      () => {
        const request = {};
        for (const [i, f] of Object.entries(this.state.filters)) {
          if (f !== false) {
            request[i] = f;
          }
        }
        this.props.getPayments(this.props.eventId, request, true);
        this.props.getPayments(this.props.eventId, request);
      }
    );
  }

  resetRange() {
    this.setState(
      {
        resetRange: true,
        closeRange: true,
        filters: Object.assign({}, this.state.filters, {
          dateRange: false,
        }),
      },
      () => {
        this.setState({ resetRange: false, closeRange: false });
        const request = {};
        for (const [i, f] of Object.entries(this.state.filters)) {
          if (f !== false) {
            request[i] = f;
          }
        }
        this.props.getPayments(this.props.eventId, request);
        this.props.getPayments(this.props.eventId, request, true);
      }
    );
  }
  changeSponsor(val) {
    let id;
    this.setState({ sponsorName: val });
    this.props.sponsorList.map((sponsor) => {
      if (sponsor.name === val) {
        id = sponsor.id;
      }
    });
    if (val === "Select") {
      id = "";
    }
    this.setFilter("orgSponsorId", id);
  }

  render() {
    const sponsors = {};
    sponsors["Select"] = "Select";
    this.props.sponsorList.map((sponsor) => {
      sponsors[sponsor.name] = sponsor.name;
    });
    return (
      <>
        <SelectDateRange
          placeholder="Select Date Range"
          forceClose={true}
          reset={this.state.reset || this.state.resetRange}
          onChange={(value) => {
            if (value.from === null && value.to === null) {
              this.resetRange();
            } else {
              this.setFilter("dateRange", value);
            }
          }}
          onReset={this.resetRange}
        />
        <SelectCheckboxes
          options={{
            accomodation: {
              label: "Accommodation",
              checked: false,
            },
            registration: {
              label: "Registration",
              checked: false,
            },
            product: {
              label: "Product",
              checked: false,
            },
            sponsorship: {
              label: "Sponsorship",
              checked: false,
            },
          }}
          placeholder={"Select Type"}
          onChange={(type) => this.setFilter("type", type)}
          selected={this.state.filters?.type}
        />
        <SelectCheckboxes
          options={{
            pos: {
              label: "POS",
              checked: false,
            },
            cash: {
              label: "Cash",
              checked: false,
            },
            discount: {
              label: "Discount",
              checked: false,
            },
            bank: {
              label: "Bank",
              checked: false,
            },
            card: {
              label: "Card",
              checked: false,
            },
            paypal: {
              label: "Paypal",
              checked: false,
            },
          }}
          placeholder={"Select Method"}
          onChange={(type) => this.setFilter("way", type)}
          selected={this.state.filters?.way}
        />
        <SearchAndSelect
          placeholder={"Search participant..."}
          onSelectChange={this.onSelectChange}
          selectValue={this.state.userName}
          onSearch={(query) => {
            if (!query) this.setFilter("eventUserId", "");
            if (
              this.state.filters?.eventUserId?.value &&
              this.state.filters?.eventUserId?.value !== ""
            )
              this.setFilter("eventUserId", "");
            return this.props.searchUsers(query, null, this.props.eventId);
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    schema: state.api.abstracts.currentSchema.data,
    eventAbstractSchemaId: state.api.events.edit.data.eventAbstractSchemaId,
    eventId: state.api.events.edit.data.id,
    allPolicyUsers: state.users.allPolicyUsers.data,
    policyId: state.page.params.pid,
    doingAjax: state.api.payments.meta.statisticData.doingAjax,
    sponsorList: state.api.sponsors.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (queryString, policyId, eventId) => {
      return dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
    getPayments: (eventId, meta, statistics) => {
      dispatch(getPayments(eventId, meta, statistics));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filters);

Filters.propTypes = {
  onChange: PropTypes.func,
  searchUsers: PropTypes.func,
  schema: PropTypes.object,
  eventAbstractSchemaId: PropTypes.number,
  eventId: PropTypes.number,
  allPolicyUsers: PropTypes.array,
  policyId: PropTypes.number,
  getPayments: PropTypes.func,
  sponsorList: PropTypes.array,
};
