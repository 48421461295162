import React from "react";
import { isNumeric, isEmpty } from "@helpers";
import Banner from "@layout/Banner";
import PropTypes from "prop-types";

export default class MinuteScanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("keyup", this.handleKeyPress);
    if (isEmpty(this.props.event)) {
      this.props.loadEvent(this.props.eventId);
    }
  }

  handleKeyPress(e) {
    const { key } = e;
    const interestedGroup = ["Enter", "Delete", "Backspace"];

    if (!isNumeric(key) && !interestedGroup.includes(key)) {
      return;
    }

    if (interestedGroup.includes(key)) {
      // call submit or clear
      const userId = this.state.id;

      if (userId === "") {
        return;
      }

      this.setState(
        {
          id: "",
        },
        () => {
          if (key === "Enter")
            this.props.checkUserMinutes(this.props.eventId, userId);
        }
      );
    } else {
      // prepare number
      this.setState((prevState) => {
        return {
          id: `${prevState.id}${key}`,
        };
      });
    }
  }

  render() {
    const { user, event } = this.props;
    if (isEmpty(event)) {
      return null;
    }
    const minutes =
      event.settings.hasOwnProperty("time") && event.settings.time.minutes
        ? event.settings.time.minutes
        : null;

    const { scanningBanners } = event;

    return (
      <div className="container-fluid tab-content">
        <div className="row">
          <div className="col-sm-6 poster text-center">
            <div>
              <img
                src="/images/minutes-scanning-holder.png"
                alt="holder"
                width="481"
                height="723"
              />
              {scanningBanners && scanningBanners.length > 0 && (
                <div className="scanning-banner">
                  <div className="vertical-center">
                    <Banner
                      bannerUrl={scanningBanners[0].url}
                      alt="Scanning Banner"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 scan-area">
            <div className="vertical-center">
              <div>
                {isEmpty(user) && (
                  <div>
                    <div className="text-container">
                      <h1>
                        <span>Please scan your card</span>to see your
                        participation minutes
                      </h1>
                    </div>
                    <div className="image-holder">
                      <img
                        src="/images/place-card.png"
                        alt="unplaced card"
                        width="223"
                        height="172"
                      />
                    </div>
                  </div>
                )}
                {!isEmpty(user) && (
                  <div className="user-scanned">
                    <div className="text-container">
                      <h1>
                        Hello,{" "}
                        <span>{`${user.info.firstName} ${user.info.lastName}`}</span>
                      </h1>
                      <p className="minutes">
                        You have <span>{user.minutes ? user.minutes : 0}</span>
                        {minutes ? ` of ${minutes}` : ""} minutes
                      </p>
                    </div>
                    <div className="image-holder">
                      <img
                        src="/images/placed-card.png"
                        alt="placed card"
                        width="223"
                        height="172"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MinuteScanning.propTypes = {
  event: PropTypes.object,
  loadEvent: PropTypes.func,
  eventId: PropTypes.string.isRequired,
  checkUserMinutes: PropTypes.func.isRequired,
  scanningBanners: PropTypes.array,
  user: PropTypes.object,
  timeLimit: PropTypes.number,
};
