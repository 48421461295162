import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";
import { updateClientPanelFooterSponsorOrder } from "@actions";
import Checkbox from "@layout/Checkbox";

const ExtraOptions = (props) => {
  const [roundImages, setRoundImages] = React.useState(
    props.event.clientPanelSettings.footer.roundBorders
  );

  return (
    <div className="panel-content-card footer-content">
      <div className="switch-holder">
        <div
          className="check-box"
          onClick={() => {
            setRoundImages(!roundImages);
          }}
        >
          <Checkbox checked={roundImages} />
        </div>
        Enable round footer images
      </div>
      <SubmitContainer
        fetching={props.fetching}
        onSubmit={() => {
          props.updateClientPanelFooterSponsorOrder(props.event.id, {
            footerRoundBorders: roundImages,
          });
        }}
        onCancel={() => {
          setRoundImages(props.event.clientPanelSettings.footer.roundBorders);
        }}
        disabled={
          props.fetching ||
          roundImages === props.event.clientPanelSettings.footer.roundBorders
        }
        cancelDisabled={
          roundImages === props.event.clientPanelSettings.footer.roundBorders
        }
        submitText="Save Changes"
      />
    </div>
  );
};

ExtraOptions.propTypes = {
  updateClientPanelFooterSponsorOrder: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientPanelFooterSponsorOrder: (eventId, data) => {
      return dispatch(updateClientPanelFooterSponsorOrder(eventId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraOptions);
