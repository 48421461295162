import React from "react";
import Select2 from "@layout/Select2";

import PropTypes from "prop-types";

export default class Speaker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode,
      value: props.value || [],
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(val) {
    this.setState({ value: val });
  }

  render() {
    return (
      <div className="speaker-inner">
        <div className={`mode-${this.state.mode}-wrapper speaker-wrapper`}>
          {this.state.mode === "existing" && (
            <div
              className={`form-group${
                this.props.hasErrors ? " has-error" : ""
              }`}
            >
              <Select2
                options={this.props.options}
                onChange={this.onChange}
                value={this.state.value}
                multi={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Speaker.propTypes = {
  mode: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.object.isRequired,
  hasErrors: PropTypes.bool,
};
