import { connect } from "react-redux";
import TotalScans from "./TotalScans";
import { addNotification, loadScans } from "@actions";

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    totalScans: state.api.events.totalScans.data || [],
    meta: state.api.events.totalScans.meta,
    policies: state.api.accesspolicies.list.data || [],
    fetching:
      state.api.events.totalScans.fetching ||
      state.api.accesspolicies.list.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadScans: (eventId, p = 1, endpoint, rpp = 10) => {
      dispatch(loadScans(eventId, p, endpoint, rpp));
    },

    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalScans);
