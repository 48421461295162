import { connect } from "react-redux";
import { getSponsors, deleteSponsor } from "@actions/sponsors";

import Sponsors from "./Sponsors";
import { showModal, hideModal } from "@actions";

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
    sponsorList: state.api.sponsors.data,
    fetching: state.api.sponsors.fetching,
    meta: state.api.sponsors.meta,
    page: state.page,
    rpp: state.api.sponsors.meta && state.api.sponsors.meta.rpp,
    p: state.api.sponsors.meta && state.api.sponsors.meta.p,
    totalRows: state.api.sponsors.meta && state.api.sponsors.meta.totalRows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSponsors: (orgId, meta) => {
      dispatch(getSponsors(orgId, meta));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    deleteSponsor: (orgId, sponsorId) => {
      dispatch(deleteSponsor(orgId, sponsorId));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);
