import React from "react";
import PropTypes from "prop-types";

export default class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.onError = this.onError.bind(this);
    this.state = {
      src: props.bannerUrl ? props.bannerUrl : "/images/medlive-noimage.jpg",
    };
  }

  onError() {
    if (this.props.bannerUrl) {
      this.setState({
        src: "/images/medlive-noimage.jpg",
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      src: nextProps.bannerUrl
        ? nextProps.bannerUrl
        : "/images/medlive-noimage.jpg",
    });
  }

  render() {
    return (
      <img
        onError={this.onError}
        src={this.state.src}
        alt={this.props.bannerAlt}
      />
    );
  }
}

Banner.propTypes = {
  bannerUrl: PropTypes.string,
  bannerAlt: PropTypes.string,
};
