import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import { loadPolicy } from "@actions/users";
import InformationFields from "./InformationFields";
import { savePolicy } from "@actions";
import SubmitContainer from "@layout/SubmitContainer";
import { showModal, hideModal } from "@actions";

export const PolicySettings = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [fields, setFields] = React.useState([]);
  const [initialPolicy, setInitialPolicy] = React.useState();
  const [disableCondition, setDisableCondition] = React.useState(false);
  const checkForErrors = () => {
    const newFields = [...fields];
    let check = true;
    newFields.forEach((field) => {
      if (field.name === "" && !field.deleted) {
        field.error = true;
        check = false;
      }
      if (
        field.askUser &&
        field.type === "dropdown" &&
        field.value?.value?.length === 0
      ) {
        field.error = true;
        check = false;
      }
    });
    /* todo: why? */
    if (!check) setFields(newFields);
    return check;
  };

  const updateFieldsByPolicy = (policy, getValue) => {
    const newFields = [
      ...(props.policy.type === "private"
        ? initFieldsPrivate
        : initFieldsPublic),
    ];

    // fields
    newFields[0].askUser = policy.settings.firstName?.askUser || 0;
    newFields[0].required = policy.settings.firstName?.required || 0;
    newFields[1].askUser = policy.settings.lastName?.askUser || 0;
    newFields[1].required = policy.settings.lastName?.required || 0;
    newFields[2].askUser = policy.settings.email?.askUser || 0;
    newFields[2].required = policy.settings.email?.required || 0;

    // if (policy.type === "private") {
    //   newFields[3].askUser =
    //     policy.extraSettings.speakerChairperson?.askUser || 0;
    //   newFields[3].required =
    //     policy.extraSettings.speakerChairperson?.required || 0;
    //   newFields[4].askUser = policy.extraSettings.sponsored?.askUser || 0;
    //   newFields[4].required = policy.extraSettings.sponsored?.required || 0;
    // }
    Object.keys(policy.extraSettings).map((key) => {
      const disabled = [];
      const { type, sysGenerated, orgCrmSchemaMetaId } =
        policy.extraSettings[key];
      if (orgCrmSchemaMetaId) disabled.push("name");
      else if (type !== "dropdown") disabled.push("value");
      else if (sysGenerated) disabled.push("name");

      newFields.push({
        id: policy.extraSettings[key].id,
        key,
        name: policy.extraSettings[key].name,
        askUser: policy.extraSettings[key].askUser,
        required: policy.extraSettings[key].required,
        pricing: policy.extraSettings[key].pricing,
        type: policy.extraSettings[key].type,
        value: policy.extraSettings[key].value,
        disabled,
        notUsed: disabled,
        index: policy.extraSettings[key].index,
        orgCrmSchemaMetaId,
        conditionSchema: policy.extraSettings[key].conditionSchema,
      });
    });

    const sortByIndex = function sort(a, b) {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    };

    newFields.sort(sortByIndex);
    if (getValue) return newFields;
    setFields(newFields);
  };

  const onChangeFields = (newFields) => {
    setFields(newFields);
  };

  const initialize = () => {
    setLoading(true);
    if (props.policy) {
      props
        .loadPolicy(props.event.id, props.policy.id)
        .then(({ data: policy }) => {
          setInitialPolicy(policy);
          setLoading(false);
          updateFieldsByPolicy(policy);
        });
    }
  };
  React.useEffect(() => {
    initialize();
  }, []);
  React.useEffect(() => {
    initialize();
  }, [props.policy]);
  const saveButton = () => (
    <SubmitContainer
      onCancel={() => {
        setDisableCondition(false);
        updateFieldsByPolicy(initialPolicy);
      }}
      onSubmit={() => {
        const sendFields = JSON.parse(JSON.stringify(fields));

        const usernameSetup = props.policy.settings.username;
        const passwordSetup = props.policy.settings.password;

        let baseIndex = 3; //firstName 0, lastName 1, email 2

        if (usernameSetup) {
          usernameSetup["key"] = "username";
          sendFields.splice(baseIndex, 0, usernameSetup);
          baseIndex++; // username 3
        }

        if (passwordSetup) {
          passwordSetup["key"] = "password";
          sendFields.splice(baseIndex, 0, passwordSetup);
          baseIndex++; // password 4
        }

        const settingsLength = baseIndex;
        const extraSettings = {};
        sendFields
          .slice(settingsLength, sendFields.length)
          .forEach((item, index) => {
            if (
              item.deleted &&
              !props.policy.extraSettings.hasOwnProperty(item.key)
            ) {
              return;
            }
            if (
              item.key === "username" ||
              item.key === "password" ||
              item.deleted
            )
              return;
            item.conditionSchema ??= null;
            if (!item.conditionSchema?.hasOwnProperty("filters"))
              item.conditionSchema = {
                filters: item.conditionSchema,
              };
            extraSettings[item.key] = {
              id: item.id || item.oldDBId,
              orgCrmSchemaMetaId: item.orgCrmSchemaMetaId,
              type: item.type,
              name: item.name,
              askUser: item.askUser,
              required: item.required,
              value: item.value,
              index,
              conditionSchema: JSON.stringify(item.conditionSchema),
              pricing: item.pricing,
            };
          });
        const request = {
          name: props.policy.name,
          type: props.policy.type,
          // base index + 2 for firstname and lastname
          settings: sendFields.slice(0, settingsLength).reduce((prev, next) => {
            return {
              ...prev,
              [next.key]: {
                name: next.name,
                askUser: next.askUser,
                identifier: next.identifier,
                unique:
                  props.policy.type === "private" ? next.key === "email" : 0,
                required: next.required,
                value: next.value,
              },
            };
          }, {}),
          extraSettings,
        };
        if (checkForErrors()) {
          setDisableCondition(false);

          props
            .savePolicy(props.eventId, request, props.policy?.id)
            .then(() => {
              initialize();
            })
            .catch(() => {
              setLoading(false);
            });
        }
      }}
    />
  );

  if (!Object.keys(props.policy || {}).length)
    return (
      <div className="form-container single-policy">
        <div
          style={{
            padding: 50,
            textAlign: "center",
          }}
        >
          {`It looks like you dont have a policy. Please click 'Save' to create one`}
        </div>
        {saveButton()}
      </div>
    );
  if (loading) return <KMBLoader rows={15} padding={24} height={53} />;
  return (
    <div className="form-container single-policy tab-content">
      <InformationFields
        disableCondition={disableCondition}
        updating={loading}
        policy={props.policy}
        isParticipantPolicy={props.policy.type === "private"}
        items={fields}
        onChange={onChangeFields}
        {...props}
      />
      {saveButton()}
    </div>
  );
};

PolicySettings.propTypes = {
  accessPolicies: PropTypes.array,
  policy: PropTypes.object,
  event: PropTypes.object,
  eventId: PropTypes.number,
  savePolicy: PropTypes.func,
  loadPolicy: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    policy: state.api.accesspolicies.selectedPolicy,
  };
};

const mapDispatchToProps = {
  loadPolicy: (eventId, policyId) =>
    loadPolicy(policyId, eventId, { dropdownValuesAsObject: true }),
  savePolicy: (eventId, request, policyId = false) =>
    savePolicy(eventId, request, policyId),
  showModal: (content) => showModal(content),
  hideModal: () => hideModal(),
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicySettings);

const initFieldsPrivate = [
  {
    name: "First Name",
    key: "firstName",
    pricing: 0,
    required: 1,
    identifier: 0,
    unique: 0,
    value: "",
    type: "text",
    askUser: 1,
    disabled: ["askUser", "required", "value", "pricing"],
    notUsed: ["value"],
  },
  {
    name: "Last Name",
    key: "lastName",
    pricing: 0,
    required: 1,
    identifier: 0,
    unique: 0,
    value: "",
    type: "text",
    askUser: 1,
    disabled: ["askUser", "required", "value", "pricing"],
    notUsed: ["value"],
  },
  {
    name: "Email",
    key: "email",
    pricing: 0,
    required: 1,
    identifier: 1,
    unique: 1,
    value: "",
    type: "email",
    askUser: 1,
    disabled: ["askUser", "required", "value", "pricing"],
    notUsed: ["value"],
  },
  // {
  //   name: "Speaker/Chairperson",
  //   key: "speakerChairperson",
  //   pricing: 0,
  //   required: 0,
  //   identifier: 0,
  //   unique: 0,
  //   value: "",
  //   type: "yes_no",
  //   askUser: 0,
  //   disabled: ["askUser", "required", "value", "pricing"],
  //   notUsed: ["value"],
  // },
  // {
  //   name: "Sponsored",
  //   key: "sponsored",
  //   pricing: 0,
  //   required: 0,
  //   identifier: 0,
  //   unique: 0,
  //   value: "",
  //   type: "yes_no",
  //   askUser: 0,
  //   disabled: ["askUser", "required", "value", "pricing"],
  //   notUsed: ["value"],
  // },
];
const initFieldsPublic = [
  {
    name: "First Name",
    key: "firstName",
    pricing: 0,
    required: 1,
    identifier: 0,
    unique: 0,
    value: "",
    type: "text",
    askUser: 1,
    disabled: ["value", "pricing"],
    notUsed: ["value"],
  },
  {
    name: "Last Name",
    key: "lastName",
    pricing: 0,
    required: 1,
    identifier: 0,
    unique: 0,
    value: "",
    type: "text",
    askUser: 1,
    disabled: ["value", "pricing"],
    notUsed: ["value"],
  },
  {
    name: "Email",
    key: "email",
    pricing: 0,
    required: 1,
    identifier: 0,
    unique: 0,
    value: "",
    type: "email",
    askUser: 1,
    disabled: ["value", "pricing"],
    notUsed: ["value"],
  },
];
