/* eslint-disable */
import { isEmpty } from "@helpers";
export const checkIfConditionIsFulfilled = (field, userInfo) => {
  if (!field?.conditionSchema?.filters) return true;
  // or blocks
  return field.conditionSchema.filters
    ?.map((conditionBlock) => {
      // and blocks
      return conditionBlock
        .map((condition) => {
          let userValue = userInfo[condition.key];

          const finalValue = condition.rendered_value || condition.value;
          if (condition.type === "yes_no" && userValue != 0 && userValue != 1)
            userValue = userInfo[condition.key] == "Yes" ? 1 : 0;

          // case when the field is conditionally rendered
          // from fields with logic "field is equal"
          if (condition.operator === "=" && userValue != finalValue)
            return false;

          // from fields with logic "field is not equal"
          if (condition.operator === "<>" && userValue == finalValue)
            return false;

          return true;
        })
        .reduce((acc, curr) => acc && curr, true);
    })
    .reduce((acc, curr) => acc || curr, false);
};
const UsersHelper = {
  adjustFilters: (filters, policy, event) => {
    const newFields = UsersHelper.getPolicyFields(policy, filters);
    const newFilters = Object.assign({}, filters);
    if (!event.settings.time.points) {
      delete newFilters["points"];
      delete newFields["points"];
    }

    return Object.assign({}, newFilters, newFields);
  },
  crmFilters: (filters, extraFilters) => {
    Object.keys(extraFilters).forEach((k) => {
      filters[k] = {
        name: extraFilters[k].name,
        label: extraFilters[k].name,
        filter: false,
      };
    });
    return filters;
  },
  getPolicyFields: (policy, filters) => {
    const form = {};
    const settings = Object.keys(policy.settings || {})
      .sort((a, b) => policy.settings[a].index - policy.settings[b].index)
      .map((key) => ({ key, ...policy.settings[key] }));

    const extraSettings = Object.keys(policy.extraSettings || {})
      .sort(
        (a, b) => policy.extraSettings[b].index - policy.extraSettings[a].index
      )
      .map((key) => ({ key, ...policy.extraSettings[key] }))
      .filter((item) => item.type !== "section");

    const systemExtraSettings = Object.keys(policy.systemExtraSettings || {})
      .sort(
        (a, b) =>
          policy.systemExtraSettings[b].index -
          policy.systemExtraSettings[a].index
      )
      .map((key) => ({ key, ...policy.systemExtraSettings[key] }));

    //add what does not exist in the fields
    form["id"] = {
      name: "ID",
      label: "ID",
      filter: true,
      sortable: true,
    };
    form["active"] = {
      name: "Active",
      label: "Active",
      filter: false,
      sortable: true,
    };
    form["registered"] = {
      name: "Verified",
      label: "Verified",
      filter: true,
      sortable: true,
    };
    form["createdAt"] = {
      name: "Created At",
      label: "Created At",
      filter: true,
      sortable: true,
    };
    form["videoMinutes"] = {
      name: "Online Minutes",
      label: "Online Minutes",
      filter: false,
    };
    form["minutes"] = {
      name: "On-site Minutes",
      label: "On-site Minutes",
      filter: false,
    };
    form["sumMinutes"] = {
      name: "Total Minutes",
      label: "Totlal Minutes",
      filter: false,
    };
    form["points"] = {
      name: "Points",
      label: "Points",
      filter: false,
    };
    form["currentRoom"] = {
      name: "Current Room",
      label: "Current Room",
      filter: false,
      sortable: true,
    };
    const deleted = ["crmUserProfilePhoto", "speaker"];
    settings
      .filter(
        (setting) => !setting.system_gen && deleted.indexOf(setting.key) === -1
      )
      .map((setting) => {
        form[setting.key] = {
          name: setting.name,
          label: setting.name,
          filter: true,
          sortable: true,
          editable: setting.editable !== false,
        };
      });

    extraSettings.map((setting) => {
      form[setting.key] = {
        name: setting.name,
        label: setting.name,
        filter: false,
        sortable: true,
        editable: setting.editable !== false,
      };
    });
    systemExtraSettings.map((setting) => {
      form[setting.key] = {
        name: setting.name,
        label: setting.name,
        filter: false,
        editable: setting.editable !== false,
      };
    });

    const allowed = ["createdAt", "registered", "active"];
    settings
      .filter((setting) => setting.system_gen)
      .map((setting) => {
        if (setting.used == 1) {
          form[setting.key] = {
            name: setting.name,
            label: setting.name,
            filter: allowed.indexOf(setting.key) !== -1,
            sortable: true,
            editable: false,
          };
        }
      });

    form["events"] = {
      name: "Events",
      label: "Events",
      filter: false,
    };

    form["card"] = {
      name: "Card",
      label: "Card",
      filter: false,
    };

    form["certificate"] = {
      name: "Certificate",
      label: "Certificate",
      filter: false,
    };

    form["subscription"] = {
      name: "Subscription",
      label: "Subscription",
      filter: true,
    };
    form["actions"] = {
      name: "",
      label: "",
      filter: true,
    };
    form["active"].filter = false;
    form["createdAt"].filter = true;

    return form;
  },
};

export default UsersHelper;
