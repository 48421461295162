import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateCrmSchema, createCrmSchema } from "@actions/crm";
import { loadCrmSchemas, updateCrmSchemaFields } from "@actions/crm";
import CrmFields from "@layout/CrmFields";
import SubmitContainer from "@layout/SubmitContainer";
const Schema = (props) => {
  const [fields, setFields] = React.useState([]);
  const initializeFields = () => {
    const newFields = [];
    for (const i in props.schemas.orglist.data.meta) {
      newFields.push({
        id: props.schemas.orglist.data.meta[i].id,
        name: props.schemas.orglist.data.meta[i].name,
        value: props.schemas.orglist.data.meta[i].value,
        type: props.schemas.orglist.data.meta[i].type,
        key: i,
        index: props.schemas.orglist.data.meta[i].index,
      });
    }

    setFields([
      ...defaultFields,
      ...newFields.sort((a, b) => a.index - b.index),
    ]);
  };
  React.useEffect(() => {
    props.loadSchemas(props.orgId);
  }, []);

  React.useEffect(() => {
    initializeFields();
  }, [props.schemas.orglist.data.meta]);
  return (
    <div className="form-container">
      <CrmFields
        items={fields}
        onChange={(newItems) => {
          setFields(newItems);
        }}
      />
      <SubmitContainer
        onCancel={initializeFields}
        onSubmit={() => {
          const meta = {};
          fields.slice(4, fields.length).forEach((item, index) => {
            if (item.name.length === 0) throw new Error("Name is required");
            if (
              item.deleted &&
              props.schemas.orglist.data.meta.hasOwnProperty(item.key)
            ) {
              return;
            }
            if (item.deleted) {
              item.name = "";
              delete item.deleted;
            }
            meta[item.key] = {
              ...item,
              index,
            };
          });
          if (props.schemas.orglist.data?.id) {
            return props.updateSchema(
              {
                meta,
              },
              props.orgId,
              props.schemas.orglist.data.id
            );
          }
          return props.createSchema(
            {
              meta,
            },
            props.orgId
          );
        }}
      />
    </div>
  );
};

Schema.propTypes = {
  orgId: PropTypes.number,
  updateSchema: PropTypes.func.isRequired,
  createSchema: PropTypes.func.isRequired,
  schemas: PropTypes.object.isRequired,
  updateFields: PropTypes.func,
  loadSchemas: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    schemas: state.api["schemas"],
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSchema: (_request, orgId, schemaId) => {
      dispatch(updateCrmSchema(_request, orgId, schemaId));
    },
    createSchema: (_request, orgId) => {
      dispatch(createCrmSchema(_request, orgId));
    },
    loadSchemas: (orgId, meta = {}, cache = false) => {
      dispatch(
        loadCrmSchemas(orgId, { ...meta, dropdownValuesAsObject: true }, cache)
      );
    },
    updateFields: (fields, updating) => {
      return dispatch(updateCrmSchemaFields(fields, updating));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schema);

const defaultFields = [
  { name: "Profile photo", key: `firstName`, value: "", type: "text" },
  { name: "First Name", key: `firstName`, value: "", type: "text" },
  { name: "Last Name", key: `lastName`, value: "", type: "text" },
  { name: "Email", key: `email`, value: "", type: "email" },
];
