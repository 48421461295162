import React from "react";
import PropTypes from "prop-types";
import UserNotificationsArea from "./partials/";

export default class UserNotifications extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="notifications-wrapper">
        <div className="vertical-center">
          <div
            onClick={() => {
              this.props.showModal(
                <UserNotificationsArea />,
                this.props.notificationsCount
              );
            }}
          >
            <span className="icon-bell"></span>
            {this.props.notificationsCount > 0 && (
              <span className="notification-count"></span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UserNotifications.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
};
