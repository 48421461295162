import React from "react";
import Form from "@components/form";
import formFields from "@helpers/form-fields";
import { isEmpty } from "@helpers";
import PropTypes from "prop-types";
import Confirm from "@layout/Confirm";
import KMBLoader from "@layout/KMBLoader";
import ApiHelper from "@helpers/api-helper";
import moment from "moment";
import { parse, stringify } from "flatted";

export default class SingleSession extends React.Component {
  constructor(props) {
    super(props);
    ["inject", "prepareSession"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );

    this.mode = isEmpty(props.session) ? "create" : "update";

    formFields.forms.sessions.singleSession["datetimeRange"].min =
      props.event.startDate.tz;
    formFields.forms.sessions.singleSession["datetimeRange"].max =
      props.event.endDate.tz;

    this.state = {
      rooms: props.rooms,
      sessionTypes: props.sessionTypes,
      showConfirm: props.showConfirm || false,
      file: null,
      coordinatorName: null,
      ready: false,
      searchUsers: {},
      session: props.session || {},
      newSession: props.newSession || {},
    };
  }

  inject(type, data) {
    const obj = {};
    for (const d of data) {
      obj[d.id] =
        type === "orgPersonId"
          ? `${d.info.firstName} ${d.info.lastName}`
          : (obj[d.id] = d.name);
    }
    formFields.forms.sessions.singleSession[type].options = obj;
  }

  prepareSession(s) {
    if (s.thirdPartyHosting || s.selfHostingVideo) {
      s["enableVideos"] = 1;
    }
    const stringified = stringify(s);
    let session = parse(stringified);

    const allUsers = {};
    this.props.allPolicyUsers.map((au) => {
      allUsers[au.id] = `${au.info.firstName} ${au.info.lastName}`;
    });
    let { searchUsers } = this.state;
    searchUsers[-1] = "Select";

    formFields.forms.sessions.singleSession["orgPersonId"].options = allUsers;
    formFields.forms.sessions.singleSession["chatSection"].fields[
      "chatCoordinator"
    ].options = searchUsers;
    formFields.forms.sessions.singleSession["chatSection"].fields[
      "chatCoordinator"
    ].callback = (query) => {
      if (query?.length) {
        this.props
          .searchUsers(query, this.props.eventId, this.props.policyIds)
          .then((d) => {
            searchUsers = {};
            d.data.map((au) => {
              searchUsers[
                au.id
              ] = `${au.info.firstName} ${au.info.lastName}, ${au.info.email}`;
            });
            searchUsers[-1] = "Select";
            this.setState({ searchUsers });
          });
      }
    };

    if (this.mode === "create") {
      const rangeFrom = this.state.newSession?.startDate?.split(" ") || [];
      const rangeTo = this.state.newSession?.endDate?.split(" ") || [];
      const eventRoomId = this.state.newSession?.eventRoomId;
      session = Object.assign({}, session, {
        startDate: rangeFrom[0],
        startDateTime: rangeFrom[1]?.slice(0, -3),
        endDate: rangeTo[0],
        endDateTime: rangeTo[1]?.slice(0, -3),
        eventRoomId: String(eventRoomId),
      });
      return session;
    }
    if (isEmpty(session)) return session;

    const rangeFrom = session.startDate.tz.split(" ");
    const rangeTo = session.endDate.tz.split(" ");

    session = Object.assign({}, session, {
      startDate: rangeFrom[0],
      startDateTime: rangeFrom[1].slice(0, -3),
      endDate: rangeTo[0],
      endDateTime: rangeTo[1].slice(0, -3),
      live: [],
      minutesHandler: [],
    });

    if (session.liveStream) {
      session.live.push("liveStream");
    }

    if (session.questions) {
      session.live.push("questions");
    }

    const chairpersons = session.chairpersons.map((s) => s.id.toString());
    session.orgPersonId = chairpersons;

    if (this.state.coordinatorName) {
      session.chatCoordinator = this.state.session.chatCoordinator;
    } else {
      session.chatCoordinator = [];
    }
    return session;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.mode = isEmpty(nextProps.session) ? "create" : "update";

    const newState = {
      showConfirm: nextProps.showConfirm || false,
      file: nextProps.showConfirm ? {} : null,
      session: nextProps.session,
      newSession: nextProps.newSession,
    };

    this.inject("eventRoomId", nextProps.rooms);
    newState.rooms = nextProps.rooms;
    this.inject("orgPersonId", nextProps.allPolicyUsers);

    if (stringify(newState) !== stringify(this.state)) {
      this.setState(newState);
    }
  }

  componentDidMount() {
    this.props.loadRooms(this.props.eventId);

    if (this.state.session.chatCoordinator) {
      this.props
        .getUserById(this.props.eventId, this.state.session.chatCoordinator)
        .then((res) => {
          this.setState({
            ready: true,
            coordinatorName: true,
            searchUsers: {
              [this.state.session.chatCoordinator]:
                res.data.info.firstName +
                " " +
                res.data.info.lastName +
                ", " +
                res.data.info.email,
            },
          });
        });
      return;
    }

    this.setState({ ready: true });
  }

  prepareValue(data) {
    if (!isEmpty(data.errors)) {
      return;
    }
    delete data.value.enableVideos;
    const form = Object.assign({}, data.value);
    // form ok

    form.startDate = moment(
      `${form.datetimeRange.from} ${form.datetimeRange.fromTime}`,
      "DD-MM-Y HH:mm"
    ).format(ApiHelper.dateFormat);
    form.endDate = moment(
      `${form.datetimeRange.to} ${form.datetimeRange.toTime}`,
      "DD-MM-Y HH:mm"
    ).format(ApiHelper.dateFormat);
    delete form.datetimeRange;

    if (form.sessionFiles.length === 0) {
      delete form.sessionFiles;
    }
    if (form.orgPersonId === null) {
      delete form.orgPersonId;
      form["eventUsersIds"] = [];
    }
    if (
      form.chatCoordinator &&
      (form.chatCoordinator == "-1" || form.chatCoordinator.length === 0)
    ) {
      form.chatCoordinator = null;
    } else if (form.chatCoordinator === undefined) {
      form.chatCoordinator = null;
    }

    this.props.saveSession(
      this.props.eventId,
      form,
      this.mode === "create" ? false : this.state.session.id,
      data.type,
      null,
      this.props.meta
    );
  }

  render() {
    const deletefileBody = this.state.showConfirm ? (
      <div>
        <p>
          This action will permanently delete the file with ID:{" "}
          <strong>{this.state.file.id}</strong>
        </p>
        <p>
          <span>{this.state.file.fileName}</span>
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    ) : null;
    const session = this.state.session;

    if (!this.state.ready) return <KMBLoader rows={50} />;

    return (
      <div className="form-container single-session">
        <h2>
          {this.mode === "create"
            ? "Create Session"
            : `Edit "${this.state.session.name}"`}
        </h2>
        <Confirm
          onConfirm={() => {
            this.props.saveSession(
              this.props.eventId,
              { sessionFiles: [this.state.file.id] },
              this.state.session.id,
              "json",
              true,
              this.props.meta
            );
          }}
          body={deletefileBody}
          className="delete-event"
          confirmText="Confirm File Deletion"
          disabled={false}
          visible={this.state.showConfirm}
          title="File Delete Action! Are you sure?"
        ></Confirm>
        <Form
          componentName="sessions"
          formName="singleSession"
          onSubmit={(data) => {
            this.prepareValue(data);
          }}
          value={(() => {
            const theSession = this.prepareSession(session);
            return theSession;
          })()}
          onCancel={() => {
            this.props.hideModal();
          }}
          onChange={(data) => {
            if (
              data.key === "sessionFiles" ||
              data.key === "videoSessionThumbnail"
            ) {
              this.props.saveSession(
                this.props.eventId,
                { [data.key]: [data.value.id] },
                this.state.session.id,
                "json",
                true,
                this.props.meta
              );
            }
          }}
        />
      </div>
    );
  }
}

SingleSession.propTypes = {
  session: PropTypes.object,
  rooms: PropTypes.array.isRequired,
  sessionTypes: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  loadRooms: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  saveSession: PropTypes.func.isRequired,
  mode: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  crmUsers: PropTypes.array,
  policyIds: PropTypes.array,
  showConfirm: PropTypes.bool,
  newSession: PropTypes.object,
  allPolicyUsers: PropTypes.array,
  getUserById: PropTypes.func,
  searchUsers: PropTypes.func,
  meta: PropTypes.object,
};

SingleSession.defaultProps = {
  session: {},
  rooms: [],
  sessionTypes: [],
};
