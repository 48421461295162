import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ExtraOptions from "./partials/ExtraOptions";
import Sponsors from "./partials/Sponsors";
import PropTypes from "prop-types";
import SingleFooterSponsor from "./partials/SingleFooterSponsor";
import { connect } from "react-redux";
import { showModal, createClientPanelFooterSponsor } from "@actions";
const Footer = (props) => {
  const tabs = [
    { key: "sponsors", label: "Footer Sponsors" },
    { key: "options", label: "Extra Options" },
  ];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <React.Fragment>
      <div className="top-menu">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
        {tabs[activeTab].key === "sponsors" && (
          <button
            type="button"
            className="btn create rounded"
            onClick={() =>
              props.showModal(
                <SingleFooterSponsor
                  item={{}}
                  mode="create"
                  onSave={(data) => {
                    return props.createClientPanelFooterSponsor(
                      props.event.id,
                      {
                        ...data,
                        index:
                          props.event.clientPanelSettings.footer.sponsorsSchema
                            .length,
                      }
                    );
                  }}
                />
              )
            }
          >
            Create Footer Sponsor
          </button>
        )}
      </div>
      {tabs[activeTab].key === "sponsors" && <Sponsors />}
      {tabs[activeTab].key === "options" && <ExtraOptions />}
    </React.Fragment>
  );
};

Footer.propTypes = {
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  createClientPanelFooterSponsor: PropTypes.func,
  showModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createClientPanelFooterSponsor: (eventId, data) => {
      return dispatch(createClientPanelFooterSponsor(eventId, data));
    },
    showModal: (modal) => {
      return dispatch(showModal(modal));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
