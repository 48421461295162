import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteGroup, addCondition } from "@actions/policyGroups";
import Condition from "./Condition";

class Block extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.group?.conditions?.map((condition, index) => {
          return (
            <Condition
              operators={this.props.operators}
              key={`condition-${this.props.groupIndex}-${index}`}
              groupIndex={this.props.groupIndex}
              conditionIndex={index}
              condition={condition}
              policy={this.props.policy}
              {...this.props}
              isRemovable={this.props.group?.conditions?.length > 1}
            />
          );
        })}

        <div className="remover">
          <button
            className="btn transparent add"
            onClick={() => this.props.addCondition(this.props.groupIndex)}
          >
            Add Condition
          </button>
          {this.props.isRemovable && (
            <button
              type="button"
              className="btn transparent remove"
              onClick={() => this.props.deleteGroup(this.props.groupIndex)}
            >
              Remove block
            </button>
          )}
        </div>
      </>
    );
  }
}
Block.propTypes = {
  eventId: PropTypes.number,
  policy: PropTypes.any,
  group: PropTypes.object,
  groupIndex: PropTypes.number,
  addGroup: PropTypes.func,
  deleteGroup: PropTypes.func.isRequired,
  addCondition: PropTypes.func.isRequired,
  isRemovable: PropTypes.bool,
  operators: PropTypes.object,
};
Block.defaultProps = {
  group: {
    conditions: [],
  },
};
const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    groups: state.api.policyGroups.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    deleteGroup: (id) => {
      dispatch(deleteGroup(id));
    },
    addCondition: (groupId, key, type, value, label) => {
      dispatch(addCondition(groupId, key, type, value, label));
    },
  };
  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Block);
