import { connect } from "react-redux";
import Pricing from "./Pricing";
import { showModal, deleteItem, addNotification } from "@actions";
import {
  getSubscriptions,
  deleteTypePeriod,
  upsertEventSubscriptions,
} from "@actions/pricing";
import { changeSection, changeTab } from "@actions/editmenu";
import { listGroups } from "@actions/policyGroups";
const mapStateToProps = (state, ownProps) => {
  const billing = state.api.events.edit.data.settings.billing;
  return {
    eventId: Number(ownProps.event.id),
    subscriptions: state.api.subscriptions.list.data,
    fetching:
      state.api.subscriptions.list.fetching &&
      state.api.subscriptions.types.fetching &&
      state.api.subscriptions.periods.fetching,

    policy: state.api.accesspolicies.selectedPolicy,
    periods: state.api.subscriptions.periods.data,
    types: state.api.subscriptions.types.data,
    subscriptionId: ownProps.subscriptionId,
    hasBilling: billing.bank || billing.creditCard || billing.paypal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions: (eventId, action, policyId) =>
      dispatch(getSubscriptions(eventId, "subscriptions", action, policyId)),
    upsertEventSubscriptions: (eventId, data) =>
      dispatch(upsertEventSubscriptions(eventId, data)),
    showModal: (content) =>
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        )
      ),
    listGroups: (eventId, policyId, query) => {
      return dispatch(listGroups(eventId, policyId, query));
    },
    changeSection: (section) => dispatch(changeSection(section)),
    changeTab: (section) => dispatch(changeTab(section)),
    deleteItem: (eventId, subscriptionId) =>
      dispatch(deleteItem({ eventId, subscriptionId }, "subscriptions")),
    deleteTypePeriod: (eventId, id, param) => {
      return dispatch(deleteTypePeriod(eventId, id, param))
        .then(() => {
          return dispatch(getSubscriptions(eventId, undefined, param));
        })
        .then(() => {
          dispatch(addNotification("Delete was successfull", "success"));
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
