const files = {
  list: {
    cover: {
      dimensions: "950 x 560",
    },
    scanningbanner: {
      dimensions: "400 x 540",
    },
  },
  maxFileSize: 2,
  fileName:
    "The filename should be in Latin characters and must not contain spaces or any illegal characters.",
};

export default files;
