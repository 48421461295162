import { api } from "@actions/ajax";
import { getClientPanelSettings } from "@actions";
import { uploadFile } from ".";
export const RECEIVED_SPEAKERS_LIST = "RECEIVED_SPEAKERS_LIST";
export const REQUEST_SPEAKERS_LIST = "REQUEST_SPEAKERS_LIST";

export const loadSpeakers = (eventId, meta = { rpp: 20, p: 1 }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const { rpp, p } = meta;
      const endpoint = "speakers";
      const query = {
        endpoint,
        action: "list",
        params: {
          eventId,
        },
        query: `?rpp=${rpp || 20}&p=${p || 1}`,
      };

      dispatch(api(query))
        .then((speakers) => {
          resolve(speakers);
        })
        .catch((err) => reject(err));
    });
  };
};

export const updateSpeaker = (eventId, speakerId, data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const filesToUpload = {
        speakerThumbnail: Object.assign([], data.speakerThumbnail),
      };
      if (
        data.speakerThumbnail &&
        typeof data.speakerThumbnail[0] !== "number"
      ) {
        data.speakerThumbnail[0] = data.speakerThumbnail[0].name;
      }

      const filesToUploadProps = Object.keys(filesToUpload);
      let hasFiles = false;
      filesToUploadProps.forEach((key) => {
        if (
          filesToUpload[key]?.length > 0 &&
          typeof filesToUpload[key][0] !== "number"
        )
          hasFiles = true;
      });

      const query = {
        endpoint: "speakers",
        action: "update",
        params: {
          eventId,
          speakerId,
        },
        body: {
          data,
        },
      };

      const callback = () => {
        const state = getState();
        const { p = 1, rpp = 20 } = state.api.speakers.meta;
        dispatch(getClientPanelSettings(eventId));
        dispatch(loadSpeakers(eventId, { rpp, p }));
      };

      if (!hasFiles) {
        dispatch(api(query))
          .then(() => {
            const state = getState();
            const { p = 1, rpp = 20 } = state.api.speakers.meta;
            dispatch(getClientPanelSettings(eventId));
            dispatch(loadSpeakers(eventId, { rpp, p }));
          })
          .catch((err) => reject(err));
      } else {
        dispatch(api(query))
          .then(() => {
            dispatch(
              uploadFile(
                eventId,
                "speakerThumbnail",
                filesToUpload.speakerThumbnail[0],
                { speakerId },
                callback,
                null,
                filesToUpload.speakerThumbnail.length
              )
            );
          })
          .catch((err) => reject(err));
      }
    });
  };
};
