import React from "react";
import PropTypes from "prop-types";

class Iban extends React.Component {
  constructor(props) {
    super(props);
    ["onChange", "isValidIBANNumber", "mod97"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );

    this.state = {
      value: props.value ? props.value : "",
      isValid: props.value ? this.isValidIBANNumber(props.value) : true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isValidationActive !== this.props.isValidationActive &&
      this.props.isValidationActive
    ) {
      const isValid = this.isValidIBANNumber(this.state.value);
      this.setState({ isValid });
      this.props.onChange(this.state.value, isValid);
    }
  }

  onChange(e) {
    const { value } = e.target;
    let isValid = true;
    if (this.props.isValidationActive) {
      isValid = this.isValidIBANNumber(value);
    }
    this.setState({
      value,
      isValid: this.props.isValidationActive ? isValid : true,
    });
    this.props.onChange(value, isValid);
  }

  isValidIBANNumber(input) {
    const CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
    };
    const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ""),
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(
      /[A-Z]/g,
      function (letter) {
        return letter.charCodeAt(0) - 55;
      }
    );
    // final check
    return this.mod97(digits) === 1;
  }

  mod97(string) {
    let checksum = string.slice(0, 2),
      fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

  render() {
    const { isValid } = this.state;
    const { isValidationActive } = this.props;
    const className = isValidationActive && !isValid ? " has-error" : "";

    return (
      <div className={className}>
        <div>
          <input
            ref={this.props.name}
            type={"text"}
            className={`form-control ${this.props.inputClass}`}
            name={this.props.name}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : "Enter a valid IBAN"
            }
            id={this.props.id}
            value={this.state.value}
            onChange={this.onChange}
          />
          {isValidationActive && !isValid ? (
            <div className="help-block">Invalid IBAN</div>
          ) : null}
        </div>
      </div>
    );
  }
}

Iban.propTypes = {
  value: PropTypes.string,
  inputClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isValidationActive: PropTypes.bool,
};

Iban.defaultProps = {
  onChange: () => {},
  inputClass: "",
  isValidationActive: true,
};
export default Iban;
