import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import formFields from "@helpers/form-fields";

const yesNoKeys = [];

const SingleUser = (props) => {
  const [values, setValues] = React.useState();
  const [initialized, setInitialized] = React.useState(false);
  React.useEffect(() => {
    if (props.endpoint === "schemas") {
      const form = formFields.forms["schemas"].single;

      form["email"] = {
        id: "email",
        name: "email",
        type: "text",
        required: true,
        label: "Email",
      };

      form["speaker"] = {
        id: "speaker",
        name: "speaker",
        type: "radio",
        required: false,
        label: "Speaker",
        options: { 1: "Yes", 0: "No" },
      };

      yesNoKeys.push("speaker");
      // We add all the yesNo options in this array, since speaker always exist we
      // hardcode it.
      Object.entries(props.schema.data.meta).forEach(([key, obj]) => {
        const options = {};
        if (obj.type === "dropdown") {
          obj.value.forEach((value) => {
            options[value] = value;
          });
        } else if (obj.type === "yes_no") {
          yesNoKeys.push(key);
          options["yes"] = "Yes";
          options["no"] = "No";
        }

        form[key] = {
          id: obj.id,
          name: key,
          type:
            obj.type === "dropdown"
              ? "select"
              : obj.type === "yes_no"
              ? "radio"
              : obj.type,
          required: obj.required === 1 ? true : false,
          label: obj.name,
          options,
        };
      });
      form["crmUserProfilePhoto"] = {
        id: "crmUserProfilePhoto",
        name: "crmUserProfilePhoto",
        type: "dropzone",
        required: false,
        label: "Profile Photo",
        styleType: "simple",
        multiple: true,
      };
    }
    const newValues = JSON.parse(JSON.stringify(props.value));
    const schema = props.schema.data.meta;
    Object.keys(schema).forEach((key) => {
      if (schema[key].type === "yes_no") {
        if (newValues[key] === "1") {
          newValues[key] = "yes";
        } else {
          newValues[key] = "no";
        }
      }
    });
    if (newValues.speaker === 1) {
      newValues.speaker = "1";
    } else {
      newValues.speaker = "0";
    }
    setValues(newValues);
    setInitialized(true);
  }, []);

  if (!initialized) return <div />;
  return (
    <div className="new-staff form-container">
      {Object.keys(props.value || {}).length > 0 ? (
        <h2>Edit Customer</h2>
      ) : (
        <h2>Create Customer</h2>
      )}
      <Form
        componentName={"schemas"}
        formName="single"
        value={props.value ? values : {}}
        onChange={(data) => {
          if (data.key === "crmUserProfilePhoto") {
            const photoId = data.value.id;
            props.save(
              props.orgId,
              { crmUserProfilePhoto: [photoId] },
              isEmpty(props.value) ? false : props.value.id,
              data.type,
              props.endpoint,
              true
            );
          }
        }}
        onSubmit={(data) => {
          if (isEmpty(data.errors)) {
            Object.entries(data.value).map(([k, v]) => {
              if (yesNoKeys.indexOf(k) !== -1) {
                if (v === "yes") {
                  data.value[k] = true;
                } else if (v === "no") {
                  data.value[k] = false;
                }
              }
              if (data.value[k] === "") {
                delete data.value[k];
              }
            });

            if (
              data.type === "json" &&
              data.value.hasOwnProperty("personThumbnail")
            ) {
              delete data.value.personThumbnail;
            }

            props.save(
              props.orgId,
              data.value,
              isEmpty(props.value) ? false : props.value.id,
              data.type,
              props.endpoint
            );
          }
        }}
        onCancel={props.hideModal}
      />
    </div>
  );
};

SingleUser.propTypes = {
  endpoint: PropTypes.string.isRequired,
  h2: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  value: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
};

SingleUser.defaultProps = {
  value: {},
};

export default SingleUser;
