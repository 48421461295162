/* eslint-disable */
import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { changePassword } from "@actions/appuser";
import { connect } from "react-redux";
import { useAuth } from "../../routers/partials/authProvider";
import Input from "@layout/Input";
import Logo from "../../icons/Logo";
import { useNavigate } from "react-router-dom";

function isStrongPassword(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

function ChangePasswordPage(props) {
  const [showPasswordEnabled, setShowPasswordEnabled] = useState(false);
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordIsStrongEnough, setPasswordIsStrongEnough] = useState(false);
  const [newPasswordIsSet, setNewPasswordIsSet] = useState(false);
  const navigate = useNavigate();
  const {token} = useParams();
  const handleChangePassword = (e) => {
    e.preventDefault();
    return props
      .changePassword({ token, password })
      .then(({ meta }) => {
       setNewPasswordIsSet(true);
      })
      .catch((e) => {
        setNewPasswordIsSet(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <div className="login-page">
      <div className="left">
        <img className="main-image" src="/images/bluetree-with-idcard.png" />
        <img className="background-image" src="/images/login-background.png" />
        <div className="overlay-color"></div>
        <div className="text-wrapper">
          <Logo />
          <span>Event</span>
          <span>Management</span>
          <span>Platform</span>
        </div>
      </div>
      <div className="right" onSubmit={handleChangePassword}>
        <div>
          <h2>
            Welcome to <span>BlueTree</span>
          </h2>
          <p>
            BlueTree is a web platform made for event organizers, to help them
            manage their events and tasks.
          </p>
          {
            !newPasswordIsSet ?
            <>
              <h3>Please type your password</h3>
              <form className="form-container">
                <div className="form-group">
                  <label>Password</label>
                  <Input
                    onChange={(e) => {
                      setPasswordIsStrongEnough(isStrongPassword(e.target.value));
                      setPassword(e.target.value)}}
                    type={showPasswordEnabled ? "text" : "password"}
                    name="password"
                    required
                    placeholder="********"
                  />
                  <p class="password-hint">Your password must be at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character.</p>

                  <button type="button" className="btn transparent-light change-password"
                  onClick={() => setShowPasswordEnabled(!showPasswordEnabled)}>
                    {showPasswordEnabled ? "hide password" : "show password"}
                  </button>
                </div>
                {errorMessage.length > 0 && (
                  <span className="error">{errorMessage}</span>
                )}
                <button className="btn save" type="submit" disabled = {
                  !passwordIsStrongEnough
                }>
                  {passwordIsStrongEnough ? "Change Password" : "Password is not strong enough"}
                </button>
              </form>
            </> :
            <div>
              <h3>Password changed successfully!</h3>
              <p>
                You can now login with your new password.
              </p>
            </div>
          }
          <br />
          <button className="btn transparent-light" type="button" onClick={()=>{
              return navigate("/login")
            }}>
              <span class="fa fa-angle-left" style={{marginTop: "-4px", marginRight:"5px"}}></span>
             Go to Login
            </button>
        </div>
      </div>
    </div>
  );
}
export default connect(null, { changePassword })(ChangePasswordPage);