import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import KMBEditor from "@layout/KMBEditor";
import Switch from "@layout/Switch";
import KMBDropzone from "@layout/KMBDropzone";
import Banner from "@layout/Banner";
import { updateEvent } from "@actions";
import Select from "@layout/Select2";
import Input from "@layout/Input";
import SubmitContainer from "@layout/SubmitContainer";
class AdminEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      senderEmal: "",
      senderDomain: "",
      emailValue: this.props.event.supportEmail,
      emailHeaderBanners: this.props.event.emailHeaderBanners,
      imageChanged: false,
      settings: {
        hasHeader: this.props.event.emailHeader === 1 ? 1 : 0,
        hasFooter: this.props.event.emailFooter === 1 ? 1 : 0,
        hasSignature: this.props.event.emailSignature === 1 ? 1 : 0,
      },
      signature: this.props.event.emailSignatureText || "",
      footer: this.props.event.emailFooterText || "",
      emailOptions: {},
    };
  }
  componentDidMount() {
    const emailOptions = {};
    this.props.event.defaultEmailSenderOptions.map((option) => {
      emailOptions[option] = option;
    });
    const splittedDefaultEmail = this.props.event.defaultEmailSender.split("@");
    this.setState({
      senderEmail: splittedDefaultEmail[0],
      senderDomain: splittedDefaultEmail[1],
      emailOptions,
    });
  }
  prepareData() {
    const signature = this.state.signature;
    const footer = this.state.footer;
    const supportEmail = this.state.emailValue;

    const data = {
      defaultEmailSender:
        this.state.senderEmail + "@" + this.state.senderDomain,
      supportEmail: supportEmail,
      emailHeader: Boolean(this.state.settings.hasHeader),
      emailFooterText: footer,
      emailFooter: Boolean(this.state.settings.hasFooter),
      emailSignatureText: signature,
      emailSignature: Boolean(this.state.settings.hasSignature),
    };
    if (this.state.imageChanged) {
      if (!this.state.emailHeaderBanners)
        data["emailHeaderBanners"] = [
          this.props.event.emailHeaderBanners[0].id,
        ];
      else data["emailHeaderBanners"] = this.state.emailHeaderBanners;
    }

    this.props.updateEventDetails(this.props.event.id, data);
  }
  render() {
    return (
      <div className="tab-content form-container email-settings">
        <div className="form-group">
          <label className="email-label">Default Sender Email</label>
          <div className="email-sender">
            <div className="email-name">
              <Input
                style={{ borderRadius: 0 }}
                value={this.state.senderEmail}
                onChange={(e) => this.setState({ senderEmail: e.target.value })}
                placeholder=""
              />
            </div>
            <span className="at-container">@</span>
            <div className="email-domain">
              <Select
                isSearchable={false}
                isClearable={false}
                options={this.state.emailOptions}
                value={this.state.emailOptions[this.state.senderDomain]}
                onChange={(senderDomain) => this.setState({ senderDomain })}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="header-switch">
            <Switch
              id={`display-sponsor-types`}
              label="Use a header"
              isActive={this.state.settings.hasHeader}
              onChange={(value) => {
                this.setState({
                  settings: Object.assign({}, this.state.settings, {
                    hasHeader: value,
                  }),
                });
              }}
            />
            <div className="header-switch-title">Use a header</div>
          </div>
          {this.state.settings.hasHeader !== 0 && (
            <React.Fragment>
              {this.state.emailHeaderBanners &&
                this.state.emailHeaderBanners.length > 0 && (
                  <div className="banner-container">
                    <div className="banner-wrapper">
                      {this.state.emailHeaderBanners[0].url ? (
                        <Banner
                          bannerUrl={this.state.emailHeaderBanners[0].url}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(
                            this.state.emailHeaderBanners[0]
                          )}
                        />
                      )}
                      <div className="actions">
                        <div className="vertical-center">
                          <span
                            className="icon-delete"
                            onClick={() =>
                              this.setState({
                                emailHeaderBanners: null,
                                imageChanged: true,
                              })
                            }
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <KMBDropzone
                styleType="simple"
                max={1}
                onDropAccepted={(emailHeaderBanners) => {
                  this.setState({
                    emailHeaderBanners,
                    imageChanged: true,
                  });
                }}
              />
            </React.Fragment>
          )}
        </div>
        <div className="form-group">
          <div className="header-switch">
            <Switch
              id="hasFooter"
              label="Use a header"
              isActive={this.state.settings.hasFooter}
              onChange={(e) => {
                this.setState({
                  settings: Object.assign({}, this.state.settings, {
                    hasFooter: e,
                  }),
                });
              }}
            />
            <div className="header-switch-title">Use a footer</div>
          </div>
          {this.state.settings.hasFooter !== 0 && (
            <>
              <label>Support email Footer </label>
              <KMBEditor
                height={200}
                placeholder={`Type the content of the footer you are going to use...`}
                rows={50}
                value={this.state.footer}
                onChange={(data) => this.setState({ footer: data })}
              />
            </>
          )}
        </div>

        <div className="form-group">
          <div className="header-switch">
            <Switch
              id="hasSignature"
              label="Use a signature"
              isActive={this.state.settings.hasSignature}
              onChange={(value) => {
                this.setState({
                  settings: Object.assign({}, this.state.settings, {
                    hasSignature: value,
                  }),
                });
              }}
            />
            <div className="header-switch-title">Use a signature</div>
          </div>
          {this.state.settings.hasSignature !== 0 && (
            <>
              <label>Support email Signature </label>
              <KMBEditor
                height={200}
                placeholder={`Type the content of the signature you are going to use....`}
                rows={50}
                value={this.state.signature}
                onChange={(data) => this.setState({ signature: data })}
              />
            </>
          )}
        </div>
        <SubmitContainer onSubmit={() => this.prepareData()} />
      </div>
    );
  }
}

AdminEmail.propTypes = {
  event: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
  emailHeaderBanners: PropTypes.array,
  updateEventDetails: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateEventDetails: (eventId, data) => {
      dispatch(updateEvent(eventId, data));
    },
  };
};

export default connect(null, mapDispatchToProps)(AdminEmail);
