import { connect } from "react-redux";
import Sessions from "./Sessions";
import { loadSessions, saveSession, saveSpeech } from "@actions/sessions";
import { loadUsers, loadRooms } from "@actions/users";
import {
  showModal,
  hideModal,
  deleteItem,
  updateEvent,
  loadPolicies,
} from "@actions";
import { getAbstracts } from "@actions/abstracts";

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.event.id,
    event: ownProps.event,
    sessions: state.api.sessions.list.data,
    fetching:
      state.api.sessions.list.fetching ||
      state.api.sessions.edit.fetching ||
      state.api.sessions.create.fetching ||
      state.api.speeches.edit.fetching ||
      state.api.speeches.create.fetching ||
      state.api.accesspolicies.list.fetching,
    timestamp: state.api.sessions.list.timestamp,
    meta: state.api.sessions.list.meta,
    publishSchedule: state.api.events.edit.data.publishSchedule,
    publishVideos: state.api.events.edit.data.publishVideos,
    rooms: state.rooms.data,
    allPolicyUsers: state.users.allPolicyUsers.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadSessions: (eventId, meta) => {
      dispatch(loadSessions(eventId, meta));
    },
    getAbstracts: () => {
      dispatch(getAbstracts({ rpp: -1 }));
    },
    loadRooms: (eventId) => {
      dispatch(loadRooms(eventId));
    },
    loadPolicies: (eventId) => {
      return dispatch(loadPolicies(eventId));
    },
    loadPoliciesCrmUsers: (eventId) =>
      dispatch(
        loadUsers(
          eventId,
          null,
          { rpp: -1, speaker: true },
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          true
        )
      ),
    hideModal: () => dispatch(hideModal()),
    showModal: (content, onHide = undefined) => {
      dispatch(
        showModal(
          content,
          undefined,
          onHide,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    updateEvent: (eventId, data) => {
      dispatch(updateEvent(eventId, data));
    },
    deleteItem: (eventId, sessionId, speechId = false) => {
      if (speechId) {
        dispatch(deleteItem({ eventId, sessionId, speechId }, "speeches"));
      } else {
        dispatch(deleteItem({ eventId, sessionId }, "sessions"));
      }
    },

    saveSpeech: (
      eventId,
      sessionId,
      data,
      speechId = false,
      type = "json",
      deleteFile = false,
      quickEdit,
      meta
    ) => {
      return dispatch(
        saveSpeech(
          eventId,
          sessionId,
          data,
          speechId,
          type,
          deleteFile,
          quickEdit,
          meta
        )
      );
    },

    saveSession: (eventId, data, sessionId) => {
      dispatch(saveSession(eventId, data, sessionId, "json", false));
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
