import React from "react";
import Input from "./Input";
import PropTypes from "prop-types";

const NumberInput = (props) => {
  const sanitizeValue = (value) => {
    value = value.replaceAll("-", "");
    value = value.replaceAll("+", "");
    value = value.replaceAll("e", "");
    value = value.replaceAll("E", "");
    return value;
  };

  return (
    <Input
      {...props}
      type={props.inputType ? props.inputType : "number"}
      className={props.className}
      onChange={(e) => {
        let value = sanitizeValue(e.target.value);
        value = parseFloat(value);
        if (isFinite(props.max) && value > props.max) value = props.max;
        else if (isFinite(props.min) && value < props.min) value = props.min;
        props.onChange(value);
      }}
    />
  );
};

NumberInput.propTypes = {
  onChange: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  className: PropTypes.string,
  inputType: PropTypes.string,
};

export default NumberInput;
