import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { useDispatch, useSelector } from "react-redux";
import TableActions from "@layout/TableActions";
import KMBLoader from "@layout/KMBLoader";
import { showModal } from "@actions";
import moment from "moment";
import ApiHelper from "@helpers/api-helper";
import SingleRentData from "./partials/SingleRentData";
import Breadcrumbs from "@layout/Breadcrumbs";
import Search from "@layout/Search";
import { HeaderPagination } from "../../../../layout/Pagination";
import { loadRentData, deleteRentData } from "@actions/rentables";

const RentData = ({
  eventId,
  rentableId,
  productName,
  goBack,
  rentableGroupId,
}) => {
  const dispatch = useDispatch();

  const rentData = useSelector(
    (state) => state?.api?.rentables?.listRentData?.data
  );
  const rentDataReady = useSelector(
    (state) => state?.api?.rentables?.listRentData?.ready
  );
  const p = useSelector(
    (state) => state?.api?.rentables?.listRentData?.meta?.p
  );
  const rpp = useSelector(
    (state) => state?.api?.rentables?.listRentData?.meta?.rpp
  );
  const totalRows = useSelector(
    (state) => state?.api?.rentables?.listRentData?.meta?.totalRows
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(loadRentData(eventId, rentableId, { p, rpp, search }));
  }, [dispatch, eventId, rentableId, p, rpp, search]);

  const onChangePage = (data) => {
    dispatch(
      loadRentData(eventId, rentableId, { search, p: data.p, rpp: data.rpp })
    );
  };

  const onRender = (col, item) => {
    switch (col.key) {
      case "rentedAtDate": {
        return item.rentedAtDate
          ? moment(item.rentedAtDate).format(ApiHelper.dateFormat)
          : "-";
      }
      case "returnedAtDate": {
        return item.returnedAtDate
          ? moment(item.returnedAtDate).format(ApiHelper.dateFormat)
          : "-";
      }
      case "isLost": {
        return item.isLost ? "Yes" : "No";
      }
      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "updatedAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onDelete={() =>
              dispatch(deleteRentData(eventId, rentableId, item.id))
            }
            onEdit={() =>
              dispatch(
                showModal(
                  <SingleRentData
                    h2={"Edit"}
                    eventId={eventId}
                    rentableGroupId={rentableGroupId}
                    rentableId={rentableId}
                    item={item}
                  />,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              )
            }
          />
        );
      }
    }
  };

  return (
    <>
      <div className="tab-header">
        <div className="left">
          <Search
            placeholder="Search..."
            onSearch={(search) => {
              setSearch(search);
              return dispatch(
                loadRentData(eventId, rentableId, { search, rpp: 20, p: 1 })
              );
            }}
            visible={true}
            timeout={400}
          />
        </div>
        <div className="right">
          <HeaderPagination
            fetching={!rentDataReady}
            p={p}
            rpp={rpp}
            totalRows={totalRows}
            onChangePage={onChangePage}
          />
          <button
            type="button"
            className="btn create rounded"
            onClick={() =>
              dispatch(
                showModal(
                  <SingleRentData
                    rentableGroupId={rentableGroupId}
                    h2={"Create"}
                    eventId={eventId}
                    rentableId={rentableId}
                  />,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              )
            }
          >
            Create
          </button>
        </div>
      </div>

      {!rentDataReady ? (
        <KMBLoader rows={15} padding={24} height={53} />
      ) : (
        <div className="tab-content">
          <Breadcrumbs
            options={[
              { name: "Rentables List", action: goBack },
              { name: productName },
              { name: "Rent Data" },
            ]}
          />

          <Table
            columns={{
              id: {
                name: "ID",
              },
              eventUserId: {
                name: "Participant ID",
              },
              eventUserFullName: {
                name: "Participant Name",
              },
              createdBy: {
                name: "Created By",
              },
              rentedAtDate: {
                name: "Rented At",
              },
              returnedAtDate: {
                name: "Returned At",
              },
              isLost: {
                name: "Is Lost",
              },
              createdAt: {
                name: "Created At",
              },
              actions: {
                name: "",
              },
            }}
            filtered={search}
            updating={false}
            items={rentData}
            onRender={onRender}
          />
        </div>
      )}
    </>
  );
};

RentData.propTypes = {
  eventId: PropTypes.number.isRequired,
  rentableGroupId: PropTypes.number.isRequired,
  rentableId: PropTypes.number.isRequired,
  targetUser: PropTypes.number,
  productName: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default RentData;
