import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { isEmpty } from "@helpers";

export default class SelectDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getPrompt = this.getPrompt.bind(this);
    this.from = this.to = null;
    this.excludedArea = null;
    this.handleBody = this.handleBody.bind(this);

    this.state = {
      toggled: false,
      range: {
        from: null,
        to: null,
      },
    };
  }

  toggleSelect() {
    this.setState((prevState) => {
      return {
        toggled: !prevState.toggled,
      };
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {};

    if (nextProps.forceClose) {
      newState.toggled = false;
    }

    if (nextProps.reset) {
      newState.range = {
        from: null,
        to: null,
      };
      this.from = null;
      this.to = null;
    }

    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }

  getPrompt() {
    if (this.state.range.from || this.state.range.to) {
      let placeholder;
      placeholder = this.state.range.from ? `${this.state.range.from}` : "...";
      placeholder += this.state.range.to
        ? ` - ${this.state.range.to} `
        : " - ...";
      return placeholder;
    } else {
      return this.props.placeholder;
    }
  }

  onChange(moment, instance) {
    const range = {
      from: this.from.state.selectedDate
        ? this.from.state.selectedDate.format("DD-MM-YYYY")
        : null,
      to: this.to.state.selectedDate
        ? this.to.state.selectedDate.format("DD-MM-YYYY")
        : null,
    };

    if (moment) {
      range[instance] = moment.format("DD-MM-YYYY");
    }

    this.setState({ range }, () => this.props.onChange(range));
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleBody);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBody);
  }

  handleBody(e) {
    if (!this.excludedArea.contains(e.target)) {
      this.setState({
        toggled: false,
      });
    }
  }

  render() {
    const className = `bottom-area type-range${
      this.state.toggled ? "" : " hidden"
    }`;

    return (
      <div
        className="select-advanced date-range"
        ref={(ref) => (this.excludedArea = ref)}
      >
        <div className="select-advanced-outer">
          <div className="upper-area" onClick={this.toggleSelect}>
            <span>{this.getPrompt()}</span>
            <span className="fa fa-angle-down directional"></span>
          </div>
          <div className={className}>
            <div className="range-wrapper">
              <Datetime
                ref={(ref) => (this.from = ref)}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: "From", readOnly: true }}
                closeOnSelect={true}
                value={this.state.range.from}
                onChange={(moment) => this.onChange(moment, "from")}
                isValidDate={(current) =>
                  this.to && this.to.state.selectedDate
                    ? current.isBefore(this.to.state.selectedDate)
                    : true
                }
              />
              <Datetime
                ref={(ref) => (this.to = ref)}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: "To", readOnly: true }}
                closeOnSelect={true}
                value={this.state.range.to}
                onChange={(moment) => this.onChange(moment, "to")}
                isValidDate={(current) =>
                  this.from && this.from.state.selectedDate
                    ? current.isAfter(this.from.state.selectedDate)
                    : true
                }
              />
              <span onClick={this.props.onReset}>Reset</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectDateRange.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  forceClose: PropTypes.bool,
  reset: PropTypes.bool,
};

SelectDateRange.defaultProps = {
  placeholder: "Select",
  onChange: () => {},
  onReset: () => {},
  forceClose: false,
};
