import { connect } from "react-redux";
import Subscriptions from "./Subscriptions";
import { showModal, deleteItem, addNotification } from "@actions";
import { getSubscriptions, deleteTypePeriod } from "@actions/pricing";

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.event.id,
    subscriptions: state.api.subscriptions.list.data,
    fetching:
      state.api.subscriptions.list.fetching &&
      state.api.subscriptions.types.fetching &&
      state.api.subscriptions.periods.fetching,

    periods: state.api.subscriptions.periods.data,
    types: state.api.subscriptions.types.data,
    subscriptionId: ownProps.subscriptionId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions: (eventId, action, policyId) => {
      dispatch(getSubscriptions(eventId, undefined, action, policyId));
    },

    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );
    },
    deleteItem: (eventId, subscriptionId) => {
      dispatch(deleteItem({ eventId, subscriptionId }, "subscriptions"));
    },
    deleteTypePeriod: (eventId, id, param) => {
      return dispatch(deleteTypePeriod(eventId, id, param))
        .then(() => {
          return dispatch(getSubscriptions(eventId, undefined, param));
        })
        .then(() => {
          dispatch(addNotification("Delete was successfull", "success"));
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
