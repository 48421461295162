import { connect } from "react-redux";
import { loadEvent, changeEvent } from "@actions";
import EditEvent from "./EditEvent";

const mapStateToProps = (state, ownProps) => {
  const eventEdit = state.api.events.edit;
  return {
    eventId: ownProps.eventId,
    data: eventEdit.data,
    fetching: eventEdit.fetching,
    doingAjax: state.api.doingAjax,
    user: state.appuser.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => {
      dispatch(loadEvent(eventId));
    },

    changeEvent: (eventId) => {
      dispatch(changeEvent(eventId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
