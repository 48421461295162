/* eslint-disable */
import React from "react";
import Table from "@layout/Table";
import PropTypes from "prop-types";
import Search from "@layout/Search";
import TableActions from "@layout/TableActions";
import Switch from "@layout/Switch";
import SingleStaff from "./partials/SingleStaff/";
import { capitalizeFirstLetter, isEmpty } from "@helpers";
import ApiHelper from "@helpers/api-helper";
import moment from "moment";
import { HeaderPagination } from "@layout/Pagination";
import { Link } from "react-router-dom";

export default class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.fetched = false;
    this.name = capitalizeFirstLetter(props.mode);
    ["onRender", "onSort"].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    this.props.loadData(this.props.orgId, this.props.mode);
  }
  onSort(orderBy) {
    this.props.loadData(this.props.orgId, this.props.mode, { orderBy }, false);
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            {isEmpty(item.personThumbnail) ? (
              <span className="icon-users"></span>
            ) : (
              <span className="person-thumbnail">
                <img
                  src={
                    item.personThumbnail.url
                      ? item.personThumbnail.url
                      : "/images/medlive-noimage.jpg"
                  }
                  alt={`${item.firstName} ${item.lastName}`}
                />
              </span>
            )}
          </div>
        );
      }

      case "active": {
        return (
          <Switch
            id={`staff-active-${item.id}`}
            isActive={col.value}
            onChange={(active) =>
              this.props.save(
                this.props.orgId,
                { active },
                item.id,
                "json",
                this.props.mode,
                true
              )
            }
          />
        );
      }

      case "assignRoles": {
        return (
          <div
            className="btn transparent table-button"
            onClick={() =>
              this.props?.page.navigate(`/administrator/staff/${item.id}/roles`)
            }
          >
            <span className="icon-manage-users"></span>Assign Roles
          </div>
        );
      }

      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }

      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SingleStaff
                  endpoint={this.props.mode}
                  h2={this.name === "Persons" ? "Person" : this.name}
                  orgId={this.props.orgId}
                  id={item.id}
                  value={item}
                  hideModal={this.props.hideModal}
                />
              )
            }
            onDelete={() =>
              this.props.deleteItem(this.props.orgId, item.id, this.props.mode)
            }
          />
        );
      }
    }
  }

  render() {
    const columns = {
      id: {
        name: "ID",
        sortable: true,
      },
      firstName: {
        name: "First Name",
        sortable: true,
      },
      lastName: {
        name: "Last Name",
        sortable: true,
      },
      email: {
        name: "Email",
        sortable: true,
      },
      phoneNumber: {
        name: "Phone",
      },
      company: {
        name: "Company",
        sortable: true,
      },
      createdAt: {
        name: "Created At",
        sortable: true,
      },
      assignRoles: {
        name: "",
      },
      active: {
        name: "Active",
        sortable: true,
      },
      actions: {
        name: "",
      },
    };

    const { data, activeSort, mode, meta } = this.props;
    const name = this.name === "Persons" ? "Person" : this.name;
    const { p, rpp, totalRows } = meta;

    if (mode === "persons") {
      delete columns["phoneNumber"];
      delete columns["assignRoles"];
    } else {
      delete columns["company"];
    }

    if (columns.hasOwnProperty(activeSort)) {
      columns[activeSort]["activeSort"] = true;
    }

    return (
      <div className="list-staff-main">
        <div className="top-bar">
          <div>
            <div className="right"></div>
          </div>
          <div>
            <div className="left">
              <Search
                onSearch={(search) =>
                  this.props.loadData(
                    this.props.orgId,
                    this.props.mode,
                    { search },
                    false
                  )
                }
                visible={true}
                timeout={400}
              />
            </div>
            <div className="right">
              <HeaderPagination
                p={p}
                rpp={rpp}
                totalRows={totalRows}
                onChangePage={(data) =>
                  this.props.loadData(
                    this.props.orgId,
                    this.props.mode,
                    { p: data.p, rpp: data.rpp },
                    false
                  )
                }
              />
              <div className="create-staff-user">
                <button
                  className="btn create"
                  onClick={() =>
                    this.props.showModal(
                      <SingleStaff
                        endpoint={this.props.mode}
                        h2={name}
                        orgId={this.props.orgId}
                        id={false}
                      />
                    )
                  }
                >
                  Create new Staff
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="list-view">
          <div className="main-wrapper">
            <Table
              id={"event-list"}
              items={data}
              columns={columns}
              onRender={this.onRender}
              onSort={this.onSort}
              updating={!this.props.dataReady || this.props.fetching}
              containerClassName={`table-container `}
            />
          </div>
        </div>
      </div>
    );
  }
}

Staff.propTypes = {
  orgId: PropTypes.number,
  data: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  dataReady: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  activeSort: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};
