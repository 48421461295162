import { connect } from "react-redux";
import SingleSession from "./SingleSession";
import { hideModal } from "@actions";
import { saveSession } from "@actions/sessions";
import { loadRooms, loadSeachUsers } from "@actions/users";
import { getUserById } from "@actions/reservations";

const mapStateToProps = (state, ownProps) => {
  return {
    policyIds: state.api.accesspolicies.list.data
      .filter((p) => p.type !== "public")
      .map((p) => p.id),
    rooms: state.rooms.data,
    event: state.api.events.edit.data,
    eventId: state.page.params.eventId,
    crmUsers: state.users.data,
    sessionTypes: state.api.sessions.sessionTypes.data,
    sessionId: ownProps.sessionId,
    allPolicyUsers: state.users.allPolicyUsers.data,
    session: [...state.api.sessions.list.data].filter(
      (s) => s.id === ownProps.sessionId
    )[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadRooms: (eventId) => {
      dispatch(loadRooms(eventId));
    },

    hideModal: () => {
      dispatch(hideModal());
    },
    getUserById: (eventId, userId) => {
      return dispatch(getUserById(eventId, userId));
    },
    saveSession: (
      eventId,
      data,
      sessionId = false,
      type,
      deleteFile = false,
      meta
    ) => {
      dispatch(saveSession(eventId, data, sessionId, type, deleteFile, meta));
    },
    searchUsers: (queryString, eventId, policyIds) => {
      return dispatch(
        loadSeachUsers(eventId, null, {
          search: queryString,
          policyIds: policyIds.join(","),
        })
      );
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSession);
