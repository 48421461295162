import React from "react";
import btn from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

import { makeStyles, withStyles, emphasize } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { DropzoneArea } from "material-ui-dropzone";

const defaultColor = "#2a79d1";
const disabledColor = "#ddd";

export const KMBDropzoneArea = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      height: "100%",
      width: "100%",
      "& *": {
        color: "white",
      },
      "& .MuiDropzoneArea-root": {
        width: "100% !important",
        height: "100% !important",
        minHeight: "100% !important",
        background: "transparent",
        color: "white",
        border: "none",
        backgroundImage:
          "linear-gradient(to top right, #0a233d 10%, rgba(39, 149, 255, 0.4) 100%)",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DropzoneArea
        acceptedFiles={["image/*"]}
        dropzoneText={"Drag and drop files here or click to browse"}
        onChange={(files) => files && files.length && props.onChange(files)}
        filesLimit={props.filesLimit || 3}
      />
    </div>
  );
};
KMBDropzoneArea.propTypes = {
  filesLimit: PropTypes.number,
  onChange: PropTypes.func,
};
const createTemp = (props, isButton = false) => {
  const newProps = { ...props, children: undefined };

  if (!isButton) {
    const parent = JSON.parse(JSON.stringify(newProps));
    const child = JSON.parse(JSON.stringify(newProps));

    delete child.className;
    delete child.parentStyle;
    child.style = child.style || {};
    child.style.color = !props.disabled
      ? child.style.color || defaultColor
      : disabledColor;
    parent.parentStyle = parent.parentStyle || {};
    parent.parentStyle.color = !props.disabled
      ? parent.parentStyle.color || defaultColor
      : disabledColor;
    const response = {
      parent: {
        className: parent.className,
        style: parent.parentStyle,
        onClick: props.onClick,
      },
      child,
    };
    return response;
  } else {
    const parent = JSON.parse(JSON.stringify(newProps));

    parent.style = parent.style || {};
    parent.style.color = parent.style.color || "#fff";
    parent.style.backgroundColor = parent.style.backgroundColor || defaultColor;
    if (props.disabled) {
      parent.style.backgroundColor = disabledColor;
    }
    return { parent };
  }
};

export const Button = btn;

export const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    fontSize: 16,
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
      outline: "none",
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      outline: "none",
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export const AddButton = (props) => {
  const { parent, child } = createTemp(props);
  return (
    <IconButton variant="contained" {...parent} disabled={props.disabled}>
      <AddCircleIcon {...child} />
      {props.children}
    </IconButton>
  );
};
AddButton.propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.bool,
};
export const SaveButton = (props) => {
  const { parent, child } = createTemp(props);
  return (
    <IconButton variant="contained" {...parent}>
      <SaveIcon {...child} />
    </IconButton>
  );
};

export const DeleteButton = (props) => {
  const { parent, child } = createTemp(props);
  return (
    <IconButton variant="contained" {...parent}>
      <DeleteIcon {...child} />
    </IconButton>
  );
};

export const CloseButton = (props) => {
  const { parent, child } = createTemp(props);
  return (
    <IconButton variant="contained" {...parent}>
      <CloseIcon {...child} />
    </IconButton>
  );
};
