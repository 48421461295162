import React from "react";
import styles from "@config/googleMapStyle";
import fontawesome from "fontawesome-markers";
import PropTypes from "prop-types";

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.marker = null;
    this.setMarker = this.setMarker.bind(this);
  }

  setMarker(center) {
    if (this.marker !== null) {
      this.marker.setMap(null);
    }

    this.marker = new window.google.maps.Marker({
      map: this.map,
      icon: {
        path: fontawesome.MAP_MARKER,
        scale: 0.7,
        strokeWeight: 0,
        strokeOpacity: 0,
        fillColor: "#208edd",
        fillOpacity: 1,
      },
      clickable: false,
      position: new window.google.maps.LatLng(center.lat, center.lng),
    });

    this.marker.setMap(this.map);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.center.lat === this.props.center.lat &&
      nextProps.center.lng === this.props.center.lng
    ) {
      return;
    }
    this.setMarker(nextProps.center);
  }

  componentDidMount() {
    this.map = new window.google.maps.Map(this.mapRef, {
      zoomControl: false,
      gestureHandling: "none",
      fullscreenControl: false,
      streetViewControl: false,
      center: this.props.center,
      zoom: this.props.zoom,
      styles,
      mapTypeControl: false,
      scrollwheel: false,
    });
    window.map = this.map;
    this.setMarker(this.props.center);
    this.props.getMapInstance(this.map);
  }

  getOptions() {
    return { styles };
  }

  render() {
    return (
      <div
        ref={(ref) => (this.mapRef = ref)}
        style={{ width: "100%", height: this.props.mapHeight }}
      ></div>
    );
  }
}

Map.defaultProps = {
  zoom: 14,
  getMapInstance: () => {},
  mapHeight: 300,
};

Map.propTypes = {
  mapHeight: PropTypes.number,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  getMapInstance: PropTypes.func.isRequired,
};
