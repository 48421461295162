/* eslint-disable */
import {
  RECEIVED_SCHEMAS_ORGLIST,
  RECEIVED_SCHEMAS_LIST,
  RECEIVED_CRM_LIST,
  RECEIVED_EVENTS_LIST,
  REQUEST_RENTABLES_LIST,
  RECEIVED_RENTABLES_LIST,
  RECEIVED_RENTABLES_DATALIST,
  REQUEST_RENTABLES_DATALIST,
  REQUEST_EVENTS_LIST,
  REQUEST_INVOICES_LIST,
  RECEIVED_INVOICES_LIST,
  INVALIDATE_EVENTS_LIST,
  RECEIVED_EVENTS_EDIT,
  RECEIVED_EVENTS_EDITPAYMENTS,
  RECEIVED_EVENTS_RECENT,
  RECEIVED_ACCESSPOLICIES_LIST,
  RECEIVED_ACCESSPOLICIES_EDIT,
  RECEIVED_ACCESSPOLICIES_CREATE,
  RECEIVED_EVENTS_STATISTICS,
  REQUEST_EVENTS_UPDATECLIENTPANELSETTINGS,
  REQUEST_EVENTS_GETCLIENTPANELSETTINGS,
  RECEIVED_EVENTS_GETCLIENTPANELSETTINGS,
  RECEIVED_EVENTS_UPDATECLIENTPANELSETTINGS,
  RECEIVED_SESSIONS_LIST,
  RECEIVED_SESSIONS_GETSESSIONTYPES,
  RECEIVED_SESSIONS_EDIT,
  RECEIVED_SESSIONS_CREATE,
  RECEIVED_PERSONS_LIST,
  RECEIVED_EVENTS_TOTALSCANS,
  RECEIVED_SPEECHES_CREATE,
  RECEIVED_SPEECHES_DELETE,
  RECEIVED_SPEECHES_EDIT,
  RECEIVED_PERSONS_CREATE,
  RECEIVED_STAFF_LIST,
  RECEIVED_STAFF_CREATE,
  RECEIVED_STAFF_EDIT,
  RECEIVED_PERSONS_EDIT,
  RECEIVED_ROLES_TYPES,
  RECEIVED_SUBSCRIPTIONS_LIST,
  RECEIVED_SUBSCRIPTIONS_PERIODS,
  RECEIVED_SUBSCRIPTIONS_TYPES,
  RECEIVED_SUBSCRIPTIONS_UPDATE,
  RECEIVED_CACHED_PERSONS_LIST,
  RECEIVED_CACHED_STAFF_LIST,
  RECEIVED_HOTELS_LIST,
  RECEIVED_HOTELS_EDIT,
  RECEIVED_HOTELS_REORDER,
  RECEIVED_HOTELS_UPDATEROOM,
  RECEIVED_HOTELS_SAVEEVENTROOM,
  RECEIVED_HOTELS_ORGLIST,
  RECEIVED_HOTELS_ASSIGNHOTEL,
  RECEIVED_HOTELS_UPDATEEVENTROOM,
  RECEIVED_HOTELS_ASSIGNROOM,
  RECEIVED_HOTELS_UNASSIGNHOTEL,
  RECEIVED_HOTELS_ORGROOMS,
  RECEIVED_HOTELS_EVENTROOMS,
  REQUEST_HOTELS_UPDATEEVENTROOM,
  REQUEST_SESSIONS_GETSESSIONTYPES,
  RECEIVED_ABSTRACTS_LIST,
  RECEIVED_ABSTRACTS_SCHEMAS,
  REQUEST_ABSTRACTS_GETSCHEMA,
  RECEIVED_ABSTRACTS_GETSCHEMA,
  RECEIVED_ABSTRACTS_UPDATEEVENTSCHEMA,
  RECEIVED_ABSTRACTS_CREATESCHEMA,
  RECEIVED_ABSTRACTS_UPDATESCHEMA,
  RECEIVED_TOPICS_GETORGTOPICS,
  RECEIVED_JUDGE_LIST,
  RECEIVED_JUDGE_GET,
  REQUEST_EVENTHOOKS_LIST,
  RECEIVED_EVENTHOOKS_LIST,
  RECEIVED_EVENTHOOKS_EDIT,
  RECEIVED_HOOKEMAILS_LIST,
  RECEIVED_HOOKEMAILS_EDIT,
  REQUEST_HOOKEMAILS_EDIT,
  REQUEST_HOOKEMAILS_LIST,
  RECEIVED_HOOKEMAILS_DELETE,
  REQUEST_HOOKEMAILS_DELETE,
  REQUEST_HOOKEMAILS_CREATE,
  RECEIVED_HOOKEMAILS_CREATE,
  RECEIVED_SCHEMAS_UPDATE,
  RECEIVED_SCHEMAS_CREATE,
  REQUEST_PRESETEMAILS_LIST,
  RECEIVED_PRESETEMAILS_LIST,
  RECEIVED_PRESETEMAILS_CONTACTS,
  RECEIVED_PRESETEMAILS_CUSTOMCONTACTS,
  RECEIVED_PRESETEMAILS_CREATE,
  RECEIVED_PRESETEMAILS_CUSTOM,
  DELETE_CONTACT,
  EDIT_CONTACT,
  RECEIVED_SENTEMAILS_LIST,
  RECEIVED_SENTEMAILS_UPDATE,
  RECEIVED_PRESETEMAILS_UPDATE,
  REQUEST_QUESTIONNAIRE_LIST,
  RECEIVED_QUESTIONNAIRE_LIST,
  RECEIVED_QUESTIONNAIRE_CUSTOMCONTACTS,
  RECEIVED_QUESTIONNAIRE_CREATE,
  DELETE_CONTACT_QUESTIONNAIRE,
  ADD_CONTACT_QUESTIONNAIRE,
  RECEIVED_SPONSORS_LIST,
  REQUEST_SPONSORS_LIST,
  RECEIVED_SPONSORS_PERSONSLIST,
  RECEIVED_POLICYGROUPS_LIST,
  REQUEST_POLICYGROUPS_LIST,
  REQUEST_SPONSORS_SPONSORSHIPSTATUS,
  RECEIVED_SPONSORS_SPONSORSHIPSTATUS,
  RECEIVED_RESERVATIONS_LISTRESERVATIONS,
  REQUEST_RESERVATIONS_LISTRESERVATIONS,
  RECEIVED_PAYMENTS_LIST,
  REQUEST_PAYMENTS_LIST,
  REQUEST_SENTEMAILS_LIST,
  RECEIVED_DATASETS_LIST,
  RECEIVED_DATASETS_LISTENTRIES,
  REQUEST_DATASETS_LISTENTRIES,
  REQUEST_DATASETS_UPDATE,
  RECEIVED_PRODUCTS_LIST,
  RECEIVED_PRODUCTS_PRICESLIST,
  REQUEST_PRODUCTS_PRICESLIST,
  REQUEST_PRODUCTS_DATALIST,
  RECEIVED_PRODUCTS_DATALIST,
  RECEIVED_TYPES_GETORGTYPES,
} from "@actions/ajax";
import {
  UPDATE_CONDITION_VALUE,
  UPDATE_CONDITION_OPERATOR,
  ADD_GROUP,
  DELETE_GROUP,
  ADD_CONDITION,
  DELETE_CONDITION,
  UPDATE_CONDITION,
} from "@actions/policyGroups";
import {
  SET_REQUEST_CRITERIA,
  TAB_UPDATED,
  CHANGE_PAGE,
  SET_FILTERS,
  IMPORT_USERS,
  USERS_IMPORTED,
  ITEM_DELETED,
  EVENT_CREATED,
  CHANGE_PRINTER_TYPE,
  SET_ADVANCED_SEARCH_OPTIONS,
  PAYMENTS_GET_STATISTICS,
  SET_SELECTED_POLICY,
} from "@actions";

import {
  RECEIVED_BANKACCOUNTS_GETBANKACCOUNTS,
  REQUEST_BANKACCOUNTS_GETBANKACCOUNTS,
} from "@actions/bankAccounts";

import {
  REQUEST_EXHIBITIONDIGITALSETTINGS_LISTSLOTS,
  RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTSLOTS,
  REQUEST_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES,
  RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES,
  REQUEST_EXHIBITORS_LIST,
  RECEIVED_EXHIBITORS_LIST,
} from "@actions/exhibitions";
import { CRM_FIELD_CHANGE } from "@actions/crm";

import { SELECT_HOOK } from "@actions/hooks";
import {
  RECEIVED_SESSIONS_UPDATESESSIONTYPE,
  RECEIVED_SESSIONS_DELETESESSIONTYPE,
  SESSION_TYPES_SAVED,
} from "@actions/sessions";
import {
  RECEIVED_ROLES_LIST,
  RECEIVED_ROLES_CREATE,
  RECEIVED_ROLES_EDIT,
  RECEIVED_ROLES_DELETE,
  RECEIVED_STAFF_GET,
  ROLE_FILTERS_ACTIVE,
  ROLE_FILTERS_INACTIVE,
  REQUEST_ROLES_LIST,
} from "@actions/roles";
import {
  RECEIVED_ABSTRACTS_UPDATE,
  RECEIVED_ABSTRACTS_UPDATEMULTIPLE,
  RECEIVED_ABSTRACTS_GETJUDGES,
  VIEW_ABSTRACT,
} from "@actions/abstracts";
import { CHANGE_SECTION, CHANGE_TAB } from "@actions/editmenu";
import { VIEW_JUDGE_ABSTRACT } from "@actions/judge";
import { api as configuration } from "@config/conf";
import ApiHelper from "@helpers/api-helper";
import UsersHelper from "@helpers/users-helper";
import { CHANGE_CRM_FILTERS } from "@actions/crm";
import {
  RECEIVED_SPEAKERS_LIST,
  REQUEST_SPEAKERS_LIST,
} from "@actions/speakers";
import { ADD_RENT_ACTIVE_SCANS, CLEAR_RENT_SCANS } from "@actions/rentables";

export const initialState = {
  payments: {
    data: [],

    ready: false,
    meta: {
      statisticData: {
        doingAjax: false,
      },
    },
  },
  datasets: {
    list: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    entriesList: {
      data: [],
      ready: false,
    },
  },
  products: {
    list: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    listPrices: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    listData: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
  },
  reservations: {
    data: [],
    ready: false,
  },
  policyGroups: {
    data: [],
    list: {
      data: [],
      ready: false,
    },
  },
  crmColumns: {
    cosmetic: {
      name: "Cosmetic",
      label: "Cosmetic",
      filter: true,
    },
    id: {
      name: "ID",
      label: "ID",
      filter: true,
    },
    firstName: {
      name: "First Name",
      label: "First Name",
      filter: true,
    },
    lastName: {
      name: "Last Name",
      label: "Last Name",
      filter: true,
    },
    email: {
      name: "Email",
      label: "Email",
      filter: true,
    },
    createdAt: {
      name: "Created At",
      label: "Created At",
      filter: true,
    },
    speaker: {
      name: "Speaker",
      label: "Speaker",
      filter: false,
    },
    registered: {
      name: "Verified",
      label: "Verified",
      filter: false,
    },
    active: {
      name: "Active",
      label: "Active",
      filter: false,
      sortable: true,
    },
  },
  sponsors: {
    data: [],
    ready: false,
    persons: {
      data: [],
      ready: false,
    },
    sponsorships: {
      data: [],
      meta: {},
      fetching: false,
    },
  },
  exhibitionHall: {
    categories: {
      data: [],
      meta: {},
      fetching: false,
    },
    slots: {
      data: [],
      meta: {},
      fetching: false,
    },
    exhibitors: {
      data: [],
      meta: {},
      fetching: false,
    },
    exhibitorsPage: {
      data: [],
      meta: {},
      fetching: false,
    },
  },
  events: {
    list: {
      data: [],
      organisations: [],
      meta: {},
      filters: {
        dateRange: {},
        active: [],
        status: [],
        owner: [],
      },
      filtersActive: false,
      needsUpdate: false,
      fetching: false,
      criteria: {
        p: 1,
        rpp: 20,
        orderBy: "createdAt",
        order: "DESC",
        search: "",
      },
    },
    recent: {
      data: [],
      meta: {},
      needsUpdate: false,
      fetching: false,
      criteria: {
        p: 1,
        rpp: 3,
        orderBy: "createdAt",
        order: "DESC",
        search: "",
      },
    },
    create: {
      fetching: false,
    },
    edit: {
      data: {},
      needsUpdate: false,
      fetching: false,
    },
    statistics: {
      data: {},
      fetching: false,
      meta: {},
      timestamp: null,
    },
    totalScans: {
      data: [],
      fetching: false,
      meta: {},
    },
    hooks: {
      data: [],
      meta: {},
      fetching: false,
      innerHook: {
        type: null,
        hook: null,
        emails: {
          data: [],
          fetching: false,
          meta: {},
        },
      },
    },
    presetEmails: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    questionnaires: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    sendEmails: {
      data: [],
      meta: {},
      fetching: false,
    },
    invoices: {
      data: [],
      meta: {},
      fetching: false,
    },
    tempContacts: [],
    tempCustomContacts: [],
    tempQuestionnaireContacts: [],
  },
  rentables: {
    list: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    listRentData: {
      data: [],
      meta: {},
      fetching: false,
      ready: false,
    },
    activeScans: [],
  },
  advancedSearchOptions: {},
  accesspolicies: {
    selectedPolicy: {},
    list: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
    edit: {
      fetching: false,
    },
    create: {
      fetching: false,
    },
  },
  users: {
    create: {
      fetching: false,
    },
    update: {
      fetching: false,
    },
    delete: {
      fetching: false,
    },
  },
  bankAccounts: {
    data: [],
    needsUpdate: false,
    meta: {},
    fetching: false,
    timestamp: null,
  },
  speakers: {
    data: [],
    meta: {},
    fetching: false,
  },
  sessions: {
    list: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
    },
    sessionTypes: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      timestamp: null,
    },
    edit: {
      fetching: false,
    },
    create: {
      fetching: false,
    },
  },
  speeches: {
    create: {
      fetching: false,
    },
    edit: {
      fetching: false,
    },
  },
  persons: {
    list: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
  },
  crm: {
    list: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
  },
  schemas: {
    list: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
    update: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
    orglist: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
  },
  staff: {
    single: {
      data: {},
      meta: {},
      fetching: false,
      ready: false,
    },
    list: {
      data: [],
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
    },
    roles: {
      data: {
        events: [],
        organisations: [],
      },
      needsUpdate: false,
      meta: {},
      fetching: false,
      ready: false,
      filtersActive: false,
      criteria: {
        p: 1,
        rpp: 20,
        orderBy: "createdAt",
        order: "DESC",
        search: "",
      },
    },
    roleTypes: {
      data: {},
      ready: false,
    },
  },
  subscriptions: {
    list: {
      data: [],
      fetching: false,
      ready: false,
      meta: {},
    },
    types: {
      data: [],
      fetching: false,
      ready: false,
      meta: {},
    },
    periods: {
      data: [],
      fetching: false,
      ready: false,
      meta: {},
    },
  },
  hotels: {
    list: {
      data: [],
      fetching: false,
      ready: false,
      meta: {},
      needsUpdate: false,
    },
    orgList: {
      data: [],
      fetching: false,
      ready: false,
      meta: {},
      needsUpdate: false,
    },
    orgRooms: {
      data: [],
      fetching: false,
      ready: false,
      meta: {},
      needsUpdate: false,
    },
    eventRooms: {
      fetching: false,
    },
  },
  abstracts: {
    list: {
      data: [],
      fetching: false,
      meta: {},
      needsUpdate: false,
      firstFetch: true,
    },
    schemas: {
      data: [],
      fetching: false,
      meta: {},
      needsUpdate: false,
    },
    currentSchema: {
      data: {},
      fetching: false,
      meta: {},
      needsUpdate: false,
    },
    topics: {
      data: [],
      fetching: false,
    },
    types: {
      data: [],
      fetching: false,
    },
    showingAbstract: {
      abstract: {},
      active: false,
    },
    judges: {
      data: [],
      meta: {},
      ready: false,
    },
  },
  judge: {
    list: {
      data: [],
      fetching: false,
      meta: {
        orderBy: "id",
        order: "DESC",
        search: "",
        type: "",
        status: "",
      },
      extra: "",
      needsUpdate: false,
    },
    showingAbstract: {
      abstractId: null,
      active: false,
      eventId: null,
      abstract: {},
    },
  },
  configuration,
  doingAjax: false,
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVANCED_SEARCH_OPTIONS: {
      return Object.assign({}, state, {
        advancedSearchOptions: Object.assign({}, state.advancedSearchOptions, {
          [action.eventId]: Object.assign(
            { ...(state.advancedSearchOptions[action.eventId] || {}) },
            { [action.policyId]: action.options }
          ),
        }),
      });
    }
    case CRM_FIELD_CHANGE: {
      return Object.assign({}, state, {
        schemas: Object.assign({}, state.schemas, {
          update: Object.assign({}, state.schemas.update, {
            data: action.fields,
          }),
        }),
      });
    }

    case SELECT_HOOK: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              type: action.hookType,
              hook: action.hook,
            }),
          }),
        }),
      });
    }

    case RECEIVED_EVENTHOOKS_EDIT: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            data: state.events.hooks.data.map((v) => {
              if (v.id === action.response.data.id) {
                return action.response.data;
              }
              return v;
            }),
          }),
        }),
      });
    }
    case RECEIVED_DATASETS_LISTENTRIES: {
      return Object.assign({}, state, {
        datasets: Object.assign({}, state.datasets, {
          entriesList: Object.assign({}, state.datasets.entriesList, {
            data: action.response.data,
            ready: true,
          }),
        }),
      });
    }
    case REQUEST_DATASETS_LISTENTRIES: {
      return Object.assign({}, state, {
        datasets: Object.assign({}, state.datasets, {
          entriesList: Object.assign({}, state.datasets.entriesList, {
            ready: false,
          }),
        }),
      });
    }
    case REQUEST_DATASETS_UPDATE: {
      return Object.assign({}, state, {
        datasets: Object.assign({}, state.datasets, {
          entriesList: Object.assign({}, state.datasets.entriesList, {
            ready: false,
          }),
        }),
      });
    }
    case RECEIVED_PRODUCTS_LIST: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          list: Object.assign({}, state.products.list, {
            data: action.response.data,
            ready: true,
          }),
        }),
      });
    }
    case RECEIVED_PRODUCTS_PRICESLIST: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          listPrices: Object.assign({}, state.products.listPrices, {
            data: action.response.data,
            ready: true,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }
    case REQUEST_PRODUCTS_PRICESLIST: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          listPrices: Object.assign({}, state.products.listPrices, {
            ready: false,
            fetching: true,
          }),
        }),
      });
    }
    case REQUEST_PRODUCTS_DATALIST: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          listData: Object.assign({}, state.products.listData, {
            ready: false,
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_PRODUCTS_DATALIST: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          listData: Object.assign({}, state.products.listData, {
            data: action.response.data,
            ready: true,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }
    case RECEIVED_DATASETS_LIST: {
      return Object.assign({}, state, {
        datasets: Object.assign({}, state.datasets, {
          list: Object.assign({}, state.datasets.list, {
            data: action.response.data,
            ready: true,
          }),
        }),
      });
    }
    case REQUEST_EVENTHOOKS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_EVENTHOOKS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }

    case RECEIVED_HOOKEMAILS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                data: action.response.data,
                meta: action.response.meta,
                fetching: false,
              }),
            }),
          }),
        }),
      });
    }
    case REQUEST_HOOKEMAILS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                fetching: true,
              }),
            }),
          }),
        }),
      });
    }
    case REQUEST_HOOKEMAILS_EDIT: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                fetching: false, //prevet kmbrows
              }),
            }),
          }),
        }),
      });
    }

    case RECEIVED_HOOKEMAILS_EDIT: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                data: state.events.hooks.innerHook.emails.data.map((v) =>
                  v.id === action.response.data.id ? action.response.data : v
                ),
                meta: action.response.meta,
                fetching: false,
              }),
            }),
          }),
        }),
      });
    }

    case REQUEST_HOOKEMAILS_DELETE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                fetching: true,
              }),
            }),
          }),
        }),
      });
    }

    case RECEIVED_HOOKEMAILS_DELETE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                data: state.events.hooks.innerHook.emails.data.filter(
                  (e) =>
                    e.id !==
                    parseInt(ApiHelper.getItemIdByMeta(action.response.meta))
                ),
                fetching: false,
              }),
            }),
          }),
        }),
      });
    }
    case REQUEST_HOOKEMAILS_CREATE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                fetching: true,
              }),
            }),
          }),
        }),
      });
    }
    case RECEIVED_HOOKEMAILS_CREATE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: Object.assign({}, state.events.hooks.innerHook, {
              emails: Object.assign({}, state.events.hooks.innerHook.emails, {
                data: [
                  ...[action.response.data],
                  ...state.events.hooks.innerHook.emails.data,
                ],
                fetching: false,
              }),
            }),
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_GETJUDGES: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          judges: Object.assign({}, state.abstracts.judges, {
            data: action.response.data,
            meta: action.response.meta,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_JUDGE_GET: {
      return Object.assign({}, state, {
        judge: Object.assign({}, state.judge, {
          showingAbstract: Object.assign({}, state.judge.showingAbstract, {
            abstract: action.response.data,
          }),
        }),
      });
    }

    case VIEW_JUDGE_ABSTRACT: {
      return Object.assign({}, state, {
        judge: Object.assign({}, state.judge, {
          showingAbstract: Object.assign({}, state.judge.showingAbstract, {
            active: true,
            abstractId: action.abstractId,
            eventId: action.eventId,
          }),
        }),
      });
    }

    case VIEW_ABSTRACT: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract, {
            active: true,
            abstract: action.abstract,
          }),
        }),
      });
    }

    case CHANGE_TAB:
    case CHANGE_SECTION: {
      state.policyGroups.data = [];
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          datasets: Object.assign({}, state.datasets, {
            entriesList: Object.assign({}, state.datasets.entriesList, {
              data: [],
              ready: false,
            }),
          }),
          hooks: Object.assign({}, state.events.hooks, {
            innerHook: {
              type: null,
              hook: null,
            },
          }),
        }),
      });
    }

    case RECEIVED_JUDGE_LIST: {
      const meta = action.response.meta;

      if (!meta.hasOwnProperty("type")) {
        meta["type"] = initialState.judge.list.meta.type;
      }

      if (!meta.hasOwnProperty("status")) {
        meta["status"] = initialState.judge.list.meta.status;
      }

      return Object.assign({}, state, {
        judge: Object.assign({}, state.judge, {
          list: Object.assign({}, state.judge.list, {
            data: action.response.data,
            fetching: false,
            meta,
            extra: action.hasOwnProperty("extra") ? action.extra : "",
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_UPDATE: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          list: Object.assign({}, state.abstracts.list, {
            data: [...state.abstracts.list.data].map((d) => {
              if (d.id === action.response.data.id) {
                return action.response.data;
              }
              return d;
            }),
            meta: Object.assign(
              {},
              state.abstracts.list.meta,
              action.response.meta
            ),
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_UPDATEMULTIPLE: {
      const { meta } = action.response;
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          list: Object.assign({}, state.abstracts.list, {
            data: [...state.abstracts.list.data].map((d) => {
              for (const _d of action.response.data) {
                if (_d.id === d.id) {
                  return _d;
                }
              }
              return d;
            }),
            meta: Object.assign({}, state.abstracts.list.meta, {
              totalPassingByJudge: meta.totalPassingByJudge,
              totalPassingByAdmin: meta.totalPassingByAdmin,
              totalNotPassingByJudge: meta.totalPassingByAdmin,
              totalNotPassingByAdmin: meta.totalNotPassingByAdmin,
            }),
          }),
        }),
      });
    }

    case RECEIVED_TOPICS_GETORGTOPICS: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          topics: Object.assign({}, state.abstracts.topics, {
            data: action.response.data,
            fetching: false,
          }),
        }),
      });
    }
    case RECEIVED_TYPES_GETORGTYPES: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          types: Object.assign({}, state.abstracts.types, {
            data: action.response.data,
            fetching: false,
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_UPDATESCHEMA: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          schemas: Object.assign({}, state.abstracts.schemas, {
            data: [...state.abstracts.schemas.data].map((d) => {
              if (d.id === action.response.data.id) {
                return action.response.data;
              }
              return d;
            }),
          }),
          currentSchema: Object.assign({}, state.abstracts.currentSchema, {
            data: Object.assign(
              {},
              state.abstracts.currentSchema.data,
              action.response.data
            ),
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_CREATESCHEMA: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          schemas: Object.assign({}, state.abstracts.schemas, {
            data: [...[action.response.data], ...state.abstracts.schemas.data],
          }),
          currentSchema: Object.assign({}, state.abstracts.currentSchema, {
            data: action.response.data,
            meta: action.response.meta,
          }),
        }),
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            data: Object.assign({}, state.events.edit.data, {
              eventAbstractSchemaId: action.response.data.id,
            }),
          }),
        }),
      });
    }
    case REQUEST_EVENTS_GETCLIENTPANELSETTINGS:
    case REQUEST_EVENTS_UPDATECLIENTPANELSETTINGS: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            fetchingInner: true,
          }),
        }),
      });
    }

    case RECEIVED_EVENTS_GETCLIENTPANELSETTINGS: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            data: Object.assign({}, state.events.edit.data, {
              clientPanelSettings: action.response.data,
            }),
            fetchingInner: false,
          }),
        }),
      });
    }
    case RECEIVED_ABSTRACTS_UPDATEEVENTSCHEMA: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            data: Object.assign({}, state.events.edit.data, {
              orgAbstractSchemaId: action.response.data.orgAbstractSchemaId,
            }),
          }),
        }),
        abstracts: Object.assign({}, state.abstracts, {
          currentSchema: Object.assign({}, state.abstracts.currentSchema, {
            data: Object.assign({}, state.abstracts.currentSchema.data, {
              startDate: action.response.data.startDate,
              endDate: action.response.data.endDate,
              judgingEndDate: action.response.data.judgingEndDate,
              topics: action.response.data.topics,
              types: action.response.data.types,
            }),
          }),
        }),
      });
    }

    case REQUEST_ABSTRACTS_GETSCHEMA: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          currentSchema: Object.assign({}, state.abstracts.currentSchema, {
            fetching: true,
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_GETSCHEMA: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          currentSchema: Object.assign({}, state.abstracts.currentSchema, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_SCHEMAS: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          schemas: Object.assign({}, state.abstracts.schemas, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_LIST: {
      const {
        typeIds,
        topicIds,
        ratedBy,
        status,
        published,
        search,
        inSchedule,
      } = action.response.meta;

      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          list: Object.assign({}, state.abstracts.list, {
            data: action.response.data,
            meta:
              state.abstracts.list.firstFetch === true
                ? Object.assign({}, action.response.meta, {
                    originalTotalRows: action.response.meta.totalRows,
                  })
                : Object.assign({}, state.abstracts.list.meta, {
                    totalRows: action.response.meta.totalRows,
                    p: action.response.meta.p,
                    rpp: action.response.meta.rpp,
                    typeIds,
                    topicIds,
                    ratedBy,
                    status,
                    search,
                    published,
                    inSchedule,
                  }),
            fetching: false,
            firstFetch: false,
          }),
        }),
      });
    }

    case REQUEST_SPEAKERS_LIST: {
      return Object.assign({}, state, {
        speakers: Object.assign({}, state.speakers, {
          fetching: true,
        }),
      });
    }

    case RECEIVED_SPEAKERS_LIST: {
      return Object.assign({}, state, {
        speakers: Object.assign({}, state.speakers, {
          data: action.response.data,
          meta: action.response.meta,
          fetching: false,
        }),
      });
    }

    case SESSION_TYPES_SAVED: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          sessionTypes: Object.assign({}, state.sessions.sessionTypes, {
            data: [...action.sessionTypes, ...state.sessions.sessionTypes.data],
          }),
        }),
      });
    }

    case RECEIVED_SESSIONS_DELETESESSIONTYPE: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          sessionTypes: Object.assign({}, state.sessions.sessionTypes, {
            data: [...state.sessions.sessionTypes.data].filter(
              (s) => s.id !== action.extra.sessionTypeId
            ),
          }),
        }),
      });
    }

    case RECEIVED_SESSIONS_UPDATESESSIONTYPE: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          sessionTypes: Object.assign({}, state.sessions.sessionTypes, {
            data: [...state.sessions.sessionTypes.data].map((s) => {
              if (s.id === action.response.data.id) {
                return action.response.data;
              }
              return s;
            }),
          }),
        }),
      });
    }

    case REQUEST_BANKACCOUNTS_GETBANKACCOUNTS: {
      return Object.assign({}, state, {
        bankAccounts: Object.assign({}, state.bankAccounts, {
          fetching: true,
        }),
      });
    }

    case RECEIVED_BANKACCOUNTS_GETBANKACCOUNTS: {
      return Object.assign({}, state, {
        bankAccounts: Object.assign({}, state.bankAccounts, {
          data: action.response.data,
          meta: action.response.meta,
          fetching: false,
        }),
      });
    }
    case REQUEST_HOTELS_UPDATEEVENTROOM: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          eventRooms: Object.assign({}, state.hotels.eventRooms, {
            fetching: true,
          }),
        }),
      });
    }

    case REQUEST_SESSIONS_GETSESSIONTYPES: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          sessionTypes: Object.assign({}, state.sessions.sessionTypes, {
            fetching: true,
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_EVENTROOMS: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            data: [...state.hotels.list.data].map((h) => {
              if (h.id === action.extra.hotelId) {
                h.rooms = action.response.data;
              }
              return h;
            }),
          }),
          eventRooms: Object.assign({}, state.hotels.eventRooms, {
            fetching: false,
          }),
        }),
      });
    }

    case CHANGE_PRINTER_TYPE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            data: Object.assign({}, state.events.edit.data, {
              extra: Object.assign({}, state.events.edit.data.extra, {
                cardPrintingSettings: JSON.stringify({}),
              }),
            }),
          }),
        }),
      });
    }
    case REQUEST_POLICYGROUPS_LIST: {
      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          list: Object.assign({}, state.policyGroups.list, {
            ready: false,
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_POLICYGROUPS_LIST: {
      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          list: Object.assign({}, state.policyGroups.list, {
            data: action.response.data,
            ready: true,
            fetching: false,
          }),
        }),
      });
    }
    case ADD_GROUP: {
      const newGroups = [...(state.policyGroups.data || [])];
      if (action.conditions) {
        newGroups.push({
          conditions: action.conditions.map((condition) => {
            let value = condition.value;
            if (condition.type == "yes_no") {
              value = value == 1 ? 1 : 0;
            }
            return {
              ...condition,
              operator: condition.operator || "=",
              value,
            };
          }),
        });
      } else {
        newGroups.push({
          conditions: [
            {
              key: "",
              label: "",
              type: "",
              value: "",
              operator: "=",
            },
          ],
        });
      }

      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          data: newGroups,
        }),
      });
    }
    case DELETE_GROUP: {
      const newGroups = [...(state.policyGroups.data || [])];
      if (action.groupIndex === "all") {
        newGroups.length = 0;
      } else {
        newGroups.splice(action.groupIndex, 1);
      }
      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          data: newGroups,
        }),
      });
    }

    case ADD_CONDITION: {
      const newGroups = [...(state.policyGroups.data || [])];
      newGroups[action.groupIndex].conditions?.push({
        key: "",
        label: "",
        type: "",
        value: "",
        operator: "=",
      });

      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          data: newGroups,
        }),
      });
    }
    case UPDATE_CONDITION: {
      const newGroups = [...(state.policyGroups.data || [])];
      newGroups[action.groupIndex].conditions[action.conditionIndex] =
        action.condition;

      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          data: newGroups,
        }),
      });
    }

    case DELETE_CONDITION: {
      const newGroups = [...(state.policyGroups.data || [])];
      newGroups[action.groupIndex].conditions.splice(action.conditionIndex, 1);

      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          data: newGroups,
        }),
      });
    }

    case RECEIVED_PAYMENTS_LIST: {
      return Object.assign({}, state, {
        payments: Object.assign({}, state.payments, {
          data: action.response.data,
          ready: true,
          meta: Object.assign({}, action.response.meta, {
            statisticData: Object.assign(
              {},
              state.payments.meta.statisticData,
              {
                ...action.response.meta.statisticData,
              }
            ),
          }),
        }),
      });
    }
    case REQUEST_PAYMENTS_LIST: {
      return Object.assign({}, state, {
        payments: Object.assign({}, state.payments, {
          ready: false,
        }),
      });
    }

    case PAYMENTS_GET_STATISTICS: {
      return Object.assign({}, state, {
        payments: Object.assign({}, state.payments, {
          meta: Object.assign({}, state.payments.meta, {
            statisticData: Object.assign(
              {},
              state.payments.meta.statisticData,
              {
                doingAjax: action.doingAjax,
              }
            ),
          }),
        }),
      });
    }

    case REQUEST_RESERVATIONS_LISTRESERVATIONS: {
      return Object.assign({}, state, {
        reservations: Object.assign({}, state.reservations, {
          ready: false,
          fetching: true,
        }),
      });
    }
    case RECEIVED_RESERVATIONS_LISTRESERVATIONS: {
      return Object.assign({}, state, {
        reservations: Object.assign({}, state.reservations, {
          data: action.response.data,
          ready: true,
          fetching: false,
        }),
      });
    }

    case RECEIVED_HOTELS_ORGROOMS: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          orgRooms: Object.assign({}, state.hotels.orgRooms, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case REQUEST_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES: {
      return Object.assign({}, state, {
        exhibitionHall: Object.assign({}, state.exhibitionHall, {
          categories: Object.assign({}, state.exhibitionHall.categories, {
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTCATEGORIES: {
      return Object.assign({}, state, {
        exhibitionHall: Object.assign({}, state.exhibitionHall, {
          categories: Object.assign({}, state.exhibitionHall.categories, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }
    case REQUEST_EXHIBITIONDIGITALSETTINGS_LISTSLOTS: {
      return Object.assign({}, state, {
        exhibitionHall: Object.assign({}, state.exhibitionHall, {
          slots: Object.assign({}, state.exhibitionHall.slots, {
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_EXHIBITIONDIGITALSETTINGS_LISTSLOTS: {
      return Object.assign({}, state, {
        exhibitionHall: Object.assign({}, state.exhibitionHall, {
          slots: Object.assign({}, state.exhibitionHall.slots, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }
    case REQUEST_EXHIBITORS_LIST: {
      return Object.assign({}, state, {
        exhibitionHall: Object.assign({}, state.exhibitionHall, {
          exhibitors: Object.assign({}, state.exhibitionHall.exhibitors, {
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_EXHIBITORS_LIST: {
      return Object.assign({}, state, {
        exhibitionHall: Object.assign({}, state.exhibitionHall, {
          exhibitors: Object.assign({}, state.exhibitionHall.exhibitors, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_ORGLIST: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          orgList: Object.assign({}, state.hotels.orgList, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }
    case RECEIVED_SPONSORS_SPONSORSHIPSTATUS: {
      return Object.assign({}, state, {
        sponsors: Object.assign({}, state.sponsors, {
          sponsorships: Object.assign({}, state.sponsors.sponsorships, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }
    case REQUEST_SPONSORS_SPONSORSHIPSTATUS: {
      return Object.assign({}, state, {
        sponsors: Object.assign({}, state.sponsors, {
          sponsorships: Object.assign({}, state.sponsors.sponsorships, {
            fetching: true,
          }),
        }),
      });
    }

    case RECEIVED_SPONSORS_LIST: {
      return Object.assign({}, state, {
        sponsors: Object.assign({}, state.sponsors, {
          data: action.response.data,
          meta: action.response.meta,
          fetching: false,
          ready: true,
        }),
      });
    }
    case REQUEST_SPONSORS_LIST: {
      return Object.assign({}, state, {
        sponsors: Object.assign({}, state.sponsors, {
          fetching: true,
          ready: false,
        }),
      });
    }
    case RECEIVED_SPONSORS_PERSONSLIST: {
      return Object.assign({}, state, {
        sponsors: Object.assign({}, state.sponsors, {
          persons: Object.assign({}, state.sponsors.persons, {
            data: action.response.data,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_SAVEEVENTROOM: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            data: [...state.hotels.list.data].map((h) => {
              if (h.id === action.response.data.eventHotelId) {
                h.rooms = [...h.rooms].map((r) => {
                  if (r.id === action.response.data.hotelRoomId) {
                    return Object.assign({}, r, {
                      amount: action.response.data.amount,
                      capacity: action.response.data.capacity,
                      endDate: action.response.data.endDate,
                      startDate: action.response.data.startDate,
                      price: action.response.data.price,
                    });
                  }
                  return r;
                });
              }
              return h;
            }),
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_ASSIGNROOM: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            data: [...state.hotels.list.data].map((h) => {
              if (h.id === action.response.data.eventHotelId) {
                h.rooms = [...h.rooms, ...[action.response.data]];
              }
              return h;
            }),
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_UPDATEROOM: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            data: [...state.hotels.list.data].map((h) => {
              if (h.id === action.response.data.hotelId) {
                h.rooms = [...h.rooms].map((r) => {
                  if (r.id === action.response.data.id) {
                    return Object.assign({}, r, action.response.data);
                  }
                  return r;
                });
              }
              return h;
            }),
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_UPDATEEVENTROOM: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            data: [...state.hotels.list.data].map((h) => {
              if (h.id === action.response.data.eventHotelId) {
                h.rooms = [...h.rooms].map((r) => {
                  if (r.hotelRoomId === action.response.data.hotelRoomId) {
                    return Object.assign({}, r, action.response.data);
                  }
                  return r;
                });
              }
              return h;
            }),
          }),
          eventRooms: Object.assign({}, state.hotels.eventRooms, {
            fetching: false,
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_UNASSIGNHOTEL:
    case RECEIVED_HOTELS_ASSIGNHOTEL:
    case RECEIVED_HOTELS_REORDER:
    case RECEIVED_HOTELS_EDIT: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            needsUpdate: true,
            data: [...state.hotels.list.data].map((h) => {
              if (h.id === action.response.data.id) {
                h.hotelImages = action.response.data.hotelImages;
              }
              return h;
            }),
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_LIST: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          list: Object.assign({}, state.hotels.list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_SUBSCRIPTIONS_UPDATE: {
      return Object.assign({}, state, {
        subscriptions: Object.assign({}, state.subscriptions, {
          list: Object.assign({}, state.subscriptions.list, {
            data: [...state.subscriptions.list.data].map((s) => {
              if (s.id === action.response.data.id) {
                return action.response.data;
              }
              return s;
            }),
          }),
        }),
      });
    }

    case RECEIVED_SUBSCRIPTIONS_PERIODS: {
      return Object.assign({}, state, {
        subscriptions: Object.assign({}, state.subscriptions, {
          periods: Object.assign({}, state.subscriptions.periods, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_SUBSCRIPTIONS_TYPES: {
      return Object.assign({}, state, {
        subscriptions: Object.assign({}, state.subscriptions, {
          types: Object.assign({}, state.subscriptions.types, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_SUBSCRIPTIONS_LIST: {
      return Object.assign({}, state, {
        subscriptions: Object.assign({}, state.subscriptions, {
          list: Object.assign({}, state.list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case REQUEST_ROLES_LIST: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roles: Object.assign({}, state.staff.roles, {
            fetching: true,
          }),
        }),
      });
    }
    case REQUEST_RENTABLES_LIST: {
      return Object.assign({}, state, {
        rentables: Object.assign({}, state.rentables, {
          list: Object.assign({}, state.staff.list, {
            ready: false,
            fetching: true,
          }),
        }),
      });
    }
    case ADD_RENT_ACTIVE_SCANS: {
      return Object.assign({}, state, {
        rentables: Object.assign({}, state.rentables, {
          activeScans: state.rentables.activeScans.concat(action.payload),
        }),
      });
    }

    case CLEAR_RENT_SCANS: {
      return Object.assign({}, state, {
        rentables: Object.assign({}, state.rentables, {
          activeScans: [],
        }),
      });
    }

    case RECEIVED_RENTABLES_LIST: {
      return Object.assign({}, state, {
        rentables: Object.assign({}, state.rentables, {
          list: Object.assign({}, state.rentables.list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_RENTABLES_DATALIST: {
      return Object.assign({}, state, {
        rentables: Object.assign({}, state.rentables, {
          listRentData: Object.assign({}, state.rentables.listRentData, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }
    case REQUEST_RENTABLES_DATALIST: {
      return Object.assign({}, state, {
        rentables: Object.assign({}, state.rentables, {
          listRentData: Object.assign({}, state.rentables.listRentData, {
            ready: false,
            fetching: true,
          }),
        }),
      });
    }

    case ROLE_FILTERS_ACTIVE: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roles: Object.assign({}, state.staff.roles, {
            filtersActive: true,
          }),
        }),
      });
    }

    case ROLE_FILTERS_INACTIVE: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roles: Object.assign({}, state.staff.roles, {
            filtersActive: false,
          }),
        }),
      });
    }

    case RECEIVED_STAFF_GET: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          single: Object.assign({}, state.staff.single, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
      });
    }

    case RECEIVED_ROLES_DELETE: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roles: Object.assign({}, state.staff.roles, {
            data: Object.assign({}, state.staff.roles.data, {
              events: [...state.staff.roles.data.events].map((d) => {
                if (d.id === action.response.data.eventId) {
                  d.role = null;
                }
                return d;
              }),
            }),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_ROLES_EDIT:
    case RECEIVED_ROLES_CREATE: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roles: Object.assign({}, state.staff.roles, {
            data: Object.assign({}, state.staff.roles.data, {
              events: [...state.staff.roles.data.events].map((d) => {
                if (d.id === action.response.data.id) {
                  return action.response.data;
                }
                return d;
              }),
            }),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_ROLES_TYPES: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roleTypes: Object.assign({}, state.staff.roleTypes, {
            data: action.response.data,
            ready: true,
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_ROLES_LIST: {
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, {
          roles: Object.assign({}, state.staff.roles, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
        doingAjax: false,
      });
    }

    case EVENT_CREATED: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          list: Object.assign({}, state.events.list, {
            data: [...[action.event], ...state.events.list.data],
          }),
        }),
      });
    }

    case RECEIVED_SPEECHES_DELETE: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          list: Object.assign({}, state.sessions.list, {
            data: state.sessions.list.data.map((s) => {
              s.speeches = [...s.speeches].filter(
                (sp) =>
                  sp.id !==
                  parseInt(ApiHelper.getItemIdByMeta(action.response.meta))
              );
              return s;
            }),
          }),
        }),
      });
    }

    case RECEIVED_SPEECHES_EDIT: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          list: Object.assign({}, state.sessions.list, {
            data: state.sessions.list.data.map((s) => {
              if (s.id === action.response.data.eventSessionId) {
                s.speeches = [...s.speeches].map((sp) => {
                  if (action.response.data.id === sp.id) {
                    return action.response.data;
                  }
                  return sp;
                });
              }
              return s;
            }),
          }),
        }),
        speeches: Object.assign({}, state.speeches, {
          edit: {
            fetching: false,
          },
        }),
      });
    }

    case ITEM_DELETED: {
      if (
        !initialState.hasOwnProperty(action.endpoint) ||
        !initialState[action.endpoint].hasOwnProperty("list")
      ) {
        return state;
      }

      const endpoint = action.endpoint;

      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          list: Object.assign({}, state[endpoint].list, {
            data: [...state[endpoint].list.data].filter(
              (d) => d.id !== parseInt(action.itemId)
            ),
          }),
        }),
      });
    }

    case IMPORT_USERS: {
      return Object.assign({}, state, {
        accesspolicies: Object.assign({}, state.accesspolicies, {
          list: Object.assign({}, state.accesspolicies.list, {
            fetching: true,
          }),
        }),
        doingAjax: true,
      });
    }

    case USERS_IMPORTED: {
      return Object.assign({}, state, {
        accesspolicies: Object.assign({}, state.accesspolicies, {
          list: Object.assign({}, state.accesspolicies.list, {
            fetching: false,
          }),
        }),
        doingAjax: false,
      });
    }
    case REQUEST_EVENTS_LIST:
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          list: Object.assign({}, state.events.list, {
            fetching: true,
            ready: false,
          }),
        }),
        doingAjax: true,
      });
    case RECEIVED_EVENTS_LIST:
    case RECEIVED_EVENTS_RECENT: {
      const index = action.type.lastIndexOf("_");
      const actionKey = action.type.substr(index + 1).toLowerCase();

      if (
        action.hasOwnProperty("extra") &&
        action.extra.hasOwnProperty("additional") &&
        action.extra.additional === true
      ) {
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            [actionKey]: Object.assign({}, state.events[actionKey], {
              data: [
                ...state.events[actionKey].data,
                ...action.response.data.events,
              ],
              organisations: [
                ...action.response.data.organisations,
                ...state.events[actionKey].organisations,
              ],
              fetching: false,
              needsUpdate: false,
            }),
          }),
          doingAjax: false,
        });
      } else {
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            [actionKey]: Object.assign({}, state.events[actionKey], {
              data: action.response.data.events,
              organisations: action.response.data.organisations,
              meta: ApiHelper.normalizeApiMeta(
                action.response.meta,
                action.response.data.length
              ),
              fetching: false,
              needsUpdate: false,
            }),
          }),
          doingAjax: false,
        });
      }
    }

    case RECEIVED_EVENTS_STATISTICS: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          statistics: Object.assign({}, state.events.statistics, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            timestamp: Date.now(),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_EVENTS_TOTALSCANS: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          totalScans: Object.assign({}, state.events.statistics, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_ACCESSPOLICIES_LIST: {
      return Object.assign({}, state, {
        accesspolicies: Object.assign({}, state.accesspolicies, {
          list: Object.assign({}, state.accesspolicies.list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
        doingAjax: false,
      });
    }
    case RECEIVED_EVENTS_EDITPAYMENTS:
    case RECEIVED_EVENTS_EDIT: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
        doingAjax: false,
      });
    }
    case SET_SELECTED_POLICY: {
      return Object.assign({}, state, {
        accesspolicies: Object.assign({}, state.accesspolicies, {
          selectedPolicy: state.accesspolicies.list.data.filter(
            (policyItem) => policyItem.id == action.policyId
          )[0],
        }),
        doingAjax: false,
      });
    }
    case RECEIVED_ACCESSPOLICIES_EDIT: {
      return Object.assign({}, state, {
        accesspolicies: Object.assign({}, state.accesspolicies, {
          edit: Object.assign({}, state.accesspolicies.edit, {
            fetching: false,
          }),
          list: Object.assign({}, state.accesspolicies.list, {
            data: [...state.accesspolicies.list.data].map((e) => {
              if (e.id === action.response.data.id) {
                return action.response.data;
              }
              if (action.response.data.default) {
                return {...e,default:0};
              }
              return e;
            }),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_ACCESSPOLICIES_CREATE: {
      return Object.assign({}, state, {
        accesspolicies: Object.assign({}, state.accesspolicies, {
          create: Object.assign({}, state.accesspolicies.edit, {
            fetching: false,
          }),
          list: Object.assign({}, state.accesspolicies.list, {
            data: [
              ...[action.response.data],
              ...state.accesspolicies.list.data,
            ],
          }),
        }),
        doingAjax: false,
      });
    }

    case INVALIDATE_EVENTS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          list: Object.assign({}, state.events.list, {
            needsUpdate: true,
          }),
        }),
      });
    }

    case SET_REQUEST_CRITERIA: {
      if (!state.hasOwnProperty(action.endpoint)) {
        return state;
      }

      if (!state[action.endpoint].hasOwnProperty(action.action)) {
        return state;
      }

      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.action]: Object.assign({}, state.events[action.action], {
            criteria: action.criteria,
          }),
        }),
      });
    }

    case RECEIVED_SESSIONS_LIST: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          list: Object.assign({}, state.sessions.list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            timestamp: Date.now(),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_SPEECHES_CREATE: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          list: Object.assign({}, state.sessions.list, {
            data: state.sessions.list.data.map((s) => {
              if (s.id === action.response.data.eventSessionId) {
                s.speeches = [...s.speeches, ...[action.response.data]];
              }
              return s;
            }),
            meta: Object.assign({}, state.sessions.meta, {
              temp: action.response.meta.temp,
            }),
            timestamp: Date.now(),
          }),
          doingAjax: false,
        }),
        speeches: Object.assign({}, state.speeches, {
          create: {
            fetching: false,
          },
        }),
      });
    }

    case RECEIVED_PERSONS_CREATE:
    case RECEIVED_STAFF_CREATE: {
      const endpoint = action.type.split("_")[1].toLowerCase();
      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          list: Object.assign({}, state[endpoint].list, {
            needsUpdate: true,
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_PERSONS_EDIT:
    case RECEIVED_STAFF_EDIT: {
      const endpoint = action.type.split("_")[1].toLowerCase();
      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          list: Object.assign({}, state[endpoint].list, {
            needsUpdate: false,
            data: state[endpoint].list.data.map((s) => {
              if (s.id === action.response.data.id) {
                return action.response.data;
              }
              return s;
            }),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_CACHED_STAFF_LIST:
    case RECEIVED_CACHED_PERSONS_LIST:
    case RECEIVED_STAFF_LIST:
    case RECEIVED_PERSONS_LIST: {
      const endpoint = action.type
        .replace("CACHED_", "")
        .split("_")[1]
        .toLowerCase();
      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          list: Object.assign({}, state[endpoint].list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
        doingAjax: false,
      });
    }
    case RECEIVED_CRM_LIST: {
      const endpoint = action.type
        .replace("CACHED_", "")
        .split("_")[1]
        .toLowerCase();

      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          list: Object.assign({}, state[endpoint].list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
        doingAjax: false,
      });
    }
    case CHANGE_CRM_FILTERS: {
      const columns = { ...state.crmColumns };
      Object.keys(columns).forEach((k) => {
        if (action.filters.includes(k)) {
          columns[k].filter = true;
          columns[k].checked = true;
        } else {
          columns[k].filter = false;
          columns[k].checked = false;
        }
      });
      return Object.assign({}, state, {
        crmColumns: { ...columns },
      });
    }
    case RECEIVED_SCHEMAS_LIST: {
      const endpoint = action.type
        .replace("CACHED_", "")
        .split("_")[1]
        .toLowerCase();
      let columns = {};
      if (action.response.data.length > 0) {
        columns = UsersHelper.crmFilters(
          state.crmColumns,
          action.response.data[0].meta
        );
      }

      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          list: Object.assign({}, state[endpoint].list, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
          orglist: Object.assign({}, state[endpoint].orglist, {
            data: action.response.data[0] || {},
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
        doingAjax: false,
        crmColumns: columns,
      });
    }

    case RECEIVED_SCHEMAS_ORGLIST: {
      const endpoint = action.type
        .replace("CACHED_", "")
        .split("_")[1]
        .toLowerCase();
      return Object.assign({}, state, {
        [endpoint]: Object.assign({}, state[endpoint], {
          orglist: Object.assign({}, state[endpoint].orglist, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            ready: true,
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_SESSIONS_GETSESSIONTYPES: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          sessionTypes: Object.assign({}, state.sessions.sessionTypes, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
            timestamp: Date.now(),
          }),
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_SESSIONS_EDIT: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          list: Object.assign({}, state.sessions.list, {
            fetching: false,
            data: [...state.sessions.list.data].map((e) => {
              if (e.id === action.response.data.id) {
                return action.response.data;
              }
              return e;
            }),
          }),
          edit: {
            fetching: false,
          },
        }),
        doingAjax: false,
      });
    }

    case RECEIVED_SESSIONS_CREATE: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          create: Object.assign({}, state.sessions.edit, {
            fetching: false,
          }),
          list: Object.assign({}, state.sessions.list, {
            data: [...state.sessions.list.data, ...[action.response.data]],
            meta: Object.assign({}, state.sessions.meta, {
              temp: action.response.meta.temp,
            }),
          }),
        }),
        doingAjax: false,
      });
    }

    case TAB_UPDATED: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            data: action.event.data,
            meta: action.event.meta,
            needsUpdate: false,
          }),
          list: Object.assign({}, state.events.list, {
            needsUpdate: true,
          }),
          recent: Object.assign({}, state.events.list, {
            needsUpdate: true,
          }),
        }),
      });
    }

    case CHANGE_PAGE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          edit: Object.assign({}, state.events.edit, {
            needsUpdate: true,
            hooks: Object.assign({}, state.events.hooks, {
              innerHook: Object.assign({}, state.events.hooks.innerHook, {
                type: null,
                hook: null,
              }),
            }),
          }),
        }),
        accesspolicies: Object.assign({}, state.accesspolicies, {
          list: Object.assign({}, state.accesspolicies.list, {
            needsUpdate: true,
          }),
        }),
        staff: Object.assign({}, state.staff, {
          single: initialState.staff.single,
          roles: initialState.staff.roles,
        }),
        sessions: Object.assign({}, state.sessions, {
          list: Object.assign({}, state.sessions.list, {
            needsUpdate: true,
          }),
          sessionTypes: Object.assign({}, state.sessions.sessionTypes, {
            needsUpdate: true,
          }),
        }),
        abstracts: Object.assign({}, initialState.abstracts),
      });
    }
    case RECEIVED_SCHEMAS_UPDATE: {
      return Object.assign({}, state, {
        schemas: Object.assign({}, state.schemas, {
          orglist: Object.assign({}, state.schemas.orglist, {
            data: action.response.data,
          }),
        }),
        doingAjax: false,
      });
    }
    case RECEIVED_SCHEMAS_CREATE: {
      return Object.assign({}, state, {
        schemas: Object.assign({}, state.schemas, {
          orglist: Object.assign({}, state.schemas.orglist, {
            data: action.response.data,
          }),
        }),
        doingAjax: false,
      });
    }

    case REQUEST_PRESETEMAILS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          presetEmails: Object.assign({}, state.events.presetEmails, {
            fetching: true,
          }),
        }),
      });
    }

    case RECEIVED_PRESETEMAILS_LIST: {
      action.response.data.map((email) => {
        email.contacts !== null &&
          email.contacts.map((contact, index) => {
            contact["id"] = index;
          });
      });
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempContacts: [],
          presetEmails: Object.assign({}, state.events.presetEmails, {
            data: action.response.data,
            ready: true,
            fetching: false,
            meta: action.response.meta,
          }),
        }),
        doingAjax: false,
      });
    }
    case RECEIVED_PRESETEMAILS_UPDATE: {
      const oldEmails = state.events.presetEmails.data;
      oldEmails.map((email, index) => {
        if (action.response.data.id === email.id) {
          oldEmails[index].contacts = action.response.data.contacts;
        }
      });
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          presetEmails: Object.assign({}, state.events.presetEmails, {
            data: oldEmails,
          }),
        }),
        doingAjax: false,
      });
    }
    case RECEIVED_PRESETEMAILS_CONTACTS: {
      if (action.response.data[0].hasOwnProperty("id")) {
        const newContacts = JSON.parse(
          JSON.stringify(state.events.tempContacts)
        );
        for (const i in newContacts) {
          if (newContacts[i].id === action.response.data[0].id) {
            newContacts[i] = action.response.data[0];
          }
        }
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempContacts: [...newContacts.map((v, i) => ({ ...v, id: i }))],
          }),
        });
      }
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempContacts: [
            ...state.events.tempContacts.map((v, i) => ({ ...v, id: i })),
            ...action.response.data.map((v, i) => ({
              ...v,
              id: state.events.tempContacts.length + i,
            })),
          ],
        }),
      });
    }
    case REQUEST_QUESTIONNAIRE_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          questionnaires: Object.assign({}, state.events.questionnaires, {
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_QUESTIONNAIRE_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          questionnaires: Object.assign({}, state.events.questionnaires, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }
    case RECEIVED_QUESTIONNAIRE_CREATE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempQuestionnaireContacts: [],
        }),
      });
    }
    case DELETE_CONTACT_QUESTIONNAIRE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempQuestionnaireContacts: [...action.contacts],
        }),
      });
    }
    case ADD_CONTACT_QUESTIONNAIRE: {
      state.events.questionnaires.data.map((questionnaire) => {
        if (questionnaire.id === action.questionnaireId) {
          questionnaire.contacts = [
            ...questionnaire.contacts,
            ...action.contacts,
          ];
        }
      });
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempQuestionnaireContacts: [],
        }),
      });
    }
    case RECEIVED_QUESTIONNAIRE_CUSTOMCONTACTS: {
      if (action.response.data[0].hasOwnProperty("id")) {
        const newContacts = JSON.parse(
          JSON.stringify(state.events.tempQuestionnaireContacts)
        );
        for (const i in newContacts) {
          if (newContacts[i].id === action.response.data[0].id) {
            newContacts[i] = action.response.data[0];
          }
        }
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempQuestionnaireContacts: [
              ...newContacts.map((v, i) => ({ ...v, id: i })),
            ],
          }),
        });
      }
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempQuestionnaireContacts: [
            ...state.events.tempQuestionnaireContacts.map((v, i) => ({
              ...v,
              id: i,
            })),
            ...action.response.data.map((v, i) => ({
              ...v,
              id: state.events.tempQuestionnaireContacts.length + i,
            })),
          ],
        }),
      });
    }
    case RECEIVED_PRESETEMAILS_CUSTOMCONTACTS: {
      if (action.response.data[0].hasOwnProperty("id")) {
        const newContacts = JSON.parse(
          JSON.stringify(state.events.tempCustomContacts)
        );
        for (const i in newContacts) {
          if (newContacts[i].id === action.response.data[0].id) {
            newContacts[i] = action.response.data[0];
          }
        }
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempCustomContacts: [
              ...newContacts.map((v, i) => ({ ...v, id: i })),
            ],
          }),
        });
      }
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempCustomContacts: [
            ...state.events.tempCustomContacts.map((v, i) => ({ ...v, id: i })),
            ...action.response.data.map((v, i) => ({
              ...v,
              id: state.events.tempCustomContacts.length + i,
            })),
          ],
        }),
      });
    }
    case RECEIVED_PRESETEMAILS_CREATE: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempContacts: [],
        }),
      });
    }
    case RECEIVED_PRESETEMAILS_CUSTOM: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          tempCustomContacts: [],
        }),
      });
    }
    case DELETE_CONTACT: {
      if (action.mode === "custom") {
        state.events.tempCustomContacts.splice(action.key, 1);
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempCustomContacts: [...state.events.tempCustomContacts],
          }),
        });
      } else {
        state.events.tempContacts.splice(action.key, 1);
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempContacts: [...state.events.tempContacts],
          }),
        });
      }
    }
    case EDIT_CONTACT: {
      if (action.mode === "custom") {
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempCustomContacts: [
              ...state.events.tempCustomContacts.map((v, i) =>
                i === action.response.data.id ? action.response.data : v
              ),
            ],
          }),
        });
      } else if (action.mode === "question") {
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempQuestionnaireContacts: [
              ...state.events.tempQuestionnaireContacts.map((v, i) =>
                i === action.response.data[0].id ? action.response.data[0] : v
              ),
            ],
          }),
        });
      } else {
        return Object.assign({}, state, {
          events: Object.assign({}, state.events, {
            tempContacts: [
              ...state.events.tempContacts.map((v, i) =>
                i === action.response.data.id ? action.response.data : v
              ),
            ],
          }),
        });
      }
    }
    case REQUEST_SENTEMAILS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          sendEmails: Object.assign({}, state.events.sendEmails, {
            fetching: true,
          }),
        }),
      });
    }
    case RECEIVED_SENTEMAILS_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          sendEmails: Object.assign({}, state.events.sendEmails, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }
    case RECEIVED_SENTEMAILS_UPDATE: {
      let newEmail = [];
      newEmail = state.events.sendEmails.data.map((email) => {
        if (email.id === action.response.data.id) {
          return action.response.data;
        }
        return email;
      });
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          sendEmails: Object.assign({}, state.events.sendEmails, {
            data: newEmail,
            meta: action.response.meta,
          }),
        }),
      });
    }

    case SET_FILTERS: {
      const filters = Object.assign(
        {},
        state.events.list.filters,
        action.filters
      );
      const filtersActive = ApiHelper.hasActiveFilters(filters);

      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          list: Object.assign({}, state.events.list, {
            filters: Object.assign(
              {},
              state.events.list.filters,
              action.filters
            ),
            needsUpdate: true,
            filtersActive,
          }),
        }),
      });
    }

    case REQUEST_INVOICES_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          invoices: Object.assign({}, state.events.invoices, {
            fetching: true,
          }),
        }),
      });
    }

    case RECEIVED_INVOICES_LIST: {
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          invoices: Object.assign({}, state.events.invoices, {
            data: action.response.data,
            meta: action.response.meta,
            fetching: false,
          }),
        }),
      });
    }

    default: {
      if (action.type.substring(0, 7) === "REQUEST") {
        // DOING AJAX

        if (!state.hasOwnProperty(action.endpoint)) {
          return state;
        }

        if (!state[action.endpoint].hasOwnProperty(action.action)) {
          return state;
        }

        return Object.assign({}, state, {
          doingAjax: true,
          [action.endpoint]: Object.assign({}, state[action.endpoint], {
            [action.action]: Object.assign(
              {},
              state[action.endpoint][action.action],
              {
                fetching: true,
                ready: false,
              }
            ),
          }),
        });
      } else if (action.type.substring(0, 8) === "RECEIVED") {
        // AJAX RESOLVED

        const breakAction = action.type.split("_");
        const endpoint = breakAction[breakAction.length - 2].toLowerCase();
        const actionKey = breakAction[breakAction.length - 1].toLowerCase();

        if (!state.hasOwnProperty(endpoint)) {
          return state;
        }

        if (!state[endpoint].hasOwnProperty(actionKey)) {
          return state;
        }

        return Object.assign({}, state, {
          doingAjax: false,
          [endpoint]: Object.assign({}, state[endpoint], {
            [actionKey]: Object.assign({}, state[endpoint][actionKey], {
              fetching: false,
            }),
          }),
        });
      } else if (action.type === "FAILED_REQUEST") {
        let { endpoint, actionKey } = action;

        if (!state.hasOwnProperty(endpoint)) {
          return state;
        }

        if (
          actionKey === "update" &&
          !state[endpoint].hasOwnProperty("update") &&
          state[endpoint].hasOwnProperty("edit")
        ) {
          actionKey = "edit";
        }

        if (actionKey === "bulkImport") {
          endpoint = "accesspolicies";
          actionKey = "list";
        }

        if (!state[endpoint].hasOwnProperty(actionKey)) {
          return state;
        }

        return Object.assign({}, state, {
          doingAjax: false,
          [endpoint]: Object.assign({}, state[endpoint], {
            [actionKey]: Object.assign({}, state[endpoint][actionKey], {
              fetching: false,
            }),
          }),
        });
      } else {
        return state;
      }
    }
  }
};

export default api;
