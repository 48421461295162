import React, { useEffect, useState } from "react";
import App from "@components/App";
import { connect } from "react-redux";
import { getUser, getUserNotifications } from "@actions/appuser";
import {
  changePage,
  logUser,
  dismissNotification,
  hideModal,
  confirmChanges,
  promptChanges,
  hideNormalModal,
} from "@actions";
import { changeSection } from "@actions/editmenu";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import ListEvents from "@components/events/list";
import EditEvent from "@components/events/edit/index";
import ListCRM from "@components/crm";
import ListHotels from "@components/hotels";
import ListHotelRooms from "@components/hotels/Rooms";
import ListSponsors from "@components/sponsors";
import ListSponsorPersons from "@components/sponsors/partials/Persons";
import ListStaff from "@components/staff/list";
import ListStaffRoles from "@components/staff/roles";
import ListBankAccounts from "@components/BankAccounts";
import MyAccount from "@components/account/Account";
import RoomScanning from "@components/scanning/RoomScanning";
import MinuteScanning from "@components/scanning/MinuteScanning";

import JudgeAbstracts from "@components/judge/Abstracts";
import JudgeAbstract from "@components/judge/SingleAbstract";
import RentableScanning from "../../components/scanning/RoomScanning/RentableScanning";

const map = {
  "list-events": () => <ListEvents />,
  "list-crm": (props) => <ListCRM {...props} />,
  accommodation: (props) => <ListHotels {...props} />,
  "accommodation-rooms": (props) => <ListHotelRooms {...props} />,
  "list-sponosors": (props) => <ListSponsors {...props} />,
  "list-sponosors-persons": (props) => <ListSponsorPersons {...props} />,
  "list-staff": (props) => <ListStaff {...props} />,
  "list-staff-roles": (props) => <ListStaffRoles {...props} />,
  "bank-accounts": (props) => <ListBankAccounts {...props} />,
  "room-scanning": (props) => <RoomScanning {...props} />,
  "rentable-scanning": (props) => <RentableScanning {...props} />,
  "minutes-scanning": (props) => <MinuteScanning {...props} />,
  myaccount: (props) => <MyAccount {...props} />,
  abstracts: (props) => <JudgeAbstracts {...props} />,
  abstract: (props) => <JudgeAbstract {...props} />,
  "edit-event": (props) => <EditEvent {...props} />,
  participants: (props) => <EditEvent {...props} />,
};

const Wrapper = (props) => {
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!Object.keys(props.user?.data || {}).length && !initialized) {
      setInitialized(true);
      props.getUser().then(() => {
        setLoading(false);
      });
    }
  }, [props]);

  useEffect(() => {
    props.changePage({ title: props.title, pathname, params, navigate });
  }, [props.title]);

  useEffect(() => {
    if (props.innerPage) {
      props.changeSection(props.innerPage);
    }
  }, [props.innerPage]);

  if (loading) return null;
  return <App {...props}>{map[props.title]({ ...params, pathname })}</App>;
};

Wrapper.propTypes = {
  innerPage: PropTypes.string,
  user: PropTypes.object,
  getUser: PropTypes.func,
  changeSection: PropTypes.func,
  changePage: PropTypes.func,
  title: PropTypes.string,
};

export default connect(
  (state, ownProps) => {
    const obj = {
      router: {
        history: ownProps.history,
        location: ownProps.location,
      },
      user: state.appuser,
      notifications: state.notifications,
      page: state.page,
      modal: state.page.modal,
      normalModal: state.page.normalModal,
      actionBar: state.actionBar,
      tempRoute: state.page.tempRoute,
      hasChanges: state.page.hasChanges,
    };

    return obj;
  },
  (dispatch) => {
    return {
      changePage: (page) => {
        dispatch(changePage(page));
      },
      changeSection: (page) => {
        dispatch(changeSection(page));
      },

      logUser: (user) => {
        dispatch(logUser(user));
      },

      getUser: () => {
        return new Promise((resolve, reject) => {
          dispatch(getUser())
            .then((user) => dispatch(getUserNotifications(user)))
            .then((user) => resolve(user))
            .catch((err) => reject(err));
        });
      },

      dismissNotification: (notification) => {
        dispatch(dismissNotification(notification));
      },

      hideModal: () => {
        return dispatch(hideModal());
      },

      confirmChanges: (proceed = false) => {
        dispatch(confirmChanges(proceed));
      },

      promptChanges: (location, ce) => {
        dispatch(promptChanges(location.pathname, "page", ce));
      },

      hideNormalModal: () => {
        dispatch(hideNormalModal());
      },
    };
  }
)(Wrapper);
