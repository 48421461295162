import React from "react";
import { AddButton } from "@layout/Buttons";
import Table from "@layout/Table";
import PropTypes from "prop-types";

const IndependentTable = (props) => {
  return (
    <div
      className={"independent-table-container"}
      key={`type-item-${props._key}`}
    >
      <div>
        <label className="it-title">{props.tableName}</label>
        <div className="it-add-btn">
          <AddButton
            disabled={props.disableAdd}
            fontSize="large"
            onClick={props.onAdd}
          />
        </div>
        <div style={{ position: "relative", overflow: "auto" }}>
          <Table
            sortable={!!props.onSortEnd}
            onSortEnd={(newIndex, item, newItems) => {
              const newSortList = newItems.map((sorted, index) => ({
                id: sorted.id,
                index,
              }));
              props.onSortEnd(newSortList);
            }}
            columns={props.columns}
            updating={false}
            items={props.items}
            containerClassName={`table-container  independent-table`}
            onRender={props.onRender}
          />
        </div>
      </div>
    </div>
  );
};

IndependentTable.defaultProps = {};
IndependentTable.propTypes = {
  onRender: PropTypes.func,
  onAdd: PropTypes.func,
  onSortEnd: PropTypes.func,
  _key: PropTypes.any,
  tableName: PropTypes.string,
  columns: PropTypes.object,
  items: PropTypes.array,
  disableAdd: PropTypes.bool,
};
export default IndependentTable;
