import React from "react";
import PropTypes from "prop-types";
import { HeaderPagination } from "@layout/Pagination";
import NewEvent from "@components/events/create/";

import Search from "@layout/Search";
export default class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      selected: "all",
      closeAllSelects: false,
      reset: false,
      resetRange: false,
      closeRange: false,
      filters: this.initialFilters,
    };
    this.timeout = null;
  }

  setSelected(selected) {
    if (this.state.selected === selected) return;
    this.setState({ selected });
    if (selected === "all")
      return this.props.loadEvents(
        {
          archived: false,
          search: this.state.search,
        },
        { status: "" }
      );
    if (selected === "archived") {
      return this.props.loadEvents({
        archived: true,
        search: this.state.search,
      });
    }
    return this.props.loadEvents(
      { archived: false, search: this.state.search, p: 1 },
      { status: [selected] }
    );
  }
  debounce(callback) {
    window.clearTimeout(this.timeout);
    this.timeout = setTimeout(callback, 500);
  }
  render() {
    return (
      <div className="top-bar">
        <div>
          <div className="left"></div>
          <div className="middle">
            <ul>
              <li
                onClick={() => this.setSelected("all")}
                className={this.state.selected === "all" ? "selected" : ""}
              >
                All
              </li>
              <li
                onClick={() => this.setSelected("running")}
                className={this.state.selected === "running" ? "selected" : ""}
              >
                Running
              </li>
              <li
                onClick={() => this.setSelected("upcoming")}
                className={this.state.selected === "upcoming" ? "selected" : ""}
              >
                Upcoming
              </li>
              <li
                onClick={() => this.setSelected("completed")}
                className={
                  this.state.selected === "completed" ? "selected" : ""
                }
              >
                Completed
              </li>
              <li
                onClick={() => this.setSelected("archived")}
                className={this.state.selected === "archived" ? "selected" : ""}
              >
                Archived
              </li>
            </ul>
          </div>
          <div className="right"></div>
        </div>
        <div>
          <div className="left">
            <Search
              onSearch={(search) => {
                this.setState({ search });
                const { rpp, status, archived } = this.props.data.meta;
                this.debounce(() =>
                  this.props.loadEvents({
                    p: 1,
                    rpp,
                    status,
                    archived,
                    search,
                  })
                );
              }}
            />
          </div>

          <div className="right">
            <HeaderPagination
              fetching={this.props.data.fetching}
              p={this.props.data.meta.p}
              rpp={this.props.data.meta.rpp}
              totalRows={this.props.data.meta.totalRows}
              onChangePage={(criteria) => {
                const { status, archived = false } = this.props.data.meta;
                this.props.loadEvents(
                  { ...criteria, archived, search: this.state.search },
                  { status: [status] }
                );
              }}
            />
            {this.props.user.orgAdmin === 1 && (
              <div className="create-conference">
                <button
                  className="btn create"
                  onClick={() => {
                    this.props.showModal(
                      <NewEvent hideModal={this.props.hideModal} />
                    );
                  }}
                >
                  Create new event
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  organisations: PropTypes.array.isRequired,
  loadEvents: PropTypes.func.isRequired,
  data: PropTypes.object,
  user: PropTypes.object,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
};
