import React from "react";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import SingleVideo from "./SingleVideo";
import PropTypes from "prop-types";
import {
  createClientPanelVideoSponsor,
  updateClientPanelSettings,
  deleteClientPanelVideoSponsor,
  showModal,
} from "@actions";
import { connect } from "react-redux";

const sortVideos = (a, b) => {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
};

const VideoSponsors = (props) => {
  const onRender = (col, item) => {
    switch (col.key) {
      case "clientVideoSponsorImage": {
        return (
          <img
            src={
              (item.clientVideoSponsorImage || [])[0]
                ? item.clientVideoSponsorImage[0].url
                : "/images/medlive-noimage.jpg"
            }
            style={{ objectFit: "contain", width: 50, height: 40 }}
          />
        );
      }
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="video sponsor"
              item={item}
              onDelete={() => {
                return props.deleteClientPanelVideoSponsor(
                  props.event.id,
                  item.id
                );
              }}
              onEdit={() => {
                return props.showModal(
                  <SingleVideo
                    item={item}
                    onSave={(data, preserveModal, file) => {
                      if (
                        !file &&
                        typeof data.clientVideoSponsorImage !== "object"
                      ) {
                        if (data.clientVideoSponsorImage) {
                          const matches =
                            data.clientVideoSponsorImage.match(/\d+/g);
                          data.clientVideoSponsorImage = [parseInt(matches[0])];
                        }
                        return props.updateClientPanelSettings(
                          props.event.id,
                          data,
                          "videos-sponsors",
                          item.id,
                          preserveModal
                        );
                      } else {
                        return props.updateClientPanelSettings(
                          props.event.id,
                          data,
                          "videos-sponsors",
                          item.id,
                          preserveModal,
                          file
                        );
                      }
                    }}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <div className="video-sponsor-container" key={`type-item-${props.index}`}>
      <Table
        columns={{
          clientVideoSponsorImage: {
            name: "Video Sponsor Image",
          },
          url: {
            name: "Action URL",
          },
          actions: {
            name: "",
          },
        }}
        sortable={true}
        onSortEnd={(newIndex, item, newItems) => {
          const newSortList = newItems.map((sorted, index) => ({
            id: sorted.id,
            index,
          }));
          return props.updateClientPanelVideoSponsorOrder(props.event.id, {
            videoSponsors: newSortList,
          });
        }}
        updating={props.fetching}
        items={props.event.clientPanelSettings.videos.sponsorsSchema.sort(
          sortVideos
        )}
        containerClassName={`table-container `}
        onRender={onRender}
      />
    </div>
  );
};

VideoSponsors.propTypes = {
  event: PropTypes.object,
  deleteClientPanelVideoSponsor: PropTypes.func,
  createClientPanelVideoSponsor: PropTypes.func,
  showModal: PropTypes.func,
  updateClientPanelSettings: PropTypes.func,
  index: PropTypes.number,
  fetching: PropTypes.bool,
  updateClientPanelVideoSponsorOrder: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteClientPanelVideoSponsor: (eventId, data) => {
      return dispatch(deleteClientPanelVideoSponsor(eventId, data));
    },
    showModal: (content) => dispatch(showModal(content)),
    createClientPanelVideoSponsor: (eventId, data) => {
      return dispatch(createClientPanelVideoSponsor(eventId, data));
    },
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoSponsors);
