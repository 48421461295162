import React from "react";
import PropTypes from "prop-types";

const BottomLineInput = (props) => {
  return (
    <div className={`bottom-line ${props.className ? props.className : ""}`}>
      {props.placeholderPosition === "start" ? (
        <React.Fragment>
          <span
            className={
              props.placeholderClassName ? props.placeholderClassName : ""
            }
          >
            {props.placeholder ? props.placeholder + ":" : ""}
          </span>
          <input
            placeholder={props.placeholderInner || ""}
            type="text"
            value={props.value}
            onChange={props.onChange}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span
            className={
              props.placeholderClassName ? props.placeholderClassName : ""
            }
          >
            {props.placeholder ? props.placeholder + ":" : ""}
          </span>
          <input
            placeholder={props.placeholderInner || ""}
            type="text"
            value={props.value}
            onChange={props.onChange}
          />
        </React.Fragment>
      )}
    </div>
  );
};

BottomLineInput.propTypes = {
  name: PropTypes.string,
  base: PropTypes.object,
  className: PropTypes.string,
  placeholderPosition: PropTypes.string,
  placeholderClassName: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderInner: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

BottomLineInput.defaultProps = {
  placeholderPosition: "start",
};

export default BottomLineInput;
