import React from "react";
import IndependentTable from "@layout/IndependentTable";
import TableActions from "@layout/TableActions";
import SingleSponsor from "./SingleSponsor";
import PropTypes from "prop-types";

export default function Sponsors(props) {
  const onRender = (col, item) => {
    switch (col.key) {
      case "clientSponsorImage": {
        return (
          <img
            src={
              (item.clientSponsorImage || [])[0]
                ? item.clientSponsorImage[0].url
                : "/images/medlive-noimage.jpg"
            }
            style={{ objectFit: "contain", width: 50, height: 40 }}
          />
        );
      }
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="sponsor"
              item={item}
              onDelete={() => {
                return props.deleteClientPanelSponsor(props.event.id, item.id);
              }}
              onEdit={() => {
                return props.showModal(
                  <SingleSponsor
                    item={item}
                    options={props.sponsorsTypes}
                    onSave={(data) => {
                      return props.updateClientPanelSettings(
                        props.event.id,
                        data,
                        "sponsors",
                        item.id
                      );
                    }}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <IndependentTable
      tableName={props.type.name}
      _key={props.index}
      onSortEnd={(newSortList) => {
        return props.updateClientPanelSponsorsOrder(props.event.id, {
          sponsors: newSortList,
        });
      }}
      columns={{
        clientSponsorImage: {
          name: "Sponsor Image",
        },
        url: {
          name: "Action URL",
        },
        actions: {
          name: "",
        },
      }}
      items={props.items}
      onRender={onRender}
      onAdd={() =>
        props.showModal(
          <SingleSponsor
            item={{ eventClientPanelSponsorTypeId: props.type.id }}
            mode="create"
            options={props.sponsorsTypes}
            onSave={(data) => {
              return props.createClientPanelSponsor(props.event.id, {
                ...data,
                index: props.items.length,
              });
            }}
          />
        )
      }
    />
  );
}

Sponsors.propTypes = {
  event: PropTypes.object,
  sponsorsTypes: PropTypes.array,
  items: PropTypes.array,
  createClientPanelSponsor: PropTypes.func,
  type: PropTypes.object,
  showModal: PropTypes.func,
  index: PropTypes.number,
  updateClientPanelSponsorsOrder: PropTypes.func,
  deleteClientPanelSponsor: PropTypes.func,
  updateClientPanelSettings: PropTypes.func,
};
