import {
  CHANGE_PAGE,
  APPLY_FILTER,
  LOG_USER,
  CONTENT_LOADED,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_BREADCRUMB,
  hideModal,
  MODAL_HIDED,
  TOGGLE_FULL_SCREEN,
  SELECT_PRINTER,
  PRINTER_DELETED,
  SETUP_PRINTERS,
  DYMO_FRAMEWORK_ERROR,
  FORM_CHANGED,
  PROMPT_HAS_CHANGES,
  HIDE_PROMPT_STAY,
  HIDE_PROMT_PROCEED,
  TAB_UPDATED,
  FORM_CANCELLED,
  CLEAR_CHANGES,
  SHOW_NORMAL_MODAL,
  HIDE_NORMAL_MODAL,
  ENABLE_NATIVE_PRINTING,
  DISABLE_NATIVE_PRINTING,
  CHANGE_PRINTER_TYPE,
} from "@actions";
import { pages as configuration } from "@config/conf";
import PrinterHelper from "@helpers/printer-helper";
PrinterHelper.init(PrinterHelper.getPrinterType()).catch(() =>
  console.error("No Printers found")
);

const initialState = {
  contentLoaded: false,
  title: "",
  url: "",
  path: "",
  params: {},
  breadcrumb: {},
  router: {},
  configuration,
  fullScreen: false,
  hasChanges: false,
  browserLocked: true,
  tempRoute: {
    active: false,
    changeType: "page",
    section: null,
    tab: null,
    page: null,
    ce: false,
    escapeFunction: null,
  },
  printers: {
    ready: false,
    availablePrinters: [],
    selectedPrinter: null,
    printerType: PrinterHelper.getPrinterType(),
  },
  modal: {
    showModal: false,
    header: null,
    content: null,
    onClose: hideModal,
    type: "sideModal",
    large: false,
    className: "",
  },
  normalModal: {
    active: false,
    content: null,
    footer: null,
    className: "",
  },
  confirm: {
    active: false,
    content: null,
  },
  printing: {
    badge: null,
    certification: null,
    nativePrintingActive: false,
  },
};

const page = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_PROMT_PROCEED:
    case FORM_CANCELLED:
    case TAB_UPDATED:
    case CLEAR_CHANGES: {
      return Object.assign({}, state, {
        tempRoute: initialState.tempRoute,
        hasChanges: false,
      });
    }

    case HIDE_PROMPT_STAY:
      return Object.assign({}, state, {
        tempRoute: Object.assign(
          {},
          {
            active: false,
          }
        ),
      });

    case PROMPT_HAS_CHANGES: {
      return Object.assign({}, state, {
        tempRoute: Object.assign(
          {},
          {
            active: true,
            changeType: action.changeType,
            section: action.section,
            tab: action.tab,
            title: action.title,
            pathname: action.pathname,
            params: action.params,
            navigate: action.navigate,
            page: action.page,
            ce: action.ce,
            escapeFunction: action.escapeFunc,
          }
        ),
      });
    }

    case CHANGE_PRINTER_TYPE: {
      return Object.assign({}, state, {
        printers: Object.assign({}, state.printers, {
          printerType: action.printerType,
        }),
      });
    }

    case FORM_CHANGED:
      return Object.assign({}, state, {
        hasChanges: true,
        tempRoute: Object.assign({}, state.tempRoute, {
          unBlock: action.unBlock,
        }),
        browserLocked: action.browserLocked,
      });

    case DYMO_FRAMEWORK_ERROR:
      return Object.assign({}, state, {
        printers: Object.assign({}, state.printers, {
          ready: true,
        }),
      });

    case SETUP_PRINTERS:
      return Object.assign({}, state, {
        printers: Object.assign({}, state.printers, {
          availablePrinters: action.data.printers,
          selectedPrinter: action.data.selectedPrinter,
          ready: true,
        }),
      });

    case PRINTER_DELETED:
      return Object.assign({}, state, {
        printers: Object.assign({}, state.printers, {
          selectedPrinter: null,
        }),
      });

    case SELECT_PRINTER:
      return Object.assign({}, state, {
        printers: Object.assign({}, state.printers, {
          selectedPrinter: action.printer,
        }),
      });

    case TOGGLE_FULL_SCREEN:
      return Object.assign({}, state, {
        fullScreen: state.fullScreen ? false : true,
      });

    case CHANGE_PAGE: {
      if (action.navigate) {
        action.navigate(action.pathname);
      }
      return Object.assign({}, state, {
        contentLoaded: false,
        title: action.title,
        pathname: action.path,
        params: action.params,
        navigate: action.navigate,
      });
    }

    case SET_BREADCRUMB:
      return Object.assign({}, state, {
        breadcrumb: {},
      });

    case APPLY_FILTER:
      return Object.assign({}, state, {
        filter: action.filter,
        tableTitle: action.tableTitle,
      });

    case CONTENT_LOADED:
      return Object.assign({}, state, {
        contentLoaded: true,
      });

    case ENABLE_NATIVE_PRINTING:
      return Object.assign({}, state, {
        printing: Object.assign({}, state.printing, {
          nativePrintingActive: true,
          badge: action.item === "badge" ? action.content : null,
          certification: action.item === "cert" ? action.content : null,
          callback: action.callback,
        }),
      });

    case DISABLE_NATIVE_PRINTING:
      return Object.assign({}, state, {
        printing: Object.assign({}, state.printing, {
          nativePrintingActive: false,
          badge: null,
          certification: null,
          callback: null,
        }),
      });

    case HIDE_NORMAL_MODAL:
      return Object.assign({}, state, {
        normalModal: Object.assign({}, state.normalModal, {
          active: false,
        }),
      });

    case SHOW_NORMAL_MODAL:
      return Object.assign({}, state, {
        normalModal: Object.assign({}, state.normalModal, {
          active: true,
          title: action.title ?? null,
          content: action.content ?? null,
          footer: action.footer ?? null,
          className: action.className ?? null,
        }),
      });

    case SHOW_MODAL:
      return Object.assign({}, state, {
        modal: Object.assign({}, state.modal, {
          showModal: true,
          header: action.header ? action.header : null,
          content: action.content,
          onHide: action.onHide ? action.onHide : false,
          onSave: action.onSave ? action.onSave : false,
          onExited: action.onExited ? action.onExited : false,
          type: action.modalType ? action.modalType : "sideModal",
          large: action.large ? action.large : false,
          className: action.className ? action.className : "",
        }),
      });

    case HIDE_MODAL:
      return Object.assign({}, state, {
        modal: Object.assign({}, state.modal, {
          showModal: false,
        }),
      });

    case MODAL_HIDED:
      return Object.assign({}, state, {
        modal: Object.assign({}, state.modal, {
          content: null,
          className: "",
        }),
      });

    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case LOG_USER:
      return Object.assign({}, state, {
        fname: action.user.fname,
        lname: action.user.lname,
        email: action.user.email,
        role: action.user.role,
      });
    default:
      return state;
  }
};

export { page, user };
