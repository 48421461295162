import React from "react";
import Switch from "@layout/Switch";
import moment from "moment";
import Banner from "@layout/Banner";
import { Link } from "react-router-dom";
import { isEmpty } from "@helpers";
import Table from "@layout/Table";
import PropTypes from "prop-types";
import SelectCheckboxes from "@layout/SelectCheckboxes";
import Modal from "@layout/Modal";
import DateRange from "@layout/DateRange";
import ClientPanelSelect from "@layout/ClientPanelSelect";
import { Navigate } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import SubmitContainer from "@layout/SubmitContainer";
import TableActions from "../../../layout/TableActions";
let saveSort = "createdAt";

export default class ListView extends React.Component {
  constructor(props) {
    super(props);

    [
      "prepareRoleTypes",
      "onSort",
      "toggleActions",
      "onRender",
      "handleBody",
      "handleRole",
      "getRoleValue",
      "changeRole",
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));

    this.excludedArea = null;
    this.initialObj = {
      name: "",
      startDate: null,
      endDate: null,
      urlAlias: "",
    };

    this.state = {
      toggledCells: [],
      wordConfirmed: false,
      roles: {},
      selected: {},
      ready: false,
      showDuplicate: false,
      duplicateObj: Object.assign({}, this.initialObj),
      eventUrl: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.roleMode &&
      JSON.stringify(nextProps.data?.events) !==
        JSON.stringify(this.props.data?.events)
    ) {
      this.prepareRoleTypes(nextProps.data.events);
    }
  }

  componentDidMount() {
    if (this.props.roleMode) {
      this.prepareRoleTypes(this.props.data.events);
    }
    document.body.addEventListener("click", this.handleBody);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBody);
  }

  changeRole(eventId, orgId, personId, prevRole, newRole) {
    if (newRole[0] === "event_policy_admin" && newRole.length > 1) {
      if (newRole[newRole.length - 1].substr(0, 7) === "policy-") {
        return this.setState(
          {
            selected: Object.assign({}, this.state.selected, {
              [eventId]: newRole,
            }),
          },
          () => {
            return this.props
              .changeRole(
                eventId,
                orgId,
                personId,
                prevRole,
                this.state.selected[eventId]
              )
              .catch((e) => {
                console.error(e);
              });
          }
        );
      }
    }

    if (newRole[0].substr(0, 7) === "policy-") {
      return this.setState({
        selected: Object.assign({}, this.state.selected, {
          [eventId]: ["event_policy_admin", ...newRole],
        }),
      });
    }

    newRole = newRole[newRole.length - 1];
    const value = this.getRoleValue({ type: newRole });
    const data = {};
    const selected = [newRole];

    if (newRole === "event_policy_admin") {
      this.props.loadPolicies(eventId).then((policies) => {
        this.props.changeRole(eventId, orgId, personId, prevRole, newRole);

        policies.data.map((p) => {
          data[`policy-${p.id}`] = {
            label: `Policy: ${p.name}`,
            containerClassName: "policy-checkboxes",
          };
        });

        let additional = Object.entries(data).map(([k]) => {
          return k;
        });

        additional = [];

        this.setState({
          selected: Object.assign({}, this.state.selected, {
            [eventId]: [...selected, ...additional],
          }),
          roles: Object.assign({}, this.state.roles, {
            [eventId]: Object.assign({}, this.state.roles[eventId], data),
          }),
        });

        return;
      });
    } else {
      const prev = this.state.roles[eventId];
      this.setState(
        {
          roles: Object.assign({}, this.state.roles, {
            [eventId]: Object.assign({}, value),
          }),
          selected: Object.assign({}, this.state.selected, {
            [eventId]: selected,
          }),
        },
        () =>
          this.props
            .changeRole(eventId, orgId, personId, prevRole, newRole)
            .catch((e) => {
              console.error(e);
              this.setState({
                roles: Object.assign({}, this.state.roles, {
                  [eventId]: Object.assign({}, prev),
                }),
                selected: Object.assign({}, this.state.selected, {
                  [eventId]: prevRole,
                }),
              });
            })
      );
    }
  }

  handleBody(e) {
    if (
      this.state.toggledCells.length > 0 &&
      !this.excludedArea.contains(e.target)
    ) {
      this.setState({ toggledCells: [] });
    }
  }

  onSort(key) {
    const { data, loadEvents } = this.props;

    saveSort = key;
    key = mapKeys(key);

    const { rpp = 10, search = "", status = "", archived = false } = data.meta;
    const params = { rpp, p: 1, search, status, archived, orderBy: key };

    if (data.meta.orderBy === key) {
      params.order = data.meta.order === "ASC" ? "DESC" : "ASC";
    }

    loadEvents(params, { status: [status] });
  }

  toggleActions(e, id) {
    const { target } = e;
    this.excludedArea = target.classList.contains("event-actions")
      ? e.target.parentNode
      : e.target.parentNode.parentNode;

    const toggledCells = [id];

    this.setState({ toggledCells });
  }

  handleRole(id, selected) {
    const newRoles = this.getRoleValue({ type: selected[selected.length - 1] });

    this.setState({
      roles: Object.assign({}, this.state.roles, {
        [id]: newRoles,
      }),
    });
  }

  getRoleValue(role) {
    const obj = {};

    Object.entries(this.props.roleTypes).map(([k, v]) => {
      const value = Object.assign({}, v);

      if (!role) {
        role = { type: "none" };
      }

      if (k === role.type) {
        value.checked = true;
      }

      obj[k] = value;
    });

    return obj;
  }

  prepareRoleTypes(events) {
    const roles = {};
    const eventPolicies = [];
    const selected = {};

    events.map((event) => {
      const role = event.role;
      roles[event.id] = this.getRoleValue(role);
    });

    this.setState({ roles });

    if (eventPolicies.length > 0) {
      const promises = [];

      eventPolicies.map((eid) => {
        const p = new Promise((resolve) => {
          this.props.loadPolicies(eid).then((policy) => {
            resolve({ [eid]: policy.data });
          });
        });

        promises.push(p);
      });

      Promise.all(promises).then((policiesArr) => {
        const roles = Object.assign({}, this.state.roles);

        policiesArr.map((p) => {
          const eventId = Object.keys(p)[0];
          const policies = p[eventId];

          if (policies.length > 0) {
            const data = {};
            policies.map((p) => {
              data[`policy-${p.id}`] = {
                label: `Policy: ${p.name}`,
                containerClassName: "policy-checkboxes",
              };
            });

            roles[eventId] = Object.assign({}, this.state.roles[eventId], data);
          }
        });

        this.setState({ roles, selected });
      });
    }
  }
  onRowClick(item, e) {
    if (
      e.target.classList.contains("kmb-name") ||
      e.target.matches(":where(.kmb-name) :scope")
    ) {
      return this.setState({
        eventUrl: `/administrator/events/${item.id}/basic-setup`,
      });
    }
  }
  onRender(col, item) {
    // pass a callback function
    // to render custom td's
    // based on col key and value

    switch (col.key) {
      case "actionButtons": {
        return (
          <ClientPanelSelect
            eventFreeAccess={item.freeAccess}
            eventActive={item.active}
            policies={Object.values(item.policies)}
            eventURLAlias={item.urlAlias}
            controlBackground={"transparent"}
            color={"#516673"}
            placement={"left"}
            hoverColor={"#2a79d1"}
            border={"none"}
            width={145}
          />
        );
      }

      case "owner": {
        return item.organisation.name;
      }

      case "role": {
        return (
          <SelectCheckboxes
            id={`roles-${item.id}`}
            options={this.state.roles[item.id] || {}}
            selected={
              this.state.selected.hasOwnProperty(item.id)
                ? this.state.selected[item.id]
                : []
            }
            roles={true}
            onChange={(selected) => {
              this.changeRole(
                item.id,
                item.orgId,
                this.props.page.params.staffId,
                item.role,
                selected
              );
            }}
          />
        );
      }

      case "banners": {
        return (
          <div className="event-banner-in-listing">
            <Banner
              bannerUrl={col.value.length > 0 ? col.value[0].url : ""}
              bannerAlt={item.name}
            />
          </div>
        );
      }

      case "startDate":
      case "endDate": {
        return (
          <div className="date-wrapper">
            <p>{moment(col.value.tz).format("DD MMM YYYY")}</p>
          </div>
        );
      }

      case "status": {
        const status = col.value;

        return (
          <div className={`status-indicator ${status.toLowerCase()}`}>
            {status}
          </div>
        );
      }

      case "active": {
        return (
          <Switch
            id={`${item.id}`}
            isActive={item.active}
            onChange={(active) =>
              this.props.toggleIsActive(item.id, active ? 1 : 0)
            }
          />
        );
      }

      case "actions": {
        let duplicateTd = null,
          archiveTd = null,
          unarchiveTd = null,
          minutesScanningScreenTd = null;
        if (this.props.user.orgAdmin === 1) {
          duplicateTd = (
            <Tooltip title="Duplicate Event">
              <span className="confirm-holder">
                <div>
                  <button
                    className="btn circle"
                    onClick={() => this.setState({ showDuplicate: item.id })}
                  >
                    <span className="fa fa-copy"></span>
                  </button>
                </div>
              </span>
            </Tooltip>
          );

          archiveTd = (
            <Tooltip title="Archive">
              <span className="confirm-holder">
                <div>
                  <button
                    className="btn circle"
                    onClick={() =>
                      this.props.updateEvent(item.id, { archived: 1 })
                    }
                  >
                    <span className="icon-archive"></span>
                  </button>
                </div>
              </span>
            </Tooltip>
          );

          unarchiveTd = (
            <Tooltip title="Un-archive">
              <span className="confirm-holder">
                <div>
                  <button
                    className="btn circle"
                    onClick={() =>
                      this.props.updateEvent(item.id, { archived: 0 })
                    }
                  >
                    <span className="icon-archive"></span>
                  </button>
                </div>
              </span>
            </Tooltip>
          );

          minutesScanningScreenTd = (
            <Tooltip title="Minutes Scanning Screen">
              <Link
                to={`/events/${item.id}/minutescanning/`}
                className="btn circle"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon-scanning"></span>
              </Link>
            </Tooltip>
          );
        }

        const policies = {};
        if (!isEmpty(item.policies)) {
          policies[0] = "Select a policy";
          Object.entries(item.policies).map(([index, policy]) => {
            if (policy.type === "private") {
              policies[index] = policy.name;
            }
          });
        }

        return (
          <TableActions
            item={item}
            entity="event"
            onDelete={(item) => this.props.deleteEvent(item.id)}
            edit={false}
            custom={() => (
              <>
                {duplicateTd}
                {minutesScanningScreenTd}
                {item.archived === 0 ? archiveTd : unarchiveTd}
              </>
            )}
          />
        );
      }
    }
  }

  render() {
    const { data, loadEvents, roleMode } = this.props;

    // columns must be an Object
    // with items keys as keys
    // and th name as value
    // sortable default is false

    const columns = {
      id: {
        name: "ID",
        sortable: true,
      },
      banners: {
        name: "",
      },
      name: {
        name: "Name",
        sortable: true,
      },
      role: {
        name: "Role",
      },

      status: {
        name: "Status",
        sortable: true,
      },
      startDate: {
        name: "Start Date",
        sortable: true,
      },
      endDate: {
        name: "End Date",
        sortable: true,
      },
      actionButtons: {
        name: "",
      },
      actions: {
        name: "",
      },
    };

    const { events, meta } = data;
    let key = meta.orderBy;

    if (key === "createdAt") key = "id";

    if (saveSort === "status") key = saveSort;

    if (columns.hasOwnProperty(key)) {
      columns[key]["activeSort"] = true;
    }

    if (roleMode) {
      delete columns["active"];
      delete columns["actions"];
      delete columns["actionButtons"];
    } else {
      if (this.state.eventUrl) {
        return <Navigate replace to={this.state.eventUrl} />;
      }
      delete columns["role"];
    }

    if (this.props.user.orgAdmin !== 1) {
      delete columns["active"];
    }

    const duplicateEvent =
      this.state.showDuplicate !== false
        ? [...events].filter((e) => e.id === this.state.showDuplicate)[0]
        : false;
    const { name, startDate, endDate } = this.state.duplicateObj;
    const readyToSubmit = name && startDate && endDate;

    return (
      <div className="main-wrapper">
        <Table
          filtered={this.props.filtered}
          id={"event-list"}
          items={events}
          columns={columns}
          onRender={this.onRender}
          containerClassName={`table-container ${
            !roleMode ? "main-list-events" : ""
          }`}
          updating={this.props.fetching}
          onTrClick={(item, e) =>
            this.props.roleMode ? () => {} : this.onRowClick(item, e)
          }
          onSort={this.onSort}
        />
        {this.state.showDuplicate && (
          <Modal
            title={"Duplicate Event"}
            show={this.state.showDuplicate ? true : false}
            onClose={() => {
              this.setState({
                duplicateObj: Object.assign({}, this.initialObj),
                showDuplicate: false,
              });
            }}
            className="duplicate-event"
          >
            <div>
              <div className="form-container">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group">
                    <p>This action will duplicate the event with name:</p>
                    <p>
                      <span>{duplicateEvent.name}</span>
                    </p>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.duplicateObj.name}
                      onChange={(e) =>
                        this.setState({
                          duplicateObj: Object.assign(
                            {},
                            this.state.duplicateObj,
                            {
                              name: e.target.value,
                            }
                          ),
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <DateRange
                      from={{ value: this.state.duplicateObj.startDate }}
                      to={{ value: this.state.duplicateObj.endDate }}
                      onChange={(value) => {
                        value.from =
                          value.from &&
                          moment(value.from, "DD-MM-YYYY").format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                        value.to =
                          value.to &&
                          moment(value.to, "DD-MM-YYYY").format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                        this.setState({
                          duplicateObj: Object.assign(
                            {},
                            this.state.duplicateObj,
                            {
                              startDate: value.from,
                              endDate: value.to,
                            }
                          ),
                        });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Url Alias"
                      onChange={(e) =>
                        this.setState({
                          duplicateObj: Object.assign(
                            {},
                            this.state.duplicateObj,
                            {
                              urlAlias: e.target.value,
                            }
                          ),
                        })
                      }
                    />
                  </div>
                  <SubmitContainer
                    onSubmit={() => {
                      this.props
                        .duplicateEvent(
                          duplicateEvent.id,
                          this.state.duplicateObj
                        )
                        .then(() => {
                          this.props.addNotification(
                            "Event duplicated successfully!",
                            "success"
                          );
                          this.setState(
                            {
                              showDuplicate: false,
                              duplicateObj: Object.assign({}, this.initialObj),
                            },
                            () => {
                              loadEvents(
                                undefined,
                                undefined,
                                undefined,
                                false
                              );
                            }
                          );
                        })
                        .catch((e) => {
                          console.error(e);
                        });
                    }}
                    disabled={!readyToSubmit}
                    submitText="Duplicate"
                  />
                </form>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapKeys = (dbKey) => {
  let key = "";

  switch (dbKey) {
    case "status": {
      key = "startDate";
      break;
    }

    case "state": {
      key = "active";
      break;
    }

    default: {
      key = dbKey;
    }
  }

  return key;
};

ListView.propTypes = {
  data: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  toggleIsActive: PropTypes.func.isRequired,
  loadEvents: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  roleMode: PropTypes.bool.isRequired,
  changeRole: PropTypes.func,
  roleTypes: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  user: PropTypes.object.isRequired,
  loadPolicies: PropTypes.func.isRequired,
  duplicateEvent: PropTypes.func,
  addNotification: PropTypes.func,
  updateEvent: PropTypes.func,
  filtered: PropTypes.bool,
};

ListView.defaultProps = {
  roleMode: false,
  roleTypes: false,
  changeRole: () => {},
  user: {},
  loadPolicies: () => {},
};
