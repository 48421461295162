import { connect } from "react-redux";
import { showModal } from "@actions";
import { notificationsViewed } from "@actions/appuser";
import UserNotifications from "./UserNotifications";

const mapStateToProps = (state) => {
  return {
    notificationsCount: state.appuser.unread,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content, unread) => {
      if (unread > 0) {
        dispatch(notificationsViewed()).then(() =>
          dispatch(
            showModal(
              content,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              false,
              "user-notifications-modal"
            )
          )
        );
      } else {
        dispatch(
          showModal(
            content,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false,
            "user-notifications-modal"
          )
        );
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotifications);
