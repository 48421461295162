import { api } from "@actions/ajax";
import { addNotification } from "@actions";
export const VIEW_JUDGE_ABSTRACT = "VIEW_JUDGE_ABSTRACT";
export const RECEIVED_JUDGE_RATE = "RECEIVED_JUDGE_RATE";

export const commentAbstract = (eventId, abstractId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "judge",
      action: "comment",
      params: { eventId, abstractId },
      body: { data },
    })
  ).then(() => {
    const { commentType } = data;
    addNotification(
      `Comment sent successfully!${
        commentType.includes("revision")
          ? " Additionally a revision has been requested!"
          : ""
      }`,
      "success"
    );
  });

export const rateAbstract =
  (eventId, abstractId, data) => (dispatch, getState) => {
    const hasScore =
      data.score !== undefined && data.score !== null && data.score !== "";
    if (hasScore) {
      data.rated = 1;
    } else {
      delete data.score;
    }

    dispatch(
      api({
        endpoint: "judge",
        action: "rate",
        params: { eventId, abstractId },
        body: { data },
      })
    ).then(() => {
      getState().page.navigate("/judge/abstracts");
      setTimeout(
        () =>
          dispatch(
            addNotification(
              `Your review has been ${
                hasScore ? "submitted!" : "saved as a draft!"
              }`,
              "success"
            )
          ),
        200
      );
    });
  };

export const getJudgeAbstracts =
  (
    newParams = {
      rpp: 20,
      p: 1,
    }
  ) =>
  (dispatch, getState) => {
    const paramsToCheck = [
      "p",
      "rpp",
      "order",
      "rated",
      "status",
      "search",
      "orderBy",
      "eventId",
    ];

    // merge the new params with the current meta
    const queryObject = {
      ...getState().api.judge.list.meta,
      ...newParams,
    };

    // remove the empty values and build the query string
    const query = Object.keys(queryObject)
      .filter((key) => paramsToCheck.includes(key) && queryObject[key])
      .map((key) => `${key}=${queryObject[key]}`)
      .join("&");

    const request = {
      endpoint: "judge",
      action: "list",
      query: query?.length ? `?${query}` : "",
      cache: false,
    };

    return dispatch(api(request));
  };

export const getJudgeAbstract = (eventId, abstractId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "judge",
      action: "get",
      params: { abstractId, eventId },
    })
  );

export const viewAbstract = (abstractId, eventId) => (dispatch, getState) => {
  const state = getState();
  state.page.navigate(`/judge/abstracts/${eventId}/${abstractId}`);
  dispatch({
    type: VIEW_JUDGE_ABSTRACT,
    abstractId,
    eventId,
  });
};
