import React from "react";
import KMBLoader from "@layout/KMBLoader";
import Breadcrumbs from "@layout/Breadcrumbs";
import Email from "@layout/Email";
import PropTypes from "prop-types";
import ScheduledEmailsModal from "@layout/ScheduledEmailsModal";
import SendSchedule from "@app_icons/SendSchedule";
import { connect } from "react-redux";
import SendNow from "@app_icons/SendNow";
import { addNotification, showModal } from "@actions";
import { sendPresetEmail } from "@actions/presetEmails";
import Confirm from "@layout/Confirm";
import { replaceSpanWithDataOriginalVar } from "../../../../../../../helpers";

class Preset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.email) !== JSON.stringify(this.props.email)) {
      this.setState({ email: nextProps.email });
    }
  }

  emailTemplateChanged(data) {
    const newEmail = { ...this.state.email };
    newEmail.template = data;
    this.setState({ email: newEmail });
  }

  editContact(e, contact, contactIndex) {
    return this.props.contactModal(
      this.props.orgId,
      e.id,
      e,
      "editContact",
      contact,
      contactIndex,
      this.props.policyGroups,
      (orgId, emailId, data) => {
        this.setState(
          {
            email: {
              ...this.state.email,
              ...data,
            },
          },
          this.props.hideModal(false)
        );
      }
    );
  }
  addContact(e) {
    return this.props.contactModal(
      this.props.orgId,
      e.id,
      e,
      "createContact",
      {},
      null,
      this.props.policyGroups,
      (orgId, emailId, data) => {
        this.setState(
          {
            email: {
              ...this.state.email,
              ...data,
            },
          },
          this.props.hideModal(false)
        );
      }
    );
  }
  deleteContact(email, contactIndex) {
    const contacts = [...email.contacts];

    contacts.splice(contactIndex, 1);
    const template = JSON.stringify(this.props.email.template);
    const data = {
      template,
      contacts,
    };

    this.setState(
      {
        email: {
          ...this.state.email,
          contacts: data.contacts,
        },
      },
      this.props.hideModal(false)
    );
  }
  render() {
    if (this.props.fetching) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }

    const { email } = this.state;
    const variables = JSON.parse(JSON.stringify(this.props.globalVars));

    return (
      <React.Fragment>
        {email.id && (
          <div className="email-schedule-bar">
            <React.Fragment>
              <Confirm
                onConfirm={() => {
                  this.props.sendEmail(
                    this.props.orgId,
                    email.id,
                    this.props.eventId
                  );
                }}
                body={
                  <span>
                    This email will be sent to its recipients on its last saved
                    state. Make sure you have saved any unsaved changes before
                    you send.
                  </span>
                }
                confirmText="Yes, send email now!"
                title="Attention"
              >
                <button
                  type="submit"
                  className="btn save rounded email-save-button email-secondary"
                >
                  <div className="icon-wrapper">
                    <SendNow />
                  </div>
                  <div>Send email now</div>
                </button>
              </Confirm>

              <button
                type="button"
                className=" btn create rounded save email-save-button email-secondary"
                onClick={() => {
                  this.props.showModal(
                    <ScheduledEmailsModal
                      scheduling={email.scheduling}
                      emailId={email.id}
                    />
                  );
                  this.setState({
                    schedulingWizard: !this.state.schedulingWizard,
                  });
                }}
              >
                <div className="icon-wrapper">
                  <SendSchedule></SendSchedule>
                </div>
                <div>Schedule email</div>
              </button>
            </React.Fragment>
          </div>
        )}
        <div className="tab-content">
          <Breadcrumbs
            options={[
              {
                name: "Preset Emails",
                action: () => this.props.changeTab("Presets", null),
              },
              { name: email.name },
            ]}
          />
          <Email
            preset={true}
            placeholder={`Type the content of the email. Use any variables you want, for dynamic injection.`}
            rows={50}
            template={{ id: email.id, name: email.name, ...email.template }}
            scheduling={email.scheduling}
            onChange={(data) => this.emailTemplateChanged(data)}
            defaultReplyTo={this.props.supportEmail}
            variables={variables}
            contacts={email.contacts}
            addContact={() => this.addContact(email)}
            editContact={(email, contact, index) =>
              this.editContact(email, contact, index)
            }
            deleteContact={(index) => this.deleteContact(email, index)}
            onSave={() => {
              const { template, contacts, eventId } = email;
              const name = template.name;
              delete template.name;
              const newTemplate = { ...template };
              newTemplate.html = replaceSpanWithDataOriginalVar(
                newTemplate.html
              );
              const data = {
                eventId,
                name,
                template: JSON.stringify(newTemplate),
                contacts,
              };
              if (this.props.mode === "edit") {
                this.props.updateEmail(
                  this.props.orgId,
                  email.id,
                  data,
                  this.props.eventId
                );
              } else {
                this.props.createNewPresetEmail(this.props.orgId, data);
              }
            }}
            delete={false}
            showDeleteModal={this.props.mode === "edit"}
            showName={this.props.showName}
            contactVariables={{
              groups: this.props.policyGroups,
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

Preset.propTypes = {
  email: PropTypes.object,
  policyGroups: PropTypes.array,
  contactModal: PropTypes.func,
  orgId: PropTypes.number,
  hideModal: PropTypes.func,
  fetching: PropTypes.bool,
  globalVars: PropTypes.array,
  changeTab: PropTypes.func,
  supportEmail: PropTypes.string,
  mode: PropTypes.string,
  showName: PropTypes.bool,
  updateEmail: PropTypes.func,
  createNewPresetEmail: PropTypes.func,
  eventId: PropTypes.number,
  showModal: PropTypes.func,
  sendEmail: PropTypes.func,
  sendQuestionnaire: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modal) => {
      dispatch(showModal(modal));
    },
    sendEmail: (orgId, emailid, eventId) => {
      return dispatch(sendPresetEmail(orgId, emailid, eventId)).then(() => {
        dispatch(addNotification("successfully send email", "success"));
        return null;
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preset);
