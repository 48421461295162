import { io } from "socket.io-client";

const socketsHelper = {
  socket: null,
  token: localStorage.getItem("bluetree_admin_token"),
  attemps: 0,
  close: () => {
    if (socketsHelper.socket) {
      socketsHelper.socket.close();
      socketsHelper.socket = null;
    }
  },
  init: (url, nameSpace) => {
    socketsHelper.socket = null;

    return new Promise((resolve, reject) => {
      socketsHelper.socket = io(`${url}/${nameSpace}`, {
        query: `ns=/${nameSpace}`,
        handshake: true,
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${socketsHelper.token}`,
            },
          },
        },
      });

      socketsHelper.socket.on("connect", () => {
        socketsHelper.attemps = 0;
        return resolve(socketsHelper.socket);
      });

      socketsHelper.socket.on("error", (error) => {
        console.warn(error);
        socketsHelper.attemps++;
        if (socketsHelper.attemps > 3) {
          socketsHelper.attemps = 0;
          return reject(error);
        }
        socketsHelper.close();
        socketsHelper
          .init(url, nameSpace)
          .then((socket) => {
            return resolve(socket);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    });
  },
};

export default socketsHelper;
