import {
  RECEIVED_USER_GET,
  NEW_NOTIFICATION,
  NOTIFICATION_DISMISSED,
  NOTIFICATION_DISMISSING,
  RESET_USER_NOTIFICATIONS,
  SYNC_NOTIFICATIONS,
  MULTIPLE_NOTIFICATIONS,
  DECORATE_USER,
  CHANGE_SCREEN,
  LOGOUT_USER,
} from "@actions/appuser";
import { REGISTER_EVENT_ACCESS, EVENT_CREATED } from "@actions";
import { RESET_MENU } from "@actions/editmenu";
import {
  REQUEST_STAFF_EDIT,
  RECEIVED_STAFF_EDIT,
  RECEIVED_USER_NOTIFICATIONSREAD,
} from "@actions/ajax";

const initialState = {
  data: {},
  ready: false,
  notifications: [],
  unread: 0,
  currentAccess: {},
  roles: [],
  activeScreen: null,
};

let key = 0;

const appuser = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SCREEN: {
      return Object.assign({}, state, {
        activeScreen: action.screen,
      });
    }

    case DECORATE_USER: {
      return Object.assign({}, state, {
        roles: action.roles,
        activeScreen: action.initialScreen,
      });
    }

    case RECEIVED_USER_NOTIFICATIONSREAD: {
      return Object.assign({}, state, {
        unread: 0,
      });
    }

    case REQUEST_STAFF_EDIT: {
      if (!action.hasOwnProperty("extra")) {
        return state;
      }
      return Object.assign({}, state, {
        ready: false,
      });
    }

    case RECEIVED_STAFF_EDIT: {
      if (action.response.data.id !== state.data.id) {
        return state;
      }
      return Object.assign({}, state, {
        ready: true,
        data: Object.assign({}, state.data, {
          firstName: action.response.data.firstName,
          lastName: action.response.data.lastName,
          email: action.response.data.email,
        }),
      });
    }

    case EVENT_CREATED: {
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          accessEvents: Object.assign({}, state.data.accessEvents, {
            [action.event.id]: {
              orgId: state.data.orgId,
              role: "organisation_admin",
              eventPoliciesId: [],
            },
          }),
        }),
      });
    }

    case RESET_MENU: {
      return Object.assign({}, state, {
        currentAccess: {},
      });
    }

    case REGISTER_EVENT_ACCESS: {
      return Object.assign({}, state, {
        currentAccess: action.eventAccess,
      });
    }

    case SYNC_NOTIFICATIONS: {
      return Object.assign({}, state, {
        notifications: action.notifications,
      });
    }

    case RESET_USER_NOTIFICATIONS: {
      key = 0;
      return Object.assign({}, state, {
        notifications: initialState.notifications,
      });
    }

    case NOTIFICATION_DISMISSING: {
      return Object.assign({}, state, {
        notifications: [...state.notifications].map((n) => {
          if (n.key === action.index) {
            n.className = "dismissing";
          }
          return n;
        }),
      });
    }

    case NOTIFICATION_DISMISSED: {
      return Object.assign({}, state, {
        notifications: [...state.notifications].filter(
          (n) => n.key !== action.index
        ),
      });
    }

    case MULTIPLE_NOTIFICATIONS: {
      let unread = state.unread;
      const notifications = [...action.notifications].map((n) => {
        if (n.viewed === 0) unread++;
        const _n = Object.assign({}, n, {
          key,
        });
        key++;
        return _n;
      });
      return Object.assign({}, state, {
        notifications: [...notifications],
        unread,
      });
    }

    case NEW_NOTIFICATION: {
      const notification = Object.assign({}, action.notification, {
        key,
      });
      key++;
      return Object.assign({}, state, {
        notifications: [...[notification], ...state.notifications],
        unread:
          action.notification.viewed === 0 ? state.unread + 1 : state.unread,
      });
    }

    case RECEIVED_USER_GET: {
      return Object.assign({}, state, {
        data: action.response.data,
        ready: true,
      });
    }
    case LOGOUT_USER: {
      return Object.assign({}, state, {
        data: {},
        ready: false,
      });
    }

    default: {
      return state;
    }
  }
};

export default appuser;
