import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { v4 as uuidv4 } from "uuid";

export default class SingleRoom extends React.Component {
  constructor(props) {
    super(props);
    this.mode = props.room ? "update" : "create";

    this.state = {
      inputCount: 1,
      values: this.mode === "create" ? [""] : [this.props.room.name],
      capacity: this.mode === "create" ? [""] : [this.props.room.capacity],
      ids: this.mode === "create" ? [uuidv4()] : [this.props.room.id],
      error: "",
    };

    this.renderInputs = this.renderInputs.bind(this);
    this.addInput = this.addInput.bind(this);
    this.removeInput = this.removeInput.bind(this);
    this.submit = this.submit.bind(this);
    this.update = this.update.bind(this);
  }

  submit() {
    const values = [...this.state.values].filter((v) => v !== "");
    const capacity = [...this.state.capacity].filter((v) => v !== "");

    const inputCount = values.length;
    if (inputCount === 0) {
      this.setState({
        values: [""],
        error: "Room name is empty",
        inputCount: 1,
      });
      return;
    }
    this.setState(
      {
        values,
        inputCount,
        error: "",
        bttnClicked: true,
      },
      () => this.props.saveRooms(this.props.eventId, values, capacity)
    );
  }

  update() {
    const value = this.state.values[0];
    const capacity = this.state.capacity[0];
    if (!value) {
      this.setState({
        error: "Room name is empty",
      });
      return;
    }
    this.props.editRoom(this.props.eventId, this.props.room.id, {
      name: value,
      capacity,
    });
  }

  removeInput(index) {
    if (index === 0) {
      return;
    }

    this.setState((prevState) => {
      return {
        inputCount: prevState.inputCount - 1,
        values: [...prevState.values].filter((v, i) => i !== index),
        ids: [...prevState.ids].filter((v, i) => i !== index),
        capacity: [...prevState.capacity].filter((v, i) => i !== index),
      };
    });
  }

  onChange(index, e, type) {
    const value = e.target.value;
    if (type === "capacity") {
      const capacity = this.state.capacity;
      capacity[index] = e.target.value;
      this.setState({ capacity });
    } else {
      this.setState((prevState) => {
        return {
          values: [...prevState.values].map((v, i) => {
            if (i === index) {
              return value;
            }
            return v;
          }),
        };
      });
    }
  }

  addInput() {
    this.setState((prevState) => {
      return {
        inputCount: prevState.inputCount + 1,
        values: [...prevState.values, ""],
        ids: [...prevState.ids, uuidv4()],
        error: "",
      };
    });
  }

  renderInputs() {
    const count = this.state.inputCount;
    const jsx = [];

    for (let i = 0; i < count; i++) {
      jsx.push(
        <div
          className={`form-group${this.state.error ? " has-error" : ""}`}
          key={`room-field-${i}-${this.state.ids[i]}`}
        >
          <label>Room Name</label>
          <div className="field-type type-text kmb-text">
            <div>
              <input
                type="text"
                className="form-control"
                name={`roomName-${i}`}
                placeholder="Please type the name of the room"
                id={`roomName-${i}`}
                value={this.state.values[i]}
                onChange={(e) => this.onChange(i, e)}
              />
              <span className="icon icon-edit"></span>
            </div>
          </div>
          <br />
          <label>Room Capacity</label>

          <div className="field-type type-text kmb-text">
            <div>
              <input
                type="text"
                className="form-control"
                name={`capacity-${i}`}
                placeholder="Please type the capacity of the room"
                id={`capacity-${i}`}
                value={this.state.capacity[i]}
                onChange={(e) => this.onChange(i, e, "capacity")}
              />
              <span className="icon icon-edit"></span>
            </div>
          </div>
          {i > 0 && (
            <div className="right remove-container">
              <button
                type="button"
                className="btn btn-white"
                onClick={(e) => this.removeInput(i, e)}
              >
                <span className="icon-close"></span>Remove
              </button>
            </div>
          )}
          <div className="help-block">{this.state.error}</div>
        </div>
      );
    }
    return jsx;
  }

  render() {
    return (
      <div className="form-container create-rooms">
        <h2>
          {this.mode === "create"
            ? "Create Rooms"
            : `Update room ${this.props.room.name}`}
        </h2>
        <form onSubmit={(e) => e.preventDefault()}>
          {this.renderInputs(this.state.inputCount)}
          {this.mode === "create" && (
            <div className="text-right add-more-rooms-container">
              <button
                type="button"
                className="btn btn-white"
                onClick={this.addInput}
              >
                <span className="icon-add-1"></span>Add more rooms
              </button>
            </div>
          )}
          <SubmitContainer
            onCancel={this.props.hideModal}
            onSubmit={this.mode === "create" ? this.submit : this.update}
          />
        </form>
      </div>
    );
  }
}

SingleRoom.propTypes = {
  room: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  saveRooms: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  editRoom: PropTypes.func.isRequired,
};
