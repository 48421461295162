import { connect } from "react-redux";
import {
  loadExhibitionDigitalSlots,
  updateExhibitionDigitalSlots,
  loadExhibitionCategories,
  updateExhibitionDigitalCategories,
} from "@actions/exhibitions";
import DigitalSettings from "./DigitalSlots";

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    categories: state.api.exhibitionHall.categories.data,
    slots: state.api.exhibitionHall.slots.data,
    updating:
      state.api.exhibitionHall.slots.fetching ||
      state.api.exhibitionHall.categories.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadExhibitionDigitalSlots: (eventId) =>
      dispatch(loadExhibitionDigitalSlots(eventId)),
    updateExhibitionDigitalSlots: (eventId, data) =>
      dispatch(updateExhibitionDigitalSlots(eventId, data)),
    loadExhibitionCategories: (eventId) =>
      dispatch(loadExhibitionCategories(eventId)),
    updateExhibitionDigitalCategories: (eventId, data) =>
      dispatch(updateExhibitionDigitalCategories(eventId, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalSettings);
