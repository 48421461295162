import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Switch from "@layout/Switch";
import Breadcrumbs from "@layout/Breadcrumbs";
import SingleExhibitor from "./SingleExhibitor";
import SingleExhibitorPage from "./SingleExhibitorPage";
import TableActions from "@layout/TableActions";

const Exhibitors = (props) => {
  const [state, setState] = React.useState({
    selectedExhibitor: {},
  });

  const components = {
    addExhibitor: {
      component: (props) => <SingleExhibitor {...props} />,
      label: () => "Add Exhibitor",
    },
    editExhibitor: {
      component: (props) => <SingleExhibitor {...props} />,
      label: (id) => `Edit Exhibitor ${id}`,
    },
    editExhibitorPage: {
      component: (props) => <SingleExhibitorPage {...props} />,
      label: (id) => `Edit Exhibitor ${id} Page`,
    },
  };

  React.useEffect(() => {
    Promise.all([
      props.getSponsors(props.event.orgId, { rpp: -1 }),
      props.loadExhibitionDigitalSlots(props.event.id),
      props.loadExhibitionCategories(props.event.id),
      props.loadExhibitors(props.event.id),
    ]);
  }, []);

  React.useEffect(() => {
    if (props.fakeChild == null) setState({ ...state, selectedExhibitor: {} });
  }, [props.fakeChild]);

  const onRender = (col, item) => {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            <span className="exhibitor-thumbnail">
              <img
                src={
                  item.exhibitorThumbnail[0]
                    ? item.exhibitorThumbnail[0].url
                    : "/images/medlive-noimage.jpg"
                }
                alt={`${item.name}`}
              />
            </span>
          </div>
        );
      }
      case "page": {
        return (
          <div>
            <button
              key={`${item.name}-${item.id}`}
              className="btn transparent table-button"
              style={{ marginRight: 15 }}
              onClick={() => {
                setState({ ...state, selectedExhibitor: item });
                props.changeTab("ExhibitorsList", `editExhibitorPage`);
              }}
            >
              <span
                style={{
                  position: "absolute",
                  left: 15,
                  top: 15,
                  fontSize: 13,
                }}
              >
                <i className="fas fa-sliders-h"></i>
              </span>
              EDIT PAGE
            </button>
          </div>
        );
      }
      case "active": {
        return (
          <Switch
            id={`exhibitor-active-${item.id}`}
            isActive={col.value}
            onChange={(active) =>
              props.updateExhibitor(props.event.id, item.id, {
                orgSponsorId: item.orgSponsorId,
                active,
              })
            }
          />
        );
      }
      case "actions": {
        return (
          <TableActions
            entity="exhibitor"
            item={item}
            onEdit={() => {
              setState({ ...state, selectedExhibitor: item });
              props.changeTab("ExhibitorsList", `editExhibitor`);
            }}
            onDelete={() => props.deleteExhibitor(props.event.id, item.id)}
          />
        );
      }
    }
  };
  if (props.fakeChild) {
    return (
      <div className="tab-content">
        <Breadcrumbs
          options={[
            {
              name: "Exhibitors List",
              action: () => props.changeTab("ExhibitorsList", null),
            },
            {
              name: `${components[props.fakeChild].label(
                state.selectedExhibitor.id
              )}`,
            },
          ]}
        />

        <div style={{ marginTop: 12 }}>
          {components[props.fakeChild].component({
            ...props,
            exhibitor: state.selectedExhibitor,
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="tab-header">
        <div className="left"></div>
        <div className="right">
          <button
            type="button"
            className="btn create rounded"
            onClick={() => props.changeTab("ExhibitorsList", `addExhibitor`)}
          >
            Create
          </button>
        </div>
      </div>
      <div className="tab-content">
        <Table
          items={props.exhibitors}
          columns={{
            cosmetic: {
              name: "",
            },
            id: {
              name: "ID",
            },
            name: {
              name: "Exhibitor",
            },
            notes: {
              name: "Notes",
            },
            page: {
              name: "",
            },
            active: {
              name: "Active",
            },
            actions: {
              name: "",
            },
          }}
          onRender={onRender}
          updating={props.updating}
        />
      </div>
    </>
  );
};

Exhibitors.propTypes = {
  event: PropTypes.object.isRequired,
  loadExhibitors: PropTypes.func.isRequired,
  getSponsors: PropTypes.func.isRequired,
  loadExhibitionDigitalSlots: PropTypes.func.isRequired,
  loadExhibitionCategories: PropTypes.func.isRequired,
  deleteExhibitor: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  fakeChild: PropTypes.string,
  exhibitors: PropTypes.array,
  updating: PropTypes.bool,
  updateExhibitor: PropTypes.func,
};
export default Exhibitors;
