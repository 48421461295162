import { api } from "@actions/ajax";
import { addNotification } from ".";
// import { addNotification } from "@actions";

export const getInvoices =
  (eventId, { p, rpp } = { p: 1, rpp: 20 }) =>
  (dispatch) =>
    dispatch(
      api({
        endpoint: "invoices",
        action: "list",
        params: { eventId },
        query: `?p=${p}&rpp=${rpp}`,
      })
    );

export const createInvoice = (eventId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "invoices",
      action: "create",
      params: { eventId },
      body: {
        data,
      },
    })
  ).then(() => {
    return dispatch(getInvoices(eventId));
  });

export const updateInvoice = (eventId, invoiceId, data) => (dispatch) => {
  return dispatch(
    api({
      endpoint: "invoices",
      action: "update",
      params: { eventId, invoiceId },
      body: {
        data,
      },
    })
  ).then(() => {
    return dispatch(getInvoices(eventId));
  });
};

export const deleteInvoice = (eventId, invoiceId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "invoices",
      action: "delete",
      params: { eventId, invoiceId },
      body: {
        data: [],
      },
    })
  ).then(() => {
    // add notification
    addNotification("Invoice deleted", "success");
    return dispatch(getInvoices(eventId));
  });
