import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Input = forwardRef((props, ref) => {
  const { type, min, max, onChange, value, ...rest } = props;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (type !== "number") return onChange(e, inputValue);

    // case number
    const normalizedValue = inputValue.replace(",", ".");
    e.target.value = normalizedValue;

    // if does not pass the regex number test, prevent further input
    if (!/^-?\d*[.,]?\d*$/.test(normalizedValue)) {
      if (normalizedValue === "") return onChange(e, normalizedValue);
      e.target.value = parseFloat(normalizedValue) || "";
      return onChange(e, normalizedValue);
    }

    // Allow only one comma or one dot, and prevent both comma and dot together
    const dotCount = (inputValue.match(/\./g) || []).length;
    const commaCount = (inputValue.match(/,/g) || []).length;

    // If more than one dot or comma or both comma and dot exist, prevent further input
    if (dotCount > 1 || commaCount > 1 || (dotCount >= 1 && commaCount >= 1)) {
      return onChange(e, normalizedValue);
    }

    // Handle empty input gracefully to allow clearing the field
    if (
      normalizedValue === "" ||
      normalizedValue === "-" ||
      normalizedValue === "."
    ) {
      return onChange(e, normalizedValue);
    }

    // Prevent parsing the number if it ends with a dot or comma
    if (normalizedValue.endsWith(".")) return onChange(e, normalizedValue);

    // Convert to a number
    let numericValue = parseFloat(normalizedValue);

    // Ensure the number respects min/max constraints
    if (!isNaN(numericValue)) {
      if (min !== undefined && numericValue < min) {
        numericValue = min;
      }
      if (max !== undefined && numericValue > max) {
        numericValue = max;
      }
    }
    // Update the event's target value to the numeric value for external onChange
    if (!isFinite(numericValue)) e.target.value = numericValue;

    // Pass the transformed numeric value to the onChange prop
    return onChange(e, numericValue);
  };

  // Render as text if type is "number", otherwise use the passed type
  const inputType = type === "number" ? "text" : type;

  return (
    <div
      className="field-type type-text kmb-text"
      style={{
        width: props.width || "100%",
      }}
    >
      <div>
        <input
          {...rest}
          type={inputType} // Render as text if number is selected
          value={value}
          onChange={handleChange}
          className={`form-control ${props.className || ""}`}
          ref={ref} // Pass the ref to the input element
        />
        <span className="icon icon-edit"></span>
      </div>
    </div>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default Input;
