import { connect } from "react-redux";
import User from "./User";
import { changeScreen, logoutUser } from "@actions/appuser";

const mapStateToProps = (state) => {
  return {
    user: state.appuser.data,
    roles: state.appuser.roles,
    page: state.page,
    activeScreen: state.appuser.activeScreen,
  };
};

export default connect(mapStateToProps, { changeScreen, logoutUser })(User);
