import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { addNotification } from "@actions";
const setClipboardText = (text) => {
  const id = "mycustom-clipboard-textarea-hidden-id";
  let existsTextarea = document.getElementById(id);
  if (!existsTextarea) {
    const textarea = document.createElement("textarea");
    textarea.id = id;
    // Place in top-left corner of screen regardless of scroll position.
    textarea.style.position = "fixed";
    textarea.style.top = 0;
    textarea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textarea.style.width = "1px";
    textarea.style.height = "1px";

    // We don't need padding, reducing the size if it does flash render.
    textarea.style.padding = 0;

    // Clean up any borders.
    textarea.style.border = "none";
    textarea.style.outline = "none";
    textarea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textarea.style.background = "transparent";
    document.querySelector("body").appendChild(textarea);
    existsTextarea = document.getElementById(id);
  }
  existsTextarea.value = text;
  existsTextarea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy.");
  }
};
const RichEditorExample = (props) => {
  const [initialValue] = useState(props.value);

  const handleEditorChange = (e) => {
    props.onChange(e.target.getContent());
  };

  const replaceMatchesWithElement = (editor, regex, createElement) => {
    const content = editor.getContent();
    const replacedContent = content.replace(regex, createElement);
    if (content !== replacedContent) {
      editor.setContent(replacedContent);
    }
  };

  const getElement = (variable, originalVar) =>
    `<span data-original-var="${originalVar}" contenteditable="false" style="background-color: #8dd0da; padding: 2px 6px; border-radius:6px">${variable}</span>`;

  const variables = props.variables;
  let flatVariables = [];
  variables?.forEach((group) => {
    flatVariables = [
      ...flatVariables,
      ...group.value.map((v) => ({
        ...v,
        variableRenderLabel: `${group.label} &#8594; ${v.label}`,
      })),
    ];
  });

  const initConfig = {
    placeholder: props.placeholder,
    toolbar_mode: "floating",
    entity_encoding: "raw",
    br_in_pre: false,
    branding: false,
    height: props.height || 500,
    menubar: false,
    convert_urls: false,
    plugins: [
      "advlist autolink lists link image imagetools table ",
      "charmap print preview anchor code help",
      "searchreplace visualblocks",
      "insertdatetime  table paste wordcount hr fullscreen",
    ],
    toolbar: ` undo redo | Variables |
        fontselect fontsizeselect forecolor backcolor |
        bold italic underline strikethrough removeformat |
        alignleft aligncenter alignright alignjustify |
        bullist numlist |
        image table media |
        outdent indent | subscript superscript blockquote hr| formatselect |
        preview visualblocks code fullscreen |
        searchreplace print help`,
    init_instance_callback: (editor) => {
      replaceMatchesWithElement(
        editor,
        /(?<!href="){{([^}]+)}}/g,
        (match, innerText) => {
          const found = flatVariables.find((v) => v.key === innerText);
          if (!found) return match;
          return getElement(found.variableRenderLabel, innerText);
        }
      );
    },
    setup: (editor) => {
      editor.on("change", () => {
        replaceMatchesWithElement(
          editor,
          /(?<!href="){{([^}]+)}}/g,
          (match) => {
            const innerText = match.slice(2, -2);
            const found = flatVariables.find((v) => v.key === innerText);
            if (!found) return match;
            return getElement(found.variableRenderLabel, innerText);
          }
        );
      });

      if (variables) {
        editor.ui.registry.addMenuButton("Variables", {
          text: "Variables",
          fetch: (callback) => {
            const items = variables?.map((variable) => ({
              type: "nestedmenuitem",
              text: variable.label,
              getSubmenuItems: () =>
                variable.value.map((sub) => ({
                  type: "menuitem",
                  text: sub.label,
                  onAction: () => {
                    setClipboardText(sub.value);
                    props.addNotification("Copied to clipboard!", "success");
                    // editor.insertContent(
                    //   getElement(
                    //     `${variable.label} &#8594; ${sub.label}`,
                    //     sub.value.slice(2, -2)
                    //   )
                    // );
                  },
                })),
            }));
            callback(items);
          },
        });
      }
    },
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE}
      initialValue={initialValue}
      init={initConfig}
      onChange={handleEditorChange}
    />
  );
};

RichEditorExample.propTypes = {
  placeholder: PropTypes.string,
  variables: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  height: PropTypes.number,
  addNotification: PropTypes.func.isRequired,
};

RichEditorExample.defaultProps = {
  placeholder: "",
  variables: undefined,
  onChange: () => {},
};

export default connect(undefined, {
  addNotification,
})(RichEditorExample);
