const PrinterHelper = {
  printers: [],
  selectedPrinter: null,

  init: (printerType) => {
    return new Promise((resolve, reject) => {
      if (printerType === "generic") {
        return resolve(PrinterHelper.getSelectedPrinter([]));
      }

      PrinterHelper.initDymo()
        .then(() => {
          PrinterHelper.getPrinters(true)
            .then((printers) => PrinterHelper.getSelectedPrinter(printers))
            .then((data) => resolve(data))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  },

  changePrinterType: (printerType) => {
    localStorage.setItem("printerType", printerType);
  },

  getPrinterType: () => {
    const printerType = localStorage.getItem("printerType");
    return printerType || "generic";
  },

  getStatus: ({ printers, selectedPrinter }) => {
    const response = {
      success: true,
      messages: [],
      errorCodes: [],
    };

    if (printers.length === 0) {
      response.success = false;
      response.messages.push("No dymo printers found on your system.");
      response.errorCodes.push(0);
    }

    if (
      localStorage.getItem("selectedPrinter") !== null &&
      selectedPrinter === null
    ) {
      response.success = false;
      response.messages.push(
        "The previously selected printer was not found on your system. Please select a printer."
      );
      response.errorCodes.push(1);
    }

    return response;
  },

  initDymo: () => {
    return new Promise((resolve) => {
      window.dymo.label.framework.init(() => {
        // Dymo framework initialized
        resolve();
      });
    });
  },

  getPrinters: (connected = false) => {
    return new Promise((resolve) => {
      window.dymo.label.framework.getPrintersAsync().then((printers) => {
        if (!connected) {
          resolve(printers);
        }
        resolve(printers.filter((p) => p.isConnected));
      });
    });
  },

  getSelectedPrinter: (printers) => {
    let response = null;
    const selectedPrinter = localStorage.getItem("selectedPrinter");
    if (selectedPrinter !== null) {
      for (const p of printers) {
        if (p.name === selectedPrinter) {
          response = p.name;
        }
      }
    }

    return { printers, selectedPrinter: response };
  },

  deletePrinter: () => {
    localStorage.removeItem("selectedPrinter");
  },
};

export default PrinterHelper;
