import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect } from "react-redux";
import ApiHelper from "@helpers/api-helper";
import moment from "moment";
import { addNotification } from "@actions";
import Switch from "@layout/Switch";
import { HeaderPagination } from "@layout/Pagination";
import { getSendEmails, toggleViewedStatus } from "@actions/sendEmails";
import Search from "@layout/Search";

class SendEmails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      emailIsSelected: false,
      orgId: "",
      search: "",
    };
  }
  componentDidMount() {
    this.props.getEmails(this.props.eventId);
  }
  refreshEmails() {
    this.props.getEmails(this.props.eventId);
  }

  onRender(col, item) {
    switch (col.key) {
      case "viewed": {
        return (
          <Switch
            id={`${item.id}`}
            isActive={item.viewed}
            onChange={(viewed) =>
              this.props.toggleViewed(this.props.eventId, item.id, viewed)
            }
          />
        );
      }
      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "updatedAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
    }
  }
  findIfDifferent(val, identifier) {
    let newVal = 0;
    if (identifier === "rpp") {
      if (this.props.sendEmailsMeta.rpp !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.sendEmailsMeta.rpp;
      }
    } else if (identifier === "p") {
      if (this.props.sendEmailsMeta.p !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.sendEmailsMeta.p;
      }
    } else if (identifier === "totalRows") {
      if (this.props.sendEmailsMeta.totalRows !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.sendEmailsMeta.totalRows;
      }
    }
    return newVal;
  }
  render() {
    const { sendEmailsMeta } = this.props;

    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Search
              placeholder="Search email..."
              onSearch={(search) => {
                this.setState({ search });
                this.props.getEmails(this.props.eventId, { search });
              }}
              visible={true}
              timeout={400}
            />
          </div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={sendEmailsMeta.p}
              rpp={sendEmailsMeta.rpp}
              totalRows={sendEmailsMeta.totalRows}
              onChangePage={(data) => {
                this.props.getEmails(this.props.eventId, {
                  p: data.p,
                  rpp: data.rpp,
                  search: this.state.search,
                });
              }}
            />
            <button
              type="button"
              className="btn create rounded"
              onClick={() => this.refreshEmails()}
            >
              Refresh
            </button>
          </div>
        </div>

        <div className="tab-content">
          <Table
            filtered={this.state.search}
            updating={this.props.fetching}
            columns={{
              id: {
                name: "ID",
              },
              subject: {
                name: "Subject",
              },
              type: {
                name: "Type",
              },
              email: {
                name: "Email",
              },
              createdAt: {
                name: "Created At",
              },
            }}
            onRender={this.onRender}
            items={this.props.sendEmails}
          />
        </div>
      </>
    );
  }
}
SendEmails.propTypes = {
  getEmails: PropTypes.any,
  eventId: PropTypes.any.isRequired,
  sendEmails: PropTypes.any,
  toggleViewed: PropTypes.func.isRequired,
  addNotification: PropTypes.func,
  sendEmailsMeta: PropTypes.object,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    sendEmails: state.api.events.sendEmails.data,
    eventId: state.api.events.edit.data.id,
    sendEmailsMeta: state.api.events.sendEmails.meta,
    fetching: state.api.events.sendEmails.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmails: (eventId, meta) => {
      return dispatch(getSendEmails(eventId, meta));
    },
    toggleViewed: (eventId, emailId, viewed) => {
      dispatch(toggleViewedStatus(eventId, emailId, viewed));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SendEmails);
