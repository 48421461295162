import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Block from "./Block";
import { loadPolicies } from "@actions";
import { addNotification } from "@actions";
import {
  addGroup,
  createPolicyGroup,
  deleteGroup,
} from "@actions/policyGroups";
import { changeTab } from "@actions/editmenu";
import Input from "@layout/Input";
import SubmitContainer from "@layout/SubmitContainer";

class CreateGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionType: "OR",
      nameValue: props.group ? props.group.name : "",
      errors: [],
    };
  }
  componentDidMount() {
    if (this.props.group) {
      for (let i = 0; i < this.props.group?.filter?.filters?.length; i++) {
        this.props.addGroup(this.props.group.filter.filters[i]);
      }
    }
  }
  validateForm() {
    const errors = [];
    if (this.state.nameValue.length === 0) {
      errors.push("name");
    }
    return errors;
  }
  addGroup() {
    const check = this.validateGroup();
    const errors = [...this.state.errors];
    if (check.length === 0) {
      errors.splice(errors.indexOf("group"), 1);
      this.setState(errors);
      return this.props.addGroup();
    }
    return this.setState({ errors: errors.concat(check) });
  }
  componentWillUnmount() {
    this.props.deleteGroup("all");
  }
  validateGroup() {
    const errors = [];
    const emptyGroups = this.props.groups.filter((g) => {
      for (const condition of g.conditions) {
        if (
          Object.keys(condition).length <= 1 ||
          !condition.hasOwnProperty("value")
        ) {
          return true;
        }
        return false;
      }
      return true;
    });
    if (emptyGroups.length !== 0) {
      for (const emptyGroup of emptyGroups) {
        errors.push("group" + emptyGroup.id);
      }
    }
    return errors;
  }

  createPolicyGroup() {
    const errors = this.validateForm().concat(this.validateGroup());
    if (errors.length !== 0) {
      return this.setState({ errors });
    }
    const update = [];
    if (this.props.group) {
      update.push(this.props.group.id, true);
    }
    this.props.createPolicyGroup(
      this.props.eventId,
      this.props.policy.id,
      this.state.connectionType,
      this.props.groups,
      this.state.nameValue,
      ...update
    );
  }

  render() {
    const { errors } = this.state;
    const groups = JSON.parse(JSON.stringify(this.props.groups));
    return (
      <div className="form-container policy-group-container">
        <h2>{this.props.edit ? "Edit Group" : "Create Group"}</h2>
        <div className="form-group">
          <div
            className={`field-type type-text kmb-text  ${
              errors.indexOf("name") > -1 ? " has-error" : ""
            }`}
          >
            <label>Please type the Group Name (*)</label>
            <Input
              placeholder="Please type the name of the policy group"
              defaultValue={this.state.nameValue}
              onChange={(e) => this.setState({ nameValue: e.target.value })}
            />
            {errors.indexOf("name") > -1 && (
              <div className="help-block">This field is required.</div>
            )}
          </div>
        </div>
        <>
          {groups.map((group, index) => {
            return (
              <div key={group.id || index}>
                {index > 0 && <p style={{ textAlign: "center" }}> OR</p>}
                <div
                  className={`form-group condition-block${
                    errors.indexOf("group" + group.id) > -1 ? " has-error" : ""
                  }`}
                >
                  <Block
                    isRemovable={this.props.groups.length > 1}
                    groupIndex={index}
                    group={group}
                    policy={this.props.policy}
                  />

                  {errors.indexOf("group" + group.id) > -1 && (
                    <div className="help-block">
                      Please add one or more conditions with a valid field-value
                      combination.
                    </div>
                  )}
                </div>
                {index === this.props.groups.length - 1 && (
                  <p className="faded" style={{ textAlign: "center" }}>
                    OR
                  </p>
                )}
              </div>
            );
          })}
        </>
        <div className="form-group">
          <button
            className="new-condition btn btn-white"
            onClick={() => this.addGroup()}
          >
            <span className="icon-add-1"></span>
            New Condition Block
          </button>
        </div>
        <SubmitContainer
          onCancel={() => this.props.hideModal()}
          onSubmit={() => this.createPolicyGroup()}
        />
      </div>
    );
  }
}
CreateGroup.propTypes = {
  eventId: PropTypes.number,
  groups: PropTypes.array,
  group: PropTypes.object,
  policy: PropTypes.object.isRequired,
  changeTab: PropTypes.func,
  createPolicyGroup: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  hideModal: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    accessPolicies: state.api.accesspolicies.list.data,
    groups: state.api.policyGroups.data,
    // policy: state.api.accesspolicies.selectedPolicy,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadPolicies: (eventId) => {
      return dispatch(loadPolicies(eventId));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    addGroup: (conditions) => {
      dispatch(addGroup(conditions));
    },
    deleteGroup: (index) => {
      dispatch(deleteGroup(index));
    },
    createPolicyGroup: (
      eventId,
      policyId,
      connection,
      groups,
      name,
      groupId,
      update
    ) => {
      return dispatch(
        createPolicyGroup(
          eventId,
          policyId,
          connection,
          groups,
          name,
          groupId,
          update
        )
      ).then(() => dispatch(changeTab("PolicyGroups")));
    },
  };
  return dispatcher;
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
