import * as React from "react";
const SvgPrivatePolicy = (props) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#private_policy_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.484 7.6c0-.318.259-.577.578-.577h3.411a.577.577 0 0 1 0 1.155H8.062a.577.577 0 0 1-.578-.577Z"
        fill="#27303E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.345 7.047c.319 0 .577.258.577.577v1.294a.577.577 0 1 1-1.155 0V7.624c0-.319.259-.577.578-.577ZM6.303 6.446a1.155 1.155 0 1 0 0 2.31 1.155 1.155 0 0 0 0-2.31ZM3.993 7.6a2.31 2.31 0 1 1 4.62 0 2.31 2.31 0 0 1-4.62 0Z"
        fill="#27303E"
      />
      <path
        d="M8 15.572s5.73-2.865 5.73-7.162V3.396L8 1.247 2.27 3.396V8.41c0 4.297 5.73 7.162 5.73 7.162Z"
        stroke="#27303E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="private_policy_svg__a">
        <path fill="#fff" transform="translate(0 .41)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPrivatePolicy;
