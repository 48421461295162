import { api } from "@actions/ajax";
import { addNotification } from "@actions";
export const REQUEST_BANKACCOUNTS_GETBANKACCOUNTS =
  "REQUEST_BANKACCOUNTS_GETBANKACCOUNTS";
export const RECEIVED_BANKACCOUNTS_GETBANKACCOUNTS =
  "RECEIVED_BANKACCOUNTS_GETBANKACCOUNTS";

export const loadBankAccounts = (orgId, meta = { rpp: 20, p: 1 }) => {
  return (dispatch) => {
    const endpoint = "bankAccounts";
    const query = {
      endpoint,
      action: "getBankAccounts",
      params: {
        orgId,
      },
      query:
        "?" +
        Object.entries(meta)
          .map(([key, value]) => `${key}=${value}`)
          .join("&"),
    };

    dispatch(api(query));
  };
};

export const saveBankAccount = (orgId, data, bankAccountId = false) => {
  return (dispatch) => {
    const query = {
      endpoint: "bankAccounts",
      action: bankAccountId ? "updateBankAccount" : "createBankAccount",
      params: {
        orgId,
      },
      body: { data },
    };

    if (bankAccountId) {
      query.params["bankAccountId"] = bankAccountId;
    }

    return dispatch(api(query)).then((data) => {
      if (bankAccountId) {
        dispatch(
          addNotification(
            `Bank Account "${data.data.collaborator} (MID:${data.data.merchantId})" updated successfully!`,
            "success"
          )
        );
      }
      return dispatch(loadBankAccounts(orgId));
    });
  };
};

export const deleteBankAccount = (orgId, bankAccountId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "bankAccounts",
      action: "deleteBankAccount",
      params: {
        orgId,
        bankAccountId,
      },
      extra: {
        bankAccountId,
      },
    })
  ).then(() => {
    dispatch(addNotification("successfully deleted Bank Account", "success"));
    return dispatch(loadBankAccounts(orgId));
  });
