import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import Form from "@components/form";

const SingleEbag = (props) => {
  const [state, setState] = React.useState({
    item: props.item,
  });

  return (
    <div className="form-container single-sponsor">
      <h2>{`${props.mode === "create" ? "Create" : "Edit"} Ebag Item`}</h2>
      <Form
        formName="ebag"
        componentName="clientPanel"
        value={state.item}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;

          if (
            !state.item.clientEbagImage?.length &&
            !data.value.clientEbagImage.length &&
            (props.item.clientEbagImage || [])[0]
          ) {
            data.value.clientEbagImage = [props.item.clientEbagImage[0].id];
          } else if (!data.value.clientEbagImage.length) {
            delete data.value.clientEbagImage;
          }
          if (
            !state.item.clientEbagBrochure?.length &&
            !data.value.clientEbagBrochure.length &&
            (props.item.clientEbagBrochure || [])[0]
          ) {
            data.value.clientEbagBrochure = [
              props.item.clientEbagBrochure[0].id,
            ];
          } else if (!data.value.clientEbagBrochure.length) {
            delete data.value.clientEbagBrochure;
          }

          props.onSave(data.value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
        onChange={(data) => {
          if (data.key === "clientEbagImage") {
            setState({
              item: {
                ...state.item,
                clientEbagImage: [],
              },
            });
          } else if (data.key === "clientEbagBrochure") {
            setState({
              item: {
                ...state.item,
                clientEbagBrochure: [],
              },
            });
          }
        }}
      />
    </div>
  );
};

SingleEbag.propTypes = {
  eventId: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
  item: PropTypes.object,
  mode: PropTypes.string,
  onSave: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleEbag);
