import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import SponsorsList from "./partials/SponsorsList";
import SingleSponsorType from "./partials/SingleSponsorType";
import {
  showModal,
  createClientPanelSponsorType,
  createClientPanelSponsor,
} from "@actions";
import PropTypes from "prop-types";

const Sponsors = (props) => {
  const tabs = [{ key: "sponsorsList", label: "Sponsors List" }];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <React.Fragment>
      <div className="top-menu">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
        {tabs[activeTab].key === "sponsorsList" && (
          <button
            type="button"
            className="btn create rounded"
            onClick={() =>
              props.showModal(
                <SingleSponsorType
                  item={{}}
                  mode="create"
                  onSave={(data, file) => {
                    if (!file) {
                      return props.createClientPanelSponsorType(
                        props.event.id,
                        {
                          ...data,
                          index:
                            props.event.clientPanelSettings.sponsors
                              .sponsorsTypes.length,
                        }
                      );
                    }
                  }}
                />
              )
            }
          >
            Create Sponsor Type
          </button>
        )}
      </div>
      {tabs[activeTab].key === "sponsorsList" && (
        <SponsorsList
          createClientPanelSponsor={props.createClientPanelSponsor}
        />
      )}
    </React.Fragment>
  );
};

Sponsors.propTypes = {
  event: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  createClientPanelSponsorType: PropTypes.func.isRequired,
  createClientPanelSponsor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modal) => {
      return dispatch(showModal(modal));
    },
    createClientPanelSponsor: (eventId, data) => {
      return dispatch(createClientPanelSponsor(eventId, data));
    },
    createClientPanelSponsorType: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        createClientPanelSponsorType(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);
