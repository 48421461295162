import React from "react";
import Checkbox from "@layout/Checkbox";
import PropTypes from "prop-types";

export default class Checkboxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.value,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { name } = e.target;

    const selected = [...this.state.selected];
    const index = selected.indexOf(name);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(name);
    }

    this.setState({ selected }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.props.name, selected);
      }
    });
  }

  render() {
    const props = this.props;
    return (
      <div className="checkboxes-wrapper form-group">
        {Object.entries(props.options).map(([key, o]) => {
          return (
            <Checkbox
              id={key}
              key={key}
              onChange={this.onChange}
              label={o}
              checked={this.state.selected.includes(key) ? true : false}
            />
          );
        })}
      </div>
    );
  }
}

Checkboxes.defaultProps = {
  value: [],
};

Checkboxes.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.object,
};
