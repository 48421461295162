import { SHOW_ACTIONBAR, HIDE_ACTIONBAR } from "@actions";

const originalState = { visible: false, onSave: false, onDiscard: false };

const actionBar = (state = originalState, action) => {
  switch (action.type) {
    case SHOW_ACTIONBAR:
      return Object.assign({}, state, {
        visible: true,
        onSave: action.onSave ? action.onSave : false,
        onDiscard: action.onDiscard ? action.onDiscard : false,
      });

    case HIDE_ACTIONBAR:
      return originalState;

    default:
      return state;
  }
};

export default actionBar;
