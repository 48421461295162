import { api } from "@actions/ajax";
import { addNotification } from "@actions";
import { createConditionFilter } from "../helpers";

export const ADD_GROUP = "ADD_GROUP";
export const ADD_CONDITION = "ADD_CONDITION";
export const UPDATE_CONDITION = "UPDATE_CONDITION";
export const UPDATE_CONDITION_VALUE = "UPDATE_CONDITION_VALUE";
export const UPDATE_CONDITION_OPERATOR = "UPDATE_CONDITION_OPERATOR";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_CONDITION = "DELETE_CONDITION";

export const addGroup = (conditions) => (dispatch) =>
  dispatch({ type: ADD_GROUP, conditions });

export const addCondition = (groupIndex) => (dispatch) =>
  dispatch({ type: ADD_CONDITION, groupIndex });

export const updateCondition =
  (groupIndex, conditionIndex, condition) => (dispatch) =>
    dispatch({
      type: UPDATE_CONDITION,
      groupIndex,
      conditionIndex,
      condition,
    });

export const deleteGroup = (groupIndex) => (dispatch) =>
  dispatch({ type: DELETE_GROUP, groupIndex });

export const deleteCondition = (groupIndex, conditionIndex) => (dispatch) =>
  dispatch({ type: DELETE_CONDITION, groupIndex, conditionIndex });

export const listGroups =
  (eventId, policyId, query = "?") =>
  (dispatch) => {
    const cache = false;
    query += policyId ? `policyId=${policyId}` : "";
    return dispatch(
      api({
        endpoint: "policyGroups",
        action: "list",
        params: { eventId },
        cache,
        query,
      })
    );
  };

export const deletePolicyGroup = (eventId, policyId, groupId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "policyGroups",
      action: "delete",
      params: { eventId, policyId, groupId },
    })
  )
    .then(() =>
      dispatch(addNotification("Group deleted successfully", "success"))
    )
    .then(() => dispatch(listGroups(eventId, policyId)))
    .catch(console.error);

export const createPolicyGroup =
  (eventId, policyId, connection, groups, name, groupId, update) =>
  (dispatch) => {
    const hasEmptyGroup =
      groups.filter((group) => {
        return group.length === 0;
      }).length > 0;
    if (hasEmptyGroup)
      return dispatch(addNotification("Please Select a condition", "error"));

    let connectionType = "";
    if (connection === "AND") {
      connectionType = "and";
    } else {
      connectionType = "or";
    }
    const dto = createConditionFilter(groups, []);

    return dispatch(
      api({
        endpoint: "policyGroups",
        action: update ? "update" : "create",
        params: update ? { eventId, policyId, groupId } : { eventId, policyId },
        body: {
          data: {
            name,
            filter: JSON.stringify({
              filters: dto,
              connectionType,
            }),
          },
        },
      })
    )
      .then(() => dispatch(listGroups(eventId, policyId)))
      .catch(Promise.reject);
  };
