import { connect } from "react-redux";
import Rooms from "./Rooms";
import {
  reorderHotelRoom,
  unassignRoom,
  getOrgRooms,
  getOrgHotel,
  deleteRoom,
  getEventRooms,
} from "@actions/hotels";
import { showModal, addNotification, hideModal } from "@actions";

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.data.orgId,
    page: state.page,
    rooms: state.api.hotels.orgRooms.data,
    fetching: state.api.hotels.orgRooms.fetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (content) =>
    dispatch(
      showModal(
        content,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
    ),
  hideModal: () => dispatch(hideModal()),
  reorderHotelRoom: (index, hotelId, roomId) =>
    dispatch(reorderHotelRoom(index, hotelId, roomId)),
  unassignRoom: (hotelId, roomId, orgHotelId) =>
    dispatch(unassignRoom(hotelId, roomId, undefined, undefined, orgHotelId)),
  getOrgRooms: (hotelId) => dispatch(getOrgRooms("orgHotels", hotelId)),
  getOrgHotel: (hotelId) => dispatch(getOrgHotel(hotelId)),
  deleteRoom: (hotelId, roomId) =>
    dispatch(deleteRoom(hotelId, roomId, "orgHotels")),
  getEventRooms: (hotelId) => dispatch(getEventRooms(hotelId)),
  addNotification: (msg, status) => dispatch(addNotification(msg, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
