import React from "react";
import PropTypes from "prop-types";
import EnableReorder from "./EnableReorder/EnableReorder";

const PageSettings = (props) => {
  const renderActiveItem = () => {
    switch (props.activeItem.key) {
      case "enableReorder": {
        return (
          <EnableReorder
            pagesControls={props.pagesControls}
            activeItem={props.activeItem}
          />
        );
      }
    }
  };

  return (
    <div className="panel-content-wrapper tab-content">
      {renderActiveItem()}
    </div>
  );
};

PageSettings.propTypes = {
  activeItem: PropTypes.object.isRequired,
  pagesControls: PropTypes.array.isRequired,
};

export default PageSettings;
