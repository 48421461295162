import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import { connect } from "react-redux";
import { hideModal } from "@actions";
import { savePerson } from "@actions/crm";
import { createSponsor, updateSponsor } from "@actions/sponsors";

class SingleSponsor extends React.Component {
  render() {
    return (
      <div className="new-staff form-container">
        <h2>{this.props.value.id ? "Edit Sponsor" : "Create Sponsor"}</h2>
        <Form
          componentName={"sponsors"}
          formName="single"
          value={this.props.value ? this.props.value : {}}
          onSubmit={(data) => {
            if (isEmpty(data.errors)) {
              if (this.props.h2 === "Edit") {
                this.props.updateSponsor(
                  this.props.orgId,
                  data.value,
                  this.props.itemId
                );
              } else {
                this.props.createSponsor(this.props.orgId, data.value);
              }
            }
          }}
          onChange={(data) => {
            if (data.key === "sponsorThumbnail") {
              this.props.updateSponsor(
                this.props.orgId,
                { sponsorThumbnail: [data.value.id] },
                this.props.itemId
              );
            }
          }}
          onCancel={this.props.hideModal}
        />
      </div>
    );
  }
}

SingleSponsor.propTypes = {
  value: PropTypes.any.isRequired,
  h2: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  createSponsor: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  updateSponsor: PropTypes.func.isRequired,
  itemId: PropTypes.number,
};

SingleSponsor.defaultProps = {
  value: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    orgId: ownProps.orgId,
    id: ownProps.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    save: (orgId, data, personId = false, contentType = "json", endpoint) => {
      dispatch(savePerson(orgId, data, personId, contentType, endpoint));
    },
    createSponsor: (orgId, data) => {
      dispatch(createSponsor(orgId, data));
    },
    updateSponsor: (orgId, data, sponsorId) => {
      dispatch(updateSponsor(orgId, data, sponsorId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleSponsor);
