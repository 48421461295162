import * as React from "react";
const SvgBranding = (props) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 5.25h.008m11.317 5.341L10.6 16.566a1.666 1.666 0 0 1-2.358 0l-7.159-7.15V1.083h8.334l7.158 7.158a1.667 1.667 0 0 1 0 2.35Z"
      stroke="#0098FD"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBranding;
