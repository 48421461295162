import { connect } from "react-redux";
import {
  changePage,
  loadEvents,
  showModal,
  hideModal,
  deleteItem,
  updateEvent,
  showNormalModal,
  duplicateEvent,
  addNotification,
} from "@actions";
import ListEvents from "./ListEvents";

let timeout = null;

const mapStateToProps = (state) => {
  const list = state.api.events.list;

  return {
    events: list.data,
    page: state.page,
    organisations: list.organisations,
    meta: list.meta,
    doingAjax: state.api.doingAjax,
    fetching: list.fetching,
    criteria: list.criteria,
    router: state.page.router,
    filtered:
      list.filtersActive ||
      (list.meta.hasOwnProperty("search") && list.meta.search !== "") ||
      list.meta.archived,
    user: state.appuser.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const Dispatcher = {
    changePage: (page) => {
      return dispatch(changePage(page));
    },
    loadEvents: (
      criteria = {
        p: 1,
        rpp: 20,
        orderBy: "endDate",
        order: "DESC",
        search: "",
      },
      filters = {},
      additional = false,
      cache = false
    ) => {
      return new Promise((resolve, reject) => {
        dispatch(loadEvents(criteria, filters, undefined, additional, cache))
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },

    addNotification: (message, type) => {
      dispatch(addNotification(message, type));
    },

    toggleIsActive: (eventId, active) => {
      return new Promise(() => {
        dispatch(updateEvent(eventId, { active })).catch((err) =>
          console.warn(err)
        );
      });
    },
    updateEvent: (eventId, archive) => {
      dispatch(updateEvent(eventId, archive)).then(() =>
        dispatch(loadEvents(undefined, undefined, undefined, undefined, false))
      );
    },
    deleteItem: (id) => {
      return dispatch(deleteItem({ eventId: id }, "events"));
    },

    sortEvents: (orderBy) => {
      Dispatcher.loadEvents({ orderBy });
    },

    search: (search) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        Dispatcher.loadEvents({ search });
      }, 300);
    },

    showModal: (content, header = "", onSave) => {
      const onExited = () => {};

      const save = () => {
        dispatch(onSave());
      };
      return dispatch(
        showModal(
          content,
          header,
          () => dispatch(hideModal()),
          save,
          onExited,
          undefined,
          true
        )
      );
    },

    hideModal: () => {
      return dispatch(hideModal());
    },

    showNormalModal: (content, title) => {
      dispatch(showNormalModal(content, title));
    },

    duplicateEvent: (eventId, data) => {
      return dispatch(duplicateEvent(eventId, data));
    },
  };

  return Dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEvents);
