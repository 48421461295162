import React from "react";
import Form from "@components/form/";
import formFields from "@helpers/form-fields";
import { isEmpty, capitalizeFirstLetter } from "@helpers";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";

export default class SingleSubscription extends React.Component {
  constructor(props) {
    super(props);
    ["inject"].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.mode = this.props.subscriptionId ? "update" : "create";
  }

  componentDidMount() {
    this.props.getSubscriptions(
      this.props.eventId,
      "types",
      this.props.policyId
    );
    this.props.getSubscriptions(this.props.eventId, "periods");
  }

  inject(type, data) {
    const obj = {};
    for (const d of data) {
      obj[d.id] = d.name;
    }
    formFields.forms.events.subscriptions.subscription[type] = obj;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.inject("subtypesOptions", nextProps.types);
    this.inject("subperiodsOptions", nextProps.periods);
  }
  findFormType() {
    if (this.props.type === "types") {
      return "subscriptionTypes";
    } else if (this.props.type === "periods") {
      return "subscriptionPeriods";
    } else {
      return "subscriptions";
    }
  }
  prepareSubscription(subscription) {
    return Object.assign({}, subscription, {
      subscription: {
        subtype: subscription.eventPolicyGroupId,
        subperiod: subscription.eventSubscriptionPeriodId,
      },
    });
  }
  prepareCreateTypePeriod(value, param) {
    this.props.createTypePeriod(
      this.props.eventId,
      value.data,
      param,
      this.props.policyId
    );
  }
  prepareValue(value) {
    const wait = {};
    const rvalue = {
      price: value.price,
    };

    for (const [k, v] of Object.entries(value.subscription)) {
      let key = k;
      if (v.mode === "new") {
        wait[key] = v;
      } else {
        if (k === "subtype") {
          key = "eventPolicyGroupId";
        } else {
          key = "eventSubscriptionPeriodId";
        }
        rvalue[key] = v.value.toString();
      }
    }
    if (isEmpty(wait)) {
      this.props.saveSubscription(
        this.props.eventId,
        this.props.subscriptionId,
        rvalue,
        true,
        this.props.policyId
      );
    } else {
      const promises = [];
      for (const [k, v] of Object.entries(wait)) {
        const promise = new Promise((resolve, reject) => {
          const func = `create${capitalizeFirstLetter(k.substring(3))}`;
          this.props[func](this.props.eventId, v.value)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then((data) => {
          data.map((d) => {
            rvalue[
              d.hasOwnProperty("startDate")
                ? "eventSubscriptionPeriodId"
                : "eventPolicyGroupId"
            ] = d.id;
          });
          this.props.saveSubscription(
            this.props.eventId,
            this.props.subscriptionId,
            rvalue,
            this.props.policyId
          );
          this.props.getSubscriptions(this.props.eventId);
        })
        .then(() => {
          this.props.getSubscriptions(this.props.eventId);
        })
        .then(() => {
          this.props.getSubscriptions(this.props.eventId, "periods");
          this.props.getSubscriptions(
            this.props.eventId,
            "types",
            this.props.policyId
          );
        })
        .catch((err) => console.error(err));
    }
  }

  render() {
    if (!this.props.ready) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }

    return (
      <div className="new-event form-container">
        <h2>{`${this.mode === "update" ? "Edit" : "New"} Subscription${
          this.props.type === "periods" ? " Period" : ""
        }`}</h2>
        <Form
          componentName="events"
          formName={this.findFormType()}
          value={
            this.mode === "create"
              ? null
              : this.props.type
              ? this.props.item
              : this.prepareSubscription(this.props.subscription)
          }
          onSubmit={(data) => {
            if (this.props.type) {
              if (this.mode === "update") {
                this.props.updateTypePeriod(
                  this.props.eventId,
                  data.value,
                  this.props.item.id,
                  this.props.type,
                  this.props.policyId
                );
              } else {
                if (isEmpty(data.errors)) {
                  this.props.createTypePeriod(
                    this.props.eventId,
                    data.value,
                    this.props.type,
                    this.props.policyId
                  );
                }
              }
            } else {
              if (isEmpty(data.errors)) {
                this.prepareValue(data.value);
              }
            }
          }}
          onCancel={() => this.props.hideModal()}
        />
      </div>
    );
  }
}

SingleSubscription.propTypes = {
  getSubscriptions: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  periods: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  ready: PropTypes.bool.isRequired,
  saveSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  hideModal: PropTypes.func,
  subscriptionId: PropTypes.number,
  refreshSubmitions: PropTypes.func,
  type: PropTypes.string,
  createTypePeriod: PropTypes.func.isRequired,
  createType: PropTypes.func,
  createPeriod: PropTypes.func,
  item: PropTypes.any,
  updateTypePeriod: PropTypes.func,
  policyId: PropTypes.number,
};
