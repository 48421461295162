import React from "react";
import PropTypes from "prop-types";

export const Checkbox = (props) => {
  return (
    <div className={"type-checkbox kmb-checkbox " + (props.className || "")}>
      <input
        style={{ position: "relative", display: "none" }}
        type="checkbox"
        id={props.id}
        name={props.id}
        onChange={props.onChange}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  defaultChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default Checkbox;
