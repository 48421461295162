import * as React from "react";
const SvgSendNow = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.922 1.078a.833.833 0 0 1 .198.865l-5.834 16.666a.833.833 0 0 1-1.548.063l-3.203-7.207-7.207-3.203a.833.833 0 0 1 .063-1.548L18.058.881a.833.833 0 0 1 .864.197Zm-8.761 9.94 2.247 5.057L16.34 4.839l-6.18 6.18Zm5-7.358L3.927 7.593 8.982 9.84l6.18-6.18Z"
      fill="#fff"
    />
  </svg>
);
export default SvgSendNow;
