import React from "react";
import { connect } from "react-redux";

import {
  getPresetEmails,
  deletePresetEmail,
  sendPresetEmail,
  updatePresetEmailPublicStatus,
  updatePresetEmail,
  createPresetEmail,
  createVars,
} from "@actions/presetEmails";
import { listGroups } from "@actions/policyGroups";
import { changeTab } from "@actions/editmenu";

import { showModal, hideModal, addNotification } from "@actions";
import SingleContact from "../partials/SingleContact";
import Presets from "./Presets";
const sort = {
  recipient: 0,
  organisation: 1,
  event: 2,
  abstract_schema: 3,
  links: 4,
  action: 5,
};
const mapStateToProps = (state) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    globalVars: state.api.events.presetEmails.meta.email_variables?.sort(
      (a, b) => (sort[a.key] < sort[b.key] ? -1 : 1)
    ),
    tempContacts: state.users.tempContacts,
    eventId: state.api.events.edit.data.id,
    orgId: state.api.events.edit.data.orgId,
    supportEmail: state.api.events.edit.data.supportEmail,
    fakeChild: state.editmenu.fakeChild,
    policyGroups: state.api.policyGroups.list.data,
    fetching:
      state.api.events.presetEmails.fetching ||
      state.api.policyGroups.list.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    contactModal: (
      orgId,
      emailId,
      emails,
      contactAction,
      contact = {},
      contactIndex,
      policyGroups,
      saveEmail = dispatcher.updateEmail
    ) => {
      dispatch(
        showModal(
          <SingleContact
            policyGroups={policyGroups}
            emails={emails}
            contactAction={contactAction}
            addNotification={dispatcher.addNotification}
            saveEmail={saveEmail}
            contact={contact}
            contactIndex={contactIndex}
            orgId={orgId}
            emailId={emailId}
            hideModal={dispatcher.hideModal}
            emailType={"preset"}
          />
        )
      );
    },
    loadGroups: (eventId) => {
      return dispatch(listGroups(eventId));
    },
    loadPresetEmails: (orgId, eventId) => {
      dispatch(getPresetEmails(orgId, eventId));
    },
    createNewPresetEmail: (orgId, data) => {
      return dispatch(createPresetEmail(orgId, data)).then(() =>
        dispatcher.changeTab("Presets", null)
      );
    },

    deleteEmail: (orgId, emailId, eventId) => {
      return dispatch(deletePresetEmail(orgId, emailId, eventId));
    },
    switchPublicStatus: (orgId, emailId, isPublic) => {
      dispatch(updatePresetEmailPublicStatus(orgId, emailId, isPublic));
    },
    updateEmail: (orgId, emailId, data, eventId) => {
      return dispatch(updatePresetEmail(orgId, emailId, data))
        .then(() => {
          return dispatch(getPresetEmails(orgId, eventId));
        })
        .then((email) => {
          dispatch(hideModal());
          return email;
        });
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    hideModal: (checkForChanges) => {
      dispatch(hideModal(checkForChanges));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    createTempVars: (contacts) => {
      dispatch(createVars(contacts));
    },
    sendEmail: (orgId, emailid, eventId) => {
      return dispatch(sendPresetEmail(orgId, emailid, eventId)).then(() => {
        dispatch(addNotification("Email sent successfully", "success"));
        return null;
      });
    },
    changeTab: (tab, fakeChild) => dispatch(changeTab(tab, fakeChild)),
  };
  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Presets);
