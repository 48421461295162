/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from "react-sortable-hoc";
import NoResults from "./NoResults";

const DragHandle = SortableHandle((id) => (
  <td key={`${id}-td-draghandle`} className={`kmb-draghandle`}>
    <i className="fa fa-sort"></i>
  </td>
));

const SortableItem = SortableElement((props) => {
  return props.html;
});

const bodyHtml = (items, data) => {
  const {
    id,
    columns,
    onRender,
    onRenderColSpan,
    sortable,
    onTrClick,
    onTrClassName,
    defaultDragHandle,
  } = data;

  return (
    <tbody>
      {items.map((item, trIndex) => {
        const content = [];
        const hasColSpan =
          item.hasOwnProperty("colSpan") && item.colSpan ? true : false;

        if (hasColSpan && onRenderColSpan) {
          const jsx = onRenderColSpan(item, trIndex);
          if (jsx !== undefined) {
            content.push(jsx);
          }
        } else {
          Object.keys(columns).map((key, tdIndex) => {
            let value = item.hasOwnProperty(key) ? item[key] : "";

            if (onRender) {
              const jsx = onRender({ key, value }, item, trIndex);
              if (jsx !== undefined) {
                // we have an override
                value = jsx;
              }
            }
            content.push(
              <td key={`${id}-${trIndex}-${tdIndex}`} className={`kmb-${key}`}>
                {value}
              </td>
            );
          });
        }

        let trClassName = hasColSpan ? "has-colspan" : "";
        if (onTrClassName) {
          const t = onTrClassName(trClassName, item);
          if (t !== undefined) trClassName = t;
        }
        if (data.trClassNameFunc) {
          trClassName += " " + data.trClassNameFunc(item);
        }
        const html = (
          <tr
            onClick={(e) => onTrClick(item, e)}
            key={`${id}-${trIndex}`}
            className={trClassName}
          >
            {sortable && item.hasOwnProperty("id") && defaultDragHandle && (
              <DragHandle id={id} />
            )}
            {content}
          </tr>
        );

        if (sortable && item.hasOwnProperty("id")) {
          return (
            <SortableItem html={html} index={trIndex} key={`item-${trIndex}`} />
          );
        }

        return html;
      })}
    </tbody>
  );
};

const SortableList = SortableContainer(({ items, props }) =>
  bodyHtml(items, props)
);

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.updateBeforeSortStart = this.updateBeforeSortStart.bind(this);
    this.state = { items: props.items };
  }
  createObserver() {
    if (!window.IntersectionObserver) return;
    this.observer?.disconnect();
    const table = this.table;
    const elementID = table.querySelector(".actions-observer");
    const root =
      document.querySelector(".tab-content") ||
      document.querySelector(".main-wrapper");

    if (!elementID) return;
    if (!root) return;
    this.observer = new IntersectionObserver(
      ([e]) => {
        return table.classList.toggle(
          "actions-pinned",
          e.intersectionRatio < 0.1
        );
      },
      {
        root,
        threshold: [0.1],
        rootMargin: "40000px 0px 40000px 0px", //this big number ensures handling for vertical scroll
      }
    );
    this.observer.observe(elementID);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.createObserver();
    this.setState({
      items: nextProps.items,
    });
  }
  componentDidMount() {
    this.createObserver();
  }
  componentWillUnmount() {
    this.observer?.disconnect();
  }
  onSortEnd({ oldIndex, newIndex }) {
    this.setState(
      {
        items: arrayMove(this.state.items, oldIndex, newIndex),
      },
      () =>
        this.props.onSortEnd(
          newIndex,
          this.state.items[newIndex],
          this.state.items,
          oldIndex
        )
    );
  }
  updateBeforeSortStart({ node, index, collection, isKeySorting }, event) {
    this.props.updateBeforeSortStart(
      { node, index, collection, isKeySorting },
      event
    );
  }

  render() {
    const { id, columns, containerClassName, updating, onRenderTh, sortable } =
      this.props;
    const { items } = this.state;
    return (
      <div
        className={`med-table-container${
          containerClassName ? ` ${containerClassName}` : ""
        }`}
        ref={(elem) => (this.table = elem)}
      >
        {(updating || items.length) > 0 && (
          <table className="table">
            {Object.keys(columns).length && this.props.includeHead && (
              <thead>
                <tr>
                  {sortable && (
                    <th
                      key={`${id}-th-draghandle`}
                      className={`kmb-draghandle`}
                    ></th>
                  )}
                  {Object.keys(columns).map((key, thIndex) => {
                    const className = `${key}${
                      columns[key].hasOwnProperty("activeSort") ||
                      columns[columns[key]?.sortValue]?.hasOwnProperty(
                        "activeSort"
                      )
                        ? " is-active"
                        : ""
                    }`;
                    let th = <div>{columns[key].name}</div>;

                    if (columns[key].sortable) {
                      th = (
                        <>
                          <div
                            onClick={() =>
                              this.props.onSort(columns[key]?.sortValue || key)
                            }
                            className="sortable-th"
                          >
                            {columns[key].name}
                            <span className="icon-sort-arrow-down"></span>
                          </div>
                        </>
                      );
                    }

                    if (onRenderTh) {
                      const jsx = onRenderTh(
                        { key, thIndex, name: columns[key].name },
                        className
                      );
                      if (jsx !== undefined) {
                        // we have an override
                        th = jsx;
                      }
                    }

                    return (
                      <th
                        key={`${id}-th-${thIndex}`}
                        className={`kmb-${className}`}
                        style={
                          key === "actions" ? { padding: 0, width: 50 } : {}
                        }
                      >
                        {th}
                      </th>
                    );
                  })}
                  {Object.keys(columns).includes("actions") && (
                    <th
                      key={`${id}-th-observer-act`}
                      className="actions-observer"
                      style={{
                        padding: 0,
                        width: 1,
                        maxWidth: 1,
                        position: "relative",
                        left: -24,
                      }}
                    ></th>
                  )}
                </tr>
              </thead>
            )}

            {!updating && sortable && (
              <SortableList
                items={items}
                props={this.props}
                onSortEnd={this.onSortEnd}
                updateBeforeSortStart={this.updateBeforeSortStart}
                lockAxis="y"
                lockToContainerEdges={true}
                lockOffset={`70%`}
                useDragHandle={true}
                helperClass={this.props.id}
              />
            )}
            {!updating && !sortable && bodyHtml(items, this.props)}
          </table>
        )}
        {updating && (
          <KMBLoader
            rows={this.props.loadingRows}
            height={this.props.loadingRowsHeight}
          />
        )}
        {!updating && items.length === 0 && (
          <NoResults filtered={this.props.filtered} />
        )}
      </div>
    );
  }
}

Table.defaultProps = {
  id: "",
  items: [],
  columns: {},
  containerClassName: "",
  onSort: () => {},
  updating: false,
  sortable: false,
  onSortEnd: () => {},
  updateBeforeSortStart: () => {},
  quickEdit: null,
  onTrClick: () => {},
  defaultDragHandle: true,
  loadingRows: 10,
  loadingRowsHeight: 53,
  includeHead: true,
};

Table.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  columns: PropTypes.object,
  onRender: PropTypes.func,
  onRenderTh: PropTypes.func,
  onTrClassName: PropTypes.func,
  onSort: PropTypes.func,
  containerClassName: PropTypes.string,
  updating: PropTypes.bool,
  onRenderColSpan: PropTypes.func,
  sortable: PropTypes.bool,
  includeHead: PropTypes.bool,
  onSortEnd: PropTypes.func,
  updateBeforeSortStart: PropTypes.func,
  quickEdit: PropTypes.number,
  loadingRows: PropTypes.number,
  onTrClick: PropTypes.func,
  defaultDragHandle: PropTypes.bool,
};
