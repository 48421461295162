import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updateClientPanelSettings,
  showModal,
  updateClientPanelFooterSponsorOrder,
  deleteClientPanelFooterSponsor,
} from "@actions";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import SingleFooterSponsor from "./SingleFooterSponsor";

const Sponsors = (props) => {
  const sortFooterSponsors = (a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    return 0;
  };

  const onRender = (col, item) => {
    switch (col.key) {
      case "clientFooterSponsorImage": {
        return (
          <img
            src={
              (item.clientFooterSponsorImage || [])[0]
                ? item.clientFooterSponsorImage[0].url
                : "/images/medlive-noimage.jpg"
            }
            style={{ objectFit: "contain", width: 50, height: 40 }}
          />
        );
      }
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="footer sponsor"
              item={item}
              onDelete={() => {
                return props.deleteClientPanelFooterSponsor(
                  props.event.id,
                  item.id
                );
              }}
              onEdit={() => {
                return props.showModal(
                  <SingleFooterSponsor
                    item={item}
                    onSave={(data, preserveModal, file) => {
                      if (!file) {
                        return props.updateClientPanelSettings(
                          props.event.id,
                          data,
                          "footer-sponsors",
                          item.id,
                          preserveModal
                        );
                      } else {
                        return props.updateClientPanelSettings(
                          props.event.id,
                          data,
                          "footer-sponsors",
                          item.id,
                          preserveModal,
                          file
                        );
                      }
                    }}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <div className="panel-content-card footer-content sponsors">
      <div key={`type-item-${props.index}`} className="form-container">
        <Table
          sortable={true}
          onSortEnd={(newIndex, item, newItems) => {
            const newSortList = newItems.map((sorted, index) => ({
              id: sorted.id,
              index,
            }));

            return props.updateClientPanelFooterSponsorOrder(props.event.id, {
              footerSponsors: newSortList,
            });
          }}
          columns={{
            clientFooterSponsorImage: {
              name: "Footer Sponsor Image",
            },
            title: {
              name: "Title",
            },
            url: {
              name: "Action URL",
            },
            description: {
              name: "Description",
            },
            actions: {
              name: "",
            },
          }}
          updating={props.fetching}
          items={props.event.clientPanelSettings.footer.sponsorsSchema.sort(
            sortFooterSponsors
          )}
          containerClassName={`table-container `}
          onRender={onRender}
        />
      </div>
    </div>
  );
};

Sponsors.propTypes = {
  updateClientPanelFooterSponsorOrder: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  deleteClientPanelFooterSponsor: PropTypes.func,
  showModal: PropTypes.func,
  updateClientPanelSettings: PropTypes.func,
  index: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    fetching: state.api.events.edit.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
    updateClientPanelFooterSponsorOrder: (eventId, data) => {
      return dispatch(updateClientPanelFooterSponsorOrder(eventId, data));
    },
    deleteClientPanelFooterSponsor: (eventId, data) => {
      return dispatch(deleteClientPanelFooterSponsor(eventId, data));
    },
    showModal: (modal) => {
      return dispatch(showModal(modal));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);
