import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import InnerQuestionnaire from "./partials/innerQuestionnaire";
import Form from "@components/form";
import { Button } from "@layout/Buttons";
import { createContactLabel } from "../../../../../../helpers";
import moment from "moment";

export default class Questionnaires extends Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.changeTab = this.changeTab.bind(this);

    this.state = {
      emailId: null,
      mode: null,
    };
  }
  componentDidMount() {
    this.props.getQuestionnaires(this.props.eventId);
  }
  changeTab() {
    this.setState({
      emailId: null,
      mode: null,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fakeChild !== "inner") {
      this.setState({ emailId: null, mode: null });
    }
  }

  onRender(col, item) {
    switch (col.key) {
      case "recipients": {
        return (
          <div className="recipients-column">
            {item.contacts.slice(0, 2).map((child, index) => {
              return (
                <div key={`contact-item-${index}`} className="contact-item">
                  <Button disableRipple style={{ height: "24px" }}>
                    {createContactLabel(child, {
                      groups: this.props.policyGroups,
                    })}
                  </Button>
                </div>
              );
            })}
            {item.contacts.slice(2).length > 0 && (
              <div key={`contact-item-${2}`} className="contact-item">
                <Button disableRipple style={{ height: "24px" }}>
                  {`& ${item.contacts.slice(2).length} more...`}
                </Button>
              </div>
            )}
          </div>
        );
      }
      case "id": {
        return <div className="id-column">{col.value}</div>;
      }
      case "lastSent": {
        return (
          <div className="id-column">
            {item.lastSentAt
              ? moment
                  .utc(item.lastSentAt, "YYYY-MM-DD HH:mm:ss")
                  .tz(this.props.event.timezone)
                  .format("Do MMMM  YY - HH:mm")
              : "N/A"}
          </div>
        );
      }
      case "name": {
        return (
          <div
            onClick={() =>
              this.setState(
                {
                  mode: "edit",
                  emailId: item.id,
                },
                () => this.props.changeTab("Questionnaires", "inner")
              )
            }
            className="email-label-column"
          >
            {col.value}
          </div>
        );
      }
      case "actions": {
        return (
          <TableActions
            entity="email"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <div className="form-container">
                  <h2> Edit {item.name} name </h2>
                  <Form
                    componentName="email"
                    formName="edit"
                    onSubmit={(data) => {
                      this.props.updateTitle(
                        this.props.eventId,
                        item.id,
                        data.value
                      );
                    }}
                    value={{ name: item.name }}
                    onCancel={() => {
                      this.props.hideModal();
                    }}
                  />
                </div>
              )
            }
            onDelete={() =>
              this.props.deleteQuestionnaire(this.props.eventId, item.id)
            }
          />
        );
      }
    }
  }
  render() {
    if (this.state.mode === "create") {
      const email = {
        eventId: this.props.eventId,
        contacts: [],
        name: "Untitled",
        template: {
          html: "",
          name: "Untitled",
        },
      };
      return (
        <InnerQuestionnaire
          email={email}
          mode={this.state.mode}
          {...this.props}
        />
      );
    }
    if (this.state.mode === "edit") {
      const email = this.props.questionnaires.filter(
        (questionnaire) => questionnaire.id == this.state.emailId
      )[0];
      return (
        <InnerQuestionnaire
          email={email}
          mode={this.state.mode}
          {...this.props}
        />
      );
    }
    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <div className="right">
              <button
                type="button"
                className="btn create rounded"
                onClick={() => {
                  this.props.changeTab("Questionnaires", "inner");
                  this.setState({ mode: "create" });
                }}
              >
                Create questionnaire email
              </button>
            </div>
          </div>
        </div>
        <div className="tab-content indexed">
          <Table
            updating={this.props.fetching}
            columns={{
              id: {
                name: "ID",
              },
              name: {
                name: "Questionnaire Name",
              },
              recipients: {
                name: "Recipients",
              },
              actions: {
                name: "",
              },
            }}
            onRender={this.onRender}
            items={this.props.questionnaires}
          />
        </div>
      </>
    );
  }
}
Questionnaires.propTypes = {
  event: PropTypes.object.isRequired,
  eventId: PropTypes.number.isRequired,
  getQuestionnaires: PropTypes.func.isRequired,
  questionnaires: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  deleteQuestionnaire: PropTypes.func.isRequired,
  changeTab: PropTypes.func,
  sendQuestionnaire: PropTypes.func,
  policyGroups: PropTypes.array,
  fakeChild: PropTypes.string,
  fetching: PropTypes.bool,
};

Questionnaires.defaultProps = {
  changeTab: () => {},
};
