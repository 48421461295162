import React from "react";

const Schedule = () => {
  return (
    <React.Fragment>
      <div className="configuration-placeholder">
        No configuration options available
      </div>
    </React.Fragment>
  );
};

Schedule.propTypes = {};

export default Schedule;
