import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import Form from "@components/form";

const SingleHomeSponsor = (props) => {
  const [state, setState] = React.useState({ item: props.item || {} });

  return (
    <div className="form-container single-home-sponsor">
      <h2>{`${props.mode == "create" ? "Create Sponsor" : "Edit Sponsor"}`}</h2>
      <Form
        formName="homeSponsors"
        componentName="clientPanel"
        value={state.item}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;
          if (
            !state.item.clientHomeSponsorImage?.length &&
            !data.value.clientHomeSponsorImage.length &&
            ((props.item || {}).clientHomeSponsorImage || [])[0]
          ) {
            data.value.clientHomeSponsorImage = [
              props.item.clientHomeSponsorImage[0].id,
            ];
          } else if (!data.value.clientHomeSponsorImage.length) {
            delete data.value.clientHomeSponsorImage;
          }
          props.onSave(data.value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
        onChange={(data) => {
          if (data.key === "clientHomeSponsorImage") {
            setState({ item: { ...state.item, clientHomeSponsorImage: [] } });
          }
        }}
      />
    </div>
  );
};

SingleHomeSponsor.propTypes = {
  hideModal: PropTypes.func,
  item: PropTypes.object,
  mode: PropTypes.string,
  onSave: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleHomeSponsor);
