import { api } from "@actions/ajax";
import { isArray, isEmpty } from "@helpers";
import { addNotification } from "@actions";
import { uploadFile } from ".";

export const RECEIVED_ABSTRACTS_UPDATE = "RECEIVED_ABSTRACTS_UPDATE";
export const RECEIVED_ABSTRACTS_UPDATEMULTIPLE =
  "RECEIVED_ABSTRACTS_UPDATEMULTIPLE";
export const VIEW_ABSTRACT = "VIEW_ABSTRACT";
export const REQUEST_ABSTRACTS_GETJUDGES = "REQUEST_ABSTRACTS_GETJUDGES";
export const RECEIVED_ABSTRACTS_GETJUDGES = "RECEIVED_ABSTRACTS_GETJUDGES";

export const getJudges = () => (dispatch, getState) => {
  const state = getState();
  const eventId = state.api.events.edit.data.id;
  dispatch(
    api({
      endpoint: "abstracts",
      action: "getJudges",
      params: { eventId },
    })
  );
};
export const getStatistics = () => (dispatch, getState) => {
  const state = getState();
  const eventId = state.api.events.edit.data.id;
  return dispatch(
    api({
      endpoint: "abstracts",
      action: "statistics",
      params: { eventId },
    })
  );
};
const getAbstractsWithPreviousMeta = (dispatch, getState) => {
  const state = getState();
  let {
    typeIds = [],
    topicIds = [],
    status = [],
    ratedBy = [],
  } = state.api.abstracts.list.meta;
  const {
    rpp,
    p,
    search = "",
    published = null,
    inSchedule = null,
  } = state.api.abstracts.list.meta;
  if (typeof typeIds == "string") typeIds = typeIds.split(",");
  if (typeof topicIds == "string") topicIds = topicIds.split(",");
  if (typeof status == "string") status = status.split(",");
  if (typeof ratedBy == "string") ratedBy = ratedBy.split(",");
  return getAbstracts(
    {
      rpp,
      p,
    },
    { typeIds, topicIds, status, search, published, ratedBy, inSchedule }
  );
};
export const unassignAbstractsFromJudges =
  (eventId, abstractIds, unassignRated = false) =>
  (dispatch, getState) => {
    return dispatch(
      api({
        endpoint: "abstracts",
        action: "unassignJudges",
        params: { eventId },
        body: {
          data: {
            abstractIds,
            unassignRated,
          },
        },
      })
    ).then(() => {
      dispatch(
        addNotification("Bulk unassign completed successfully!", "success")
      );
      return getAbstractsWithPreviousMeta(dispatch, getState);
    });
  };

export const publishAbstracts =
  (eventId, publishedAbstracts, publish = false) =>
  (dispatch, getState) => {
    return dispatch(
      api({
        endpoint: "abstracts",
        action: "publish",
        params: { eventId },
        body: {
          data: {
            publish,
            publishedAbstracts,
          },
        },
      })
    ).then(() => {
      dispatch(
        addNotification(
          `Bulk ${publish ? "publish" : "un-publish"} completed successfully!`,
          "success"
        )
      );
      return getAbstractsWithPreviousMeta(dispatch, getState);
    });
  };

export const assignAbstractsToJudges =
  (eventId, abstractIds, judgeIds) => (dispatch, getState) => {
    return dispatch(
      api({
        endpoint: "abstracts",
        action: "assignJudges",
        params: { eventId },
        body: {
          data: {
            abstractIds,
            judgeIds,
          },
        },
      })
    ).then(() => {
      dispatch(
        addNotification("Bulk assign completed successfully!", "success")
      );
      return getAbstractsWithPreviousMeta(dispatch, getState);
    });
  };

export const updateAbstract =
  (
    eventId,
    abstractId,
    data = {},
    bulkJudgeRate = false,
    _, // multpart = false
    getAllAbstracts = true
  ) =>
  (dispatch, getState) => {
    const params = isArray(abstractId) ? { eventId } : { eventId, abstractId };

    const body = isArray(abstractId)
      ? { data: Object.assign({}, data, { abstractIds: abstractId }) }
      : { data };

    if (bulkJudgeRate) {
      body.data["keepJudgeScore"] = true;
    }

    const action = isArray(abstractId) ? "updateMultiple" : "update";

    const filesToUpload = {
      publishedAbstractThumbnail: Object.assign(
        [],
        data.publishedAbstractThumbnail
      ),
      publishedAbstractFile: Object.assign([], data.publishedAbstractFile),
    };

    if (
      data.publishedAbstractThumbnail &&
      typeof data.publishedAbstractThumbnail[0] !== "number"
    ) {
      data.publishedAbstractThumbnail[0] =
        data.publishedAbstractThumbnail[0].name;
    }
    if (
      data.publishedAbstractFile &&
      typeof data.publishedAbstractFile[0] !== "number"
    ) {
      data.publishedAbstractFile[0] = data.publishedAbstractFile[0].name;
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    if (data.published !== undefined) {
      body.data.published = Boolean(body.data.published);
    }

    const requestOptions = {
      endpoint: "abstracts",
      action,
      params,
      body,
    };

    if (!hasFiles) {
      return dispatch(api(requestOptions)).then((data) => {
        if (action === "updateMultiple") {
          dispatch(
            addNotification(
              "Bulk action on abstracts completed successfully!",
              "success"
            )
          );
        }
        if (getAllAbstracts) getAbstractsWithPreviousMeta(dispatch, getState);
        return data;
      });
    } else {
      return dispatch(api(requestOptions)).then((data) => {
        const promiseArr = [];
        Object.keys(filesToUpload).forEach((key) => {
          if (
            filesToUpload[key][0] &&
            typeof filesToUpload[key][0] !== "number"
          ) {
            promiseArr.push(
              dispatch(
                uploadFile(
                  eventId,
                  key,
                  filesToUpload[key][0],
                  { abstractId },
                  () => {
                    dispatch(getAbstractsWithPreviousMeta(dispatch, getState));
                  },
                  () => {
                    dispatch(getAbstractsWithPreviousMeta(dispatch, getState));
                    dispatch(
                      addNotification(
                        "An error occured while uploading your files",
                        "error"
                      )
                    );
                  },
                  filesToUpload[key].length
                )
              )
            );
          }
        });

        Promise.all(promiseArr)
          .then(() => {
            dispatch(getAbstractsWithPreviousMeta(dispatch, getState));
          })
          .catch((err) => {
            console.warn(err);
          });
        return data;
      });
    }
  };

export const createAbstract =
  (eventId, body = {}) =>
  (dispatch) => {
    const requestOptions = {
      endpoint: "abstracts",
      action: "create",
      params: { eventId },
      body,
    };
    return dispatch(api(requestOptions)).then(() =>
      dispatch(getAbstracts(eventId))
    );
  };

export const createOrgTopic = (orgId, name) => (dispatch) =>
  dispatch(
    api({
      endpoint: "topics",
      action: "createOrgTopic",
      params: { orgId },
      body: { data: { name } },
    })
  );
export const createOrgType = (orgId, name) => (dispatch) =>
  dispatch(
    api({
      endpoint: "types",
      action: "createOrgType",
      params: { orgId },
      body: { data: { name } },
    })
  );

export const getTopics = (orgId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "topics",
      action: "getOrgTopics",
      params: { orgId },
      query: "?rpp=-1",
    })
  );
export const getTypes = (orgId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "types",
      action: "getOrgTypes",
      params: { orgId },
      query: "?rpp=-1",
    })
  );
export const loadSchemas = (eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "abstracts",
      action: "schemas",
      query: `?rpp=-1&eventId=${eventId}`,
    })
  );

export const loadSchema = (schemaId, eventId) => (dispatch) => {
  return dispatch(
    api({
      endpoint: "abstracts",
      action: "getSchema",
      params: { schemaId, eventId },
      query: `?dropdownValuesAsObject=true`,
    })
  );
};

export const loadEventSchema = (eventId) => (dispatch) => {
  return dispatch(
    api({
      endpoint: "abstracts",
      action: "getEventSchema",
      params: { eventId },
    })
  );
};

export const assignSchema =
  (eventId, data, schemaEventId = false, notification = true) =>
  (dispatch, getState) => {
    const hasSchema = getState().api.events.edit.data.orgAbstractSchemaId;
    const request = {
      endpoint: "abstracts",
      action: hasSchema ? "updateEventSchema" : "createEventSchema",
      params: { eventId },
      body: { data },
    };

    if (schemaEventId) {
      request.params["schemaEventId"] = schemaEventId;
    }

    return dispatch(api(request)).then((data) => {
      if (notification) {
        dispatch(
          addNotification(`Abstract Schema updated successfully!`, "success")
        );
      }
      return data;
    });
  };

export const createSchema =
  (
    _request,
    updating = false,
    eventSchemaId = false,
    removePreviousMeta = false
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;
    const orgId = state.api.events.edit.data.orgId;

    delete _request["createdAt"];
    delete _request["updatedAt"];
    delete _request["id"];
    delete _request["orgId"];
    delete _request["eventAbstractSchemaId"];
    delete _request["eventId"];
    delete _request["eventName"];
    _request["removePreviousMeta"] = removePreviousMeta;
    const request = {
      endpoint: "abstracts",
      action: updating ? "updateSchema" : "createSchema",
      body: { data: _request },
      params: { eventId },
    };

    if (updating && eventSchemaId) {
      request.params["schemaId"] = eventSchemaId;
    }
    dispatch(api(request))
      .then(({ data }) => {
        if (updating) {
          dispatch(
            addNotification(`Abstract Schema updated successfully!`, "success")
          );
        }
        dispatch(getTopics(orgId));
        dispatch(getTypes(orgId));
        dispatch(loadSchema(data.id, eventId));
      })
      .catch((err) => console.error(err));
  };

export const getAbstract = (eventId, abstractId) => (dispatch) => {
  const request = {
    endpoint: "abstracts",
    action: "get",
    params: { eventId, abstractId },
  };
  return dispatch(api(request));
};
export const deleteAbstract = (eventId, abstractId) => (dispatch) => {
  const request = {
    endpoint: "abstracts",
    action: "delete",
    params: { eventId, abstractId },
    body: {
      data: {},
    },
  };
  return dispatch(api(request));
};

const analyzeFilters = (filters) => {
  const _filters = Object.assign({}, filters);

  if (_filters.hasOwnProperty("status")) {
    // break down
    const passingAdmin = ["passingByAdmin", "passingByAdmin_false"].filter(
      (v) => -1 !== _filters.status.indexOf(v)
    );
    const passingJudge = ["passingByJudge", "passingByJudge_false"].filter(
      (v) => -1 !== _filters.status.indexOf(v)
    );
    const status = [..._filters.status].filter(
      (f) =>
        ![
          "passingByAdmin",
          "passingByAdmin_false",
          "passingByJudge",
          "passingByJudge_false",
        ].includes(f)
    );
    delete _filters["status"];
    if (passingAdmin.length) {
      const includeApprovedOrRejected = [];
      if (passingAdmin.includes("passingByAdmin")) {
        includeApprovedOrRejected.push(1);
      }
      if (passingAdmin.includes("passingByAdmin_false")) {
        includeApprovedOrRejected.push(0);
      }
      if (includeApprovedOrRejected.length) {
        _filters["passingByAdmin"] = includeApprovedOrRejected.join(",");
      }
    }
    if (passingJudge.length) {
      const includeApprovedOrRejectedByJudge = [];
      if (passingJudge.includes("passingByJudge")) {
        includeApprovedOrRejectedByJudge.push(1);
      }
      if (passingJudge.includes("passingByJudge_false")) {
        includeApprovedOrRejectedByJudge.push(0);
      }
      if (includeApprovedOrRejectedByJudge.length) {
        _filters["passingByJudge"] = includeApprovedOrRejectedByJudge.join(",");
      }
    }
    if (status.length > 0) {
      _filters["status"] = status;
    }
  }
  return _filters;
};

export const getAbstracts =
  (meta = {}, filters = {}, returnAbstracts = false) =>
  (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;
    const {
      rpp,
      typeIds = [],
      topicIds = [],
      status,
    } = state.api.abstracts.list.meta;
    let query = "";
    let cache = false;

    filters = analyzeFilters(filters);
    if (meta.hasOwnProperty("rpp") && meta.rpp !== rpp) {
      meta["p"] = 1;
    }
    if (!isEmpty(meta)) {
      if (!isEmpty(filters)) {
        if (
          (filters.typeIds &&
            typeIds.toString() !== filters.typeIds.toString()) ||
          (filters.topicIds &&
            topicIds.toString() !== filters.topicIds.toString()) ||
          (filters.status && [status].toString() !== filters.status.toString())
        ) {
          meta["p"] = 1;
        }
      }
      Object.entries(meta).map(([k, v]) => {
        query += query === "" ? "?" : "&";
        query += `${k}=${v}`;
      });
    }
    if (!isEmpty(filters)) {
      Object.entries(filters).map(([k, v]) => {
        query += query === "" ? "?" : "&";
        if (isArray(v)) {
          const check = v[0] ?? null;
          if (check === null) return;
          query += `${k}=${v.join(",")}`;
        } else {
          const check = v ?? null;
          if (check === null) return;
          //only search for now
          query += `${k}=${v}`;
        }
      });
    }

    if (query.length) {
      cache = false;
    }
    if (returnAbstracts) {
      return dispatch(
        api({
          endpoint: "abstracts",
          action: "view",
          params: { eventId },
          cache: false,
          query,
          dataType: meta.export ? "buffer" : undefined,
        })
      );
    }
    return dispatch(
      api({
        endpoint: "abstracts",
        action: "list",
        params: { eventId },
        cache,
        query,
      })
    );
  };
