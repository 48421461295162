import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";

const DataViewSponsorships = (props) => {
  if (isEmpty(props.data)) return null;

  const { totalSponsorships, sumAmount } = props.data;
  const style = { textAlign: "center" };
  return (
    <div
      className="med-table-container table-container "
      style={{ paddingBottom: 20 }}
    >
      <table className="table">
        <thead>
          <tr>
            <th style={style}>Total Sponsorships </th>
            <th style={style}>Sum Amount ({props.currency})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={style}>{totalSponsorships}</td>
            <td style={style}>
              <strong>{(sumAmount || 0).toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

DataViewSponsorships.propTypes = {
  data: PropTypes.object,
  currency: PropTypes.string,
};

export default DataViewSponsorships;
