import { api } from "@actions/ajax";
import { changeTab } from "./editmenu";
export const SELECT_HOOK = "SELECT_HOOK";
export const getHookEmails = (eventId, hookId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "hookEmails",
      action: "list",
      params: { eventId, hookId },
    })
  );

export const selectHook = (hook, type) => (dispatch) => {
  dispatch(changeTab("Hooks", "innerHook"));
  return dispatch({
    type: SELECT_HOOK,
    hookType: type,
    hook,
  });
};

export const getHooks = (eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "eventHooks",
      action: "list",
      params: { eventId },
      query: `?rpp=-1`,
    })
  );

export const getHook = (eventId, hookId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "eventHooks",
      action: "get",
      params: { eventId, hookId },
    })
  );

export const saveEmail =
  (eventId, hookId, emailId, action, data) => (dispatch) =>
    dispatch(
      api({
        endpoint: "hookEmails",
        action: action,
        params: { eventId, hookId, emailId },
        body: data,
      })
    );

export const createEmail = (eventId, hookId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "hookEmails",
      action: "create",
      params: { eventId, hookId },
      body: data,
    })
  );

export const saveHook = (eventId, hookId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "eventHooks",
      action: "edit",
      params: { eventId, hookId },
      body: data,
    })
  );
