import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import KMBLoader from "@layout/KMBLoader";

const DataView = (props) => {
  if (isEmpty(props.data)) return null;
  if (props.data.doingAjax) {
    return (
      <div style={{ marginTop: 22 }}>
        <KMBLoader rows="1" height={53} />
      </div>
    );
  }
  const {
    currentDues = 0,
    currentSponsorAmount = 0,
    currentDiscount = 0,
    currentIncome = 0,
    sponsorships = 0,
  } = props.data;
  const style = { textAlign: "center" };
  return (
    <div
      className="med-table-container table-container "
      style={{ paddingBottom: 20 }}
    >
      <table className="table">
        <thead>
          <tr>
            <th style={style}>Income by Sponsorhips({props.currency})</th>
            <th style={style}>Total Sponsored Amount({props.currency})</th>
            <th style={style}>Discount ({props.currency})</th>
            <th style={style}>Participant Dues ({props.currency})</th>
            <th style={style}>Income By Participants ({props.currency})</th>
            <th style={style}>
              Final Income by Participants ({props.currency})
            </th>
            <th style={style}>Total Amount ({props.currency})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={style}>
              <i>{sponsorships || 0?.toFixed(2)}</i>
            </td>
            <td style={style}>
              <i>{currentSponsorAmount?.toFixed(2)}</i>
            </td>
            <td style={style}>{currentDiscount?.toFixed(2)}</td>
            <td style={style}>
              <strong>{(currentDues || 0)?.toFixed(2)}</strong>
            </td>

            <td style={style}>
              <strong>{(currentIncome || 0)?.toFixed(2)}</strong>
            </td>
            <td style={style}>
              <strong>{(currentDues + currentIncome || 0)?.toFixed(2)}</strong>
            </td>
            <td style={style}>
              {(
                currentDues + currentSponsorAmount + currentIncome || 0
              )?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

DataView.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.string,
};

export default DataView;
