import { api } from "@actions/ajax";
import { addNotification, PAYMENTS_GET_STATISTICS } from "@actions";
import { getReservations } from "@actions/reservations";
import { downloadFile } from "@helpers";

export const getPayments =
  (eventId, meta = false, statistics = false) =>
  (dispatch) => {
    const request = {
      endpoint: "payments",
      action: "list",
      params: { eventId },
    };
    const defaults = {
      rpp: 20,
      orderBy: "id",
      order: "DESC",
      p: 1,
      statistics,
    };
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });
      Object.keys(meta).forEach((key) => {
        if (key === "dateRange") {
          buildMeta[key] = JSON.stringify({
            from: meta[key].from,
            to: meta[key].to,
          });
        } else if (key === "eventUserId" && meta[key] === "") {
          console.warn("Hi this should not be happening, i am sorry :)");
        } else {
          buildMeta[key] = meta[key];
        }
      });
    }

    if (meta === false) {
      Object.keys(defaults).forEach((key) => {
        buildMeta[key] = defaults[key];
      });
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }
    if (statistics) {
      dispatch(paymentGetStatistics(true));
    }
    request.cache = false;
    return dispatch(api(request)).then((d) => {
      if (statistics) {
        dispatch(paymentGetStatistics(false));
      }
      return Promise.resolve(d);
    });
  };

export const paymentGetStatistics = (doingAjax) => (dispatch) =>
  dispatch({
    type: PAYMENTS_GET_STATISTICS,
    doingAjax,
  });

export const deletePayment = (eventId, paymentId) => (dispatch, getState) => {
  const state = getState();
  const meta = JSON.parse(JSON.stringify(state.api.payments.meta));
  delete meta.accessToken;
  delete meta.method;
  delete meta.statisticData;
  delete meta.statistics;
  delete meta.totalRows;
  delete meta.url;
  return dispatch(
    api({
      endpoint: "payments",
      action: "delete",
      params: { eventId, paymentId },
      body: {
        data: {},
      },
    })
  )
    .then(() => {
      dispatch(paymentGetStatistics(true));
      dispatch(getPayments(eventId, { ...meta, statistics: true })).then(() => {
        dispatch(paymentGetStatistics(false));
      });
      return dispatch(getPayments(eventId, meta));
    })
    .then(() =>
      dispatch(addNotification("Payment deleted successfully", "success"))
    );
};

export const getUserByIdPayment = (eventId, userId) => (dispatch) => {
  if (userId.length > 0) {
    dispatch(
      api({
        endpoint: "users",
        action: "get",
        params: { eventId, userId },
        query: "?_404=false",
      })
    );
  }
};
export const exportSponsorships = (eventId, filters) => {
  return (dispatch) => {
    const request = {
      endpoint: "sponsors",
      action: "export",
      body: {
        data: {
          rpp: -1,
          p: 1,
          ...filters,
        },
      },
      params: { eventId },
      dataType: "buffer",
    };
    dispatch(api(request)).then((file) => {
      const blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
      const objectUrl = URL.createObjectURL(blob);
      downloadFile(objectUrl, `sponsors.xlsx`);
    });
  };
};
export const createFastPayment = (eventId, data) => (dispatch) => {
  return dispatch(
    api({
      endpoint: "payments",
      action: "create",
      params: { eventId },
      body: {
        data,
      },
    })
  );
};
export const updateFastPayment = (eventId, paymentId, data) => (dispatch) => {
  return dispatch(
    api({
      endpoint: "payments",
      action: "update",
      params: { eventId, paymentId },
      body: {
        data,
      },
    })
  );
};

export const createPayment =
  (
    eventId,
    amount,
    invoice,
    invoice_url,
    way,
    type,
    eventUserId,
    transactionId,
    isReservation,
    paymentNotes,
    dates,
    typeOrReferenceId = null
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const meta = JSON.parse(JSON.stringify(state.api.payments.meta));

    delete meta.accessToken;
    delete meta.method;
    delete meta.statisticData;
    delete meta.statistics;
    delete meta.totalRows;
    delete meta.url;

    if (way === "On Site") {
      way = "on_site";
    } else if (way === "Credit Card") {
      way = "credit_card";
    }
    if (paymentNotes === null) {
      paymentNotes = "";
    }
    const data = {
      transactionId,
      amount,
      type,
      way,
      eventUserId,
      notes: paymentNotes,
    };
    if (dates) {
      data["transactionDate"] = dates.from;
    }
    if (type === "product") {
      data["typeId"] = typeOrReferenceId;
    } else if (type === "sponsorship") {
      data["orgSponsorId"] = typeOrReferenceId;
    }
    return dispatch(
      api({
        endpoint: "payments",
        action: "create",
        params: { eventId },
        body: {
          data,
        },
      })
    ).then(() => {
      if (isReservation) {
        return dispatch(getReservations(eventId));
      } else {
        dispatch(paymentGetStatistics(true));
        dispatch(getPayments(eventId, { ...meta, statistics: true })).then(
          () => {
            dispatch(paymentGetStatistics(false));
          }
        );
        return dispatch(getPayments(eventId, meta));
      }
    });
  };
export const updatePayment =
  (
    eventId,
    amount,
    invoice,
    invoice_url,
    way,
    type,
    eventUserId,
    paymentId,
    transactionId,
    isReservation,
    paymentNotes,
    dates,
    typeOrReferenceId = null
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const meta = JSON.parse(JSON.stringify(state.api.payments.meta));

    delete meta.accessToken;
    delete meta.method;
    delete meta.statisticData;
    delete meta.statistics;
    delete meta.totalRows;
    delete meta.url;

    if (way === "On Site") {
      way = "on_site";
    }
    if (way === "Credit Card") {
      way = "credit_card";
    }
    const data = {
      transactionId,
      amount,
      type,
      way,
      eventUserId,
      notes: paymentNotes,
    };

    if (dates) {
      data["transactionDate"] = dates.from + " " + dates.fromTime;
    }
    if (type === "product") {
      data["typeId"] = typeOrReferenceId;
    } else if (type === "sponsorship") {
      data["orgSponsorId"] = typeOrReferenceId;
    }
    return dispatch(
      api({
        endpoint: "payments",
        action: "update",
        params: { eventId, paymentId },
        body: {
          data,
        },
      })
    ).then(() => {
      if (isReservation) {
        return dispatch(getReservations(eventId));
      } else {
        dispatch(paymentGetStatistics(true));
        dispatch(getPayments(eventId, { ...meta, statistics: true })).then(
          () => {
            dispatch(paymentGetStatistics(false));
          }
        );
        return dispatch(getPayments(eventId, meta));
      }
    });
  };
