import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect, useDispatch, useSelector } from "react-redux";
import TableActions from "@layout/TableActions";
import RentData from "./RentData";
import SingleRentable from "./partials/SingleRentable";
import { loadRentables, deleteRentable } from "@actions/rentables";
import { listGroups } from "@actions/policyGroups";
import { changeTab } from "@actions/editmenu";
import { showModal } from "@actions";
import { Link } from "react-router-dom";

const RentablesList = ({ eventId, hasFakeChild }) => {
  const dispatch = useDispatch();

  const rentables = useSelector((state) => state?.api?.rentables?.list?.data);
  const rentablesReady = useSelector(
    (state) => state?.api?.rentables?.list?.ready
  );

  const [viewData, setViewData] = useState(false);
  const [rentableId, setRentableId] = useState(null);
  const [rentableName, setRentableName] = useState("");
  const [groupId, setGroupId] = useState(null);
  useEffect(() => {
    dispatch(loadRentables(eventId));
    dispatch(listGroups(eventId));
  }, [dispatch, eventId]);

  const goBack = () => {
    setViewData(false);
    dispatch(loadRentables(eventId));
  };

  const onRender = (col, item) => {
    switch (col.key) {
      case "availableQuantity":
        return `${item.availableQuantity} / ${item.quantity}`;
      case "rentableActions":
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <button
              className="btn transparent table-button"
              style={{ marginRight: 15 }}
              onClick={() => {
                setViewData(true);
                setRentableId(item.id);
                setRentableName(item.name);
                setGroupId(item.eventPolicyGroupId);
                dispatch(changeTab("RentablesList", "rentData"));
              }}
            >
              <span className="fa fa-list-ul" aria-hidden="true"></span>
              View Rents
            </button>
            <Link
              to={`/events/${item.eventId}/rentables/${item.id}/scanning`}
              className="btn table-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-scanning"></span>Scanning
            </Link>
          </div>
        );
      case "actions":
        return (
          <TableActions
            entity="session"
            item={item}
            onDelete={() => dispatch(deleteRentable(eventId, item.id))}
            onEdit={() =>
              dispatch(
                showModal(
                  <SingleRentable h2={"Edit"} eventId={eventId} item={item} />,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              )
            }
          />
        );
    }
  };

  if (viewData && hasFakeChild) {
    return (
      <RentData
        productName={rentableName}
        rentableGroupId={groupId}
        eventId={eventId}
        rentableId={rentableId}
        rentableName={rentableName}
        goBack={goBack}
      />
    );
  }

  return (
    <>
      <div className="tab-header">
        <div className="left"></div>
        <div className="right">
          <button
            type="button"
            className="btn create rounded"
            onClick={() =>
              dispatch(
                showModal(
                  <SingleRentable h2={"Create"} eventId={eventId} />,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              )
            }
          >
            Create
          </button>
        </div>
      </div>

      <div className="tab-content">
        <Table
          columns={{
            id: {
              name: "ID",
            },
            name: {
              name: "Name",
            },
            availableQuantity: {
              name: "Available / Total",
            },
            description: {
              name: "Description",
            },
            rentableActions: {
              name: "Actions",
            },
            actions: {
              name: "",
            },
          }}
          updating={!rentablesReady}
          items={rentables}
          onRender={onRender}
        />
      </div>
    </>
  );
};

RentablesList.propTypes = {
  eventId: PropTypes.number.isRequired,
  hasFakeChild: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.event.id,
    hasFakeChild: Boolean(state.editmenu.fakeChild),
  };
};

export default connect(mapStateToProps)(RentablesList);
