import React from "react";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@helpers";
import { connect } from "react-redux";
import EmailHooks from "./Hooks/partials/innerHook";
import TableActions from "@layout/TableActions";
import Switch from "@layout/Switch";
import { showModal, hideModal, addNotification } from "@actions";
import SingleContact from "./partials/SingleContact";

import Form from "@components/form";
import {
  getPresetEmails,
  deletePresetEmail,
  updatePresetEmailPublicStatus,
  updatePresetEmail,
} from "@actions/presetEmails";

// This component is called in the Hooks component
// Normally this component and hooks should be children of a parent component
// But the logic happens in the Hooks, if there is time it should be changed
class Emails extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.components = {
      EmailHooks,
    };
    this.state = {
      emailIsSelected: false,
      orgId: "",
    };
  }

  onRender(col, item) {
    switch (col.key) {
      case "options": {
        return (
          <span>
            <button
              className="btn transparent table-button"
              style={{ marginRight: 15 }}
              onClick={() =>
                this.setState({ emailIsSelected: true, emailId: item.id })
              }
            >
              <span className="fa fa-envelope-o" aria-hidden="true"></span>
              Email
            </button>
            <button
              className="btn transparent table-button-send"
              style={{ marginRight: 15 }}
            >
              <span className="fa fa-chevron-right" aria-hidden="true"></span>
              Send
            </button>
          </span>
        );
      }
      case "actions": {
        return (
          <TableActions
            entity="email"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <div className="form-container">
                  <h2> Edit {item.name} </h2>
                  <Form
                    componentName="email"
                    formName="edit"
                    onSubmit={(data) => {
                      this.props.updateEmail(
                        this.state.orgId,
                        item.id,
                        data.value
                      );
                    }}
                    value={{ name: item.name }}
                    onCancel={() => {
                      this.props.hideModal();
                    }}
                  />
                </div>
              )
            }
            onDelete={() => this.props.deleteEmail(this.state.orgId, item.id)}
          />
        );
      }
      case "public": {
        return (
          <Switch
            id={`staff-active-${item.id}`}
            isActive={col.value}
            onChange={(active) =>
              this.props.switchPublicStatus(this.state.orgId, item.id, active)
            }
          />
        );
      }
    }
  }

  getComponent(type, mode) {
    const Component = this.components[`${capitalizeFirstLetter(type)}Hooks`];
    const { presetList } = this.props;
    let emailSelected = null;
    // Because the Component expects an array of emails, i create a fake array
    // containing only one email which is the email the user selects
    Object.keys(presetList).forEach((key) => {
      if (presetList[key].id === this.state.emailId) {
        emailSelected = presetList[key];
      }
    });
    const emails = {
      data: [emailSelected],
    };
    return (
      <Component
        hook={emails.data[0]}
        mode={mode}
        emails={emails}
        vars={this.props.globalVars || []}
        isHook={false}
        orgId={this.state.orgId}
        {...this.props}
      />
    );
  }
  render() {
    return this.getComponent("email", "custom");
  }
}
Emails.propTypes = {
  loadPresetEmails: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  accessEvents: PropTypes.object.isRequired,
  presetList: PropTypes.any.isRequired,
  deleteEmail: PropTypes.func,
  switchPublicStatus: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  updateEmail: PropTypes.func,
  globalVars: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    globalVars: state.api.events.presetEmails.data,
    eventId: ownProps.event.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    contactModal: (
      orgId,
      hookId,
      emailId,
      emails,
      contactAction,
      contact = null,
      innerHook,
      isHook,
      type,
      eventId,
      mode
    ) => {
      dispatch(
        showModal(
          <SingleContact
            emails={emails}
            contactAction={contactAction}
            addNotification={dispatcher.addNotification}
            saveEmail={dispatcher.updateEmail}
            contact={contact}
            orgId={orgId}
            hookId={hookId}
            emailId={emailId}
            hideModal={dispatcher.hideModal}
            innerHook={innerHook}
            isHook={false}
            type={type}
            eventId={eventId}
            mode={mode}
          />
        )
      );
    },
    loadPresetEmails: (eventId) => {
      dispatch(getPresetEmails(eventId));
    },

    deleteEmail: (orgId, emailId) => {
      dispatch(deletePresetEmail(orgId, emailId));
    },
    switchPublicStatus: (orgId, emailId, isPublic) => {
      dispatch(updatePresetEmailPublicStatus(orgId, emailId, isPublic));
    },
    updateEmail: (orgId, emailId, data) => {
      dispatch(updatePresetEmail(orgId, emailId, data));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
