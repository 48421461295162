import React from "react";
import PropTypes from "prop-types";

const Password = (props) => {
  const [state, setState] = React.useState({
    type: props.type,
  });
  return (
    <React.Fragment>
      <input
        style={{ paddingRight: 55 }}
        type={state.type}
        className={props.className}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : ""}
        id={props.id}
        defaultValue={props.value}
        onChange={props.onChange}
        autoComplete="new-password"
      />
      <span className="icon icon-edit"></span>
      <span
        style={{
          position: "absolute",
          right: 35,
          top: 12,
          color: "#208edd",
          cursor: "pointer",
        }}
        onClick={() => {
          state.type === "text"
            ? setState({ type: "password" })
            : setState({ type: "text" });
        }}
      >
        {state.type === "text" ? (
          <i className="far fa-eye"></i>
        ) : (
          <i className="far fa-eye-slash"></i>
        )}
      </span>
    </React.Fragment>
  );
};

Password.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Password.defaultProps = {};

export default Password;
