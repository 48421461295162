/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Search from "@layout/Search";
import TableActions from "@layout/TableActions";
import { isEmpty } from "@helpers";
import ApiHelper from "@helpers/api-helper";
import moment from "moment";
import KMBLoader from "@layout/KMBLoader";
import Table from "@layout/Table";
import SingleSponsor from "./partials/SingleSponsor/SingleSponsor";
import { HeaderPagination } from "@layout/Pagination";
import Persons from "./partials/Persons";

export default class Sponsors extends React.Component {
  constructor(props) {
    super(props);
    ["onRender"].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.onRender = this.onRender.bind(this);
    this.excludedArea = null;
    this.toggleActions = this.toggleActions.bind(this);
    this.handleBody = this.handleBody.bind(this);
    this.state = {
      toggledCells: [],
      sponsorId: null,
      p: 1,
      rpp: -1,
      totalRows: 10,
      searchMeta: "",
    };
  }
  componentDidMount() {
    this.props.loadSponsors(this.props.orgId);
    document.body.addEventListener("click", this.handleBody);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      p: nextProps?.meta?.p,
      rpp: nextProps?.meta?.rpp,
      totalRows: nextProps?.meta?.totalRows,
      searchMeta: nextProps?.meta?.search,
    });
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBody);
  }
  handleBody(e) {
    if (
      this.state.toggledCells.length > 0 &&
      !this.excludedArea.contains(e.target)
    ) {
      this.setState({ toggledCells: [] });
    }
  }
  toggleActions(e, id) {
    this.excludedArea = e.target.parentNode;

    const toggledCells = [id];

    this.setState({ toggledCells });
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            {isEmpty(item.personThumbnail) ? (
              <span className="icon-users"></span>
            ) : (
              <span className="person-thumbnail">
                <img
                  src={
                    item.personThumbnail?.url?.length
                      ? item.personThumbnail.url
                      : "/images/medlive-noimage.jpg"
                  }
                  style={{ borderRadius: 4 }}
                  alt={`${item.firstName} ${item.lastName}`}
                />
              </span>
            )}
          </div>
        );
      }
      case "options": {
        return (
          <button
            className="btn transparent table-button"
            style={{ marginRight: 15 }}
            onClick={() =>
              this.props.page.navigate(
                `/administrator/sponsors/${item.id}/persons`
              )
            }
          >
            <span className="fa fa-user" aria-hidden="true"></span>
            Persons
          </button>
        );
      }

      case "sponsorImg": {
        return (
          <img
            src={
              item.sponsorThumbnail?.[0]?.url
                ? item.sponsorThumbnail?.[0]?.url
                : "/images/medlive-noimage.jpg"
            }
            style={{
              objectFit: "cover",
              width: 35,
              height: 35,
              borderRadius: 4,
              backgroundColor: "whitesmoke",
            }}
          />
        );
      }

      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "updatedAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }

      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SingleSponsor
                  value={item}
                  h2={"Edit"}
                  orgId={this.props.orgId}
                  itemId={item.id}
                />
              )
            }
            onDelete={() => this.props.deleteSponsor(this.props.orgId, item.id)}
          />
        );
      }
    }
  }
  render() {
    return (
      <div className="list-sponsors-main">
        <div className="top-bar">
          <div>
            <div className="right"></div>
          </div>
          <div>
            <div className="left">
              <Search
                onSearch={(search) => {
                  this.setState({ search });
                  this.props.loadSponsors(this.props.orgId, {
                    ...this.props.meta,
                    p: 1,
                    search,
                  });
                }}
                visible={true}
                timeout={400}
              />
            </div>
            <div className="right">
              <HeaderPagination
                fetching={this.props.fetching}
                p={this.props.p}
                rpp={this.props.rpp}
                totalRows={this.props.totalRows}
                onChangePage={(data) => {
                  this.props.loadSponsors(
                    this.props.orgId,
                    {
                      p: data.p,
                      rpp: data.rpp,
                      search: this.state.search || "",
                    },
                    false
                  );
                }}
              />
              <div className="create-crm-user">
                <button
                  className="btn create"
                  onClick={() =>
                    this.props.showModal(
                      <SingleSponsor h2={"chris"} orgId={this.props.orgId} />
                    )
                  }
                >
                  Create new Sponsor
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.fetching && (
          <KMBLoader rows={15} padding={24} height={53} />
        )}
        <div className="list-view">
          <div className="main-wrapper">
            <div className="med-table-container">
              <Table
                columns={{
                  sponsorImg: {
                    name: "",
                  },
                  id: {
                    name: "ID",
                  },
                  name: {
                    name: "Name",
                  },
                  country: {
                    name: "Country",
                  },
                  city: {
                    name: "City",
                  },
                  state: {
                    name: "State",
                  },
                  profession: {
                    name: "Profession",
                  },
                  address1: {
                    name: "Address 1",
                  },
                  address2: {
                    name: "Address 2",
                  },

                  vat: {
                    name: "VAT Number",
                  },
                  options: {
                    name: "Persons",
                  },

                  actions: {
                    name: "",
                  },
                }}
                onRender={this.onRender}
                items={this.props.sponsorList}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Sponsors.propTypes = {
  orgId: PropTypes.number.isRequired,
  loadSponsors: PropTypes.func.isRequired,
  sponsorList: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteSponsor: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  meta: PropTypes.object,
  rpp: PropTypes.number,
  p: PropTypes.number,
  totalRows: PropTypes.number,
};
