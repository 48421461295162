import * as React from "react";
const SvgPublicPolicy = (props) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.668 8.41a1.883 1.883 0 1 1 3.766 0 1.883 1.883 0 0 1-3.766 0Zm5.03.666a3.217 3.217 0 1 1 0-1.333H14c.368 0 .667.299.667.667v2.55a.667.667 0 0 1-1.333 0V9.075h-1.228v1.883a.667.667 0 1 1-1.333 0V9.076H7.698Z"
      fill="#1A2029"
    />
  </svg>
);
export default SvgPublicPolicy;
