import React from "react";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import { connect } from "react-redux";
import SponsorTypes from "./SponsorTypes";
import Sponsors from "./Sponsors";
import {
  showModal,
  updateClientPanelSponsorsOrder,
  updateClientPanelSettings,
  deleteClientPanelSponsor,
} from "@actions";

const SponsorsList = (props) => {
  const [state, setState] = React.useState({
    sponsorsSchema: props.event.clientPanelSettings.sponsors.sponsorsSchema,
    sponsorsTypes: props.event.clientPanelSettings.sponsors.sponsorsTypes,
    showSponsorNames: props.event.clientPanelSettings.sponsors.showSponsorNames,
    sponsorsUseOldSchemaIfExists:
      props.event.clientPanelSettings.sponsors.sponsorsUseOldSchemaIfExists,
  });

  const sortSponsors = (a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    return 0;
  };

  React.useEffect(() => {
    if (props.fetching) return;
    setState({
      sponsorsSchema: props.event.clientPanelSettings.sponsors.sponsorsSchema,
      sponsorsTypes: props.event.clientPanelSettings.sponsors.sponsorsTypes,
      showSponsorNames:
        props.event.clientPanelSettings.sponsors.showSponsorNames,
    });
  }, [props.fetching, props.event.clientPanelSettings.sponsors]);

  if (props.fetching) {
    return <KMBLoader rows={15} padding={24} height={53} />;
  }

  return (
    <div className="form-container sponsors-container">
      <SponsorTypes {...props} items={state.sponsorsTypes.sort(sortSponsors)} />

      <div className="sponsors-list">
        <div
          className="disabled"
          style={{
            display: state.disabled ? "flex" : "none",
          }}
        >
          <span>Save or Discard the changes above to enable editing</span>
        </div>
        {state.sponsorsTypes.map((type, index) => {
          return (
            <Sponsors
              key={`sponsors-${index}`}
              {...props}
              items={state.sponsorsSchema
                .filter((sponsor) => {
                  return sponsor.eventClientPanelSponsorTypeId == type.id;
                })
                .sort(sortSponsors)}
              sponsorsTypes={state.sponsorsTypes}
              createClientPanelSponsor={props.createClientPanelSponsor}
              updateClientPanelSettings={props.updateClientPanelSettings}
              updateClientPanelSponsorsOrder={
                props.updateClientPanelSponsorsOrder
              }
              index={index}
              type={type}
            />
          );
        })}
      </div>
    </div>
  );
};

SponsorsList.propTypes = {
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  changeTab: PropTypes.func,
  createClientPanelEbag: PropTypes.func,
  createClientPanelFooterSponsor: PropTypes.func,
  createClientPanelVideoSponsor: PropTypes.func,
  createClientPanelSponsor: PropTypes.func,
  updateClientPanelSponsorsOrder: PropTypes.func,
  updateClientPanelSettings: PropTypes.func,
  deleteClientPanelSponsor: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    showModal: (modal) => {
      return dispatch(showModal(modal));
    },
    deleteClientPanelSponsor: (eventId, data) => {
      return dispatch(deleteClientPanelSponsor(eventId, data));
    },
    updateClientPanelSponsorsOrder: (eventId, data) => {
      return dispatch(updateClientPanelSponsorsOrder(eventId, data));
    },
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsList);
