import { api } from "@actions/ajax";

export const getSendEmails =
  (eventId, meta = false) =>
  (dispatch) => {
    const request = {
      endpoint: "sentEmails",
      action: "list",
      params: { eventId },
    };
    const defaults = {
      rpp: 20,
      orderBy: "id",
      order: "DESC",
      p: 1,
      search: "",
    };
    const buildMeta = {};
    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });
    }
    if (meta === false) {
      Object.keys(defaults).forEach((key) => {
        buildMeta[key] = defaults[key];
      });
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }
    request.cache = false;

    dispatch(api(request));
  };

export const toggleViewedStatus = (eventId, emailId, viewed) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sentEmails",
      action: "update",
      params: { eventId, emailId },
      body: {
        data: {
          viewed: viewed,
        },
      },
    })
  );
