import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
export default function WarningBar(props) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        background: "var(--error-background)",
        border: "1px solid var(--error-color)",
        borderRadius: "8px",
        padding: "8px",
        alignItems: "center",
        marginBottom: "22px",
        gap: "12px",
      }}
    >
      <div>{props.message}</div>
      <Button variant="outlined" onClick={props.onClick}>
        {props.buttonText}
      </Button>
    </div>
  );
}
WarningBar.propTypes = {
  message: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};
