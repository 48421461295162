import React, { useEffect, useRef } from "react";
import {} from "@helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  hideModal,
  savePrintingSettings,
  enableNativePrinting,
  disableNativePrinting,
} from "@actions";
import { listGroups } from "@actions/policyGroups";
import KMBLoader from "@layout/KMBLoader";
import { useState } from "react";
import KMBDropzone from "@layout/KMBDropzone";
import Select from "@layout/Select2";
import Switch from "@layout/Switch";
import SubmitContainer from "@layout/SubmitContainer";
import ColorPicker from "material-ui-color-picker";
import {
  prepDragAndDrop,
  paperSizes,
  fontOptions,
  findFont,
} from "./DragAndDrop/dragNDropConfig.js";
import DragAndDrop from "./DragAndDrop/DragAndDrop";
import StayCurrentLandscapeIcon from "@material-ui/icons/StayCurrentLandscape";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import NumberInput from "@layout/NumberInput";
import { getTestCertificate } from "@actions/users";

const CertPrinting = (props) => {
  // LISTENER SETUP
  window.addEventListener(
    "afterprint",
    () => {
      onPrintFinished();
    },
    { once: true }
  );
  window.scriptCaller = () => {
    let loading = 0;
    const images = document.querySelectorAll(".whole-page-wrapper img");
    if (images.length === 0) {
      window.print();
    }
    images.forEach((element) => {
      if (element.complete) {
        loading++;
        if (images.length === loading) {
          loading = 0;
          window.print();
        }
      } else {
        element.onload = () => {
          loading++;
          if (images.length === loading) {
            loading = 0;
            window.print();
          }
        };
      }
    });
  };

  // CONFIGURATION
  const paperScreenBase = 350;
  const constantFactor_Y = useRef(0);
  const constantFactor_X = useRef(0);
  const { certificationPrintingSettings: incomingState } =
    props.params.printingSettings;

  // FUNCTION THAT INITIALIZES THE PROCESSED BOXES STATE
  const initializeProcessedBoxes = (processedState) => {
    const _ret = {
      id: {
        top: getTopValue("idStyle", 110),
        left: getLeftValue("idStyle", 0, processedState.centerId),
        title: "Participant ID",
      },
      name: {
        top: getTopValue("nameStyle", 182),
        left: getLeftValue("nameStyle", 0, processedState.centerName),
        title: "Participant Name",
      },
      cert_text: {
        top: getTopValue("certStyle", 246),
        left: getLeftValue("certStyle", 0, processedState.centerCertText),
        title: "Certification Text",
      },
    };

    return _ret;
  };

  // FUNCTION THAT INITIALIZES THE PROCESSED STATE
  const initializeProcessedState = () => {
    const ret = {
      fontFamily: findFont(incomingState.fontFamily),
      nameOrder: incomingState.nameOrder ?? "first",
      sameLine: incomingState.sameLine ?? true,
      fontColor: incomingState.fontColor ?? "#000000",
      selectedPaperSize: incomingState.paperSize ?? "A4",
      orientation: incomingState.orientation ?? "landscape",
      scale: incomingState.scale ?? 1,
      printBackground:
        incomingState.printBackground === "Yes" ||
        incomingState.printBackground === 1 ||
        incomingState.printBackground === true
          ? true
          : false,
      showName: incomingState.showName ?? true,
      showId: incomingState.showId ?? true,
      certText: incomingState.certText ?? "",
      showCertText: incomingState.showCertText ?? true,
      policyGroup: incomingState.certTextGroup ?? "",
      centerName: incomingState.centeredName ?? true,
      centerId: incomingState.centeredId ?? true,
      centerCertText: incomingState.centeredPoints ?? true,
      fontSizeName: parseInt(incomingState.nameStyle?.fontSize ?? 16),
      fontSizeId: parseInt(incomingState.idStyle?.fontSize ?? 16),
      fontSizeCertText: parseInt(incomingState.certStyle?.fontSize ?? 16),
      fileUpload:
        props.params.certificationImages.length > 0
          ? props.params.certificationImages[0].url
          : null,
    };
    return ret;
  };

  // get the aspect ratio of papersize
  const getAspectRatio = () => {
    let r = 1.4142; // this is a fallback to the A-paper sizes aspect ratio
    paperSizes.forEach((paper) => {
      if (paper.id === formValue.selectedPaperSize) {
        r = paper.y / paper.x;
      }
    });
    return r;
  };

  // PREVENT BOXES FROM BE PLACED OUTSIDE THE LIMITS
  const calibrateOffset = (offset, offsetFromLeft) => {
    const max = offsetFromLeft
      ? constantFactor_X.current
      : constantFactor_Y.current;
    return offset < 0 || offset >= max ? 0 : offset;
  };

  //RETURN THE TOP VALUE FOR ANY DND OBJECT
  const getTopValue = (property, def) => {
    const intValue = parseInt(incomingState[property]?.top);
    if (isNaN(intValue)) return calibrateOffset(def);
    return calibrateOffset((intValue * constantFactor_Y.current) / 100);
  };

  //RETURN THE LEFT VALUE FOR ANY DND OBJECT
  const getLeftValue = (property, def, centered) => {
    if (centered) return 0;
    const intValue = parseInt(incomingState[property]?.left);
    if (isNaN(intValue)) return calibrateOffset(def, true);
    return calibrateOffset((intValue * constantFactor_X.current) / 100, true);
  };

  // CREATE THE FONT OPTION FOR THE SELECT COMPONENT
  const parseFontOptions = () => {
    const parsedFonts = {};
    Object.keys(fontOptions).forEach((key) => {
      parsedFonts[
        key
      ] = `${fontOptions[key].font} (${fontOptions[key].subFamily})`;
    });
    return parsedFonts;
  };

  // CREATE THE PAPER OPTION FOR THE SELECT COMPONENT
  const parsePaperOptions = () => {
    const options = {};
    paperSizes.forEach((paperSize) => {
      if (
        paperSize.id === "A6" ||
        paperSize.id === "A7" ||
        paperSize.id === "A8"
      )
        return;
      options[paperSize.id] = paperSize.id;
    });
    return options;
  };

  // STATE MANAGEMENT
  const [certification, setCertification] = React.useState({
    settings: JSON.parse(props.certificationPrintingSettings),
    printing: false,
    user: null,
    userSettings: null,
  });
  const [loading, setLoading] = React.useState(true);
  const [policyGroups, setPolicyGroups] = React.useState({});
  const [formValue, setFormValue] = React.useState(initializeProcessedState());
  const [boxes, setBoxes] = useState(initializeProcessedBoxes(formValue));
  const colorRef = React.useRef(formValue.fontColor);
  const [imgSource, setImgSource] = React.useState(null);
  const [imageHeight, setImageHeight] = React.useState();
  const [imageWidth, setImageWidth] = React.useState();

  useEffect(() => {
    if (formValue.orientation === "landscape") {
      // the 350 will be the width
      constantFactor_X.current = paperScreenBase * getAspectRatio();
      constantFactor_Y.current = paperScreenBase;
    } else {
      // 350 will be the height
      constantFactor_X.current = paperScreenBase;
      constantFactor_Y.current = paperScreenBase * getAspectRatio();
    }
    setImageHeight(constantFactor_Y.current);
    setImageWidth(constantFactor_X.current);
  }, [formValue]);

  useEffect(() => {
    if (incomingState)
      setBoxes(initializeProcessedBoxes(initializeProcessedState()));
  }, [incomingState]);

  React.useEffect(() => {
    const options = {};
    props.listGroups(props.params.eventId).then((groups_DTO) => {
      if (groups_DTO.data.length > 0) {
        groups_DTO.data.map((group) => {
          options[group.id] = `${group.eventPolicyName} - ${group.name}`;
        });
      }
      setPolicyGroups(options);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    onChange("selectedPaperSize", formValue.selectedPaperSize);
  }, [formValue.scale]);

  const onChange = (key, value) => {
    const newFormValue = { ...formValue };
    newFormValue[key] = value;

    if (key === "fontColor") {
      colorRef.current = value;
    }

    if (key === "selectedPaperSize") {
      let newScale = 1;
      paperSizes.forEach((paperSize) => {
        if (paperSize.id === value) newScale = paperSize.scale;
      });
      newFormValue.scale = newScale;
    }

    if (key.includes("center")) {
      let parsedBoxKey = "";
      switch (key) {
        case "centerName": {
          parsedBoxKey = "name";
          break;
        }
        case "centerId": {
          parsedBoxKey = "id";
          break;
        }
        case "centerCertText": {
          parsedBoxKey = "cert_text";
          break;
        }
      }

      moveBox(
        parsedBoxKey,
        boxes[parsedBoxKey].left,
        boxes[parsedBoxKey].top,
        value
      );
    }
    setFormValue(newFormValue);
  };

  const centerBox = (boxKey) => {
    switch (boxKey) {
      case "name": {
        return formValue.centerName;
      }
      case "id": {
        return formValue.centerId;
      }
      case "cert_text": {
        return formValue.centerCertText;
      }
    }
    return "";
  };

  // PREPARE THE FRONT SCHEMA FOR SUBMISSION AND CONVERT IT ACCORDINGLY
  const parseSendingForm = () => {
    return {
      nameStyle: {
        left: `${(100 * boxes["name"].left) / imageWidth}%`,
        top: `${100 * (boxes["name"].top / imageHeight)}%`,
        fontSize: `${formValue.fontSizeName}px`,
      },
      idStyle: {
        left: `${(100 * boxes["id"].left) / imageWidth}%`,
        top: `${100 * (boxes["id"].top / imageHeight)}%`,
        fontSize: `${formValue.fontSizeId}px`,
      },
      certStyle: {
        left: `${(100 * boxes["cert_text"].left) / imageWidth}%`,
        top: `${100 * (boxes["cert_text"].top / imageHeight)}%`,
        fontSize: `${formValue.fontSizeCertText}px`,
      },
      certText: formValue.certText,
      nameOrder: formValue.nameOrder ?? "first",
      sameLine: formValue.sameLine ?? true,
      orientation: formValue.orientation,
      paperSize: formValue.selectedPaperSize,
      printBackground: formValue.printBackground ? "Yes" : "No",
      showName: formValue.showName ? 1 : 0,
      showId: formValue.showId ? 1 : 0,
      showCertText: formValue.showCertText ? 1 : 0,
      certTextGroup: formValue.policyGroup,
      centeredName: formValue.centerName ? 1 : 0,
      centeredId: formValue.centerId ? 1 : 0,
      centeredPoints: formValue.centerCertText ? 1 : 0,
      fontFamily: `${fontOptions[formValue.fontFamily].font}, ${
        fontOptions[formValue.fontFamily].subFamily
      }`,
      fontColor: formValue.fontColor ?? "#000000",
    };
  };

  const onSave = () => {
    props.savePrintingSettings(
      parseSendingForm(),
      "certificationPrintingSettings",
      props.params.eventId,
      (typeof formValue.fileUpload === "string"
        ? null
        : formValue.fileUpload) ?? [],
      undefined,
      "cert"
    );
  };

  const onCancel = () => {
    const processedState = initializeProcessedState();
    colorRef.current = processedState.fontColor;
    setFormValue(processedState);
    setBoxes(initializeProcessedBoxes(processedState));
  };

  const nativePrintCallback = (output) => {
    props.enableNativePrinting("cert", output.html, printFunction);
  };

  const printFunction = () => {
    window.scriptCaller();
  };

  const onPrintFinished = () => {
    setCertification(
      Object.assign({}, certification, {
        printing: false,
        user: null,
        userSettings: null,
      })
    );
    props.disableNativePrinting();
  };

  // CONFIGURE DRAG AND DROP
  const [moveBox, drop] = prepDragAndDrop(
    boxes,
    setBoxes,
    centerBox,
    formValue
  );

  return loading ? (
    <div className="container">
      <KMBLoader rows={15} padding={24} height={53} />
    </div>
  ) : (
    <div className="form-container cert-printing tab-content">
      <div className="card-editor-wrapper">
        <div className="card-drag-editor">
          <div className="printer-preview cert">
            <div className="preview-selector">
              <label>Preview printer result</label>
            </div>
            <div className="preview-test">
              <button
                type="submit"
                className="btn save rounded"
                onClick={() => {
                  props
                    .getTestCertificate(
                      props.params.eventId,
                      0,
                      0,
                      true,
                      10,
                      false
                    )
                    .then((output) => {
                      nativePrintCallback(output);
                    });
                }}
              >
                {"Test Print"}
              </button>
            </div>
          </div>
          <DragAndDrop
            centerBox={centerBox}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            boxes={boxes}
            formValue={formValue}
            drop={drop}
            imgSource={imgSource}
          />
          <div className="editor-row">
            <div className="form-wrapper">
              <div className="field-type type-select kmb-select ">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    marginBottom: "4px",
                  }}
                >
                  Upload Certificate Background
                </label>
                <KMBDropzone
                  styleType="simple"
                  id={"certificationImages"}
                  max={1}
                  value={props.params.certificationImages}
                  onFileDelete={() => {
                    props.savePrintingSettings(
                      {},
                      "certificationPrintingSettings",
                      props.params.eventId,
                      "delete",
                      props.params.certificationImages[0].id,
                      "cert"
                    );
                    onChange("fileUpload", null);
                  }}
                  onDropAccepted={(file, deletefile) => {
                    if (deletefile) {
                      setImgSource(null);
                      setFormValue({
                        ...{ formValue },
                        fileUpload:
                          props.params.certificationImages.length > 0
                            ? props.params.certificationImages[0].url
                            : null,
                      });
                    } else if (window.FileReader && file) {
                      const fr = new FileReader();
                      fr.onload = () => {
                        onChange("fileUpload", file);
                        setImgSource(fr.result);
                      };
                      fr.readAsDataURL(file[0]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-editor-tools">
          <div className="tools-wrapper ">
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <label style={{ fontSize: "16px", fontWeight: 700 }}>
                    Paper options
                  </label>
                  <div className="divider"></div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Print Background</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.printBackground}
                          onChange={(value) =>
                            onChange("printBackground", !!value)
                          }
                        />
                      </div>
                    </div>
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Orientation</label>
                      <div className="icons-wrapper">
                        <div className="icon-container">
                          <StayCurrentLandscapeIcon
                            onClick={() => onChange("orientation", "landscape")}
                            className={`${
                              formValue.orientation === "landscape"
                                ? "selected"
                                : ""
                            } icon-selector icon-landscape`}
                          />
                        </div>
                        <div className="icon-container">
                          <StayCurrentPortraitIcon
                            onClick={() => onChange("orientation", "portrait")}
                            className={`${
                              formValue.orientation === "portrait"
                                ? "selected"
                                : ""
                            } icon-selector icon-landscape`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Paper Size</label>
                      <Select
                        name="paperSize"
                        id="paperSize"
                        isClearable={false}
                        options={parsePaperOptions()}
                        value={formValue.selectedPaperSize}
                        onChange={(value) =>
                          onChange("selectedPaperSize", value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Show ID</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.showId}
                          onChange={(value) => onChange("showId", !!value)}
                        />
                      </div>
                    </div>
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.centerId}
                          onChange={(value) => onChange("centerId", !!value)}
                        />
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Font size (px)</label>
                      <NumberInput
                        max={72}
                        min={0}
                        value={formValue.fontSizeId}
                        onChange={(value) => {
                          onChange("fontSizeId", value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Show Name</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.showName}
                          onChange={(value) => onChange("showName", !!value)}
                        />
                      </div>
                    </div>
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.centerName}
                          onChange={(value) => onChange("centerName", !!value)}
                        />
                      </div>
                    </div>
                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Font size (px)</label>
                      <NumberInput
                        max={72}
                        min={0}
                        value={formValue.fontSizeName}
                        onChange={(value) => {
                          onChange("fontSizeName", value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Single Line</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          isActive={formValue.sameLine}
                          onChange={(value) =>
                            onChange("sameLine", Boolean(value))
                          }
                        />
                      </div>
                    </div>
                    <div className={`field-type`}>
                      <label>Name Order</label>
                      <Select
                        name="nameOrder"
                        id="nameOrder"
                        isClearable={false}
                        options={{
                          first: "FirstName LastName",
                          last: "LastName FirstName",
                        }}
                        value={formValue.nameOrder}
                        onChange={(value) => onChange("nameOrder", value)}
                      />
                    </div>
                    <div>{/* placeholder */}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group">
                <div className="form-wrapper">
                  <div className="merged-row">
                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Show Text</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.showCertText}
                          onChange={(value) =>
                            onChange("showCertText", !!value)
                          }
                        />
                      </div>
                    </div>

                    <div className="field-type type-switch kmb-switch clearfix ">
                      <label>Vertically center</label>
                      <div className="switch-holder">
                        <Switch
                          isLive={true}
                          id={`display-sponsor-types`}
                          isActive={formValue.centerCertText}
                          onChange={(value) =>
                            onChange("centerCertText", !!value)
                          }
                        />
                      </div>
                    </div>

                    <div className={`field-type type-text kmb-text-outter`}>
                      <label>Font size (px)</label>
                      <NumberInput
                        max={72}
                        min={0}
                        value={formValue.fontSizeCertText}
                        onChange={(value) => {
                          onChange("fontSizeCertText", value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="merged-row full-width-fields">
                    <div className="text-area-holder">
                      <label>Certification text</label>
                      <textarea
                        placeholder="Appears only for users of the selected group"
                        className="form-control"
                        value={formValue.certText}
                        onChange={(e) => onChange("certText", e.target.value)}
                      />
                      <label className="text-area-subtitle">
                        Please use the variable %POINTS% to add the user points
                        in the certification text.
                      </label>
                    </div>
                    <div className="field-type type-select kmb-select ">
                      <label>Policy group to show certification text</label>
                      <Select
                        options={policyGroups}
                        value={formValue.policyGroup}
                        onChange={(value) => onChange("policyGroup", value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor-row">
              <div className="form-group font-group">
                <div className="form-wrapper">
                  <label style={{ fontSize: "16px", fontWeight: 700 }}>
                    Font Options
                  </label>
                  <div className="divider"></div>
                  <div className="merged-row font-options">
                    <div
                      style={{
                        display: "flex",
                      }}
                      className={`field-type type-text`}
                    >
                      <label>Font Family</label>
                      <Select
                        className="flex-input"
                        isClearable={false}
                        options={parseFontOptions()}
                        value={formValue.fontFamily}
                        onChange={(value) => {
                          onChange("fontFamily", value);
                        }}
                      />
                    </div>
                    <div key={"color"} className="color-picker-container">
                      <label>Font Color</label>
                      <ColorPicker
                        name="color"
                        value={colorRef.current}
                        defaultValue={"This is how the text will look like"}
                        onChange={(color) => {
                          onChange("fontColor", color);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitContainer onCancel={onCancel} onSubmit={onSave} />
    </div>
  );
};

CertPrinting.propTypes = {
  params: PropTypes.object.isRequired,
  savePrintingSettings: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  listGroups: PropTypes.func,
  policyGroups: PropTypes.array,
  certificationImages: PropTypes.array,
  getTestCertificate: PropTypes.func,
  enableNativePrinting: PropTypes.func,
  disableNativePrinting: PropTypes.func,
  certificationPrintingSettings: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    policyGroups: state.api.policyGroups.list.data,
    certificationPrintingSettings:
      state.api.events.edit.data.extra.certificationPrintingSettings,
    params: {
      printingSettings: {
        certificationPrintingSettings: JSON.parse(
          state.api.events.edit.data.extra.certificationPrintingSettings
        ),
      },
      eventId: state.api.events.edit.data.id,
      certificationImages: state.api.events.edit.data.certificationImages,
    },
  };
};

export default connect(mapStateToProps, {
  savePrintingSettings,
  hideModal,
  listGroups,
  getTestCertificate,
  disableNativePrinting,
  enableNativePrinting,
})(CertPrinting);
