import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideModal } from "@actions";
import Form from "@components/form";
import { saveBankAccount } from "@actions/bankAccounts";
import formFields from "@helpers/form-fields";

class BankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.mode = props.bankAccount ? "update" : "create";

    this.state = {
      inputCount: 1,
      values: this.mode === "create" ? [""] : [this.props.bankAccount.name],
      error: "",
    };

    this.addInput = this.addInput.bind(this);
    this.removeInput = this.removeInput.bind(this);
    this.submit = this.submit.bind(this);
    this.update = this.update.bind(this);
  }
  componentDidMount() {
    if (Object.keys(this.props.bankAccount || {}).length) {
      formFields.forms.bankAccounts.single["password"].placeholder = "*******";
      formFields.forms.bankAccounts.single["password"].subtitle =
        "Leave empty if you wish to stay the same";
    }
  }

  submit() {
    const values = [...this.state.values].filter((v) => v !== "");
    const inputCount = values.length;
    if (inputCount === 0) {
      this.setState({
        values: [""],
        error: "Bank Account name is empty",
        inputCount: 1,
      });
      return;
    }
    this.setState(
      {
        values,
        inputCount,
        error: "",
      },
      () => this.props.saveBankAccount(this.props.orgId, values)
    );
  }

  update() {
    const value = this.state.values[0];
    if (!value) {
      this.setState({
        error: "Bank Account name is empty",
      });
      return;
    }
    this.props.saveBankAccount(
      this.props.orgId,
      { name: value },
      this.props.bankAccount.id
    );
  }

  removeInput(index) {
    if (index === 0) {
      return;
    }

    this.setState((prevState) => {
      return {
        inputCount: prevState.inputCount - 1,
        values: [...prevState.values].filter((v, i) => i !== index),
      };
    });
  }

  onChange(index, e) {
    const value = e.target.value;
    this.setState((prevState) => {
      return {
        values: [...prevState.values].map((v, i) => {
          if (i === index) {
            return value;
          }
          return v;
        }),
      };
    });
  }

  addInput() {
    this.setState((prevState) => {
      return {
        inputCount: prevState.inputCount + 1,
        values: [...prevState.values, ...[""]],
        error: "",
      };
    });
  }

  render() {
    return (
      <div className="form-container">
        <h2>
          {this.props.bankAccount ? "Edit Bank Account" : "Create Bank Account"}
        </h2>
        <Form
          componentName={"bankAccounts"}
          formName={"single"}
          value={this.props.bankAccount ? this.props.bankAccount : {}}
          onSubmit={(data) => {
            if (Object.keys(data.errors).length) {
              return false;
            }
            this.props.saveBankAccount(
              this.props.orgId,
              data.value,
              this.props?.bankAccount?.id
            );
          }}
          onCancel={this.props.hideModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.data.orgId,
  };
};

BankAccount.propTypes = {
  bankAccount: PropTypes.object,
  saveBankAccount: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, {
  hideModal,
  saveBankAccount,
})(BankAccount);
