/* eslint-disable */
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "@actions/appuser";
import { connect } from "react-redux";
import { useAuth } from "../../routers/partials/authProvider";
import Input from "@layout/Input";
import Logo from "../../icons/Logo";
function LoginPage(props) {
  const { authenticated, setAuthenticated } = useAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  if (authenticated) return <Navigate replace to="/" />;

  const handleLogin = (e) => {
    e.preventDefault();
    return props
      .login({ email, password })
      .then(({ meta }) => {
        window.localStorage.setItem("bluetree_admin_token", meta.accessToken);
        setAuthenticated(true);
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  };

  return (
    <div className="login-page">
      <div className="left">
        <img className="main-image" src="/images/bluetree-with-idcard.png" />
        <a href="https://bluetree-events.s3.eu-west-1.amazonaws.com/ALMA_WEB3EN.pdf" target="_blank">
        <img className="espa_logo" src="/images/e-bannersEUERDF730X90.webp" />
        </a>
        <img className="background-image" src="/images/login-background.png" />
        <div className="overlay-color"></div>
        <div className="text-wrapper">
          <Logo />
          <span>Event</span>
          <span>Management</span>
          <span>Platform</span>
        </div>
      </div>
      <div className="right" onSubmit={handleLogin}>
        <div>
          <h2>
            Welcome to <span>BlueTree</span>
          </h2>
          <p>
            BlueTree is a web platform made for event organizers, to help them
            manage their events and tasks.
          </p>
          <h3>Login to dashboard</h3>
          <form className="form-container">
            <div className="form-group">
              <label>Email</label>
              <Input
                placeholder="Enter your email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                required
                placeholder="********"
              />
            </div>
            <button type="button" className="btn transparent-light change-password"
              onClick={() => {navigate("/forgot-password")}}>
                Forgot password?
              </button>
            {errorMessage.length > 0 && (
              <span className="error">{errorMessage}</span>
            )}
            <button className="btn save" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
export default connect(null, { login })(LoginPage);
