/* eslint-disable */
import React, { createContext, useContext, useState } from "react";

export const authContext = createContext({
  authenticated: false,
  setAuthenticated: () => {},
});

export function ProvideAuth({ children }) {
  const [authenticated, setAuthenticated] = useState(
    window.localStorage.getItem("bluetree_admin_token")?.length > 0
  );
  return (
    <authContext.Provider value={{ authenticated, setAuthenticated }}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
