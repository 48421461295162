import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Switch from "@layout/Switch";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Iban from "@layout/Iban";
import Select2 from "@layout/Select2";
import Table from "@layout/Table";
import Checkbox from "@layout/Checkbox";
import SubmitContainer from "@layout/SubmitContainer";
import { addNotification } from "../../../../../actions";
import Input from "../../../../layout/Input";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& textarea": {
      width: "100%",
      resize: "vertical",
      minHeight: "180px",
    },
    "& .med-table-container": {
      marginBottom: 30,
      padding: 0,
    },
    "& .kmb-label,& .kmb-offline,& .kmb-online": {
      width: "33%",
      padding: 5,
      border: "none",
      borderBottom: "1px dashed #dae4f0",
    },
  },
  paper: {
    "& textarea": {
      width: "100%",
      resize: "vertical",
      minHeight: "120px",
    },
    "& .form-container": {
      flex: 1,
      position: "relative",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    border: "1px solid rgb(218, 228, 240)",
    minHeight: "100%",
    minWidth: 300,
    flex: 1,
    display: "flex",
    flexFlow: "column",
    padding: 5,
    marginBottom: 15,
    position: "relative",
    boxShadow: "none",
  },
  control: {
    padding: theme.spacing(2),
  },
  item: {
    flex: 1,
  },
  general: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    gap: "15px",
  },
  disabled: {
    position: "absolute",
    cursor: "not-allowed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(186,194,205,.14)",
    zIndex: 1,
  },
  submitContainer: {
    display: "flex",
    gap: "15px",
    justifyContent: "flex-end",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    padding: 10,
    height: 40,
    gap: "15px",
    "& i": {
      fontSize: 18,
      color: "#a7b7ce",
    },
    "& h4": {
      flex: 1,
      color: "var(--primary)",
      fontSize: 14,
    },
  },
}));

const Payments = (props) => {
  const [state, setState] = React.useState({
    bankActive: props.event.settings.billing.bank,
    cardActive: props.event.settings.billing.creditCard,
    paypalActive: props.event.settings.billing.paypal,
    iban: props.event.settings.billing.iban,
    bankInfo: props.event.settings.billing.bankInfo,
    cardBankAccountId: props.event.settings.billing.collaborator ?? null,
    paypalBankAccountId: props.event.settings.billing.paypalAccount ?? null,
    currencyId: props.event.currencyId,
    depositExpirationDays: props.event.settings.billing.depositExpirationDays,
    cancellationPolicy: props.event.settings.billing.cancellationPolicy,
    isIbanValid: true,
    supportedProductPayments: Object.entries(
      props.event.settings.billing.valuesOptions
    ).map(([productKey, productValue]) => {
      return {
        name: productKey,
        label: productValue,
        online:
          props.event.settings.billing.cardOptions.indexOf(productKey) !== -1,
        offline:
          props.event.settings.billing.bankOptions.indexOf(productKey) !== -1,
      };
    }),
  });

  const stateRef = React.useRef(state);

  React.useEffect(() => {
    stateRef.current = state;
  }, []);

  const handleIbanChange = (iban, isValid) => {
    setState({ ...state, iban, isIbanValid: isValid });
  };

  const cardBankAccounts = Object.entries(
    props.event.settings.billing.cardCollaboratorOptions
  )
    .filter((value) => {
      return !value[1].includes("paypal");
    })
    .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {});

  const paypalBankAccounts = Object.entries(
    props.event.settings.billing.cardCollaboratorOptions
  )
    .filter((value) => {
      return value[1].includes("paypal");
    })
    .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {});

  const classes = useStyles();

  const onRender = (col, item) => {
    switch (col.key) {
      case "label":
        return <span>{item.label}</span>;
      case "offline":
        return (
          <Checkbox
            disabled={!state.bankActive}
            id={`offline-support-${item.name}`}
            checked={item.offline}
            onChange={(e) => {
              const updatedProducts = state.supportedProductPayments.map(
                (product) =>
                  product.name === item.name
                    ? { ...product, offline: e.target.checked }
                    : product
              );
              setState({ ...state, supportedProductPayments: updatedProducts });
            }}
          />
        );
      case "online":
        return (
          <Checkbox
            disabled={!state.paypalActive && !state.cardActive}
            id={`online-support-${item.name}`}
            checked={item.online}
            onChange={(e) => {
              const updatedProducts = state.supportedProductPayments.map(
                (product) =>
                  product.name === item.name
                    ? { ...product, online: e.target.checked }
                    : product
              );
              setState({ ...state, supportedProductPayments: updatedProducts });
            }}
          />
        );
      default:
        return <React.Fragment />;
    }
  };
  return (
    <div className="tab-content form-container">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} className={classes.general}>
          <Grid className={classes.item} key={"general-settings-1"} item>
            <div className="form-container">
              <div className="item  form-group">
                <label>Currency (*)</label>
                <Select2
                  isClearable={false}
                  className="input"
                  options={{
                    1: `${props.event.currencyIso} / ${props.event.currencySymbol}`,
                  }}
                  placeholder={"Select"}
                  value={1}
                />
              </div>
            </div>
          </Grid>
          <Grid className={classes.item} key={"general-settings-2"} item>
            <div className="form-container">
              <div className="item  form-group">
                <label>Thousands Separator (*)</label>
                <Select2
                  className="input"
                  isClearable={false}
                  options={{
                    1: "None",
                  }}
                  placeholder={"Select"}
                  value={1}
                />
              </div>
            </div>
          </Grid>
          <Grid className={classes.item} key={"general-settings-2"} item>
            <div className="form-container">
              <div className="item  form-group">
                <label>Decimal Separator (*)</label>
                <Select2
                  isClearable={false}
                  className="input"
                  options={{
                    1: "Dot (.)",
                  }}
                  placeholder={"Select"}
                  value={1}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="form-container">
            <div className="item form-group">
              <label>Supported Payment Methods</label>
              <Grid container justify="center" spacing={2}>
                <Grid className={classes.item} key={"bank-transfer"} item>
                  <Paper className={classes.paper}>
                    <div className={classes.header}>
                      <i className="fas fa-university"></i>
                      <h4>Bank Transfer</h4>
                      <Switch
                        id={`bank-transfer-switch`}
                        isActive={state.bankActive}
                        isLive={true}
                        onChange={(active) => {
                          setState({ ...state, bankActive: active });
                        }}
                      />
                    </div>
                    <div className="form-container">
                      {state.bankActive === false && (
                        <div className={classes.disabled}></div>
                      )}

                      <div className="item ">
                        <label>IBAN (*)</label>
                        <Iban
                          value={state.iban}
                          isValidationActive={state.bankActive}
                          onChange={handleIbanChange}
                        />
                      </div>
                      <div className="item">
                        <label>Bank Info</label>
                        <textarea
                          className="form-control"
                          value={state.bankInfo}
                          placeholder={`e.g When transferring money through bank don't forget to add your participant-ID`}
                          onChange={(e) => {
                            setState({ ...state, bankInfo: e.target.value });
                          }}
                        />
                      </div>
                      <div className="item">
                        <label>Deposit Expiration (days)</label>
                        <Input
                          type="number"
                          className="form-control"
                          value={state.depositExpirationDays}
                          placeholder={`eg 10`}
                          onChange={(e) => {
                            setState({
                              ...state,
                              depositExpirationDays: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid className={classes.item} key={"credit-card"} item>
                  <Paper className={classes.paper}>
                    <div className={classes.header}>
                      <i className="fas fa-money-check"></i>
                      <h4>Card | e-Commerce</h4>
                      <Switch
                        id={`card-switch`}
                        isActive={state.cardActive}
                        isLive={true}
                        onChange={(active) => {
                          setState({ ...state, cardActive: active });
                        }}
                      />
                    </div>
                    <div className="form-container">
                      {state.cardActive === false && (
                        <div className={classes.disabled}></div>
                      )}
                      <div className="item">
                        <label>Bank Account</label>
                        <Select2
                          className="input "
                          options={cardBankAccounts}
                          onChange={(value) => {
                            setState({ ...state, cardBankAccountId: value });
                          }}
                          placeholder={"Select"}
                          value={state.cardBankAccountId}
                        />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid className={classes.item} key={"paypal"} item>
                  <Paper className={classes.paper}>
                    <div className={classes.header}>
                      <i className="fab fa-paypal"></i>
                      <h4>PayPal</h4>
                      <Switch
                        id={`paypal-switch`}
                        isActive={state.paypalActive}
                        isLive={true}
                        onChange={(active) => {
                          setState({ ...state, paypalActive: active });
                        }}
                      />
                    </div>
                    <div className="form-container">
                      {state.paypalActive === false && (
                        <div className={classes.disabled}></div>
                      )}
                      <div className="item">
                        <label>Paypal Account</label>
                        <Select2
                          className="input "
                          options={paypalBankAccounts}
                          onChange={(value) => {
                            setState({ ...state, paypalBankAccountId: value });
                          }}
                          placeholder={"Select"}
                          value={state.paypalBankAccountId}
                        />
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="form-container">
            <div className="item form-group">
              <label>Supported Payment Methods per Item</label>
              <Table
                columns={{
                  label: {
                    name: "Item",
                  },
                  offline: {
                    name: "Offline Payments",
                  },
                  online: {
                    name: "Online Payments",
                  },
                }}
                items={state.supportedProductPayments}
                containerClassName={`table-container  client-settings-table`}
                onRender={onRender}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="form-container">
            <div className="item form-group">
              <label>Cancellation Policy</label>
              <textarea
                className="form-control"
                value={state.cancellationPolicy}
                placeholder={`eg We do not support refunds after 48 hours of any transaction.`}
                onChange={(e) => {
                  setState({ ...state, cancellationPolicy: e.target.value });
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <SubmitContainer
        onSubmit={() => {
          const {
            bankActive,
            iban,
            paypalActive,
            paypalBankAccountId,
            cardActive,
            cardBankAccountId,
            isIbanValid,
          } = state;

          if (bankActive && (!iban || iban.trim() === "" || !isIbanValid)) {
            addNotification(
              "Error: Please provide a valid IBAN for Bank Transfer.",
              "error"
            );
            return;
          }

          if (cardActive && (!cardBankAccountId || cardBankAccountId === "")) {
            addNotification(
              "Error: Please provide a valid Card Bank Account.",
              "error"
            );
            return;
          }

          if (
            paypalActive &&
            (!paypalBankAccountId || paypalBankAccountId === "")
          ) {
            addNotification(
              "Error: Please provide a valid Paypal Account.",
              "error"
            );
            return;
          }

          const data = {
            bank: bankActive,
            creditCard: cardActive,
            paypal: paypalActive,
            iban: iban,
            bankInfo: state.bankInfo,
            collaborator: cardBankAccountId,
            paypalAccount: paypalBankAccountId,
            currencyId: state.currencyId,
            depositExpirationDays: state.depositExpirationDays,
            cancellationPolicy: state.cancellationPolicy,
            cardOptions: state.supportedProductPayments
              .filter((product) => product.online)
              .map((product) => product.name),
            bankOptions: state.supportedProductPayments
              .filter((product) => product.offline)
              .map((product) => product.name),
          };
          props.updateEvent(props.event.id, data);
        }}
        onCancel={() => {
          setState({ ...stateRef.current });
        }}
      />
    </div>
  );
};

Payments.propTypes = {
  updateEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

export default Payments;
