import { connect } from "react-redux";
import BulkImportSessions from "./BulkImportSessions";
import { hideModal, addNotification } from "@actions";
import { importSessions } from "@actions/sessions";
import { loadRooms } from "@actions/users";

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    rooms: state.rooms.data,
    sessionTypes: state.api.sessions.sessionTypes.data,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    importSessions: (
      eventId,
      activeFields,
      file,
      ignoreFirstRow,
      splitChar
    ) => {
      dispatch(
        importSessions(eventId, activeFields, file, ignoreFirstRow, splitChar)
      );
    },

    loadRooms: (eventId) => {
      dispatch(loadRooms(eventId));
    },

    hideModal: () => {
      dispatch(hideModal());
    },

    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkImportSessions);
