import React from "react";
import Select from "@layout/Select";
import KMBLoader from "@layout/KMBLoader";
import PropTypes from "prop-types";

export default class Printers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.printerType,
    };
  }

  componentDidMount() {
    this.props.setupPrinters();
  }

  render() {
    const { selectedPrinter, printers, ready, printerType } = this.props;
    const preparePrinters = {};

    if (!ready) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }

    printers.map((p) => {
      preparePrinters[p.name] = p.name;
    });

    return (
      <div className="printers tab-content  form-container">
        <div className="printer-type form-group">
          <label htmlFor="">Printer Type</label>
          <Select
            options={{
              dymo: "Dymo Printer",
              generic: "Generic Printer (Native)",
            }}
            onChange={(type) =>
              this.setState({ type }, () => this.props.changePrinterType(type))
            }
            value={this.state.type}
            placeholderInsideSelect={false}
          />
        </div>
        <div className="form-group">
          <div className="printer-box">
            {selectedPrinter === null && (
              <div className="conditional">
                <img
                  src="/images/printer-icon.png"
                  alt="no selected printer"
                  width="100"
                  height="100"
                />
                <p>No printer selected yet.</p>
              </div>
            )}
            {selectedPrinter !== null && (
              <div className="conditional">
                <img
                  src="/images/printer-icon-active.png"
                  alt="selected printer"
                  width="100"
                  height="100"
                />
                <p>{selectedPrinter}</p>
                {printerType !== "generic" && (
                  <div
                    className="remove-printer"
                    onClick={() => this.props.selectPrinter("")}
                  >
                    <span className="icon-close"></span>
                  </div>
                )}
              </div>
            )}
            {this.props.printers.length > 0 && printerType !== "generic" && (
              <div>
                <Select
                  options={preparePrinters}
                  placeholder={
                    selectedPrinter === null
                      ? "Select your printer"
                      : "Change your printer"
                  }
                  onChange={(printer) => this.props.selectPrinter(printer)}
                  value={selectedPrinter}
                  placeholderInsideSelect={false}
                  isDropDownButton={true}
                />
              </div>
            )}
            {this.props.printers.length === 0 && printerType !== "generic" && (
              <p>No dymo printers found on your system.</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Printers.propTypes = {
  setupPrinters: PropTypes.func.isRequired,
  selectedPrinter: PropTypes.any,
  printers: PropTypes.array.isRequired,
  ready: PropTypes.bool.isRequired,
  selectPrinter: PropTypes.func.isRequired,
  changePrinterType: PropTypes.func.isRequired,
  printerType: PropTypes.string.isRequired,
};
