import * as React from "react";
const SvgSwitch = (props) => (
  <svg
    width={20}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.333 1.167H6.667a5.833 5.833 0 0 0 0 11.667h6.666a5.833 5.833 0 0 0 0-11.667Z"
      stroke="#0098FD"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.667 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      stroke="#0098FD"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSwitch;
