import React from "react";
import PropTypes from "prop-types";
import ExhibitorPageHeader from "@layout/ExhibitorPageHeader";
import ExhibitorEditableItem from "@layout/ExhibitorEditableItem";
import SingleExhibitorEditComponent from "./SingleExhibitorEditComponent";
import SingleExhibitorEditHeader from "./SingleExhibitorEditHeader";
import KMBLoader from "@layout/KMBLoader";
import Form from "@components/form";
import ReactPlayer from "react-player";
import Confirm from "@layout/Confirm";
import { deferScript } from "@helpers";
import { api as configuration } from "@config/conf";
import Address from "@components/form/partials/Address";
import SubmitContainer from "@layout/SubmitContainer";

const SingleExhibitor = (props) => {
  const [state, setState] = React.useState({
    exhibitor: props.exhibitor,
    loading: true,
    page: {},
    deleteFile: {},
    showConfirm: false,
    googleMapsReady: false,
  });
  const setFormChangedState = (newState) => {
    props.formChanged();
    setState(newState);
  };
  React.useEffect(() => {
    props
      .loadExhibitorPage(props.event.id, props.exhibitor.id)
      .then(({ data }) => {
        if (typeof google === "undefined") {
          deferScript(
            `https://maps.googleapis.com/maps/api/js?key=${configuration.googleMapKey}&libraries=places`
          ).then(() =>
            setState({
              ...state,
              loading: false,
              page: { ...data.schema },
              bucket: {
                exhibitorsBucket: data.exhibitorsBucket,
              },
              googleMapsReady: true,
            })
          );
        } else {
          setState({
            ...state,
            loading: false,
            page: { ...data.schema },
            bucket: { exhibitorsBucket: data.exhibitorsBucket },
            googleMapsReady: true,
          });
        }
      });
  }, []);

  const clearFields = () => setState({ ...state, exhibitor: props.exhibitor });

  const onSave = () => {
    setState({ ...state, loading: true });

    props
      .updateExhibitorPage(props.event.id, props.exhibitor.id, {
        schema: JSON.stringify(state.page),
      })
      .then(({ data }) => {
        setState({
          ...state,
          bucket: { exhibitorsBucket: data.exhibitorsBucket },
          loading: false,
          page: { ...data.schema },
        });
      });
  };

  const onPublish = () => {
    setState({ ...state, loading: true });
    props
      .updateExhibitorPage(props.event.id, props.exhibitor.id, {
        schema: JSON.stringify(state.page),
        publishedSchema: JSON.stringify(state.page),
      })
      .then(({ data }) => {
        setState({
          ...state,
          bucket: { exhibitorsBucket: data.exhibitorsBucket },
          loading: false,
          page: { ...data.schema },
        });
      });
  };

  const onAddRow = (slots, index) => {
    const newState = { ...state };
    newState.page.main.splice(index + 1, 0, {
      rowHeight: 1,
      rowSlots: slots,
      components:
        slots == 1
          ? [{ type: "", content: {} }]
          : [
              { type: "", content: {} },
              { type: "", content: {} },
            ],
    });
    setFormChangedState(newState);
  };
  const onDelete = (index) => {
    const newState = { ...state };
    newState.page.main.splice(index, 1);
    setFormChangedState(newState);
  };
  const moveUp = (index) => {
    const newState = { ...state };
    const [item] = newState.page.main.splice(index, 1);
    newState.page.main.splice(index - 1, 0, item);
    setFormChangedState(newState);
  };
  const mirror = (index) => {
    const newState = { ...state };
    newState.page.main[index].components.push(
      newState.page.main[index].components.splice(0, 1)[0]
    );
    setFormChangedState(newState);
  };
  const changeRowHeight = (height, index) => {
    const newState = { ...state };
    newState.page.main[index].rowHeight = height;
    setFormChangedState(newState);
  };
  const moveDown = (index) => {
    const newState = { ...state };
    const [item] = newState.page.main.splice(index, 1);
    newState.page.main.splice(index + 1, 0, item);
    setFormChangedState(newState);
  };
  const onEditLogo = (type) => {
    props.showModal(
      <SingleExhibitorEditHeader
        bucket={state.bucket}
        header={state.page.header}
        type={type}
        onSave={(header) => {
          const newState = { ...state };
          state.page.header = header;
          setFormChangedState(newState);
          props.hideModal();
        }}
        onCancel={props.hideModal}
      />
    );
  };
  const onEdit = (item, index, slot) => {
    props.showModal(
      <SingleExhibitorEditComponent
        bucket={state.bucket}
        item={item || {}}
        onSave={(item) => {
          const newState = { ...state };
          state.page.main[index].components[slot] = item;
          setFormChangedState(newState);
          props.hideModal();
        }}
        onCancel={props.hideModal}
      />
    );
  };
  const getRow = (row, index) => {
    const isSingleSlot = row.rowSlots == 1;
    const component = (i) => (
      <div
        className={isSingleSlot ? "col-sm-12 component" : "col-sm-6 component"}
        key={`exhibitor-component-${index}-${i}`}
      >
        <ExhibitorEditableItem
          onEdit={() => onEdit(row.components[i], index, i)}
          showDelete={false}
          showAddRow={false}
        >
          {getItem(row.components[i], row)}
        </ExhibitorEditableItem>
      </div>
    );
    return (
      <div className="" key={`exhibitor-row-${index}`}>
        <ExhibitorEditableItem
          centerDeleteButton={true}
          index={index}
          showEdit={false}
          showDelete={false}
          disableDelete={index === state.page.main.length - 1 && index == 0}
          className={getRowHeight(row.rowHeight)}
          onAddRow={(slots) => onAddRow(slots, index)}
          onDelete={() => onDelete(index)}
          changeRowHeight={(height) => changeRowHeight(height, index)}
          selectedRowHeight={row.rowHeight}
          slotsLegth={row.components.length}
          moveUp={() => moveUp(index)}
          moveDown={() => moveDown(index)}
          mirror={() => mirror(index)}
          disableUp={index == 0}
          disableDown={index == state.page.main.length - 1}
        >
          {component(0)}
          {!isSingleSlot && component(1)}
        </ExhibitorEditableItem>
      </div>
    );
  };
  const getItem = (item, row) => {
    if (!item) return;
    switch (item.type) {
      case "image":
        return <img style={{ objectFit: "contain" }} src={item.content.url} />;
      case "file":
        return (
          <a
            rel="noopener noreferrer"
            style={{ textAlign: "center" }}
            target="_blank"
            href={item.content.url}
          >
            {item.content.text}
          </a>
        );
      case "html":
        return (
          <div dangerouslySetInnerHTML={{ __html: item.content.value }}></div>
        );
      case "video":
        return <ReactPlayer url={item.content.url} controls={true} />;
      case "map":
        return (
          <Address
            mapHeight={row.rowHeight == 1 ? 253 : 536}
            hasErrors={false}
            id={"exhibitor-map-component"}
            name={"exhibitor-map-component"}
            withMap={true}
            withName={false}
            withAddress={false}
            fullHeight={true}
            value={item.content.value}
          />
        );
      case "iframe":
        return (
          <iframe
            style={{ border: "none" }}
            width="100%"
            height="100%"
            src={item.content.url}
          ></iframe>
        );
      default:
        break;
    }
  };
  const getRowHeight = (rowHeight) => {
    if (rowHeight == 1) {
      return "row-height-1";
    } else if (rowHeight == 2) {
      return "row-height-2";
    }
  };

  if (state.loading) {
    return <KMBLoader rows={15} padding={24} height={53} />;
  }
  const deletefileBody = state.showConfirm ? (
    <div>
      <p>
        This action will permanently delete the file with ID:{" "}
        <strong>{state.deleteFile.id}</strong>
      </p>
      <p>
        <span>{state.deleteFile.fileName}</span>
      </p>
      <p>Are you sure that you want to do this?</p>
    </div>
  ) : null;

  return (
    <div className="single-exhibitor-page form-container">
      <div className="tools form-group">
        <button
          className="btn transparent table-header rounded"
          style={{ height: 38 }}
          onClick={() =>
            props.showModal(
              <div className="form-container single-speech">
                <h2>File Management</h2>
                <Form
                  componentName="exhibitors"
                  formName="bucket"
                  value={state.bucket}
                  onSubmit={(data) => {
                    props
                      .updateExhibitorBucket(
                        props.event.id,
                        props.exhibitor.id,
                        data.value
                      )
                      .then(() => {
                        props
                          .loadExhibitorPage(props.event.id, props.exhibitor.id)
                          .then(({ data }) => {
                            setState({
                              ...state,
                              bucket: {
                                exhibitorsBucket: data.exhibitorsBucket,
                              },
                              showConfirm: false,
                              deleteFile: {},
                            });
                          });
                      });
                  }}
                  onCancel={() => {
                    props.hideModal();
                  }}
                  submitText={"Upload"}
                  onChange={(data) => {
                    if (data.key === "exhibitorsBucket") {
                      setState({
                        ...state,
                        showConfirm: true,
                        deleteFile: data.value,
                      });
                    }
                  }}
                />
              </div>
            )
          }
        >
          <span
            style={{
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            <i className="fas fa-upload"></i>
            <span style={{ paddingLeft: 5 }}>File Management</span>
          </span>
        </button>
        <SubmitContainer
          onCancel={clearFields}
          onSubmit={onSave}
          onPublish={onPublish}
        />
      </div>
      <div className="form-group">
        <div className="row main-page-header">
          <ExhibitorPageHeader
            backgroundUrl={state.page.header.exhibitorPageBackground.url}
            logoUrl={state.page.header.exhibitorPageLogo.url}
            onEdit={(type) => onEditLogo(type)}
          />
        </div>
        {state.page.main.map((row, i) => {
          return getRow(row, i);
        })}
      </div>
      <Confirm
        onClose={() => setState({ ...state, showConfirm: false })}
        onConfirm={() => {
          props
            .updateExhibitorBucket(props.event.id, props.exhibitor.id, {
              exhibitorsBucket: [state.deleteFile.id],
            })
            .then(() => {
              props
                .loadExhibitorPage(props.event.id, props.exhibitor.id)
                .then((data) => {
                  setState({
                    ...state,
                    bucket: { exhibitorsBucket: data.data.exhibitorsBucket },
                    showConfirm: false,
                    deleteFile: {},
                  });
                });
            });
        }}
        body={deletefileBody}
        className="delete-event"
        confirmText="Confirm File Deletion"
        disabled={false}
        visible={state.showConfirm}
        title="File Delete Action! Are you sure?"
      ></Confirm>
    </div>
  );
};

SingleExhibitor.propTypes = {
  event: PropTypes.object.isRequired,
  exhibitor: PropTypes.object.isRequired,
  loadExhibitionDigitalSlots: PropTypes.func.isRequired,
  loadExhibitionCategories: PropTypes.func.isRequired,
  updateExhibitor: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  createExhibitor: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateExhibitorBucket: PropTypes.func.isRequired,
  formChanged: PropTypes.func,
  updateExhibitorPage: PropTypes.func,
  loadExhibitorPage: PropTypes.func,
};

export default SingleExhibitor;
