import React from "react";
import KMBLoader from "@layout/KMBLoader";
import moment from "moment";
import socketsHelper from "@helpers/sockets-helper";
import { isEmpty } from "@helpers";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import NoResults from "@layout/NoResults";

export default class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: props.statistics,
    };
    this.initSockets = this.initSockets.bind(this);
    this.socket = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      statistics: nextProps.statistics,
    });
  }

  UNSAFE_componentWillMount() {
    this.props.loadStatistics(this.props.eventId).then(() => {
      this.initSockets();
    });
  }

  componentWillUnmount() {
    socketsHelper.close();
  }

  initSockets() {
    socketsHelper
      .init(this.props.apiUrl, `events/${this.props.eventId}/`)
      .then((socket) => {
        socket.on("scans", (data) => {
          this.setState((prevState) => {
            return {
              statistics: Object.assign({}, this.state.statistics, {
                latestScans: [
                  ...[data.response.data],
                  ...this.state.statistics.latestScans,
                ].slice(0, -1),
                scans: prevState.statistics.scans + data.data.number,
                usersInRooms:
                  data.response.data.scanType === "enter"
                    ? prevState.statistics.usersInRooms + data.data.number
                    : prevState.statistics.usersInRooms - data.data.number,
                usersInRoom: Object.assign(
                  {},
                  this.state.statistics.usersInRoom,
                  {
                    [data.response.data.eventRoomId]: Object.assign(
                      {},
                      this.state.statistics.usersInRoom[
                        data.response.data.eventRoomId
                      ],
                      {
                        users:
                          data.response.data.scanType === "enter"
                            ? prevState.statistics.usersInRoom[
                                data.response.data.eventRoomId
                              ].users + data.data.number
                            : prevState.statistics.usersInRoom[
                                data.response.data.eventRoomId
                              ].users - data.data.number,
                      }
                    ),
                  }
                ),
              }),
            };
          });
        });

        socket.on("users", (data) => {
          this.setState((prevState) => {
            return {
              statistics: Object.assign({}, this.state.statistics, {
                usersRegistered:
                  prevState.statistics.usersRegistered + data.data.number,
              }),
            };
          });
        });

        socket.on("rooms", (data) => {
          this.setState((prevState) => {
            return {
              statistics: Object.assign({}, this.state.statistics, {
                rooms: prevState.statistics.rooms + data.data.number,
              }),
            };
          });
        });

        socket.on("allout", (data) => {
          this.setState((prevState) => {
            return {
              statistics: Object.assign({}, this.state.statistics, {
                scans: prevState.statistics.scans + data.data.number,
                latestScans: [
                  ...data.array,
                  ...this.state.statistics.latestScans,
                ].slice(0, 5),
              }),
            };
          });
        });
      })
      .catch((error) =>
        this.props.addNotification(`Sockets error: ${error}`, "error")
      );
  }

  render() {
    const statistics = this.state.statistics;

    if (isEmpty(statistics)) {
      return <KMBLoader rows={10} padding={24} height={53} />;
    }

    return (
      <div className="tab-content statistics form-container">
        <div className="stats form-group">
          <div className="box">
            <div className="inner-box">
              <div className="upper">
                <span className="icon-total-rooms"></span>
                <CountUp start={0} end={statistics.rooms} duration={0.5} />
              </div>
              <p>Total Rooms</p>
            </div>
          </div>
          <div className="box">
            <div className="inner-box">
              <div className="upper">
                <span className="icon-register-participants"></span>
                <CountUp
                  start={0}
                  end={statistics.usersRegistered}
                  duration={0.5}
                />
              </div>
              <p>Registered Participants</p>
            </div>
          </div>
          <div className="box">
            <div className="inner-box">
              <div className="upper">
                <span className="icon-criteria-search"></span>
                {statistics.scans}
              </div>
              <p>Total Scans</p>
            </div>
          </div>
          <div className="box">
            <div className="inner-box">
              <div className="upper">
                <span className="icon-visitors-onsite"></span>
                <CountUp
                  start={0}
                  end={statistics.usersOnSite}
                  duration={0.5}
                />
              </div>
              <p>Verified Participants</p>
            </div>
          </div>
          <div className="box">
            <div className="inner-box">
              <div className="upper">
                <span className="icon-register-participants"></span>
                <CountUp
                  start={0}
                  end={statistics.usersInRooms}
                  duration={0.5}
                />
              </div>
              <p>Participants In Rooms</p>
            </div>
          </div>
        </div>
        <div className="latest-scans data-table">
          <h3>Latest Scans</h3>
          <div className="med-table-container table-container ">
            {statistics.latestScans.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Room</th>
                    <th>Event</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {statistics.latestScans.map((scan, index) => {
                    return (
                      <tr key={`scan-${index}`}>
                        <td>{scan.id}</td>
                        <td>{`${scan.info.firstName} ${scan.info.lastName}`}</td>
                        <td>{scan.eventRoomName}</td>
                        <td className="scan-type">
                          <span className={scan.scanType}>{scan.scanType}</span>
                        </td>
                        <td>{moment(scan.scanDate.tz).format("HH:mm:ss")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {!statistics.latestScans.length && (
              <NoResults style={{ height: 200 }} />
            )}
          </div>
        </div>
        <div className="participants-in-rooms data-table">
          <h3>Participants In Rooms</h3>
          <div className="med-table-container table-container ">
            {Object.entries(statistics.usersInRoom).length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>Room Name</th>
                    <th>Active Participants</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(statistics.usersInRoom).map(
                    ([index, value]) => {
                      return (
                        <tr key={`room-${index}`}>
                          <td>{value.name}</td>
                          <td>
                            <CountUp
                              start={0}
                              end={value.users}
                              duration={0.5}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            )}
            {!statistics.latestScans.length && (
              <NoResults style={{ height: 200 }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  statistics: PropTypes.object,
  loadStatistics: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  addNotification: PropTypes.func.isRequired,
};
