import React from "react";
import Table from "@layout/Table";
import { connect } from "react-redux";
import { loadBankAccounts, deleteBankAccount } from "@actions/bankAccounts";
import { showModal, hideModal } from "@actions";
import PropTypes from "prop-types";
import TableActions from "@layout/TableActions";
import SingleBankAccount from "./SingleBankAccount";
import { HeaderPagination } from "@layout/Pagination";

class BankAccounts extends React.Component {
  constructor(props) {
    super(props);
    ["onRender"].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return <span className="icon-sessions"></span>;
      }

      case "actions": {
        return (
          <TableActions
            entity="bank account"
            item={item}
            onEdit={() =>
              this.props.showModal(<SingleBankAccount bankAccount={item} />)
            }
            onDelete={() =>
              this.props.deleteBankAccount(this.props.orgId, item.id)
            }
          />
        );
      }
    }
  }
  componentDidMount() {
    this.props.loadBankAccounts(this.props.orgId);
  }
  render() {
    const { bankAccounts, showModal } = this.props;
    return (
      <div className="list-bank-accounts-main">
        <div className="top-bar">
          <div>
            <div className="right"></div>
          </div>
          <div>
            <div className="right">
              <HeaderPagination
                fetching={this.props.fetching}
                p={this.props.meta.p}
                rpp={this.props.meta.rpp}
                totalRows={this.props.meta.totalRows}
                onChangePage={(data) => {
                  this.props.loadBankAccounts(
                    this.props.orgId,
                    {
                      p: data.p,
                      rpp: data.rpp,
                    },
                    false
                  );
                }}
              />
              <div className="create-bank-account">
                <button
                  className="btn create"
                  onClick={() => showModal(<SingleBankAccount />)}
                >
                  Create new Bank Account
                </button>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="list-view">
          <div className="main-wrapper">
            <Table
              id={"bank-accounts"}
              items={bankAccounts}
              columns={{
                id: {
                  name: "ID",
                },
                cosmetic: {
                  name: "",
                },
                merchantId: {
                  name: "merchantId",
                },
                collaborator: {
                  name: "collaborator",
                },
                acquirerId: {
                  name: "acquirerId",
                },
                posId: {
                  name: "posId",
                },
                actions: {
                  name: "",
                },
              }}
              onRender={this.onRender}
              updating={this.props.fetching}
              containerClassName={`table-container`}
            />
          </div>
        </div>
      </div>
    );
  }
}

BankAccounts.propTypes = {
  bankAccounts: PropTypes.array.isRequired,
  loadBankAccounts: PropTypes.func.isRequired,
  orgId: PropTypes.number,
  fetching: PropTypes.bool,
  meta: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteBankAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.data.orgId,
    bankAccounts: state.api.bankAccounts.data,
    meta: state.api.bankAccounts.meta,
    timestamp: state.api.bankAccounts.timestamp,
    fetching: state.api.bankAccounts.fetching,
  };
};

export default connect(mapStateToProps, {
  loadBankAccounts,
  showModal,
  hideModal,
  deleteBankAccount,
})(BankAccounts);
