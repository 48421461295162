const accessLevels = {
  organisation_admin: {
    editmenu: 1,
  },
  event_admin: {
    editmenu: 1,
  },
  event_secretary: {
    editmenu: 1,
  },
};

export default accessLevels;
