/* eslint-disable */
import React from "react";
import Banner from "@layout/Banner";
import { getEventRange } from "@helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEvent, deleteItem } from "@actions";
import ClientPanelSelect from "@layout/ClientPanelSelect";
import EventLoader from "@layout/EventLoader";
class EditHeader extends React.Component {
  constructor(props) {
    super(props);
    this.policiesMap = {};
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { event, fetching } = this.props;

    if (fetching || Object.keys(event).length === 0) {
      return <EventLoader />;
    }
    return (
      <div className="top-bar">
        <div className="left">
          <div className="banner">
            <Banner
              bannerUrl={event.banners.length > 0 ? event.banners[0].url : null}
              bannerAlt={event.name}
            />
          </div>
          <div className="title">
            <h1>{event.name}</h1>
          </div>
        </div>
        <div className="right">
          <div className="flags">
            <div>{`Event ID: ${event.id}`}</div>
            <div
              title={event.status}
              className={`status-indicator ${event.status}`}
            >
              {getEventRange(event.startDate.tz, event.endDate.tz)}
            </div>
          </div>
          <ClientPanelSelect
            eventFreeAccess={this.props.event.freeAccess}
            eventActive={this.props.event.active}
            policies={this.props.policies}
            eventURLAlias={this.props.event.urlAlias}
            controlBackground={"transparent"}
            color={"white"}
            hoverColor={"white"}
            border={"none"}
            placement={"right"}
            width={145}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { history } = state.page.router;
  return { history, policies: state.api.accesspolicies?.list?.data };
};

export default connect(mapStateToProps, { updateEvent, deleteItem })(
  EditHeader
);

EditHeader.propTypes = {
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  history: PropTypes.object,
  policies: PropTypes.array,
};
