import { connect } from "react-redux";
import Peak from "./Peak";

const mapStateToProps = (state) => {
  return {
    peak: state.api.events.statistics.data.topParticipation || {},
    fetching: state.api.events.statistics.fetching,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Peak);
