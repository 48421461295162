import React from "react";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import TableActions from "@layout/TableActions";
import ImageCover from "@layout/ImageCover";
import Checkbox from "@layout/Checkbox";
import { ChromePicker } from "react-color";
import IndependentTable from "@layout/IndependentTable";
import SingleCallToAction from "./SingleCallToAction";
import SingleHomeSponsor from "./SingleHomeSponsor";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";
import {
  updateClientPanelSettings,
  deleteClientPanelHomeCallToAction,
  createClientPanelHomeCallToAction,
  updateClientPanelHomeCallToActionsOrder,
  showModal,
  deleteClientPanelHomeSponsor,
  createClientPanelHomeSponsor,
  updateClientPanelHomeSponsorsOrder,
} from "@actions";

const sortOnIndex = (a, b) => {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
};

const Jumbotron = (props) => {
  const initialState = () => ({
    homeTitle: props.event.clientPanelSettings.home.homeTitle,
    homeSubtitle: props.event.clientPanelSettings.home.homeSubtitle,
    homeJumbotronAsBackground:
      props.event.clientPanelSettings.home.homeJumbotronAsBackground,
    homeJumbotronBackgroundColor:
      props.event.clientPanelSettings.home.homeJumbotronBackgroundColor,
    clientHomeJumbotron:
      props.event.clientPanelSettings.home.clientHomeJumbotron[0]?.url || "",
    clientHomeForegroundLogo:
      props.event.clientPanelSettings.home.clientHomeForegroundLogo[0]?.url ||
      "",
    homeCallToActions:
      props.event.clientPanelSettings.home.homeCallToActions.sort(sortOnIndex),
    homeSponsors:
      props.event.clientPanelSettings.home.homeSponsors.sort(sortOnIndex),
    selectingColor: null,
    backgroundMask: props.event.clientPanelSettings.home.colors.backgroundMask,
    text: props.event.clientPanelSettings.home.colors.text,
  });
  const [state, setState] = React.useState(initialState());
  const node = React.useRef([]);
  const jumbotron = React.useRef();
  const foreground = React.useRef();
  const stateRef = React.useRef(state);
  const setAllStates = (_state) => {
    stateRef.current = _state;
    setState(_state);
  };
  const colorOnClick = (type, e) => {
    if (
      node.current &&
      node.current.contains(e.target) &&
      stateRef.current.selectingColor
    ) {
      setAllStates({ ...stateRef.current, selectingColor: type });
      return;
    }
    setAllStates({ ...stateRef.current, selectingColor: type });

    const windowOnClick = (ev) => {
      if (
        node.current &&
        node.current.contains(ev.target) &&
        stateRef.current.selectingColor
      ) {
        return;
      }
      window.removeEventListener("mousedown", windowOnClick);
      setAllStates({ ...stateRef.current, selectingColor: null });
    };

    window.addEventListener("mousedown", windowOnClick);
  };

  if (props.fetching) {
    return <KMBLoader rows="10" padding={24} height={53} />;
  }

  return (
    <div className="main-content-container form-container">
      <div className="row">
        <div className="col-sm-6 col-xs-12 form-container">
          <div className="form-group">
            <label>Jumbotron</label>
            <div>
              <ImageCover
                height={"300px"}
                id={"clientHomeJumbotron"}
                imageUrl={state.clientHomeJumbotron}
                name="Jumbotron"
                onChange={(file, deletefile) => {
                  if (deletefile) {
                    setAllStates({
                      ...stateRef.current,
                      clientHomeJumbotron: "",
                    });
                    jumbotron.current = [
                      props.event.clientPanelSettings.home
                        .clientHomeJumbotron[0]?.id,
                    ];
                  } else if (window.FileReader && file) {
                    const fr = new FileReader();
                    fr.onload = () => {
                      jumbotron.current = [file];
                      setAllStates({
                        ...stateRef.current,
                        clientHomeJumbotron: fr.result,
                      });
                    };
                    fr.readAsDataURL(file);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ margin: "10px 0px" }} className="form-group">
            <Checkbox
              label="Use Jumbotron as Background"
              id={`use-as-background`}
              checked={state.homeJumbotronAsBackground}
              onChange={(e) =>
                setAllStates({
                  ...stateRef.current,
                  homeJumbotronAsBackground: e.target.checked,
                })
              }
            />
          </div>
          <div style={{ position: "relative" }}>
            <div
              className="form-disabled"
              style={{
                display: state.homeJumbotronAsBackground ? "none" : "block",
              }}
            ></div>

            <div style={{ position: "relative" }} className="form-group">
              <div
                className="color-picker"
                style={{
                  position: "relative",
                  height: "40px",
                  border: "1px solid #d5d9df",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  marginBottom: 10,
                  borderRadius: 4,
                }}
                ref={(elem) => (node.current = elem)}
              >
                <div
                  style={{
                    flex: 1,
                    height: 40,
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#333",
                    position: "relative",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                  onClick={(e) => colorOnClick("backgroundMask", e)}
                >
                  <p
                    style={{
                      margin: 0,
                      color:
                        state.selectingColor == "backgroundMask"
                          ? "#208edd"
                          : "inherit",
                    }}
                  >
                    Background Mask Color
                  </p>
                  <div
                    style={{
                      border: "1px solid #eee",
                      marginLeft: 5,
                      background: state.backgroundMask,
                      width: 15,
                      height: 15,
                      borderRadius: "100%",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    height: 25,
                    width: 1,
                    display: "inline-flex",
                    background: "#ccc",
                  }}
                ></div>
                <div
                  style={{
                    flex: 1,
                    height: 40,
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#333",
                    position: "relative",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                  onClick={(e) => colorOnClick("text", e)}
                >
                  <p
                    style={{
                      margin: 0,
                      color:
                        state.selectingColor == "text" ? "#208edd" : "inherit",
                    }}
                  >
                    Text Color
                  </p>
                  <div
                    style={{
                      border: "1px solid #eee",
                      marginLeft: 5,
                      background: state.text,
                      width: 15,
                      height: 15,
                      borderRadius: "100%",
                    }}
                  ></div>
                </div>

                {state.selectingColor && (
                  <div
                    style={{
                      position: "absolute",
                      top: 45,
                      right: 0,
                      zIndex: 10,
                    }}
                  >
                    <ChromePicker
                      color={state[state.selectingColor]}
                      onChangeComplete={(color) => {
                        setAllStates({
                          ...stateRef.current,
                          [stateRef.current
                            .selectingColor]: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                        });
                      }}
                      onChange={(color) => {
                        setAllStates({
                          ...stateRef.current,
                          [stateRef.current
                            .selectingColor]: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Foreground Logo</label>
              <div>
                <ImageCover
                  height={"295px"}
                  imageUrl={state.clientHomeForegroundLogo}
                  name="Foreground Logo"
                  onChange={(file, deletefile) => {
                    if (deletefile) {
                      setAllStates({
                        ...stateRef.current,
                        clientHomeForegroundLogo: "",
                      });
                      foreground.current = [
                        props.event.clientPanelSettings.home
                          .clientHomeForegroundLogo[0]?.id,
                      ];
                    } else if (window.FileReader && file) {
                      const fr = new FileReader();
                      fr.onload = () => {
                        foreground.current = [file];
                        setAllStates({
                          ...stateRef.current,
                          clientHomeForegroundLogo: fr.result,
                        });
                      };
                      fr.readAsDataURL(file);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xs-12 form-container">
          <div className="form-group">
            <label>Preview</label>
            <div style={{ minHeight: 300, wordBreak: "break-word" }}>
              <div
                style={{
                  height: "auto",
                  minHeight: state.homeJumbotronAsBackground ? "300px" : "auto",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: state.homeJumbotronAsBackground ? 20 : 0,
                }}
              >
                {state.clientHomeJumbotron != 0 && (
                  <React.Fragment>
                    <img
                      style={{
                        height: state.homeJumbotronAsBackground
                          ? "100%"
                          : "auto",
                        width: "100%",
                        position: state.homeJumbotronAsBackground
                          ? "absolute"
                          : "relative",
                        objectFit: state.homeJumbotronAsBackground
                          ? "cover"
                          : "contain",
                      }}
                      src={state.clientHomeJumbotron}
                    />
                  </React.Fragment>
                )}
                {state.homeJumbotronAsBackground != 0 && (
                  <React.Fragment>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        background: state.backgroundMask,
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <div
                      style={{
                        color: `${state.text}`,
                        position: "relative",
                        textAlign: "center",
                        height: "100%",
                        width: "90%",
                        maxWidth: 1024,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "column",
                      }}
                    >
                      {state.clientHomeForegroundLogo != 0 && (
                        <img
                          style={{
                            width: "100%",
                            maxHeight: "30%",
                            position: "relative",
                            objectFit: "contain",
                          }}
                          src={state.clientHomeForegroundLogo}
                        />
                      )}
                      <h3>{state.homeTitle}</h3>
                      <h5>{state.homeSubtitle}</h5>
                      {state.homeCallToActions.length > 0 && (
                        <div className="home-cta-container">
                          {state.homeCallToActions.map((cta, i) => {
                            return (
                              <div key={`cta-${i}`} className="home-cta">
                                {cta.name}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div
              className="form-disabled"
              style={{
                display: state.homeJumbotronAsBackground ? "none" : "block",
              }}
            ></div>
            <div className="form-group">
              <label>Title</label>
              <textarea
                maxLength={256}
                className="form-control"
                value={state.homeTitle}
                placeholder={`eg Welcome to ${props.event.name}`}
                onChange={(e) =>
                  setAllStates({
                    ...stateRef.current,
                    homeTitle: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>Subtitle</label>
              <textarea
                maxLength={256}
                className="form-control"
                placeholder={
                  props.event.location
                    ? `eg ${props.event.location}`
                    : `eg The location of the event`
                }
                value={state.homeSubtitle}
                onChange={(e) =>
                  setAllStates({
                    ...stateRef.current,
                    homeSubtitle: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <SubmitContainer
        onCancel={() => {
          setAllStates(initialState());
        }}
        onSubmit={() => {
          const data = {};
          data.homeTitle = state.homeTitle;
          data.homeSubtitle = state.homeSubtitle;
          data.homeJumbotronBackgroundColor = state.backgroundMask;
          data.homeJumbotronTextColor = state.text;
          data.homeJumbotronAsBackground = Boolean(
            state.homeJumbotronAsBackground
          );
          data.homePromoVideoUrl =
            props.event.clientPanelSettings.home.homePromoVideoUrl;
          data.homeShowKeySpeakers =
            props.event.clientPanelSettings.home.homeShowKeySpeakers;
          data.homeShowNote = props.event.clientPanelSettings.home.homeShowNote;
          data.homeShowPromoVideo =
            props.event.clientPanelSettings.home.homeShowPromoVideo;
          data.homeShowSponsors =
            props.event.clientPanelSettings.home.homeShowSponsors;
          data.homeShowSubscriptions =
            props.event.clientPanelSettings.home.homeShowSubscriptions;

          if (jumbotron.current) {
            data["clientHomeJumbotron"] = jumbotron.current;
          }

          if (foreground.current) {
            data["clientHomeForegroundLogo"] = foreground.current;
          }

          props
            .updateClientPanelSettings(props.event.id, data, "home")
            .then(() => {
              jumbotron.current = null;
              foreground.current = null;
            });
        }}
      />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 30,
          justifyContent: "center",
          position: "relative",
          overflow: "auto",
          maxWidth: "100%",
          paddingTop: 40,
        }}
      >
        <IndependentTable
          _key={1}
          onSortEnd={(newSortList) => {
            return props.updateClientPanelHomeCallToActionsOrder(
              props.event.id,
              {
                callToActions: newSortList,
              }
            );
          }}
          columns={{
            name: {
              name: "Text",
            },
            url: {
              name: "Url",
            },
            actions: {
              name: "",
            },
          }}
          items={props.event.clientPanelSettings.home.homeCallToActions.sort(
            sortOnIndex
          )}
          onRender={(col, item) => {
            switch (col.key) {
              case "actions": {
                return (
                  <React.Fragment>
                    <TableActions
                      entity="call to action"
                      item={item}
                      onDelete={() => {
                        return props.deleteClientPanelHomeCallToAction(
                          props.event.id,
                          item.id
                        );
                      }}
                      onEdit={() => {
                        return props.showModal(
                          <SingleCallToAction
                            item={item}
                            onSave={(data) => {
                              return props.updateClientPanelSettings(
                                props.event.id,
                                data,
                                "home-call-to-action",
                                item.id
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </React.Fragment>
                );
              }
            }
          }}
          tableName={"Call To Action"}
          onAdd={() =>
            props.showModal(
              <SingleCallToAction
                mode="create"
                onSave={(data) => {
                  return props.createClientPanelHomeCallToAction(
                    props.event.id,
                    {
                      ...data,
                      index: state.homeCallToActions.length,
                    }
                  );
                }}
              />
            )
          }
        />
        <IndependentTable
          _key={2}
          onSortEnd={(newSortList) => {
            return props.updateClientPanelHomeSponsorsOrder(props.event.id, {
              sponsors: newSortList,
            });
          }}
          columns={{
            clientHomeSponsorImage: {
              name: "image",
            },
            url: {
              name: "Url",
            },
            actions: {
              name: "",
            },
          }}
          items={props.event.clientPanelSettings.home.homeSponsors.sort(
            sortOnIndex
          )}
          onRender={(col, item) => {
            switch (col.key) {
              case "clientHomeSponsorImage": {
                return (
                  <img
                    src={
                      (item.clientHomeSponsorImage || [])[0]
                        ? item.clientHomeSponsorImage[0].url
                        : "/images/medlive-noimage.jpg"
                    }
                    style={{ objectFit: "contain", width: 50, height: 40 }}
                  />
                );
              }
              case "actions": {
                return (
                  <React.Fragment>
                    <TableActions
                      entity="sponsor"
                      item={item}
                      onDelete={() => {
                        return props.deleteClientPanelHomeSponsor(
                          props.event.id,
                          item.id
                        );
                      }}
                      onEdit={() => {
                        return props.showModal(
                          <SingleHomeSponsor
                            item={item}
                            options={props.sponsorsTypes}
                            onSave={(data) => {
                              return props.updateClientPanelSettings(
                                props.event.id,
                                data,
                                "home-sponsors",
                                item.id
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </React.Fragment>
                );
              }
            }
          }}
          tableName={"Sponsors"}
          onAdd={() =>
            props.showModal(
              <SingleHomeSponsor
                mode="create"
                onSave={(data) => {
                  return props.createClientPanelHomeSponsor(props.event.id, {
                    ...data,
                    index: state.homeSponsors.length,
                  });
                }}
              />
            )
          }
        />
      </div>
    </div>
  );
};

Jumbotron.propTypes = {
  event: PropTypes.object.isRequired,
  updateClientPanelSettings: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  updateClientPanelHomeCallToActionsOrder: PropTypes.func,
  deleteClientPanelHomeCallToAction: PropTypes.func,
  createClientPanelHomeCallToAction: PropTypes.func,
  updateClientPanelHomeSponsorsOrder: PropTypes.func,
  deleteClientPanelHomeSponsor: PropTypes.func,
  showModal: PropTypes.func,
  sponsorsTypes: PropTypes.array,
  createClientPanelHomeSponsor: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteClientPanelHomeCallToAction: (eventId, data) => {
      return dispatch(deleteClientPanelHomeCallToAction(eventId, data));
    },
    createClientPanelHomeCallToAction: (eventId, data) => {
      return dispatch(createClientPanelHomeCallToAction(eventId, data));
    },
    updateClientPanelHomeCallToActionsOrder: (eventId, data) => {
      return dispatch(updateClientPanelHomeCallToActionsOrder(eventId, data));
    },
    deleteClientPanelHomeSponsor: (eventId, data) => {
      return dispatch(deleteClientPanelHomeSponsor(eventId, data));
    },
    createClientPanelHomeSponsor: (eventId, data) => {
      return dispatch(createClientPanelHomeSponsor(eventId, data));
    },
    updateClientPanelHomeSponsorsOrder: (eventId, data) => {
      return dispatch(updateClientPanelHomeSponsorsOrder(eventId, data));
    },
    showModal: (content) => dispatch(showModal(content)),
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Jumbotron);
