import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
  return (
    <div className="breadcrumbs" style={props.style || {}}>
      {props.options.map((item, index) => {
        return (
          <span key={`bread-${index}`}>
            {item.link ? (
              <Link className="active" to={item.link}>
                {item.name}
              </Link>
            ) : (
              <div
                className={item.action ? "active" : ""}
                onClick={() => item.action?.()}
              >
                {item.name}
              </div>
            )}

            {index !== props.options.length - 1 && (
              <span className="arrow icon-arrow-right-small"></span>
            )}
          </span>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  options: PropTypes.array,
  style: PropTypes.object,
};

Breadcrumbs.defaultProps = {
  options: [],
};

export default Breadcrumbs;
