import React from "react";
import Form from "@components/form";
import formFields from "@helpers/form-fields";
import { isEmpty } from "@helpers";
import PropTypes from "prop-types";
import moment from "moment";
import ApiHelper from "@helpers/api-helper";
import Select2 from "@layout/Select2";
export default class SingleSpeech extends React.Component {
  constructor(props) {
    super(props);
    [
      "getMode",
      "calculateDefaultDateTime",
      "injectSpeakers",
      "injectFilters",
      "prepareSpeech",
      "toggleSearchMode",
      "getSelectedSpeakersId",
      "setExistingSpeech",
      "getSelectedAbstract",
      "getAbstractOptions",
    ].forEach((name) => (this[name] = this[name].bind(this)));

    this.mode = this.getMode();
    this.speakerOptionsRef = {};

    this.state = {
      showConfirm: false,
      file: null,
      author: null,
      selectedAbstractId: this.props.speech?.eventAbstractId,
      createMode: this.props.speech?.eventAbstractId ? "abstract" : "normal",
      abstract: this.getSelectedAbstract(),
      abstractsOptions: this.getAbstractOptions(),
      presenterOptions: {},
      speech: this.prepareSpeech(),
      searchMode: "Search In Participants",
    };
  }
  getMode() {
    return !Object.keys(this.props.speech || {}).length ? "create" : "update";
  }
  calculateDefaultDateTime() {
    const sessionStart = this.formatSessionDate(this.props.session.startDate);
    const sessionEnd = this.formatSessionDate(this.props.session.endDate);
    const isCreatingNewSpeech = !Object.keys(this.props.speech || {}).length;
    this.injectFormFields(sessionStart, sessionEnd, this.props.session);

    const {
      defaultStartDate,
      defaultStartTime,
      defaultEndDate,
      defaultEndTime,
    } = this.getDefaultDateTime(
      isCreatingNewSpeech,
      this.props.latestSpeechEndDate,
      this.props.speech
    );
    return {
      sessionStart,
      sessionEnd,
      defaultStartDate,
      defaultStartTime,
      defaultEndDate,
      defaultEndTime,
    };
  }
  toggleSearchMode = (filter) => {
    this.setState(
      {
        searchMode:
          filter === "Search In Speakers"
            ? "Search In Speakers"
            : "Search In Participants",
      },
      () => {
        this.injectSpeakers();
      }
    );
  };

  getLabel(user) {
    user.info ??= user;
    return `${user?.info.firstName} ${user.info.lastName}`;
  }

  getSpeakerOptions(speakers) {
    const speakerOptions = {};
    speakers?.forEach((user) => {
      speakerOptions[user.id] = this.getLabel(user);
    });
    return speakerOptions;
  }

  getDefaultDateTime(isCreatingNewSpeech, latestSpeechEndDate, speech) {
    const defaultSDateTime = isCreatingNewSpeech
      ? latestSpeechEndDate.tz
      : speech.startDate.tz;

    const defaultEDateTime = isCreatingNewSpeech
      ? latestSpeechEndDate.tz
      : speech.endDate.tz;

    const [defaultStartDate, defaultStartTime] = defaultSDateTime.split(" ");
    const [defaultEndDate, defaultEndTime] = defaultEDateTime.split(" ");
    return {
      defaultStartDate,
      defaultStartTime,
      defaultEndDate,
      defaultEndTime,
    };
  }

  formatSessionDate(sessionDate) {
    return sessionDate
      ? moment(sessionDate.tz).format("MMMM Do YYYY, HH:mm")
      : "Session date not set";
  }

  injectFormFields(sessionStart, sessionEnd, session) {
    const subtitle = `Session runs from ${sessionStart} to ${sessionEnd}`;
    const minDateTime = session.startDate?.tz;
    const maxDateTime = session.endDate?.tz;

    formFields.forms.sessions.singleSpeech.datetimeRange.subtitle = subtitle;
    formFields.forms.sessions.singleSpeech.datetimeRange.min = minDateTime;
    formFields.forms.sessions.singleSpeech.datetimeRange.max = maxDateTime;
    formFields.forms.sessions.abstractSpeech.datetimeRange.subtitle = subtitle;
    formFields.forms.sessions.abstractSpeech.datetimeRange.min = minDateTime;
    formFields.forms.sessions.abstractSpeech.datetimeRange.max = maxDateTime;
  }

  injectSpeakers() {
    this.speakerOptionsRef = {
      ...this.speakerOptionsRef,
      ...this.getSpeakerOptions(this.props.speech.speakers),
    };
    formFields.forms.sessions.singleSpeech["eventUsersIds"].options =
      this.speakerOptionsRef;

    formFields.forms.sessions.singleSpeech["eventUsersIds"].callback = (
      query
    ) => {
      if (!query?.length) {
        formFields.forms.sessions.singleSpeech["eventUsersIds"].options =
          this.speakerOptionsRef;
        this.setState({});
        return;
      }

      this.props
        .searchUsers(
          query,
          this.props.eventId,
          this.props.policyIds,
          this.state.searchMode != "Search In Participants"
        )
        .then((response) => {
          formFields.forms.sessions.singleSpeech["eventUsersIds"].options = {
            ...this.speakerOptionsRef,
            ...this.getSpeakerOptions(response.data),
          };
          this.setState({});
        })
        .catch((err) => console.error("Error fetching speakers:", err));
    };
  }

  injectPresenter() {
    if (
      this.props.speech.speakers?.length &&
      this.props.speech.eventAbstractId
    ) {
      const presenterOptions = {
        "-1": "Select",
      };

      this.props.speech.speakers.forEach(
        ({ id, firstName, lastName, email }) => {
          presenterOptions[id] = `${firstName} ${lastName}, ${email}`;
        }
      );

      formFields.forms.sessions.abstractSpeech["presenter"].options =
        presenterOptions;

      formFields.forms.sessions.abstractSpeech["presenter"].value = [
        this.props.speech.speakers[0].id,
      ];

      return presenterOptions;
    }

    formFields.forms.sessions.abstractSpeech["presenter"].callback = (
      query
    ) => {
      if (!query?.length) return;
      this.props
        .searchUsers(query, this.props.eventId, this.props.policyIds)
        .then((d) => {
          const presenterOptions = {};
          d.data.map((au) => {
            presenterOptions[
              au.id
            ] = `${au.info.firstName} ${au.info.lastName}, ${au.info.email}`;
          });
          presenterOptions[-1] = "Select";
          formFields.forms.sessions.abstractSpeech["presenter"].options =
            presenterOptions;
          this.setState({ presenterOptions });
        });
    };
  }
  injectFilters() {
    formFields.forms.sessions.singleSpeech["eventUsersIds"].filters = {
      callback: (filter) => this.toggleSearchMode(filter),
      options: ["Search In Participants", "Search In Speakers"],
    };
  }

  getAbstractOptions(abstracts = this.props.abstracts) {
    const abstractsOptions = { None: null };
    [...abstracts].map((abstract) => {
      abstractsOptions[abstract.id] = `${abstract.id} ${abstract.name}`;
    });
    return abstractsOptions;
  }

  getSelectedAbstract(abstractId = this.props.speech?.eventAbstractId) {
    return (
      this.props.abstracts.filter((abstract) => abstract.id == abstractId)[0] ||
      {}
    );
  }

  setAbstractPresenter(abstract) {
    const presenterOptions = {
      "-1": "Select",
      [`${abstract.eventUserId}`]: `${abstract.eventUserFirstName} ${abstract.eventUserLastName}, ${abstract.eventUserEmail}`,
    };
    formFields.forms.sessions.abstractSpeech["presenter"].value = [
      abstract.eventUserId,
    ];
    formFields.forms.sessions.abstractSpeech["presenter"].options =
      presenterOptions;

    return presenterOptions;
  }

  setSelectedAbstract(abstractId) {
    const abstract = this.getSelectedAbstract(abstractId);
    const presenterOptions = this.setAbstractPresenter(abstract);
    const title = `${abstract.publishedId} - ${abstract.name}`;
    this.setState((prevState) => ({
      abstract,
      presenterOptions,
      speech: {
        ...prevState.speech,
        name: title,
      },
    }));
  }
  getDateAndTimeFromDateTime(dateTime = "") {
    const [date = "", time = ""] = dateTime.split(" ");
    return [date, time?.slice(0, -3)];
  }
  setNewSpeech() {
    const [startDate, startDateTime] = this.getDateAndTimeFromDateTime(
      this.props.newSpeech?.startDate?.tz
    );
    const [endDate, endDateTime] = this.getDateAndTimeFromDateTime(
      this.props.newSpeech?.endDate?.tz
    );
    return {
      ...this.props.speech,
      startDate,
      startDateTime,
      endDate,
      endDateTime,
    };
  }

  setExistingSpeech() {
    const [startDate, startDateTime] = this.getDateAndTimeFromDateTime(
      this.props.speech.startDate?.tz
    );
    const [endDate, endDateTime] = this.getDateAndTimeFromDateTime(
      this.props.speech.endDate?.tz
    );
    const eventUsersIds = this.props.speech.speakers.map(({ id }) => id);

    return {
      ...this.props.speech,
      startDate,
      startDateTime,
      endDate,
      endDateTime,
      eventUsersIds,
    };
  }

  getSelectedSpeakersId() {
    return this.props.speech?.map(({ id }) => id) || [];
  }

  prepareSpeech() {
    this.injectSpeakers();
    this.injectPresenter();
    this.injectFilters();
    const {
      defaultStartDate,
      defaultStartTime,
      defaultEndDate,
      defaultEndTime,
    } = this.calculateDefaultDateTime();
    const preparedSpeech =
      this.mode === "create" ? this.setNewSpeech() : this.setExistingSpeech();
    return {
      ...preparedSpeech,
      name: this.props.speech?.name || "",
      startDate: preparedSpeech.startDate || defaultStartDate,
      startDateTime: preparedSpeech.startDateTime || defaultStartTime,
      endDate: preparedSpeech.endDate || defaultEndDate,
      endDateTime: preparedSpeech.endDateTime || defaultEndTime,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSpeech = this.prepareSpeech(nextProps.speech);
    newSpeech.name = this.state.speech.name;
    newSpeech.startDate = this.state.speech.startDate;
    newSpeech.startDateTime = this.state.speech.startDateTime;
    newSpeech.endDate = this.state.speech.endDate;
    newSpeech.endDateTime = this.state.speech.endDateTime;
    const newState = {
      showConfirm: false,
      file: null,
      speech: newSpeech,
    };
    if (nextProps.abstracts.length !== this.props.abstracts.length) {
      newState.abstract = this.getSelectedAbstract(
        nextProps.speech.eventAbstractId
      );
      newState.abstractsOptions = this.getAbstractOptions(nextProps.abstracts);
    }
    this.setState(newState);
  }

  convertDatesToStartEnd(form) {
    form.startDate = moment(
      `${form.datetimeRange.from} ${form.datetimeRange.fromTime}`,
      "DD-MM-Y HH:mm"
    ).format(ApiHelper.dateFormat);
    form.endDate = moment(
      `${form.datetimeRange.to} ${form.datetimeRange.toTime}`,
      "DD-MM-Y HH:mm"
    ).format(ApiHelper.dateFormat);
    delete form.datetimeRange;
  }

  clearForm() {
    formFields.forms.sessions.abstractSpeech["presenter"].options = {};
    formFields.forms.sessions.abstractSpeech["presenter"].value = [];
    formFields.forms.sessions.abstractSpeech["presenter"].callback = () => {};
  }

  onSubmit(data) {
    if (!isEmpty(data.errors)) return;
    const form = { ...data.value };
    this.convertDatesToStartEnd(form);

    if (form.speechFiles.length === 0) delete form.speechFiles;
    form.eventAbstractId = this.state.abstract.id;
    if (form.presenter) {
      form.eventUsersIds = form.presenter;
      if (!(form.presenter instanceof Array))
        form.eventUsersIds = [form.presenter];
      delete form.presenter;
    }
    if (!form.eventUsersIds || form.eventUsersIds?.[0] == "-1")
      form.eventUsersIds = [];
    if (this.state.createMode === "abstract") {
      form.eventAbstractId = this.state.abstract.id;
    } else {
      delete form.eventAbstractId;
    }
    this.props
      .saveSpeech(
        this.props.eventId,
        this.props.sessionId,
        form,
        this.mode === "create" ? false : this.props.speech.id,
        data.type,
        null,
        null,
        this.props.meta
      )
      .then(() => {
        this.clearForm();
        this.setState({ presenterOptions: {} });
      })
      .catch((err) => console.warn(err));
  }

  render() {
    const isAbstractSpeech = this.state.createMode === "abstract";
    //case create speech from calendar without selecting session
    if (!Object.keys(this.props.session).length) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <h4 className="modal-title">
            You cannot create a Speech outside of a Session
          </h4>
          <button className="btn cancel" style={{ marginTop: 50 }}>
            OK
          </button>
        </div>
      );
    }
    return (
      <div className="form-container single-speech">
        <h2>
          {this.mode === "create" || this.props.newSpeech
            ? "Add New Speech"
            : `Edit "${this.props.speech.name}"`}
        </h2>
        <div className="boolean-buttons">
          <button
            className={`btn${!isAbstractSpeech ? " selected" : ""}`}
            type="button"
            onClick={() =>
              this.setState({
                createMode: "normal",
                selectedAbstractId: null,
              })
            }
          >
            Normal Speech
          </button>
          <button
            className={`btn${isAbstractSpeech ? " selected" : ""}`}
            type="button"
            onClick={() =>
              this.setState({
                createMode: "abstract",
                selectedAbstractId: this.props.speech?.eventAbstractId || null,
              })
            }
          >
            Abstract Speech
          </button>
        </div>
        <br />
        <br />
        {!isAbstractSpeech ? (
          <Form
            componentName="sessions"
            formName="singleSpeech"
            onSubmit={(data) => {
              this.onSubmit(data);
            }}
            value={this.state.speech}
            onCancel={() => {
              this.props.hideModal();
            }}
            onChange={(data, meta) => {
              if (
                data.key === "speechFiles" ||
                data.key === "videoSpeechThumbnail"
              ) {
                this.props.saveSpeech(
                  this.props.eventId,
                  this.props.sessionId,
                  { [data.key]: [data.value.id] },
                  this.props.speech.id,
                  "json",
                  true,
                  this.props.meta
                );
              }
              if (data.key === "eventUsersIds") {
                this.speakerOptionsRef = {
                  ...this.speakerOptionsRef,
                  ...(meta.selectedOption
                    ? {
                        [meta.selectedOption.value]: meta.selectedOption.label,
                      }
                    : {}),
                };
              }
            }}
          />
        ) : (
          <>
            <label>Abstract</label>
            <Select2
              options={this.state.abstractsOptions}
              onChange={(value) => this.setSelectedAbstract(value)}
              value={this.state.abstract.id}
            />
            <br />
            {Object.keys(this.state.abstract || {}).length > 0 && (
              <div
                style={{
                  padding: 10,
                  background: "#f5f5f5",
                  color: "#4e5b70",
                }}
              >
                <p>Abstract Id: {this.state.abstract.id}</p>
                <p>Abstract Publish Id: {this.state.abstract.publishedId}</p>
                <p>Abstract Title: {this.state.abstract.name}</p>
                <p>Type: {this.state.abstract.type}</p>
                <p>
                  Topics:{" "}
                  {this.state.abstract.topics
                    ?.map((topic) => topic.name)
                    .join(", ")}
                </p>
                <p>
                  Authors:{" "}
                  {this.state.abstract.authors
                    ?.map((author) => `${author.firstName} ${author.lastName}`)
                    .join(", ")}
                </p>
                <p>
                  User: {this.state.abstract.eventUserId}{" "}
                  {this.state.abstract.eventUserFirstName}{" "}
                  {this.state.abstract.eventUserLastName}
                </p>
              </div>
            )}

            <br />
            <Form
              componentName="sessions"
              formName="abstractSpeech"
              onSubmit={(data) => {
                this.onSubmit(data);
              }}
              value={this.state.speech}
              onCancel={() => {
                this.props.hideModal();
              }}
              onChange={(data) => {
                if (
                  data.key === "speechFiles" ||
                  data.key === "videoSpeechThumbnail"
                ) {
                  this.props.saveSpeech(
                    this.props.eventId,
                    this.props.sessionId,
                    { [data.key]: [data.value.id] },
                    this.props.speech.id,
                    "json",
                    true,
                    this.props.meta
                  );
                }
              }}
            />
          </>
        )}
      </div>
    );
  }
}

SingleSpeech.propTypes = {
  newSpeech: PropTypes.object,
  speech: PropTypes.object,
  meta: PropTypes.object,
  speakers: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  savePerson: PropTypes.func.isRequired,
  organisationId: PropTypes.number.isRequired,
  saveSpeech: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  mode: PropTypes.string,
  crmUsers: PropTypes.array,
  allPolicyUsers: PropTypes.array,
  getAbstracts: PropTypes.func,
  searchUsers: PropTypes.func,
  abstracts: PropTypes.array,
  policyIds: PropTypes.array,
  latestSpeechEndDate: PropTypes.object,
};

SingleSpeech.defaultProps = {
  speech: {},
  session: {},
  speakers: [],
  hideModal: () => {},
  savePerson: () => {},
  abstracts: {},
  meta: {},
};
