import React from "react";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import { connect } from "react-redux";
import { addNotification } from "@actions";
import Breadcrumbs from "@layout/Breadcrumbs";

import {
  getPresetEmails,
  deleteContact,
  updatePresetEmail,
  createPresetEmail,
  createCustomEmail,
} from "@actions/presetEmails";

import Email from "@layout/Email";
import { replaceSpanWithDataOriginalVar } from "../../../../../../../helpers";
const sort = {
  recipient: 0,
  organisation: 1,
  event: 2,
  abstract_schema: 3,
  links: 4,
  action: 5,
};
class EmailHooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: this.props.emails,
      ready: false,
    };
    this.addContact = this.addContact.bind(this);
  }

  componentDidMount() {
    this.props.getHookEmails(this.props.event.id, this.props.hook.id);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.ready !== !nextProps.innerHookFetching) {
      this.setState({
        emails: nextProps.emails,
        ready: !nextProps.innerHookFetching,
      });
    }
  }
  addContact(e) {
    return this.props.contactModal(
      this.props.eventId,
      this.props.hook.id,
      e.id,
      e,
      "createContact",
      {},
      null,
      this.props.innerHook,
      this.props.policyGroups,
      (eventId, hookId, emailId, action, email) => {
        this.setState(
          {
            emails: [
              ...this.state.emails.map((em) => {
                if (emailId == em.id) {
                  return { ...em, contacts: email.data.contacts };
                }
                return em;
              }),
            ],
          },
          this.props.hideModal(false)
        );
      }
    );
  }

  editContact(e, contact, contactIndex) {
    return this.props.contactModal(
      this.props.eventId,
      this.props.hook.id,
      e.id,
      e,
      "editContact",
      contact,
      contactIndex,
      this.props.innerHook,
      this.props.policyGroups,
      (eventId, hookId, emailId, action, email) => {
        this.setState(
          {
            emails: [
              ...this.state.emails.map((em) => {
                if (emailId == em.id) {
                  return { ...em, contacts: email.data.contacts };
                }
                return em;
              }),
            ],
          },
          this.props.hideModal(false)
        );
      }
    );
  }
  deleteEmail(e) {
    this.props.deleteItem(
      {
        emailId: e.id,
        hookId: this.props.hook.id,
        eventId: this.props.eventId,
      },
      "hookEmails"
    );
  }

  deleteContact(emailId, contactIndex) {
    const contacts = [
      ...this.state.emails.filter((email) => email.id == emailId)[0].contacts,
    ];
    contacts.splice(contactIndex, 1);
    this.setState(
      {
        emails: [
          ...this.state.emails.map((em) => {
            if (emailId == em.id) {
              return { ...em, contacts };
            }
            return em;
          }),
        ],
      },
      this.props.hideModal(false)
    );
  }
  emailTemplateChanged(index, data) {
    const newEmails = [...this.state.emails];
    newEmails[index].template = data;
    this.setState({ emails: newEmails });
  }

  createEmail() {
    this.setState({ ready: false }, () =>
      this.props.createEmail(this.props.eventId, this.props.hook.id)
    );
  }

  cancelEdit() {
    this.props.cancelEdit();
  }
  submitEmails(emailId, index) {
    const template = { ...this.state.emails[index].template };
    template.html = replaceSpanWithDataOriginalVar(template.html);
    this.props.saveEmail(
      this.props.eventId,
      this.props.hook.id,
      emailId,
      "edit",
      {
        data: {
          template: JSON.stringify(template),
          contacts: this.state.emails[index].contacts,
        },
      }
    );
  }
  createDefaultEmail() {
    this.props.createEmail(this.props.eventId, this.props.hook.id, true);
  }
  render() {
    if (!this.state.ready) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }

    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <div className="right">
              <button
                type="button"
                className="btn create rounded"
                onClick={() => {
                  this.createEmail();
                }}
              >
                Create from scratch
              </button>
              <button
                type="button"
                className="btn create rounded"
                onClick={() => {
                  this.createDefaultEmail();
                }}
              >
                Create from template
              </button>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <Breadcrumbs
            options={[
              {
                name: "Hook Emails",
                action: () => this.props.changeTab("Hooks", null),
              },
              { name: this.props.hook.hookLabel },
            ]}
          />
          <div>
            {this.state.emails.map((e, i) => {
              const variables = JSON.parse(
                JSON.stringify(this.props.globalVars)
              )
                .concat(e.email_variables)
                .sort((a, b) => (sort[a.key] < sort[b.key] ? -1 : 1));

              //remove all links from user self registration hook
              if (this.props.innerHook.hook.hookId == 1) {
                variables
                  .filter((v) => v?.key == "links")[0]
                  ?.value?.splice(2, 4);
              } else if (this.props.innerHook.hook.hookId == 3) {
                variables
                  .filter((v) => v?.key == "links")[0]
                  ?.value?.splice(0, 1);
              }
              return (
                <div key={`${e.id}-${i}`} style={{ marginBottom: "20px" }}>
                  <Email
                    placeholder={`Type the content of the email. Use any variables you want, for dynamic injection.`}
                    rows={50}
                    defaultReplyTo={this.props.supportEmail}
                    template={{ id: e.id, ...e.template }}
                    onChange={(data) => this.emailTemplateChanged(i, data)}
                    variables={variables}
                    contacts={e.contacts}
                    addContact={() => this.addContact(e)}
                    editContact={(email, contact, index) =>
                      this.editContact(email, contact, index)
                    }
                    deleteContact={(contactId) =>
                      this.deleteContact(e.id, contactId)
                    }
                    deleteEmail={() => this.deleteEmail(e)}
                    onSave={() => this.submitEmails(e.id, i)}
                    contactVariables={{
                      groups: this.props.policyGroups,
                      subjectLabel: this.props.innerHook.hook.subjectLabel,
                      objectLabel: this.props.innerHook.hook.objectLabel,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

EmailHooks.defaultProps = {
  emails: [],
};
EmailHooks.propTypes = {
  emails: PropTypes.array,
  event: PropTypes.object,
  getHookEmails: PropTypes.func,
  hook: PropTypes.object,
  contactModal: PropTypes.func,
  eventId: PropTypes.number,
  saveEmail: PropTypes.func,
  createEmail: PropTypes.func,
  action: PropTypes.string,
  innerHook: PropTypes.object,
  cancelEdit: PropTypes.func,
  deleteItem: PropTypes.func,
  resetEmail: PropTypes.func,
  isHook: PropTypes.bool,
  mode: PropTypes.string,
  globalVars: PropTypes.array,
  presetVars: PropTypes.array,
  orgId: PropTypes.any,
  createPresetEmail: PropTypes.func,
  tempContacts: PropTypes.any,
  updateEmail: PropTypes.func,
  accessEvents: PropTypes.any,
  loadPresetEmails: PropTypes.func,
  createCustomEmail: PropTypes.func,
  tempCustomContacts: PropTypes.any,
  deleteContact: PropTypes.func,
  presetEmailId: PropTypes.any,
  presetEmails: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  changeTab: PropTypes.func,
  changeState: PropTypes.func,
  addNotification: PropTypes.func,
  innerHookFetching: PropTypes.bool,
  policyGroups: PropTypes.array,
  hideModal: PropTypes.func,
  supportEmail: PropTypes.string,
};
const mapStateToProps = (state) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    tempContacts: state.api.events.tempContacts,
    tempCustomContacts: state.api.events.tempCustomContacts,
    presetEmails: state.api.events.presetEmails,
    supportEmail: state.api.events.edit.data.supportEmail,
    presetVars: state.api.events.presetEmails.meta.email_variables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPresetEmails: (orgId, eventId) => {
      dispatch(getPresetEmails(orgId, eventId));
    },
    addNotification: (msg) => {
      dispatch(addNotification(msg, "success"));
    },
    createPresetEmail: (orgId, data) => {
      return dispatch(createPresetEmail(orgId, data));
    },
    updateEmail: (orgId, presetEmailId, data) => {
      return dispatch(updatePresetEmail(orgId, presetEmailId, data));
    },
    deleteContact: (key, mode) => {
      dispatch(deleteContact(key, mode));
    },
    createCustomEmail: (eventId, data) => {
      return dispatch(createCustomEmail(eventId, data)).then(() => {
        dispatch(addNotification("Email has been sent", "success"));
        return null;
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailHooks);
