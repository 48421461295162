import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import KMBLoader from "@layout/KMBLoader";
import { HeaderPagination } from "@layout/Pagination";
import NoResults from "@layout/NoResults";

export default class TotalScans extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.props.loadScans(this.props.eventId);
  }

  render() {
    if (this.props.fetching) {
      return <KMBLoader rows="20" padding={24} height={53} />;
    }

    const { p, rpp, totalRows } = this.props.meta;

    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <div className="right">
              <HeaderPagination
                fetching={this.props.fetching}
                p={p}
                rpp={rpp}
                totalRows={totalRows}
                onChangePage={(data) =>
                  this.props.loadScans(
                    this.props.eventId,
                    data.p,
                    undefined,
                    data.rpp
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="tab-content total-scans">
          <div className="med-table-container table-container  ">
            {this.props.totalScans.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Room</th>
                    <th>Access Policy</th>
                    <th>Event</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.totalScans.map((scan, index) => {
                    const policyName =
                      this.props.policies.filter(
                        (p) => p.id === scan.eventPolicyId
                      )[0].name || "";
                    return (
                      <tr key={`scan-${index}`}>
                        <td>{scan.eventUserId}</td>
                        <td>{`${scan.info.firstName} ${scan.info.lastName}`}</td>
                        <td>{scan.eventRoomName}</td>
                        <td>{policyName}</td>
                        <td className="scan-type">
                          <span>{scan.scanType}</span>
                        </td>
                        <td>
                          {moment(scan.scanDate.tz).format("DD-MM-Y HH:mm:ss")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {!this.props.totalScans.length && (
              <NoResults style={{ height: 200 }} />
            )}
          </div>
        </div>
      </>
    );
  }
}

TotalScans.propTypes = {
  eventId: PropTypes.string.isRequired,
  loadScans: PropTypes.func.isRequired,
  totalScans: PropTypes.array.isRequired,
  policies: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
};
