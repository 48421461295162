import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MainContent from "./partials/MainContent";
import Jumbotron from "./partials/Jumbotron";

const Home = () => {
  const tabs = [
    { key: "jumbotron", label: "Jumbotron" },
    { key: "mainContent", label: "Main Content" },
  ];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <React.Fragment>
      <div className="top-menu">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </div>
      {tabs[activeTab].key === "jumbotron" && <Jumbotron />}
      {tabs[activeTab].key === "mainContent" && <MainContent />}
    </React.Fragment>
  );
};

Home.propTypes = {};

export default Home;
