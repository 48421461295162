import { connect } from "react-redux";
import { createEvent } from "@actions";
import NewEvent from "./NewEvent";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    createEvent: (data) => {
      dispatch(createEvent(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEvent);
