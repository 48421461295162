import React from "react";
import moment from "moment";
import { isNumeric, isOnline } from "@helpers";
import KMBLoader from "@layout/KMBLoader";
import Confirm from "@layout/Confirm";
import PropTypes from "prop-types";
import socketsHelper from "@helpers/sockets-helper";

export default class RoomScanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format("HH:mm:ss"),
      id: "",
      allOutConfirm: false,
      onLine: true,
      settingsOpen: false,
      userConfirmed: false,
      statistics: props.statistics,
    };

    this.initSockets = this.initSockets.bind(this);
    this.timer = null;
    this.tick = this.tick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.allOut = this.allOut.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.tick();
    }, 1000);

    document.body.addEventListener("keyup", this.handleKeyPress);
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.fullScreen) {
      this.main.focus();
    }
  }
  componentWillUnmount() {
    socketsHelper.close();
  }
  UNSAFE_componentWillMount() {
    this.props.loadEvent(this.props.eventId);
    this.props.loadRoom(this.props.eventId, this.props.roomId);
    this.props.registerScanner(this.props.eventId, this.props.roomId);
    this.props.loadStatistics(this.props.eventId).then(() => {
      this.initSockets();
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      statistics: nextProps.statistics,
    });
  }

  initSockets() {
    socketsHelper
      .init(this.props.apiUrl, `events/${this.props.eventId}/`)
      .then((socket) => {
        socket.on("scans", () => {
          this.props.loadStatistics(this.props.eventId);
        });
      })
      .catch((error) => {
        this.props.addNotification(`Sockets error: ${error}`, "error");
      });
  }
  allOut() {
    this.setState({
      allOutConfirm: true,
    });
  }
  handleKeyPress(e) {
    const { key } = e;
    const interestedGroup = ["Enter", "Delete", "Backspace"];

    if (!isNumeric(key) && !interestedGroup.includes(key)) {
      return;
    }

    if (interestedGroup.includes(key)) {
      // call submit or clear
      const userId = this.state.id;

      if (userId === "") {
        return;
      }

      this.setState(
        {
          id: "",
        },
        () => {
          if (key === "Enter")
            this.props.scanUser(this.props.eventId, this.props.roomId, userId);
        }
      );
    } else {
      // prepare number
      this.setState((prevState) => {
        return {
          id: `${prevState.id}${key}`,
        };
      });
    }
  }
  toggleSettings() {
    if (!this.state.settingsOpen) {
      document.body.removeEventListener("keyup", this.handleKeyPress);
      this.props
        .scanningAuth(() =>
          document.body.addEventListener("keyup", this.handleKeyPress)
        )
        .then(() => {
          this.setState({
            settingsOpen: true,
          });
        })
        .catch((err) => {
          console.warn(err);
        });
    } else {
      document.body.addEventListener("keyup", this.handleKeyPress);
      this.setState({
        settingsOpen: false,
      });
    }
  }

  tick() {
    this.setState({
      time: moment().format("HH:mm:ss"),
      onLine: isOnline(),
    });
  }

  render() {
    const rooms = this.state.statistics.usersInRoom;
    let usersInRoom = 0;
    for (const roomId in rooms) {
      if (roomId == this.props.currentRoom?.id) {
        usersInRoom = rooms[roomId].users;
      }
    }
    const {
      activeScans,
      currentAccess,
      currentRoom,
      eventId,
      roomId,
      unlockScanner,
    } = this.props;
    let notFound = false;

    const showWarning = this.props.offlineScans || !this.state.onLine;

    activeScans.map((scan) => {
      if (scan.id === -1) {
        notFound = true;
      }
    });

    const alloutBody = currentRoom ? (
      <div>
        <p>
          This action will permanently remove the users from room with ID:{" "}
          <strong>{this.props.roomId}</strong>
        </p>
        <p>
          <span>{this.props.currentRoom.name}</span>
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    ) : null;

    const closeSessionBody = currentRoom ? (
      <div>
        <p>
          This action will close the scanning session in room{" "}
          <strong>{this.props.roomId}</strong>
        </p>
        <p>
          <span>{this.props.currentRoom.name}</span>
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    ) : null;

    return (
      <div
        className="container-fluid tab-content"
        tabIndex="-1"
        ref={(ref) => (this.main = ref)}
      >
        <div className="row top-bar">
          <div className="col-xs-6 informations">
            <div className="room">
              <label>Room name</label>
              {!this.props.currentRoom && (
                <KMBLoader rows={15} padding={24} height={53} />
              )}
              <p>{this.props.currentRoom ? this.props.currentRoom.name : ""}</p>
            </div>
            <div className="time" style={{ width: "145px" }}>
              <label>Time</label>
              <p>{this.state.time}</p>
            </div>
            <div className="time">
              <label>Participants Inside</label>
              <p>
                {this.props.currentRoom &&
                  `${usersInRoom}${
                    this.props.currentRoom?.capacity
                      ? `/${this.props.currentRoom?.capacity}`
                      : ""
                  }`}
              </p>
            </div>
          </div>
          {currentAccess &&
            (currentAccess.role === "organisation_admin" ||
              currentAccess.role === "event_admin") && (
              <div className="col-xs-6 action-buttons text-right">
                <span
                  className={`fa fa-wrench settings-toggler${
                    this.state.settingsOpen ? " toggled" : ""
                  }`}
                  onClick={this.toggleSettings}
                ></span>
                <div
                  className={`inner-settings${
                    this.state.settingsOpen ? "" : " hidden"
                  }`}
                >
                  {currentRoom && currentRoom.lockScanner === 1 && (
                    <button
                      className="btn"
                      onClick={() => unlockScanner(eventId, roomId)}
                    >
                      Unlock Scanner
                    </button>
                  )}
                  <span className="confirm-holder">
                    <Confirm
                      onConfirm={() => this.props.allOut(eventId, roomId)}
                      body={alloutBody}
                      className="delete-event"
                      confirmText="Confirm All Out"
                      disabled={false}
                      title="All Out Action! Are you sure?"
                    >
                      <button className="btn" onClick={this.allOut}>
                        <span className="icon-all-out"></span>All out
                      </button>
                    </Confirm>
                  </span>
                  <span className="confirm-holder">
                    <Confirm
                      onConfirm={() => window.close()}
                      body={closeSessionBody}
                      className="delete-event"
                      confirmText="Confirm Close Session"
                      disabled={false}
                      title="Closing Scanning Session!"
                    >
                      <button className="btn">
                        <span className="icon-close"></span>Close Session
                      </button>
                    </Confirm>
                  </span>
                </div>
              </div>
            )}
        </div>
        {showWarning && (
          <div className="offline-panel row text-center">
            <div className="col-xs-12">
              <h4>
                Caution:
                {!this.state.onLine && (
                  <span> Network connection is lost. </span>
                )}
                {this.props.offlineScans &&
                  " There are unsaved hits. Available actions:"}
              </h4>
              {this.props.offlineScans && (
                <ul>
                  <li>
                    <button
                      className="btn"
                      onClick={() =>
                        this.props.downloadFile(
                          this.props.eventId,
                          this.props.roomId
                        )
                      }
                    >
                      Download hits file
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn"
                      onClick={() => {
                        this.props.loadStatistics(this.props.eventId);

                        this.props.sendHitsToServer(
                          this.props.eventId,
                          this.props.roomId
                        );
                      }}
                    >
                      Retry to connect and send the hits
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
        <div className="scan-table  med-table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>In / Out</th>
                <th>Total Time</th>
              </tr>
            </thead>
            <tbody>
              {activeScans.map((currentScan, index) => {
                if (currentScan.id === -1) {
                  return null;
                }
                return (
                  <tr
                    className={`type-${currentScan.scanType}`}
                    key={`scan-${index}`}
                  >
                    <td>{`${currentScan.info.firstName} ${currentScan.info.lastName}`}</td>
                    <td className="scan-type">
                      <div>
                        {currentScan.scanType === "offline"
                          ? "N/A"
                          : currentScan.scanType === "exit"
                          ? "Out"
                          : "In"}
                      </div>
                    </td>
                    <td>{currentScan.minutes} minutes</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {notFound && (
          <div className="not-found">
            <span className="icon-valid"></span>
            User not found.
          </div>
        )}
      </div>
    );
  }
}

RoomScanning.propTypes = {
  loadRoom: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  scanUser: PropTypes.func.isRequired,
  activeScans: PropTypes.array,
  currentRoom: PropTypes.object,
  allOut: PropTypes.func.isRequired,
  offlineScans: PropTypes.bool.isRequired,
  downloadFile: PropTypes.func.isRequired,
  sendHitsToServer: PropTypes.func.isRequired,
  scanningAuth: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  currentAccess: PropTypes.object,
  unlockScanner: PropTypes.func.isRequired,
  registerScanner: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  apiUrl: PropTypes.string,
  statistics: PropTypes.any,
  loadStatistics: PropTypes.func,
  addNotification: PropTypes.func,
};
