import { api } from "@actions/ajax";
import { addNotification } from "@actions";
import { isEmpty } from "@helpers";
import { uploadFile } from ".";

export const SESSION_TYPES_SAVED = "SESSION_TYPES_SAVED";
export const RECEIVED_SESSIONS_UPDATESESSIONTYPE =
  "RECEIVED_SESSIONS_UPDATESESSIONTYPE";
export const RECEIVED_SESSIONS_DELETESESSIONTYPE =
  "RECEIVED_SESSIONS_DELETESESSIONTYPE";
export const UPDATING_TAB = "UPDATING_TAB";
export const TAB_UPDATED = "TAB_UPDATED";

export const importSessions = (
  eventId,
  fieldsName,
  sessions,
  ignoreFirstRow,
  splitChar
) => {
  return (dispatch) => {
    const endpoint = "sessions";

    const query = {
      endpoint,
      action: "import",
      params: {
        eventId,
      },
      body: {
        fieldsName: fieldsName.join(","),
        sessions,
        ignoreFirstRow,
        splitChar,
      },
      contentType: "multipart/form-data",
    };

    dispatch(api(query))
      .then((d) => {
        dispatch(
          addNotification(
            `${d.data.numberOfSessions} Session${
              d.data.numberOfSessions > 1 ? "s" : ""
            } imported successfully.`,
            "success"
          )
        );

        dispatch(loadSessions(eventId));
      })
      .catch((err) => console.error(err));
  };
};

export const saveSpeech = (
  eventId,
  sessionId,
  data,
  speechId = false,
  _, // deprecated contentType = "json",
  deleteFile,
  quickEdit = false,
  meta
) => {
  const temp = data.temp;
  delete data.temp;
  return (dispatch) => {
    let deletionAction = false;
    data.speechFiles?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });
    data.videoSpeechThumbnail?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });

    const filesToUpload = {
      speechFiles: Object.assign([], data.speechFiles),
      videoSpeechThumbnail: Object.assign([], data?.videoSpeechThumbnail),
    };

    if (!deletionAction) {
      delete data.speechFiles;
      delete data.videoSpeechThumbnail;
    } else {
      filesToUpload.speechFiles = [];
      filesToUpload.videoSpeechThumbnail = [];
    }

    if (speechId) {
      data.eventSessionId = sessionId;
    }

    if (data.orgPersonsIds) {
      data["eventUsersIds"] = [...data.orgPersonsIds];
    }

    delete data.orgPersonsIds;

    if (quickEdit) {
      delete data.eventUsersIds;
    }

    const query = {
      endpoint: "speeches",
      action: speechId ? "edit" : "create",
      params: {
        eventId,
        sessionId,
        speechId,
      },
      body: {
        data,
      },
    };

    query.params["sessionId"] = sessionId;

    if (query.params)
      if (deleteFile) {
        query.extra = { deleteFile };
      }

    if (query.action === "edit") {
      query.params["speechId"] = speechId;
    }

    if (temp) {
      query.query = "?temp=" + temp;
    }

    const callback = () => {
      dispatch(loadSessions(eventId, meta));
      dispatch({ type: TAB_UPDATED });
    };

    const promiseArr = [];
    return dispatch(api(query)).then((response) => {
      if (deletionAction) {
        return response;
      }
      Object.keys(filesToUpload).forEach((key) => {
        filesToUpload[key].forEach((file) => {
          if (file && typeof file !== "number") {
            promiseArr.push(
              dispatch(
                uploadFile(
                  parseInt(eventId),
                  key,
                  file,
                  {
                    speechId: response.data.id,
                  },
                  null,
                  null,
                  filesToUpload[key].length
                )
              )
            );
          }
        });
      });
      Promise.all(promiseArr)
        .then(() => {
          callback();
          return response;
        })
        .catch((err) => {
          console.warn(err);
        });
    });
  };
};

export const loadPersons = (orgId, meta = false, endpoint = "persons") => {
  return (dispatch, getState) => {
    const request = {
      endpoint,
      action: "list",
      params: {
        orgId,
      },
    };

    const defaults = {
      rpp: 20,
      search: "",
      orderBy: "id",
      order: "DESC",
      p: 1,
    };

    const state = getState();
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });

      if (
        meta.hasOwnProperty("orderBy") &&
        state.api[endpoint].list.meta.orderBy === buildMeta.orderBy
      ) {
        buildMeta.order =
          state.api[endpoint].list.meta.order === "ASC" ? "DESC" : "ASC";
      }
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    request.cache = false;

    dispatch(api(request));
  };
};
export const loadSessionUsers = (eventId, sessionId, meta) => {
  return (dispatch, getState) => {
    const endpoint = "sessionUsers";
    const request = {
      endpoint,
      action: "list",
      params: {
        eventId,
        sessionId,
      },
    };

    const defaults = {
      rpp: 20,
      search: "",
      orderBy: "id",
      order: "DESC",
      p: 1,
    };

    const state = getState();
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });

      if (
        meta.hasOwnProperty("orderBy") &&
        state.api[endpoint].list.meta.orderBy === buildMeta.orderBy
      ) {
        buildMeta.order =
          state.api[endpoint].list.meta.order === "ASC" ? "DESC" : "ASC";
      }
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }
    dispatch(api(request));
  };
};
export const deleteSessionUser = (eventId, sessionId, userId) => {
  return (dispatch) => {
    const endpoint = "sessionUsers";
    const request = {
      endpoint,
      action: "delete",
      body: {
        data: [],
      },
      params: {
        eventId,
        sessionId,
        userId,
      },
    };

    return dispatch(api(request)).then(() => {
      dispatch(loadSessionUsers(eventId, sessionId));
      dispatch(
        addNotification("User removed from session successfully", "success")
      );
    });
  };
};
export const createSessionUser = (eventId, sessionId, userId) => {
  return (dispatch) => {
    const endpoint = "sessionUsers";
    const request = {
      endpoint,
      action: "add",
      body: {
        data: {
          userId,
        },
      },
      params: {
        eventId,
        sessionId,
      },
      extra: { preserveModal: true },
    };

    return dispatch(api(request)).then(() => {
      dispatch(loadSessionUsers(eventId, sessionId));
      dispatch(
        addNotification("User added to session successfully", "success")
      );
    });
  };
};

//TODO: check if depricated
export const savePerson = (
  orgId,
  data,
  personId = false,
  _, //contentType = "json",
  endpoint = "persons",
  refetch = false,
  extra = {}
) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: personId ? "edit" : "create",
        params: {
          orgId,
        },
        body: {
          data,
        },
      };

      if (!isEmpty(extra)) {
        query.extra = extra;
      }

      if (personId) {
        query.params["personId"] = personId;
      }

      if (endpoint === "staff") {
        query.body.data = Object.assign({}, query.body.data, {
          verified: 1,
        });
      }

      dispatch(api(query))
        .then((person) => {
          resolve(person);
          if (refetch) {
            const state = getState();
            if (query.action === "create") {
              dispatch(
                loadPersons(orgId, state.api.persons.list.meta, endpoint, true)
              );
            }
          }
        })
        .catch((err) => reject(err));
    });
  };
};
export const updateSchedule = (eventId, data, type, endpoint = "events") => {
  if (data.scheduleFile.length === 0) {
    delete data.scheduleFile;
  }
  const event = Object.assign({}, data);

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: UPDATING_TAB });

      const query = {
        endpoint,
        action: "update",
        params: {
          section: "info",
          eventId,
        },
        body: {
          data: event,
        },
        cache: false,
      };
      if (
        data.scheduleFile &&
        data.scheduleFile.length > 0 &&
        type !== "delete"
      ) {
        query.body = Object.assign({}, event, {});
      }

      dispatch(api(query))
        .then((event) => {
          dispatch({ type: TAB_UPDATED, event });
          resolve(event);
        })
        .catch((err) => {
          console.warn(err);
          reject(new Error(err));
        });
    });
  };
};

export const saveSession = (
  eventId,
  data,
  sessionId = false,
  _, // deprecated contentType = "json",
  deleteFile,
  meta = {}
) => {
  const temp = data.temp;
  delete data.temp;
  return (dispatch) => {
    data.eventRoomId = parseInt(data.eventRoomId);

    let deletionAction = false;
    data.sessionFiles?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });
    data.videoSessionThumbnail?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });

    const filesToUpload = {
      sessionFiles: Object.assign([], data?.sessionFiles),
      videoSessionThumbnail: Object.assign([], data?.videoSessionThumbnail),
    };

    if (!deletionAction) {
      delete data.sessionFiles;
      delete data.videoSessionThumbnail;
    } else {
      filesToUpload.sessionFiles = [];
      filesToUpload.videoSessionThumbnail = [];
    }

    if (data.orgPersonId) {
      const id = data.orgPersonId.value;
      delete data.orgPersonId;
      data["eventUsersIds"] = [...id];
    }
    const query = {
      endpoint: "sessions",
      action: sessionId ? "edit" : "create",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };

    if (deleteFile) {
      query.extra = { deleteFile };
    }

    if (sessionId) {
      query.params["sessionId"] = sessionId;
    }

    if (temp) {
      query.query = "?temp=" + temp;
    }

    const callback = () => {
      dispatch(loadSessions(eventId, meta));
      dispatch({ type: TAB_UPDATED });
    };

    const promiseArr = [];
    dispatch(api(query)).then((response) => {
      if (deletionAction) {
        callback();
        return;
      }
      Object.keys(filesToUpload).forEach((key) => {
        filesToUpload[key].forEach((file) => {
          if (file && typeof file !== "number") {
            promiseArr.push(
              dispatch(
                uploadFile(
                  parseInt(eventId),
                  key,
                  file,
                  {
                    sessionId: response.data.id,
                  },
                  callback,
                  null,
                  filesToUpload[key].length
                )
              )
            );
          }
        });
      });
      Promise.all(promiseArr)
        .then(() => {
          callback();
        })
        .catch((err) => {
          console.warn(err);
        });
    });
  };
};

export const deleteSessionType = (orgId, sessionTypeId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "sessions",
      action: "deleteSessionType",
      params: {
        orgId,
        sessionTypeId,
      },
      extra: {
        sessionTypeId,
      },
    })
  ).then(() =>
    dispatch(addNotification("successfully deleted Session Type", "success"))
  );

export const saveSessionType = (orgId, data, sessionTypeId = false) => {
  return (dispatch) => {
    const query = {
      endpoint: "sessions",
      action: sessionTypeId ? "updateSessionType" : "createSessionType",
      params: {
        orgId,
      },
      body: { data },
    };

    if (sessionTypeId) {
      query.params["sessionTypeId"] = sessionTypeId;
    }

    return dispatch(api(query)).then((data) => {
      if (sessionTypeId) {
        dispatch(
          addNotification(
            `Session type "${data.data.name}" updated successfully!`,
            "success"
          )
        );
      }
      return data.data;
    });
  };
};

export const loadSessions = (eventId, meta = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      p = 1,
      rpp = 20,
      order = "ASC",
      orderBy = "eventRoomId",
    } = state.api.sessions.list.meta;

    const search = meta.search || "";

    meta = { p, rpp, search, order, orderBy, ...meta };
    let query = "?";
    Object.entries(meta).map(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const endpoint = "sessions";
    const request = {
      endpoint,
      action: "list",
      params: {
        eventId,
      },
      query,
    };

    dispatch(api(request));
  };
};
