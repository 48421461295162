import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";

const SingleStaff = (props) => {
  return (
    <div className="new-staff form-container">
      <h2>{isEmpty(props.value) ? "Create Staff" : "Edit Staff"}</h2>
      <Form
        componentName={props.endpoint}
        formName="single"
        value={props.value}
        onSubmit={(data) => {
          if (isEmpty(data.errors)) {
            if (
              data.type === "json" &&
              data.value.hasOwnProperty("personThumbnail")
            ) {
              delete data.value.personThumbnail;
            }
            props.save(
              props.orgId,
              data.value,
              isEmpty(props.value) ? false : props.value.id,
              data.type,
              props.endpoint
            );
          }
        }}
        onCancel={props.hideModal}
      />
    </div>
  );
};

SingleStaff.propTypes = {
  endpoint: PropTypes.string.isRequired,
  h2: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  value: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
};

SingleStaff.defaultProps = {
  value: {},
};

export default SingleStaff;
