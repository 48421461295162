import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import { isEmpty } from "@helpers";
import moment from "moment";
import socketsHelper from "@helpers/sockets-helper";
import NoResults from "@layout/NoResults";

export default class Charts extends React.Component {
  constructor(props) {
    super(props);

    [
      "changeDateTab",
      "buildTabs",
      "buildData",
      "initSockets",
      "refetchDiagrams",
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));

    this.lockUpdate = false;

    this.state = {
      tabs: this.buildTabs(props.rooms, props.statistics),
      rooms: props.rooms,
      statistics: props.statistics,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.rooms.length) {
      this.props.loadRooms(this.props.eventId);
    }
  }

  componentDidMount() {
    this.initSockets();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {};
    let refreshTabs = false;

    if (this.props.rooms.timestamp !== nextProps.rooms.timestamp) {
      newState["rooms"] = nextProps.rooms;
      refreshTabs = true;
    }

    if (this.props.statistics.timestamp !== nextProps.statistics.timestamp) {
      newState["statistics"] = nextProps.statistics;
      refreshTabs = true;
    }

    if (refreshTabs) {
      newState["tabs"] = this.buildTabs(nextProps.rooms, nextProps.statistics);
    }

    this.setState(newState);
  }

  refetchDiagrams() {
    this.lockUpdate = true;
    this.props.loadStatistics(this.props.eventId, true);
  }

  initSockets() {
    socketsHelper
      .init(this.props.apiUrl, `events/${this.props.eventId}/`)
      .then((socket) => {
        socket.on("scans", () => {
          this.refetchDiagrams();
        });

        socket.on("allout", () => {
          this.refetchDiagrams();
        });

        socket.on("rooms", () => {
          this.props.loadRooms(this.props.eventId);
        });
      });
  }

  buildTabs(roomsObj, statistics) {
    const response = {};
    const diagrams = statistics.data.scanDiagram;
    const rooms = roomsObj.data;

    if (rooms.length === 0) {
      return false;
    }

    rooms.map((r) => {
      if (diagrams.hasOwnProperty(r.id) && !isEmpty(diagrams[r.id])) {
        response[r.id] = {
          activeIndex: Object.keys(diagrams[r.id]).length - 1,
          data: this.buildData(diagrams[r.id]),
        };
      }
    });

    return response;
  }

  changeDateTab(e, roomId, activeIndex) {
    e.preventDefault();
    this.setState({
      tabs: Object.assign({}, this.state.tabs, {
        [roomId]: Object.assign({}, this.state.tabs[roomId], {
          activeIndex,
        }),
      }),
    });
  }

  buildData(data) {
    const response = [];

    Object.entries(data).map(([i, d]) => {
      const originalDate = moment(i);
      const object = {
        date: originalDate.format("dddd, D MMMM YYYY HH:mm:ss"),
        dateMoment: originalDate,
        data: [],
      };

      let day = moment(i);

      d.map((users) => {
        object.data.push({
          date: day.format("HH:mm"),
          users,
        });
        day = day.add(10, "minutes");
      });

      response.push(object);
    });

    return response;
  }

  render() {
    const { fetching } = this.props;
    const { statistics, rooms } = this.state;
    const diagrams = statistics.data.scanDiagram;

    if (fetching && !this.lockUpdate) {
      return <KMBLoader rows="15" padding={24} height={53} />;
    }

    if (isEmpty(diagrams) || rooms.data.length === 0) {
      return <NoResults />;
    }

    return (
      <div className="charts tab-content">
        {Object.entries(diagrams).map(([roomId, d]) => {
          let roomName = [...rooms.data].filter(
            (r) => r.id === parseInt(roomId)
          );
          if (roomName.length) {
            roomName = roomName[0].name;
          } else {
            return null;
          }

          const data = isEmpty(d)
            ? null
            : this.state.tabs[roomId].data[this.state.tabs[roomId].activeIndex];

          return (
            <div key={`stats-${roomId}`} className="panel panel-info">
              <div className="panel-heading">
                <h4>{roomName}</h4>
                {Object.keys(d).length > 0 && (
                  <ul className="selectdate nav nav-tabs">
                    {Object.keys(d).map((k, i) => (
                      <li
                        key={`date-${roomId}-${i}`}
                        className={
                          this.state.tabs[roomId].activeIndex === i
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href="#"
                          onClick={(e) => this.changeDateTab(e, roomId, i)}
                        >
                          {moment(k).format("dddd, D MMMM YYYY")}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="panel-body">
                {!isEmpty(d) ? (
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      height={300}
                      data={data.data}
                      margin={{ top: 15, right: 0, left: -20, bottom: 0 }}
                    >
                      <XAxis dataKey="date" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip id="linechart-tooltip" />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="users"
                        name="Participants"
                        stroke="#1976d2"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <p>No data available.</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

Charts.propTypes = {
  statistics: PropTypes.object.isRequired,
  loadRooms: PropTypes.func.isRequired,
  rooms: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  apiUrl: PropTypes.string.isRequired,
  addNotification: PropTypes.func.isRequired,
  loadStatistics: PropTypes.func.isRequired,
};
