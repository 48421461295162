import React from "react";

const Accommodation = () => {
  return (
    <React.Fragment>
      <div className="configuration-placeholder">
        No configuration options available
      </div>
    </React.Fragment>
  );
};

Accommodation.propTypes = {};

export default Accommodation;
