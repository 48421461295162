import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@layout/Select2";
import {
  addCondition,
  deleteCondition,
  updateCondition,
} from "@actions/policyGroups";
import Input from "@layout/Input";

class Condition extends React.Component {
  constructor(props) {
    super(props);
    this.operators = {
      "=": "=",
      ">": ">",
      "<": "<",
      ">=": ">=",
      "<=": "<=",
      "<>": "is not",
      like: "contains",
    };
    this.typesAvailableForOperators = ["text", "textarea"];
  }

  getOptionsAndValues() {
    const options = {};
    const optionsValues = {};
    // I take the extraSettings and push them into an array to create the options
    let optionsArray = Object.entries({
      ...this.props.policy.extraSettings,
      ...this.props.policy.systemExtraSettings,
    }).reduce((acc, [key, value]) => {
      return [...acc, { ...value, key }];
    }, []);

    const startIndex = 3;
    if (this.props.items)
      optionsArray = this.props.items
        .slice(
          startIndex,
          this.props.items.findIndex((item) => item.key === this.props.item.key)
        )
        .filter((item) => item.type === "dropdown" || item.type === "yes_no");
    if (this.props.policy)
      optionsArray.forEach((extraSetting) => {
        const key = extraSetting.key;
        if (this.props.excludeKeys.includes(key)) return;
        if (extraSetting.type === "section") return;
        options[key] = extraSetting.name;
        /// Then i create the optionsValues array which contain the values for each extra setting
        if (extraSetting.type === "yes_no") {
          optionsValues[key] = {
            type: extraSetting.type,
            values: { 1: "Yes", 0: "No" },
          };
        }
        /// Some dropdown values come stringified so i check if they are strings so i can parse them
        else if (extraSetting.type === "dropdown") {
          if (typeof extraSetting.value === "string") {
            const values = {};
            JSON.parse(extraSetting.value).forEach(
              ({ value, key }) => (values[key] = value)
            );
            optionsValues[key] = {
              type: extraSetting.type,
              values,
            };
          } else {
            const values = {};
            extraSetting.value?.forEach?.(
              ({ value, key }) => (values[key] = value)
            );
            optionsValues[key] = {
              type: extraSetting.type,
              values,
            };
          }
        } else {
          optionsValues[key] = {
            type: extraSetting.type,
            values: "",
          };
        }
      });

    return { options, optionsValues };
  }
  renderJsx(options, optionsValues) {
    switch (this.props.condition.type) {
      case "text":
      case "textarea":
        return (
          <Input
            disabled={!this.props.condition.type}
            placeholder="Value"
            value={this.props.condition.value}
            onChange={(e) => {
              this.updateCondition({
                value: e.target.value,
              });
            }}
          />
        );
      default:
        return (
          <Select
            placeholder={
              ((optionsValues[this.props.condition.key] || {}).values || {})[
                this.props.condition.type === "dropdown"
                  ? this.props.condition.dropdown_value_key
                  : this.props.condition.value
              ] || "Select"
            }
            isClearable={false}
            isSearchable={false}
            disabled={!this.props.condition.key}
            placeholderInsideSelect={false}
            options={
              (optionsValues[this.props.condition.key] || {}).values || {}
            }
            onChange={(value) => {
              this.updateCondition({
                value: this.props.condition.type === "dropdown" ? "" : value,
                dropdown_value_key:
                  this.props.condition.type === "dropdown" ? value : null,
              });
            }}
          />
        );
    }
  }
  updateCondition(obj) {
    return this.props.updateCondition(
      this.props.groupIndex,
      this.props.conditionIndex,
      {
        ...this.props.condition,
        ...obj,
      }
    );
  }
  render() {
    const { options, optionsValues } = this.getOptionsAndValues();
    if (!Object.keys(options).length)
      return <div>There are no extra fields available.</div>;

    return (
      <div className="condition">
        <Select
          placeholder={"Select field"}
          options={options}
          isClearable={false}
          onChange={(key) => {
            this.updateCondition({
              key: key,
              label: options[key],
              operator: "=",
              value: "",
              dropdown_value_key: null,
              type: optionsValues[key].type,
            });
          }}
          value={this.props.condition.key}
        />
        <Select
          placeholder={this.props.condition.operator || "Select"}
          isDropDownButton={true}
          placeholderInsideSelect={false}
          options={this.props.operators || this.operators}
          isClearable={false}
          isSearchable={false}
          onChange={(index) => {
            this.updateCondition({
              operator: index,
            });
          }}
          value={this.props.condition.operator}
        />
        {this.renderJsx(options, optionsValues)}
        {this.props.isRemovable && (
          <button
            className="btn circle remove-condition"
            onClick={() =>
              this.props.deleteCondition(
                this.props.groupIndex,
                this.props.conditionIndex
              )
            }
          >
            <i className="fa fa-times-circle"></i>
          </button>
        )}
      </div>
    );
  }
}

Condition.defaultProps = {
  excludeKeys: [],
};
Condition.propTypes = {
  eventId: PropTypes.number,
  policy: PropTypes.any,
  groupIndex: PropTypes.number.isRequired,
  condition: PropTypes.object.isRequired,
  conditionIndex: PropTypes.number.isRequired,
  addCondition: PropTypes.func.isRequired,
  deleteCondition: PropTypes.func.isRequired,
  updateCondition: PropTypes.func.isRequired,
  excludeKeys: PropTypes.array.isRequired,
  isRemovable: PropTypes.bool,
  items: PropTypes.array,
  item: PropTypes.object,
  operators: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCondition: (groupIndex, conditionIndex, condition) =>
      dispatch(updateCondition(groupIndex, conditionIndex, condition)),
    addCondition: (groupIndex) => dispatch(addCondition(groupIndex)),
    deleteCondition: (groupIndex, conditionIndex) => {
      dispatch(deleteCondition(groupIndex, conditionIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Condition);
