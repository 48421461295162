import { isEmpty, generateFile } from "@helpers";

const offLineScanner = {
  getChannel: (eventId) => {
    const channel = localStorage.getItem(`offline_scanner_${eventId}`);
    return channel === null ? {} : JSON.parse(channel);
  },

  insert: (eventId, roomId, scan) => {
    const channel = offLineScanner.getChannel(eventId);
    const channelRoom = channel.hasOwnProperty(roomId) ? channel[roomId] : [];
    channelRoom.push(scan);
    channel[roomId] = channelRoom;
    localStorage.setItem(`offline_scanner_${eventId}`, JSON.stringify(channel));
    return channelRoom;
  },

  get: (eventId, roomId) => {
    const channel = offLineScanner.getChannel(eventId);
    if (!isEmpty(channel)) {
      return channel.hasOwnProperty(roomId) ? channel[roomId] : [];
    }
    return [];
  },

  deleteChannel: (eventId, roomId) => {
    const channel = offLineScanner.getChannel(eventId);

    if (channel.hasOwnProperty(roomId)) {
      delete channel[roomId];
    }

    if (isEmpty(channel)) {
      localStorage.removeItem(`offline_scanner_${eventId}`);
    } else {
      localStorage.setItem(
        `offline_scanner_${eventId}`,
        JSON.stringify(channel)
      );
    }
  },

  getAllByUser: (user) => {
    const response = {};
    for (const event of Object.entries(user.accessEvents)) {
      const channel = offLineScanner.getChannel(event[0]);
      if (!isEmpty(channel)) {
        response[event[0]] = channel;
      }
    }
    return response;
  },

  downloadFile: (eventId, eventRoomId) => {
    const scans = offLineScanner.get(eventId, eventRoomId);
    const data = { scans, eventRoomId };
    generateFile(
      `offlinehits_event_${eventId}_room_${eventRoomId}`,
      "json",
      data
    );
    offLineScanner.deleteChannel(eventId, eventRoomId);
  },
};

export default offLineScanner;
