import { api } from "@actions/ajax";
import { addNotification } from "@actions";
export const ADD_RENT_ACTIVE_SCANS = "ADD_RENT_ACTIVE_SCANS";
export const CLEAR_RENT_SCANS = "CLEAR_RENT_SCANS";

export const loadRentables = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "list",
      params: { eventId },
      cache: false,
      query: "?rpp=-1",
    };
    return dispatch(api(request));
  };
};

export const loadRentData = (
  eventId,
  rentableId,
  meta = { search: "", p: 1, rpp: 20 }
) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "dataList",
      params: { eventId, rentableId },
      cache: false,
      query: `?search=${meta.search}&p=${meta.p || 1}&rpp=${meta.rpp || 20}`,
    };
    return dispatch(api(request));
  };
};

export const deleteRentable = (eventId, rentableId) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "delete",
      params: { eventId, rentableId },
      cache: false,
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Rentable deleted successfully", "success"))
      )
      .then(() => dispatch(loadRentables(eventId)));
  };
};

export const deleteRentData = (eventId, rentableId, itemId) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "dataDelete",
      params: { eventId, rentableId, itemId },
      cache: false,
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Data deleted successfully", "success"))
      )
      .then(() => dispatch(loadRentData(eventId, rentableId)));
  };
};

export const createRentable = (eventId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "create",
      params: { eventId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request)).then(() => {
      return dispatch(loadRentables(eventId));
    });
  };
};

export const updateRentable = (eventId, rentableId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "edit",
      params: { eventId, rentableId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request)).then(() => dispatch(loadRentables(eventId)));
  };
};

export const createRentData = (eventId, rentableId, data, userId) => {
  return (dispatch) => {
    data["eventUserId"] = userId;
    const request = {
      endpoint: "rentables",
      action: "dataCreate",
      params: { eventId, rentableId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Data created successfully!", "success"))
      )
      .then(() => dispatch(loadRentData(eventId, rentableId)));
  };
};

export const createRentOrReturnDataForScan = (
  eventId,
  rentableId,
  data,
  userId
) => {
  return (dispatch) => {
    data["eventUserId"] = userId;
    const request = {
      endpoint: "rentables",
      action: "dataRentOrReturn",
      params: { eventId, rentableId },
      cache: false,
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then((response) => {
        const { eventUserFullName, rentedAtDate, returnedAtDate } =
          response.data;
        dispatch({
          type: ADD_RENT_ACTIVE_SCANS,
          payload: {
            eventUserFullName,
            rentedAtDate,
            returnedAtDate,
          },
        });

        setTimeout(() => {
          dispatch({
            type: CLEAR_RENT_SCANS,
          });
        }, 10000);

        return response.data;
      })
      .catch((error) => {
        console.error("Error in createRentDataForScan:", error);
      });
  };
};
export const createRentDataForScan = (eventId, rentableId, data, userId) => {
  return (dispatch) => {
    data["eventUserId"] = userId;
    const request = {
      endpoint: "rentables",
      action: "dataCreate",
      params: { eventId, rentableId },
      cache: false,
      body: {
        data,
      },
    };

    return dispatch(api(request))
      .then((response) => {
        const { eventUserFullName, rentedAtDate, returnedAtDate } =
          response.data;
        dispatch({
          type: ADD_RENT_ACTIVE_SCANS,
          payload: {
            eventUserFullName,
            rentedAtDate,
            returnedAtDate,
          },
        });

        setTimeout(() => {
          dispatch({
            type: CLEAR_RENT_SCANS,
          });
        }, 10000);

        return response.data;
      })
      .catch((error) => {
        console.error("Error in createRentDataForScan:", error);
      });
  };
};
export const getSingleRentable = (eventId, rentableId) => {
  return (dispatch) => {
    const request = {
      endpoint: "rentables",
      action: "getSingle",
      params: { eventId, rentableId },
      cache: false,
    };
    return dispatch(api(request)).then((response) => {
      return response.data;
    });
  };
};

export const updateRentData = (eventId, rentableId, itemId, data, userId) => {
  return (dispatch) => {
    data["eventUserId"] = userId;
    const request = {
      endpoint: "rentables",
      action: "dataEdit",
      params: { eventId, rentableId, itemId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Data updated successfully!", "success"))
      )
      .then(() => dispatch(loadRentData(eventId, rentableId)));
  };
};
