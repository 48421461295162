import * as React from "react";
const SvgSettingsPanel = (props) => (
  <svg
    width={20}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 16.5v-5.833m0-3.334V1.5M10 16.5V9m0-3.333V1.5m6.667 15v-4.167m0-3.333V1.5M.833 10.667h5m1.667-5h5m1.667 6.666h5"
      stroke="#566986"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSettingsPanel;
