import React from "react";
import PropTypes from "prop-types";
import Barcode from "react-barcode";

const DymoPreview = (props) => {
  const nameStyle = () => {
    return {
      position: "relative",
      fontSize: `${props.formValue.fontSizeName * 1.72}px`,
      top: "12px",
    };
  };

  return (
    <div className="image-wrapper">
      <div className="dymo-preview">
        {props.formValue.sameLine ? (
          <div style={nameStyle()} className="nameLine">
            {props.formValue.nameOrder === "first"
              ? "Firstname Lastname"
              : "Lastname Firstname"}
          </div>
        ) : (
          <React.Fragment>
            <div style={nameStyle()} className="nameLine">
              {props.formValue.nameOrder === "first" ? "Firstname" : "Lastname"}
            </div>
            <div style={nameStyle()} className="nameLine">
              {props.formValue.nameOrder === "first" ? "Lastname" : "Firstname"}
            </div>
          </React.Fragment>
        )}
        <Barcode
          className="barcode"
          displayValue={true}
          width={2}
          height={props.formValue.barcodeHeight * 1.72}
          fontSize={14}
          value={"12345"}
        />
      </div>
    </div>
  );
};

DymoPreview.propTypes = {
  formValue: PropTypes.object.isRequired,
};

export default DymoPreview;
