import { useDrag } from "react-dnd";
import { useDrop } from "react-dnd";
import { useCallback } from "react";
import update from "immutability-helper";

// Dimensions, ratios, and scale are sourced from ISO 216 for paper sizes
const paperSizes = [
  { id: "Legal", x: 816, y: 1346, scale: 0.26 },
  { id: "A4", x: 794, y: 1123, scale: 0.31 },
  { id: "Letter", x: 816, y: 1054, scale: 0.33 },
  { id: "A5", x: 559, y: 794, scale: 0.44 },
  { id: "Half Letter", x: 529, y: 816, scale: 0.49 },
  { id: "A6", x: 397, y: 559, scale: 0.63 },
  { id: "A7", x: 280, y: 397, scale: 0.88 },
  { id: "A8", x: 197, y: 280, scale: 1.25 },
];

// FONT OPTIONS
const fontOptions = {
  arial: { font: "Arial", subFamily: "sans-serif" },
  arialBlack: { font: "Arial Black", subFamily: "sans-serif" },
  verdana: { font: "Verdana", subFamily: "sans-serif" },
  tahoma: { font: "Tahoma", subFamily: "sans-serif" },
  trebuchet: { font: "Trebuchet MS", subFamily: "sans-serif" },
  impact: { font: "Impact", subFamily: "sans-serif" },
  timesNewRoman: { font: "Times New Roman", subFamily: "serif" },
  didot: { font: "Didot", subFamily: "serif" },
  georgia: { font: "Georgia", subFamily: "serif" },
  american: { font: "American Typewriter", subFamily: "serif" },
  andale: { font: "Andalé Mono", subFamily: "monospace" },
  courier: { font: "Courier", subFamily: "monospace" },
  lucida: { font: "Lucida Console", subFamily: "monospace" },
  bradley: { font: "Bradley Hand", subFamily: "cursive" },
  brushScript: { font: "Brush Script MT", subFamily: "cursive" },
  luminari: { font: "Luminari", subFamily: "fantasy" },
  comicSans: { font: "Comic Sans MS", subFamily: "cursive" },
};

// FIND FONT OF THE ABOVE OBJECT BASED ON KEY
const findFont = (fontValues = "Arial, sans-serif") => {
  const parsedFont = fontValues.split(", ");
  const processedFont = { font: parsedFont[0], subFamily: parsedFont[1] };
  let font = "arial";
  Object.keys(fontOptions).forEach((key) => {
    if (
      processedFont.font === fontOptions[key].font &&
      processedFont.subFamily === fontOptions[key].subFamily
    )
      font = key;
  });
  return font;
};

// Crate and configure instance of drang-n-drop with parametrized components
const prepDragAndDrop = (boxes, setBoxes, centerBox, formValue) => {
  // Move box callback function used by DnD and onChange
  const moveBox = useCallback(
    (id, left, top, center) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left: center ? 0 : left, top },
          },
        })
      );
    },
    [
      boxes,
      formValue.centerName,
      formValue.showId,
      formValue.centerId,
      formValue.centerBarcode,
    ]
  );

  // create a drop container refernce for drag and drop plugin
  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        let itemID;
        Object.keys(boxes).forEach((key) => {
          if (boxes[key].title === item.title) itemID = key;
        });
        if (!itemID) return;
        moveBox(itemID, left, top, centerBox(itemID));
        return undefined;
      },
    }),
    [moveBox]
  );

  // add drag hook function for each drag and drop element
  Object.keys(boxes).forEach((key) => {
    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: "box",
        item: boxes[key],
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [boxes[key].id, boxes[key].left, boxes[key].top, boxes[key].title]
    );
    boxes[key]["isDragging"] = isDragging;
    boxes[key]["drag"] = drag;
    boxes[key]["preview"] = preview;
  });

  return [moveBox, drop];
};

export { prepDragAndDrop, paperSizes, fontOptions, findFont };
