import React from "react";
import PropTypes from "prop-types";
import Input from "@layout/Input";

export default class Confirmer extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    if (value.toLowerCase() === this.props.word.toLowerCase()) {
      this.props.onSuccess();
    } else {
      this.props.onFail();
    }
  }

  render() {
    return (
      <div className="form-container">
        <Input autoFocus onChange={this.onChange} />
      </div>
    );
  }
}

Confirmer.propTypes = {
  word: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
};
