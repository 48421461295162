/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import UsersHeader from "./UsersHeader";
import {
  changeFilters,
  loadUsers,
  advancedUserSearch,
  getGroups,
  setGroupId,
} from "@actions/users";
import { showModal, hideModal, savePrintingSettings } from "@actions";
import CardPrinting from "../CardPrinting";
import CertPrinting from "../CertPrinting";
import AdvancedSearch from "../AdvancedSearch/";
import CreateUser from "@components/events/edit/sections/registration/AccessPolicies/partials/CreateUser/CreateUser";
import {
  setAdvancedSearchOptions,
  importUsers,
  addNotification,
} from "@actions";

const modals = {
  CardPrinting,
  CertPrinting,
  AdvancedSearch,
  CreateUser,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    columns: state.users.columns,
    policyGroups: state.users.policyGroups,
    printingSettings: {
      certificationPrintingSettings: JSON.parse(
        state.api.events.edit.data.extra.certificationPrintingSettings
      ),
      cardPrintingSettings: JSON.parse(
        state.api.events.edit.data.extra.cardPrintingSettings
      ),
    },
    advancedSearch: state.users.advancedSearch,
    groupId: state.users.groupId,
    meta: state.users.meta,
    userSet:
      state.users.advancedSearch ||
      state.users.meta.orderBy !== "id" ||
      state.users.meta.order !== "DESC" ||
      (state.users.meta.hasOwnProperty("search") &&
        state.users.meta.search !== ""),
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    changeFilters: (eventId, policyId, filters, newSort = false) => {
      dispatch(changeFilters(eventId, policyId, filters, newSort));
    },
    setGroupId: (eventId, policyId, groupId) =>
      dispatch(setGroupId(eventId, policyId, groupId)),
    resetAdvanced: (policyId, eventId) => {
      dispatch(setAdvancedSearchOptions(eventId, policyId, {}));
      dispatch(advancedUserSearch(eventId, policyId, {}, false));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    hideModal: () => {
      dispatch(hideModal());
    },

    searchUsers: (meta, policyId, eventId, loadAll) => {
      dispatch(loadUsers(eventId, policyId, meta));
    },
    fbulkImport: (content) => {
      dispatch(showModal(content));
    },
    importUsers: (data, policyId, eventId) => {
      dispatch(importUsers(data, policyId, eventId));
    },
    doAction: (action, large = true, params = {}) => {
      const Component = modals[action];
      if (action === "CreateUser") {
        dispatch(
          showModal(
            <Component
              hideModal={dispatcher.hideModal}
              params={params}
              policy={params.policy}
              createUser={params.createUser}
            />,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            large
          )
        );
      } else {
        dispatch(
          showModal(
            <Component
              hideModal={dispatcher.hideModal}
              params={params}
              savePrintingSettings={dispatcher.savePrintingSettings}
            />,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            large
          )
        );
      }
    },
    getGroups: (eventId, policyId) => {
      dispatch(getGroups(eventId, policyId));
    },
    savePrintingSettings: (data, key, eventId) => {
      dispatch(savePrintingSettings(data, key, eventId));
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersHeader);
