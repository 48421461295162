import React from "react";
import PropTypes from "prop-types";
import { Button, CloseButton } from "@layout/Buttons";
import Confirm from "@layout/Confirm";
import Confirmer from "@layout/Confirmer";
import { createContactLabel } from "../../helpers";
import AddIcon from "@app_icons/AddIcon";

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      wordConfirmed: false,
    };
  }
  deleteRecipient(item, index) {
    const deleteBody = (
      <div>
        <p>
          This action will permanently delete the contact with ID:{" "}
          <strong>{index}</strong>
        </p>
        <p>
          <span>{item.label}</span>
        </p>
        <p>Are you sure that you want to delete this contact?</p>
        <p>If yes, then please type: CONFIRM DELETE</p>
        <Confirmer
          word="confirm delete"
          onSuccess={() => {
            this.setState({ wordConfirmed: true });
          }}
          onFail={() => {
            if (this.state.wordConfirmed) {
              this.setState({ wordConfirmed: false });
            }
          }}
        />
      </div>
    );

    return (
      <Confirm
        style={{ display: "inline-block" }}
        onConfirm={() => {
          this.setState(
            {
              wordConfirmed: false,
            },
            () => this.props.removeRecipient(index)
          );
        }}
        body={deleteBody}
        className="delete-event"
        confirmText="Confirm Delete"
        disabled={this.state.wordConfirmed ? false : true}
        title="Delete Action! Are you sure?"
        onClose={() => {
          this.setState({ wordConfirmed: false });
        }}
      >
        <CloseButton
          fontSize="large"
          style={{ color: "#000", fontSize: "20px" }}
        />
      </Confirm>
    );
  }

  render() {
    return (
      <div className="row-container contacts" style={this.props.style}>
        <div className="row-title subject-title">To*</div>
        <div className="row-field subject-field non-input">
          <div className="recipients-placeholder">
            {this.props.children.length == 0
              ? "Please click the plus button to add recipients"
              : ""}
          </div>
          <div className="items">
            {this.props.children.map((child, index) => {
              return (
                <div key={`contact-item-${index}`} className="contact-item">
                  <Button
                    style={{ height: "24px" }}
                    onClick={() => this.props.editRecipient(child, index)}
                  >
                    {createContactLabel(child, this.props.contactVariables)}
                  </Button>
                  {this.props.showDeleteModal ? (
                    this.deleteRecipient(child, index)
                  ) : (
                    <CloseButton
                      style={{
                        padding: "0px",
                        height: "18px",
                        color: "#000",
                        fontSize: 15,
                      }}
                      onClick={() => this.props.removeRecipient(index)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <AddIcon
          className={"add-recipient"}
          onClick={this.props.addRecipient}
        ></AddIcon>
      </div>
    );
  }
}

Email.propTypes = {
  children: PropTypes.array,
  style: PropTypes.object,
  contactVariables: PropTypes.object,
  showDeleteModal: PropTypes.bool,
  removeRecipient: PropTypes.func,
  editRecipient: PropTypes.func,
  addRecipient: PropTypes.func,
};
Email.defaultProps = {
  style: {},
  placeholder: `Type the content of the email. Use any variables you want, for dynamic injection.`,
  showDeleteModal: true,
};

export default Email;
