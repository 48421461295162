import React from "react";
import PropTypes from "prop-types";
import Home from "./partials/Home/Home";
import Abstracts from "./partials/Abstracts/Abstracts";
import Schedule from "./partials/Schedule/Schedule";
import Videos from "./partials/Videos/Videos";
import Speakers from "./partials/Speakers/Speakers";
import Exhibition from "./partials/Exhibition/Exhibition";
import Sponsors from "./partials/Sponsors/Sponsors";
import EBag from "./partials/EBag/EBag";
import Accommodation from "./partials/Accommodation/Accommodation";

const Pages = (props) => {
  const renderActiveItem = () => {
    switch (props.activeItem.key) {
      case "home": {
        return <Home />;
      }
      case "abstracts": {
        return <Abstracts />;
      }
      case "schedule": {
        return <Schedule />;
      }
      case "accommodation": {
        return <Accommodation />;
      }
      case "videos": {
        return <Videos />;
      }
      case "speakers": {
        return <Speakers />;
      }
      case "exhibition": {
        return <Exhibition />;
      }
      case "sponsors": {
        return <Sponsors />;
      }
      case "ebag": {
        return <EBag />;
      }
    }
  };

  return (
    <div className="pages-wrapper panel-content-wrapper tab-content">
      {renderActiveItem()}
    </div>
  );
};

Pages.propTypes = {
  activeItem: PropTypes.object.isRequired,
};
export default Pages;
