import React from "react";
import PropTypes from "prop-types";
import ImageCover from "@layout/ImageCover";
import SubmitContainer from "@layout/SubmitContainer";
//this is used in scanning banner
export default class Cover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: this.props.event.scanningBanners[0]?.url || "",
    };
    this.banners = null;
  }

  render() {
    return (
      <div className="form-container tab-content">
        <div className="form-group">
          <ImageCover
            height={"300px"}
            imageUrl={this.state.imageUrl}
            name="Scanning Banner"
            description="This is used in the minutes scanning screen"
            onChange={(file, deletefile) => {
              if (deletefile) {
                this.setState({
                  imageUrl: "",
                });
                this.banners = [this.props.event.scanningBanners[0]?.id];
              } else if (window.FileReader && file) {
                const fr = new FileReader();
                fr.onload = () => {
                  this.banners = [file];
                  this.setState({
                    imageUrl: fr.result,
                  });
                };
                fr.readAsDataURL(file);
              }
            }}
          />
        </div>
        <SubmitContainer
          onCancel={() => {
            this.setState({
              imageUrl: this.props.event.scanningBanners[0]?.url || "",
            });
            this.banners = null;
          }}
          onSubmit={() => {
            this.props
              .updateEvent(this.props.event.id, {
                scanningBanners: this.banners,
              })
              .catch((err) => console.warn(err));
          }}
        />
      </div>
    );
  }
}

Cover.propTypes = {
  event: PropTypes.object.isRequired,
  addNotification: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  currentComponent: PropTypes.object,
};
