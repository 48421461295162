import React from "react";
import PropTypes from "prop-types";
import Select2 from "@layout/Select2";
import SubmitContainer from "@layout/SubmitContainer";

const EditComponent = (props) => {
  const [state, setState] = React.useState({
    header: JSON.parse(JSON.stringify(props.header)),
  });

  const allBucketOptionsUrl = props.bucket.exhibitorsBucket.reduce(
    (prev, next) => ({ ...prev, [next.id]: next.url }),
    {}
  );
  const bucketImageOptions = props.bucket.exhibitorsBucket
    .filter((item) => {
      return ["png", "jpg", "jpeg"].includes(item.extension);
    })
    .reduce((prev, next) => ({ ...prev, [next.id]: next.fileName }), {});

  return (
    <div className="exhibitor-page-edit-component form-container">
      <h2>Edit exhibitors Header</h2>
      <div className="form-group">
        <label>Image url</label>
        <input
          className="form-control"
          disabled={state.header[props.type].id}
          value={state.header[props.type].url}
          onChange={(e) => {
            const newState = { ...state };
            state.header[props.type].url = e.target.value;
            setState(newState);
          }}
          placeholder={"Type an image url..."}
        />
      </div>
      <div className="form-group" style={{ position: "relative", zIndex: 2 }}>
        <label>Select an Image from the bucket</label>
        <Select2
          disabled={
            !state.header[props.type].id && state.header[props.type].url
          }
          options={bucketImageOptions}
          onChange={(val) => {
            const newState = { ...state };
            newState.header[props.type].id = val;
            newState.header[props.type].url = allBucketOptionsUrl[val];
            setState(newState);
          }}
          placeholder={"Select an image"}
          value={state.header[props.type].id}
          multi={false}
        />
      </div>
      <div className="form-group">
        <label>Action url</label>
        <input
          className="form-control"
          value={state.header[props.type].actionUrl}
          onChange={(e) => {
            const newState = { ...state };
            newState.header[props.type].actionUrl = e.target.value;
            setState(newState);
          }}
          placeholder={"Type an image url..."}
        />
      </div>
      <SubmitContainer
        onCancel={props.onCancel}
        submitText={"Preview"}
        onSubmit={() => props.onSave(state.header)}
      />
    </div>
  );
};

EditComponent.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
};
EditComponent.propTypes = {
  header: PropTypes.object.isRequired,
  bucket: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditComponent;
