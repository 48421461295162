import { connect } from "react-redux";
import Options from "./Options";
import { adjustSection } from "@actions/editmenu";
import { getSectionKey } from "@helpers";

const mapStateToProps = (state, ownProps) => {
  return ownProps;
};

const mapDispatchToProps = (dispatch) => {
  return {
    adjustMenu: (section, action) => {
      const key = getSectionKey(section);
      dispatch(adjustSection(key, action));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
