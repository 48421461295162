import { connect } from "react-redux";
import SingleUser from "./SingleUser";
import { hideModal } from "@actions";
import { savePerson } from "@actions/crm";

const mapStateToProps = (state, ownProps) => {
  return {
    orgId: ownProps.orgId,
    id: ownProps.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    save: (
      orgId,
      data,
      personId = false,
      contentType = "json",
      endpoint,
      deletePhoto
    ) => {
      dispatch(
        savePerson(
          orgId,
          data,
          personId,
          contentType,
          endpoint,
          undefined,
          deletePhoto
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUser);
