import React from "react";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import SingleEbag from "./SingleEbag";
import PropTypes from "prop-types";
import {
  updateClientPanelSettings,
  updateClientPanelEbagOrder,
  deleteClientPanelEbag,
  showModal,
} from "@actions";
import { connect } from "react-redux";

const sortEbag = (a, b) => {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
};

const EBagList = (props) => {
  const onRender = (col, item) => {
    switch (col.key) {
      case "clientEbagImage": {
        return (
          <img
            src={
              (item.clientEbagImage || [])[0]
                ? item.clientEbagImage[0].url
                : "/images/medlive-noimage.jpg"
            }
            style={{ objectFit: "contain", width: 50, height: 40 }}
          />
        );
      }
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="ebag item"
              item={item}
              onDelete={() => {
                return props.deleteClientPanelEbag(props.event.id, item.id);
              }}
              onEdit={() => {
                return props.showModal(
                  <SingleEbag
                    item={item}
                    onSave={(data, preserveModal) => {
                      return props.updateClientPanelSettings(
                        props.event.id,
                        data,
                        "ebag",
                        item.id,
                        preserveModal
                      );
                    }}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <div className="ebag-container" key={`type-item-${props.index}`}>
      <Table
        sortable={true}
        onSortEnd={(newIndex, item, newItems) => {
          const newSortList = newItems.map((sorted, index) => ({
            id: sorted.id,
            index,
          }));

          return props.updateClientPanelEbagOrder(props.event.id, {
            ebag: newSortList,
          });
        }}
        columns={{
          clientEbagImage: {
            name: "E-Bag Image",
          },
          url: {
            name: "Action URL",
          },
          actions: {
            name: "",
          },
        }}
        items={props.event.clientPanelSettings.ebag.ebagSchema.sort(sortEbag)}
        containerClassName={`table-container `}
        onRender={onRender}
      />
    </div>
  );
};

EBagList.propTypes = {
  deleteClientPanelEbag: PropTypes.func,
  showModal: PropTypes.func,
  updateClientPanelSettings: PropTypes.func,
  event: PropTypes.object,
  index: PropTypes.number,
  updateClientPanelEbagOrder: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteClientPanelEbag: (eventId, data) => {
      return dispatch(deleteClientPanelEbag(eventId, data));
    },
    showModal: (content) => dispatch(showModal(content)),
    updateClientPanelEbagOrder: (eventId, data) => {
      return dispatch(updateClientPanelEbagOrder(eventId, data));
    },
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EBagList);
