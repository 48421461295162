import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPricingFields,
  createPeriod,
  upsertEventSubscriptions,
  updateTypePeriod,
  calculatePricingGroups,
} from "@actions/pricing";
import Subscriptions from "../Subscriptions/Subscriptions";
import PricingHeader from "./PricingHeader";
import PricingMain from "./PricingMain";
import moment from "moment";
import { deleteTypePeriod } from "@actions/pricing";
import KMBLoader from "@layout/KMBLoader";
import InfoMessages from "@layout/InfoMessages";
import { v4 as uuidv4 } from "uuid";
import { splitLastOccurrence } from "../../../../../../helpers";

//eslint-disable-next-line
const sortGroupFieldsByValueLength = (fields) => {
  return (a, b) => {
    const firstField = fields.find((p) => p.id === a);
    const secondField = fields.find((p) => p.id === b);

    if (typeof firstField?.value === "string") return -1;
    if (typeof secondField?.value === "string") return 1;
    return firstField?.value?.length - secondField?.value?.length;
  };
};
const Pricing = (props) => {
  const executeFunctions = React.useRef({});
  const [loading, setLoading] = React.useState(false);
  const [pricingFields, setPricingFields] = React.useState();
  const [bypassGroups, setBypassGroups] = React.useState();
  const [selectedBypassGroups, setSelectedBypassGroups] = React.useState();
  const [renderOldSubscription, setRenderOldSubscription] =
    React.useState(false);
  const [allOptions, setAllOptions] = React.useState({});
  const [pricingGroups, setPricingGroups] = React.useState();
  const [periods, setPeriods] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState();
  const [selectedPricingFields, setSelectedPricingFields] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState();
  const [subsection, setSubsection] = React.useState("");
  const [exceptions, setExceptions] = React.useState([]);
  const initializePricing = ({
    _pricingFields,
    _pricingGroups,
    _subscriptions,
    _periods,
  }) => {
    //create all options object
    const allOptions = {};
    const newSelectedPricingFields = [];
    const newBypassGroups = {};
    for (const field of _pricingFields.data) {
      if (field.pricing) {
        newSelectedPricingFields.push(String(field.id));
        allOptions[field.id] = field.name;
      }
      if (
        field.type === "yes_no" &&
        !newSelectedPricingFields.includes(String(field.id))
      ) {
        newBypassGroups[field.id] = field.name + " - Yes";
      }
    }
    const newPricingGroups = [];
    const newSelectedBypassGroups = [];
    for (const pricingGroup of _pricingGroups.data) {
      if (pricingGroup.pricingGroup) newPricingGroups.push(pricingGroup);
      if (pricingGroup.bypassPricing) {
        const [fieldId] = Object.entries(newBypassGroups).find(([, value]) => {
          let splitPricingGroup = [];
          try {
            const nameOnly = JSON.parse(pricingGroup.systemKey);
            splitPricingGroup = splitLastOccurrence(nameOnly[0], "-");
          } catch (e) {
            console.error(e);
          }

          try {
            // todo: cannot reproduce this error
            // but it fixes the case where the filter is a string
            if (typeof pricingGroup?.filter === "string") {
              pricingGroup.filter = JSON.parse(pricingGroup.filter);
            }
          } catch (e) {
            pricingGroup.filter = {};
            console.error(e);
          }

          return (
            value ===
            pricingGroup?.filter?.filters?.[0]?.[0]?.label +
              " - " +
              splitPricingGroup[splitPricingGroup.length - 1]
          );
        });
        newSelectedBypassGroups.push(String(fieldId));
      }

      if (typeof pricingGroup.filter !== "string") {
        pricingGroup.filter = JSON.stringify(pricingGroup.filter);
      }
    }
    const hasPricingGroups = newPricingGroups.length;

    setLoading(false);
    //check if the old subscription screen need to be rendered
    if (_periods.data.some((period) => period.eventPolicyId === null))
      return setRenderOldSubscription(true);

    const filteredPeriods = _periods.data.filter(
      (period) => period.eventPolicyId === props.policy.id
    );

    if (!filteredPeriods.length) {
      addPeriod(true);
    } else {
      setPeriods(filteredPeriods);
    }
    setPricingFields(_pricingFields.data);

    setSubsection(
      newSelectedPricingFields.length > 1
        ? newSelectedPricingFields.sort(
            sortGroupFieldsByValueLength(_pricingFields.data)
          )[0]
        : ""
    );
    setSelectedPricingFields(newSelectedPricingFields);
    if (!hasPricingGroups) {
      calculateNewGroups([]);
    } else {
      setPricingGroups(newPricingGroups);
    }
    setSelectedBypassGroups(newSelectedBypassGroups);
    setBypassGroups(newBypassGroups);
    setAllOptions(allOptions);
    setSubscriptions(_subscriptions.data);
  };

  const onCancel = () => {
    executeFunctions.current = [];
    initializePricing(initialValues);
    setExceptions(initialValues._exceptions);
  };

  const initialCall = () => {
    setRenderOldSubscription(false);
    setLoading(true);
    Promise.all([
      props.getPricingFields(props.eventId, props.policy.id),
      props.listGroups(props.eventId, props.policy.id),
      props.getSubscriptions(props.eventId, "list", props.policy.id),
      props.getSubscriptions(props.eventId, "periods", props.policy.id),
    ])
      .then(([_pricingFields, _pricingGroups, _subscriptions, _periods]) => {
        const _exceptions = _subscriptions.meta.meta.exceptions[0].map(
          (exception) =>
            String(exception.eventPolicyExtraSettingDropdownValueId)
        );
        setExceptions(_exceptions);
        setInitialValues({
          _pricingFields,
          _pricingGroups,
          _subscriptions,
          _periods,
          _exceptions,
        });
        initializePricing({
          _pricingFields,
          _pricingGroups,
          _subscriptions,
          _periods,
        });
      })
      .catch(console.error);
  };

  const onChangeSubsection = (subsection) => {
    setSubsection(subsection);
  };

  const deletePeriod = (periodId) => {
    if (periods.length === 1) return;
    setPeriods([...periods.filter((period) => period.id !== periodId)]);
  };

  const calculateBypassGroups = (newGroups) => {
    setSelectedBypassGroups(newGroups);
  };

  const onChangePeriod = (newPeriod) => {
    const newPeriods = JSON.parse(JSON.stringify(periods)).map((oldPeriod) => {
      if (oldPeriod.id === newPeriod.id) return newPeriod;
      return oldPeriod;
    });
    setPeriods(newPeriods);
  };

  const addPeriod = (initialize) => {
    const periodId = `new-${Date.now()}`;
    const today = moment();
    today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const formated = today.format("YYYY-MM-DD HH:mm");
    const periodData = {
      id: periodId,
      name: "Untitled Period",
      startDate: { tz: formated },
      endDate: { tz: formated },
    };
    if (initialize) {
      setPeriods([periodData]);
    } else {
      setPeriods([...periods, periodData]);
    }
  };

  const onSave = (prices) => {
    const data = {};
    //create periods object
    const newPeriods = [];
    for (const period of periods) {
      newPeriods.push({
        id: period.id,
        eventPolicyId: props.policy.id,
        name: period.name,
        startDate: moment(period.startDate.tz, "YYYY-MM-DD HH:mm").format(
          "YYYY-MM-DD HH:mm"
        ),
        endDate: moment(period.endDate.tz, "YYYY-MM-DD HH:mm").format(
          "YYYY-MM-DD HH:mm"
        ),
      });
    }
    data.periods = newPeriods;
    prices.forEach((price) => {
      price.systemKey = price.price.systemKey;
      price.uuid = price.price.uuid;
      price.id = price.price.id;
      price.price = price.price.value;
      if (String(price.eventPolicyGroupId).includes("new")) {
        price.eventPolicyGroupId = undefined;
      }
    });
    data.allSubscriptions = prices;
    data.policyId = props.policy.id;
    data.exceptions = exceptions;
    const sendBypassGroups = [];
    for (const field of pricingFields) {
      // todo: bypass this if no new field is added
      if (selectedBypassGroups.includes(String(field.id))) {
        sendBypassGroups.push({
          name: field.name + " - Yes",
          pricingGroup: 0,
          bypassPricing: 1,
          id: "new-" + Math.random(),
          uuid: uuidv4(),
          systemKey: JSON.stringify([field.name + "-" + "Yes"]),
          filter: JSON.stringify({
            connectionType: "and",
            filters: [
              [
                {
                  key: field.key,
                  label: field.name,
                  operator: "=",
                  value: 1,
                  type: "yes_no",
                },
              ],
            ],
          }),
        });
      }
    }

    for (const group of sendBypassGroups) {
      // todo: periods has no updated id
      for (const period of newPeriods) {
        data.allSubscriptions.push({
          active: true,
          eventPolicyGroupId: group.id,
          eventSubscriptionPeriodId: period.id,
          price: 0,
          systemKey: group.systemKey,
        });
      }
    }
    data.groups = pricingGroups.concat(sendBypassGroups);
    data.pricingFields = selectedPricingFields;
    props.upsertEventSubscriptions(props.event.id, data).then(() => {
      setInitialValues();
      initialCall();
    });
  };

  const calculateNewGroups = (newFields, newExceptions = []) => {
    setExceptions(newExceptions);

    if (pricingFields?.length) {
      const availableByPassGroups = {};
      pricingFields.forEach((field) => {
        if (field.type !== "yes_no" || newFields.includes(String(field.id)))
          return;
        availableByPassGroups[field.id] = field.name + " - Yes";
      });

      setBypassGroups(availableByPassGroups);
    }
    const fieldsThatIncludedInPricing =
      pricingFields?.filter((field) => newFields.includes(String(field.id))) ||
      [];
    props
      .calculatePricingGroups(
        props.event.id,
        fieldsThatIncludedInPricing,
        newExceptions
      )
      .then((res) => {
        res.data.forEach((group) => {
          group.id = "new-" + Math.random();
        });
        setPricingGroups(res.data);
        setSubscriptions([]);
        setSelectedPricingFields(newFields);
        if (newFields.length > 1)
          setSubsection(
            newFields.sort(sortGroupFieldsByValueLength(pricingFields))[0]
          );
        else setSubsection("");

        const allOptions = {};

        for (const i in pricingFields) {
          if (newFields.includes(String(pricingFields[i].id)))
            allOptions[pricingFields[i].id] = pricingFields[i].name;
        }
        setAllOptions(allOptions);
      });
  };

  React.useEffect(() => {
    initialCall();
  }, [props.policy]);

  if (loading) return <KMBLoader rows={15} padding={24} height={53} />;
  if (props.policy.type === "public") {
    return (
      <InfoMessages
        type="info"
        title="Subscriptions are not available for Public Policies"
        subtitle="Only Private policies support subscription fees"
      />
    );
  }
  if (renderOldSubscription) {
    return <Subscriptions {...props} />;
  }
  return (
    <div className="form-container pricing tab-content">
      <PricingHeader
        {...props}
        calculateBypassGroups={calculateBypassGroups}
        bypassGroups={bypassGroups}
        selectedBypassGroups={selectedBypassGroups}
        selectedPricingFields={selectedPricingFields}
        loading={loading}
        allOptions={allOptions}
        subscriptions={subscriptions}
        pricingFields={pricingFields}
        periods={periods}
        onChangeSubsection={onChangeSubsection}
        selectedSubsection={subsection}
        calculateNewGroups={calculateNewGroups}
        exceptions={exceptions}
      />
      <PricingMain
        {...props}
        addPeriod={addPeriod}
        deletePeriod={deletePeriod}
        subsection={allOptions[subsection]}
        periods={periods}
        pricingFields={pricingFields}
        pricingGroups={pricingGroups}
        subscriptions={subscriptions}
        onChangePeriod={onChangePeriod}
        onSave={onSave}
        onCancel={onCancel}
        exceptions={exceptions}
      />
    </div>
  );
};

Pricing.propTypes = {
  policy: PropTypes.object,
  getPricingFields: PropTypes.func,
  eventId: PropTypes.string.isRequired,
  listGroups: PropTypes.func,
  getSubscriptions: PropTypes.func,
  deleteTypePeriod: PropTypes.func.isRequired,
  createPeriod: PropTypes.func.isRequired,
  updatePeriod: PropTypes.func.isRequired,
  upsertEventSubscriptions: PropTypes.func.isRequired,
  event: PropTypes.object,
  calculatePricingGroups: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    policy: state.api.accesspolicies.selectedPolicy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPricingFields: (eventId, policyId) =>
      dispatch(getPricingFields(eventId, policyId)),
    upsertEventSubscriptions: (eventId, data) =>
      dispatch(upsertEventSubscriptions(eventId, data)),
    createPeriod: (eventId, data) => dispatch(createPeriod(eventId, data)),
    updatePeriod: (eventId, data, id) =>
      dispatch(updateTypePeriod(eventId, data, id, "periods")),
    deleteTypePeriod: (eventId, id, type, force) =>
      dispatch(deleteTypePeriod(eventId, id, type, force)),
    calculatePricingGroups: (eventId, newFields, exceptions) =>
      dispatch(calculatePricingGroups(eventId, newFields, exceptions)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
