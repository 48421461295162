/* eslint-disable */
import React from "react";
export default ({ filtered, style = {} }) => (
  <>
    {!filtered && (
      <div className="no-results" style={style}>
        <span className="no-results-icon icon-notification-info-1"></span>
        <p className="no-results-title">No Results</p>
        <p className="no-results-message">There are no data available yet</p>
      </div>
    )}
    {filtered && (
      <div className="no-results" style={style}>
        <span className="no-results-icon icon-search"></span>
        <p className="no-results-title">No Results</p>
        <p className="no-results-message">
          We did not find any data related to your search
        </p>
      </div>
    )}
  </>
);
