import React from "react";
import { connect } from "react-redux";
import { showModal, hideModal, addNotification } from "@actions";
import { listGroups } from "@actions/policyGroups";
import SingleContact from "../partials/SingleContact";
import Questionnaires from "./Questionnaires";
import {
  getQuestionnaires,
  updateQuestionnaireTitle,
  deleteQuestionnaire,
  sendQuestionnaire,
} from "@actions/questionnaire";

import {
  createQuestionnaire,
  updateQuestionnaire,
} from "@actions/questionnaire";
import { changeTab } from "@actions/editmenu";

const sort = {
  recipient: 0,
  organisation: 1,
  event: 2,
  abstract_schema: 3,
  links: 4,
  action: 5,
};

const mapStateToProps = (state) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    globalVars: state.api.events.questionnaires.meta.email_variables?.sort(
      (a, b) => (sort[a.key] < sort[b.key] ? -1 : 1)
    ),
    tempContacts: state.users.tempContacts,
    questionnaires: state.api.events.questionnaires.data,
    policyGroups: state.api.policyGroups.list.data,
    eventId: state.api.events.edit.data.id,
    fakeChild: state.editmenu.fakeChild,
    fetching:
      state.api.events.questionnaires.fetching ||
      state.api.policyGroups.list.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    contactModal: (
      orgId,
      emailId,
      emails,
      contactAction,
      contact = {},
      contactIndex,
      policyGroups,
      saveEmail = dispatcher.updateQuestionnaire
    ) => {
      dispatch(
        showModal(
          <SingleContact
            policyGroups={policyGroups}
            emails={emails}
            contactAction={contactAction}
            addNotification={dispatcher.addNotification}
            saveEmail={saveEmail}
            contact={contact}
            contactIndex={contactIndex}
            orgId={orgId}
            emailId={emailId}
            hideModal={dispatcher.hideModal}
            emailType={"questionnaire"}
          />
        )
      );
    },
    updateQuestionnaire: (data, eventId, questionnaireId) => {
      dispatch(updateQuestionnaire(data, eventId, questionnaireId));
    },
    createQuestionnaire: (eventId, data) => {
      return dispatch(createQuestionnaire(eventId, data)).then(() =>
        dispatcher.changeTab("Questionnaires", null)
      );
    },
    loadGroups: (eventId) => {
      return dispatch(listGroups(eventId));
    },
    getQuestionnaires: (eventId) => {
      return dispatch(getQuestionnaires(eventId));
    },
    sendQuestionnaire: (eventId, itemId) => {
      return dispatch(sendQuestionnaire(eventId, itemId)).then(() =>
        dispatch(addNotification("Questionnaire sent successfully", "success"))
      );
    },

    deleteQuestionnaire: (eventId, itemId) => {
      return dispatch(deleteQuestionnaire(eventId, itemId));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    hideModal: (promt) => {
      dispatch(hideModal(promt));
    },
    updateTitle: (eventId, itemId, data) => {
      dispatch(updateQuestionnaireTitle(eventId, itemId, data));
    },
    changeTab: (tab, fakeChild) => dispatch(changeTab(tab, fakeChild)),
  };
  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaires);
