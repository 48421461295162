import React from "react";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import SingleSponsorType from "./SingleSponsorType";
import Switch from "@layout/Switch";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  deleteClientPanelSponsorType,
  createClientPanelSponsorType,
  updateClientPanelSponsorsTypesOrder,
  updateClientPanelSettings,
  updateClientPanelSponsorsOrder,
} from "@actions";

const SponsorTypes = (props) => {
  const [state] = React.useState({
    items: [...props.items],
    showSponsorNames: props.showSponsorNames,
    sponsorsShowTypes:
      props.event.clientPanelSettings.sponsors.sponsorsShowTypes,
  });
  const onRender = (col, item) => {
    switch (col.key) {
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="sponsor type"
              item={item}
              onDelete={() => {
                return props.deleteClientPanelSponsorType(
                  props.event.id,
                  item.id
                );
              }}
              onEdit={() =>
                props.showModal(
                  <SingleSponsorType
                    item={item}
                    onSave={(data) => {
                      return props.updateClientPanelSettings(
                        props.event.id,
                        data,
                        "sponsors-types",
                        item.id
                      );
                    }}
                  />
                )
              }
            />
          </React.Fragment>
        );
      }
    }
  };
  return (
    <div className="row sponsors-types-container">
      <div>
        <div>
          <Table
            sortable={true}
            onSortEnd={(newIndex, item, newItems) => {
              const newSortList = newItems.map((sorted, index) => ({
                id: sorted.id,
                index,
              }));

              return props.updateClientPanelSponsorsTypesOrder(props.event.id, {
                sponsorTypes: newSortList,
              });
            }}
            columns={{
              name: {
                name: "Sponsor Type",
              },
              actions: {
                name: "",
              },
            }}
            updating={false}
            items={props.items}
            containerClassName={`table-container `}
            onRender={onRender}
          />
        </div>
        <div className="form-group">
          <div className="field-type type-switch kmb-switch clearfix ">
            <label>Display Sponsor Type Names</label>
            <div className="switch-holder">
              <Switch
                id={`display-sponsor-types`}
                isActive={state.sponsorsShowTypes}
                onChange={(active) => {
                  return props.updateClientPanelSponsorsOrder(props.event.id, {
                    sponsorsShowTypes: active,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SponsorTypes.propTypes = {
  items: PropTypes.array,
  showSponsorNames: PropTypes.bool,
  event: PropTypes.object,
  showModal: PropTypes.func,
  deleteClientPanelSponsorType: PropTypes.func,
  createClientPanelSponsorType: PropTypes.func,
  updateClientPanelSponsorsTypesOrder: PropTypes.func,
  updateClientPanelSettings: PropTypes.func,
  updateClientPanelSponsorsOrder: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteClientPanelSponsorType: (eventId, data) => {
      return dispatch(deleteClientPanelSponsorType(eventId, data));
    },
    createClientPanelSponsorType: (eventId, data) => {
      return dispatch(createClientPanelSponsorType(eventId, data));
    },
    updateClientPanelSponsorsTypesOrder: (eventId, data) => {
      return dispatch(updateClientPanelSponsorsTypesOrder(eventId, data));
    },
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },

    updateClientPanelSponsorsOrder: (eventId, data) => {
      return dispatch(updateClientPanelSponsorsOrder(eventId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorTypes);
