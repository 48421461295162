import React from "react";
import Select from "@layout/Select";
import PropTypes from "prop-types";

export default class QuickChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "add",
      customValue: 0,
    };

    [
      "swapMode",
      "getOptions",
      "renderModeHtml",
      "onRender",
      "onChange",
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));

    this.custom = null;
  }

  swapMode(mode) {
    this.setState({
      mode,
    });
  }

  renderModeHtml() {
    return (
      <ul className="time-mode" title="Change Mode">
        <li
          onClick={() => this.swapMode("add")}
          className={this.state.mode === "add" ? "selected" : ""}
        >
          Add
        </li>
        <li
          onClick={() => this.swapMode("subtract")}
          className={this.state.mode === "subtract" ? "selected" : ""}
        >
          Subtract
        </li>
      </ul>
    );
  }

  onChange(minutes) {
    if (minutes === "custom") {
      minutes = this.state.customValue;
      this.setState({
        customValue: 0,
      });
    }

    this.props.onChange({ mode: this.state.mode, minutes });
  }

  onRender(data) {
    if (data.key === "custom") {
      return (
        <span>
          {this.state.mode === "subtract" ? "-" : "+"}
          <input
            type="number"
            min={0}
            max={100}
            placeholder="Add minutes"
            defaultValue={this.state.customValue}
            onChange={(e) => {
              this.setState({
                customValue: parseInt(e.target.value),
              });
            }}
          />
        </span>
      );
    }
  }

  getOptions() {
    const options = {};
    let i = 5;
    while (i <= 60) {
      options[i] = `${this.state.mode === "subtract" ? "-" : "+"} ${i} minutes`;
      i = i + 5;
    }
    options["custom"] = 0;
    return options;
  }

  render() {
    return (
      <div className="quick-change">
        <Select
          placeholder={[
            <span key="placeholder">
              Quick Change<span className="icon-time"></span>
            </span>,
          ]}
          isDropDownButton={true}
          hasIndicator={false}
          placeholderInsideSelect={false}
          options={this.getOptions()}
          onRender={this.onRender}
          beforeRenderUl={this.renderModeHtml}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

QuickChange.propTypes = {
  onChange: PropTypes.func.isRequired,
};

QuickChange.defaultProps = {
  onChange: () => {},
};
