import { api } from "@actions/ajax";
import { addNotification } from "@actions";

export const getQuestionnaires = (eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "questionnaire",
      action: "list",
      params: { eventId },
      query: `?orderBy=id&order=DESC&rpp=-1`,
    })
  );
export const sendQuestionnaire = (eventId, itemId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "questionnaire",
      action: "send",
      params: { eventId, itemId },
      body: {
        data: {},
      },
    })
  );
export const updateQuestionnaireTitle = (eventId, itemId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "questionnaire",
      action: "update",
      params: { eventId, itemId },
      body: {
        data,
      },
    })
  ).then(() => dispatch(getQuestionnaires(eventId)));

export const deleteQuestionnaire = (eventId, itemId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "questionnaire",
      action: "delete",
      params: { eventId, itemId },
    })
  )
    .then(() =>
      dispatch(addNotification("Questionnaire deleted successfully", "success"))
    )
    .then(() => dispatch(getQuestionnaires(eventId)));

export const createQuestionnaire =
  (eventId, data, itemId, editMode) => (dispatch) =>
    dispatch(
      api({
        endpoint: "questionnaire",
        action: editMode ? "update" : "create",
        params: editMode ? { eventId, itemId } : { eventId },
        body: {
          data,
        },
      })
    ).then(() => dispatch(getQuestionnaires(eventId)));

export const deleteContact = (contacts) => (dispatch) => {
  dispatch({ type: "DELETE_CONTACT_QUESTIONNAIRE", contacts });
};

export const updateQuestionnaire = (data, eventId, itemId) => (dispatch) => {
  dispatch(
    api({
      endpoint: "questionnaire",
      action: "update",
      params: { eventId, itemId },
      body: {
        data,
      },
    })
  ).then(() => dispatch(getQuestionnaires(eventId)));
};
export const createCustomQuestionnaireVars =
  (contacts, eventId) => (dispatch) =>
    dispatch(
      api({
        endpoint: "questionnaire",
        action: "customContacts",
        params: { eventId },
        body: {
          data: {
            contacts: [contacts[1]],
          },
        },
      })
    );
export const editQuestionContact = (contact, eventId, key, mode) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        api({
          endpoint: "presetEmails",
          action: "customContacts",
          params: { eventId },
          body: {
            data: {
              contacts: [contact],
            },
          },
        })
      ).then((response) => {
        dispatch({ type: "EDIT_CONTACT", response, key, mode });
      });
      resolve(true);
    });
  };
};
