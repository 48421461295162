import React from "react";
import { connect } from "react-redux";
import {
  loadRooms,
  editRoom,
  saveRooms,
  allOut,
  getMassiveEvents,
  undoAllOut,
} from "@actions/users";
import { showModal, hideModal, addNotification, deleteItem } from "@actions";
import { uploadHitsToServer } from "@actions/appuser";
import Rooms from "./Rooms";
import SingleRoom from "./partials/SingleRoom";
import UndoMassiveEvents from "./partials/UndoMassiveEvents/";
import UploadHits from "./partials/UploadHits";
import AllOutPast from "./partials/AllOutPast";

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.event.id,
    rooms: state.rooms.data,
    fetching: state.rooms.fetching,
    massiveEvents: state.rooms.massiveEvents,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadRooms: (eventId) => {
      dispatch(loadRooms(eventId));
    },
    roomModal: (eventId, room = false) => {
      dispatch(
        showModal(
          <SingleRoom
            addNotification={dispatcher.addNotification}
            saveRooms={dispatcher.saveRooms}
            room={room}
            eventId={eventId}
            hideModal={dispatcher.hideModal}
            editRoom={dispatcher.editRoom}
          />
        )
      );
    },
    undoModal: (eventId) => {
      dispatch(
        showModal(
          <UndoMassiveEvents
            eventId={eventId}
            undoAllOut={dispatcher.undoAllOut}
            hideModal={dispatcher.hideModal}
          />,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    uploadFile: (eventId, file) => {
      return dispatch(uploadHitsToServer(eventId, file)).then(() => {
        dispatch(addNotification("The file has been uploaded", "success"));
      });
    },
    hitsModal: (eventId) => {
      dispatch(
        showModal(
          <UploadHits
            addNotification={dispatcher.addNotification}
            uploadFile={dispatcher.uploadFile}
            eventId={eventId}
            hideModal={dispatcher.hideModal}
          />
        )
      );
    },
    editRoom: (eventId, roomId, data) => {
      dispatch(editRoom(eventId, roomId, data));
    },
    saveRooms: (eventId, rooms, capacity) => {
      dispatch(saveRooms(eventId, rooms, undefined, capacity)).then(() =>
        dispatch(hideModal())
      );
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    allOut: (eventId, roomId) => {
      dispatch(allOut(eventId, roomId));
    },
    getMassiveEvents: (eventId) => {
      dispatch(getMassiveEvents(eventId));
    },
    deleteItem: (params, endpoint) => {
      dispatch(deleteItem(params, endpoint));
    },
    undoAllOut: (eventId, allOutId, roomId) => {
      dispatch(undoAllOut(eventId, allOutId, roomId));
    },
    allOutModal: () =>
      dispatch(showModal(<AllOutPast hideModal={dispatcher.hideModal} />)),
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
