import React from "react";
import ReactDataGrid from "react-data-grid";
import columns from "@helpers/excel-helper";
import { ExcelActions } from "@helpers/excelComponents";

import PropTypes from "prop-types";

export default class KMBExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [],
      add: false,
      fullscreen: false,
      height: window.innerHeight - 136,
      normalHeight:
        window.document.querySelector(".tab-content").offsetHeight - 124,
      showConfirm: false,
      showConfirmWord: false,
    };
    this.fullscreenListener = this.fullscreenListener.bind(this);
    this.timeout = null;
  }
  fullscreenListener() {
    window.clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        height: window.innerHeight - 136,
        normalHeight:
          window.document.querySelector(".tab-content").offsetHeight - 124,
      });
    }, 300);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fullscreenListener);
  }
  componentDidMount() {
    window.addEventListener("resize", this.fullscreenListener);
    const excelItems = [];
    const newColumns = columns[this.props.type];
    if (newColumns.actions) {
      newColumns.actions.formatter = (value) => {
        return (
          <ExcelActions
            confirmDeleteText={this.props.confirmDeleteText}
            onDelete={this.props.onDeleteRow}
            onEnter={this.props.onEnterRow}
            value={value}
            onSave={value.row.new ? this.props.onSaveRow : undefined}
          />
        );
      };
    }
    const excelCol = columns.toArray(this.props.type, this.props.options);

    this.props.items.map((item) => {
      const row = {};
      Object.keys(item).map((key) => {
        if (key in newColumns) {
          if (newColumns[key].valueFormatter) {
            row[key] = newColumns[key].valueFormatter(null, item[key]);
          } else {
            row[key] = item[key];
          }
        }
      });

      excelItems.push(row);
    });

    this.setState({ rows: excelItems, columns: excelCol });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const excelItems = [];
    const newColumns = columns[nextProps.type];
    if (newColumns.actions) {
      newColumns.actions.formatter = (value) => {
        return (
          <ExcelActions
            confirmDeleteText={this.props.confirmDeleteText}
            onDelete={this.props.onDeleteRow}
            onEnter={this.props.onEnterRow}
            value={value}
            onSave={value.row.new ? this.props.onSaveRow : undefined}
          />
        );
      };
    }
    const excelCol = columns.toArray(nextProps.type, nextProps.options);
    nextProps.items.map((item) => {
      const row = {};
      Object.keys(item).map((key) => {
        if (key in newColumns) {
          if (newColumns[key].valueFormatter) {
            row[key] = newColumns[key].valueFormatter(null, item[key]);
          } else {
            row[key] = item[key];
          }
        }
      });

      excelItems.push(row);
    });
    if (this.props.type === nextProps.type) {
      this.state.rows.map((row) => {
        if (String(row.id).includes("new") && row.id !== nextProps.meta?.temp) {
          excelItems.push(row);
        }
      });
    }
    this.setState({ rows: excelItems, columns: excelCol });
  }
  addRow() {
    const newRow = {};

    this.state.columns.map((column) => {
      newRow[column.key] = column.default && column.default(this.props.event);
    });
    newRow["id"] = this.state.rows.length + 1 + "-new";
    newRow["new"] = true;
    const newRows = [...this.state.rows, newRow];

    this.setState({ rows: newRows, add: true });
  }
  onUpdate(update) {
    const onChange = columns[this.props.type][update.cellKey].onChange;
    if (onChange) {
      const newRows = onChange(this.state.rows, update, this.props.options);

      if (newRows !== false && update.fromRowData.new) {
        this.setState({
          rows: newRows,
        });
      } else if (newRows) {
        this.setState(
          {
            rows: newRows,
          },
          () => {
            if (!String(update.toRowId).includes("new")) {
              this.props.onChange(update);
            }
          }
        );
      }
    } else {
      const { updated, cellKey, toRowId } = update;
      const newRows = this.state.rows.map((row) => {
        const newRow = { ...row };
        if (row.id === toRowId) {
          newRow[cellKey] = updated[cellKey];
        }
        return newRow;
      });
      if (JSON.stringify(newRows) === JSON.stringify(this.state.rows)) {
        return false;
      }
      if (update.fromRowData.new) {
        this.setState({ rows: newRows });
      } else {
        this.setState({ rows: newRows }, () => {
          if (!String(update.toRowId).includes("new")) {
            this.props.onChange(update);
          }
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={
            this.state.fullscreen
              ? {
                  position: "sticky",
                  width: "100%",
                  height: "100%",
                  top: "56px",
                  left: "0px",
                  zIndex: 2,
                }
              : {}
          }
          className="kmb-datagrid"
        >
          <div className="kmb-datagrid-toolbar">
            <div className="col-sm-12 text-left">
              {Object.keys(this.props.toolbarTop.custom).map(
                (key) => this.props.toolbarTop.custom[key]
              )}
            </div>
            <div className="col-sm-12  text-right">
              {/* {this.props.toolbarTop.fullscreen && (
                <span
                  className="kmb-datagrid-toolbar-item selectable"
                  onClick={() => {
                    this.setState(
                      { fullscreen: !this.state.fullscreen },
                      () => {
                        window.document.body.style.overflow = this.state
                          .fullscreen
                          ? "hidden"
                          : "auto";
                        window.dispatchEvent(new Event("resize"));
                      }
                    );
                  }}
                >
                  <i className="fas fa-expand"></i>
                </span>
              )} */}
            </div>
          </div>
          <div key={this.state.fullscreen.toString()}>
            <ReactDataGrid
              minHeight={
                this.state.fullscreen
                  ? this.state.height
                  : this.state.normalHeight
              }
              columns={this.state.columns}
              rowGetter={(i) => this.state.rows[i]}
              rowsCount={this.state.rows.length}
              enableCellSelect={true}
              onGridRowsUpdated={(from) => this.onUpdate(from)}
            />
          </div>
          <div className="kmb-datagrid-toolbar">
            <div
              className="kmb-datagrid-toolbar-item text selectable"
              onClick={() => this.addRow()}
            >
              <i className="far fa-plus-square"></i>
              <span>Add Row</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
KMBExcel.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.any.isRequired,
  eventId: PropTypes.number.isRequired,
  event: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.any,
  onSaveRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onEnterRow: PropTypes.func,
  toolbarTop: PropTypes.object,
  confirmDeleteText: PropTypes.func,
  meta: PropTypes.object,
};
KMBExcel.defaultProps = {
  options: {},
  toolbarTop: false,
  toolbarBottom: false,
};
