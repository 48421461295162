import React from "react";
import moment from "moment";
import ApiHelper from "@helpers/api-helper";
import Confirm from "@layout/Confirm";
import PropTypes from "prop-types";

const UndoMassiveEvents = (props) => {
  const events = props.massiveEvents;

  return (
    <div className="med-table-container table-container  undo-massive">
      <h2>Undo All out</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Time</th>
            <th>Room</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {events.map((item, index) => {
            const undoBody = (
              <div>
                <p>
                  This action will permanently undo the allout, performed at{" "}
                  {moment(item.createdAt).format(ApiHelper.dateFormat)}, from
                  room with ID: <strong>{item.roomId}</strong>
                </p>
                <p>Are you sure that you want to do this?</p>
              </div>
            );

            return (
              <tr key={`massive-event-${index}`}>
                <td>{item.type === "allout" ? "All Out" : "Unknown event"}</td>
                <td>
                  {moment(item.createdAt.tz).format(ApiHelper.dateFormat)}
                </td>
                <td>{item.eventRoomName}</td>
                <td>
                  <Confirm
                    onConfirm={() =>
                      props.undoAllOut(props.eventId, item.id, item.eventRoomId)
                    }
                    body={undoBody}
                    className="delete-event"
                    confirmText="Confirm Undo"
                    disabled={false}
                    title="Undo All Out Action! Are you sure?"
                  >
                    <button className="btn transparent">Undo</button>
                  </Confirm>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

UndoMassiveEvents.propTypes = {
  massiveEvents: PropTypes.array.isRequired,
  undoAllOut: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default UndoMassiveEvents;
