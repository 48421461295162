import {
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  CHANGE_PAGE,
  ITEM_DELETED,
  DYMO_FRAMEWORK_ERROR,
} from "@actions";
import { UNDO_ALL_OUT } from "@actions/users";
import { FAILED_REQUEST } from "@actions/ajax";
let key = 0;
const dismissAfter = 8500;

const notifications = (state = [], action) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return [];

    case UNDO_ALL_OUT: {
      key++;
      return [
        ...state,
        {
          key,
          message: action.message,
          className: `alert alert-success`,
          dismissAfter,
          style: false,
        },
      ];
    }

    case FAILED_REQUEST: {
      key++;

      if (
        action.endpoint === "rooms" &&
        action.actionKey === "scan" &&
        action.status === 404
      ) {
        return state;
      }

      return [
        ...state,
        {
          key,
          message: action.errorMessage,
          className: `alert alert-error`,
          dismissAfter,
          style: false,
        },
      ];
    }

    case ITEM_DELETED:
      key++;

      return [
        ...state,
        {
          key,
          message: `${action.itemName} deleted successfully.`,
          className: `alert alert-success`,
          dismissAfter,
          style: false,
        },
      ];

    case DYMO_FRAMEWORK_ERROR:
      key++;

      return [
        ...state,
        {
          key,
          message: `${action.error}`,
          className: `alert alert-error`,
          dismissAfter,
          style: false,
        },
      ];

    case ADD_NOTIFICATION:
      key++;

      return [
        ...state,
        {
          key,
          message: action.message,
          className: `alert alert-${action.status}`,
          dismissAfter,
          style: false,
        },
      ];

    case DISMISS_NOTIFICATION:
      return [...state].filter((n) => {
        return n.key !== action.notification.key;
      });

    default:
      return state;
  }
};

export default notifications;
