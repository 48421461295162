import React from "react";
export default () => {
  return (
    <div className="container event-loader" style={{ height: "100%" }}>
      <div className="spinner-wrapper">
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
      <div className="wrapper">
        <div className="header"></div>
        <div className="main">
          <div className="left"></div>
          <div className="right">
            <div className="top"></div>
            <div className="bottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
