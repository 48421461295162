import { connect } from "react-redux";
import Printers from "./Printers";
import { selectPrinter, setupPrinters, changePrinterType } from "@actions";

const mapStateToProps = (state) => {
  return {
    printers: state.page.printers.availablePrinters,
    selectedPrinter: state.page.printers.selectedPrinter,
    ready: state.page.printers.ready,
    printerType: state.page.printers.printerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectPrinter: (printer, notFound = false) => {
      dispatch(selectPrinter(printer, notFound));
    },
    setupPrinters: () => {
      dispatch(setupPrinters())
        .then(() => {})
        .catch((err) => console.warn(err));
    },
    changePrinterType: (type) => {
      dispatch(changePrinterType(type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Printers);
