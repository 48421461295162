import React from "react";
import PropTypes from "prop-types";

export default class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.toggleActive = this.toggleActive.bind(this);
    this.state = {
      isActive: props.isActive && !isNaN(props.isActive) ? props.isActive : 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLive && nextProps.isActive !== this.state.isActive) {
      this.setState({
        isActive: nextProps.isActive,
      });
    }
  }

  toggleActive() {
    this.setState(
      (prevState) => ({ isActive: Number(!prevState.isActive) }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(Number(this.state.isActive));
        }
        if (this.props.callback) {
          this.props.callback(Number(this.state.isActive));
        }
      }
    );
  }

  render() {
    return (
      <div
        className={`switch-toggle-container ${
          this.state.isActive ? "active" : ""
        } ${this.props.isDisabled ? "disabled" : ""}`}
        onClick={() => {
          if (!this.props.isDisabled) {
            this.toggleActive();
          }
        }}
      >
        <div className={`switch-toggle-handle`}></div>
      </div>
    );
  }
}

Switch.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  callback: PropTypes.func,
  isLive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Switch.defaultProps = {
  isActive: false,
  isLive: false,
  isDisabled: false,
  onChange: () => {},
  callback: () => {},
};
