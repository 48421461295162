import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect } from "react-redux";
import { loadData, deleteData, loadPrices } from "@actions/products";
import TableActions from "@layout/TableActions";
import KMBLoader from "@layout/KMBLoader";
import { showModal } from "@actions";
import moment from "moment";
import ApiHelper from "@helpers/api-helper";
import SingleData from "./partials/SingleData";
import SinglePayment from "./partials/SinglePayment";
import { changeSection } from "@actions/editmenu";
import Breadcrumbs from "@layout/Breadcrumbs";
import Search from "@layout/Search";
import { HeaderPagination } from "../../../../layout/Pagination";

class Data extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      viewData: false,
      viewPrices: false,
      productId: null,
      targetUser: 0,
      search: "",
    };
  }
  componentDidMount() {
    this.props.loadData(this.props.eventId, this.props.productId);
    this.props.loadPrices(this.props.eventId, this.props.productId);
  }
  goBack() {
    this.setState({ viewData: false, viewPrices: false });
  }
  onRender(col, item) {
    switch (col.key) {
      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "updatedAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "totalPrice": {
        return col.value.toFixed(2);
      }
      case "payment": {
        const disabled = false;
        let color = "";
        let className = "icon-notpaid";
        let info = "Not Paid";
        let title = "";
        const colorGreen = "#14da9e";
        const colorBlue = "#3C96FF";
        const colorRed = "#FF8100";

        const hasPayment = Object.keys(item.payments).length;
        const hasSponsorPayment = Object.keys(item.sponsorships).length;
        if (item.totalPrice === 0 && item.dueAmout === 0) {
          color = hasSponsorPayment ? colorBlue : colorGreen;
          info = "Free";
          title = "Free (no product fees)";
          className = "icon-paid";
        } else if (item.dueAmount > 0) {
          if (hasSponsorPayment) {
            color = colorBlue;
            info = "Partial";
            className = "icon-partial";
            title = "Partially paid by Sponsor";
          } else if (hasPayment) {
            color = colorGreen;
            info = "Partial";
            className = "icon-partial";
            title = "Partially paid by the Participant";
          } else {
            color = hasSponsorPayment ? colorBlue : colorRed;
            info = "Not Paid";
            className = "icon-notpaid";
            title = "Not Paid";
          }
        } else if (item.dueAmount < 0) {
          className = "icon-refund";
          if (hasSponsorPayment) {
            color = colorBlue;
            info = "Refund";
            title = "Refund the Sponsor";
          } else {
            color = colorGreen;
            info = "Refund";
            title = "Refund the Participant";
          }
        } else {
          if (hasSponsorPayment) {
            /* paid by sponsor*/
            className = "icon-paid";
            color = colorBlue;
            info = "Paid";
            title = "Paid by the Sponsor";
          } else {
            /* paid by user*/

            className = "icon-paid";
            color = colorGreen;
            info = "Paid";
            title = "Paid by the Participant";
          }
        }
        return (
          <button
            title={title}
            style={{ minWidth: 129 }}
            className="btn transparent table-button-link"
            disabled={disabled}
            onClick={() => {
              this.props.showModal(<SinglePayment item={item} />);
            }}
          >
            <span className={className} style={{ color, fontSize: 13 }}></span>
            {info}
          </button>
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onDelete={() =>
              this.props.deleteData(
                this.props.eventId,
                this.props.productId,
                item.id
              )
            }
            onEdit={() =>
              this.props.showModal(
                <SingleData
                  h2={"Edit"}
                  eventId={this.props.eventId}
                  productId={this.props.productId}
                  targetUser={this.props.targetUser}
                  item={item}
                />
              )
            }
          />
        );
      }
    }
  }
  render() {
    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Search
              placeholder="Search participant..."
              onSearch={(search) => {
                this.setState({ search });
                this.props.loadData(this.props.eventId, this.props.productId, {
                  search,
                  rpp: 20,
                  p: 1,
                });
              }}
              visible={true}
              timeout={400}
            />
          </div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={this.props.p}
              rpp={this.props.rpp}
              totalRows={this.props.totalRows}
              onChangePage={(data) => {
                this.props.loadData(this.props.eventId, this.props.productId, {
                  search: this.state.search || "",
                  p: data.p,
                  rpp: data.rpp,
                });
              }}
            />
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleData
                    h2={"Create"}
                    eventId={this.props.eventId}
                    productId={this.props.productId}
                    targetUser={this.props.targetUser}
                  />
                )
              }
            >
              Create
            </button>
          </div>
        </div>

        {!this.props.dataReady ? (
          <KMBLoader rows={15} padding={24} height={53} />
        ) : (
          <div className="tab-content">
            <Breadcrumbs
              options={[
                {
                  name: "Products List",
                  action: () => this.props.changeSection("products"),
                },
                {
                  name: this.props.productName,
                },
                {
                  name: "Purchases",
                },
              ]}
            />

            <Table
              columns={{
                id: {
                  name: "ID",
                },
                eventUserId: {
                  name: "Participant ID",
                },
                eventUserFullName: {
                  name: "Participant Name",
                },
                quantity: {
                  name: "Quantity",
                },
                eventProductPriceName: {
                  name: "Product Price Name",
                },
                totalPrice: {
                  name: "Total Price",
                },
                createdBy: {
                  name: "Created By",
                },
                createdAt: {
                  name: "Created At",
                },
                payment: {
                  name: "Payment",
                },
                actions: {
                  name: "",
                },
              }}
              filtered={this.state.search}
              updating={false}
              items={this.props.data}
              onRender={this.onRender}
            />
          </div>
        )}
      </>
    );
  }
}
Data.propTypes = {
  eventId: PropTypes.number,
  loadData: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  dataReady: PropTypes.bool,
  data: PropTypes.array,
  showModal: PropTypes.func,
  deleteData: PropTypes.func,
  goBack: PropTypes.func,
  targetUser: PropTypes.number,
  loadPrices: PropTypes.func,
  productName: PropTypes.string,
  changeSection: PropTypes.func,
  fetching: PropTypes.bool,
  p: PropTypes.number,
  rpp: PropTypes.number,
  totalRows: PropTypes.number,
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadData: (eventId, productId, meta) => {
      return dispatch(loadData(eventId, productId, meta));
    },
    loadPrices: (eventId, productId) => {
      return dispatch(loadPrices(eventId, productId));
    },
    changeSection: (section) => dispatch(changeSection(section)),
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    deleteData: (eventId, productId, dataId) => {
      dispatch(deleteData(eventId, productId, dataId));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    globalVars: state.api.events.presetEmails.meta.available_vars,
    tempContacts: state.users.tempContacts,
    data: state.api.products.listData.data,
    dataReady: state.api.products.listData.ready,
    p: state.api.products.listData.meta.p,
    rpp: state.api.products.listData.meta.rpp,
    totalRows: state.api.products.listData.meta.totalRows,
    fetching: state.api.products.listData.meta.fetching,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Data);
