import { connect } from "react-redux";
import AccessPolicies from "./AccessPolicies";
import { showModal, hideModal, savePolicy } from "@actions";
import { deleteItem } from "../../../../../../actions";
const mapStateToProps = (state) => {
  return {
    policies: state.api.accesspolicies.list.data,
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content) => dispatch(showModal(content)),
    hideModal: () => dispatch(hideModal()),
    savePolicy: (eventId, data, policyId) =>
      dispatch(savePolicy(eventId, data, policyId)),
    deleteItem: (data, endpoint) => dispatch(deleteItem(data, endpoint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessPolicies);
