import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { updateEvent } from "@actions";
import { connect } from "react-redux";

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
    };
  }

  render() {
    return (
      <div className="form-container tab-content">
        <Form
          componentName="sessions"
          formName="scheduleSettings"
          onSubmit={(data) => {
            this.props.updateEvent(parseInt(this.props.eventId), data.value);
          }}
          onCancel={() => {
            this.props.hideModal();
          }}
          value={this.props.scheduleSettings}
          onChange={(data) => {
            if (data.key === "scheduleFile") {
              this.props.updateEvent(parseInt(this.props.eventId), {
                scheduleFile: [data.value.id],
              });
            }
          }}
        />
      </div>
    );
  }
}

Settings.propTypes = {
  updateEvent: PropTypes.func,
  eventId: PropTypes.string.isRequired,
  scheduleSettings: PropTypes.object,
  hideModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    scheduleSettings: state.api.events.edit.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateEvent: (eventId, data) => {
      dispatch(updateEvent(eventId, data));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
