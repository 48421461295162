import { connect } from "react-redux";
import SingleSpeech from "./SingleSpeech";
import { hideModal } from "@actions";
import { saveSpeech, savePerson } from "@actions/sessions";
import { loadSeachUsers } from "@actions/users";

const mapStateToProps = (state, ownProps) => {
  const session =
    [...state.api.sessions.list.data].filter(
      (s) => s.id === ownProps.sessionId
    )[0] || {};
  const speech =
    [...(session.speeches || [])].filter(
      (sp) => sp.id === ownProps.speechId
    )[0] || {};

  const latestSpeechEndDate =
    session.speeches && session.speeches.length > 0
      ? session.speeches[session.speeches.length - 1].endDate
      : session.startDate;

  return {
    policyIds: state.api.accesspolicies.list.data
      .filter((p) => p.type !== "public")
      .map((p) => p.id),
    speakers: state.api.persons.list.data,
    session,
    organisationId: state.api.events.edit.data.organisation.id,
    eventId: state.page.params.eventId,
    sessionId: ownProps.sessionId,
    speech: speech,
    crmUsers: state.users.data,
    allPolicyUsers: state.users.allPolicyUsers.data,
    abstracts: state.api.abstracts.list.data,
    latestSpeechEndDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    hideModal: () => {
      dispatch(hideModal());
    },

    saveSpeech: (
      eventId,
      sessionId,
      data,
      speechId = false,
      type,
      deleteFile = false,
      quickEdit,
      meta
    ) => {
      return dispatch(
        saveSpeech(
          eventId,
          sessionId,
          data,
          speechId,
          type,
          deleteFile,
          quickEdit,
          meta
        )
      );
    },

    savePerson: (orgId, data, personId, contentType = "json") => {
      return new Promise((resolve, reject) => {
        if (data.mode === "new") {
          const promises = [];

          data.value.map((v) => {
            const p = new Promise((resolve, reject) => {
              dispatch(savePerson(orgId, v, false, contentType))
                .then((pid) => {
                  return resolve(pid);
                })
                .catch((err) => {
                  reject(err);
                });
            });

            promises.push(p);
          });

          Promise.all(promises)
            .then((values) => {
              const response = [];
              values.map((v) => {
                response.push(v.data.id);
              });
              return resolve(response);
            })
            .catch((err) => {
              return reject(err);
            });
        } else {
          return resolve(data.value);
        }
      });
    },

    searchUsers: (queryString, eventId, policyIds, searchMode) => {
      return dispatch(
        loadSeachUsers(eventId, null, {
          search: queryString,
          policyIds: policyIds.join(","),
          speaker: searchMode,
        })
      );
    },
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSpeech);
