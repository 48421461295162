import React from "react";
import PropTypes from "prop-types";
import ExhibitorEditableItem from "@layout/ExhibitorEditableItem";

const ExhibitorPageHeader = (props) => {
  return (
    <ExhibitorEditableItem
      showDelete={false}
      showAddRow={false}
      onEdit={() => props.onEdit("exhibitorPageBackground")}
    >
      <div className="exhibitor-page-header col-sm-12">
        <img className="background" src={props.backgroundUrl} />
        <ExhibitorEditableItem
          showDelete={false}
          showAddRow={false}
          onEdit={() => {
            props.onEdit("exhibitorPageLogo");
          }}
        >
          {props.logoUrl?.length ? (
            <img className="logo" src={props.logoUrl} />
          ) : (
            <p className="logo">LOGO</p>
          )}
        </ExhibitorEditableItem>
      </div>
    </ExhibitorEditableItem>
  );
};

ExhibitorPageHeader.defaultProps = {
  onEdit: () => {},
};
ExhibitorPageHeader.propTypes = {
  backgroundUrl: PropTypes.string,
  logoUrl: PropTypes.string,
  onEdit: PropTypes.func,
};
export default ExhibitorPageHeader;
