/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import ListEvents from "@components/events/list/ListEvents";

export default class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.fetched = false;
    this.getEventRoles = this.getEventRoles.bind(this);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    this.props.getRoleTypes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.orgId &&
      nextProps.personId &&
      this.props.person?.id != nextProps.personId &&
      !this.state.initialized
    ) {
      this.props.getPerson(nextProps.orgId, nextProps.personId);
      this.setState({ initialized: true });
    }
  }

  getEventRoles(params = false, filters) {
    this.props.getEventRoles(this.props.orgId, this.props.personId, {
      ...params,
      ...filters,
    });
  }

  render() {
    const {
      events,
      organisations,
      fetching,
      meta,
      criteria,
      router,
      roleTypes,
      personFetching,
    } = this.props;

    if (!this.props.orgId) {
      return null;
    }
    return (
      <div className="list-events">
        <ListEvents
          data={{ events, meta, fetching, criteria }}
          loadEvents={(criteria, filters) =>
            this.getEventRoles(criteria, filters)
          }
          events={events}
          organisations={organisations}
          meta={meta}
          fetching={fetching}
          personFetching={personFetching}
          criteria={criteria}
          search={() => {}}
          sortEvents={() => {}}
          showModal={() => {}}
          hideModal={() => {}}
          toggleIsActive={() => {}}
          deleteItem={() => {}}
          page={this.props.page}
          roleMode={true}
          changePage={this.props.changePage}
          roleTypes={roleTypes}
          changeRole={this.props.changeRole}
          person={this.props.person}
          personId={this.props.personId}
          filtered={this.props.filtered}
          loadPolicies={this.props.loadPolicies}
        />
      </div>
    );
  }
}

Roles.propTypes = {
  getEventRoles: PropTypes.func.isRequired,
  getRoleTypes: PropTypes.func.isRequired,
  orgId: PropTypes.number,
  personId: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  organisations: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  criteria: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  roleTypes: PropTypes.object.isRequired,
  changeRole: PropTypes.func.isRequired,
  getPerson: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
  filtered: PropTypes.bool.isRequired,
  personFetching: PropTypes.bool.isRequired,
  loadPolicies: PropTypes.func.isRequired,
};
