import React from "react";
import PropTypes from "prop-types";

const ExhibitorPageHeader = (props) => {
  const [state, setState] = React.useState({
    active: false,
    confirmDelete: false,
  });
  const el = React.useRef();
  const addListener = () =>
    document.body.addEventListener("click", listenerHandler, {
      once: true,
    });
  const listenerHandler = (e) => {
    if (e.target == el.current) return;
    if (!el.current.contains(e.target)) {
      setState({ ...state, active: false });
    }
  };

  return (
    <div className={`exhibitor-editable-item ${props.className || ""}`}>
      {props.showEdit && (
        <div
          title="Edit"
          className="editable-buttons edit-btn"
          style={!props.showDelete ? { right: 10 } : {}}
          onClick={props.onEdit}
        >
          <span className="icon-edit"></span>
        </div>
      )}
      {props.showDelete && (
        <div className="editable-buttons delete-btn" title="Delete">
          <span className="icon-delete"></span>
        </div>
      )}
      {props.showAddRow && (
        <div
          ref={(element) => (el.current = element)}
          className={`editable-buttons add-row-btn ${
            state.active ? " active" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setState({ ...state, active: !state.active, confirmDelete: false });
            addListener();
          }}
        >
          <span className="add-row fas fa-ellipsis-h"></span>
          <div className="options">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                className={`option ${props.disableUp ? "disabled" : ""}`}
                onClick={() => {
                  props.moveUp();
                }}
              >
                Move Up
              </div>
              <div
                className={`option ${props.disableDown ? "disabled" : ""}`}
                onClick={() => props.moveDown()}
              >
                Move Down
              </div>
              <div
                className={`option ${props.slotsLegth == 1 ? "disabled" : ""}`}
                onClick={() => props.mirror()}
              >
                Mirror
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              Row Height
              <div
                className={`option`}
                style={{
                  fontWeight: props.selectedRowHeight == 1 ? "bold" : "normal",
                }}
                onClick={() => {
                  props.changeRowHeight(1);
                }}
              >
                1
              </div>
              <div
                style={{
                  fontWeight: props.selectedRowHeight == 2 ? "bold" : "normal",
                }}
                className={`option`}
                onClick={() => {
                  props.changeRowHeight(2);
                }}
              >
                2
              </div>
            </div>
            <div
              className="option"
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, active: false });

                props.onAddRow(1);
              }}
            >
              Add One Column Below
            </div>
            <div
              className="option"
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, active: false });
                props.onAddRow(2);
              }}
            >
              Add Two Columns Below
            </div>
            <div
              style={{ display: "flex", color: "var(--error-color)" }}
              className={`option ${props.disableDelete ? "disabled" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                if (state.confirmDelete) {
                  setState({ ...state, confirmDelete: false, active: false });
                  props.onDelete();
                } else {
                  addListener();
                  setState({ ...state, confirmDelete: true });
                }
              }}
            >
              {state.confirmDelete && (
                <div style={{ flex: 1 }}>Confirm Delete</div>
              )}
              {!state.confirmDelete && (
                <>
                  <div style={{ flex: 1 }}>Delete this Row</div>
                  <span style={{ width: 30 }} className="icon-delete"></span>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {props.children}
    </div>
  );
};

ExhibitorPageHeader.defaultProps = {
  showDelete: true,
  showAddRow: true,
  showEdit: true,
  onEdit: () => {},
};
ExhibitorPageHeader.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onAddRow: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showAddRow: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  index: PropTypes.number,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
  slotsLegth: PropTypes.number,
  selectedRowHeight: PropTypes.number,
  disableDelete: PropTypes.bool,
  mirror: PropTypes.func,
  changeRowHeight: PropTypes.func,
};
export default ExhibitorPageHeader;
