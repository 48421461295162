import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Modal from "@layout/Modal";

export default class Confirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: this.props.visible ? true : false,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
    this.props.mounted();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty("visible")) {
      this.setState({
        isOpened: nextProps.visible,
      });
    }
  }

  handleKeyPress(e) {
    if (!this.state.isOpened) {
      return;
    }

    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      if (this.props.disabled === false) {
        this.onConfirm();
      }
    }
  }

  onButtonClick(e) {
    e.stopPropagation();
    this.setState({ isOpened: true }, () => {
      this.props.onShow();
    });
  }

  onClose() {
    this.setState({ isOpened: false }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  }

  onConfirm() {
    this.setState({ isOpened: false });
    this.props.onConfirm();
  }

  render() {
    const footer = (
      <div>
        <Button
          type="button"
          bsStyle={this.props.confirmBSStyle}
          onClick={this.onConfirm}
          disabled={this.props.disabled}
        >
          {this.props.confirmText}
        </Button>
      </div>
    );

    return (
      <div style={this.props.style}>
        {React.Children.map(this.props.children, (child) =>
          React.cloneElement(child, {
            onClick: this.onButtonClick,
          })
        )}
        <>
          {this.state.isOpened ? (
            <Modal
              show={this.state.isOpened}
              className={this.props.className}
              title={this.props.title}
              footer={footer}
              onClose={this.onClose}
            >
              {this.props.body}
            </Modal>
          ) : null}
        </>
      </div>
    );
  }
}

Confirm.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Confirm",
  confirmBSStyle: "danger",
  mounted: () => {},
  onShow: () => {},
  className: "",
  disabled: false,
  style: {},
};

Confirm.propTypes = {
  visible: PropTypes.bool,
  mounted: PropTypes.func,
  disabled: PropTypes.bool,
  onShow: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmBSStyle: PropTypes.string,
  confirmText: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.element,
  style: PropTypes.object,
};
