import React from "react";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import moment from "moment";
import { isEmpty } from "@helpers";

const Peak = (props) => {
  if (props.fetching) {
    return <KMBLoader rows="20" padding={24} height={53} />;
  }
  return (
    <div className="peak tab-content form-container">
      <div className="stats form-group">
        <div className="box">
          <div className="inner-box">
            <div className="upper">
              <span className="icon-date"></span>
              {(props.peak.date || {}).tz
                ? moment(props.peak.date.tz).format("DD MMM")
                : "N/A"}
            </div>
            <p>Date</p>
          </div>
        </div>
        <div className="box">
          <div className="inner-box">
            <div className="upper">
              <span className="icon-total-rooms"></span>
              {props.peak.roomName ? props.peak.roomName : "N/A"}
            </div>
            <p>Room</p>
          </div>
        </div>
        <div className="box">
          <div className="inner-box">
            <div className="upper">
              <span className="icon-register-participants"></span>
              <CountUp start={0} end={props.peak.users} duration={0.5} />
            </div>
            <p>Participants</p>
          </div>
        </div>
        <div className="box">
          <div className="inner-box">
            <div className="upper">
              <span className="icon-schedule"></span>
              {isEmpty(props.peak.session) ? "N/A" : props.peak.session.name}
            </div>
            <p>Session</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Peak.propTypes = {
  fetching: PropTypes.bool.isRequired,
  peak: PropTypes.object.isRequired,
};

export default Peak;
