import Datetime from "react-datetime";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
const Period = (props) => {
  const { name, startDate, endDate, eventPeriodId } = props.group;
  return (
    <div className="period-date-header">
      <span className="icon icon-edit"></span>
      <span
        suppressContentEditableWarning={true}
        className="form-control period-name"
        contentEditable={true}
        onBlur={(e) => {
          if (e.currentTarget.textContent === name) return;
          props.onChangePeriod({
            id: eventPeriodId,
            name: e.currentTarget.textContent,
            startDate,
            endDate,
          });
        }}
      >
        {name}
      </span>
      <div style={{ flexWrap: "wrap" }}>
        <label>from</label>
        <div className="date-picker">
          <Datetime
            inputProps={{ readOnly: true }}
            dateFormat="DD MMMM YYYY - HH:mm"
            timeFormat={false}
            value={moment(startDate.tz).format("DD MMMM YYYY - HH:mm")}
            closeOnSelect={true}
            onChange={(val) =>
              props.onChangePeriod({
                id: eventPeriodId,
                name,
                startDate: { tz: val.format("YYYY-MM-DD HH:mm") },
                endDate,
              })
            }
          />
        </div>
        <label>to</label>
        <div className="date-picker">
          <Datetime
            inputProps={{ readOnly: true }}
            className="form-group"
            dateFormat="DD MMMM YYYY - HH:mm"
            timeFormat={false}
            value={moment(endDate.tz).format("DD MMMM YYYY - HH:mm")}
            closeOnSelect={true}
            onChange={(val) =>
              props.onChangePeriod({
                id: eventPeriodId,
                name,
                startDate,
                endDate: { tz: val.format("YYYY-MM-DD HH:mm") },
              })
            }
          />
        </div>
      </div>
      {props.showDelete && (
        <span
          title="delete"
          className="icon-delete"
          style={{ fontWeight: "normal" }}
          onClick={() => {
            props.deletePeriod(eventPeriodId);
          }}
        ></span>
      )}
    </div>
  );
};
Period.propTypes = {
  group: PropTypes.object,
  deletePeriod: PropTypes.func,
  onChangePeriod: PropTypes.func,
  setOpenDateTime: PropTypes.func,
  showDelete: PropTypes.bool,
};
export default Period;
