import React from "react";
import SelectCheckboxes from "@layout/SelectCheckboxes";
import Search from "@layout/Search";
import PropTypes from "prop-types";
import { loadSchema } from "@actions/abstracts";
import { connect } from "react-redux";

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.statuses = {
      submitted: {
        label: "Submitted",
        checked: false,
      },
      awaiting_resubmission: {
        label: "Awaiting Resubmission",
        checked: false,
      },
      passingByAdmin: {
        label: "Approved",
        checked: false,
      },
      passingByAdmin_false: {
        label: "Rejected",
        checked: false,
      },
      passingByJudge: {
        label: "Approved (By Judges)",
        checked: false,
      },
      passingByJudge_false: {
        label: "Rejected (By Judges)",
        checked: false,
      },
    };

    this.types = {
      presentation: {
        label: "Presentation",
      },
      poster: {
        label: "Poster",
      },
    };

    this.state = {};
  }

  componentDidMount() {
    const { loadSchema, eventId, eventAbstractSchemaId } = this.props;
    if (eventAbstractSchemaId) {
      loadSchema(eventAbstractSchemaId, eventId);
    }
  }

  setFilter(key, value) {
    this.setState({ [key]: value }, () => this.props.onChange(this.state));
  }

  getOptions() {
    const { schema } = this.props;
    const options = {
      "-1": {
        label: "Judged (any number)",
      },
      0: {
        label: "Not Judged",
      },
    };
    for (let i = 1; i <= schema.numberOfJudges; i++) {
      options[`${i}`] = {
        label: `Judged by ${i} judge${i > 1 ? "s" : ""}`,
      };
    }
    return options;
  }

  render() {
    const types = {};
    this.props.schema &&
      this.props.schema.types &&
      this.props.schema.types.map((type) => {
        types[type.id] = { label: type.name };
      });

    const topics = {};
    this.props.schema &&
      this.props.schema.topics &&
      this.props.schema.topics.map((topic) => {
        topics[topic.id] = { label: topic.name };
      });

    return (
      <>
        <div className="">
          <SelectCheckboxes
            keyName="status"
            options={this.statuses}
            placeholder={"Status"}
            onChange={(status) => this.setFilter("status", status)}
            selected={this.state.status}
          />
        </div>
        <div className="">
          <SelectCheckboxes
            options={types}
            keyName="types"
            placeholder={"Type"}
            selected={this.state.typeIds}
            onChange={(type) => {
              this.setFilter("typeIds", type);
            }}
          />
        </div>
        <div className="">
          <SelectCheckboxes
            options={topics}
            keyName="topics"
            placeholder={"Topic"}
            selected={this.state.topicIds}
            onChange={(topic) => {
              this.setFilter("topicIds", topic);
            }}
          />
        </div>
        <div className="">
          <SelectCheckboxes
            options={this.getOptions()}
            keyName="judgingStatus"
            placeholder={"Judging"}
            selected={this.state.ratedBy}
            onChange={(val) => {
              this.setFilter("ratedBy", val);
            }}
          />
        </div>

        <div className="">
          <SelectCheckboxes
            options={{
              true: { label: "Published" },
              false: { label: "Not Published" },
            }}
            single={true}
            selectAllButton={false}
            keyName="published"
            placeholderInsideSelect={false}
            placeholder={"Publish"}
            selected={this.state.published}
            onChange={(val) => {
              this.setFilter("published", val);
            }}
          />
        </div>
        <div className="">
          <SelectCheckboxes
            options={{
              true: { label: "Added to Schedule" },
              false: { label: "Not Added to Schedule" },
            }}
            single={true}
            selectAllButton={false}
            keyName="inSchedule"
            placeholderInsideSelect={false}
            placeholder={"In Schedule"}
            selected={this.state.inSchedule}
            onChange={(val) => {
              this.setFilter("inSchedule", val);
            }}
          />
        </div>
        <div className="">
          <Search
            onSearch={(search) => this.setFilter("search", search)}
            timeout={400}
          />
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    schema: state.api.abstracts.currentSchema.data,
    eventAbstractSchemaId: state.api.events.edit.data.eventAbstractSchemaId,
    eventId: state.api.events.edit.data.id,
  }),
  { loadSchema }
)(Filters);

Filters.propTypes = {
  onChange: PropTypes.func.isRequired,
  loadSchema: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  eventAbstractSchemaId: PropTypes.number,
  eventId: PropTypes.number.isRequired,
};
