import { api } from "@actions/ajax";
import { addNotification } from "@actions";

export const getPricingFields = (
  eventId,
  policyId,
  endpoint = "subscriptions"
) => {
  return (dispatch) => {
    const request = {
      endpoint,
      action: "listPricingFields",
      params: {
        eventId,
        policyId,
      },
    };

    return dispatch(api(request)).catch((err) => Promise.reject(err));
  };
};
export const calculatePricingGroups = (
  eventId,
  newFields = [],
  exceptions = []
) => {
  return (dispatch) => {
    const request = {
      endpoint: "subscriptions",
      action: "calculatePricingGroups",
      params: {
        eventId,
      },
      body: {
        data: {
          newFields,
          exceptions,
        },
      },
    };
    return dispatch(api(request)).catch((err) => Promise.reject(err));
  };
};
export const createPeriod = (eventId, d, endpoint = "subscriptions") => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const data = {
        name: d.name,
        startDate: d.date.from,
        endDate: d.date.to,
        eventPolicyId: d.eventPolicyId,
      };

      const request = {
        endpoint,
        action: "createPeriod",
        params: {
          eventId,
        },
        body: {
          data,
        },
      };

      dispatch(api(request))
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
};

export const createType = (eventId, data, endpoint = "subscriptions") => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint,
        action: "createType",
        params: {
          eventId,
        },
        body: {
          data,
        },
      };
      dispatch(api(request))
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
};
export const createTypePeriod = (eventId, data, param) => (dispatch) => {
  let action = "";

  if (param === "types") {
    action = "createType";
  } else if (param === "periods") {
    action = "createPeriod";
    const newData = {
      name: data.name,
      startDate: data.datetimeRange.from,
      endDate: data.datetimeRange.to,
    };
    data = newData;
  } else {
    return null;
  }
  const request = {
    endpoint: "subscriptions",
    action,
    params: {
      eventId,
    },
    body: {
      data,
    },
  };
  return dispatch(api(request));
};

export const deleteTypePeriod = (eventId, id, param, force) => (dispatch) => {
  let action = "";
  if (param === "types") {
    action = "deleteType";
  } else if (param === "periods") {
    action = "deletePeriod";
  } else {
    return null;
  }
  const request = {
    endpoint: "subscriptions",
    action,
    params: {
      eventId,
      id,
    },
    body: {
      data: force ? { force: true } : {},
    },
  };

  return dispatch(api(request));
};
export const updateTypePeriod = (eventId, data, id, param) => (dispatch) => {
  let action = "";
  if (param === "types") {
    action = "updateType";
  } else if (param === "periods") {
    action = "updatePeriod";
    const newData = {
      name: data.name,
      startDate: data.datetimeRange.from,
      endDate: data.datetimeRange.to,
    };
    data = newData;
  } else {
    return null;
  }
  const request = {
    endpoint: "subscriptions",
    action,
    params: {
      eventId,
      id,
    },
    body: {
      data,
    },
  };

  return dispatch(api(request));
};
export const upsertEventSubscriptions = (eventId, data) => (dispatch) => {
  const request = {
    endpoint: "subscriptions",
    action: "upsert",
    params: {
      eventId,
    },
    body: {
      data,
    },
  };

  return dispatch(api(request)).then(() => {
    dispatch(
      addNotification("Subscription prices updated successfully!", "success")
    );
  });
};

export const saveSubscription =
  (eventId, subscriptionId, data, endpoint = "subscriptions") =>
  (dispatch) => {
    const request = {
      endpoint,
      action: subscriptionId ? "update" : "create",
      params: {
        eventId,
      },
      body: {
        data,
      },
    };

    if (subscriptionId) {
      request.params["subscriptionId"] = subscriptionId;
    }

    return dispatch(api(request));
  };

export const getSubscriptions = (
  eventId,
  endpoint = "subscriptions",
  action = "list",
  policyId
) => {
  return (dispatch) => {
    const request = {
      endpoint,
      action,
      params: {
        eventId,
      },
      query: policyId ? `?policyId=${policyId}&rpp=-1` : "?rpp=-1",
      // cache: action === 'list'
    };

    return dispatch(api(request));
  };
};
