import React from "react";
import PropTypes from "prop-types";
import Map from "@layout/Map";
import { deferScript } from "@helpers";
import { api as configuration } from "@config/conf";

export default class Address extends React.Component {
  constructor(props) {
    super(props);
    ["initAutocomplete", "onChange", "handleInput"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.map = null;
    this.autocomplete = null;
    this.state = {
      googleMapsReady: false,
      location: {
        address: props.value.address,
        lng: props.value.lng,
        lat: props.value.lat,
        hname: props.value.hname,
      },
    };
  }

  componentDidMount() {
    if (typeof google === "undefined") {
      deferScript(
        `https://maps.googleapis.com/maps/api/js?key=${configuration.googleMapKey}&libraries=places`
      ).then(() => {
        this.setState({ googleMapsReady: true });
        if (this.props.withAddress) this.initAutocomplete();
      });
    } else {
      this.setState({ googleMapsReady: true }, () => {
        if (this.props.withAddress) this.initAutocomplete();
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({ location: nextProps.value });
      const { lat, lng } = nextProps.value;
      this.map.setCenter({ lat, lng });
    }
  }
  handleInput(e) {
    const newState = {
      location: Object.assign({}, this.state.location, {
        [e.target.name]: e.target.value,
      }),
    };
    this.props.onChange(newState);
    this.setState(newState);
  }

  initAutocomplete() {
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.autoCompleteRef
    );
    this.autocomplete.addListener("place_changed", this.onChange);
    this.autocomplete.bindTo("bounds", this.map);
    window.google.maps.event.addDomListener(
      this.autoCompleteRef,
      "keydown",
      (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      }
    );
  }

  onChange() {
    const place = this.autocomplete.getPlace();
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
    }
    const newState = {
      location: Object.assign({}, this.state.location, {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
        hname: place.name,
      }),
    };
    this.props.onChange(newState);
    this.setState(newState);
  }

  render() {
    if (!this.state.googleMapsReady) {
      return null;
    }

    const { lng, lat, address, hname } = this.state.location;

    return (
      <div
        style={this.props.fullHeight ? { width: "100%", height: "100%" } : {}}
      >
        {this.props.withName && (
          <div
            className={`form-group${
              this.props.hasErrors && !hname ? " has-error" : ""
            }`}
          >
            <div className="field-type type-text kmb-text">
              <label htmlFor="name">
                Name {this.props.isRequired && <span> (*)</span>}
              </label>
              <div>
                <input
                  type="text"
                  placeholder="Type Name"
                  className="form-control"
                  id="hname"
                  name="hname"
                  value={hname}
                  onChange={this.handleInput}
                />
                <span className="icon icon-edit"></span>
              </div>
              {this.props.hasErrors && !hname && (
                <div className="help-block">Name is required</div>
              )}
            </div>
          </div>
        )}
        {this.props.withAddress && (
          <div
            className={`form-group${
              this.props.hasErrors && !address ? " has-error" : ""
            }`}
          >
            <div className="field-type type-text kmb-text">
              <label htmlFor="address">
                Address {this.props.isRequired && <span> (*)</span>}
              </label>
              <div>
                <input
                  ref={(ref) => (this.autoCompleteRef = ref)}
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Type Address"
                  className="form-control"
                  value={address}
                  onChange={this.handleInput}
                />
                <span className="icon icon-edit"></span>
              </div>
              {this.props.hasErrors && !address && (
                <div className="help-block">Address is required</div>
              )}
            </div>
          </div>
        )}
        {this.props.withMap && (
          <Map
            mapHeight={this.props.mapHeight || 300}
            center={{ lng, lat }}
            getMapInstance={(map) => {
              this.map = map;
            }}
          />
        )}
      </div>
    );
  }
}

Address.defaultProps = {
  withName: true,
  withAddress: true,
  value: {
    lng: 22.948426,
    lat: 40.626446,
    address: "White Tower",
    hname: "",
    hasErrors: false,
    fullHeight: false,
    onChange: () => {},
  },
  isRequired: false,
};

Address.propTypes = {
  fullHeight: PropTypes.bool,
  onChange: PropTypes.func,
  withName: PropTypes.bool,
  withAddress: PropTypes.bool,
  value: PropTypes.object.isRequired,
  withMap: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  mapHeight: PropTypes.number,
  isRequired: PropTypes.bool,
};
