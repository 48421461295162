import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import Form from "@components/form";
import formFields from "@helpers/form-fields";

const SingleSpeakerChairperson = (props) => {
  const [state, setState] = React.useState({ item: props.item });

  formFields.forms.sessions.speakersChairpersons.jobTitle.disabled =
    props.item.jobTitleDisabled;
  formFields.forms.sessions.speakersChairpersons.institution.disabled =
    props.item.institutionDisabled;
  formFields.forms.sessions.speakersChairpersons.shortBio.disabled =
    props.item.shortBioDisabled;

  return (
    <div className="form-container single-sponsor">
      <h2>{`${props.mode == "create" ? "Add Speaker" : "Edit Speaker"}`}</h2>
      <Form
        formName="speakersChairpersons"
        componentName="sessions"
        value={state.item}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;
          if (
            !state.item.speakerThumbnail?.length &&
            !data.value.speakerThumbnail.length &&
            (props.item.speakerThumbnail || [])[0]
          ) {
            data.value.speakerThumbnail = [props.item.speakerThumbnail[0].id];
          } else if (!data.value.speakerThumbnail.length) {
            delete data.value.speakerThumbnail;
          }
          props.onSave(data.value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
        onChange={(data) => {
          if (data.key === "speakerThumbnail") {
            setState({ item: { ...state.item, speakerThumbnail: [] } });
          }
        }}
      />
    </div>
  );
};

SingleSpeakerChairperson.propTypes = {
  hideModal: PropTypes.func,
  mode: PropTypes.string,
  item: PropTypes.object,
  onSave: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleSpeakerChairperson);
