import React from "react";
import PropTypes from "prop-types";
import ExhibitorSlots from "@layout/ExhibitorSlots";
import KMBLoader from "@layout/KMBLoader";
import Select2 from "@layout/Select2";
import ImageCover from "@layout/ImageCover";
import Switch from "@layout/Switch";
import SubmitContainer from "@layout/SubmitContainer";

const SingleExhibitor = (props) => {
  const [state, setState] = React.useState({
    exhibitor: props.exhibitor,
    premiumSlots: [],
    normalSlots: [],
    selectedCategories: props.exhibitor.categories || [],
    exhibitorThumbnail: (props.exhibitor?.exhibitorThumbnail || [])[0]?.url,
  });
  const exhibitorThumbnail = React.useRef();
  const PREMIUM_SLOTS = "PremiumSlots";
  const NORMAL_SLOTS = "NormalSlots";
  const PREMIUM_SLOTS_LENGTH = 4;
  const NORMAL_SLOTS_LENGTH = 6;

  React.useEffect(() => {
    setState({
      ...state,
      premiumSlots: props.slots.filter((slot) => slot.type == "premium"),
      normalSlots: props.slots.filter((slot) => slot.type == "normal"),
    });
  }, props);

  const onChangeSlots = (slot, selectedPosition) => {
    let { slotId, position, size } = state.exhibitor;

    //case checkbox was not checked
    const unset = !slotId;
    const isCurrentPosition = selectedPosition === position;
    const isAfterSelectedPosition =
      slotId === slot.id &&
      selectedPosition - (position + size - 1) == 0 &&
      selectedPosition > position;
    const isBeforeSelectedPosition =
      slotId === slot.id &&
      selectedPosition - (position + size - 1) == 0 &&
      selectedPosition < position;

    const beforeSelectedPosition =
      slotId === slot.id && position - selectedPosition == 1;
    const afterSelectedPosition =
      slotId === slot.id &&
      selectedPosition - (position + size) == 0 &&
      selectedPosition > position;

    if (!slot.checked) {
      if (unset) {
        slotId = slot.id;
        size = 1;
        position = selectedPosition;
      } else if (beforeSelectedPosition) {
        position = selectedPosition;
        size++;
      } else if (afterSelectedPosition) {
        size++;
      }
    } else {
      if (isCurrentPosition) {
        if (size > 1) {
          position++;
          size--;
        } else {
          position = null;
          size = null;
          slotId = null;
        }
      } else if (isAfterSelectedPosition) {
        size = selectedPosition - position;
      } else if (isBeforeSelectedPosition) {
        size = selectedPosition - position;
      }
    }

    const newState = {
      ...state,
      exhibitor: { ...state.exhibitor, slotId, position, size },
    };
    setState(newState);
  };

  const clearFields = () =>
    setState({
      ...state,
      exhibitor: props.exhibitor,
      selectedCategories: props.exhibitor.categories || [],
      exhibitorThumbnail: (props.exhibitor?.exhibitorThumbnail || [])[0]?.url,
    });

  const onSave = () => {
    if (state.exhibitor.id) {
      return props
        .updateExhibitor(props.event.id, state.exhibitor.id, {
          orgSponsorId: state.exhibitor.orgSponsorId,
          categories: state.selectedCategories,
          slotId: state.exhibitor.slotId,
          size: state.exhibitor.size,
          position: state.exhibitor.position,
          exhibitorThumbnail: exhibitorThumbnail.current,
          notes: state.exhibitor.notes || "",
          supportEmail: state.exhibitor.supportEmail || "",
          enableEmailCommunication:
            state.exhibitor.enableEmailCommunication || false,
        })
        .then(() => props.changeTab("ExhibitorsList", null));
    }
    return props
      .createExhibitor(props.event.id, {
        orgSponsorId: state.exhibitor.orgSponsorId,
        categories: state.selectedCategories,
        slotId: state.exhibitor.slotId,
        size: state.exhibitor.size,
        position: state.exhibitor.position,
        exhibitorThumbnail: exhibitorThumbnail.current,
        notes: state.exhibitor.notes || "",
        supportEmail: state.exhibitor.supportEmail || "",
        enableEmailCommunication:
          state.exhibitor.enableEmailCommunication || false,
      })
      .then(() => props.changeTab("ExhibitorsList", null));
  };

  if (props.singleUpdating) {
    return <KMBLoader rows={15} padding={24} height={53} />;
  }

  return (
    <div className="single-exhibitor form-container">
      <div className="row form-container">
        <div className="col-sm-6 col-xs-12">
          <div className="form-group">
            <label>Exhibitor (*)</label>
            <Select2
              style={{ zIndex: 10 }}
              options={props.sponsors.reduce(
                (prev, next) => ({ ...prev, [next.id]: next.name }),
                {}
              )}
              onChange={(val) => {
                setState({
                  ...state,
                  exhibitor: { ...state.exhibitor, orgSponsorId: val },
                });
              }}
              placeholder={"Please select from Sponsors list"}
              value={
                state.exhibitor.orgSponsorId?.length === 0
                  ? ""
                  : state.exhibitor.orgSponsorId
              }
              multi={false}
            />
          </div>
          <div className="form-group">
            <label>Categories</label>
            <Select2
              options={props.categories.reduce(
                (prev, next) => ({ ...prev, [next.id]: next.name }),
                {}
              )}
              onChange={(val) => {
                setState({ ...state, selectedCategories: val });
              }}
              placeholder={"Please select one or more categories"}
              value={
                state.selectedCategories?.length === 0
                  ? ""
                  : state.selectedCategories
              }
              multi={true}
            />
          </div>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              value={state.exhibitor.notes}
              onChange={(e) =>
                setState({
                  ...state,
                  exhibitor: { ...state.exhibitor, notes: e.target.value },
                })
              }
            />
          </div>
          <div className="form-group">
            <div className="type-switch">
              <label>Enable Email Communication</label>
              <div className="switch-holder">
                <Switch
                  id={`enable-email-${state.exhibitor.id}`}
                  isLive={true}
                  isActive={Boolean(state.exhibitor.enableEmailCommunication)}
                  onChange={(active) =>
                    setState({
                      ...state,
                      exhibitor: {
                        ...state.exhibitor,
                        enableEmailCommunication: Boolean(active),
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Support Email</label>
            <input
              className="form-control"
              value={state.exhibitor.supportEmail}
              onChange={(e) =>
                setState({
                  ...state,
                  exhibitor: {
                    ...state.exhibitor,
                    supportEmail: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="col-sm-6 col-xs-12">
          <div className="form-group">
            <label>{"Exhibitor's Logo"}</label>
            <div>
              <ImageCover
                height={"449px"}
                imageUrl={state.exhibitorThumbnail}
                name="Exhibitor Thumbnail"
                id="exhibitorThumbnail"
                onChange={(file, deletefile) => {
                  if (deletefile) {
                    setState({
                      ...state,
                      exhibitorThumbnail: "",
                    });
                    exhibitorThumbnail.current = [
                      props.exhibitor.exhibitorThumbnail[0]?.id,
                    ];
                  } else if (window.FileReader && file) {
                    const fr = new FileReader();
                    fr.onload = () => {
                      exhibitorThumbnail.current = [file];
                      setState({
                        ...state,
                        exhibitorThumbnail: fr.result,
                      });
                    };
                    fr.readAsDataURL(file);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="slots-wrapper">
        <div className="form-group">
          <label>Premium Slots</label>
          <ExhibitorSlots
            componentName={PREMIUM_SLOTS}
            numberOfSlots={PREMIUM_SLOTS_LENGTH}
            slots={state.premiumSlots}
            name="PREMIUM SLOTS"
            onChange={onChangeSlots}
            exhibitor={state.exhibitor}
            exhibitors={props.exhibitors}
          />
        </div>
        <div className="form-group">
          <label>Normal Slots</label>
          <ExhibitorSlots
            componentName={NORMAL_SLOTS}
            numberOfSlots={NORMAL_SLOTS_LENGTH}
            slots={state.normalSlots}
            name="NORMAL SLOTS"
            onChange={onChangeSlots}
            exhibitor={state.exhibitor}
            exhibitors={props.exhibitors}
          />
        </div>
      </div>
      <SubmitContainer
        onCancel={clearFields}
        onSubmit={onSave}
        disabled={
          state.exhibitor.slotId == null || state.exhibitor.orgSponsorId == null
        }
      />
    </div>
  );
};

SingleExhibitor.propTypes = {
  event: PropTypes.object.isRequired,
  exhibitor: PropTypes.object.isRequired,
  loadExhibitionDigitalSlots: PropTypes.func.isRequired,
  loadExhibitionCategories: PropTypes.func.isRequired,
  updateExhibitor: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  createExhibitor: PropTypes.func.isRequired,
  slots: PropTypes.array,
  singleUpdating: PropTypes.bool,
  sponsors: PropTypes.array,
  categories: PropTypes.array,
  exhibitors: PropTypes.array,
};

export default SingleExhibitor;
