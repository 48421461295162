import React from "react";
import { connect } from "react-redux";
import {
  getHookEmails,
  selectHook,
  getHooks,
  getHook,
  saveEmail,
  createEmail,
  saveHook,
} from "@actions/hooks";
import { listGroups } from "@actions/policyGroups";
import { changeTab } from "@actions/editmenu";

import { showModal, hideModal, addNotification, deleteItem } from "@actions";
import { changeSection } from "@actions/editmenu";
import SingleContact from "../partials/SingleContact";
import Hooks from "./Hooks";

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    hooks: state.api.events.hooks.data,
    emails: state.api.events.hooks?.innerHook?.emails?.data,
    innerHook: state.api.events.hooks.innerHook,
    fetching:
      state.api.events.hooks.fetching ||
      state.api.policyGroups.list.fetching ||
      false,
    innerHookFetching:
      ((state.api.events.hooks.innerHook || {}).emails || {}).fetching || false,
    event: state.api.events.edit.data,
    accessEvents: state.appuser.data.accessEvents,
    globalVars: state.api.events.hooks.meta.email_variables,
    tempContacts: state.users.tempContacts,
    policyGroups: state.api.policyGroups.list.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    getHookEmails: (eventId, hookId) => {
      return dispatch(getHookEmails(eventId, hookId));
    },
    loadGroups: (eventId) => {
      return dispatch(listGroups(eventId));
    },
    getHooks: (eventId) => {
      dispatch(getHooks(eventId));
    },
    getHook: (eventId, hookId) => {
      dispatch(getHook(eventId, hookId));
    },
    cancelEdit: () => {
      dispatch(changeSection("Hooks"));
    },
    selectHook: (hook, type) => {
      dispatch(selectHook(hook, type));
    },
    saveHook: (eventId, eventHookId, data) => {
      dispatch(saveHook(eventId, eventHookId, data));
    },
    contactModal: (
      eventId,
      hookId,
      emailId,
      emails,
      contactAction,
      contact = undefined,
      contactIndex,
      innerHook,
      policyGroups,
      saveEmail
    ) => {
      return dispatch(
        showModal(
          <SingleContact
            policyGroups={policyGroups}
            emails={emails}
            contactAction={contactAction}
            addNotification={dispatcher.addNotification}
            saveEmail={saveEmail}
            contact={contact}
            contactIndex={contactIndex}
            hookId={hookId}
            emailId={emailId}
            hideModal={dispatcher.hideModal}
            innerHook={innerHook}
            emailType={"hook"}
          />
        )
      );
    },
    createEmail: (eventId, hookId, defaultTemplate = false) => {
      const data = {
        data: {
          contacts: [],
          template: '{"html":"<p><br></p>"}',
          defaultTemplate: defaultTemplate,
        },
      };
      return dispatch(createEmail(eventId, hookId, data));
    },
    saveEmail: (eventId, hookId, emailId, action, email) => {
      return dispatch(saveEmail(eventId, hookId, emailId, action, email))
        .then(() => {
          dispatch(hideModal());
          dispatcher.getHookEmails(eventId, hookId);
        })
        .catch(() => {});
    },
    resetEmail: (eventId, hookId, emailId, action, email) => {
      return dispatch(saveEmail(eventId, hookId, emailId, action, email));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    deleteItem: (params, endpoint) => {
      return dispatch(deleteItem(params, endpoint));
    },
    changeTab: (tab, fakeChild) => dispatch(changeTab(tab, fakeChild)),
  };

  return dispatcher;
};

export default connect(mapStateToProps, mapDispatchToProps)(Hooks);
