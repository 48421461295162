import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";
import Select from "@layout/Select2";
export default class DateRange extends React.Component {
  constructor(props) {
    super(props);

    [
      "onChange",
      "changeDuration",
      "getValue",
      "focusTime",
      "buildLimits",
      "checkValidTime",
      "focusTo",
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));

    if (this.props.from.value && this.props.to.value) {
      if (moment(this.props.to.value).isBefore(moment(this.props.from.value))) {
        throw new Error("End date cannot be before start date");
      }
    }

    this.from = this.to = null;
    this.state = {
      range: {
        from: this.props.from.value
          ? this.props.from.value.tz
            ? moment(this.props.from.value.tz).format(this.props.dateFormat)
            : moment(this.props.from.value).format(this.props.dateFormat)
          : moment().format(this.props.dateFormat),
        to: this.props.to.value
          ? this.props.to.value.tz
            ? moment(this.props.to.value.tz).format(this.props.dateFormat)
            : moment(this.props.to.value).format(this.props.dateFormat)
          : null,
      },
    };

    if (props.hasTime) {
      if (props.autoTime) {
        this.state.range.fromTime = this.props.from.value
          ? moment(this.props.from.value).format(this.props.timeFormat)
          : moment("09:00", this.props.timeFormat).format(
              this.props.timeFormat
            );
        this.state.range.toTime = this.props.to.value
          ? moment(this.props.to.value).format(this.props.timeFormat)
          : null;
      } else {
        if (this.props.fromTime.value) {
          this.state.range.fromTime = moment(
            this.props.fromTime.value,
            this.props.timeFormat
          ).format(this.props.timeFormat);
        } else {
          if (this.props.min) {
            this.state.range.fromTime = moment(this.props.min).format(
              this.props.timeFormat
            );
          } else {
            this.state.range.fromTime = moment(
              "09:00",
              this.props.timeFormat
            ).format(this.props.timeFormat);
          }
        }

        if (this.props.toTime.value) {
          this.state.range.toTime = moment(
            this.props.toTime.value,
            this.props.timeFormat
          ).format(this.props.timeFormat);
        } else {
          this.state.range.toTime = null;
        }
      }
    }

    this.state.limits = this.buildLimits();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.from.value !== this.props.from.value ||
      nextProps.to.value !== this.props.to.value ||
      nextProps.fromTime.value !== this.props.fromTime.value ||
      nextProps.toTime.value !== this.props.toTime.value
    ) {
      this.setState({
        range: {
          from: nextProps.from.value
            ? moment(nextProps.from.value).format(nextProps.dateFormat)
            : moment().format(nextProps.dateFormat),
          to: nextProps.to.value
            ? moment(nextProps.to.value).format(nextProps.dateFormat)
            : null,
          fromTime: nextProps.fromTime.value
            ? moment(nextProps.fromTime.value, nextProps.timeFormat).format(
                nextProps.timeFormat
              )
            : moment().format(nextProps.timeFormat),
          toTime: nextProps.toTime.value
            ? moment(nextProps.toTime.value, nextProps.timeFormat).format(
                nextProps.timeFormat
              )
            : null,
        },
      });
    }
  }

  buildLimits() {
    const response = {
      min: null,
      max: null,
      minTime: null,
      maxTime: null,
    };
    if (this.props.min) {
      response.min = moment(this.props.min).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      response.minTime = moment(this.props.min);
    }

    if (this.props.max) {
      response.max = moment(this.props.max).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      response.maxTime = moment(this.props.max);
    }

    return response;
  }

  getValue() {
    const range = {
      from: this.from.state.selectedDate
        ? this.from.state.selectedDate.format(this.props.dateFormat)
        : null,
      to: this.to.state.selectedDate
        ? this.to.state.selectedDate.format(this.props.dateFormat)
        : null,
    };

    if (this.props.hasTime) {
      range.fromTime = this.fromTime.state.selectedDate
        ? this.fromTime.state.selectedDate.format(this.props.timeFormat)
        : null;
      range.toTime = this.toTime.state.selectedDate
        ? this.toTime.state.selectedDate.format(this.props.timeFormat)
        : null;
    }

    return range;
  }

  checkValidTime(range, instance, tmoment) {
    const limits = this.state.limits;

    if (instance === "fromTime") {
      if (range["from"] === null) {
        return true;
      }

      const date = moment(
        `${range["from"]} ${tmoment.format(this.props.timeFormat)}`,
        `${this.props.dateFormat} ${this.props.timeFormat}`
      );
      if (date.isBefore(limits.minTime) || date.isAfter(limits.maxTime)) {
        return false;
      }
    }

    if (instance === "toTime") {
      if (range["to"] === null) {
        return true;
      }

      const date = moment(
        `${range["to"]} ${tmoment.format(this.props.timeFormat)}`,
        `${this.props.dateFormat} ${this.props.timeFormat}`
      );
      if (date.isBefore(limits.minTime) || date.isAfter(limits.maxTime)) {
        return false;
      }
    }

    return true;
  }

  onChange(tmoment, instance) {
    const range = this.getValue();
    let isValidTime = true;
    if (
      typeof tmoment === "string" &&
      tmoment.match(/^[0-2][0-3]:[0-5][0-9]$/)
    ) {
      tmoment = moment(tmoment, this.props.timeFormat);
    } else if (
      typeof tmoment === "string" &&
      !tmoment.match(/^[0-2][0-3]:[0-5][0-9]$/)
    ) {
      isValidTime = false;
    }

    if (tmoment && isValidTime) {
      const isValid = this.checkValidTime(range, instance, tmoment);

      if (this.props.hasTime) {
        if (instance === "fromTime" && !range["from"]) {
          range["from"] = this.state.limits.min
            ? this.state.limits.min.format(this.props.dateFormat)
            : null;
        }
        if (instance === "toTime" && !range["to"]) {
          range["to"] = this.state.limits.min
            ? this.state.limits.min.format(this.props.dateFormat)
            : null;
        }

        if (instance === "from") {
          range["fromTime"] = this.props.min
            ? moment(this.props.min).format(this.props.timeFormat)
            : moment("09:00", this.props.timeFormat).format(
                this.props.timeFormat
              );
        }

        if (instance === "to") {
          range["toTime"] = this.props.max
            ? moment(this.props.max).format(this.props.timeFormat)
            : moment("09:00", this.props.timeFormat).format(
                this.props.timeFormat
              );
        }
      }
      if (isValid) {
        range[instance] = tmoment.format(
          ["fromTime", "toTime"].includes(instance)
            ? this.props.timeFormat
            : this.props.dateFormat
        );
      }
    }
    if (instance === "toTime") {
      range["fromTime"] = this.state.range.fromTime;
    }
    if (instance === "fromTime") {
      range["toTime"] = this.state.range.toTime;
    }
    this.setState({ range }, () => {
      this.props.onChange(range);
    });
  }

  changeDuration(value) {
    if (!this.from.state.selectedDate || !this.fromTime.state.selectedDate) {
      return;
    }

    const range = this.getValue();
    const max = moment(this.props.max);
    let buildDate = `${this.from.state.selectedDate.format(
      this.props.dateFormat
    )} ${this.fromTime.state.selectedDate.format(this.props.timeFormat)}`;
    buildDate = moment(
      buildDate,
      `${this.props.dateFormat} ${this.props.timeFormat}`
    );
    buildDate.add(value, "minutes");

    if (buildDate.isAfter(max)) {
      buildDate = max;
    }

    range["to"] = buildDate.format(this.props.dateFormat);
    range["toTime"] = buildDate.format(this.props.timeFormat);

    this.setState({ range }, () => this.props.onChange(range));
  }

  focusTo() {
    if (this.state.range.to === null && this.state.range.from) {
      this.setState({
        range: Object.assign({}, this.state.range, {
          to: this.state.range.from,
        }),
      });
    }
  }

  focusTime() {
    if (!this.props.max || this.state.range.toTime !== null) {
      return;
    }

    this.setState({
      range: Object.assign({}, this.state.range, {
        toTime:
          this.state.range.fromTime !== null
            ? moment(this.state.range.fromTime, this.props.timeFormat)
                .add(60, "minutes")
                .format(this.props.timeFormat)
            : moment(this.props.max).format(this.props.timeFormat),
      }),
    });
  }

  render() {
    const className = this.props.hasTime ? "col-sm-12 has-time" : "col-sm-6";
    const limits = this.state.limits;
    let visibility = "visible";
    if (this.props.singleDate) {
      visibility = "none";
    }
    return (
      <div
        className={`range-wrapper${
          this.props.hasDuration && this.props.hasTime ? " has-duration" : ""
        }`}
      >
        {this.props.hasDuration && this.props.hasTime && (
          <div className="duration-holder">
            <div className="vertical-center">
              <div className="duration-wrapper">
                <span>Duration</span>
                <Select
                  options={{
                    30: "30 Minutes",
                    45: "45 Minutes",
                    60: "1 Hour",
                    90: "1.5 Hour",
                    120: "2 Hours",
                    150: "2.5 Hours",
                    180: "3 Hours",
                  }}
                  isDropDownButton={true}
                  placeholderInsideSelect={false}
                  onChange={(val) => this.changeDuration(val)}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className={className}>
            <div
              className={`form-group${
                this.props.hasErrors && !this.state.range.from
                  ? " has-error"
                  : ""
              }`}
            >
              <div className="field-type type-datetime">
                {!this.props.hasTime && (
                  <label className={` ${this.props.from.className || ""}`}>
                    {this.props.from.label}
                  </label>
                )}
                <div className="row">
                  <div
                    className={`date-holder ${
                      this.props.hasTime ? "col-sm-6" : "col-sm-12"
                    }`}
                  >
                    {this.props.hasTime && (
                      <label className={` ${this.props.to.className || ""}`}>
                        {this.props.from.label}
                      </label>
                    )}
                    <div className="date-icon-holder">
                      <Datetime
                        ref={(ref) => (this.from = ref)}
                        dateFormat={this.props.dateFormat}
                        timeFormat={false}
                        inputProps={{
                          placeholder: visibility === "none" ? "" : "From",
                          readOnly: true,
                        }}
                        value={
                          this.props.from.value ? this.state.range.from : ""
                        }
                        closeOnSelect={true}
                        onChange={(moment) => this.onChange(moment, "from")}
                        isValidDate={(current) => {
                          let isValid = true;
                          if (limits.min) {
                            isValid =
                              current.isAfter(limits.min) ||
                              current.isSame(limits.min);
                          }
                          if (isValid && limits.max) {
                            isValid =
                              current.isBefore(limits.max) ||
                              current.isSame(limits.max);
                          }
                          if (
                            isValid &&
                            this.to &&
                            this.to.state.selectedDate
                          ) {
                            isValid =
                              current.isBefore(this.to.state.selectedDate) ||
                              current.isSame(this.to.state.selectedDate);
                          }
                          return isValid;
                        }}
                      />
                      <span className="icon icon-date"></span>
                    </div>
                  </div>
                  {this.props.hasTime && (
                    <div className="time-holder col-sm-6">
                      <label className={` ${this.props.to.className || ""}`}>
                        {this.props.fromTime.label}
                      </label>
                      <div className="date-icon-holder">
                        <Datetime
                          ref={(ref) => (this.fromTime = ref)}
                          dateFormat={false}
                          timeFormat={this.props.timeFormat}
                          inputProps={{ placeholder: "From", readOnly: false }}
                          value={this.state.range.fromTime}
                          closeOnSelect={true}
                          onChange={(moment) => {
                            this.onChange(moment, "fromTime");
                          }}
                        />
                        <span className="icon icon-date"></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={className} style={{ display: visibility }}>
            <div
              className={`form-group${
                this.props.hasErrors && !this.state.range.to ? " has-error" : ""
              }`}
            >
              <div className="field-type type-datetime">
                {!this.props.hasTime && (
                  <label className={` ${this.props.to.className || ""}`}>
                    {this.props.to.label}
                  </label>
                )}

                <div className="row">
                  <div
                    className={`date-holder ${
                      this.props.hasTime ? "col-sm-6" : "col-sm-12"
                    }`}
                  >
                    {this.props.hasTime && (
                      <label className={` ${this.props.to.className || ""}`}>
                        {this.props.to.label}
                      </label>
                    )}
                    <div className="date-icon-holder">
                      <Datetime
                        ref={(ref) => (this.to = ref)}
                        dateFormat={this.props.dateFormat}
                        timeFormat={false}
                        inputProps={{ placeholder: "To", readOnly: true }}
                        value={this.state.range.to}
                        closeOnSelect={true}
                        onFocus={this.focusTo}
                        onChange={(moment) => this.onChange(moment, "to")}
                        isValidDate={(current) => {
                          let isValid = true;
                          if (limits.min) {
                            isValid =
                              current.isAfter(limits.min) ||
                              current.isSame(limits.min);
                          }
                          if (isValid && limits.max) {
                            isValid =
                              current.isBefore(limits.max) ||
                              current.isSame(limits.max);
                          }
                          if (
                            isValid &&
                            this.from &&
                            this.from.state.selectedDate
                          ) {
                            isValid =
                              current.isAfter(this.from.state.selectedDate) ||
                              current.isSame(this.from.state.selectedDate);
                          }
                          if (
                            isValid &&
                            this.to &&
                            this.to.state.selectedDate
                          ) {
                            isValid =
                              current.isAfter(this.from.state.selectedDate) ||
                              current.isSame(this.from.state.selectedDate);
                          }
                          return isValid;
                        }}
                      />
                      <span className="icon icon-date"></span>
                    </div>
                  </div>
                  {this.props.hasTime && (
                    <div className="time-holder col-sm-6">
                      <label className={` ${this.props.to.className || ""}`}>
                        {this.props.toTime.label}
                      </label>
                      <div className="date-icon-holder">
                        <Datetime
                          ref={(ref) => (this.toTime = ref)}
                          dateFormat={false}
                          timeFormat={this.props.timeFormat}
                          inputProps={{ placeholder: "To", readOnly: false }}
                          value={this.state.range.toTime}
                          closeOnSelect={true}
                          onFocus={this.focusTime}
                          onChange={(moment) => {
                            this.onChange(moment, "toTime");
                          }}
                        />
                        <span className="icon icon-date"></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  onChange: PropTypes.func,
  from: PropTypes.object,
  to: PropTypes.object,
  fromTime: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  toTime: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hasErrors: PropTypes.bool,
  hasTime: PropTypes.bool,
  hasDuration: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  dateFormat: PropTypes.string,
  autoTime: PropTypes.bool,
  timeFormat: PropTypes.string,
  singleDate: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  from: {
    value: null,
    label: "From",
  },
  to: {
    label: "To",
    value: null,
  },
  fromTime: {
    value: null,
    label: "Start Time",
  },
  toTime: {
    value: null,
    label: "End Time",
  },
  dateFormat: "DD-MM-YYYY",
  timeFormat: "HH:mm",
  hasErrors: false,
  hasTime: false,
  hasDuration: false,
  singleDate: false,
  min: null,
  max: null,
};

defaultProps.fromTime.value = moment("09:00", defaultProps.timeFormat);
defaultProps.from.value = moment().format(defaultProps.dateFormat);

DateRange.defaultProps = defaultProps;
