/* eslint-disable */
import React from "react";
import moment from "moment";
import screenfull from "screenfull";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import { getEventRooms } from "../actions/hotels";

const urlToPromise = (url) =>
  new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
export const createConditionFilter = (groups) => {
  return groups.map((group) => {
    return group.conditions?.map((condition) => {
      return {
        key: condition.key,
        value: condition.value,
        operator: condition.operator || "=",
        dropdown_value_key: condition.dropdown_value_key || null,
        id: condition.id,
        block_id: condition.block_id,
        rendered_value: condition.rendered_value,
      };
    });
  });
};

export const checkIfEmpty = (variable) => {
  return variable === "" || variable === null || variable === undefined;
};
export const updateFetchingState = (state, { type, endpoint, actionKey }) => {
  const queue = [...state.fetchingQueue];

  if (type === "FAILED_REQUEST") {
    type = `RECEIVED_${endpoint.toUpperCase()}_${actionKey.toUpperCase()}`;
  }
  // this if removes the keyword "cached" from the reducer name
  if (type.includes("RECEIVED_") && type.includes("CACHED")) {
    type =
      type.substring(0, type.indexOf("CACHED_")) +
      type.substring(type.indexOf("CACHED_") + 7, type.length);
  }

  //case new app request
  if (type.startsWith("REQUEST_")) {
    queue.push(type);
  } else if (type.startsWith("RECEIVED_")) {
    //case received data
    const requestName = type.replace("RECEIVED", "REQUEST");
    const position = queue.indexOf(requestName);
    if (position === -1) return queue;
    queue.splice(position, 1);
  }
  // if non is the case return the same state
  return queue;
};

const mimeTypes = {
  "image/gif": "gif",
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/pjpeg": "jpg",
  "image/tiff": "tiff",
  "image/webp": "webp",
  "image/x-tiff": "tiff",
  "image/svg+xml": "svg",
  "image/bmp": "bmp",
  "image/x-windows-bmp": "bmp",
  "image/vnd.wap.wbmp": "wbmp",
  "text/csv": "csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-excel": "xls",
  "application/vnd.oasis.opendocument.spreadsheet": "docx",
  "application/json": "json",
  "application/javascript": "js",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
  "application/pdf": "pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/msword": "doc",
  "video/mp4": "mp4",
};

// Helper function to get the MIME type from file binary data
const getMimeType = (data) => {
  const blob = new Blob([data]);
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = (new Uint8Array(reader.result)).subarray(0, 4);
      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      resolve(getFileMimeType(header, data));
    };
    reader.readAsArrayBuffer(blob);
  });
};

const getFileMimeType = async (header, data) => {
  switch (header) {
    case "47656f67":
      return "text/csv";
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
      return "image/jpeg";
    case "25504446":
      return "application/pdf";
    case "504b34": //pptx, docx
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "d0cf11e0": // Compound File Binary Format (MS Office files before 2007)
      // Further inspection needed for older MS Office files if needed
      // we need doc
      return "application/msword";
    default:
      return "application/octet-stream";
  }
};


export const createAbstractFile = async (abstracts, eventName) => {
  const zip = new JSZip();

  const processAbstract = async (abstract) => {
    let topics = [...abstract.topics].map((t) => t.name);
    let institutes = [...abstract.institutes].map(
      (a) => `(${a.index + 1}) ${a.name}`
    );

    let authors = [...abstract.authors].map((a) => {
      return `${a.lastName} ${a.firstName}${
        a.company ? ", " + a.company : ""
      } (${abstract.institutes
        .filter((inst) => a.institutes.indexOf(String(inst.index)) > -1)
        .map((inst) => inst.index + 1)
        .join(")(")})`;
    });

    let meta = [...abstract.meta].map((m) => `${m.name}: ${m.value}`);
    topics = topics.join(", ");
    authors = authors.join("; ");
    institutes = institutes.join("\r\n  ");
    meta = meta.join("\r\n");

    zip.folder(abstract.id).file(
      `Abstract-${abstract.id}.txt`,
      `\
Event: ${eventName}
Abstract ID: ${abstract.id}

Abstract Title:
  ${abstract.name}

Authors:
  ${authors}

Institutes:
  ${institutes}

Topics:
  ${topics}

Content:
  ${abstract.abstractText}

Meta:
${meta}

`
    );

    for (const f of abstract.abstractFiles) {
      try {
        const data = await urlToPromise(f.url);
        // todo: this implementation only is necessary if the file name does not have an extension
        const fileNameHasExtention = f.fileName.includes(".");
        let fileName = `${abstract.id}/${f.fileName}`;
        if (!fileNameHasExtention){
          let mimeType = await getMimeType(data);
          const extension = mimeTypes[mimeType] || "bin";
          fileName += `.${extension}`;
        }

        zip.file(fileName, data, { binary: true });
      } catch (err) {
        console.error(`Failed to process file ${f.fileName}:`, err);
      }
    }
  };

  if (abstracts instanceof Array) {
    await Promise.all(abstracts.map(processAbstract));
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `Event-${abstracts[0].eventId}-Abstracts.zip`);
    });
  } else {
    await processAbstract(abstracts);
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `Event-${abstracts.eventId}-Abstract-${abstracts.id}.zip`);
    });
  }
};

export const determineInitialScreen = (data, savedScreen) => {
  const roles = [];
  Object.values(data.accessEvents).map((v) => {
    if (!roles.includes(v.role)) roles.push(v.role);
  });

  let initialScreen = roles.includes("event_judge") ? "judge" : "admin";
  if (roles.includes(`event_${savedScreen}`)) {
    initialScreen = savedScreen;
  } else {
    localStorage.removeItem(`user_${data.id}_screen`);
  }

  return {
    roles,
    initialScreen,
  };
};

export const getAbstractInternalStatus = (item) => {
  if (item.rated === 1) {
    return "Rated";
  }

  if (
    item.status === "submitted" &&
    item.comments.length > 0 &&
    item.comments[item.comments.length - 1].commentType.includes("revision") &&
    item.score === null
  ) {
    return "Resubmitted / Awaiting your review";
  }

  if (item.status === "awaiting_resubmission") {
    return "Awaiting Resubmission";
  }

  return item.score === null ? "Awaiting your review" : "Under review / Draft";
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[`group-${x[key]}`] = rv[`group-${x[key]}`] || []).push(x);
    return rv;
  }, {});
};

export const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};

export const isChangingEvent = (prev, next) => {
  if (prev.pathname === next.pathname) return false;
  const regex = RegExp("/events/[^/]/basic-setup");
  return regex.test(prev.pathname) && regex.test(next.pathname);
};

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const getPosition = (el) => {
  let xPos = 0;
  let yPos = 0;

  while (el) {
    if (el.tagName == "BODY") {
      // deal with browser quirks with body/window/document and page scroll
      const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
      const yScroll = el.scrollTop || document.documentElement.scrollTop;

      xPos += el.offsetLeft - xScroll + el.clientLeft;
      yPos += el.offsetTop - yScroll + el.clientTop;
    } else {
      // for all other non-BODY elements
      xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
      yPos += el.offsetTop - el.scrollTop + el.clientTop;
    }

    el = el.offsetParent;
  }
  return {
    x: xPos,
    y: yPos,
  };
};

export const smoothScrollTo = (endX, endY, duration) => {
  const startX = window.scrollX || window.pageXOffset,
    startY = window.scrollY || window.pageYOffset,
    distanceX = endX - startX,
    distanceY = endY - startY,
    startTime = new Date().getTime();

  duration = typeof duration !== "undefined" ? duration : 400;

  // Easing function
  const easeInOutQuart = function (time, from, distance, duration) {
    if ((time /= duration / 2) < 1)
      return (distance / 2) * time * time * time * time + from;
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  const timer = window.setInterval(function () {
    const time = new Date().getTime() - startTime,
      newX = easeInOutQuart(time, startX, distanceX, duration),
      newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      window.clearInterval(timer);
    }
    window.scrollTo(newX, newY);
  }, 1000 / 60); // 60 fps
};

export const generateFile = (fileName, type, data) => {
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("download", fileName + "." + type);
  let blob;
  switch (type) {
    case "json": {
      blob = new Blob([JSON.stringify(data)], { type: "application/json" });
      break;
    }
    case "xlsx": {
      blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
    }
  }
  const url = URL.createObjectURL(blob);
  downloadAnchorNode.setAttribute("href", url);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadFile = (url, name, element = document.body) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = url;
  element.appendChild(link);
  link.click();
  element.removeChild(link);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function capitalizeAllWords(string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function unescapeString(str) {
  return (str = str
    .replace(/&quot;/g, "'")
    .replace(/&amp;/g, "&")
    .replace(/&#39;/, "'"));
}

/**
 * removeFromArray
 *
 * @param  {array|object} array the collection
 * @param  {type} number   how many elements to pop
 * @param  {boolean} end remove from beginning or end of the array, pass true if you want from the end
 * @return {array|object} depends on what you provided
 */

export function removeFromArray(array, number, end) {
  if (end) {
    array = array.reverse();
    number = array.length - number;
  }

  array = array.filter((value, index) => {
    if (index > number - 1) return value;
  });

  if (end) return array.reverse();

  return array;
}

export const removeAccents = (string) => {
  return string.replace(/[άέίϊήόύϋώ]+/g, function (letter) {
    const map = {
      ά: "α",
      έ: "ε",
      ί: "ι",
      ό: "ο",
      ύ: "υ",
      ϋ: "υ",
      ϊ: "ι",
      ή: "η",
      ώ: "ω",
    };
    return map[letter];
  });
};

export const getEventRange = (startDate, endDate) => {
  const start = moment(startDate),
    end = moment(endDate);

  if (start.month() === end.month() && start.year() === end.year()) {
    // case 1.. the event starts
    // and ends in the same month
    // in the same year

    if (start.date() === end.date()) {
      return `${start.date()} ${end.format("MMMM")} ${end.year()}`;
    }

    return `${start.date()} - ${end.date()} ${end.format(
      "MMMM"
    )} ${end.year()}`;
  } else {
    if (start.year() === end.year()) {
      return `${start.date()} ${start.format(
        "MMMM"
      )} - ${end.date()} ${end.format("MMMM")} ${end.year()}`;
    } else {
      return `${start.date()} ${start.format(
        "MMMM"
      )} ${start.year()} - ${end.date()} ${end.format("MMMM")} ${end.year()}`;
    }
  }
};

export const isArray = (variable) => {
  if (variable) {
    return variable.constructor === Array;
  } else {
    return false;
  }
};

export const isObject = (variable) => {
  return (
    variable !== null &&
    typeof variable === "object" &&
    !Array.isArray(variable)
  );
};

export const isEmpty = (variable) => {
  if (!variable) {
    return true;
  }
  if (variable.constructor === Object) {
    return Object.keys(variable).length === 0;
  } else if (variable.constructor === Array) {
    return variable.length === 0;
  }
  return false;
};

export const rootLevelObject = (
  object,
  rootKey = "",
  rootObject = {},
  firstTime = true
) => {
  if (firstTime) {
    rootObject.__rootObject = object;
  }
  Object.entries(object).map(([k, v]) => {
    const newKey =
      rootKey.length === 0
        ? k
        : rootKey + k.charAt(0).toUpperCase() + k.slice(1);
    if (isObject(v)) {
      if (!rootObject.hasOwnProperty(k)) {
        rootLevelObject(v, newKey, rootObject, false);
      } else {
        rootLevelObject(v, newKey, rootObject, false);
      }
    } else {
      if (!rootObject.hasOwnProperty(k)) {
        rootObject[k] = v;
      } else if (!rootObject.hasOwnProperty(newKey)) {
        rootObject[newKey] = v;
      }
    }
  });

  return rootObject;
};

export const getSectionKey = (key) => {
  switch (key) {
    case "abstractManagement": {
      return "abstracts";
    }

    case "hotelManagement": {
      return "accomodation";
    }

    case "billing": {
      return "payments";
    }
  }
};

export const makePassword = (length) => {
  const result = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};
export const getMessage = (array) => {
  const updateGroup = ["edit", "update"];

  let entity = array[1].toLowerCase(),
    action = array[2].toLowerCase();

  if (updateGroup.includes(action)) {
    if (entity.slice(-1) === "s") {
      // remove plural
      entity =
        entity === "accesspolicies"
          ? "Access Policy"
          : capitalizeFirstLetter(entity.slice(0, -1));
    }
    action = "updated successfully!";
  }

  return `${entity} ${action}`;
};

export const toggleFullScreen = () => {
  screenfull.toggle();
};

export const isNumeric = (variable) => {
  const test = variable.trim();
  if (test && !isNaN(test)) {
    return true;
  }
  return false;
};

export const defaultPrintingSettings = {
  nameStyle: {
    left: "45%",
    top: "50%",
    fontSize: `14px`,
    fontFamily: "Arial",
  },
  idStyle: {
    right: `5%`,
    top: `5%`,
    fontSize: `14px`,
    fontFamily: "Arial",
  },
  certStyle: {
    right: "40%",
    bottom: "5%",
    fontSize: `14px`,
    fontFamily: "Arial",
  },
  paperSize: "A4",
  certText: "Certification %POINTS% Text",
  printBackground: "Yes",
  orientation: "portrait",
  showName: 1,
  showId: 1,
  showCertText: 1,
};

export const convertToPx = (cm) => {
  const measure = 37.795276;
  return cm * measure;
};

export const defaultCardNativeSettings = {
  nameStyle: {
    left: "0",
    top: "43%",
    fontSize: "20px",
  },
  bannerStyle: {
    left: "0%",
    top: "2%",
  },
  barcodeStyle: {
    left: "28%",
    bottom: "0%",
  },
  paperSize: "A4",
  orientation: "portrait",
  sameLine: false,
  hasBanner: false,
  foldedPaper: false,
  fullWidth: false,
  bannerUrl: "",
  gapSize: 0,
};

export const deferScript = (src) => {
  return new Promise((resolve, reject) => {
    const s = document.createElement("script");
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const remove = (array, index) => array.filter((e, i) => i !== index);

export const isOnline = () => navigator.onLine;

export const createContactLabel = (item, vars) => {
  const roles = {
    organisation_admin: "Organisation Admin",
    event_admin: "Event Admin",
    event_judge: "Event Judge",
    any_role: "Any Role",
  };

  let label = "";
  if (item.contactSubject) {
    label += ` - The ${vars.subjectLabel} of the hook`;
  }

  if (item.contactObject) {
    label += ` - The ${vars.objectLabel} of the hook`;
  }

  if (item.contactAdmin) {
    label +=
      " - The Admins of the event with role '" + roles[item.admins.role] + "'";
  }

  if (item.contactUser) {
    label += " - The Users of the event";
    if (item.users.speaker) {
      label += " with role 'Speaker'";
    }
    if (item.users.chairperson) {
      label += " and role 'Chaiperson'";
    }
    if (item.users.allPolicyUsers) {
      label += " from all policies (private/crm)";
    }
  }

  if (item.contactEmail) {
    label += " - Specific emails: " + item.emails.toString();
  }
  let error = false;
  if (item.contactGroup) {
    try {
      label += ` - Users from Group: ${
        vars.groups.filter((group) => group.id == item.groups)[0].name
      } `;
    } catch (e) {
      label += ` - Users from Groups: <Group not found in policy>`;
      error = true;
    }
  }
  return (
    <span className={error || !label ? "error" : ""}>
      {label || "Empty Contact"}
    </span>
  );
};

export const replaceSpanWithDataOriginalVar = (str) => {
  const regex = /<span[^>]*data-original-var="([^"]+)"[^>]*>[^<]*<\/span>/gi;
  return str.replace(regex, (match, capture) => `{{${capture}}}`);
};

export const RegexPatterns = {
  TWO_DECIMAL_LIMIT: /^[0-9]+(\.[0-9]{1,2})?$/,
  EMAIL: /^[a-zA-Z0-9._+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
};

export const checkRoomNameDuplicate = async (
  dispatch,
  hotelId,
  newRoomName,
  roomId
) => {
  const existingRoomsResponse = await dispatch(getEventRooms(hotelId));
  const existingRooms = existingRoomsResponse.data;
  return existingRooms.some(
    (room) => room.name === newRoomName && room.id !== roomId
  );
};

export const splitLastOccurrence = (str, charToFind) => {
  const lastIndex = str.lastIndexOf(charToFind);

  const before = str.slice(0, lastIndex);

  const after = str.slice(lastIndex + 1);
  return [before, after];
};
