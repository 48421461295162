import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import Select2 from "@layout/Select2";
import { loadUsers } from "@actions/users";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import { createProduct } from "@actions/products";
import { updateAbstract, getAbstracts } from "@actions/abstracts";
import formFields from "@helpers/form-fields";

class EditAbstract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      judgesId: [],
      item: { ...this.props.item },
    };
  }
  componentDidMount() {
    const ids = [];
    if (this.props.schema.finalize) {
      formFields.forms["abstracts"].edit["status"].disabled = true;
      formFields.forms["abstracts"].edit["status"].options = {
        rejected: "Rejected",
        approved: "Approved",
      };
    }
    this.props.item.judging.length > 0 &&
      this.props.item.judging.map((judge) => {
        ids.push(judge.judgeUserId.toString());
      });
    this.setState({ judgesId: ids });
  }
  render() {
    const judges = {};
    this.props.judges &&
      this.props.judges.map((judge) => {
        judges[judge.id] = `${judge.id} ${judge.firstName} ${judge.lastName}`;
      });
    return (
      <React.Fragment>
        <h2>Edit Abstract</h2>
        <p className="subtitle">Abstract Id: #{this.state.item.id}</p>
        <br />
        <div className="new-staff form-container">
          <div className="form-group">
            <label>Manual Judge Assignment</label>
            <Select2
              disabled={this.props.schema.finalize}
              options={judges}
              onChange={(val) => {
                this.setState({ judgesId: val });
              }}
              placeholder={"Judges"}
              value={
                this.state.judgesId.length === 0 ? "" : this.state.judgesId
              }
              multi={true}
            />
          </div>
          <Form
            componentName={"abstracts"}
            formName="edit"
            value={this.state.item}
            onSubmit={(data) => {
              if (Object.keys(data.errors).length) return false;
              if (
                !this.state.item.publishedAbstractThumbnail?.length &&
                !data.value.publishedAbstractThumbnail.length &&
                (this.props.item.publishedAbstractThumbnail || [])[0]
              ) {
                data.value.publishedAbstractThumbnail = [
                  this.props.item.publishedAbstractThumbnail[0].id,
                ];
              } else if (!data.value.publishedAbstractThumbnail.length) {
                delete data.value.publishedAbstractThumbnail;
              }
              if (
                !this.state.item.publishedAbstractFile?.length &&
                !data.value.publishedAbstractFile.length &&
                (this.props.item.publishedAbstractFile || [])[0]
              ) {
                data.value.publishedAbstractFile = [
                  this.props.item.publishedAbstractFile[0].id,
                ];
              } else if (!data.value.publishedAbstractFile.length) {
                delete data.value.publishedAbstractFile;
              }
              data.value.judging = this.state.judgesId;
              this.props.updateAbstract(
                this.props.eventId,
                this.props.item.id,
                data.value,
                true //multipart form data
              );
            }}
            onCancel={this.props.hideModal}
            onChange={(data) => {
              if (data.key === "publishedAbstractThumbnail") {
                this.setState({
                  item: {
                    ...this.state.item,
                    publishedAbstractThumbnail: [],
                  },
                });
              } else if (data.key === "publishedAbstractFile") {
                this.setState({
                  item: {
                    ...this.state.item,
                    publishedAbstractFile: [],
                  },
                });
              }
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

EditAbstract.propTypes = {
  schema: PropTypes.object,
  eventId: PropTypes.number,
  h2: PropTypes.string,
  hideModal: PropTypes.func,
  updateAbstract: PropTypes.func,
  createProduct: PropTypes.func,
  values: PropTypes.array,
  item: PropTypes.object,
  judges: PropTypes.array,
  getAbstracts: PropTypes.func,
};

EditAbstract.defaultProps = {
  value: {},
};
const mapStateToProps = (state) => {
  return {
    policyId: state.page.params.pid,
    judges: state.api.abstracts.judges.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (queryString, policyId, eventId) => {
      dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    getAbstracts: () => {
      dispatch(getAbstracts());
    },
    createProduct: (eventId, data) => {
      dispatch(createProduct(eventId, data));
    },
    updateAbstract: (eventId, productId, data, multipart) => {
      return dispatch(
        updateAbstract(eventId, productId, data, undefined, multipart)
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAbstract);
