import { connect } from "react-redux";
import Filters from "./Filters";

const mapStateToProps = (state, ownProps) => {
  const data = state.api.events.list;

  return {
    organisations: ownProps.personId
      ? state.api.staff.roles.data.organisations
      : data.organisations,
    isOrgAdmin: state.appuser.data.orgAdmin === 1,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
