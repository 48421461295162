import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import Form from "@components/form";
import formFields from "@helpers/form-fields";

const SingleSponsor = (props) => {
  const [state, setState] = React.useState({ item: props.item });

  formFields.forms.clientPanel.sponsors.eventClientPanelSponsorTypeId.options =
    props.options.reduce((a, b) => ({ ...a, [b.id]: b.name }), {});
  return (
    <div className="form-container single-sponsor">
      <h2>{`${props.mode == "create" ? "Create Sponsor" : "Edit Sponsor"}`}</h2>
      <Form
        formName="sponsors"
        componentName="clientPanel"
        value={state.item}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;
          if (
            !state.item.clientSponsorImage?.length &&
            !data.value.clientSponsorImage.length &&
            (props.item.clientSponsorImage || [])[0]
          ) {
            data.value.clientSponsorImage = [
              props.item.clientSponsorImage[0].id,
            ];
          } else if (!data.value.clientSponsorImage.length) {
            delete data.value.clientSponsorImage;
          }
          props.onSave(data.value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
        onChange={(data) => {
          if (data.key === "clientSponsorImage") {
            setState({ item: { ...state.item, clientSponsorImage: [] } });
          }
        }}
      />
    </div>
  );
};

SingleSponsor.propTypes = {
  hideModal: PropTypes.func,
  item: PropTypes.object,
  options: PropTypes.array,
  onSave: PropTypes.func,
  mode: PropTypes.string,
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleSponsor);
