import React from "react";
import { connect } from "react-redux";
import { getHits } from "@actions";
import { loadRooms, loadUser } from "@actions/users";
import KMBLoader from "@layout/KMBLoader";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "@layout/Select2";
import { scanUser, editScan, deleteScan } from "@actions/users";
import { groupBy } from "@helpers";

class UserEvents extends React.Component {
  constructor(props) {
    super(props);
    [
      "setupData",
      "getRoomMinutes",
      "updateDate",
      "editScan",
      "divideScans",
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.initialized = false;
    this.state = {
      scans: [],
      roomFilter: null,
      newEventDate: null,
      newEventRoom: null,
      editScan: null,
      editScanDate: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { getHits, loadRooms, eventId, user, policyId } = this.props;
    loadRooms(eventId);
    getHits(eventId, policyId, user.id);
  }

  setupData(props) {
    this.setState({
      scans: this.divideScans(props.scans),
    });
  }

  getRoomMinutes(scans) {
    let newScans = [];
    Object.values(scans).forEach((v) => {
      newScans = [...newScans, ...v];
    });

    let minutes = 0,
      enterDate = null;

    newScans.reverse().forEach((s, i) => {
      if (s.scanType === "enter") {
        enterDate = moment(s.scanDate.tz);

        if (i === scans.length - 1) {
          // user still in the room.
          // let subtract from
          // the current date
          enterDate = moment(s.scanDate.tz);
          const now = moment();
          minutes += now.diff(enterDate, "minutes", true);
        }
      } else {
        const exitDate = moment(s.scanDate.tz);
        const duration = exitDate.diff(enterDate, "minutes", true);
        minutes += duration;
        enterDate = null;
      }
    });

    return Math.round(minutes);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.scans.length > 0 && !this.initialized) ||
      this.props.timestamp !== nextProps.timestamp
    ) {
      this.setupData(nextProps);
      this.initialized = true;
    }
  }

  updateDate(date, key) {
    if (!date || !moment(date).isValid()) {
      return this.setState({
        [key]: null,
      });
    }

    let newEventDate = date;
    const { event } = this.props;
    const { startDate, endDate } = event;
    if (date.isBefore(moment(startDate.tz))) {
      newEventDate = moment(startDate.tz);
    }
    if (date.isAfter(moment(endDate.tz))) {
      newEventDate = moment(endDate.tz);
    }
    this.setState({
      [key]: newEventDate,
    });
  }

  editScan(editScan, scan) {
    this.setState({
      editScan,
      editScanDate: moment(scan.scanDate.tz),
    });
  }

  changeRoom(e, roomFilter) {
    e.preventDefault();
    this.setState({
      roomFilter,
      newEventRoom: roomFilter,
      editScan: null,
    });
  }

  divideScans(scans) {
    return groupBy(scans, "id");
  }

  render() {
    const { fetching, rooms, user, event, scanUser, policyId } = this.props;
    let { scans } = this.state;
    const { roomFilter } = this.state;
    if (fetching) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }

    const name = `${user.firstName} ${user.lastName}`;
    let roomMinutes = 0,
      roomName = "",
      selectDisabled = false;
    const roomObj = {};

    if (roomFilter) {
      roomName = [...rooms].filter((r) => r.id === roomFilter)[0].name;
      const copyScans = { ...scans };
      scans = {};
      Object.entries(copyScans).forEach((scan) => {
        if (scan[1][0].eventRoomId === roomFilter) {
          scans[scan[0]] = scan[1];
        }
      });
      roomMinutes = this.getRoomMinutes({ ...scans });
      selectDisabled = true;
    }

    return (
      <div className="row user-events">
        <div className="sidebar col-md-3">
          <ul className="nav nav-pills nav-stacked">
            <li className={roomFilter === null ? "active" : ""}>
              <a href="#" onClick={(e) => this.changeRoom(e, null)}>
                All Rooms
              </a>
            </li>
            {rooms.map((r, i) => {
              roomObj[r.id] = r.name;
              return (
                <li
                  key={`room-${i}`}
                  className={roomFilter === r.id ? "active" : ""}
                >
                  <a href="#" onClick={(e) => this.changeRoom(e, r.id)}>
                    {r.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="add-event panel">
            <div className="panel-header">
              <h4>Add new event</h4>
            </div>
            <div className="panel-body">
              <div className="form-container">
                <div className="field-type">
                  <div className="date-icon-holder">
                    <Datetime
                      className="rdtPickerOpenUpwards"
                      dateFormat="DD-MM-YYYY"
                      timeFormat="HH:mm"
                      inputProps={{ placeholder: "Select a valid date" }}
                      closeOnSelect={true}
                      value={this.state.newEventDate}
                      onChange={(d) => this.updateDate(d, "newEventDate")}
                      isValidDate={(current) => {
                        return (
                          current.isSameOrAfter(
                            moment(event.startDate.tz),
                            "days"
                          ) &&
                          current.isSameOrBefore(
                            moment(event.endDate.tz),
                            "days"
                          )
                        );
                      }}
                    />
                    <span className="icon icon-date"></span>
                  </div>
                </div>
              </div>
              <Select
                options={roomObj}
                disabled={selectDisabled}
                placeholder="Select room"
                menuPlacement="top"
                value={this.state.newEventRoom}
                onChange={(newEventRoom) => this.setState({ newEventRoom })}
              />
              {this.state.newEventDate && this.state.newEventRoom && (
                <button
                  className="btn save add-event-btn"
                  onClick={() => {
                    scanUser(
                      event.id,
                      this.state.newEventRoom,
                      user.id,
                      this.state.newEventDate
                    ).then(() => {
                      this.props.getHits(
                        event.id,
                        this.props.policyId,
                        user.id
                      );
                      this.props.loadUser(event.id, user.id);
                    });
                  }}
                >
                  Add Event
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="main col-md-9">
          {Object.keys(scans).length === 0 && (
            <h3>No activity{roomFilter ? " in this room." : "."}</h3>
          )}
          {Object.keys(scans).length > 0 && (
            <div className="timeline-wrapper">
              {roomFilter && (
                <span className="minutes">
                  Minutes in room {roomName}: {roomMinutes}
                </span>
              )}
              <div id="timeline">
                <div className="entries">
                  {Object.entries(scans).map(([key, scanSet]) => {
                    return (
                      <div key={`w-${key}`} className="scans-wrapper clearfix">
                        {scanSet.map((s, i) => {
                          const deleteEnabled =
                            i === 0 &&
                            Object.keys(scans)[0] === key &&
                            s.scanMethod !== "allout";
                          return (
                            <div
                              key={`scan-${i}`}
                              className={`entry entry-${s.scanType}`}
                            >
                              <div className="title">
                                {`${name} ${s.scanType}ed the room ${s.eventRoomName}`}
                              </div>
                              <div className="body">
                                {this.state.editScan === `scan-${key}-${i}` ? (
                                  <div className="form-container">
                                    <div className="field-type">
                                      <div className="date-icon-holder">
                                        <Datetime
                                          dateFormat="DD-MM-YYYY"
                                          timeFormat="HH:mm"
                                          inputProps={{
                                            placeholder: "Select a valid date",
                                          }}
                                          closeOnSelect={true}
                                          value={this.state.editScanDate}
                                          onChange={(d) =>
                                            this.updateDate(d, "editScanDate")
                                          }
                                          isValidDate={(current) => {
                                            return (
                                              current.isSameOrAfter(
                                                moment(event.startDate.tz),
                                                "days"
                                              ) &&
                                              current.isSameOrBefore(
                                                moment(event.endDate.tz),
                                                "days"
                                              )
                                            );
                                          }}
                                        />
                                        <span className="icon icon-date"></span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <p>{s.scanDate.tz}</p>
                                )}
                                {s.scanMethod === "allout" && <p>All out</p>}
                                <div className="actions">
                                  {this.state.editScan ===
                                  `scan-${key}-${i}` ? (
                                    <div className="edit-actions-wrapper">
                                      <button
                                        className="btn rounded cancel"
                                        onClick={() =>
                                          this.setState({
                                            editScan: null,
                                            editScanDate: null,
                                          })
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn rounded save"
                                        onClick={() => {
                                          this.props
                                            .editScan(
                                              event.id,
                                              policyId,
                                              user.id,
                                              s.id,
                                              moment(
                                                this.state.editScanDate
                                              ).format("DD-MM-Y HH:mm:ss"),
                                              s.scanType
                                            )
                                            .then(() => {
                                              this.props.getHits(
                                                event.id,
                                                this.props.policyId,
                                                user.id
                                              );
                                              this.props.loadUser(
                                                event.id,
                                                user.id
                                              );
                                              this.setState({
                                                editScan: null,
                                                editScanDate: null,
                                              });
                                            });
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      className="btn edit-btn rounded cancel"
                                      onClick={() =>
                                        this.editScan(`scan-${key}-${i}`, s)
                                      }
                                    >
                                      Edit
                                    </button>
                                  )}
                                  <button
                                    className={`btn delete-btn rounded btn-danger${
                                      deleteEnabled ? "" : " disabled"
                                    }`}
                                    onClick={() => {
                                      if (s.scanType === "exit") {
                                        this.props
                                          .editScan(
                                            event.id,
                                            policyId,
                                            user.id,
                                            s.id,
                                            null,
                                            s.scanType
                                          )
                                          .then(() => {
                                            this.props.getHits(
                                              event.id,
                                              this.props.policyId,
                                              user.id
                                            );
                                            this.props.loadUser(
                                              event.id,
                                              user.id
                                            );
                                          });
                                      } else {
                                        this.props
                                          .deleteScan(
                                            event.id,
                                            policyId,
                                            user.id,
                                            s.id
                                          )
                                          .then(() => {
                                            this.props.getHits(
                                              event.id,
                                              this.props.policyId,
                                              user.id
                                            );
                                            this.props.loadUser(
                                              event.id,
                                              user.id
                                            );
                                          });
                                      }
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="delete-full">
                          <button
                            type="button"
                            className="btn delete-pair-btn rounded btn-danger"
                            onClick={() => {
                              this.props
                                .deleteScan(
                                  event.id,
                                  policyId,
                                  user.id,
                                  key.replace("group-", "")
                                )
                                .then(() => {
                                  this.props.getHits(
                                    event.id,
                                    this.props.policyId,
                                    user.id
                                  );
                                  this.props.loadUser(event.id, user.id);
                                });
                            }}
                          >
                            Delete Pair
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

UserEvents.propTypes = {
  getHits: PropTypes.func.isRequired,
  loadRooms: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  rooms: PropTypes.array,
  scans: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  scanUser: PropTypes.func.isRequired,
  timestamp: PropTypes.number,
  loadUser: PropTypes.func,
  editScan: PropTypes.func.isRequired,
  deleteScan: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.totalScans.fetching || state.rooms.fetching,
    rooms: state.rooms.data,
    scans: state.users.userEvents.data || [],
    timestamp: state.users.userEvents.timestamp,
  };
};

export default connect(mapStateToProps, {
  getHits,
  loadRooms,
  scanUser,
  loadUser,
  editScan,
  deleteScan,
})(UserEvents);
