import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadEvents } from "@actions";
import { savePerson } from "@actions/sessions";
import KMBLoader from "@layout/KMBLoader";
import SubmitContainer from "@layout/SubmitContainer";

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.fields = {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      createdAt: "Created At",
      updatedAt: "Updated At",
    };

    this.refData = {};
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.props.loadEvents();
  }

  generateField(key, value) {
    switch (key) {
      default: {
        return (
          <div className="kmb-text">
            <input
              className={`form-control${
                key === "updatedAt" || key === "createdAt" ? " disabled" : ""
              }`}
              placeholder={this.fields[key]}
              type={key == "password" ? "password" : "text"}
              defaultValue={
                key === "createdAt" || key === "updatedAt" ? value.tz : value
              }
              autoComplete="off"
              disabled={key === "createdAt" || key === "updatedAt"}
              ref={(ref) => (this.refData[key] = ref)}
            />
          </div>
        );
      }
    }
  }

  update() {
    const data = {};
    const { user } = this.props;
    this.interestedOn = ["firstName", "lastName", "email", "password"];

    Object.entries(this.refData).map(([key, ref]) => {
      if (this.interestedOn.includes(key)) {
        data[key] = ref.value;
      }
    });
    if (data.password === "") {
      delete data["password"];
    }
    const orgId = user.data.orgIds[0];

    this.props.save(orgId, data, user.data.id);
  }

  render() {
    const { user } = this.props;
    const events = [...this.props.events];
    if (user.data["accessEvents"] === null) {
      return "N/A";
    }
    return (
      <div className="my-account">
        <div className="top-bar">
          <h2>My Account</h2>
        </div>
        <div className="list-view">
          <div className="main-wrapper">
            {!user.ready && <KMBLoader rows="10" padding={24} height={53} />}
            {user.ready && (
              <div className="form-container">
                {Object.entries(this.fields).map(([key, label]) => {
                  if (!user.data.hasOwnProperty(key) && key !== "password") {
                    return null;
                  }
                  return (
                    <div key={key} className="form-group">
                      <label>{label}</label>
                      {this.generateField(key, user.data[key])}
                    </div>
                  );
                })}
                {events.length !== 0 && (
                  <div className="med-table-container">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Event ID</th>
                          <th>Event Name</th>
                          <th>Access Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(user.data["accessEvents"]).map(
                          ([k, v]) => {
                            const event = events.filter(
                              (e) => e.id === parseInt(k)
                            )[0];
                            if (event) {
                              return (
                                <tr key={k}>
                                  <td>{k}</td>
                                  <td>{event.name}</td>
                                  <td>{v.role}</td>
                                </tr>
                              );
                            }
                            return null;
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                <SubmitContainer onSubmit={this.update} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.appuser,
    events: state.api.events.list.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvents: () => {
      dispatch(loadEvents({ rpp: -1 }));
    },
    save: (orgId, data, personId) => {
      dispatch(
        savePerson(orgId, data, personId, "json", "staff", true, { orgId })
      );
    },
  };
};

Account.propTypes = {
  loadEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
