import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import SingleRoom from "./SingleRoom";
import Breadcrumbs from "@layout/Breadcrumbs";
export default class Rooms extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);

    this.columns = {
      cosmetic: {
        name: "",
      },
      id: {
        name: "ID",
      },
      name: {
        name: "Room Name",
      },
      description: {
        name: "Description",
      },
      facilities: {
        name: "Facilities",
      },
      capacity: {
        name: "capacity",
      },
      actions: {
        name: "",
      },
    };

    this.state = {
      rooms: [],
    };
  }
  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return (
          <div>
            <span className="icon-double-room"></span>
          </div>
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="hotel room"
            item={item}
            delete={true}
            onEdit={() =>
              this.props.showModal(
                <SingleRoom
                  hideModal={this.props.hideModal}
                  room={item}
                  hotelId={this.props.hotelId}
                />
              )
            }
            onDelete={() => {
              this.props.deleteRoom(this.props.hotelId, item.id);
            }}
          />
        );
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.hotel) return;
    if (
      JSON.stringify(nextProps.rooms) !== JSON.stringify(this.state.hotel.rooms)
    ) {
      const newHotel = JSON.parse(JSON.stringify(this.state.hotel));
      newHotel.rooms = nextProps.rooms;
      this.setState({ hotel: newHotel });
    }
  }
  componentDidMount() {
    this.props.getOrgHotel(this.props.hotelId).then(({ data: hotel }) => {
      this.setState({ hotel });
    });
  }

  render() {
    return (
      <div className="list-hotels-main">
        <div className="top-bar">
          <div>
            <div className="left"></div>
          </div>
          <div>
            <div className="right">
              <button
                type="button"
                className="btn create rounded"
                onClick={() =>
                  this.props.showModal(
                    <SingleRoom
                      hideModal={this.props.hideModal}
                      hotelId={this.props.hotelId}
                    />
                  )
                }
              >
                Create new Room
              </button>
            </div>
          </div>
        </div>
        <div className="list-view">
          <Breadcrumbs
            options={[
              {
                name: "Hotels",
                action: () => this.props.page.navigate("/administrator/hotels"),
              },
              { name: this.state?.hotel?.name },
              { name: "Rooms" },
            ]}
          />

          <div className="main-wrapper">
            <Table
              id={"hotel-rooms"}
              columns={this.columns}
              items={this.state.hotel?.rooms || []}
              onRender={this.onRender}
              updating={this.props.fetching}
            />
          </div>
        </div>
      </div>
    );
  }
}

Rooms.defaultProps = {
  hotel: {},
};

Rooms.propTypes = {
  hotel: PropTypes.object.isRequired,
  getOrgRooms: PropTypes.func.isRequired,
  getOrgHotel: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  orgId: PropTypes.number.isRequired,
  deleteRoom: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  hotelId: PropTypes.number,
  page: PropTypes.object,
};
