import * as React from "react";
const SvgSendSchedule = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#send_schedule_svg__a)">
      <path
        d="M13.333 9.833v3.5l2.334 1.167m3.5-1.167a5.833 5.833 0 1 1-11.667 0 5.833 5.833 0 0 1 11.667 0Z"
        stroke="#fff"
        strokeWidth={1.67}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.277 1.996A1 1 0 0 0 18.003.722L1.336 6.556a1 1 0 0 0-.076 1.857l6.589 2.928c.232-.64.574-1.229 1.003-1.743L4.378 7.61l10.15-3.552-4.403 4.402a5.806 5.806 0 0 1 3.763-.935l2.054-2.053-.809 2.31a5.82 5.82 0 0 1 1.787.95l2.357-6.736ZM13.062 19.16a5.817 5.817 0 0 1-1.36-.225 1 1 0 0 0 1.36.225Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="send_schedule_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSendSchedule;
