/* eslint-disable */
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { forgotPassword } from "@actions/appuser";
import { connect } from "react-redux";
import { useAuth } from "../../routers/partials/authProvider";
import Input from "@layout/Input";
import Logo from "../../icons/Logo";
import { useNavigate } from "react-router-dom";

function ForgotPasswordPage(props) {
  const { authenticated, setAuthenticated } = useAuth();
  const [showPasswordEnabled, setShowPasswordEnabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  if (authenticated) return <Navigate replace to="/" />;

  const handleForgotPassword = (e) => {
    e.preventDefault();
    return props
      .forgotPassword({ email })
      .then(({ meta }) => {
        setEmailSent(true);
      })
      .catch((e) => {
        setEmailSent(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <div className="login-page">
      <div className="left">
        <img className="main-image" src="/images/bluetree-with-idcard.png" />
        <img className="background-image" src="/images/login-background.png" />
        <div className="overlay-color"></div>
        <div className="text-wrapper">
          <Logo />
          <span>Event</span>
          <span>Management</span>
          <span>Platform</span>
        </div>
      </div>
      <div className="right" onSubmit={handleForgotPassword}>
        <div>
          <h2>
            Welcome to <span>BlueTree</span>
          </h2>
          <p>
            BlueTree is a web platform made for event organizers, to help them
            manage their events and tasks.
          </p>
          {!emailSent ?
            <>
              <h3>Please type your email</h3>
              <form className="form-container">
                <div className="form-group">
                  <label>Email</label>
                  <Input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    required
                    placeholder="Please type your email"
                  />
                </div>
                {errorMessage.length > 0 && (
                  <span className="error">{errorMessage}</span>
                )}
                <button className="btn save" type="submit">
                  Confirm
                </button>
              </form>
            </>
            : <div>
              <h3>Email sent successfully!</h3>
              <p>
                Please check your email for further instructions.
              </p>
            </div>
          }
          <br />
          <button className="btn transparent-light" type="button" onClick={()=>{
              return navigate("/login")
            }}>
              <span class="fa fa-angle-left" style={{marginTop: "-4px", marginRight:"5px"}}></span>
             Go to Login
            </button>
        </div>
      </div>
    </div>
  );
}
export default connect(null, { forgotPassword })(ForgotPasswordPage);