import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addNotification,
  loadEvent,
  updateEventFeaturesOrder,
  updateEventFeatureStatus,
} from "@actions";
import Switch from "@layout/Switch";
import Table from "@layout/Table";

const EnableReorder = (props) => {
  const onRender = (col, item) => {
    switch (col.key) {
      case "pageControl": {
        return (
          <React.Fragment>
            <div className={`page-controls`}>
              <div
                className={`page-label ${
                  item.key === "home" ? "home-label" : ""
                }`}
              >
                {item.label}
              </div>
              {item.key !== "home" && (
                <div
                  className={`page-toggle ${!item.enabled ? "disabled" : ""}`}
                >
                  <Switch
                    isLive={true}
                    id={`enable-reorder-${item.key}`}
                    isActive={item.enabled}
                    onChange={(value) => {
                      props.updateEventFeatureStatus(
                        props.event.id,
                        item,
                        value
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        );
      }
    }
  };

  return (
    <div className="panel-content-card enable-reorder-content enable-reorder">
      <div key={`type-item-${1}`} className="form-container">
        <Table
          columns={{
            pageControl: {
              name: "",
            },
          }}
          updating={false}
          items={props.pagesControls
            .filter((page_control) => {
              return page_control.key === "home";
            })
            .map((page_control, i) => {
              return {
                label: page_control.label,
                enabled: page_control.enabled,
                key: page_control.key,
                id: page_control.id,
                index: i,
              };
            })}
          containerClassName={`table-container features-table home-table`}
          onRender={onRender}
        />
        <Table
          sortable={true}
          onSortEnd={(newIndex, item, newItems) => {
            const newSortedList = newItems.map((sorted, index) => {
              return {
                id: sorted.key === "home" ? 1 : sorted.id,
                key: sorted.key,
                index: index + 2,
              };
            });
            newSortedList.unshift({
              id: 1,
              index: 1,
              key: "home",
            });
            props.updateEventFeaturesOrder(props.event.id, newSortedList);
          }}
          columns={{
            pageControl: {
              name: "",
            },
          }}
          updating={false}
          items={props.pagesControls
            .filter((page_control) => {
              return page_control.key !== "home";
            })
            .map((page_control, i) => {
              return {
                label: page_control.label,
                enabled: page_control.enabled,
                key: page_control.key,
                id: page_control.id,
                index: i,
              };
            })}
          containerClassName={`table-container features-table`}
          onRender={onRender}
        />
      </div>
    </div>
  );
};

EnableReorder.propTypes = {
  addNotification: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  pagesControls: PropTypes.array.isRequired,
  loadEvent: PropTypes.func.isRequired,
  updateEventFeatureStatus: PropTypes.func.isRequired,
  updateEventFeaturesOrder: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => dispatch(loadEvent(eventId)),
    addNotification: (msg, status) => dispatch(addNotification(msg, status)),
    updateEventFeatureStatus: (eventId, featureId, status) =>
      dispatch(updateEventFeatureStatus(eventId, featureId, status)),
    updateEventFeaturesOrder: (eventId, sortedFeatures) =>
      dispatch(updateEventFeaturesOrder(eventId, sortedFeatures)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableReorder);
