/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@helpers";
import Select from "@layout/Select2";

/**
 * Basic Info Section
 */
import BasicInfo from "../../sections/basicinfo/BasicInfo";
import Cover from "../../sections/basicinfo/Cover/";
import Options from "../../sections/basicinfo/Options/";
import Policies from "../../sections/basicinfo/AccessPolicies/";

/**
 * Registration Section
 */

import Pricing from "../../sections/registration/Pricing/";
import Fields from "../../sections/registration/Fields/";
import Subscriptions from "../../sections/registration/Subscriptions/";
import PolicyGroups from "../../sections/registration/PolicyGroups/PolicyGroups";
import Users from "@components/users/";
import Participants from "@components/users/";
import RegistrationSettings from "../../sections/registration/Settings";

import Guests from "@components/users/";
import GuestsGroups from "../../sections/registration/PolicyGroups/PolicyGroups";
import GuestsSettings from "../../sections/registration/Settings";
/**
 * Exhibition Hall Section
 */
import DigitalSlots from "../../sections/exhibitions/digitalSlots";
import DigitalCategories from "../../sections/exhibitions/digitalCategories";
import ExhibitorsList from "../../sections/exhibitions/exhibitorsList";

/**
 * Rooms Section
 */

import Rooms from "../../sections/rooms/";

/**
 * Settings Section
 */

import Printers from "../../sections/settings/Printers/";
import Api from "../../sections/settings/Api";

/**
 * Schedule Section
 */

import Sessions from "../../sections/schedule/";
import ScheduleSettings from "../../sections/schedule/partials/Settings/Settings";
import SpeakersChairpersons from "../../sections/schedule/partials/SpeakersChairpersons";

/**
 * Statistics Section
 */

import Statistics from "@components/statistics/Statistics/";
import Charts from "@components/statistics/Charts/";
import TotalScans from "@components/statistics/TotalScans/";
import Peak from "@components/statistics/Peak/";

/**
 * Payments Section
 */

import Payments from "../../sections/payments/";
import PaymentsList from "../../sections/payments/PaymentsList";
import Invoices from "../../sections/payments/Invoices";

/**
 * Hotels Section
 */

import Hotels from "../../sections/hotels/";
import Reservations from "../../sections/hotels/Reservations";

/**
 * Products Section
 */

import ProductsList from "../../sections/products/ProductsList";
import RentablesList from "../../sections/products/RentablesList";

/**
 * Abstracts Section
 */

import Schemas from "../../sections/abstracts/Schemas";
import AbstractsList from "../../sections/abstracts/AbstractsList";
import Judges from "../../sections/abstracts/Judges";

/**
 * Emails Section
 */
import Hooks from "../../sections/emails/Hooks";
import Presets from "../../sections/emails/Presets";
import Questionnaires from "../../sections/emails/Questionnaires";
import SentEmails from "../../sections/emails/SentEmails";
import CustomEmail from "../../sections/emails/CustomEmail";
import EmailSettings from "../../sections/emails/EmailSettings";

import CertPrinting from "@components/users/partials/CertPrinting";
import CardPrinting from "@components/users/partials/CardPrinting";

import ScannerDashboard from "@components/scanning/Dashboard";
import PaymentOptions from "../../sections/payments/PaymentOptions";
import Sponsorships from "../../sections/payments/Sponsorships";

import KMBLoader from "@layout/KMBLoader";

import SingleAbstract from "../../sections/abstracts/partials/SingleAbstract";

import { isObject } from "@helpers";
import SinglePolicy from "../../sections/basicinfo/AccessPolicies/SinglePolicy";
import SvgClientBuilder from "@app_icons/ClientBuilder";
import ClientBuilder from "../../sections/client_builder/ClientBuilder";

const components = {
  BasicInfo,
  Cover,
  Options,
  Policies,
  Users,
  Guests,
  GuestsGroups,
  GuestsSettings,
  Participants,
  RegistrationSettings,
  Rooms,
  Printers,
  Statistics,
  Charts,
  TotalScans,
  Peak,
  Api,
  EmailSettings,
  Hooks,
  Sessions,
  ScheduleSettings,
  SpeakersChairpersons,
  Payments,
  Pricing,
  Fields,
  Subscriptions,
  Hotels,
  Schemas,
  AbstractsList,
  CertPrinting,
  CardPrinting,
  SingleAbstract,
  ScannerDashboard,
  Judges,
  PolicyGroups,
  PaymentOptions,
  Sponsorships,
  Questionnaires,
  SendEmails: SentEmails,
  Presets,
  CustomEmail,
  Reservations,
  PaymentsList,
  Invoices,
  DigitalSlots,
  DigitalCategories,
  ExhibitorsList,
  ProductsList,
  RentablesList,
};

export default class EditMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: true,
      smallScreen: false,
    };
    this.toggleSubmenu = this.toggleSubmenu.bind(this);
    this.timeout = window.setTimeout(() => {}, 10);
  }
  componentDidMount() {
    const resize = () => {
      if (window.innerWidth < 991 && !this.state.smallScreen) {
        this.setState({ smallScreen: true, submenuOpen: false });
      } else if (window.innerWidth >= 991 && this.state.smallScreen) {
        this.setState({ smallScreen: false, submenuOpen: true });
      }
    };
    const handleResize = () => {
      window.clearTimeout(this.timeout);
      this.timeout = setTimeout(resize, 10);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }
  toggleSubmenu() {
    this.setState({ submenuOpen: !this.state.submenuOpen });
  }

  render() {
    if (!this.props.ready) {
      return null;
    }

    let { activeTab, tabItems } = this.props;
    const {
      sections,
      activeSection,
      changeSection,
      changeTab,
      fetching,
      event,
      loadEvent,
      updateEvent,
      updating,
    } = this.props;
    let activeChildren = false;
    let Component = null;
    let tabName = null;
    if (sections[activeSection]) {
      tabName = sections[activeSection].items[activeTab];
    } else {
      tabName = null;
    }

    if (activeTab) {
      if (activeTab.indexOf("/") !== -1) {
        // children active
        activeChildren = activeTab.split("/")[1];
        activeTab = activeTab.split("/")[0];
        tabName =
          sections[activeSection].items[activeTab].children[activeChildren] ||
          null;
      }

      if (isObject(tabName)) {
        if (tabName.hasOwnProperty("sidebar") && tabName.sidebar === false) {
          tabItems = {};
        }

        tabName = tabName.title;
      }

      if (activeChildren) {
        Component = components.hasOwnProperty(activeChildren)
          ? components[activeChildren]
          : null;
      } else {
        Component = components.hasOwnProperty(activeTab)
          ? components[activeTab]
          : null;
      }

      if (activeTab === "ScanningBanner") {
        Component = components["Cover"];
      }
    } else {
      Component = components.hasOwnProperty(
        capitalizeFirstLetter(activeSection)
      )
        ? components[capitalizeFirstLetter(activeSection)]
        : null;
      if (Component) {
        tabName = sections[activeSection].title;
        activeTab = capitalizeFirstLetter(activeSection);
      }
    }
    const selected = Object.entries(tabItems)
      .filter(([key]) => key === activeTab)
      .map(([, v]) => v);

    if (typeof selected[0] !== "string") {
      selected[0] = selected[0]?.title || "";
    }
    return (
      <div className="edit-menu">
        <div className={`main-grid`}>
          {Object.keys(sections).length > 0 && (
            <div
              className={`tab-items ${this.state.submenuOpen ? "open" : ""}`}
            >
              <ul className={`${this.state.submenuOpen ? "open" : ""}`}>
                <li className="header-burger" onClick={this.toggleSubmenu}>
                  <button className="btn transparent">
                    <span
                      className="icon-close-panel"
                      style={{
                        fontSize: 16,
                        transform: !this.state.submenuOpen
                          ? "rotate(180deg)"
                          : "",
                        marginRight: !this.state.submenuOpen ? 14 : 0,
                        paddingRight: this.state.submenuOpen ? 21 : "inherit",
                      }}
                    ></span>
                    Workspace
                  </button>
                </li>
                {Object.entries(sections).map(([key, value]) => {
                  const children = false;
                  let icon = "";
                  if (isObject(value)) {
                    icon = value.icon;
                    value = value.title;
                  }

                  return (
                    <li
                      key={`tab-${key}`}
                      className={key === activeSection ? "active" : ""}
                    >
                      <button
                        type="button"
                        className="btn transparent"
                        onClick={() => changeSection(key)}
                      >
                        {icon ? (
                          <span className={icon}></span>
                        ) : (
                          <SvgClientBuilder className={"client-builder-icon"} />
                        )}

                        {this.state.submenuOpen && value}
                      </button>
                      {children && (
                        <ul className="">
                          {Object.entries(children).map(([c, v]) => {
                            return (
                              <li
                                className={c === activeChildren ? "active" : ""}
                                key={v}
                              >
                                <button
                                  type="button"
                                  className="btn transparent"
                                  onClick={() => changeTab(key)}
                                >
                                  {c}
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div
            className={`inner-content ${!this.state.submenuOpen ? "max" : ""}`}
          >
            <div
              className={`section section-${activeTab.toLowerCase()} ${activeSection} ${
                this.props.fakeChild
              }`}
            >
              {activeSection === "client_builder" ? (
                <ClientBuilder />
              ) : (
                <React.Fragment>
                  <div className="top-menu">
                    <div>
                      {!fetching && !updating && (
                        <>
                          {activeSection === "registration" && (
                            <div className="left-tab">
                              <Select
                                options={{
                                  ...this.props.policies,
                                  "add-new": "+ Add new",
                                }}
                                controlBackground={"#E5EFFA"}
                                selectedBackground={"#E5EFFA"}
                                hideSelectedOptions={false}
                                notSelectable={["add-new"]}
                                onChange={(val) => {
                                  if (val === "add-new") {
                                    return this.props.showModal(
                                      <SinglePolicy
                                        eventId={this.props.event.id}
                                        item={{}}
                                        onSave={this.props.savePolicy}
                                        updating={this.props.policyUpdating}
                                        hideModal={this.props.hideModal}
                                      />
                                    );
                                  }
                                  this.props.setSelectedPolicy(val);
                                }}
                                placeholder={"Select Policy"}
                                value={this.props.selectedPolicy?.id}
                                isSearchable={false}
                                isClearable={false}
                                border={"none"}
                              />
                            </div>
                          )}
                          <div className="event-submenu-wrapper">
                            <ul className="event-submenu">
                              {Object.entries(tabItems).map(([key, value]) => {
                                if (value.visible == false) {
                                  return (
                                    <React.Fragment key={`section-${key}`} />
                                  );
                                }
                                if (isObject(value)) {
                                  value = value.title;
                                }
                                return (
                                  <li
                                    key={`section-${key}`}
                                    className={
                                      key === activeTab ? "active" : ""
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn transparent"
                                      onClick={() => changeTab(key)}
                                    >
                                      {value}
                                    </button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {tabName && (
                    <div
                      className={`tab tab-${
                        activeChildren
                          ? activeChildren.toLowerCase()
                          : activeTab.toLowerCase()
                      }`}
                    >
                      {(fetching || updating) && (
                        <KMBLoader rows={14} padding={24} height={53} />
                      )}
                      {!fetching && !updating && Component && (
                        <Component
                          activeTab={activeTab}
                          event={event}
                          loadEvent={loadEvent}
                          updateEvent={updateEvent}
                          updating={updating}
                        />
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditMenu.propTypes = {
  sections: PropTypes.object.isRequired,
  activeSection: PropTypes.string,
  activeTab: PropTypes.string,
  changeSection: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  tabItems: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  loadEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  fakeChild: PropTypes.string,
  policies: PropTypes.object,
  savePolicy: PropTypes.func,
  policyUpdating: PropTypes.bool,
  hideModal: PropTypes.func,
  selectedPolicy: PropTypes.object,
  setSelectedPolicy: PropTypes.func,
};
