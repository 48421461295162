import React from "react";
import PropTypes from "prop-types";
import ExhibitionSlots from "@layout/ExhibitionSlots";
import KMBLoader from "@layout/KMBLoader";
import SubmitContainer from "@layout/SubmitContainer";

const DigitalSettings = (props) => {
  const [state, setState] = React.useState({
    slots: [],
    newPremiumSlots: [],
    newNormalSlots: [],
  });

  const PREMIUM_SLOTS = "PremiumSlots";
  const NORMAL_SLOTS = "NormalSlots";
  const PREMIUM_SLOTS_LENGTH = 4;
  const NORMAL_SLOTS_LENGTH = 6;

  const makeSlotsStateFromResponse = (slots) => {
    return {
      slots,
      newPremiumSlots: slots.filter((slot) => slot.type == "premium"),
      newNormalSlots: slots.filter((slot) => slot.type == "normal"),
    };
  };

  React.useEffect(() => {
    Promise.all([props.loadExhibitionDigitalSlots(props.event.id)]);
  }, []);

  React.useEffect(() => {
    setState({
      ...state,
      loading: false,
      ...makeSlotsStateFromResponse(props.slots),
    });
  }, [props]);

  const onChangeSlots = (componentName, newSlots) => {
    let spliceNumber;
    if (componentName == PREMIUM_SLOTS) spliceNumber = PREMIUM_SLOTS_LENGTH;
    else spliceNumber = NORMAL_SLOTS_LENGTH;

    const normalizedSlots = [];
    for (let i = 0; i < newSlots.length - spliceNumber; i += spliceNumber) {
      normalizedSlots.push(newSlots[i]);
    }

    return setState({
      ...state,
      [`new${componentName}`]: normalizedSlots,
    });
  };

  const clearFields = () => {
    setState({
      ...makeSlotsStateFromResponse(props.slots),
    });
  };

  const onSave = () => {
    const allSlots = state.newPremiumSlots
      .map((slot) => ({ ...slot, type: "premium" }))
      .concat(
        state.newNormalSlots.map((slot) => ({ ...slot, type: "normal" }))
      );

    setState({ loading: true });

    props.updateExhibitionDigitalSlots(props.event.id, {
      allSlots,
    });
  };

  if (props.updating) {
    return <KMBLoader rows={15} padding={24} height={53} />;
  }
  return (
    <div className="tab-content">
      <div className="slots-wrapper form-container">
        <div className="form-group">
          <label>Premium Slots</label>
          <ExhibitionSlots
            componentName={PREMIUM_SLOTS}
            numberOfSlots={PREMIUM_SLOTS_LENGTH}
            slots={state.newPremiumSlots}
            name="PREMIUM SLOTS"
            onChange={onChangeSlots}
          />
        </div>

        <div className="form-group">
          <label>Normal Slots</label>
          <ExhibitionSlots
            componentName={NORMAL_SLOTS}
            numberOfSlots={NORMAL_SLOTS_LENGTH}
            slots={state.newNormalSlots}
            name="NORMAL SLOTS"
            onChange={onChangeSlots}
          />
        </div>
        <SubmitContainer onCancel={clearFields} onSubmit={onSave} />
      </div>
    </div>
  );
};
DigitalSettings.propTypes = {
  loadExhibitionDigitalSlots: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  loadExhibitionCategories: PropTypes.func.isRequired,
  updateExhibitionDigitalSlots: PropTypes.func.isRequired,
  slots: PropTypes.array,
  updating: PropTypes.bool,
};

export default DigitalSettings;
