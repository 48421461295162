import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default class UserNotificationsArea extends React.Component {
  constructor(props) {
    super(props);
    this.localStorageNotifications = this.getLocalStorageNotifications();
  }

  getLocalStorageNotifications() {
    const notifications = localStorage.getItem("errorNotifications");

    if (!notifications) {
      return [];
    }
    try {
      return JSON.parse(notifications);
    } catch (e) {
      console.error("Error notifications from localStorage", e);
      return [];
    }
  }

  render() {
    const { notifications } = this.props;
    const combinedNotifications = [
      ...this.localStorageNotifications,
      ...notifications,
    ];
    return (
      <div className="notifications-area">
        <h2>Notifications</h2>
        {combinedNotifications.length > 0 && (
          <div className="localstorage-errors">
            Error messages will be stored and visible on this browser for the
            next 72 hours.
          </div>
        )}
        {combinedNotifications.map((n, i) => {
          return (
            <div
              className={`notification-box type-${n.type}${
                n.className ? ` ${n.className}` : ""
              } ${n.viewed ? "viewed" : ""}`}
              key={`notification-${i}`}
            >
              <div className="notification-header">
                <div className="title">
                  <span className="icon-notification-icon"></span>
                  {n.title}
                </div>
                <div className="date">
                  {moment(n.date).format("DD MMMM YYYY HH:mm a")}
                </div>
              </div>
              {n.message && (
                <div className="notification-message">{n.message}</div>
              )}
              <div className="notification-actions">
                <div className="row">
                  {n.dismissible && (
                    <div className={`col-sm-6 dismiss`}>
                      <button
                        className={`btn transparent${
                          n.dismissible ? "" : " disabled"
                        }`}
                        onClick={() => this.props.dismissNotification(n.key)}
                      >
                        {n.dismissLabel}
                      </button>
                    </div>
                  )}
                  {n.resolvable && (
                    <div className="col-sm-6 resolve">
                      <button
                        className={`btn transparent${
                          n.resolvable ? "" : " disabled"
                        }`}
                        onClick={() => n.resolveCallback()}
                      >
                        {n.resolveLabel}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

UserNotificationsArea.propTypes = {
  notifications: PropTypes.array.isRequired,
  dismissNotification: PropTypes.func.isRequired,
};
