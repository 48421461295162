/* eslint-disable */
import { SelectEditor, Select2Editor } from "./excelComponents";
import React from "react";

const columns = {
  schedule: {
    actions: {
      name: "",
      frozen: true,
    },
    id: {
      name: "ID",
      resizable: true,
      width: 40,
      default: () => "",
    },
    name: {
      name: "Name",
      resizable: true,
      editable: true,
      default: () => "",
    },
    description: {
      name: "Description",
      resizable: true,
      editable: true,
      default: () => "",
    },
    room: {
      name: "Room",
      resizable: true,
      width: 120,
      editable: true,
      valueFormatter: (k, v) => {
        return v.name;
      },
      editor: (options) => <SelectEditor options={options} />,
      onChange: (rows, update, options) => {
        const newRows = rows.map((row) => {
          const newRow = { ...row };
          if (row.id === update.fromRowId) {
            newRow.room = options.room[update.updated.value];
          }
          return newRow;
        });
        if (
          JSON.stringify(newRows) === JSON.stringify(rows) ||
          update.updated.value === update.fromRowData.room
        ) {
          return false;
        }
        return newRows;
      },
      default: () => [],
    },
    chairpersons: {
      name: "Chairpersons",
      width: 280,
      resizable: true,
      editable: true,
      valueFormatter: (k, v) => {
        let chairpersons = "";
        if (v.length > 0) {
          v.forEach((person, i) => {
            const name = person.firstName + " " + person.lastName;
            chairpersons = chairpersons + name;
            if (i + 1 < v.length) {
              chairpersons = chairpersons + ",";
            }
          });
        }
        return chairpersons;
      },
      editor: (options) => <Select2Editor options={options} multi={true} />,
      onChange: (rows, update, options) => {
        const newRows = rows.map((row) => {
          const value = update.updated.value;
          const newRow = { ...row };
          if (row.id === update.fromRowId) {
            if (newRow.chairpersons === "") {
              newRow.chairpersons =
                options.chairpersons[value[value.length - 1]];
            } else {
              newRow.chairpersons =
                newRow.chairpersons +
                "," +
                options.chairpersons[value[value.length - 1]];
            }
          }
          return newRow;
        });
        if (
          JSON.stringify(newRows) === JSON.stringify(rows) ||
          update.updated.value === update.fromRowData.chairpersons
        ) {
          return false;
        }
        return newRows;
      },
      default: () => "",
    },
    startDate: {
      name: "Start Date",
      resizable: true,
      editable: true,
      width: 150,

      valueFormatter: (k, v) => {
        return v.tz;
      },
      default: (event) => event.startDate.tz,
    },
    endDate: {
      name: "End Date",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v.tz;
      },
      default: (event) => event.startDate.tz,
    },
    selfHostingVideo: {
      name: "Use Video Source",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v + "";
      },
      default: (event) => "",
    },
    videoThumbnail: {
      name: "Video Thumbnail",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v;
      },
      default: (event) => "",
    },
    videoUrl: {
      name: "Video Url",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v;
      },
      default: (event) => "",
    },
    urlStream: {
      name: "Error Url",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v;
      },
      default: (event) => "",
    },
    thirdPartyHosting: {
      name: "Use Iframe Source",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v + "";
      },
      default: (event) => "",
    },
    srcUrl: {
      name: "Source Url",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v;
      },
      default: (event) => "",
    },
  },
  speech: {
    actions: {
      name: "",
      frozen: true,
    },
    id: {
      name: "ID",
      resizable: true,
      width: 40,
    },
    name: {
      name: "Name",
      resizable: true,
      editable: true,
    },
    speakers: {
      name: "Speakers",
      width: 280,
      resizable: true,
      editable: true,
      valueFormatter: (k, v) => {
        let speakers = "";

        if (v.length > 0) {
          v.forEach((person, i) => {
            const name = person.firstName + " " + person.lastName;
            speakers = speakers + name;
            if (i + 1 < v.length) {
              speakers = speakers + ",";
            }
          });
        }
        return speakers;
      },
      editor: (options) => <Select2Editor options={options} multi={true} />,
      onChange: (rows, update, options) => {
        const newRows = rows.map((row) => {
          const value = update.updated.value;
          const newRow = { ...row };
          if (row.id === update.fromRowId) {
            if (newRow.speakers === "") {
              newRow.speakers = options.speakers[value[value.length - 1]];
            } else {
              newRow.speakers =
                newRow.speakers +
                "," +
                options.speakers[value[value.length - 1]];
            }
          }
          return newRow;
        });
        if (
          JSON.stringify(newRows) === JSON.stringify(rows) ||
          update.updated.value === update.fromRowData.speakers
        ) {
          return false;
        }
        return newRows;
      },
      default: () => "",
    },
    startDate: {
      name: "Start Date",
      resizable: true,
      editable: true,
      width: 150,

      valueFormatter: (k, v) => {
        return v.tz;
      },
      default: (event) => event.startDate.tz,
    },
    endDate: {
      name: "End Date",
      resizable: true,
      width: 150,
      editable: true,
      valueFormatter: (k, v) => {
        return v.tz;
      },
      default: (event) => event.endDate.tz,
    },
    videoThumbnail: {
      name: "Video Thumbnail",
      resizable: true,
      editable: true,
      valueFormatter: (k, v) => {
        return v;
      },
      default: (event) => "",
    },
    videoUrl: {
      name: "Video Url",
      resizable: true,
      editable: true,
      valueFormatter: (k, v) => {
        return v;
      },
      default: (event) => "",
    },
  },
  toArray: function (type, options) {
    const current = this[type];
    return Object.keys(current).map((key) => ({
      key,
      name: current[key].name,
      frozen: current[key].frozen,
      editable: current[key].editable,
      editor: current[key].editor && current[key].editor(options[key]),
      resizable: current[key].resizable,
      width: current[key].width,
      default: current[key].default,
      formatter: current[key].formatter,
    }));
  },
};

export default columns;
