import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addNotification } from "@actions";
import JsBarcode from "jsbarcode";

const PrinterWrapper = (props) => {
  const [content, setContent] = React.useState("");
  const [badgeSettings, setBadgeSettings] = React.useState();
  const [certSettings, setCertSettings] = React.useState();

  React.useEffect(() => {
    window.scriptCaller = () => {
      JsBarcode(".barcode").init();
      let loading = 0;
      const images = document.querySelectorAll(".whole-page-wrapper img");
      if (images.length === 0) {
        window.print();
      }
      images.forEach((element) => {
        if (element.complete) {
          loading++;
          if (images.length === loading) {
            loading = 0;
            window.print();
          }
        } else {
          element.onload = () => {
            loading++;
            if (images.length === loading) {
              loading = 0;
              window.print();
            }
          };
        }
      });
    };
  });

  React.useEffect(() => {
    if (props.badge) {
      setContent(props.badge);
    } else if (props.certification) {
      setContent(props.certification);
    }

    if (!props.badge && !props.certification) {
      setContent(null);
    }
  }, [props.badge, props.certification]);

  React.useEffect(() => {
    const type = typeof props.badgeSettings;
    if (type === "string") {
      try {
        setBadgeSettings(JSON.parse(props.badgeSettings));
      } catch (e) {
        props.addNotification(e, "error");
      }
    } else {
      setBadgeSettings(props.badgeSettings);
    }
  }, [props.badgeSettings]);

  React.useEffect(() => {
    const type = typeof props.certSettings;
    if (type === "string") {
      try {
        setBadgeSettings(JSON.parse(props.certSettings));
      } catch (e) {
        props.addNotification(e, "error");
      }
    } else {
      setCertSettings(props.certSettings);
    }
  }, [props.certSettings]);

  React.useEffect(() => {
    if (content && props.printFunc) props.printFunc();
  }, [content]);

  const renderSingleBadge = () => {
    return (
      <div
        className="card-wrapper"
        style={{
          pageBreakAfter: "always !important",
          top: `0vh`,
        }}
      >
        <div className="card-content page">
          <div
            className="regular-page page"
            style={{
              marginBottom: `${
                badgeSettings.foldedPaper ? badgeSettings.gapSize ?? 0 : 0
              }cm`,
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          {badgeSettings.foldedPaper && (
            <div
              className="reverse-page page"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    );
  };

  const renderMultiBadge = () => {
    return content.map((card, i) => {
      return (
        <div
          key={`badge-${i}`}
          style={{
            pageBreakAfter: "always !important",
            top: `${i * 100}vh`,
          }}
          className="card-wrapper"
        >
          <div className="card-content page">
            <div
              className="regular-page page"
              style={{
                marginBottom: `${
                  badgeSettings.foldedPaper ? badgeSettings.gapSize ?? 0 : 0
                }cm`,
              }}
              dangerouslySetInnerHTML={{ __html: card }}
            ></div>
            {badgeSettings.foldedPaper && (
              <div
                className="reverse-page page"
                dangerouslySetInnerHTML={{ __html: card }}
              ></div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderSingleCertificate = () => {
    return (
      <div
        className="card-wrapper"
        style={{
          pageBreakAfter: "always !important",
          top: `0vh`,
        }}
      >
        <div className="card-content page">
          <div
            className="regular-page page"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    );
  };

  const renderMultiCertificate = () => {
    return content.map((card, i) => {
      return (
        <div
          className="card-wrapper"
          key={`cert-${i}`}
          style={{
            pageBreakAfter: "always !important",
            top: `${i * 100}vh`,
            position: `absolute`,
            height: "100%",
            width: "100%",
          }}
        >
          <div className="card-content page">
            <div
              className="regular-page page"
              dangerouslySetInnerHTML={{ __html: card }}
            ></div>
          </div>
        </div>
      );
    });
  };

  const renderPrintingContent = () => {
    return (
      <React.Fragment>
        {badgeSettings &&
          props.badge &&
          (Array.isArray(content) ? renderMultiBadge() : renderSingleBadge())}
        {certSettings &&
          props.certification &&
          (Array.isArray(content)
            ? renderMultiCertificate()
            : renderSingleCertificate())}
      </React.Fragment>
    );
  };

  return (
    <div
      className={`whole-page-wrapper${
        props.nativePrintingActive ? " printing" : ""
      }`}
    >
      {renderPrintingContent()}
    </div>
  );
};

PrinterWrapper.propTypes = {
  fetching: PropTypes.bool,
  printFunc: PropTypes.func,
  addNotification: PropTypes.func,
  badgeSettings: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  certSettings: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  userSettings: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  foldedPaper: PropTypes.bool,
  nativePrintingActive: PropTypes.bool.isRequired,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  certification: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

PrinterWrapper.defaultProps = {
  badgeSettings: {},
  certSettings: {},
  userSettings: {
    action: "normal",
    points: false,
  },
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    printFunc: state.page.printing.callback,
    nativePrintingActive: state.page.printing.nativePrintingActive,
    badge: state.page.printing.badge,
    certification: state.page.printing.certification,
    badgeSettings: state.api.events.edit.data?.extra?.cardPrintingSettings,
    certSettings:
      state.api.events.edit.data?.extra?.certificationPrintingSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterWrapper);
