import React from "react";
import KMBDropzone from "@layout/KMBDropzone";
import PropTypes from "prop-types";
import { KMBDropzoneArea } from "@layout/Buttons";
export default class Cover extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleExit = this.handleExit.bind(this);

    this.count = 0;

    this.state = {
      hovered: false,
    };
  }

  handleEnter() {
    this.setState({
      hovered: true,
    });
  }

  handleExit() {
    this.setState({
      hovered: false,
    });
  }

  render() {
    return (
      <div
        className={`image-container`}
        style={{
          height: this.props.height,
          position: "relative",
          border: "1px dashed #dae4f0",
        }}
        onMouseLeave={this.handleExit}
      >
        <div
          className="back-side"
          onMouseEnter={this.handleEnter}
          onDragEnter={this.handleEnter}
        >
          {this.props.imageUrl && <img src={this.props.imageUrl} />}
        </div>
        {!this.props.imageUrl && (
          <div className="no-cover-image-cover">
            <KMBDropzone
              id={this.props.id}
              promptMessage={`No ${this.props.name || "files"} uploaded yet`}
              description={this.props.description}
              onDropRejected={() => {}}
              onDropAccepted={(file) => {
                this.props.onChange(file[0]);
              }}
            />
          </div>
        )}
        {this.state.hovered && this.props.imageUrl && (
          <div className="front-side">
            <KMBDropzoneArea
              onChange={(file) => {
                this.props.onChange(file[0]);
              }}
            />
            {this.props.imageUrl && (
              <div className="delete-cover">
                <p
                  onClick={() => {
                    this.props.onChange(null, true);
                  }}
                >
                  <span className="icon-delete"></span>
                  Delete Image
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Cover.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
