const labelXml = (fontSize = 14) => {
  return `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips">
      <PaperOrientation>Landscape</PaperOrientation>
      <Id>LargeAddress</Id>
      <PaperName>30321 Large Address</PaperName>
      <DrawCommands>
          <RoundRectangle X="0" Y="0" Width="2025" Height="5020" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
          <BarcodeObject>
              <Name>Barcode</Name>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
              <LinkedObjectName></LinkedObjectName>
              <Rotation>Rotation0</Rotation>
              <IsMirrored>False</IsMirrored>
              <IsVariable>False</IsVariable>
              <Text>1234-406T859823-RFU90F</Text>
              <Type>Code128Auto</Type>
              <Size>Small</Size>
              <TextPosition>Bottom</TextPosition>
              <TextFont Family="Lucida Grande" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <CheckSumFont Family="Lucida Grande" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <TextEmbedding>None</TextEmbedding>
              <ECLevel>0</ECLevel>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
          </BarcodeObject>
          <Bounds X="321.5997" Y="1100.461" Width="4612.8" Height="834.4888"/>
      </ObjectInfo>
      <ObjectInfo>
          <TextObject>
              <Name>firstLine</Name>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
              <LinkedObjectName></LinkedObjectName>
              <Rotation>Rotation0</Rotation>
              <IsMirrored>False</IsMirrored>
              <IsVariable>False</IsVariable>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <TextFitMode>None</TextFitMode>
              <UseFullFontHeight>True</UseFullFontHeight>
              <Verticalized>False</Verticalized>
              <StyledText>
                  <Element>
                      <String>ΕΠΩΝΥΜΟ</String>
                      <Attributes>
                          <Font Family="Arial" Size="${fontSize}" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
                      </Attributes>
                  </Element>
              </StyledText>
          </TextObject>
          <Bounds X="321.5997" Y="190.4124" Width="4425.373" Height="396.9"/>
      </ObjectInfo>
      <ObjectInfo>
          <TextObject>
              <Name>secondLine</Name>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
              <LinkedObjectName></LinkedObjectName>
              <Rotation>Rotation0</Rotation>
              <IsMirrored>False</IsMirrored>
              <IsVariable>False</IsVariable>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <TextFitMode>None</TextFitMode>
              <UseFullFontHeight>True</UseFullFontHeight>
              <Verticalized>False</Verticalized>
              <StyledText>
                  <Element>
                      <String>ΟΝΟΜΑ</String>
                      <Attributes>
                          <Font Family="Arial" Size="${fontSize}" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
                      </Attributes>
                  </Element>
              </StyledText>
          </TextObject>
          <Bounds X="321.5997" Y="582.8553" Width="4425.373" Height="396.9"/>
      </ObjectInfo>
  </DieCutLabel>`;
};

export default labelXml;
