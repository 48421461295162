import React from "react";
import Header from "@layout/Header";
import SideModal from "@layout/SideModal";
import ConfirmChanges from "@layout/ConfirmChanges";
import PropTypes from "prop-types";
import Modal from "@layout/Modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import PrinterWrapper from "./users/partials/PrinterWrapper/PrinterWrapper";
import {
  clearErrorNotifications,
  ONE_HOUR_MS,
} from "../helpers/ErrorsInLocalStorage";
export default class App extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.modalsRef = null;
    this.handleResize = this.handleResize.bind(this);
    this.historyBlocked = false;
    this.unblock = null;
    this.state = {
      unblock: false,
      smallScreen: false,
    };
    clearErrorNotifications();
    // delete local storage items on name errorNotifications what have the timestamp property more than 72 hours
    //  and add this in a setInterval to ensure that this will happen if the page remains open for more than 72 hours
    setInterval(clearErrorNotifications, ONE_HOUR_MS);
  }

  checkParent(element, classname) {
    if (!element.className) {
      return (
        element.parentNode && this.checkParent(element.parentNode, classname)
      );
    }
    if (typeof element.className === "string") {
      if (element.className.split(" ").indexOf(classname) >= 0) return true;
    }

    return (
      element.parentNode && this.checkParent(element.parentNode, classname)
    );
  }
  handleResize() {
    if (window.innerWidth < 700 && !this.state.smallScreen) {
      this.setState({ smallScreen: true });
    } else if (window.innerWidth >= 700 && this.state.smallScreen) {
      this.setState({ smallScreen: false });
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    if (!this.props.user.ready) {
      this.props.getUser().catch((err) => {
        console.error(err);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  buildWrapperClass(title, returnClass = "") {
    // useful to style unique content
    // with a wrapper class reference
    if (!title) return "";
    title = title.split(" ");

    for (const [index, segment] of title.entries()) {
      if (index > 1) break;

      if (index > 0) returnClass += "-";

      returnClass += `${segment.toLowerCase()}`;
    }

    if (!returnClass) return "";

    return returnClass;
  }

  render() {
    let pageType,
      wrapperClass = "";

    wrapperClass = this.buildWrapperClass(this.props.page.title);

    return (
      <React.Fragment>
        <PrinterWrapper style={{ display: "none" }} />
        <div
          className={`app-wrapper ${
            pageType === "scanning" ? "scanning" : "normal"
          }-page page-${wrapperClass}`}
        >
          <header>
            <Header
              user={{}}
              pageType={pageType}
              smallScreen={this.state.smallScreen}
            />
          </header>
          <main className="main-area">
            <div className="content">
              <div
                className={`component-wrapper ${wrapperClass} ${
                  this.props.page.contentLoaded ? "in" : ""
                }`}
              >
                {this.props.children}
              </div>
            </div>
          </main>
          <div className="modals" ref={(ref) => (this.modalsRef = ref)}>
            <SideModal
              data={this.props.modal}
              hideModal={this.props.hideModal}
            />
            <Modal
              title={this.props.normalModal.title}
              show={this.props.normalModal.active}
              onClose={this.props.hideNormalModal}
              footer={this.props.normalModal.footer || ""}
              className={this.props.normalModal.className}
            >
              {this.props.normalModal.content}
            </Modal>
            <ConfirmChanges
              data={this.props.tempRoute}
              confirmChanges={this.props.confirmChanges}
            />
            <ToastContainer
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              transition={Slide}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  changePage: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  promptChanges: PropTypes.func.isRequired,
  modal: PropTypes.object,
  normalModal: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  user: PropTypes.object,
  children: PropTypes.element,
  notifications: PropTypes.array,
  dismissNotification: PropTypes.func.isRequired,
  tempRoute: PropTypes.object,
  confirmChanges: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  hideNormalModal: PropTypes.func.isRequired,
};
