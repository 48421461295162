import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

function SubmitContainer(props) {
  const isFetching = props.fetchingQueue.length;
  const getText = (text) => {
    return isFetching ? (
      <CircularProgress size={20} style={{ color: "white" }} />
    ) : (
      text
    );
  };
  return (
    <div className="submit-container form-group">
      {props.onCancel && (
        <button
          disabled={props.cancelDisabled}
          className="btn cancel rounded"
          onClick={props.onCancel}
        >
          {props.cancelText || "Cancel"}
        </button>
      )}
      {props.onSubmit && (
        <button
          disabled={isFetching || props.disabled}
          type="submit"
          className="btn save rounded"
          onClick={(e) => {
            return props.onSubmit(e);
          }}
        >
          {getText(props.submitText || "Save")}
        </button>
      )}
      {props.onPublish && (
        <button
          disabled={isFetching || props.disabled}
          type="submit"
          className="btn save rounded"
          onClick={props.onPublish}
        >
          {getText(props.publishText || "Save and Publish")}
        </button>
      )}
    </div>
  );
}

SubmitContainer.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onPublish: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  publishText: PropTypes.string,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  fetchingQueue: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    fetchingQueue: state._.fetchingQueue,
  };
};
const mapDispatchToProps = () => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SubmitContainer);
