import React from "react";
import { arrayMove } from "react-sortable-hoc";
import KMBDropzone from "@layout/KMBDropzone";
import PropTypes from "prop-types";
import XLSHelper from "@helpers/xls-helper";
import KMBLoader from "@layout/KMBLoader";
import Select from "@layout/Select";
import Radio from "@layout/Radio";
import { downloadFile } from "@helpers";
import SubmitContainer from "@layout/SubmitContainer";
import Checkbox from "@layout/Checkbox";
import Table from "@layout/Table";
import { formChanged } from "../../../../../../../actions";
import { connect } from "react-redux";

class BulkImportUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFields: [],
      file: false,
      preview: [],
      buildingPreview: false,
      extension: null,
      separator: "comma",
      ignoreFirstRow: true,
    };

    this.selectField = this.selectField.bind(this);
    this.mergeSettings = this.mergeSettings.bind(this);
    this.processCsv = this.processCsv.bind(this);
    this.mergedSettings = this.mergeSettings(props.policy);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.changeSeparator = this.changeSeparator.bind(this);
    this.ignoreFirstRow = this.ignoreFirstRow.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.onRender = this.onRender.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.formChanged(this.state);
    }
  }
  mergeSettings(policy) {
    return Object.assign({}, policy.settings, policy.extraSettings);
  }

  exportTemplate(e, excel = false) {
    e.preventDefault();
    const rows = [];
    const header = [];
    const demoRow = [];
    const { urlAlias } = this.props.event;
    const fileName = `${urlAlias}-import-template`;
    for (let i = 0; i < this.state.activeFields.length; i++) {
      const field = this.state.activeFields[i];

      header.push(
        this.mergedSettings[field].required === 1
          ? `${this.mergedSettings[field].name} *`
          : this.mergedSettings[field].name
      );
      demoRow.push("Dummy Data");
    }

    rows.push(header);
    rows.push(demoRow);
    if (excel) {
      // excel template
      return XLSHelper.exportDefaultXLS(rows, fileName);
    }
    // csv template
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    downloadFile(
      encodeURI(csvContent),
      fileName + ".csv",
      document.getElementsByClassName("exportables")[0]
    );
  }

  ignoreFirstRow(value) {
    const ignoreFirstRow = value === "yes";
    this.setState({ ignoreFirstRow }, () => {
      this.previewFile(this.state.file[0], this.state.separator).then(
        ({ preview }) => this.setState({ preview })
      );
    });
  }

  onSortEnd(newIndex, item, newItems, oldIndex) {
    this.setState({
      activeFields: arrayMove(this.state.activeFields, oldIndex, newIndex),
    });
  }

  changeSeparator(separator) {
    this.setState(
      {
        separator,
      },
      () => {
        this.previewFile(this.state.file[0], separator).then(({ preview }) =>
          this.setState({ preview, buildingPreview: false })
        );
      }
    );
  }

  selectField(e) {
    const id = e.target.id;
    const activeFields = [...this.state.activeFields];
    const index = activeFields.indexOf(id);
    if (index !== -1) {
      activeFields.splice(index, 1);
    } else {
      activeFields.push(id);
    }
    this.setState({ activeFields });
  }

  previewFile(file, separator = "comma") {
    return new Promise((resolve, reject) => {
      const extension = file.name.split(".")?.pop();
      const reader = new FileReader();
      // Read file into memory as UTF-8

      extension === "csv"
        ? reader.readAsText(file)
        : reader.readAsBinaryString(file);

      const { ignoreFirstRow } = this.state;

      // Handle errors load
      reader.onload = (e) => {
        const tfile = e.target.result;
        const preview =
          extension === "csv"
            ? this.processCsv(tfile, separator, ignoreFirstRow)
            : XLSHelper.read(tfile, ignoreFirstRow);
        preview !== false && resolve({ preview, extension });
      };
      reader.onerror = (e) => {
        reject(e.target.error.name);
      };
    });
  }
  onRender(col, item) {
    switch (col.key) {
      case "name":
        return <div className="kmb-flex-center">{item.name}</div>;
      case "include": {
        return (
          <div className="kmb-flex-center">
            <Checkbox
              id={`${col.key}-${item.id}`}
              className="no-pointer-events"
              checked={Boolean(item.include)}
              readOnly={true}
            />
          </div>
        );
      }
    }
  }
  processCsv(csv, separator = "comma", ignoreFirstRow = false) {
    separator = separator === "comma" ? "," : ";";

    const rows = ignoreFirstRow ? 10 : 1;

    const allTextLines = csv.split(/\r\n|\n/);
    const preview = [];
    if (allTextLines.length === 0) {
      return preview;
    }
    for (let i = 0; i <= rows && i < allTextLines.length; i++) {
      if (ignoreFirstRow && i === 0) continue;
      const data = allTextLines[i].split(separator);
      const tarr = [];
      for (let j = 0; j < data.length; j++) {
        tarr.push(data[j]);
      }
      preview.push(tarr);
    }

    return preview;
  }

  render() {
    const policy = this.props.policy;
    const { extension, separator } = this.state;

    return (
      <div className="form-container bulk-import">
        <h2>Import Participants</h2>
        <p className="subtitle">for {policy.name} Policy</p>
        <div className="guide"></div>
        <div className="form-group">
          <div className="available-fields form-group">
            <h3>Available Participants Fields</h3>
            <ul className="fields-wrapper field-type">
              {Object.entries(this.mergedSettings).map(([k, v]) => {
                const isCrmAndFieldIsSpeaker =
                  policy.saveUsersToCrm && k === "speaker";
                if (
                  v.used === 0 ||
                  v.system_gen === 1 ||
                  k === "password" ||
                  isCrmAndFieldIsSpeaker
                ) {
                  return null;
                }
                return (
                  <li className="kmb-checkbox" key={k}>
                    <input
                      type="checkbox"
                      id={k}
                      onChange={this.selectField}
                      checked={this.state.activeFields.includes(k)}
                    />
                    <label htmlFor={k}>
                      {v.name}
                      {v.required === 1 ? " *" : ""}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="selected-fields form-group">
          <h3>Reorder the fields to match the columns in your XLS/CSV file.</h3>
          {this.state.activeFields.length > 0 && (
            <Table
              updating={false}
              trClassNameFunc={(item) => {
                let className = "information-row ";
                if (item.type === "section") {
                  className += "section-row";
                }
                return className;
              }}
              onSortEnd={this.onSortEnd}
              sortable={true}
              id={"information-fields"}
              columns={{
                name: {
                  type: "text",
                },
              }}
              items={this.state.activeFields.map((fieldId) => ({
                name: this.mergedSettings[fieldId].name,
                id: fieldId,
              }))}
              onRender={this.onRender}
            />
          )}
          {this.state.activeFields.length === 0 && (
            <p>No fields selected yet.</p>
          )}
          {this.state.activeFields.length > 0 && (
            <span className="exportables">
              <a
                href="#"
                className="export-template"
                onClick={(e) => this.exportTemplate(e)}
              >
                Download CSV Template
              </a>
              <a
                href="#"
                className="export-template"
                onClick={(e) => this.exportTemplate(e, true)}
              >
                Download XLSX Template
              </a>
            </span>
          )}
        </div>

        <div className="drop-area form-group">
          <h3>CSV or XLS file</h3>
          <KMBDropzone
            active={this.state.activeFields.length > 0}
            promptMessage={[
              <span key="decorative" className="icon-cloud"></span>,
            ]}
            accept=".csv,.xls,.xlsx"
            styleType="simple"
            onFileRemove={() => this.setState({ file: false, preview: [] })}
            onDropRejected={(message) => {
              this.props.addNotification(message, "error");
              this.setState({
                file: false,
                preview: [],
                extension: null,
                separator: "comma",
              });
            }}
            onDropAccepted={(file) => {
              this.setState(
                {
                  file,
                  buildingPreview: true,
                  extension: null,
                  separator: "comma",
                },
                () => {
                  this.previewFile(this.state.file[0])
                    .then(({ preview, extension }) =>
                      this.setState({
                        preview,
                        buildingPreview: false,
                        extension,
                      })
                    )
                    .catch((err) => {
                      this.setState(
                        { buildingPreview: false },
                        this.props.addNotification("error", err)
                      );
                    });
                }
              );
            }}
            style={{
              width: "100%",
              height: "auto",
              borderWidth: 1,
              borderColor: "#1DA4CF",
              borderStyle: "dashed",
              borderRadius: 4,
              backgroundImage: "none",
              padding: "25px 5px",
              backgroundColor: "#fff",
            }}
            activeStyle={{
              borderStyle: "dashed",
              borderColor: "#1DA4CF",
            }}
            acceptStyle={{
              borderStyle: "dashed",
              borderColor: "#14DA9E",
            }}
            rejectStyle={{
              borderStyle: "dashed",
              borderColor: "blue",
            }}
          ></KMBDropzone>
          {this.state.buildingPreview && (
            <KMBLoader rows={15} padding={24} height={53} />
          )}

          {this.state.preview.length >= 0 && (
            <div className="preview-panel">
              <h3>Preview File (First 10 rows)</h3>
              <div
                className="med-table-container "
                style={{
                  overflow: "auto",
                  minHeight: "200px",
                  padding: "8px",
                  borderRadius: "4px",
                  display: "flex",
                  background: "white",
                  width: "100%",
                }}
              >
                {this.state.preview.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        {this.state.activeFields.map((f, i) => (
                          <th key={`a-${i}`}>{this.mergedSettings[f].name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.preview.map((d, index) => (
                        <tr key={`row-${index}`}>
                          {this.state.activeFields.map((f, i) => (
                            <td key={`pre-${index}-${i}`}>{d[i]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div
                    style={{
                      overflow: "auto",
                      minHeight: "200px",
                      padding: "8px",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      width: "100%",
                    }}
                  >
                    <p>Please upload a file to preview.</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {extension === "csv" && (
            <div className="csv-seperator">
              <h3>CSV Separator</h3>
              <Select
                options={{
                  comma: "Comma",
                  semicolon: "Semicolon",
                }}
                value={separator}
                placeholderInsideSelect={false}
                onChange={(sep) => this.changeSeparator(sep)}
              />
            </div>
          )}
          <div className="ignore-first">
            <h3>Ignore First Row ( Header )</h3>
            <div className="form-container">
              <div className="field-type type-radio kmb-radio">
                <Radio
                  checked={this.state.ignoreFirstRow ? "yes" : "no"}
                  onChange={(val) => this.ignoreFirstRow(val)}
                />
              </div>
            </div>
          </div>
        </div>
        <p>Once you agree, click the save button.</p>
        <SubmitContainer
          onCancel={this.props.hideModal}
          onSubmit={() =>
            this.props.importUsers(
              {
                fields: this.state.activeFields,
                file: this.state.file[0],
                separator:
                  this.state.extension === "csv" ? this.state.separator : null,
                ignoreFirstRow: this.state.ignoreFirstRow,
              },
              policy.id,
              policy.eventId
            )
          }
        />
      </div>
    );
  }
}

BulkImportUsers.propTypes = {
  policy: PropTypes.object.isRequired,
  addNotification: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  importUsers: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  formChanged: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  formChanged,
};

export default connect(null, mapDispatchToProps)(BulkImportUsers);
