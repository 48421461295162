import React from "react";
import PropTypes from "prop-types";

const SubscriptionsHead = (props) => {
  const [showApply, setShowApply] = React.useState();
  const [applyToAllValue, setApplyToAllValue] = React.useState("");
  const { subsections, eventPeriodId, prices, errors } = props;

  const onShowApply = (subsect) =>
    showApply === `${subsect}-${eventPeriodId}`
      ? setShowApply()
      : setShowApply(`${subsect}-${eventPeriodId}`);

  const onApply = (subsect) => {
    const newPrices = { ...prices };
    const newErrors = { ...errors };
    for (const groupKey in newPrices[eventPeriodId]) {
      if (newPrices[eventPeriodId][groupKey].key.includes(subsect)) {
        newPrices[eventPeriodId][groupKey].value = applyToAllValue;
        newErrors[`${eventPeriodId}-${groupKey}`] = false;
      }
    }

    props.setPrices(newPrices);
    props.setErrors(newErrors);
    setApplyToAllValue("");
  };
  if (!subsections.length) {
    return <></>;
  }
  return (
    <tr>
      <td></td>
      {subsections.map((subsect, i) => (
        <td className="row-header" key={"subsection" + i}>
          <div>
            <div onClick={() => onShowApply(subsect)}>
              {subsect}
              <span className="icon-arrow-down-small"></span>
            </div>
            <div
              className={`apply-to-all${
                showApply === `${subsect}-${eventPeriodId}` ? " show" : ""
              }`}
            >
              <input
                value={applyToAllValue}
                className="form-control"
                onChange={(e) => setApplyToAllValue(e.target.value)}
              />
              <div>
                <button
                  className="btn btn-white"
                  onClick={() => onApply(subsect)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </td>
      ))}
    </tr>
  );
};
SubscriptionsHead.propTypes = {
  setPrices: PropTypes.func,
  setErrors: PropTypes.func,
  eventPeriodId: PropTypes.any,
  prices: PropTypes.object,
  errors: PropTypes.object,
  subsections: PropTypes.array,
};
export default SubscriptionsHead;
