import { connect } from "react-redux";
import SingleStaff from "./SingleStaff";
import { hideModal } from "@actions";
import { savePerson } from "@actions/sessions";

const mapStateToProps = (state, ownProps) => {
  return {
    orgId: ownProps.orgId,
    id: ownProps.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    save: (orgId, data, personId = false, contentType = "json", endpoint) => {
      dispatch(savePerson(orgId, data, personId, contentType, endpoint, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleStaff);
