import React from "react";
import Select from "@layout/Select2";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import { allOut } from "@actions/users";
import moment from "moment";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
class AllOutPast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: null,
      date: null,
    };
  }

  updateDate(date, key) {
    if (typeof date === "string") {
      const dateCheck = moment(date, "DD-MM-YYYY HH:mm", true);
      if (!dateCheck.isValid()) {
        return this.setState({
          [key]: date,
        });
      }
      date = dateCheck;
    }

    if (!date) {
      return this.setState({
        [key]: null,
      });
    }

    this.setState({
      [key]: date,
    });
  }

  render() {
    const { rooms, event, allOut } = this.props;
    const { room, date } = this.state;

    const trooms = {};
    rooms.forEach((room) => {
      trooms[room.id] = room.name;
    });

    return (
      <div className="form-container allout-past">
        <h2>All Out ( Past Date )</h2>
        <div className="form-group">
          <label htmlFor="">Select Room</label>
          <Select
            options={trooms}
            placeholder="Select a room"
            onChange={(room) => {
              this.setState({ room });
            }}
            value={this.state.room}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Select Date</label>
          <Datetime
            onChange={(date) => this.updateDate(date, "date")}
            dateFormat="DD-MM-YYYY"
            timeFormat="HH:mm"
            inputProps={{ placeholder: "Select a valid date" }}
            closeOnSelect={true}
            value={date}
          />
        </div>
        <SubmitContainer
          onCancel={this.props.hideModal}
          onSubmit={() => allOut(event.id, room, date)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rooms: state.rooms.data,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    allOut: (eventId, roomId, date) => dispatch(allOut(eventId, roomId, date)),
  };
};

AllOutPast.propTypes = {
  event: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  allOut: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllOutPast);
