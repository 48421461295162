/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Confirmer from "@layout/Confirmer";
import Confirm from "@layout/Confirm";
import Tooltip from "@material-ui/core/Tooltip";
import ReactDOM from "react-dom";

export default function TableActions(props) {
  const [wordConfirmed, setWordConfirmed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const timeoutRef = React.useRef();
  const { item, entity } = props;

  const deleteBody = (
    <div>
      <p>
        This action will permanently delete the {entity} with ID:{" "}
        <strong>{item.id}</strong>
      </p>
      <p>
        And name: <span>{item.name ?? item.title}</span>
      </p>
      <p>Are you sure that you want to delete this {entity}?</p>
      <p>If yes, then please type: CONFIRM DELETE</p>
      <Confirmer
        word="confirm delete"
        onSuccess={() => {
          setWordConfirmed(true);
        }}
        onFail={() => {
          if (wordConfirmed) {
            setWordConfirmed(false);
          }
        }}
      />
    </div>
  );

  const deleteTd = props.promptDeletion ? (
    <span className="confirm-holder">
      <Confirm
        onConfirm={() => {
          setWordConfirmed(false);
          props.onDelete(item, entity);
        }}
        body={deleteBody}
        className="delete-event"
        confirmText="Confirm Delete"
        disabled={wordConfirmed ? false : true}
        title="Delete Action! Are you sure?"
        onShow={() => {
          document.body.click();
        }}
        onClose={() => {
          setWordConfirmed(false);
        }}
      >
        <Tooltip title="Delete">
          <button type="button" className="btn circle">
            <span className="icon-delete"></span>
          </button>
        </Tooltip>
      </Confirm>
    </span>
  ) : (
    <Tooltip title="Delete">
      <button type="button" className="btn circle" onClick={props.onDelete}>
        <span className="icon-delete"></span>
      </button>
    </Tooltip>
  );

  const editTd = (
    <Tooltip title="Edit">
      <button
        type="button"
        className="btn circle"
        onClick={(e) => {
          e.stopPropagation();
          props.onEdit(item, entity);
        }}
      >
        <span className="icon-edit"></span>
      </button>
    </Tooltip>
  );
  const onBlurHandler = () => {
    timeoutRef.current = setTimeout(() => setToggled(false));
  };
  const onFocusHandler = () => {
    clearTimeout(timeoutRef.current);
  };
  const onClickHandler = () => {
    setToggled(!toggled);
  };
  // the following implementation for pointer events is based on
  // https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
  return (
    <div tabIndex={"10"} onBlur={onBlurHandler} onFocus={onFocusHandler}>
      <div onClick={onClickHandler} style={{ padding: "15px 25px" }}>
        <div>
          <div className="event-actions">
            <span className="toggle-actions"></span>
          </div>
          <div className={`actions-wrapper${toggled ? "" : " hidden"}`}>
            {props.customPosition === "left" && props.custom(toggled)}
            {props.edit && <div>{editTd}</div>}
            {props.delete && <div>{deleteTd}</div>}
            {props.customPosition === "right" && props.custom(toggled)}
          </div>
        </div>
      </div>
    </div>
  );
}

TableActions.defaultProps = {
  delete: true,
  edit: true,
  promptDeletion: true,
  onEdit: () => {},
  onDelete: () => {},
  custom: () => {},
  customPosition: "left",
};

TableActions.propTypes = {
  delete: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  custom: PropTypes.func.isRequired,
  customPosition: PropTypes.string,
  promptDeletion: PropTypes.bool,
};
