import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Switch from "@layout/Switch";
import InnerHook from "./partials/innerHook";

export default class Hooks extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      inner: this.props.innerHook,
    };
    this.columns = {
      hookId: {
        name: "ID",
      },
      hookLabel: {
        name: "Hook name",
      },
      emailNumber: {
        name: "No. of emails",
      },
      email: {
        name: "Active",
      },
    };
  }

  componentDidMount() {
    this.props.getHooks(this.props.event.id);
    this.props.loadGroups(this.props.event.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.innerHook) !==
      JSON.stringify(this.props.innerHook)
    ) {
      this.setState({ inner: nextProps.innerHook });
    }
  }
  onRender(col, item) {
    switch (col.key) {
      case "hookId": {
        return <div className="id-column">{col.value}</div>;
      }
      case "hookLabel": {
        return (
          <div
            className="email-label-column"
            onClick={() =>
              this.props.selectHook(item, "email", this.props.event.id)
            }
          >
            {col.value}
          </div>
        );
      }
      case "email_actions": {
        return (
          <span>
            <button
              className="btn transparent table-button"
              style={{ marginRight: 15 }}
              onClick={() =>
                this.props.selectHook(item, "email", this.props.event.id)
              }
            >
              <span className="fa fa-envelope-o" aria-hidden="true"></span>
              Edit Emails
            </button>
          </span>
        );
      }
      case "email": {
        return (
          <Switch
            id={`hook-email-active-${item.id}`}
            isActive={Number(col.value)}
            isLive={true}
            isDisabled={item.required}
            onChange={(active) => {
              if (!item.required) {
                return this.props.saveHook(this.props.event.id, item.id, {
                  data: { email: active },
                });
              }
              return null;
            }}
          />
        );
      }
      case "sms": {
        return (
          <Switch
            id={`hook-sms-active-${item.id}`}
            isActive={Number(col.value)}
            isLive={true}
            isDisabled={true}
            onChange={(active) =>
              this.props.saveHook(this.props.event.id, item.id, {
                data: { sms: active },
              })
            }
          />
        );
      }
      case "emailNumber": {
        return item.emailNum < 1 ? "-" : item.emailNum;
      }
    }
  }

  render() {
    if (this.state.inner.hook) {
      return <InnerHook hook={this.state.inner.hook} {...this.props} />;
    }

    return (
      <div className="tab-content">
        <div className="email-hooks-table indexed">
          <Table
            columns={this.columns}
            onRender={this.onRender}
            updating={this.props.fetching}
            items={this.props.hooks}
          />
        </div>
      </div>
    );
  }
}

Hooks.propTypes = {
  event: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
  getHooks: PropTypes.func.isRequired,
  saveHook: PropTypes.func.isRequired,
  hooks: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  selectHook: PropTypes.func.isRequired,
  innerHook: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  loadPresetEmails: PropTypes.func,
  accessEvents: PropTypes.any,
  loadGroups: PropTypes.func,
};
