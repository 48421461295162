import { api } from "@actions/ajax";
import { isEmpty, isOnline, downloadFile } from "@helpers";
import offLineScanner from "@helpers/offline-scanner";
import ApiHelper from "@helpers/api-helper";
import { hideModal, addNotification } from "@actions";
import moment from "moment";
import notificationObj from "@helpers/notification-object";
import xml from "@helpers/label-xml";
import { rootLevelObject } from "@helpers";
import { sendHitsToServer, getUserNotifications } from "@actions/appuser";
import { checkIfEmpty } from "../helpers";

export const LOAD_USERS = "LOAD_USERS";
export const SETUP_USERS = "SETUP_USERS";
export const SETUP_POLICY = "SETUP_POLICY";
export const LOAD_POLICY = "LOAD_POLICY";
export const CHANGE_FILTERS = "CHANGE_FILTERS";
export const LOAD_ROOMS = "LOAD_ROOMS";
export const SETUP_ROOMS = "SETUP_ROOMS";
export const REQUEST_USERS_LIST = "REQUEST_USERS_LIST";
export const CHANGE_ORDER_BY = "CHANGE_ORDER_BY";
export const REQUEST_ROOMS_LIST = "REQUEST_ROOMS_LIST";
export const ROOM_UPDATED = "ROOM_UPDATED";
export const ROOMS_SAVED = "ROOMS_SAVED";
export const REQUEST_ROOMS_CREATE = "REQUEST_ROOMS_CREATE";
export const REQUEST_ROOMS_EDIT = "REQUEST_ROOMS_EDIT";
export const USERS_ALL_OUT = "USERS_ALL_OUT";
export const SETUP_ROOM = "SETUP_ROOM";
export const USER_SCANNED = "USER_SCANNED";
export const CLEAR_SCAN = "CLEAR_SCAN";
export const USER_NOT_FOUND = "USER_NOT_FOUND";
export const REFRESH_MINUTES = "REFRESH_MINUTES";
export const SETUP_MASSIVE_EVENTS = "SETUP_MASSIVE_EVENTS";
export const CHECK_USER_MINUTES = "CHECK_USER_MINUTES";
export const CLEAR_USER_MINUTES = "CLEAR_USER_MINUTES";
export const RECEIVED_USERS_MINUTESCANNING = "RECEIVED_USERS_MINUTESCANNING";
export const UNDO_ALL_OUT = "UNDO_ALL_OUT";
export const OFFLINE_SCAN_MODE = "OFFLINE_SCAN_MODE";
export const ADVANCED_SEARCH_ACTIVE = "ADVANCED_SEARCH_ACTIVE";
export const SET_USERS_GROUP_ID = "SET_USERS_GROUP_ID";
export const SHOW_USER_EVENTS = "SHOW_USER_EVENTS";
export const HIDE_USER_EVENTS = "HIDE_USER_EVENTS";
export const REORDER_FILTERS = "REORDER_FILTERS";
export const RECEIVED_ADVANCED_LIST = "RECEIVED_ADVANCED_LIST";
export const REQUEST_ADVANCED_LIST = "REQUEST_ADVANCED_LIST";
export const REQUEST_USERS_ALLUSERSLIST = "REQUEST_USERS_ALLUSERSLIST";
export const RECEIVED_USERS_ALLUSERSLIST = "RECEIVE_USERS_ALLUSERSLIST";

export const SELECT_USER = "SELECT_USER";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";
export const USER_EDIT_MODE = "USER_EDIT_MODE";
export const RECEIVED_POLICYGROUPS_POLICYLIST =
  "RECEIVED_POLICYGROUPS_POLICYLIST";
export const TOGGLE_HIT = "TOGGLE_HIT";

export const getGroups =
  (eventId, policyId, query = "") =>
  (dispatch) => {
    return dispatch(
      api({
        endpoint: "policyGroups",
        action: "policyList",
        params: { eventId, policyId },
        query,
      })
    );
  };
export const setGroupId = (eventId, policyId, groupId) => (dispatch) =>
  dispatch({
    type: SET_USERS_GROUP_ID,
    eventId,
    policyId,
    groupId,
  });
export const editScan =
  (eventId, policyId, userId, scanId, date, scanType) => (dispatch) => {
    const request = {
      endpoint: "users",
      action: "editScan",
      params: { eventId, policyId, userId, scanId },
      body: {
        data: {
          date,
          scanType,
        },
      },
    };
    return dispatch(api(request)).then(() =>
      dispatch(addNotification("User event updated successfully!", "success"))
    );
  };

export const deleteScan = (eventId, policyId, userId, scanId) => (dispatch) => {
  const request = {
    endpoint: "users",
    action: "deleteScan",
    params: { eventId, policyId, userId, scanId },
  };
  return dispatch(api(request)).then(() =>
    dispatch(addNotification("User event delete successfully!", "success"))
  );
};

export const hideUserEvents = () => ({
  type: HIDE_USER_EVENTS,
});

export const showUserEvents = (userId) => ({
  type: SHOW_USER_EVENTS,
  userId,
});

export const exportUsers = (
  eventId,
  policyId,
  columns,
  userIds,
  all = false,
  groupId
) => {
  return (dispatch, getState) => {
    const { meta } = getState().users;

    const data = {
      rpp: all ? -1 : meta.totalRows,
      p: meta.p,
      order: meta.order,
      orderBy: meta.orderBy,
      participationType: meta.participationType,
      search: meta.search,
      minutes: meta.minutes,
      points: meta.points,
      currentRoom: meta.currentRoom,
      roomId: meta.roomId,
      dateRange: meta.dateRange,
      minutesRange: meta.minutesRange,
      info: meta.info,
      hasHit: meta.hasHit,
      columns,
    };
    if (groupId) {
      data["groupId"] = groupId;
    }
    for (const [k, d] of Object.entries(data)) {
      if (!d) delete [data[k]];
    }

    if (userIds.length) {
      data["userIds"] = userIds;
    }

    const request = {
      endpoint: "users",
      action: "export",
      params: { eventId, policyId },
      body: { data },
      dataType: "buffer",
    };

    dispatch(api(request)).then((file) => {
      const state = getState();
      const { urlAlias } = state.api.events.edit.data;
      const blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
      const objectUrl = URL.createObjectURL(blob);
      downloadFile(objectUrl, `${urlAlias}-users.xlsx`);
    });
  };
};

export const printCard = (user) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();

    const { printers } = state.page;
    if (printers.printerType === "generic") {
      return resolve(printers.printerType);
    }

    if (printers.selectedPrinter === null) {
      return dispatch(
        addNotification(
          "You have not configured any DYMO printer. Please configure one from the Settings / Printers Tab.",
          "error"
        )
      );
    }

    let cardSettings;
    try {
      cardSettings = JSON.parse(
        state.api.events.edit.data.extra.cardPrintingSettings
      );
    } catch (e) {
      return reject(e);
    }

    let firstLine = "";
    let secondLine = "";

    const labelXml = xml(parseInt(cardSettings.nameStyle.fontSize));
    const label = window.dymo.label.framework.openLabelXml(labelXml);
    const selectedPrinter = state.page.printers.selectedPrinter;
    label.setObjectText("Barcode", user.id);

    if (cardSettings.sameLine) {
      if (cardSettings.nameOrder === "first") {
        firstLine = `${user.firstName} ${user.lastName}`;
      } else {
        firstLine = `${user.lastName} ${user.firstName}`;
      }
    } else {
      if (cardSettings.nameOrder === "first") {
        firstLine = `${user.firstName}`;
        secondLine = `${user.lastName}`;
      } else {
        firstLine = `${user.lastName}`;
        secondLine = `${user.firstName}`;
      }
    }
    label.setObjectText("firstLine", firstLine);
    label.setObjectText("secondLine", secondLine);
    label.print(selectedPrinter);

    return resolve(printers.printerType);
  });
};

export const undoAllOut = (eventId, allOutId, roomId, endpoint = "rooms") => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "undoAllOut",
      params: {
        eventId,
        allOutId,
        roomId,
      },
    };

    dispatch(api(query))
      .then((response) => {
        if (response.data.deleted === 1) {
          dispatch({
            type: UNDO_ALL_OUT,
            id: allOutId,
            message: `Undo all out, on room with id: ${roomId}, completed successfully.`,
          });
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };
};

export const clearUserMinutes = () => {
  return {
    type: CLEAR_USER_MINUTES,
  };
};

export const checkUserMinutes = (eventId, userId, endpoint = "users") => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint,
        action: "get",
        query: "?info=true&minutes=true",
        params: {
          eventId,
          userId,
        },
      };

      dispatch(api(request))
        .then((user) => {
          dispatch({
            type: CHECK_USER_MINUTES,
            user,
          });
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  };
};

export const getMassiveEvents = (eventId, endpoint = "rooms") => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "massive",
      params: {
        eventId,
      },
    };

    dispatch(api(query)).then((massiveEvents) => {
      dispatch({
        type: SETUP_MASSIVE_EVENTS,
        massiveEvents,
      });
    });
  };
};

export const clearScan = (roomId) => {
  return {
    type: CLEAR_SCAN,
    roomId,
  };
};

const actualScanUser = (
  eventId,
  roomId,
  userId,
  date = false,
  endpoint = "rooms"
) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let adminScan = false;

      if (!date) {
        date = moment();
      } else {
        adminScan = true;
      }

      date = date.format(ApiHelper.dateFormat);

      const query = {
        endpoint,
        action: "scan",
        params: {
          eventId,
          roomId,
        },
        body: {
          data: {
            date,
            checks: false,
            userId: parseInt(userId),
          },
        },
      };

      if (!isOnline()) {
        const scans = offLineScanner.insert(eventId, roomId, query.body.data);
        const state = getState();

        if (
          !state.rooms.offline.data.hasOwnProperty(eventId) ||
          !state.rooms.offline.data[eventId].hasOwnProperty(roomId)
        ) {
          notificationObj.storageInvalidate(state.appuser.data.id);
        }

        dispatch({
          type: OFFLINE_SCAN_MODE,
          scans,
          eventId,
          roomId,
        });

        const response = {
          type: USER_SCANNED,
          currentScan: {
            data: {
              id: parseInt(userId),
              eventPolicyId: null,
              createdAt: null,
              active: null,
              registered: null,
              minutes: "N/A",
              info: {
                firstName: `User #${userId}`,
                lastName: "",
              },
              scanType: "offline",
              scanMethod: "manual",
              scanDate: date,
              eventRoomId: roomId,
              eventRoomName: null,
            },
          },
          roomId,
        };
        dispatch(response);
        resolve({ roomId });
        return;
      }

      dispatch(api(query))
        .then((currentScan) => {
          const response = {
            type: USER_SCANNED,
            currentScan,
            roomId,
          };
          if (adminScan) {
            dispatch(
              addNotification("User event added successfully!", "success")
            );
          }
          dispatch(response);
          resolve(response);
        })
        .catch((err) => {
          if (err.status === 404) {
            const response = {
              type: USER_NOT_FOUND,
              roomId,
            };
            dispatch(response);
            reject(response);
          }
        });
    });
  };
};

export const scanUser = (
  eventId,
  roomId,
  userId,
  date = false,
  endpoint = "rooms"
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const scansSync = offLineScanner.get(eventId, roomId);
      if (isOnline() && scansSync.length) {
        dispatch(sendHitsToServer(eventId, roomId, scansSync)).then(() => {
          offLineScanner.deleteChannel(eventId, roomId);
          dispatch(getUserNotifications());
          dispatch(actualScanUser(eventId, roomId, userId, date, endpoint))
            .then((data) => resolve(data))
            .catch((err) => reject(err));
        });
      } else {
        dispatch(actualScanUser(eventId, roomId, userId, date, endpoint))
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      }
    });
  };
};

export const loadRoom = (eventId, roomId, endpoint = "rooms") => {
  return (dispatch, getState) => {
    const state = getState();
    const room = [...state.rooms.data].filter((r) => {
      return r.id === parseInt(roomId) && r.eventId === parseInt(eventId);
    });

    if (room.length > 0) {
      // do we have the room
      // already?

      dispatch({
        type: SETUP_ROOM,
        room,
      });
      return;
    }

    const query = {
      endpoint,
      action: "get",
      params: {
        eventId,
        roomId,
      },
    };

    dispatch(api(query)).then((room) => {
      dispatch({
        type: SETUP_ROOM,
        room,
      });
    });
  };
};

export const allOut = (eventId, roomId, date = false, endpoint = "rooms") => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "allOut",
      params: {
        eventId,
        roomId,
      },
      body: {
        data: {
          date: date
            ? moment(date).format(ApiHelper.dateFormat)
            : moment().format(ApiHelper.dateFormat),
          checks: false,
        },
      },
    };

    dispatch(api(query)).then((res) => {
      dispatch({
        type: USERS_ALL_OUT,
        room: { data: res.data.room },
      });
      dispatch(getMassiveEvents(eventId));
      dispatch(
        addNotification("Users removed from the room successfully", "success")
      );
    });
  };
};
export const getCrmUsersSearch = (
  firstName,
  lastName,
  email,
  policyId,
  eventId
) => {
  const endpoint = "advanced";
  return (dispatch) => {
    const request = {
      endpoint,
      action: "list",
      params: {
        eventId,
      },
      body: {
        data: {
          firstName,
          lastName,
          email,
          eventPolicyId: policyId,
        },
      },
      extra: { preserveModal: true },
    };

    return dispatch(api(request));
  };
};

export const saveRooms = (eventId, rooms, endpoint = "rooms", capacity) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      const promises = [];

      rooms.forEach((name, i) => {
        const p = new Promise((resolve) => {
          const query = {
            endpoint,
            action: "create",
            params: {
              eventId,
            },
            body: {
              data: {
                name,
                capacity: capacity[i],
              },
            },
          };

          dispatch(api(query))
            .then((response) => {
              dispatch(loadRooms(eventId));
              resolve(response.data);
            })
            .catch(() => {
              dispatch(loadRooms(eventId));
            });
        });

        promises.push(p);
      });

      return promises
        .reduce((promiseChain, currentTask) => {
          return promiseChain.then((chainResults) =>
            currentTask.then((currentResult) => [
              ...chainResults,
              currentResult,
            ])
          );
        }, Promise.resolve([]))
        .then((results) => {
          dispatch({
            type: ROOMS_SAVED,
            rooms: results,
          });
          resolve(results);
        });
    });
  };
};

export const editRoom = (eventId, roomId, data, endpoint = "rooms") => {
  return (dispatch) => {
    const query = {
      endpoint,
      action: "edit",
      params: {
        eventId,
        roomId,
      },
      body: {
        data,
      },
    };

    if (data.hasOwnProperty("panelActive")) {
      query["extra"] = {
        withoutNotification: true,
      };
    }

    dispatch(api(query)).then((room) => {
      dispatch({
        type: ROOM_UPDATED,
        room,
      });
    });
  };
};

export const advancedUserSearch = (eventId, policyId, params, active) => {
  return (dispatch, getState) => {
    const state = getState();
    const { currentRoom, info, minutes, order, orderBy, points, rpp } =
      state.users.meta;
    dispatch({ type: ADVANCED_SEARCH_ACTIVE, active });
    const buildQuery = ApiHelper.prepareUserSearch(params);
    dispatch(
      loadUsers(
        eventId,
        policyId,
        { currentRoom, info, minutes, order, orderBy, points, rpp, p: 1 },
        false,
        buildQuery
      )
    );
  };
};

export const changeFilters = (eventId, policyId, filters, newSort = false) => {
  return (dispatch, getState) => {
    if (newSort) {
      const state = getState();

      const columns = {};
      newSort.forEach((filter) => {
        columns[filter.key] =
          state.users.columns[eventId][policyId][filter.key];
      });

      dispatch({
        type: REORDER_FILTERS,
        columns,
      });
    } else {
      filters.push("actions");

      dispatch({
        type: CHANGE_FILTERS,
        filters,
      });
    }
  };
};

export const loadUser =
  (eventId, userId, query = "?minutes=true") =>
  (dispatch) => {
    const request = {
      endpoint: "users",
      action: "get",
      params: { eventId, userId },
      query,
    };
    return dispatch(api(request)).catch(console.error);
  };
export const toggleHit = (hit) => (dispatch) =>
  dispatch({ type: TOGGLE_HIT, hit });

export const getParticipants = (options, participantsTab = false) => {
  const { eventId, policyId, endpoint = "users", action = "list" } = options;
  let { query = {} } = options;

  return (dispatch, getState) => {
    query.rpp ??= 20;
    query.p ??= 1;
    const state = getState();
    const advancedSearchOptions =
      (state.api.advancedSearchOptions[eventId] || {})[policyId] || {};
    if (participantsTab) {
      const {
        info = true,
        order = "DESC",
        orderBy = "id",
        p = 1,
        rpp = 20,
        groupId,
        subscriptions = true,
        withAbstracts = true,
        abstracts = true,
        products = true,
        accomodation = true,
        questionnaires = true,
        currentRoom = true,
        minutes = true,
        points = true,
        search,
      } = state.users.meta;

      const {
        hasHit,
        participationType,
        minutesRangeFrom = null,
        minutesRangeTo = null,
        dateFrom = null,
        dateTo = null,
        roomId,
      } = advancedSearchOptions;

      let minutesRange = {
        from: minutesRangeFrom,
        to: minutesRangeTo,
      };
      if (!checkIfEmpty(minutesRangeFrom)) {
        minutesRange ??= {};
        minutesRange.from = minutesRangeFrom;
      }
      if (!checkIfEmpty(minutesRangeTo)) {
        minutesRange ??= {};
        minutesRange.to = minutesRangeTo;
      }
      const dateRange = {
        from: dateFrom,
        to: dateTo,
      };

      query = Object.assign(
        {},
        {
          info,
          currentRoom,
          minutes,
          order,
          orderBy,
          points,
          abstracts,
          withAbstracts,
          accomodation,
          roomId,
          groupId,
          p,
          rpp,
          products,
          subscriptions,
          hasHit,
          participationType,
          minutesRange,
          questionnaires,
          dateRange,
          search,
        },
        query
      );
    }
    if (!query.dateRange?.from && !query.dateRange?.to)
      query.dateRange = undefined;
    if (!query.minutesRange?.from && !query.minutesRange?.to)
      query.minutesRange = undefined;
    const queryOptions =
      "?" +
      Object.entries(query)
        .filter(
          (keyValue) => typeof keyValue[1] !== "undefined" && keyValue[1] !== ""
        )
        .map(
          ([key, value]) =>
            `${key}=${
              typeof value === "object" ? JSON.stringify(value) : value
            }`
        )
        .join("&");
    const request = {
      endpoint,
      action,
      query: queryOptions,
      params: {
        eventId,
        policyId,
      },
    };

    return dispatch(api(request))
      .then((users) => {
        if (participantsTab) {
          dispatch(setupUsers(policyId, eventId, users));
        }
        return users;
      })
      .catch((err) => Promise.reject(err));
  };
};
export const loadUsers = (
  eventId,
  policyId,
  criteria = { orderBy: "id" },
  orderingRequest = false,
  advancedSearch = false,
  onlyMinutes = false,
  userSearch = false,
  allPolicyUsersSearch = false,
  speakers = false,
  loadAll = false
) => {
  return (dispatch, getState) => {
    const state = getState();
    const advancedSearchOptions =
      (state.api.advancedSearchOptions[eventId] || {})[policyId] || {};

    for (const k in advancedSearchOptions) {
      if (
        advancedSearchOptions[k] === "" ||
        advancedSearchOptions[k] === null
      ) {
        delete advancedSearchOptions[k];
      }
    }

    advancedSearch = ApiHelper.prepareUserSearch(advancedSearchOptions);

    const existingCriteria = state.users.meta;
    const existingGroupId =
      ((state.users.groupId || {})[eventId] || {})[policyId] || "";
    existingCriteria.groupId = existingGroupId;
    let buildCriteria = Object.assign({}, existingCriteria, criteria);

    buildCriteria = ApiHelper.normalizeUserCriteria(buildCriteria);
    if (orderingRequest) {
      if (existingCriteria.orderBy === criteria.orderBy) {
        buildCriteria.order =
          existingCriteria.order === "DESC" ? "ASC" : "DESC";
      } else {
        buildCriteria.order = "DESC";
      }
    }

    let { p } = buildCriteria;
    const {
      rpp,
      orderBy,
      order,
      search,
      policyIds,
      minutes,
      groupId,
      points,
      currentRoom,
      info,
      products,
      policyTypes,
      subscriptions,
      accomodation,
      abstracts,
    } = buildCriteria;
    if (
      criteria.hasOwnProperty("search") &&
      criteria.search !== existingCriteria.search
    ) {
      p = 1;
    }
    if (existingCriteria.rpp !== buildCriteria.rpp) p = 1;
    let query = `?rpp=${rpp}&p=${p}&orderBy=${orderBy}&order=${order}&minutes=${minutes}
		&points=${points}&currentRoom=${currentRoom}&info=${info}
		&subscriptions=${subscriptions}&accomodation=${accomodation}&products=${products}&abstracts=${abstracts}`;

    if (groupId) {
      query += `&groupId=${groupId}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (policyIds) {
      query += `&policyIds=${policyIds}`;
    }
    if (policyTypes) {
      query += `&policyTypes=${policyTypes}`;
    }

    query += advancedSearch;

    const endpoint = "users";
    return new Promise((resolve, reject) => {
      if (orderingRequest) {
        dispatch({
          type: CHANGE_ORDER_BY,
          orderBy,
        });
      }
      const request = {
        endpoint,
        action: allPolicyUsersSearch
          ? "allUsersList"
          : userSearch
          ? "advancedList"
          : onlyMinutes
          ? "refreshMinutes"
          : "list",
        query: speakers ? "?rpp=-1&speaker=true" : query,
        params: {
          policyId,
          eventId,
        },
      };

      return dispatch(api(request))
        .then((users) => {
          if (!onlyMinutes && !userSearch && !allPolicyUsersSearch) {
            dispatch(setupUsers(policyId, eventId, users, loadAll));
          } else {
            dispatch(refreshMinutes(policyId, eventId, users));
          }
          resolve(users);
        })
        .catch((err) => reject(err));
    });
  };
};

export const loadSeachUsers = (eventId, policyId, criteria = {}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const {
        search = "",
        policyIds = "",
        rpp = -1,
        orderBy = "id",
        order = "DESC",
        speaker = false,
      } = criteria;

      let query = `?rpp=${rpp}&p=1&orderBy=${orderBy}&order=${order}&search=${search}&policyIds=${policyIds}`;

      if (speaker) {
        query += `&speaker=${speaker}`;
      }

      const request = {
        endpoint: "users",
        action: "searchUsersList",
        query: query,
        params: {
          eventId,
        },
      };

      return dispatch(api(request))
        .then((users) => {
          resolve(users);
        })
        .catch((err) => reject(err));
    });
  };
};

export const refreshMinutes = (policyId, eventId, users) => {
  return {
    type: REFRESH_MINUTES,
    users,
  };
};

export const loadPolicy = (policyId, eventId, queryParams = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const event = state.api.events.edit.data;

    // not found
    // we need to perform
    // ajax on api.

    const endpoint = "accesspolicies";

    const query = {
      endpoint,
      action: "get",
      params: {
        eventId,
        policyId,
      },
      query: queryParams.dropdownValuesAsObject
        ? "?dropdownValuesAsObject=true"
        : "",
    };

    return dispatch(api(query)).then((policy) => {
      dispatch({
        type: SETUP_POLICY,
        policy: policy.data,
        event,
      });
      return policy;
    });
  };
};

export const updateUser = (
  info,
  policyId,
  eventId,
  userId,
  saveAndPrint = false,
  action = "update",
  active = false
) => {
  const endpoint = "users";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let crmUserProfilePhoto = undefined;
      let body = {};

      if (!isEmpty(info.crmUserProfilePhoto)) {
        crmUserProfilePhoto = info.crmUserProfilePhoto;
        delete info.crmUserProfilePhoto;
        body = {
          info: JSON.stringify(info),
          crmUserProfilePhoto: crmUserProfilePhoto,
        };
      } else {
        body = {
          data: {
            info,
          },
        };
      }
      if (active) {
        body = {
          data: {
            active: info["active"],
          },
        };
      }
      const query = {
        endpoint,
        action,
        params: {
          policyId,
          eventId,
          userId,
        },
        body,
      };

      if (typeof info === "number") {
        query.body.data = {
          crmUserProfilePhoto: [info],
        };
      }
      return dispatch(api(query))
        .then((user) => {
          if (saveAndPrint) {
            const rootObjectUser = rootLevelObject(user.data);
            dispatch(printCard(rootObjectUser)).then((printerType) =>
              resolve({ user: rootObjectUser, printerType })
            );
          } else {
            resolve({ user });
          }
          dispatch(hideModal());
        })
        .catch((err) => reject(err));
    });
  };
};

export const getUserCertificate = (
  eventId,
  policyId,
  userId,
  overridePoints = false,
  points = 0,
  noPoints = false
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: "users",
        action: "getUserCertificate",
        query: `?htmlOnly=true&overridePoints=${overridePoints}&certPoints=${points}&noPoints=${noPoints}`,
        params: {
          eventId,
          policyId,
          userId,
        },
      };
      return dispatch(api(request))
        .then((dto) => resolve(dto.data))
        .catch((err) => reject(err));
    });
  };
};

export const getTestCertificate = (
  eventId,
  policyId,
  userId,
  overridePoints = false,
  points = 0,
  noPoints = false
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: "users",
        action: "getTestCertificate",
        query: `?htmlOnly=true&overridePoints=${overridePoints}&certPoints=${points}&noPoints=${noPoints}`,
        params: {
          eventId,
          policyId,
          userId,
        },
      };
      return dispatch(api(request))
        .then((dto) => resolve(dto.data))
        .catch((err) => reject(err));
    });
  };
};

export const getUsersCertificates = (
  eventId,
  policyId,
  users = [],
  overridePoints = false,
  points = 0,
  noPoints = false
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: "users",
        action: "getUserCertificates",
        query: `?htmlOnly=true&overridePoints=${overridePoints}&certPoints=${points}&noPoints=${noPoints}&userIds=${users.join(
          ","
        )}`,
        params: {
          eventId,
          policyId,
        },
      };
      return dispatch(api(request))
        .then((dto) => resolve(dto.data))
        .catch((err) => reject(err));
    });
  };
};

export const getUserBadge = (eventId, policyId, userId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: "users",
        action: "getUserBadge",
        query: "?checkFoldedPaper=true&htmlOnly=true",
        params: {
          eventId,
          policyId,
          userId,
        },
      };
      return dispatch(api(request))
        .then((dto) => resolve(dto))
        .catch((err) => reject(err));
    });
  };
};

export const getTestBadge = (eventId, policyId, userId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: "users",
        action: "getTestBadge",
        query: "?checkFoldedPaper=true&htmlOnly=true",
        params: {
          eventId,
          policyId,
          userId,
        },
      };
      return dispatch(api(request))
        .then((dto) => resolve(dto))
        .catch((err) => reject(err));
    });
  };
};

export const getUserBadges = (eventId, policyId, users) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: "users",
        action: "getUserBadges",
        query: `?checkFoldedPaper=true&htmlOnly=true&userIds=${users.join(
          ","
        )}`,
        params: {
          eventId,
          policyId,
        },
      };
      return dispatch(api(request))
        .then((dto) => resolve(dto.data))
        .catch((err) => reject(err));
    });
  };
};

export const loadRooms = (eventId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const endpoint = "rooms";
      const query = {
        endpoint,
        action: "list",
        params: {
          eventId,
        },
        query: "?rpp=-1",
      };

      dispatch(api(query))
        .then((rooms) => {
          dispatch({
            type: SETUP_ROOMS,
            rooms,
          });
          resolve(rooms);
        })
        .catch((err) => reject(err));
    });
  };
};

export const setupUsers = (policyId, eventId, users, loadAll) => {
  return (dispatch) => {
    if (loadAll) {
      dispatch(loadPolicy(policyId, eventId));
      dispatch(loadRooms(eventId));
    }

    dispatch({
      type: SETUP_USERS,
      users,
    });
  };
};
export const selectUser = (user) => (dispatch) => {
  const info = user.info;
  info["id"] = user.id;
  dispatch({ type: SELECT_USER, info });
};
export const clearSelectedUser = () => (dispatch) => {
  dispatch({ type: USER_EDIT_MODE, info: false });
  dispatch({ type: CLEAR_SELECTED_USER });
};

export const toggleUserEditMode = () => (dispatch) => {
  dispatch({ type: USER_EDIT_MODE });
};
