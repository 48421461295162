import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import Switch from "@layout/Switch";
import TableActions from "@layout/TableActions";
import SingleHotel from "./SingleHotel";
import Search from "@layout/Search";
import { HeaderPagination } from "@layout/Pagination";

export default class Hotels extends React.Component {
  constructor(props) {
    super(props);
    this.columns = {
      id: {
        name: "ID",
      },
      name: {
        name: "Hotel name",
      },
      facilities: {
        name: "Facilities",
      },
      description: {
        name: "Description",
      },
      stars: {
        name: "Stars",
      },
      setupRooms: {
        name: "Setup Rooms",
      },
      actions: {
        name: "",
      },
    };
    this.state = {
      selectedHotelId: "",
      search: "",
    };
    this.onRender = this.onRender.bind(this);
    this.fetched = false;
  }

  onRender(col, item) {
    switch (col.key) {
      case "id": {
        return <div>{item.id}</div>;
      }
      case "stars": {
        const html = [];
        for (let i = 1; i <= 6; i++) {
          if (i <= col.value) {
            html.push(
              <i
                key={`star-${item.id}-${i}`}
                className={`fa fa-star${" active"}`}
                aria-hidden="true"
              ></i>
            );
          }
        }
        return html;
      }
      case "facilities":
      case "description": {
        return <div>{col.value}</div>;
      }

      case "setupRooms": {
        return (
          <button
            className="btn transparent table-button"
            onClick={() => {
              this.props.page.navigate(
                `/administrator/hotels/${item.id}/rooms`
              );
            }}
          >
            <span className="icon-text-edit-bullets"></span>
            Setup Room Types
          </button>
        );
      }

      case "active": {
        const { saveHotel, orgId } = this.props;
        return (
          <Switch
            id={`hotel-active-${item.id}`}
            isActive={col.value}
            isLive={true}
            onChange={(active) =>
              saveHotel(
                orgId,
                { active },
                item.id,
                false,
                undefined,
                undefined,
                true,
                null,
                null,
                null,
                this.props.eventId
              )
            }
          />
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="hotel"
            item={item}
            onEdit={() =>
              this.props.showModal(<SingleHotel item={item} mode={"edit"} />)
            }
            onDelete={() => this.props.deleteItem(this.props.orgId, item.id)}
            delete={true}
          />
        );
      }
    }
  }

  componentDidMount() {
    this.props.getOrgHotels(this.props.orgId);
  }

  render() {
    return (
      <div className="list-hotels-main">
        <div className="top-bar">
          <div>
            <div className="right"></div>
          </div>
          <div>
            <div className="left">
              <Search
                onSearch={(search) => {
                  this.setState({ search });
                  this.props.getOrgHotels(this.props.orgId, {
                    rpp: this.props.meta.rpp,
                    p: 1,
                    search,
                  });
                }}
                visible={true}
                timeout={400}
              />
            </div>
            <div className="right">
              <HeaderPagination
                fetching={this.props.fetching}
                p={this.props.meta.p}
                rpp={this.props.meta.rpp}
                totalRows={this.props.meta.totalRows}
                onChangePage={(data) => {
                  this.props.getOrgHotels(
                    this.props.orgId,
                    {
                      p: data.p,
                      rpp: data.rpp,
                      search: this.state.search || "",
                    },
                    false
                  );
                }}
              />
              <button
                type="button"
                className="btn create rounded"
                onClick={() =>
                  this.props.showModal(
                    <SingleHotel saveHotel={this.props.saveHotel} />
                  )
                }
              >
                Create new Hotel
              </button>
            </div>
          </div>
        </div>
        <div className="list-view">
          <div className="main-wrapper">
            <Table
              id={"hotels"}
              columns={this.columns}
              items={this.props.hotels}
              updating={this.props.fetching}
              onRender={this.onRender}
              containerClassName={`table-container `}
            />
          </div>
        </div>
      </div>
    );
  }
}

Hotels.propTypes = {
  eventId: PropTypes.number,
  hotels: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  saveHotel: PropTypes.func.isRequired,
  orgId: PropTypes.number,
  deleteItem: PropTypes.func.isRequired,
  orgAdmin: PropTypes.bool.isRequired,
  getOrgHotels: PropTypes.func.isRequired,
  exportReservation: PropTypes.func,
  addNotification: PropTypes.func.isRequired,
  page: PropTypes.object,
};
