import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Confirm from "@layout/Confirm";
import Select from "@layout/Select2";
import { saveRoom, saveOrgRoom, saveHotelRoom } from "@actions/hotels";
import Form from "@components/form";
class SingleRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      startDate: "",
      endDate: "",
      quantity: "",
      capacity: "",
      facilities: "",
      buyingPrice: "",
      sellingPrice: "",
      notes: "",
      status: "",
      hotelRoomImages: "",
      showConfirm: false,
      createMode: "new",
      selectedHotelRoomId: null,
    };
    this.photoId = null;
  }
  deletefileBody() {
    return (
      <>
        This action will permanently delete the file with ID:{" "}
        <b>{this.photoId}</b>
        and name{" "}
        <b>
          {
            this.props.item?.hotelRoomImages?.filter(
              (image) => image.id == this.photoId
            )[0]?.fileName
          }
        </b>
        {". "}
        Are you sure that you want to do this?
      </>
    );
  }
  render() {
    const availableRooms = this.props.orgRooms.reduce(
      (prev, next) => ({ ...prev, [next.id]: next.name }),
      {}
    );
    return (
      <div className="form-container">
        <h2>{this.props.item?.id ? "Edit Room" : "Create Room"}</h2>
        {!this.props.item?.id && (
          <>
            <div className="row boolean-buttons">
              <div className="col-sm-6" style={{ marginBottom: 15 }}>
                <button
                  style={{ width: "100%" }}
                  className={`btn${
                    this.state.createMode === "existing" ? " selected" : ""
                  }`}
                  type="button"
                  disabled={this.state.createMode === "existing"}
                  onClick={() => this.setState({ createMode: "existing" })}
                >
                  Select from existing
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  style={{ width: "100%" }}
                  className={`btn${
                    this.state.createMode === "new" ? " selected" : ""
                  }`}
                  type="button"
                  onClick={() => this.setState({ createMode: "new" })}
                >
                  Create New
                </button>
              </div>
            </div>
            {this.state.createMode === "existing" && (
              <div className="form-group">
                <Select
                  isClearable={false}
                  options={availableRooms}
                  value={this.state.selectedHotelRoomId}
                  onChange={(val) => {
                    const {
                      name,
                      description,
                      facilities,
                      capacity,
                      hotelRoomImages,
                      sellingPrice,
                      buyingPrice,
                      startDate,
                      endDate,
                      quantity,
                      notes,
                      active,
                    } = this.props.orgRooms.find((room) => room.id == val);
                    this.setState({
                      name,
                      description,
                      facilities,
                      capacity,
                      hotelRoomImages,
                      sellingPrice,
                      buyingPrice,
                      startDate,
                      endDate,
                      quantity,
                      notes,
                      active,
                      selectedHotelRoomId: val,
                    });
                  }}
                />
              </div>
            )}
          </>
        )}
        <Confirm
          onConfirm={() => {
            this.props.saveHotelRoom(
              this.props.orgId,
              this.props.eventId,
              this.props.hotel.id,
              this.props.item.id,
              {
                hotelRoomImages: [this.photoId],
              },
              null,
              this.props.item.orgHotelRoomId
            );
            this.photoId = null;
            this.setState({ showConfirm: false });
          }}
          body={this.deletefileBody()}
          className="delete-event"
          confirmText="Confirm File Deletion"
          disabled={false}
          visible={this.state.showConfirm}
          title="File Delete Action! Are you sure?"
        ></Confirm>
        {this.state.createMode === "existing" && (
          <Form
            componentName={"hotels"}
            formName={"singleEventHotelRoom"}
            value={this.props.item?.id ? this.props.item : this.state}
            onChange={(data) => {
              if (data.key === "hotelRoomImages") {
                this.photoId = data.value.id;
                this.setState({ showConfirm: true });
              }
            }}
            onSubmit={(data) => {
              if (!Object.keys(data.errors).length) {
                this.props.saveHotelRoom(
                  this.props.orgId,
                  this.props.eventId,
                  this.props.hotel.id,
                  undefined,
                  {
                    hotelRoomId: this.state.selectedHotelRoomId,
                    sellingPrice: data.value.sellingPrice,
                    buyingPrice: data.value.buyingPrice,
                    startDate: data.value.datetimeRange.from,
                    endDate: data.value.datetimeRange.to,
                    quantity: data.value.quantity,
                    notes: data.value.notes,
                    active: data.value.active,
                  }
                );
              }
            }}
            onCancel={this.props.hideModal}
          />
        )}
        {this.state.createMode !== "existing" && (
          <Form
            componentName={"hotels"}
            formName={"singleHotelRoom"}
            value={this.props.item?.id ? this.props.item : this.state}
            onChange={(data) => {
              if (data.key === "hotelRoomImages") {
                this.photoId = data.value.id;
                this.setState({ showConfirm: true });
              }
            }}
            onSubmit={(data) => {
              if (!Object.keys(data.errors).length) {
                this.props.saveHotelRoom(
                  this.props.orgId,
                  this.props.eventId,
                  this.props.hotel.id,
                  this.props.item.id,
                  {
                    name: data.value.name,
                    description: data.value.description,
                    facilities: data.value.facilities,
                    capacity: data.value.capacity,
                    hotelRoomImages: data.value.hotelRoomImages,
                    sellingPrice: data.value.sellingPrice,
                    buyingPrice: data.value.buyingPrice,
                    startDate: data.value.datetimeRange.from,
                    endDate: data.value.datetimeRange.to,
                    quantity: data.value.quantity,
                    notes: data.value.notes,
                    active: data.value.active,
                  },
                  null,
                  this.props.item.orgHotelRoomId
                );
              }
            }}
            onCancel={this.props.hideModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orgRooms: state.api.hotels.orgRooms.data,
    orgId: state.api.events.edit.data.orgId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveOrgRoom: (
    hotelId,
    roomId,
    data,
    endpoint = "hotels",
    deleteImage = false
  ) => {
    dispatch(
      saveOrgRoom(hotelId, roomId, data, false, endpoint, deleteImage)
    ).catch((error) => {
      console.error("Error in saving room:", error);
    });
  },
  saveRoom: (
    hotelId,
    roomId,
    orgFields,
    eventFields,
    endpoint = "hotels",
    deleteImage = false,
    eventHotelId,
    eventRoomId
  ) => {
    dispatch(
      saveRoom(
        hotelId,
        roomId,
        orgFields,
        eventFields,
        endpoint,
        deleteImage,
        eventHotelId,
        eventRoomId
      )
    );
  },
  saveHotelRoom: (
    orgId,
    eventId,
    hotelId,
    roomId,
    data,
    preserveModal,
    orgHotelRoomId
  ) =>
    dispatch(
      saveHotelRoom(
        orgId,
        eventId,
        hotelId,
        roomId,
        data,
        preserveModal,
        orgHotelRoomId
      )
    ),
});

SingleRoom.defaultProps = {
  room: false,
};

SingleRoom.propTypes = {
  roomId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  hotelId: PropTypes.number.isRequired,
  room: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hotel: PropTypes.any,
  saveRoom: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  trIndex: PropTypes.number.isRequired,
  assignRoom: PropTypes.func.isRequired,
  orgRooms: PropTypes.array.isRequired,
  rooms: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  hotelRoomImages: PropTypes.array,
  item: PropTypes.object,
  saveHotelRoom: PropTypes.func,
  eventId: PropTypes.number,
  orgId: PropTypes.number,
  hideModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleRoom);
