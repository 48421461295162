import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { createInvoice, updateInvoice } from "@actions/invoices";
import { connect } from "react-redux";
import Form from "@components/form";

const SingleEbag = (props) => {
  const [item] = React.useState(props.item || { status: "pending" });

  return (
    <div className="form-container single-sponsor">
      <h2>{`${!item.id ? "Create" : "Edit"} Invoice`}</h2>
      <Form
        componentName="invoices"
        formName="single"
        value={item}
        onSubmit={({ errors, value }) => {
          if (Object.keys(errors).length) return false;
          item.id
            ? props.onUpdate(props.eventId, item.id, value)
            : props.onCreate(props.eventId, value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
        onChange={(value) => {
          console.info("value", value);
        }}
      />
    </div>
  );
};

SingleEbag.propTypes = {
  eventId: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
  item: PropTypes.object,
  mode: PropTypes.string,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    onCreate: (eventId, data) => {
      dispatch(createInvoice(eventId, data));
    },
    onUpdate: (eventId, invoiceId, data) => {
      dispatch(updateInvoice(eventId, invoiceId, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleEbag);
