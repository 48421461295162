import React from "react";
import KMBDropzone from "@layout/KMBDropzone";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";

export default class UploadHits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: false,
    };
  }

  render() {
    const disabled = this.state.file === false;
    return (
      <div className="form-container upload-hits">
        <h2>Upload Hits File</h2>
        <p className="subtitle">
          If you lose connection while scanning, the system saves hits locally.
          You can download this file and upload it here.
        </p>
        <form>
          <div className="form-group">
            <KMBDropzone
              promptMessage={[
                <span key="decorative" className="icon-cloud"></span>,
              ]}
              accept=".json"
              styleType="simple"
              max={1}
              onDropRejected={(message) => {
                this.props.addNotification(message, "error");
                this.setState({
                  file: false,
                });
              }}
              onDropAccepted={(file) => {
                this.setState({
                  file,
                });
              }}
            ></KMBDropzone>
            <span className="info">
              <span className="icon-notification-info"></span>Accepted file
              types: .json
            </span>
          </div>

          <SubmitContainer
            onCancel={this.props.hideModal}
            onSubmit={() =>
              this.props.uploadFile(this.props.eventId, this.state.file)
            }
            disabled={disabled}
            submitText={"Import File"}
          />
        </form>
      </div>
    );
  }
}

UploadHits.propTypes = {
  addNotification: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  hideModal: PropTypes.func,
};
