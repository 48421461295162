export const NOTIFICATION_EXPIRATION_TIME_MS = 72 * 60 * 60 * 1000;
export const ONE_HOUR_MS = 60 * 60 * 1000;

export const clearErrorNotifications = () => {
  const notifications = localStorage.getItem("errorNotifications");
  const timeNow = Date.now();
  let filteredErrors = [];
  try {
    const existingErrors = JSON.parse(notifications);

    for (const error of existingErrors) {
      if (timeNow - error.timestamp >= NOTIFICATION_EXPIRATION_TIME_MS)
        continue;
      filteredErrors.push(error);
    }
  } catch (e) {
    console.error("No notifications found");
    filteredErrors = [];
  }
  // sort by timestamp
  filteredErrors.sort((a, b) => b.timestamp - a.timestamp);
  // add to localStorage the filtered errors
  localStorage.setItem("errorNotifications", JSON.stringify(filteredErrors));
  return filteredErrors;
};

export function addErrorsInLocalStorage(err) {
  const filteredErrors = clearErrorNotifications();
  const timeNow = Date.now();
  const errorMessage = err.message;
  const errorData = {
    key: timeNow,
    timestamp: timeNow,
    viewed: 1,
    dismissible: false,
    type: "danger",
    title: `Error: ${errorMessage}`,
    date: timeNow,
    dismissLabel: "Dismiss",
    resolvable: false,
    resolveCallback: () => {},
    resolveLabel: "Resolve",
  };

  filteredErrors.unshift(errorData);

  localStorage.setItem("errorNotifications", JSON.stringify(filteredErrors));
}
