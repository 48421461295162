import React from "react";
import { connect } from "react-redux";
import { getJudges } from "@actions/abstracts";
import Table from "@layout/Table";
import PropTypes from "prop-types";

class Judges extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
  }

  componentDidMount() {
    this.props.getJudges();
  }

  onRender(col, item) {
    switch (col.key) {
      case "name": {
        return item.firstName + " " + item.lastName;
      }
    }
  }

  render() {
    return (
      <div className="tab-content">
        <Table
          columns={{
            id: {
              name: "ID",
              sortable: true,
            },
            name: {
              name: "Name",
              sortable: true,
            },
            email: {
              name: "Email",
            },
            assignedAbstracts: {
              name: "Assigned Abstracts",
            },
            maxRatedScore: {
              name: "Max rated score",
            },
            meanRatedScore: {
              name: "Mean rated score",
            },
            minRatedScore: {
              name: "Min rated score",
            },
            passingAbstracts: {
              name: "Passing Abstracts",
            },
            notPassingAbstracts: {
              name: "Not Passing Abstracts",
            },
            ratedAbstracts: {
              name: "Rated Abstracts",
            },
            standardDeviation: {
              name: "Standard Deviation",
            },
          }}
          containerClassName={`table-container`}
          updating={!this.props.ready}
          items={this.props.judges}
          onRender={this.onRender}
        />
      </div>
    );
  }
}

Judges.propTypes = {
  judges: PropTypes.array.isRequired,
  getJudges: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    judges: state.api.abstracts.judges.data,
    ready: state.api.abstracts.judges.ready,
  }),
  { getJudges }
)(Judges);
