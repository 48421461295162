import React from "react";
import PropTypes from "prop-types";

export default class Rating extends React.Component {
  constructor(props) {
    super(props);
    this.changeRating = this.changeRating.bind(this);
    this.state = {
      rating: props.value,
    };
  }

  changeRating(rating) {
    this.setState({ rating });
  }

  render() {
    const markup = [];

    for (let i = 1; i <= 5; i++)
      ((i) => {
        markup.push(
          <i
            key={`star-${i}`}
            className={`fa fa-star${this.state.rating >= i ? " active" : ""}`}
            onClick={() => this.changeRating(i)}
            aria-hidden="true"
          ></i>
        );
      })(i);

    return <div className="rating kmb-stars">{markup}</div>;
  }
}

Rating.propTypes = {
  value: PropTypes.number,
};
