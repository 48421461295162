import * as React from "react";
const SvgCopy = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 10h-.666a1.333 1.333 0 0 1-1.334-1.334v-6a1.333 1.333 0 0 1 1.334-1.333h6A1.333 1.333 0 0 1 10 2.666v.667M7.333 6h6c.737 0 1.334.597 1.334 1.333v6c0 .736-.597 1.333-1.334 1.333h-6A1.333 1.333 0 0 1 6 13.333v-6C6 6.597 6.597 6 7.333 6Z"
      stroke="#0079CA"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCopy;
