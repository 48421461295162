import { connect } from "react-redux";
import AdvancedSearch from "./AdvancedSearch";
import { getParticipants, loadRooms } from "@actions/users";
import { setAdvancedSearchOptions } from "@actions";

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    policyId: state.api.accesspolicies.selectedPolicy.id,
    rooms: state.rooms.data,
    roomsFetched: Object.keys(state.rooms.meta).length > 0,
    meta: state.users.meta,
    policyGroups: state.users.policyGroups,
    advancedSearchOptions: state.api.advancedSearchOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRooms: (eventId) => {
      dispatch(loadRooms(eventId));
    },
    getParticipants: (options) => dispatch(getParticipants(options, true)),
    setAdvancedSearchOptions: (eventId, policyId, options) =>
      dispatch(setAdvancedSearchOptions(eventId, policyId, options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
