import * as React from "react";
const SvgConditionEmpty = (props) => (
  <svg
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={4} y={6} width={19} height={5} rx={2.5} fill="#D9D9D9" />
    <rect
      x={23}
      y={22}
      width={14}
      height={5}
      rx={2.5}
      transform="rotate(-180 23 22)"
      fill="#D9D9D9"
    />
    <rect
      x={14}
      y={8}
      width={14}
      height={5}
      rx={2.5}
      transform="rotate(90 14 8)"
      fill="#D9D9D9"
    />
    <path
      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
      fill="#D9D9D9"
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
);
export default SvgConditionEmpty;
