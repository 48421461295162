import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: "" };
  }

  render() {
    const { password } = this.state;
    const { onSubmit, onCancel } = this.props;
    return (
      this.props.showModal && (
        <div className="form-container" key="password-auth-modal">
          <div className="form-group">
            <label htmlFor="">Password</label>
            <div>
              <input
                type="password"
                className="form-control"
                value={password}
                placeholder="Password"
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
          </div>
          <SubmitContainer
            submitText={"Authenticate"}
            onSubmit={() => {
              onSubmit(password);
            }}
            onCancel={onCancel}
          />
        </div>
      )
    );
  }
}

Auth.defaultProps = {
  onCancel: () => {},
};

Auth.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  showModal: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    showModal: state.page.normalModal.active,
  };
};

export default connect(mapStateToProps, {})(Auth);
