import React from "react";
import PropTypes from "prop-types";
import AccessLanguage from "./AccessLanguage/AccessLanguage";
import Branding from "./Branding/Branding";
import Footer from "./Footer/Footer";

const GeneralSettings = (props) => {
  const renderActiveItem = () => {
    switch (props.activeItem.key) {
      case "accessLanguage": {
        return <AccessLanguage activeItem={props.activeItem} />;
      }
      case "branding": {
        return <Branding activeItem={props.activeItem} />;
      }
      case "footer": {
        return <Footer activeItem={props.activeItem} />;
      }
    }
  };

  return (
    <div className="panel-content-wrapper tab-content">
      {renderActiveItem()}
    </div>
  );
};

GeneralSettings.propTypes = {
  activeItem: PropTypes.object.isRequired,
};

export default GeneralSettings;
