import { connect } from "react-redux";
import Payments from "./Sponsorships";
import { updateEvent } from "@actions";

const mapStateToProps = (state, ownProps) => {
  return {
    event: ownProps.event,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEvent: (eventId, data) => {
      dispatch(updateEvent(eventId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
