import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import Form from "@components/form";

const CallToAction = (props) => {
  return (
    <div className="form-container single-home-call-to-action">
      <h2>{`${
        props.mode == "create" ? "Create Call To Action" : "Edit Call To Action"
      }`}</h2>
      <Form
        formName="callToAction"
        componentName="clientPanel"
        value={props.item || {}}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;
          props.onSave(data.value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
      />
    </div>
  );
};

CallToAction.propTypes = {
  hideModal: PropTypes.func,
  mode: PropTypes.string,
  item: PropTypes.object,
  onSave: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CallToAction);
