import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Checkbox from "@layout/Checkbox";
import KMBLoader from "@layout/KMBLoader";
import { savePolicy } from "@actions";
import { makePassword } from "@helpers";
import { loadPolicy } from "@actions/users";
import SubmitContainer from "@layout/SubmitContainer";
import Switch from "@layout/Switch";
import Input from "@layout/Input";
import Select from "@layout/Select2";

export const PolicySettings = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [registrationInfo, setRegistrationInfo] = React.useState("");
  const [loginInfo, setLoginInfo] = React.useState("");
  const [termsOfService, setTermsOfService] = React.useState("");
  const [showSettings, setShowSettings] = React.useState(false);
  const [usePassword, setUsePassword] = React.useState(
    props.policy.settings.password?.required || 0
  );
  const [speakerMeta, setSpeakerMeta] = React.useState({
    jobTitleMetaId: props.policy.jobTitleMetaId || "",
    institutionMetaId: props.policy.jobTitleMetaId || "",
    shortBioMetaId: props.policy.jobTitleMetaId || "",
  });
  const [guestPassword, setGuestPassword] = React.useState(makePassword(5));
  const [useUsername, setUseUsername] = React.useState(
    props.policy.settings.username?.required || 0
  );
  const [username, setUsername] = React.useState(
    props.policy.settings.username?.value || ""
  );
  const [schema, setSchema] = React.useState();
  const [initialPolicy, setInitialPolicy] = React.useState();
  const [error, setError] = React.useState("");

  const initializeData = (policy) => {
    setUsername(policy.settings.username?.value || "");
    const newSchema = {
      onlineRegistration: policy.onlineRegistration,
      maxUsers: policy.maxUsers,
      mandatoryPayment: policy.mandatoryPayment,
      saveUsersToCrm: policy.saveUsersToCrm,
      concurrentConnectionsLimit: policy.concurrentConnectionsLimit,
      default: policy.default,
      name: policy.name,
    };
    setSchema(newSchema);
    setRegistrationInfo(policy.registrationInfo);
    setLoginInfo(policy.loginInfo);
    setTermsOfService(policy.disclaimer);
    setGuestPassword(policy.settings.password?.value);
  };
  const initialize = () => {
    if (props.policy) {
      setLoading(true);
      props
        .loadPolicy(props.event.id, props.policy.id)
        .then(({ data: policy }) => {
          setInitialPolicy(policy);
          initializeData(policy);
          setLoading(false);
          setSpeakerMeta({
            jobTitleMetaId: policy.jobTitleMetaId,
            institutionMetaId: policy.institutionMetaId,
            shortBioMetaId: policy.shortBioMetaId,
          });
        });
    }
  };

  React.useEffect(() => {
    initialize();
  }, []);

  React.useEffect(() => {
    initialize();
  }, [props.policy]);

  const changeSetting = (key, value) => {
    const newSchema = { ...schema };
    newSchema[key] = value;
    setSchema(newSchema);
    if (key === "name") {
      setError("");
    }
  };

  const saveButton = () => (
    <SubmitContainer
      onCancel={() => {
        initializeData(initialPolicy);
      }}
      onSubmit={() => {
        if (!schema.name.trim()) {
          setError("This field is required.");
          return;
        }
        const schemaObj = schema;
        //case Guests policy
        if (props.policy.type === "public") {
          schemaObj.onlineRegistration = 1;
          schemaObj.mandatoryPayment = 0;
        }
        const request = {
          name: schemaObj.name,
          default: schemaObj.default,
          type: props.policy.type,
          maxUsers: schemaObj.maxUsers,
          saveUsersToCrm: schemaObj.saveUsersToCrm,
          onlineRegistration: schemaObj.onlineRegistration,
          mandatoryPayment: schemaObj.mandatoryPayment,
          concurrentConnectionsLimit: schemaObj.concurrentConnectionsLimit,
          loginInfo: loginInfo,
          registrationInfo: registrationInfo,
          disclaimer: termsOfService,
          ...speakerMeta,
          ...(props.policy.type === "public"
            ? {
                settings: {
                  ...initialPolicy.settings,

                  username: {
                    name: "Username",
                    askUser: useUsername,
                    identifier: useUsername,
                    unique: 0,
                    required: useUsername,
                    value: username,
                  },
                  password: {
                    name: "Password",
                    askUser: usePassword,
                    identifier: usePassword,
                    unique: 0,
                    required: usePassword,
                    value: guestPassword,
                  },
                },
              }
            : {}),
        };
        setLoading(true);
        props
          .savePolicy(props.eventId, request, props.policy?.id)
          .then(() => {
            initialize();
          })
          .catch(() => {
            setLoading(false);
          });
      }}
    />
  );

  if (!Object.keys(props.policy || {}).length)
    return (
      <div
        className="form-container single-policy row"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            padding: 50,
            textAlign: "center",
          }}
        >
          {`It looks like you dont have a policy. Please click 'Save' to create
          one`}
        </div>
        {saveButton()}
      </div>
    );
  if (loading) return <KMBLoader rows={10} padding={24} height={53} />;

  const fieldOptions = Object.values(props.policy.extraSettings).reduce(
    (prev, value) => ({ ...prev, [value.id]: value.name }),
    {}
  );
  return (
    <div className="form-container tab-content">
      <div className="additional-settings">
        <div className="form-container">
          {props.policy.type !== "public" && (
            <>
              <h3>Participants</h3>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Connect with CRM</label>
                <Switch
                  isLive={true}
                  id={`connect-to-crm`}
                  isActive={schema.saveUsersToCrm}
                  onChange={(active) => {
                    changeSetting("saveUsersToCrm", active);
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Maximum participants</label>
                <Input
                  placeholder="unlimited"
                  value={schema.maxUsers ?? ""}
                  width={"120px"}
                  onChange={(e) => {
                    if (e.target.value && !isFinite(e.target.value)) return;
                    changeSetting("maxUsers", e.target.value);
                  }}
                ></Input>
              </div>
              <h3>Client Panel</h3>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Support online registration</label>
                <Switch
                  isLive={true}
                  id={`onlineRegistration-active`}
                  isActive={schema.onlineRegistration}
                  onChange={(active) =>
                    changeSetting("onlineRegistration", active)
                  }
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Support concurrent client connections</label>
                <Switch
                  isLive={true}
                  id={`staff-concurrentConnectionsLimit`}
                  isActive={schema.concurrentConnectionsLimit}
                  onChange={(active) =>
                    changeSetting("concurrentConnectionsLimit", active)
                  }
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Prompt payment for online registration</label>
                <Switch
                  isLive={true}
                  id={`staff-mandatoryPayment`}
                  isActive={schema.mandatoryPayment}
                  onChange={(active) =>
                    changeSetting("mandatoryPayment", active)
                  }
                />
              </div>
            </>
          )}
          {props.policy.type === "public" && (
            <div className="form-container">
              <h3>Client Panel</h3>
              <div className="form-group">
                <Checkbox
                  id="hasUsername"
                  label="Use a username"
                  checked={useUsername}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setUseUsername(checked);
                  }}
                />
                <div style={{ paddingTop: 12 }}>
                  <label>Guest Username (*)</label>
                  <div style={{ display: "flex", gap: 12 }}>
                    <input
                      disabled={!useUsername}
                      className="form-control"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Checkbox
                  id="hasPassword"
                  label="Use a password"
                  checked={usePassword}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setUsePassword(checked);
                  }}
                />
                <div style={{ paddingTop: 12 }}>
                  <label>Guest Password (*)</label>
                  <div style={{ display: "flex", gap: 12 }}>
                    <input
                      disabled={!usePassword}
                      className="form-control"
                      value={guestPassword}
                      onChange={(e) => setGuestPassword(e.target.value)}
                    />
                    <button
                      disabled={!usePassword}
                      className="btn btn-transparent"
                      onClick={() => setGuestPassword(makePassword(5))}
                    >
                      Suggest New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex-gap">
        <div className={`form-group`}>
          <label>Login Message</label>
          <textarea
            className="form-control"
            value={loginInfo}
            onChange={(e) => setLoginInfo(e.target.value)}
          />
        </div>
        <div className={`form-group`}>
          <label>Registration Message</label>
          <textarea
            className="form-control"
            value={registrationInfo}
            onChange={(e) => setRegistrationInfo(e.target.value)}
          />
        </div>
        <div className={`form-group `}>
          <label>Terms of Service</label>
          <textarea
            className="form-control"
            value={termsOfService}
            onChange={(e) => setTermsOfService(e.target.value)}
          />
        </div>
      </div>
      {props.policy.type !== "public" && (
        <>
          <h3>Speakers/Chairpersons</h3>
          <div className="flex-gap">
            <div className="form-group">
              <label>Institution</label>
              <Select
                placeholder={"Select institution field"}
                placeholderInsideSelect={false}
                options={fieldOptions}
                value={speakerMeta.institutionMetaId}
                onChange={(action = null) => {
                  setSpeakerMeta({ ...speakerMeta, institutionMetaId: action });
                }}
              />
            </div>
            <div className="form-group">
              <label>Job Title</label>
              <Select
                placeholder={"Select job-title field"}
                placeholderInsideSelect={false}
                value={speakerMeta.jobTitleMetaId}
                options={fieldOptions}
                onChange={(action = null) => {
                  setSpeakerMeta({ ...speakerMeta, jobTitleMetaId: action });
                }}
              />
            </div>
            <div className="form-group">
              <label>Short Bio</label>
              <Select
                placeholder={"Select short-bio field"}
                placeholderInsideSelect={false}
                value={speakerMeta.shortBioMetaId}
                options={fieldOptions}
                onChange={(action = null) => {
                  setSpeakerMeta({ ...speakerMeta, shortBioMetaId: action });
                }}
              />
            </div>
          </div>
        </>
      )}
      <button
        className="search-advanced btn tab-header-btn"
        style={{
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
        onClick={() => setShowSettings(!showSettings)}
      >
        {(showSettings ? "Hide" : "Show") + " Advanced Settings"}
        <span className="icon-settings"></span>
      </button>

      {showSettings && (
        <>
          <h3>Policy settings</h3>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="default">This is the default policy</label>
            <Switch
              isLive={true}
              id="default"
              isActive={schema.default}
              onChange={(active) => {
                changeSetting("default", active);
              }}
            />
          </div>

          <div
            className={`form-group${error ? " has-error" : ""}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="PolicyId">Policy Name:</label>
            <div style={{ marginBottom: "8px" }}>
              <Input
                className="form-control"
                type="text"
                id="PolicyId"
                placeholder={"Type a name"}
                value={schema.name}
                onChange={(event) => {
                  changeSetting("name", event.target.value);
                }}
                style={{
                  width: "147px",
                  textAlign: "center",
                }}
              />
              {error && (
                <div
                  className="help-block"
                  style={{ height: "20px", marginTop: "4px" }}
                >
                  {error}
                </div>
              )}
            </div>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="PolicyType">Policy Type:</label>
            <div>{props.policy.type}</div>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="PolicyId">Policy ID:</label>
            <div>{props.policy.id}</div>
          </div>
        </>
      )}
      {saveButton()}
    </div>
  );
};

PolicySettings.propTypes = {
  accessPolicies: PropTypes.array,
  policy: PropTypes.object,
  eventId: PropTypes.number,
  savePolicy: PropTypes.func,
  loadPolicy: PropTypes.func,
  event: PropTypes.object,
  updatePolicyName: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    policy: state.api.accesspolicies.selectedPolicy,
  };
};

const mapDispatchToProps = {
  loadPolicy: (eventId, policyId) => loadPolicy(policyId, eventId),
  savePolicy: (eventId, request, policyId = false) =>
    savePolicy(eventId, request, policyId),
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicySettings);
