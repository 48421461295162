import { connect } from "react-redux";
import Rooms from "./Rooms";
import {
  reorderHotelRoom,
  unassignRoom,
  getOrgRooms,
  deleteRoom,
  getEventRooms,
} from "@actions/hotels";
import { showModal, addNotification } from "@actions";

const mapStateToProps = (state, ownProps) => {
  const hotelId = ownProps.hotelId;
  const iterable =
    ownProps.mode === "eventHotels"
      ? [...state.api.hotels.list.data]
      : [...state.api.hotels.orgList.data];
  const hotel = iterable.filter((h) => h.id === hotelId)[0];

  return {
    hotelId: ownProps.hotelId,
    hotel,
    rooms:
      ownProps.mode === "eventHotels"
        ? hotel.rooms
        : [...state.api.hotels.orgRooms.data],
    fetching: state.api.hotels.eventRooms.fetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (content) =>
    dispatch(
      showModal(
        content,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
    ),
  reorderHotelRoom: (index, hotelId, roomId) =>
    dispatch(reorderHotelRoom(index, hotelId, roomId)),
  unassignRoom: (hotelId, roomId, orgHotelId) =>
    dispatch(unassignRoom(hotelId, roomId, undefined, undefined, orgHotelId)),
  getOrgRooms: (mode, hotelId) => dispatch(getOrgRooms(mode, hotelId)),
  deleteRoom: (hotelId, roomId, mode, eventHotelId) =>
    dispatch(
      deleteRoom(hotelId, roomId, mode, undefined, undefined, eventHotelId)
    ),
  getEventRooms: (hotelId) => dispatch(getEventRooms(hotelId)),
  addNotification: (msg, status) => dispatch(addNotification(msg, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
