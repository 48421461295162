import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import { loadUsers } from "@actions/users";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import { updateProduct, createProduct } from "@actions/products";

const yesNoKeys = [];
class SingleProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        {this.props.h2 === "Edit" ? (
          <h2>Edit Product</h2>
        ) : (
          <h2>Create new Product</h2>
        )}
        <br />
        <div className="new-staff form-container">
          {this.props.h2 === "Edit" ? <h3></h3> : <h3></h3>}
          {/* This may seem useless, but for some reason if you remove this check,
						the search bar breaks ?? */}
          <Form
            componentName={"products"}
            formName="single"
            value={this.props.h2 === "Edit" ? this.props.item : {}}
            onSubmit={(data) => {
              if (isEmpty(data.errors)) {
                Object.entries(data.value).map(([k, v]) => {
                  if (yesNoKeys.indexOf(k) !== -1) {
                    if (v === "yes") {
                      data.value[k] = true;
                    } else if (v === "no") {
                      data.value[k] = false;
                    }
                  }
                  if (data.value[k] === "") {
                    data.value[k] = null;
                  }
                });
                if (this.props.h2 === "Edit") {
                  this.props.updateProduct(
                    this.props.eventId,
                    this.props.item.id,
                    data.value
                  );
                } else {
                  this.props.createProduct(this.props.eventId, data.value);
                }
              }
            }}
            onCancel={this.props.hideModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

SingleProduct.propTypes = {
  eventId: PropTypes.number,
  h2: PropTypes.string,
  hideModal: PropTypes.func,
  updateProduct: PropTypes.func,
  createProduct: PropTypes.func,
  values: PropTypes.array,
  item: PropTypes.object,
};

SingleProduct.defaultProps = {
  value: {},
};
const mapStateToProps = (state) => {
  return {
    policyId: state.page.params.pid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (queryString, policyId, eventId) => {
      dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    createProduct: (eventId, data) => {
      dispatch(createProduct(eventId, data));
    },
    updateProduct: (eventId, productId, data) => {
      dispatch(updateProduct(eventId, productId, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
