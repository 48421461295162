import React from "react";
import Modal from "@layout/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class ConfirmChanges extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props.data;
    const title = data.ce
      ? "You are changing event. Please confirm that you want to do this!"
      : "You have unsaved changes! Proceed?";

    return (
      <Modal
        show={data.active}
        title={title}
        onClose={() => this.props.confirmChanges(false)}
        className="confirm-changes-wrapper"
      >
        <div className="confirm-changes">
          <div className="text-right submit-container">
            <button
              className="btn btn-green rounded"
              onClick={() => this.props.confirmChanges(false)}
            >
              Stay
            </button>
            <button
              className="btn btn-red rounded"
              onClick={() => this.props.confirmChanges(true)}
            >
              {data.ce ? "" : "Discard and"} Proceed
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmChanges.propTypes = {
  data: PropTypes.object.isRequired,
  escapeFunction: PropTypes.func,
  confirmChanges: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.appuser,
    events: state.api.events.list.data,
    escapeFunction: state.page.tempRoute.escapeFunction,
  };
};

const mapDispatchToProps = () => {
  return {};
};

ConfirmChanges.defaultProps = {
  data: { active: true },
  confirmChanges: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmChanges);
