/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

export default class KMBLoader extends React.Component {
  constructor(props) {
    super(props);
    this.html = new Array();
    const margin = props.margin;

    const rows = props.rows;
    for (let i = 0; i < rows; i++) {
      const width = props.width ? props.width : this.generateWidth();
      const lineHeight = props.thin ? 1 : "normal";
      this.html.push(
        <li
          key={"loader-bar-" + i}
          style={{
            height: this.props.height || "unset",
            // width,
            margin: `${margin}px 0 ${margin}px`,
            lineHeight,
          }}
        >
          &nbsp;
        </li>
      );
    }
  }

  generateWidth() {
    return `${Math.floor(Math.random() * 49) + 50}%`;
  }

  render() {
    return (
      <ul style={{ padding: this.props.padding }} className="kmb-loader">
        {this.html}
      </ul>
    );
    return 
  }
}

KMBLoader.propTypes = {
  margin: PropTypes.number,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.any,
  thin: PropTypes.bool,
};

KMBLoader.defaultProps = {
  rows: 3,
  margin: 10,
  padding: 0,
  width: null,
  thin: false,
};
