import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectUser } from "@actions/users";

class AutocompleteTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: true,
      show: false,
      inputValue: "",
      disabled: false,
      updating: false,
    };
  }

  updateInputValue(evt) {
    this.setState(
      {
        inputValue: evt.target.value,
        updating: true,
      },
      () => {
        if (this.state.inputValue.length > 0) {
          this.props.onChange(this.state.inputValue);
        }
      }
    );
  }
  onItemSelect(user) {
    this.props.selectUser(user);
  }
  componentDidMount() {
    if (this.props.formData?.value) {
      this.setState({ inputValue: this.props.formData.value });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {
      updating:
        this.state.updating && nextProps.fetching ? false : this.state.updating,
    };
    if (
      nextProps.userEditMode !== this.props.userEditMode ||
      nextProps.hasUserBeenSelected !== this.props.hasUserBeenSelected
    ) {
      // this.setState({ inputValue: this.props.formData.value });
      newState.inputValue = nextProps.formData.value;
    }
    this.setState(newState);
  }

  render() {
    const { formData } = this.props;
    const showOptions =
      !this.props.hasUserBeenSelected &&
      this.state.inputValue.length > 0 &&
      this.state.show &&
      this.state.focused;
    const updating = this.props.fetching || this.state.updating;
    const optionItems = this.props.filteredUsers.data
      .slice(0, 4)
      .map((user, i) => {
        return (
          <div
            key={`user-search-${i}`}
            className="item"
            onMouseDown={() => this.onItemSelect(user)}
          >
            {" "}
            {`${user.info["firstName"]} ${user.info["lastName"]},  ${user.info["email"]} `}{" "}
          </div>
        );
      });
    return (
      <React.Fragment>
        <input
          ref={this.props.ref}
          name={formData ? formData.name : "CrmFilter"}
          id={formData ? formData.id : 1}
          className={
            formData ? `form-control${formData.inputClass}` : `form-control`
          }
          placeholder={
            formData ? (formData.placeholder ? formData.placeholder : "") : ""
          }
          autoComplete="off"
          disabled={this.props.hasUserBeenSelected && !this.props.userEditMode}
          value={this.state.inputValue || ""}
          onBlur={() => this.setState({ focused: false })}
          onFocus={() => this.setState({ focused: true, show: true })}
          onChange={(e) => this.updateInputValue(e)}
        />
        <div className="autocomplete">
          {showOptions && updating && <div className="item">Loading...</div>}
          {showOptions && !updating && optionItems.length > 0 && optionItems}
          {showOptions && !updating && !optionItems.length && (
            <div className="item">-- No Results --</div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
AutocompleteTest.propTypes = {
  formData: PropTypes.any,
  filteredUsers: PropTypes.object,
  onChange: PropTypes.func,
  selectUser: PropTypes.func,
  ref: PropTypes.any,
  crmUsers: PropTypes.array.isRequired,
  selectedUser: PropTypes.object,
  hasUserBeenSelected: PropTypes.bool,
  userEditMode: PropTypes.bool,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    filteredUsers: state.users.crmUsers,
    fetching: state.users.crmUsers.fetching,
    crmUsers: state.api.crm.list.data,
    selectedUser: state.users.selectedUser,
    hasUserBeenSelected: state.users.hasUserBeenSelected,
    userEditMode: state.users.userEditMode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    selectUser: (id) => {
      dispatch(selectUser(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteTest);
