import { api } from "@actions/ajax";
import { isEmpty } from "@helpers";
import { addNotification } from "@actions";
import { downloadFile, checkRoomNameDuplicate } from "@helpers";
import { uploadFile } from ".";

export const REORDER_ROOMS = "REORDER_ROOMS";

export const reorderHotelRoom = (
  data,
  hotelId,
  roomId,
  endpoint = "hotels",
  action = "updateEventRoom"
) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;

    dispatch({
      type: REORDER_ROOMS,
    });

    const request = {
      endpoint,
      action,
      params: { eventId, hotelId, roomId },
      body: { data },
      extra: { preserveModal: true },
    };

    return dispatch(api(request));
  };
};

export const saveHotelRoom = (
  orgId,
  eventId,
  hotelId,
  roomId,
  data,
  preserveModal = false
) => {
  return async (dispatch) => {
    const isRoomNameTaken = await checkRoomNameDuplicate(
      dispatch,
      hotelId,
      data.name,
      roomId
    );

    if (isRoomNameTaken) {
      dispatch(
        addNotification("Room name already exists for this hotel.", "error")
      );
      return;
    }

    const maxCapacity = 1000;
    if (data.capacity > maxCapacity) {
      dispatch(
        addNotification(`Capacity cannot be more than ${maxCapacity}`, "error")
      );
      return;
    }

    const filesToUpload = {
      hotelRoomImages: Object.assign([], data.hotelRoomImages),
    };

    if (data.hotelRoomImages?.length > 0) {
      data.hotelRoomImages.forEach((hotelRoomImageFile, i) => {
        if (typeof hotelRoomImageFile !== "number") {
          data.hotelRoomImages[i] = data.hotelRoomImages[i].name;
        }
      });
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    let deletionAction = false;
    data.hotelRoomImages?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });

    const request = {
      endpoint: "hotels",
      action: roomId ? "updateEventRoom" : "assignRoom",
      params: { eventId, hotelId, roomId },
      body: { data },
      extra: { preserveModal },
    };

    const callback = () => {
      dispatch(addNotification("Room updated successfully!", "success"));
      dispatch(getEventRooms(hotelId));
    };

    if (!hasFiles || deletionAction) {
      dispatch(api(request)).then(() => {
        callback();
      });
    } else {
      dispatch(api(request)).then((res) => {
        const promiseArr = [];

        Object.keys(filesToUpload).forEach((key) => {
          if (
            filesToUpload[key][0] &&
            typeof filesToUpload[key][0] !== "number"
          ) {
            filesToUpload[key].forEach((file) => {
              promiseArr.push(
                dispatch(
                  uploadFile(
                    eventId,
                    "hotelRoomImages",
                    file,
                    {
                      organisationId: orgId,
                      hotelId: hotelId,
                      roomId: res.data.orgHotelRoomId,
                    },
                    null,
                    null,
                    filesToUpload[key].length,
                    true,
                    orgId
                  )
                )
              );
            });
          }
        });

        Promise.all(promiseArr)
          .then(() => {
            callback(res);
          })
          .catch((err) => {
            console.warn(err);
          });
      });
    }
  };
};
export const saveRoom = (
  hotelId,
  roomId = false,
  orgFields,
  eventFields,
  endpoint = "hotels",
  deleteImage = false,
  eventHotelId,
  eventRoomId
) => {
  return async (dispatch) => {
    try {
      const roomName = orgFields.name || eventFields.name;
      const isRoomNameTaken = await checkRoomNameDuplicate(
        dispatch,
        hotelId,
        roomName,
        roomId
      );

      if (isRoomNameTaken) {
        dispatch(
          addNotification("Room name already exists for this hotel.", "error")
        );
        return;
      }

      const rid = await dispatch(
        saveOrgRoom(
          hotelId,
          roomId,
          orgFields,
          eventFields,
          endpoint,
          deleteImage,
          eventHotelId
        )
      );

      if (eventFields) {
        await dispatch(
          saveEventRoom(
            eventHotelId,
            eventRoomId || rid,
            eventFields,
            eventRoomId ? "updateEventRoom" : "assignRoom"
          )
        );
      } else if (eventRoomId) {
        dispatch(addNotification("Room updated successfully!", "success"));
      }
    } catch (error) {
      console.error("Error in saveRoom function:", error);
    }
  };
};

export const deleteRoom =
  (
    hotelId,
    roomId,
    mode = "eventHotels",
    endpoint = "hotels",
    action = "deleteRoom",
    eventHotelId
  ) =>
  (dispatch, getState) => {
    const orgId =
      mode === "eventHotels"
        ? getState().api.events.edit.data.orgId
        : getState().appuser.data.orgId;

    const request = {
      endpoint,
      action,
      params: {
        orgId,
        hotelId,
        roomId,
      },
      extra: { preserveModal: true },
    };

    dispatch(api(request)).then(() => {
      dispatch(addNotification("Room deleted successfully!", "success"));
      dispatch(getOrgRooms(mode, hotelId));
      if (mode === "eventHotels") {
        dispatch(getEventRooms(eventHotelId));
      }
    });
  };

export const saveEventRoom = (
  hotelId,
  roomId,
  data,
  action,
  endpoint = "hotels"
) => {
  return async (dispatch, getState) => {
    const eventId = getState().api.events.edit.data.id;
    const existingRoomsResponse = await dispatch(getEventRooms(hotelId));
    const existingRooms = existingRoomsResponse.data;
    const newRoomName = data.name;
    const isRoomNameTaken = existingRooms.some(
      (room) => room.name === newRoomName && room.id !== roomId
    );

    if (isRoomNameTaken) {
      dispatch(
        addNotification("Room name already exists for this hotel.", "error")
      );
      return;
    }
    const request = {
      endpoint,
      action,
      params: {
        eventId,
        hotelId,
      },
      body: { data },
      extra: { preserveModal: true },
    };
    if (action === "assignRoom") {
      request.body.data["hotelRoomId"] = roomId;
    } else {
      request.params["roomId"] = roomId;
    }
    dispatch(api(request))
      .then((res) => {
        action === "updateEventRoom"
          ? dispatch(
              addNotification(
                `Room "${res.data.name}" updated successfully!`,
                "success"
              )
            )
          : dispatch(
              addNotification(
                `Room "${res.data.name}" assigned to hotel successfully!`,
                "success"
              )
            );
      })
      .then(() => dispatch(getEventRooms(hotelId)))
      .catch((err) => console.error(err));
  };
};

export const unassignRoom = (
  hotelId,
  roomId,
  endpoint = "hotels",
  action = "unassignRoom",
  orgHotelId
) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;
    const request = {
      endpoint,
      action,
      params: {
        eventId,
        hotelId,
        roomId,
      },
    };
    dispatch(api(request)).then(() => {
      dispatch(
        addNotification(
          `The room has been successfully unassigned from this hotel!`
        )
      );
      dispatch(getOrgRooms(undefined, orgHotelId));
      dispatch(getEventRooms(hotelId));
    });
  };
};

export const saveOrgRoom = (
  hotelId,
  roomId,
  data,
  eventHotel = true,
  endpoint = "hotels",
  deleteImage = false
) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const orgId = eventHotel
        ? getState().api.events.edit.data.orgId
        : getState().appuser.data.orgId;
      const eventId = getState().api.events.edit.data.id;

      dispatch(getOrgRooms(eventHotel ? "eventHotels" : "orgHotels", hotelId))
        .then((existingRoomsResponse) => {
          const existingRooms = existingRoomsResponse.data;
          const newRoomName = data.name;
          const isRoomNameTaken = existingRooms.some(
            (room) => room.name === newRoomName && room.id !== roomId
          );

          if (isRoomNameTaken) {
            dispatch(
              addNotification(
                "Room name already exists for this hotel.",
                "error"
              )
            );
            return reject(new Error("Room name already exists"));
          }

          const filesToUpload = {
            hotelRoomImages: Object.assign([], data.hotelRoomImages),
          };

          if (data.hotelRoomImages?.length > 0) {
            data.hotelRoomImages.forEach((hotelRoomImageFile, i) => {
              if (typeof hotelRoomImageFile !== "number") {
                data.hotelRoomImages[i] = data.hotelRoomImages[i].name;
              }
            });
          }

          if (data.hotelRoomImages === "") {
            delete data.hotelRoomImages;
          }

          const filesToUploadProps = Object.keys(filesToUpload);
          let hasFiles = false;
          filesToUploadProps.forEach((key) => {
            if (filesToUpload[key]?.length > 0) hasFiles = true;
          });

          const request = {
            endpoint,
            action: roomId ? "updateRoom" : "createRoom",
            params: {
              orgId,
              hotelId,
              eventId,
            },
            body: { data },
          };

          if (roomId) {
            request.params["roomId"] = roomId;
          }

          const callback = (res) => {
            if (!eventHotel) {
              dispatch(getOrgRooms("orgHotels", hotelId));
            }
            if (roomId) {
              dispatch(
                addNotification("Room updated successfully!", "success")
              );
            }
            return resolve(res.data.id);
          };

          if (!hasFiles || deleteImage) {
            dispatch(api(request))
              .then((res) => {
                callback(res);
              })
              .catch((err) => reject(err));
          } else {
            dispatch(api(request))
              .then((res) => {
                const promiseArr = [];
                Object.keys(filesToUpload).forEach((key) => {
                  if (
                    filesToUpload[key][0] &&
                    typeof filesToUpload[key][0] !== "number"
                  ) {
                    filesToUpload[key].forEach((file) => {
                      promiseArr.push(
                        dispatch(
                          uploadFile(
                            null,
                            "hotelRoomImages",
                            file,
                            {
                              organisationId: orgId,
                              roomId: res.data.id,
                              hotelId: parseInt(hotelId),
                            },
                            null,
                            null,
                            filesToUpload[key].length,
                            true,
                            orgId
                          )
                        )
                      );
                    });
                  }
                });

                Promise.all(promiseArr)
                  .then(() => {
                    callback(res);
                  })
                  .catch((err) => {
                    console.warn(err);
                    reject(err);
                  });
              })
              .catch((err) => reject(err));
          }
        })
        .catch((err) => {
          console.error("Error in duplicate check:", err);
          reject(err);
        });
    });
  };
};

export const assignHotel =
  (
    hotelId,
    notes,
    endpoint = "hotels",
    action = "assignHotel",
    showNotification = true
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;
    const { rpp, order, orderBy, p } = state.api.hotels.list.meta;
    const request = {
      endpoint,
      action,
      params: { eventId },
      body: { data: { orgHotelId: hotelId, notes } },
    };
    let response;
    return dispatch(api(request))
      .then((res) => {
        response = res;
        if (showNotification)
          dispatch(
            addNotification(
              `Hotel "${res.data.name}" assigned to event successfully!`,
              "success"
            )
          );
      })
      .then(() => dispatch(getHotels(eventId, { rpp, order, orderBy, p })))
      .then(() => response);
  };

export const unassignHotel =
  (hotelId, endpoint = "hotels", action = "unassignHotel") =>
  (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;
    const { rpp, order, orderBy, p } = state.api.hotels.list.meta;
    const request = {
      endpoint,
      action,
      params: { eventId, hotelId },
    };
    dispatch(api(request))
      .then(() =>
        dispatch(
          addNotification(
            `The hotel has been successfully unassigned from this event!`,
            "success"
          )
        )
      )
      .then(() => dispatch(getHotels(eventId, { rpp, order, orderBy, p })));
  };

export const reorderHotel = (
  data,
  hotelId,
  endpoint = "hotels",
  action = "reorder",
  needNotification = false
) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.events.edit.data.id;

    const request = {
      endpoint,
      action,
      params: { eventId, hotelId },
      body: { data },
      needNotification,
    };

    return dispatch(api(request));
  };
};

export const saveHotel = (
  orgId,
  data,
  hotelId = false,
  endpoint = "hotels",
  preserveModal = false,
  orgMode,
  editMode,
  eventHotelId,
  event_id
) => {
  return (dispatch, getState) => {
    const filesToUpload = {
      hotelImages: Object.assign([], data.hotelImages),
    };

    data.hotelImages?.forEach((imageFile, i) => {
      if (typeof imageFile !== "number") {
        data.hotelImages[i] = imageFile.name;
      }
    });

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    let deletionAction = false;
    data.hotelImages?.forEach((file) => {
      if (typeof file === "number") {
        deletionAction = true;
      }
    });

    const deleteFiles = Object.keys(data).length == 1 && data.hotelImages;
    let active = data.active;
    let notes = "";
    if (editMode && !orgMode) {
      active = data.active;
      delete data["active"];
    }
    if (data.notes) {
      notes = data.notes;
      delete data["notes"];
    }
    const request = {
      endpoint,
      action: hotelId ? "edit" : "create",
      params: { orgId },
      body: { data },
    };
    if (hotelId) request.params["hotelId"] = hotelId;

    const state = getState();
    const eventId = state.api.events.edit.data.id;
    const { rpp = -1, p = 1 } = state.api.hotels.orgList.meta;

    if (preserveModal) {
      request["extra"] = {
        preserveModal: true,
      };
    }
    const callback = (res) => {
      if (editMode && !orgMode) {
        //reorder also updates the hotel
        dispatch(reorderHotel({ active, notes }, eventHotelId));
      }
      if (orgMode === false && !editMode && !deleteFiles) {
        return dispatch(
          assignHotel(res.data.id, notes, undefined, undefined, false)
        )
          .then((response) =>
            dispatch(
              reorderHotel(
                { active },
                response.data.id,
                undefined,
                undefined,
                true
              )
            )
          )
          .then(() => dispatch(getHotels(eventId, { rpp, p })));
      }
      if (orgMode) {
        dispatch(getOrgHotels(orgId));
      } else if (eventId) {
        dispatch(getHotels(eventId, { rpp, p }));
      }
    };

    if (!hasFiles || deletionAction) {
      dispatch(api(request)).then((res) => {
        callback(res);
      });
    } else {
      dispatch(api(request)).then((res) => {
        const promiseArr = [];

        Object.keys(filesToUpload).forEach((key) => {
          if (
            filesToUpload[key][0] &&
            typeof filesToUpload[key][0] !== "number"
          ) {
            filesToUpload[key].forEach((file) => {
              promiseArr.push(
                dispatch(
                  uploadFile(
                    event_id,
                    "hotelImages",
                    file,
                    {
                      organisationId: orgId,
                      hotelId: res.data.id,
                    },
                    null,
                    null,
                    filesToUpload[key].length,
                    true,
                    orgId
                  )
                )
              );
            });
          }
        });

        Promise.all(promiseArr)
          .then(() => {
            callback(res);
          })
          .catch((err) => {
            console.warn(err);
          });
      });
    }
  };
};
export const exportReservation = (eventId, type) => {
  return (dispatch) => {
    const request = {
      endpoint: "hotels",
      action: "export",
      params: { eventId },
      body: {
        data: {
          view: type,
        },
      },
      dataType: "buffer",
    };
    dispatch(api(request)).then((file) => {
      const name = type + "-Reservations.xlsx";
      const blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
      const objectUrl = URL.createObjectURL(blob);
      downloadFile(objectUrl, name);
    });
  };
};
export const getOrgHotels =
  (
    orgId = false,
    meta = { rpp: 20 },
    endpoint = "hotels",
    action = "orgList"
  ) =>
  (dispatch, getState) => {
    const state = getState();
    if (!orgId) {
      // org hotels listing
      orgId = state.api.events.edit.data.orgId;
    }
    const request = {
      endpoint,
      action,
      params: { orgId },
      cache: false,
      query:
        "?" +
        Object.entries(meta)
          .map(([k, v]) => `${k}=${v}`)
          .join("&"),
    };

    dispatch(api(request));
  };

export const getOrgHotel =
  (hotelId, endpoint = "hotels", action = "singleOrgHotel") =>
  (dispatch, getState) => {
    const state = getState();
    const orgId = state.appuser.data.orgId;
    const request = {
      endpoint,
      action,
      params: { orgId, hotelId },
      cache: false,
    };

    return dispatch(api(request));
  };

export const getHotels = (
  eventId,
  meta = { rpp: -1, order: "DESC" },
  endpoint = "hotels",
  action = "list"
) => {
  return (dispatch, getState) => {
    const state = getState();
    const request = {
      endpoint,
      action,
      params: { eventId },
      cache: false,
    };

    if (state.api.hotels.list.needsUpdate) {
      request.cache = false;
    }
    if (!meta.order) {
      delete meta.order;
    }

    if (!isEmpty(meta)) {
      request.query = `?`;
      Object.entries(meta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    dispatch(api(request));
  };
};

export const getEventRooms =
  (hotelId, endpoint = "hotels", action = "eventRooms") =>
  (dispatch, getState) => {
    const eventId = getState().api.events.edit.data.id;
    const request = {
      endpoint,
      action,
      params: { eventId, hotelId },
      cache: false,
      extra: { hotelId },
    };
    return dispatch(api(request));
  };

export const getOrgRooms =
  (mode = "eventHotels", hotelId, endpoint = "hotels", action = "orgRooms") =>
  (dispatch, getState) => {
    const orgId =
      mode === "eventHotels"
        ? getState().api.events.edit.data.orgId
        : getState().appuser.data.orgId;

    const request = {
      endpoint,
      action,
      params: { orgId, hotelId },
      cache: false,
    };

    return new Promise((resolve, reject) => {
      dispatch(api(request))
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
