import { connect } from "react-redux";
import Hotels from "./Hotels";
import { saveHotel, getOrgHotels } from "@actions/hotels";
import { showModal, hideModal, deleteItem } from "@actions";
import { addNotification } from "@actions";

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.data.orgId,
    hotels: state.api.hotels.orgList.data,
    page: state.page,
    meta: state.api.hotels.orgList.meta,
    fetching: state.api.hotels.orgList.fetching,
    orgAdmin: state.appuser.data.orgAdmin ? true : false,
    ready: state.appuser.ready,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (content) =>
    dispatch(
      showModal(
        content,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
    ),
  hideModal: () => dispatch(hideModal()),
  saveHotel: (orgId, data, hotelId = false, mode, endpoint, formData) => {
    dispatch(
      saveHotel(orgId, data, hotelId, mode, endpoint, formData, undefined, true)
    );
  },
  deleteItem: (orgId, hotelId, eventId) =>
    dispatch(deleteItem({ orgId, hotelId }, "hotels", eventId)),
  getOrgHotels: (orgId, meta) => {
    dispatch(getOrgHotels(orgId, meta));
  },
  addNotification: (msg, status) => dispatch(addNotification(msg, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);
