import { connect } from "react-redux";
import {
  loadRoom,
  scanUser,
  clearScan,
  allOut,
  editRoom,
} from "@actions/users";
import RoomScanning from "./RoomScanning";
import offLineScanner from "@helpers/offline-scanner";
import {
  loadEvent,
  addNotification,
  loadStatistics,
  fullScreen,
} from "@actions";
import {
  getUserNotifications,
  sendHitsToServer,
  scanningAuth,
} from "@actions/appuser";

const mapStateToProps = (state, ownProps) => {
  const currentRoom = state.rooms.roomsScanning.data.filter(
    (r) =>
      r.id === parseInt(ownProps.roomId) &&
      r.eventId === parseInt(ownProps.eventId)
  );
  let activeScans = [];
  if (currentRoom) {
    if (
      state.rooms.roomsScanning.scans.hasOwnProperty(ownProps.roomId) &&
      state.rooms.roomsScanning.scans[ownProps.roomId]
    ) {
      activeScans = state.rooms.roomsScanning.scans[ownProps.roomId];
    }
  }

  return {
    eventId: ownProps.eventId,
    roomId: ownProps.roomId,
    statistics: state.api.events.statistics.data,
    currentRoom: currentRoom.length === 0 ? null : currentRoom[0],
    activeScans,
    fullScreen: state.page.fullScreen,
    apiUrl: state.api.configuration.url,
    router: {
      match: ownProps.match,
      history: ownProps.history,
      location: ownProps.location,
    },
    offlineScans:
      state.rooms.offline.data.hasOwnProperty(ownProps.eventId) &&
      state.rooms.offline.data[ownProps.eventId].hasOwnProperty(
        ownProps.roomId
      ),
    currentAccess: state.appuser.currentAccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRoom: (eventId, roomId) => {
      dispatch(loadRoom(eventId, roomId));
    },
    scanUser: (eventId, roomId, userId) => {
      dispatch(scanUser(eventId, roomId, userId))
        .then((res) => {
          setTimeout(() => dispatch(clearScan(res.roomId)), 10000);
        })
        .catch((err) =>
          setTimeout(() => dispatch(clearScan(err.roomId)), 2000)
        );
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    loadStatistics: (eventId) => {
      return new Promise((resolve, reject) => {
        dispatch(loadStatistics(eventId))
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      });
    },
    allOut: (eventId, roomId) => {
      dispatch(allOut(eventId, roomId));
    },
    downloadFile: (eventId, roomId) => {
      offLineScanner.downloadFile(eventId, roomId);
    },
    sendHitsToServer: (eventId, roomId) => {
      const scansSync = offLineScanner.get(eventId, roomId);
      if (scansSync.length) {
        dispatch(sendHitsToServer(eventId, roomId, scansSync)).then(() => {
          offLineScanner.deleteChannel(eventId, roomId);
          dispatch(getUserNotifications());
        });
      }
    },
    scanningAuth: (cb) => dispatch(scanningAuth(cb)),
    loadEvent: (eventId) => dispatch(loadEvent(eventId)),
    unlockScanner: (eventId, roomId) =>
      dispatch(editRoom(eventId, roomId, { lockScanner: 0 })),
    registerScanner: (eventId, roomId, panelActive = true) =>
      dispatch(editRoom(eventId, roomId, { panelActive })),
    toggleFullScreen: () => {
      dispatch(fullScreen());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomScanning);
