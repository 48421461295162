import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";

const AbstractInstitutes = (props) => {
  const [state, setState] = React.useState({ institutes: [] });

  const getInstitutes = () => {
    const institutes = [...props.institutes];
    if (institutes[institutes.length - 1]?.name != "" && !props.disabled)
      institutes.push({ name: "", index: institutes.length });

    return institutes;
  };

  const onRender = (col, item, trindex) => {
    switch (col.key) {
      case "name": {
        return (
          <input
            disabled={props.disabled}
            placeholder="Type to add an Institute..."
            value={item.name}
            onChange={(e) => onChangeInstitute(e.target.value, trindex)}
          />
        );
      }
      case "remove": {
        if (trindex === state.institutes.length - 1) return null;
        return (
          <div className="remove-item">
            <span
              onClick={() => removeInstitute(trindex)}
              className="fa fa-times-circle"
            ></span>
          </div>
        );
      }
    }
  };
  React.useEffect(() => {
    const institutes = getInstitutes();
    setState({ institutes });
  }, [props]);

  const onChangeInstitute = (value, i) => {
    const newInstitutes = [...state.institutes];
    newInstitutes[i].name = value;
    return props.onChange(newInstitutes);
  };
  const removeInstitute = (index) => {
    const newInstitutes = [...state.institutes];
    newInstitutes.splice(index, 1);
    return props.onChange(newInstitutes, { name: "remove", index });
  };
  const columns = props.disabled
    ? {
        name: {
          name: "",
        },
      }
    : {
        name: {
          name: "",
        },
        remove: {
          name: "",
        },
      };
  return (
    <div className="institutes-container">
      <Table
        includeHead={false}
        id={"abstract-institutes"}
        items={state.institutes}
        columns={columns}
        onRender={onRender}
        containerClassName={`table-container table-responsive`}
      />
    </div>
  );
};

AbstractInstitutes.defaultProps = {
  institutes: [],
};
AbstractInstitutes.propTypes = {
  institutes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AbstractInstitutes;
