import React from "react";
import SvgBranding from "@app_icons/Branding";
import SvgSettingsPanel from "@app_icons/SettingsPanel";
import SvgSwitch from "@app_icons/Switch";
import SvgCatalogue from "@app_icons/Catalogue";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import PageSettings from "./PageSettings/PageSettings";
import Pages from "./Pages/Pages";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ClientBuilder = (props) => {
  const [menuState, setMenuState] = React.useState({
    generalSettings: {
      label: "General Settings",
      items: [
        {
          key: "accessLanguage",
          label: "Access/Language",
        },
        {
          key: "branding",
          label: "Branding",
        },
        {
          key: "footer",
          label: "Footer",
        },
      ],
    },
    pageSettings: {
      label: "Page Settings",
      items: [
        {
          key: "enableReorder",
          label: "Enable/Reorder",
        },
      ],
    },
    pages: {
      label: "Pages",
      items: [
        {
          key: "home",
          label: "Home",
          enabled: true,
        },
        {
          key: "schedule",
          label: "Schedule",
          enabled: true,
        },
        {
          key: "abstracts",
          label: "Abstracts",
          enabled: true,
        },
        {
          key: "videos",
          label: "Videos",
          enabled: true,
        },
        {
          key: "speakers",
          label: "Speakers",
          enabled: true,
        },
        {
          key: "accommodation",
          label: "Accommodation",
          enabled: true,
        },
        {
          key: "exhibition",
          label: "Exhibition Hall",
          enabled: true,
        },
        {
          key: "sponsors",
          label: "Sponsors",
          enabled: true,
        },
        {
          key: "ebag",
          label: "eBag",
          enabled: true,
        },
        {
          key: "products",
          label: "Products",
          enabled: true,
        },
      ],
    },
  });

  const [activePanel, setActivePanel] = React.useState("generalSettings");
  const [activeItem, setActiveItem] = React.useState("accessLanguage");

  React.useEffect(() => {
    const newPagesArr = Array(props.event.featuresOrder.length);
    props.event.featuresOrder.forEach((feat_control) => {
      menuState.pages.items.forEach((page) => {
        if (page.key === feat_control.key) {
          newPagesArr[feat_control.index - 1] = {
            key: page.key,
            label: page.label,
            id: feat_control.id,
            enabled: feat_control.enabled,
          };
        }
      });
    });

    setMenuState({
      ...menuState,
      pages: {
        label: "Pages",
        items: newPagesArr,
      },
    });
  }, [props.event]);

  const getActiveItem = () =>
    menuState[activePanel].items.find((item) => {
      return item?.key === activeItem;
    });

  const getMenuIcon = (item) => {
    switch (item.key) {
      case "accessLanguage": {
        return (
          <SvgSettingsPanel
            className={`panel-item-icon panel-item-icon-${item.key}`}
          />
        );
      }
      case "branding": {
        return (
          <SvgBranding
            className={`panel-item-icon panel-item-icon-${item.key}`}
          />
        );
      }
      case "footer": {
        return (
          <SvgCatalogue
            className={`panel-item-icon panel-item-icon-${item.key}`}
          />
        );
      }
      case "enableReorder": {
        return (
          <SvgSwitch
            className={`panel-item-icon panel-item-icon-${item.key}`}
          />
        );
      }
      default: {
        return (
          <div className="indicator-wrapper">
            <div className={`indicator ${item.enabled ? "enabled" : ""}`} />
          </div>
        );
      }
    }
  };

  const renderPanelContent = () => {
    switch (activePanel) {
      case "generalSettings": {
        return <GeneralSettings activeItem={getActiveItem()} />;
      }
      case "pageSettings": {
        return (
          <PageSettings
            activeItem={getActiveItem()}
            pagesControls={menuState.pages.items}
          />
        );
      }
      case "pages": {
        return <Pages activeItem={getActiveItem()} />;
      }
    }
  };

  return (
    <div className="client-builder-wrapper">
      <div className="control-panel">
        {Object.entries(menuState).map(([key, panel]) => {
          return (
            <div className={`panel-section panel-section-${key}`} key={key}>
              <span className="panel-title">{panel.label}</span>
              {panel.items.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setActivePanel(key);
                      setActiveItem(item.key);
                    }}
                    className={`panel-item panel-item-${item.key} ${
                      activeItem === item.key ? "active" : ""
                    }`}
                    key={item.key}
                  >
                    {getMenuIcon(item)}
                    <span>{item.label}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="panel-content">{renderPanelContent()}</div>
    </div>
  );
};

ClientBuilder.propTypes = {
  event: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
  };
};

export default connect(mapStateToProps, {})(ClientBuilder);
