import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Switch from "@layout/Switch";
import { updateEvent } from "@actions";

const DangerZone = (props) => {
  return (
    <div className="danger-zone">
      <h3>Danger Zone</h3>
      <p>
        Careful! Disabling the client panel will prevent anyone from accessing
        your Client Panel.
      </p>
      <div className="form-group">
        <div className="field-type type-switch kmb-switch clearfix ">
          <label style={{ flex: 1, margin: 0 }}>Enable Client Panel</label>
          <div className="switch-holder">
            <Switch
              id={`display-sponsor-types`}
              isActive={props.event.active}
              onChange={(active) => {
                return props.updateEvent(props.event.id, { active });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DangerZone.propTypes = {
  updateEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    policies: state.api.accesspolicies?.list?.data,
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEvent: (eventId, event) => dispatch(updateEvent(eventId, event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DangerZone);
