import React from "react";
import PropTypes from "prop-types";
import formFields from "@helpers/form-fields";
import formHelper from "@helpers/form-helper";
import KMBLoader from "@layout/KMBLoader";
import { formChanged, formCancelled } from "@actions";
import { connect } from "react-redux";
import SubmitContainer from "@layout/SubmitContainer";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.analyzeFields = this.analyzeFields.bind(this);
    this.switchOnChange = this.switchOnChange.bind(this);
    this.checkCondition = this.checkCondition.bind(this);
    this.checkConditionSchema = this.checkConditionSchema.bind(this);
    this.onChange = this.onChange.bind(this);
    const value = Object.assign({}, props.value);
    const { fields, grouped, conditional } = formFields.getFields(
      props.componentName,
      props.formName,
      value
    );
    this.conditional = conditional;
    const prepared = this.analyzeFields(fields);
    this.fields = prepared.fields;
    this.grouped = grouped;
    this.state = {
      errors: {},
      resetted: false,
      grouped: [],
      conditionalToggled: [],
      sending: false,
      conditions: prepared.conditions,
      sliders: prepared.sliders,
      rerender: false,
      isSubmitted: false,
    };

    this.customErrorHandling = ["range", "speaker", "subscription", "address"];

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onChange(key, value = false, meta = {}) {
    if (this.state.sliders.hasOwnProperty(key)) {
      this.setState({
        sliders: Object.assign({}, this.state.sliders, {
          [key]: value.val,
        }),
      });
    }

    if (this.conditional.includes(key)) {
      this.setState({
        conditions: Object.assign({}, this.state.conditions, {
          [key]: value,
        }),
      });
    }

    if (this.state.isSubmitted) {
      const updatedErrors = {};

      for (const key in this.state.errors) {
        if (this.state.errors.hasOwnProperty(key)) {
          const fieldRef = { [key]: this.refs[key] };
          const { errors } = formHelper.validateForm(fieldRef, this.grouped);
          updatedErrors[key] = errors[key];
        }
      }

      this.setState({ errors: updatedErrors });
    }

    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({ key, value }, meta);
    }
    if (this.props.hasOwnProperty("onKeyDown")) {
      this.props.onKeyDown({ key, value });
    }
    this.props.triggerFormChanged &&
      this.props.formChanged(
        `${this.props.componentName}/${this.props.formName}`
      );
  }

  analyzeFields(fields) {
    const conditions = {},
      sliders = {};

    for (const [key, field] of Object.entries(fields)) {
      if (field.type === "switch") {
        fields[key].switchOnChange = this.switchOnChange;
      }

      if (field.type === "range") {
        const now = new Date();
        sliders[field.name] = field.hasOwnProperty("value")
          ? field.value
          : {
              from: field.from.value ? field.from.value.tz : now,
              to: field.to.value ? field.to.value.tz : now,
            };
      }

      if (field.type === "slider") {
        sliders[field.name] = field.hasOwnProperty("value") ? field.value : 0;
      }

      if (field.type === "group") {
        for (const [skey] of Object.entries(field.fields)) {
          fields[key].fields[skey].onChange = this.onChange;
          fields[key].fields[skey].callback = field.fields[skey].callback;
        }
      }

      if (this.conditional.includes(key)) {
        if (field.hasOwnProperty("value")) {
          conditions[key] = field.value;
        }
      }

      fields[key].onChange = this.onChange;
    }
    return { fields, conditions, sliders };
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { values, errors, type } = formHelper.validateForm(
      this.refs,
      this.grouped
    );
    this.setState({ errors, value: values, sending: true }, () => {
      if (this.props.hasOwnProperty("onSubmit")) {
        this.props.onSubmit({ value: values, errors, type });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = Object.assign({}, nextProps.value);
    const { fields, grouped, conditional } = formFields.getFields(
      this.props.componentName,
      this.props.formName,
      value
    );
    this.conditional = conditional;
    const prepared = this.analyzeFields(fields);
    this.fields = prepared.fields;

    this.grouped = grouped;
  }

  switchOnChange(key, value) {
    if (this.conditional.includes(key)) {
      // condition met

      const conditionalToggled = [...this.state.conditionalToggled];
      const pos = conditionalToggled.indexOf(key);
      if (pos === -1) {
        conditionalToggled.push(key);
      } else {
        conditionalToggled.splice(pos, 1);
      }

      this.setState({
        conditionalToggled,
      });
    }

    if (this.props.switchOnChange) {
      this.props.switchOnChange(key, value);
    }
  }

  onCancel(e) {
    e.preventDefault();
    this.props.formCancelled(
      `${this.props.componentName}/${this.props.formName}`
    );

    if (this.props.hasOwnProperty("onCancel")) {
      this.props.onCancel();
    }

    this.setState({ resetted: true, errors: {} }, () => {
      this.setState({ resetted: false });
    });
  }
  checkConditionSchema(field) {
    const filters = field.conditionSchema.filters;
    // filters are array of arrays
    // the first level is OR, the second level is AND
    let userMatches = false;
    if (!filters) return true;
    filters.forEach((block) => {
      let blockMatches = true;
      block.forEach((f) => {
        let userValue = this.state.conditions?.[f.key];
        if (f.type === "yes_no") {
          userValue = userValue?.toLowerCase?.() == "yes" ? 1 : 0;
        }
        const value = f.type === "dropdown" ? f.rendered_value : f.value;
        switch (f.operator) {
          case "=":
            if (userValue != value) blockMatches &&= false;
            break;
          case "<>":
            if (userValue == value) blockMatches &&= false;
            break;
          case "like":
            if (!userValue.includes(value)) blockMatches &&= false;
            break;
          case "not like":
            if (userValue.includes(value)) blockMatches &&= false;
            break;
          case ">":
            if (userValue <= value) blockMatches &&= false;
            break;
          case ">=":
            if (userValue < value) blockMatches &&= false;
            break;
          case "<":
            if (userValue >= value) blockMatches &&= false;
            break;
          case "<=":
            if (userValue > value) blockMatches &&= false;
            break;
        }
      });
      userMatches = userMatches || blockMatches;
    });
    return userMatches;
  }
  checkCondition(condition) {
    const result = [];
    const operator = condition[0].operator || "and";
    for (const c of condition) {
      const val = this.state.conditions.hasOwnProperty(c.key)
        ? this.state.conditions[c.key]
        : false;
      if (c.expression === "===") {
        result.push(val === c.value);
      } else if (c.expression === "!==") {
        result.push(val !== c.value);
      } else if (c.expression === "in") {
        result.push(val.includes(c.value));
      }
    }
    if (operator == "and") {
      return result.indexOf(false) == -1;
    }
    return result.indexOf(true) !== -1;
  }

  render() {
    this.renderedFields = formHelper.renderFields(
      this.fields,
      this.grouped,
      this.state.sliders,
      this.state.errors
    );
    return (
      <form
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.target.nodeName !== "TEXTAREA")
            e.preventDefault();
        }}
        className="form-container"
        key={`${this.state.resetted ? "resetted-" : ""}form`}
        onSubmit={this.onSubmit}
      >
        {this.props.updating && (
          <KMBLoader rows={15} padding={24} height={53} />
        )}
        {(() => {
          const fieldsToRender = this.renderedFields
            .map((field) => {
              const hasError =
                field?.type !== "group" &&
                this.state?.errors?.hasOwnProperty(field?.id) &&
                this.state?.errors[field.id]?.length > 0 &&
                !this.customErrorHandling.includes(field?.id);

              let className = `form-group${hasError ? " has-error" : ""}`;
              if (this.props.updating) {
                className += " hidden";
              }

              if (field.condition && !this.checkCondition(field.condition)) {
                return null;
              }

              if (field.conditionSchema && !this.checkConditionSchema(field)) {
                return null;
              }
              return (
                <div className={className} key={field.id}>
                  {field.jsx}
                </div>
              );
            })
            .reduce((prev, next) => {
              // Skip adding 'prev' if the last element in 'prev' is 'type-section' and 'next' is also 'type-section'
              if (
                prev.length > 0 &&
                prev[
                  prev.length - 1
                ]?.props?.children?.props?.className?.includes?.(
                  "type-section"
                ) &&
                next?.props?.children?.props?.className?.includes?.(
                  "type-section"
                )
              ) {
                // replace the last element of prev with next
                prev[prev.length - 1] = next;
                return prev;
              }

              // Add 'next' unless it's undefined
              if (next) {
                return [...prev, next];
              }

              return prev;
            }, []);

          // Remove the last element if it's of type 'h3'
          if (
            fieldsToRender.length > 0 &&
            fieldsToRender[
              fieldsToRender.length - 1
            ]?.props?.children?.props?.className?.includes?.("type-section")
          ) {
            return fieldsToRender.slice(0, -1);
          }
          return fieldsToRender;
        })()}

        <SubmitContainer
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          onPublish={this.props.saveAndPrint}
          submitText={this.props.submitText}
          cancelText={this.props.cancelText}
          publishText={this.props.saveAndPrint ? "Save and print" : ""}
        />
      </form>
    );
  }
}

Form.propTypes = {
  componentName: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  value: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  updating: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  publishText: PropTypes.string,
  formChanged: PropTypes.func,
  switchOnChange: PropTypes.func,
  formCancelled: PropTypes.func,
  triggerFormChanged: PropTypes.bool,
  saveAndPrint: PropTypes.func,
};

Form.defaultProps = {
  value: {},
  updating: false,
  triggerFormChanged: true,
};

const mapStateToProps = (state) => {
  return {
    hasChanges: state.page.hasChanges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    formChanged: (form) => {
      dispatch(formChanged(form));
    },
    formCancelled: (form) => {
      dispatch(formCancelled(form));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
