import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveOrgRoom } from "@actions/hotels";
import Form from "@components/form";

class SingleRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      file: null,
    };
  }

  render() {
    return (
      <div className="single-room form-container">
        <h2>{this.props.room.id ? "Edit Room" : "Create Room"}</h2>
        <Form
          componentName="hotels"
          formName={"singleRoom"}
          value={this.props.room}
          onSubmit={(data) => {
            const { name, description, facilities, capacity, hotelRoomImages } =
              data.value;
            this.props.saveRoom(this.props.hotelId, this.props.room.id, {
              name,
              description,
              facilities,
              capacity,
              hotelRoomImages,
            });
          }}
          onCancel={() => this.props.hideModal()}
          onChange={(data) => {
            if (data.key === "hotelRoomImages") {
              this.setState(
                {
                  showConfirm: true,
                  file: data.value,
                },
                () => {
                  this.props.saveRoom(this.props.hotelId, this.props.room.id, {
                    hotelRoomImages: [data.value.id],
                  });
                }
              );
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  saveRoom: (
    hotelId,
    roomId,
    data,
    eventFields,
    endpoint = "hotels",
    deleteImage = false
  ) => {
    dispatch(
      saveOrgRoom(hotelId, roomId, data, false, endpoint, deleteImage)
    ).catch((error) => {
      console.error("Error in saving room:", error);
    });
  },
});

SingleRoom.defaultProps = {
  room: {},
};

SingleRoom.propTypes = {
  roomId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  hotelId: PropTypes.string.isRequired,
  room: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hotel: PropTypes.any,
  saveRoom: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  orgRooms: PropTypes.array,
  rooms: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleRoom);
