import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "@actions";
import { connect } from "react-redux";
import Form from "@components/form";

const SingleVideo = (props) => {
  const [state, setState] = React.useState({ item: props.item });

  return (
    <div className="form-container single-sponsor">
      <h2>{`${
        props.mode == "create"
          ? "Create Video Sponsor Item"
          : "Edit Video Sponsor Item"
      }`}</h2>
      <Form
        formName="videoSponsors"
        componentName="clientPanel"
        value={state.item}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;
          if (
            !state.item.clientVideoSponsorImage?.length &&
            !data.value.clientVideoSponsorImage.length &&
            (props.item.clientVideoSponsorImage || [])[0]
          ) {
            data.value.clientVideoSponsorImage = JSON.stringify([
              props.item.clientVideoSponsorImage[0].id,
            ]);
          } else if (!data.value.clientVideoSponsorImage.length) {
            delete data.value.clientVideoSponsorImage;
          }
          props.onSave(data.value);
        }}
        onCancel={() => {
          props.hideModal();
        }}
        onChange={(data) => {
          if (data.key === "clientVideoSponsorImage") {
            setState({ item: { ...state.item, clientVideoSponsorImage: [] } });
          }
        }}
      />
    </div>
  );
};

SingleVideo.propTypes = {
  eventId: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
  item: PropTypes.object,
  onSave: PropTypes.func,
  mode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleVideo);
