import { api } from "@actions/ajax";

export const patchSpeakers = (eventId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const endpoint = "patch";
      const query = {
        endpoint,
        action: "speakers",
        params: {
          eventId,
        },
        body: { data: {} },
      };

      dispatch(api(query))
        .then((result) => {
          resolve(result);
        })
        .catch((err) => reject(err));
    });
  };
};
