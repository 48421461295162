import { addNotification, clearChanges, hideModal } from "@actions";
import { callApi, sanitizeArgs } from "@helpers/async-helper";
import ApiHelper from "@helpers/api-helper";
import { isEmpty } from "@helpers";

export const DOING_AJAX = "DOING_AJAX";
export const REQUEST_EVENTS_LIST = "REQUEST_EVENTS_LIST";
export const RECEIVED_EVENTS_LIST = "RECEIVED_EVENTS_LIST";
export const RECEIVED_INVOICES_LIST = "RECEIVED_INVOICES_LIST";
export const REQUEST_INVOICES_LIST = "REQUEST_INVOICES_LIST";

export const REQUEST_RENTABLES_LIST = "REQUEST_RENTABLES_LIST";
export const RECEIVED_RENTABLES_LIST = "RECEIVED_RENTABLES_LIST";

export const RECEIVED_RENTABLES_DATALIST = "RECEIVED_RENTABLES_DATALIST";
export const REQUEST_RENTABLES_DATALIST = "REQUEST_RENTABLES_DATALIST";

export const RECEIVED_EVENTS_EDIT = "RECEIVED_EVENTS_EDIT";
export const RECEIVED_EVENTS_EDITPAYMENTS = "RECEIVED_EVENTS_EDITPAYMENTS";
export const INVALIDATE_EVENTS_LIST = "INVALIDATE_EVENTS_LIST";
export const FAILED_DATA = "FAILED_DATA";
export const RECEIVED_EVENTS_RECENT = "RECEIVED_EVENTS_RECENT";
export const RECEIVED_ACCESSPOLICIES_LIST = "RECEIVED_ACCESSPOLICIES_LIST";
export const RECEIVED_ACCESSPOLICIES_EDIT = "RECEIVED_ACCESSPOLICIES_EDIT";
export const RECEIVED_ACCESSPOLICIES_CREATE = "RECEIVED_ACCESSPOLICIES_CREATE";
export const RECEIVED_USERS_LIST = "RECEIVED_USERS_LIST";
export const REQUEST_EVENTS_GETCLIENTPANELSETTINGS =
  "REQUEST_EVENTS_GETCLIENTPANELSETTINGS";
export const REQUEST_EVENTS_UPDATECLIENTPANELSETTINGS =
  "REQUEST_EVENTS_UPDATECLIENTPANELSETTINGS";
export const RECEIVED_EVENTS_GETCLIENTPANELSETTINGS =
  "RECEIVED_EVENTS_GETCLIENTPANELSETTINGS";
export const RECEIVED_EVENTS_UPDATECLIENTPANELSETTINGS =
  "RECEIVED_EVENTS_UPDATECLIENTPANELSETTINGS";
export const RECEIVED_USERS_CREATE = "RECEIVED_USERS_CREATE";
export const RECEIVED_USERS_UPDATE = "RECEIVED_USERS_UPDATE";
export const RECEIVED_ROOMS_EDIT = "RECEIVED_ROOMS_EDIT";
export const FAILED_REQUEST = "FAILED_REQUEST";
export const RECEIVED_EVENTS_UPDATE = "RECEIVED_EVENTS_UPDATE";
export const RECEIVED_EVENTS_STATISTICS = "RECEIVED_EVENTS_STATISTICS";
export const RECEIVED_CACHED_EVENTS_STATISTICS =
  "RECEIVED_CACHED_EVENTS_STATISTICS";
export const RECEIVED_SESSIONS_LIST = "RECEIVED_SESSIONS_LIST";
export const RECEIVED_SESSIONS_GETSESSIONTYPES =
  "RECEIVED_SESSIONS_GETSESSIONTYPES";
export const RECEIVED_SESSIONS_EDIT = "RECEIVED_SESSIONS_EDIT";
export const RECEIVED_SESSIONS_CREATE = "RECEIVED_SESSIONS_CREATE";
export const RECEIVED_SESSIONS_IMPORT = "RECEIVED_SESSIONS_IMPORT";
export const RECEIVED_PERSONS_LIST = "RECEIVED_PERSONS_LIST";
export const RECEIVED_CACHED_STAFF_LIST = "RECEIVED_CACHED_STAFF_LIST";
export const RECEIVED_CACHED_PERSONS_LIST = "RECEIVED_CACHED_PERSONS_LIST";
export const RECEIVED_EVENTS_TOTALSCANS = "RECEIVED_EVENTS_TOTALSCANS";
export const RECEIVED_SPEECHES_CREATE = "RECEIVED_SPEECHES_CREATE";
export const RECEIVED_SPEECHES_DELETE = "RECEIVED_SPEECHES_DELETE";
export const RECEIVED_SPEECHES_EDIT = "RECEIVED_SPEECHES_EDIT";
export const RECEIVED_PERSONS_CREATE = "RECEIVED_PERSONS_CREATE";
export const RECEIVED_STAFF_LIST = "RECEIVED_STAFF_LIST";
export const RECEIVED_STAFF_EDIT = "RECEIVED_STAFF_EDIT";
export const RECEIVED_PERSONS_EDIT = "RECEIVED_PERSONS_EDIT";
export const RECEIVED_STAFF_CREATE = "RECEIVED_STAFF_CREATE";
export const RECEIVED_ROLES_TYPES = "RECEIVED_ROLES_TYPES";
export const RECEIVED_SUBSCRIPTIONS_LIST = "RECEIVED_SUBSCRIPTIONS_LIST";
export const RECEIVED_SUBSCRIPTIONS_TYPES = "RECEIVED_SUBSCRIPTIONS_TYPES";
export const RECEIVED_SUBSCRIPTIONS_PERIODS = "RECEIVED_SUBSCRIPTIONS_PERIODS";
export const RECEIVED_SUBSCRIPTIONS_UPDATE = "RECEIVED_SUBSCRIPTIONS_UPDATE";
export const REQUEST_STAFF_EDIT = "REQUEST_STAFF_EDIT";
export const RECEIVED_HOTELS_LIST = "RECEIVED_HOTELS_LIST";
export const RECEIVED_HOTELS_EDIT = "RECEIVED_HOTELS_EDIT";
export const RECEIVED_HOTELS_UPDATEROOM = "RECEIVED_HOTELS_UPDATEROOM";
export const RECEIVED_HOTELS_CREATEROOM = "RECEIVED_HOTELS_CREATEROOM";
export const RECEIVED_HOTELS_SAVEEVENTROOM = "RECEIVED_HOTELS_SAVEEVENTROOM";
export const RECEIVED_HOTELS_REORDER = "RECEIVED_HOTELS_REORDER";
export const RECEIVED_HOTELS_ORGLIST = "RECEIVED_HOTELS_ORGLIST";
export const RECEIVED_HOTELS_UNASSIGNHOTEL = "RECEIVED_HOTELS_UNASSIGNHOTEL";
export const RECEIVED_HOTELS_ASSIGNHOTEL = "RECEIVED_HOTELS_ASSIGNHOTEL";
export const RECEIVED_HOTELS_UPDATEEVENTROOM =
  "RECEIVED_HOTELS_UPDATEEVENTROOM";
export const RECEIVED_HOTELS_ASSIGNROOM = "RECEIVED_HOTELS_ASSIGNROOM";
export const RECEIVED_HOTELS_ORGROOMS = "RECEIVED_HOTELS_ORGROOMS";
export const RECEIVED_HOTELS_EVENTROOMS = "RECEIVED_HOTELS_EVENTROOMS";
export const RECEIVED_USERS_GETHITS = "RECEIVED_USERS_GETHITS";
export const REQUEST_USERS_GETHITS = "REQUEST_USERS_GETHITS";
export const RECEIVED_USERS_GET = "RECEIVED_USERS_GET";
export const REQUEST_HOTELS_UPDATEEVENTROOM = "REQUEST_HOTELS_UPDATEEVENTROOM";
export const REQUEST_SESSIONS_GETSESSIONTYPES =
  "REQUEST_SESSIONS_GETSESSIONTYPES";
export const RECEIVED_ABSTRACTS_LIST = "RECEIVED_ABSTRACTS_LIST";
export const RECEIVED_ABSTRACTS_SCHEMAS = "RECEIVED_ABSTRACTS_SCHEMAS";
export const RECEIVED_ABSTRACTS_GETSCHEMA = "RECEIVED_ABSTRACTS_GETSCHEMA";
export const REQUEST_ABSTRACTS_GETSCHEMA = "REQUEST_ABSTRACTS_GETSCHEMA";
export const RECEIVED_ABSTRACTS_UPDATEEVENTSCHEMA =
  "RECEIVED_ABSTRACTS_UPDATEEVENTSCHEMA";
export const RECEIVED_ABSTRACTS_CREATESCHEMA =
  "RECEIVED_ABSTRACTS_CREATESCHEMA";
export const RECEIVED_ABSTRACTS_UPDATESCHEMA =
  "RECEIVED_ABSTRACTS_UPDATESCHEMA";
export const RECEIVED_TOPICS_GETORGTOPICS = "RECEIVED_TOPICS_GETORGTOPICS";
export const RECEIVED_JUDGE_LIST = "RECEIVED_JUDGE_LIST";
export const RECEIVED_JUDGE_GET = "RECEIVED_JUDGE_GET";
export const RECEIVED_JUDGE_RATE = "RECEIVED_JUDGE_RATE";
export const RECEIVED_USER_NOTIFICATIONSREAD =
  "RECEIVED_USER_NOTIFICATIONSREAD";
export const REQUEST_EVENTHOOKS_LIST = "REQUEST_EVENTHOOKS_LIST";
export const RECEIVED_EVENTHOOKS_LIST = "RECEIVED_EVENTHOOKS_LIST";
export const RECEIVED_EVENTHOOKS_EDIT = "RECEIVED_EVENTHOOKS_EDIT";
export const RECEIVED_HOOKEMAILS_LIST = "RECEIVED_HOOKEMAILS_LIST";
export const RECEIVED_HOOKEMAILS_EDIT = "RECEIVED_HOOKEMAILS_EDIT";
export const REQUEST_HOOKEMAILS_EDIT = "REQUEST_HOOKEMAILS_EDIT";
export const REQUEST_HOOKEMAILS_LIST = "REQUEST_HOOKEMAILS_LIST";
export const REQUEST_HOOKEMAILS_CREATE = "REQUEST_HOOKEMAILS_CREATE";
export const RECEIVED_HOOKEMAILS_CREATE = "RECEIVED_HOOKEMAILS_CREATE";
export const RECEIVED_HOOKEMAILS_DELETE = "RECEIVED_HOOKEMAILS_DELETE";
export const REQUEST_HOOKEMAILS_DELETE = "REQUEST_HOOKEMAILS_DELETE";

export const RECEIVED_EXHIBITIONDIGITALSLOTS_LIST =
  "RECEIVED_EXHIBITIONDIGITALSLOTS_LIST";
export const RECEIVED_EXHIBITIONDIGITALSLOTS_CREATE =
  "RECEIVED_EXHIBITIONDIGITALSLOTS_CREATE";
export const RECEIVED_EXHIBITIONDIGITALSLOTS_UPDATE =
  "RECEIVED_EXHIBITIONDIGITALSLOTS_UPDATE";
export const RECEIVED_EXHIBITIONDIGITALSLOTS_DELETE =
  "RECEIVED_EXHIBITIONDIGITALSLOTS_DELETE";

export const RECEIVED_CRM_LIST = "RECEIVED_CRM_LIST";
export const RECEIVED_SCHEMAS_LIST = "RECEIVED_SCHEMAS_LIST";
export const RECEIVED_SCHEMAS_ORGLIST = "RECEIVED_SCHEMAS_ORGLIST";
export const REQUEST_SCHEMAS_UPDATE = "REQUEST_SCHEMAS_UPDATE";
export const RECEIVED_SCHEMAS_UPDATE = "RECEIVED_SCHEMAS_UPDATE";
export const RECEIVED_SCHEMAS_CREATE = "RECEIVED_SCHEMAS_CREATE";
export const REQUEST_PRESETEMAILS_LIST = "REQUEST_PRESETEMAILS_LIST";
export const RECEIVED_PRESETEMAILS_LIST = "RECEIVED_PRESETEMAILS_LIST";
export const RECEIVED_PRESETEMAILS_CONTACTS = "RECEIVED_PRESETEMAILS_CONTACTS";
export const RECEIVED_PRESETEMAILS_CUSTOMCONTACTS =
  "RECEIVED_PRESETEMAILS_CUSTOMCONTACTS";
export const RECEIVED_PRESETEMAILS_CREATE = "RECEIVED_PRESETEMAILS_CREATE";
export const RECEIVED_PRESETEMAILS_CUSTOM = "RECEIVED_PRESETEMAILS_CUSTOM";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const RECEIVED_SENTEMAILS_LIST = "RECEIVED_SENTEMAILS_LIST";
export const RECEIVED_SENTEMAILS_UPDATE = "RECEIVED_SENTEMAILS_UPDATE";
export const RECEIVED_PRESETEMAILS_UPDATE = "RECEIVED_PRESETEMAILS_UPDATE";
export const RECEIVED_QUESTIONNAIRE_CUSTOMCONTACTS =
  "RECEIVED_QUESTIONNAIRE_CUSTOMCONTACTS";
export const REQUEST_QUESTIONNAIRE_LIST = "REQUEST_QUESTIONNAIRE_LIST";
export const RECEIVED_QUESTIONNAIRE_LIST = "RECEIVED_QUESTIONNAIRE_LIST";
export const RECEIVED_QUESTIONNAIRE_CREATE = "RECEIVED_QUESTIONNAIRE_CREATE";
export const DELETE_CONTACT_QUESTIONNAIRE = "DELETE_CONTACT_QUESTIONNAIRE";
export const ADD_CONTACT_QUESTIONNAIRE = "ADD_CONTACT_QUESTIONNAIRE";
export const REQUEST_QUESTIONNAIRE_UPDATE = "REQUEST_QUESTIONNAIRE_UPDATE";
export const RECEIVED_SPONSORS_LIST = "RECEIVED_SPONSORS_LIST";
export const REQUEST_SPONSORS_LIST = "REQUEST_SPONSORS_LIST";
export const RECEIVED_SPONSORS_UPDATE = "RECEIVED_SPONSORS_UPDATE";
export const RECEIVED_SPONSORS_PERSONSLIST = "RECEIVED_SPONSORS_PERSONSLIST";
export const RECEIVED_SPONSORS_PERSONSCREATE =
  "RECEIVED_SPONSORS_PERSONSCREATE";

export const RECEIVED_POLICYGROUPS_LIST = "RECEIVED_POLICYGROUPS_LIST";
export const REQUEST_POLICYGROUPS_LIST = "REQUEST_POLICYGROUPS_LIST";

export const RECEIVED_SPONSORS_SPONSORSHIPSTATUS =
  "RECEIVED_SPONSORS_SPONSORSHIPSTATUS";
export const REQUEST_SPONSORS_SPONSORSHIPSTATUS =
  "REQUEST_SPONSORS_SPONSORSHIPSTATUS";
export const RECEIVED_RESERVATIONS_LISTRESERVATIONS =
  "RECEIVED_RESERVATIONS_LISTRESERVATIONS";
export const REQUEST_RESERVATIONS_LISTRESERVATIONS =
  "REQUEST_RESERVATIONS_LISTRESERVATIONS";
export const RECEIVED_PAYMENTS_LIST = "RECEIVED_PAYMENTS_LIST";
export const REQUEST_PAYMENTS_LIST = "REQUEST_PAYMENTS_LIST";
export const RECEIVED_USERS_ADVANCEDLIST = "RECEIVED_USERS_ADVANCEDLIST";
export const REQUEST_SENTEMAILS_LIST = "REQUEST_SENTEMAILS_LIST";
export const RECEIVED_DATASETS_LIST = "RECEIVED_DATASETS_LIST";
export const RECEIVED_DATASETS_LISTENTRIES = "RECEIVED_DATASETS_LISTENTRIES";
export const REQUEST_DATASETS_LISTENTRIES = "REQUEST_DATASETS_LISTENTRIES";
export const REQUEST_DATASETS_UPDATE = "REQUEST_DATASETS_UPDATE";
export const RECEIVED_PRODUCTS_LIST = "RECEIVED_PRODUCTS_LIST";
export const REQUEST_PRODUCTS_PRICESLIST = "REQUEST_PRODUCTS_PRICESLIST";
export const REQUEST_PRODUCTS_DATALIST = "REQUEST_PRODUCTS_DATALIST";
export const RECEIVED_PRODUCTS_PRICESLIST = "RECEIVED_PRODUCTS_PRICESLIST";
export const RECEIVED_PRODUCTS_DATALIST = "RECEIVED_PRODUCTS_DATALIST";
export const RECEIVED_TYPES_GETORGTYPES = "RECEIVED_TYPES_GETORGTYPES";
export const RECEIVED_SESSIONUSERS_LIST = "RECEIVED_SESSIONUSERS_LIST";
import { addErrorsInLocalStorage } from "../helpers/ErrorsInLocalStorage";
export const api = (args) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let state = getState();

      const timezone = isEmpty(state.api.events.edit.data)
        ? null
        : state.api.events.edit.data.timezone;

      const request = sanitizeArgs(args, timezone);

      if (!request.success) reject(request.message);

      dispatch(ajaxRequest(request));
      state = getState();
      const cached = hasValidCache(state, request);
      if (cached && request.args.endpoint !== "subscriptions") {
        dispatch(ajaxReceivedCached(request, cached));
        resolve(cached);
        return;
      }
      return callApi(request.url, request.params, request.dataType)
        .then((response) => {
          try {
            dispatch(ajaxReceived(request, response));
            const deleteFile =
              request.args.hasOwnProperty("extra") &&
              request.args.extra.hasOwnProperty("deleteFile") &&
              request.args.extra.deleteFile;

            if (["POST", "PUT"].includes(request.params.method)) {
              dispatch(clearChanges());
              const state = getState();
              if (state.page.modal.showModal) {
                const preserveModal =
                  (request.args.hasOwnProperty("extra") &&
                    request.args.extra.hasOwnProperty("preserveModal") &&
                    request.args.extra.preserveModal === true) ||
                  deleteFile;

                if (!preserveModal) {
                  dispatch(hideModal());
                }
              }
            }
            if (!args.preventNotification) {
              const notifications = checkForNotifications(
                request,
                response,
                deleteFile
              );
              if (notifications.length > 0) {
                notifications.map((n) =>
                  dispatch(addNotification(n, "success"))
                );
              }
            }

            resolve(response);
          } catch (e) {
            reject(e);
          }
        })

        .catch((err) => {
          addErrorsInLocalStorage(err);

          dispatch(addNotification(err.message, "error"));
          dispatch(ajaxFailed(request, err));
          reject(err);
        });
    });
  };
};

const checkForNotifications = (request, response, deleteFile) => {
  const notifications = [];

  if (!response.hasOwnProperty("status") || response.status !== 200) {
    return notifications;
  }

  if (deleteFile) {
    notifications.push(`File deleted successfully!`);
    return notifications;
  }

  if (
    request.args.hasOwnProperty("extra") &&
    request.args.extra.hasOwnProperty("withoutNotification")
  ) {
    return notifications;
  }

  if (
    request.params.method === "POST" &&
    (request.args.action === "create" ||
      request.args.action.substr(0, 6) === "create")
  ) {
    let name = ApiHelper.getNameByEndpoint(
      request.args.endpoint,
      false,
      request.args.action
    );
    if (
      response.hasOwnProperty("data") &&
      response.data.hasOwnProperty("name")
    ) {
      name +=
        request.args.endpoint === "roles"
          ? ` "${response.data.role.name}"`
          : ` "${response.data.name}"`;
    }
    name +=
      request.args.endpoint === "roles"
        ? ` assigned successfully!`
        : ` created successfully!`;
    notifications.push(name);
  }

  if (
    request.params.method === "PUT" &&
    (request.args.action === "edit" || request.args.action === "update")
  ) {
    let name = ApiHelper.getNameByEndpoint(request.args.endpoint);
    if (
      response.hasOwnProperty("data") &&
      response.data.hasOwnProperty("name")
    ) {
      name +=
        request.args.endpoint === "roles"
          ? ` "${response.data.role.name}"`
          : ` "${response.data.name}"`;
    }
    name +=
      request.args.endpoint === "roles"
        ? ` assigned successfully!`
        : ` updated successfully!`;
    notifications.push(name);
  }

  if (
    request.params.method === "PUT" &&
    request.args.action === "reorder" &&
    request.needNotification === false
  ) {
    notifications.push("Hotel updated successfully!");
  }

  return notifications;
};

export const ajaxRequest = (request) => {
  const obj = {
    type: `REQUEST_${request.args.endpoint.toUpperCase()}_${request.args.action.toUpperCase()}`,
    endpoint: request.args.endpoint,
    action: request.args.action,
  };

  if (request.args.hasOwnProperty("extra")) {
    obj.extra = request.args.extra;
  }

  return obj;
};

export const ajaxReceivedCached = (request, response) => {
  return {
    type: `RECEIVED_CACHED_${request.args.endpoint.toUpperCase()}_${request.args.action.toUpperCase()}`,
    response,
  };
};

export const ajaxReceived = (request, response) => {
  const obj = {
    type: `RECEIVED_${request.args.endpoint.toUpperCase()}_${request.args.action.toUpperCase()}`,
    response,
  };

  if (request.args.hasOwnProperty("extra")) {
    obj.extra = request.args.extra;
  }

  return obj;
};

export const ajaxFailed = (request, err) => {
  return {
    type: FAILED_REQUEST,
    endpoint: request.args.endpoint,
    actionKey: request.args.action,
    errorMessage: err.message,
    status: err.status,
  };
};

const hasValidCache = (state, request) => {
  if (request.args.hasOwnProperty("cache") && request.args.cache === false) {
    return false;
  }

  if (request.params.method === "GET") {
    if (
      state.api.hasOwnProperty(request.args.endpoint) &&
      state.api[request.args.endpoint].hasOwnProperty(request.args.action)
    ) {
      const matched = state.api[request.args.endpoint][request.args.action];
      if (!matched.needsUpdate) {
        if (
          (matched.data.constructor === Object &&
            Object.keys(matched.data).length) ||
          (matched.data.constructor === Array && matched.data.length)
        ) {
          return matched;
        }
      }
    }
  }

  return false;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * callApiRedux - api calls - response saved on redux state
 *
 * @param  {type} endpoint       a valid endpoint of the rest api ( e.g events )
 * @param  {type} method         the preferred method
 * @param  {type} parameters     extra parameters ( body and headers only )
 * @return {type}                Promise in the form of redux dispatch
 */
