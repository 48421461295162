import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { connect } from "react-redux";
import { addNotification, showModal } from "@actions";
import { HeaderPagination } from "../../../../layout/Pagination";
import { getInvoices, deleteInvoice } from "../../../../../actions/invoices";
import SingleInvoice from "./SingleInvoice";
import TableActions from "@layout/TableActions";
const columns = {
  id: {
    name: "ID",
  },
  name: {
    name: "Name",
  },
  profession: {
    name: "Profession",
  },
  street: {
    name: "Street",
  },
  streetNumber: {
    name: "Street Number",
  },
  city: {
    name: "City",
  },
  state: {
    name: "State",
  },
  postalCode: {
    name: "Postal Code",
  },
  vat: {
    name: "VAT",
  },
  taxOffice: {
    name: "Tax Office",
  },
  status: {
    name: "Status",
  },
  issuedAt: {
    name: "Issued At",
    type: "date",
  },

  actions: {
    name: "",
  },
};
const Invoices = (props) => {
  useEffect(() => {
    if (props.event) {
      props.getInvoices(props.event.id);
    }
  }, [props.event]);
  console.info(props.invoiceList);

  const onRender = (col, item) => {
    switch (col.key) {
      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() => props.showModal(<SingleInvoice item={item} />)}
            onDelete={() => props.deleteInvoice(props.eventId, item.id)}
          />
        );
      }
    }
  };
  return (
    <>
      <div className="tab-header">
        <div className="left"></div>
        <div className="right">
          <HeaderPagination
            fetching={props.fetching}
            p={props.invoiceListMeta.p}
            rpp={props.invoiceListMeta.rpp}
            totalRows={props.invoiceListMeta.totalRows}
            onChangePage={(data) => {
              const meta = {
                p: data.p,
                rpp: data.rpp,
              };
              props.getInvoices(props.eventId, meta);
            }}
          />
          <button
            type="button"
            className="btn create rounded"
            onClick={() => props.showModal(<SingleInvoice />)}
          >
            Create
          </button>
        </div>
      </div>
      <div className="tab-content">
        <Table
          id={"invoices"}
          items={props.invoiceList}
          columns={columns}
          loading={props.fetching}
          onRender={(col, item) => onRender(col, item, "subscriptions")}
          updating={props.fetching}
        />
      </div>
    </>
  );
};

Invoices.propTypes = {
  currency: PropTypes.string,
  addNotification: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  invoiceListMeta: PropTypes.object,
  eventId: PropTypes.number,
  showModal: PropTypes.func.isRequired,
  invoiceList: PropTypes.array,
  getInvoices: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
};

Invoices.defaultProps = {
  currency: "EUR",
  invoiceListMeta: {
    p: 1,
    rpp: 10,
    totalRows: 0,
  },
};
const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    invoiceList: state.api.events.invoices?.data,
    fetching: state.api.events.invoices.fetching,
    invoiceListMeta: state.api.events.invoices.meta,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );
    },
    addNotification: (msg, status) => dispatch(addNotification(msg, status)),
    getInvoices: (eventId) => dispatch(getInvoices(eventId)),
    deleteInvoice: (eventId, invoiceId) => {
      dispatch(deleteInvoice(eventId, invoiceId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
