import * as React from "react";
const SvgAddIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 5.334v5.333M5.333 8.001h5.333m4 0A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.333 0Z"
      stroke="#31ACFF"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAddIcon;
