import { api } from "@actions/ajax";
import { addNotification } from "@actions";

export const CREATE_TEMP_VARS = "CREATE_TEMP_VARS";

export const getPresetEmails = (orgId, eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "list",
      params: { orgId },
      query: `?eventId=${eventId}&rpp=-1`,
    })
  );

export const getPresetEmailScheduling = (orgId, presetEmailId) => {
  return (dispatch) => {
    return dispatch(
      api({
        endpoint: "presetEmails",
        action: "scheduling",
        params: { orgId, presetEmailId },
        query: `?rpp=-1`,
      })
    );
  };
};

export const deletePresetEmail = (orgId, presetEmailId) => {
  return (dispatch) => {
    return dispatch(
      api({
        endpoint: "presetEmails",
        action: "delete",
        params: { orgId, presetEmailId },
      })
    )
      .then(() => {
        return dispatch(
          addNotification("Preset email removed successfully", "success")
        );
      })
      .catch((err) => {
        console.warn(err);
        return err;
      });
  };
};

export const sendPresetEmail = (orgId, presetEmailId, eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "send",
      params: { orgId, presetEmailId },
      body: {
        data: {
          eventId,
        },
      },
    })
  );

export const createPresetEmail = (orgId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "create",
      params: { orgId },
      body: {
        data,
      },
    })
  ).then(() => dispatch(getPresetEmails(orgId, data.eventId)));
export const createCustomEmail = (eventId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "custom",
      params: { eventId },
      body: {
        data,
      },
    })
  );
export const createVars = (contacts, eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "contacts",
      params: { eventId },
      body: {
        data: {
          contacts: [contacts[1]],
        },
      },
    })
  );
export const createCustomVars = (contacts, eventId) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "customContacts",
      params: { eventId },
      body: {
        data: {
          contacts: [contacts[1]],
        },
      },
    })
  );

export const updatePresetEmail = (orgId, presetEmailId, data) => (dispatch) =>
  dispatch(
    api({
      endpoint: "presetEmails",
      action: "update",
      params: { orgId, presetEmailId },
      body: {
        data,
      },
    })
  );

export const updatePresetEmailPublicStatus =
  (orgId, presetEmailId, isPublic) => (dispatch) =>
    dispatch(
      api({
        endpoint: "presetEmails",
        action: "update",
        params: { orgId, presetEmailId },
        body: {
          data: {
            public: isPublic,
          },
        },
      })
    );
export const deleteContact = (key, mode) => (dispatch) => {
  dispatch({ type: "DELETE_CONTACT", key, mode });
};
export const editContact = (contact, eventId, key, mode) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      if (mode === "custom") {
        dispatch(
          api({
            endpoint: "presetEmails",
            action: "customContacts",
            params: { eventId },
            body: {
              data: {
                contacts: [contact],
              },
            },
          })
        ).then((response) => {
          dispatch({ type: "EDIT_CONTACT", response, key, mode });
        });
      } else {
        dispatch(
          api({
            endpoint: "presetEmails",
            action: "contacts",
            params: { eventId },
            body: {
              data: {
                contacts: [contact],
              },
            },
          })
        ).then((response) => {
          dispatch({ type: "EDIT_CONTACT", response, key, mode });
        });
      }
      resolve(true);
    });
  };
};

export const updateEmailSchedulings = (
  schedulingArr,
  orgId,
  presetEmailId,
  eventId
) => {
  return (dispatch) => {
    const query = {
      endpoint: "presetEmails",
      action: "updateScheduling",
      body: { data: { schedulingArr, eventId } },
      params: {
        orgId,
        presetEmailId,
      },
    };

    return dispatch(api(query))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.warn(err);
        return err;
      });
  };
};
