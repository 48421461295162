import React from "react";
import PropTypes from "prop-types";
import {
  loadSessionUsers,
  deleteSessionUser,
  createSessionUser,
} from "@actions/sessions";
import { addNotification } from "@actions";
import { getParticipants } from "@actions/users";
import { connect } from "react-redux";
import Search from "@layout/Search";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import KMBLoader from "@layout/KMBLoader";

class UserSessions extends React.Component {
  constructor(props) {
    super(props);
    ["onRender"].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.state = {
      file: null,
      userId: null,
      userName: null,
    };
    this.columns = {
      id: {
        name: "ID",
      },
      name: {
        name: "Name",
      },
      email: {
        name: "Email",
      },
      actions: {
        name: "",
      },
    };
  }

  componentDidMount() {
    this.props.loadSessionUsers(this.props.eventId, this.props.sessionId);
  }
  onRender(col, item) {
    switch (col.key) {
      case "id": {
        return item.id;
      }
      case "name": {
        return item.info.firstName + " " + item.info.lastName;
      }
      case "email": {
        return item.info.email;
      }
      case "actions": {
        return (
          <TableActions
            entity="user"
            item={item}
            onEdit={false}
            edit={false}
            onDelete={() =>
              this.props.deleteSessionUser(
                this.props.eventId,
                this.props.sessionId,
                item.id
              )
            }
          />
        );
      }
    }
  }
  debouncedCall(query) {
    this.setState(
      {
        userName: query,
      },
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.searchUsers(query, null, this.props.eventId);
        }, 400);
      }
    );
  }
  render() {
    return (
      <div className="sessions">
        <div className="form-container single-session">
          <h2>Users for session {this.props.sessionName}</h2>
          <label>Add a user in session {this.props.sessionName}</label>
          <div>
            <div
              className={`field-type type-text kmb-text col-sm-9 form-group`}
              onBlur={() => this.setState({ focused: false })}
              onFocus={() => this.setState({ focused: true })}
              style={{ padding: 0 }}
            >
              <Search
                onSearch={(queryString) => {
                  this.debouncedCall(queryString);
                }}
                visible={true}
                advancedSearch={true}
                value={this.state.userName}
              />
              <div>
                <div className="autocomplete" style={{ position: "absolute" }}>
                  {this.props.allPolicyUsers.length > 0 &&
                    this.state.focused &&
                    this.props.allPolicyUsers.slice(0, 4).map((item, index) => {
                      return (
                        <div
                          className="item"
                          key={index}
                          style={{ paddingTop: 3 }}
                          onMouseDown={() =>
                            this.setState({
                              userId: item.id,
                              userName: `${item.info.firstName} ${item.info.lastName}`,
                            })
                          }
                        >
                          {item.info.firstName} {item.info.lastName}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <button
                type="button"
                className="btn dark rounded"
                style={{ width: "100%", height: 40 }}
                onClick={() => {
                  if (!this.state.userId) {
                    this.props.addNotification(
                      "Try selecting a user first!",
                      "error"
                    );
                  } else {
                    return this.props
                      .createSessionUser(
                        this.props.eventId,
                        this.props.sessionId,
                        this.state.userId
                      )
                      .then(() =>
                        this.setState({ userName: null, userId: null })
                      );
                  }
                }}
              >
                <span className="icon-add-1"></span>Add User
              </button>
            </div>
          </div>
          <br />
          <br />
        </div>
        {this.props.fetching ? (
          <KMBLoader rows={15} padding={24} height={53} />
        ) : (
          <Table
            id={"usersSession"}
            columns={this.columns}
            items={this.props.sessionUsers}
            updating={false}
            onRender={this.onRender}
            containerClassName={`table-container `}
          />
        )}
      </div>
    );
  }
}

UserSessions.propTypes = {
  sessionId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  loadSessionUsers: PropTypes.func.isRequired,
  sessionUsers: PropTypes.array.isRequired,
  sessionName: PropTypes.string.isRequired,
  deleteSessionUser: PropTypes.func.isRequired,
  createSessionUser: PropTypes.func.isRequired,
  allPolicyUsers: PropTypes.array.isRequired,
  searchUsers: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    sessionUsers: state.users.sessionUsers.data,
    fetching: state.users.sessionUsers.fetching,

    allPolicyUsers: state.users.allPolicyUsers.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadSessionUsers: (eventId, sessionId, meta) => {
      dispatch(loadSessionUsers(eventId, sessionId, meta));
    },
    deleteSessionUser: (eventId, sessionId, userId) => {
      return dispatch(deleteSessionUser(eventId, sessionId, userId));
    },
    createSessionUser: (eventId, sessionId, userId) => {
      return dispatch(createSessionUser(eventId, sessionId, userId));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    searchUsers: (queryString, policyId, eventId) => {
      dispatch(
        getParticipants({
          eventId,
          policyId,
          query: { search: queryString, policyTypes: "private", info: true },
          action: "allUsersList",
        })
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserSessions);
