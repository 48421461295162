import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";
import { updateClientPanelSettings } from "@actions";
import Switch from "@layout/Switch";

const Abstracts = (props) => {
  const [viewAbstracts, setViewAbstracts] = React.useState(
    props.event.clientPanelSettings.abstracts.showPublishedAbstracts
  );

  return (
    <div className="panel-content-card footer-content abstract-panel">
      <div className="switch-holder">
        <Switch
          isActive={viewAbstracts}
          isLive={true}
          onChange={() => setViewAbstracts(!viewAbstracts)}
        />
        Show Published Abstracts
      </div>
      <SubmitContainer
        fetching={props.fetching}
        onSubmit={() => {
          props.updateClientPanelSettings(
            props.event.id,
            {
              abstractsShowPublishedAbstracts:
                !props.event.clientPanelSettings.abstracts
                  .showPublishedAbstracts,
            },
            "abstracts"
          );
        }}
        onCancel={() => {
          setViewAbstracts(
            props.event.clientPanelSettings.abstracts.showPublishedAbstracts
          );
        }}
        disabled={
          props.fetching ||
          viewAbstracts ===
            props.event.clientPanelSettings.abstracts.showPublishedAbstracts
        }
        cancelDisabled={
          viewAbstracts ===
          props.event.clientPanelSettings.abstracts.showPublishedAbstracts
        }
        submitText="Save Changes"
      />
    </div>
  );
};

Abstracts.propTypes = {
  event: PropTypes.object.isRequired,
  updateClientPanelSettings: PropTypes.func,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Abstracts);
