import React from "react";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import SinglePolicy from "./SinglePolicy";
import Switch from "@layout/Switch";
import PropTypes from "prop-types";

const AccessPolicies = (props) => {
  const onRender = (col, item) => {
    switch (col.key) {
      case "default": {
        return item.default ? "Yes" : "No";
      }
      case "active": {
        return (
          <Switch
            id={`${item.id}`}
            isActive={item.active}
            onChange={(active) =>
              props.savePolicy(
                item.eventId,
                { active: active ? 1 : 0 },
                item.id
              )
            }
          />
        );
      }
      case "type": {
        return (
          <div
            className="event-policies-dropdown"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              padding: "5px 10px 5px 5px",
              background: "#e5e8ea",
              width: 120,
              borderRadius: 20,
            }}
          >
            <div
              style={{
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: !item.saveUsersToCrm ? 10 : "5px 10px",
                background: "#b9c2c7",
                borderRadius: "100%",
              }}
            >
              <span
                className={
                  item.type == "public"
                    ? "icon-public-policy"
                    : "icon-private-policy"
                }
              ></span>
              {!!item.saveUsersToCrm && (
                <span style={{ fontSize: 7 }}>CRM</span>
              )}
            </div>
            <span
              title={item.name + (item.default ? " (Default)" : "")}
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {item.type.toUpperCase()}
            </span>
          </div>
        );
      }
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="ebag item"
              item={item}
              onDelete={() => {
                props.deleteItem(
                  { eventId: props.event.id, policyId: item.id },
                  "accesspolicies"
                );
              }}
              onEdit={() => {
                return props.showModal(
                  <SinglePolicy
                    hideModal={props.hideModal}
                    item={item}
                    onSave={props.savePolicy}
                    eventId={props.event.id}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <>
      <div className="tab-header">
        <div className="left"></div>
        <div className="right">
          <button
            type="button"
            className="btn create rounded"
            onClick={() =>
              props.showModal(
                <SinglePolicy
                  hideModal={props.hideModal}
                  item={{}}
                  onSave={props.savePolicy}
                  eventId={props.event.id}
                />
              )
            }
          >
            Create
          </button>
        </div>
      </div>
      <div className="tab-content">
        <Table
          columns={{
            id: {
              name: "ID",
            },
            name: {
              name: "Name",
            },
            type: {
              name: "Type",
            },
            default: {
              name: "Default",
            },
            active: {
              name: "Enable Client Panel",
            },
            actions: {
              name: "",
            },
          }}
          items={props.policies}
          containerClassName={`table-container `}
          onRender={onRender}
          updating={props.fetching}
        />
      </div>
    </>
  );
};
AccessPolicies.propTypes = {
  savePolicy: PropTypes.func,
  policies: PropTypes.array,
  fetching: PropTypes.bool,
  deleteItem: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  event: PropTypes.object,
};
export default AccessPolicies;
