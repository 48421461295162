import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";

const ExhibitionCategories = (props) => {
  const [state, setState] = React.useState({ categories: [] });

  const getCategories = () => {
    const categories = JSON.parse(JSON.stringify(props.categories));
    if (categories[categories.length - 1]?.name != "") {
      categories.push({ name: "" });
    }
    return categories;
  };
  const onRender = (col, item, trindex) => {
    switch (col.key) {
      case "name": {
        return (
          <input
            placeholder="Type a category..."
            value={item.name}
            onChange={(e) => onChangeCategory(e.target.value, trindex)}
          />
        );
      }
      case "exhibitors": {
        return (
          <React.Fragment>
            {props.exhibitors.map(
              (exhibitor, i) =>
                exhibitor.categories.includes(String(item.id)) && (
                  <img
                    title={exhibitor.name}
                    key={`exhibitor-img-${i}`}
                    src={
                      exhibitor.exhibitorThumbnail[0]?.url ||
                      "/images/medlive-noimage.jpg"
                    }
                    style={{
                      width: 40,
                      height: 40,
                      objectFit: "contain",
                      paddingLeft: 10,
                    }}
                  />
                )
            )}
          </React.Fragment>
        );
      }
      case "remove":
        if (trindex >= state.categories.length - 1) return;
        return (
          <div className="remove-item">
            <span
              onClick={() => props.onDelete(trindex)}
              className="fa fa-times-circle"
            ></span>
          </div>
        );
    }
  };
  React.useEffect(() => {
    const categories = getCategories();
    setState({ categories });
  }, [props.categories]);

  const onChangeCategory = (value, i) => {
    const newCategories = [...state.categories];
    newCategories[i].name = value;
    return props.onChange(newCategories);
  };

  return (
    <div className="category-container">
      <Table
        id={"exhibition-categories"}
        items={state.categories}
        columns={{
          name: {
            name: "Category",
          },
          exhibitors: {
            name: "Exhibitors",
          },
          remove: {
            name: "",
          },
        }}
        onRender={onRender}
        containerClassName={`table-container `}
      />
    </div>
  );
};

ExhibitionCategories.defaultProps = {
  categories: [],
};
ExhibitionCategories.propTypes = {
  exhibitors: PropTypes.array,
  categories: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ExhibitionCategories;
