/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Select from "@layout/Select";
import Select2 from "@layout/Select2";
import Confirm from "@layout/Confirm";
import Confirmer from "@layout/Confirmer";
class ConfirmDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wordConfirmed: false,
    };
  }
  render() {
    return (
      <Confirm
        onConfirm={() => {
          this.props.confirmCallback();
          this.props.setShowConfirm(false);
        }}
        body={
          <div>
            {this.props.confirmDeleteText}
            <Confirmer
              word="confirm delete"
              onSuccess={() => {
                this.setState({ wordConfirmed: true });
              }}
              onFail={() => {
                if (this.state.wordConfirmed) {
                  this.setState({ wordConfirmed: false });
                }
              }}
            />
          </div>
        }
        title="Delete Action! Are you sure?"
        className="delete-event"
        confirmText={`Confirm Delete`}
        disabled={!this.state.wordConfirmed}
        visible={true}
        word="confirm delete"
        onSuccess={() => {
          this.setState({ wordConfirmed: true });
        }}
        onFail={() => {
          if (this.state.wordConfirmed) {
            this.setState({ wordConfirmed: false });
          }
        }}
        onClose={() => {
          this.props.setShowConfirm(false);
        }}
      ></Confirm>
    );
  }
}
export class SelectEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  getValue() {
    return { value: this.state.value };
  }
  getInputNode() {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  render() {
    return (
      <Select
        options={this.props.options || {}}
        onChange={(value) =>
          this.setState({ value: value }, () => this.props.onCommit())
        }
      />
    );
  }
}

export class Select2Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  getValue() {
    return { value: this.state.value };
  }
  getInputNode() {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  render() {
    const values = [];
    this.props.value &&
      typeof this.props.value === "string" &&
      this.props.value.split(",").map((entry) => {
        Object.keys(this.props.options).map((key) => {
          if (this.props.options[key] === entry) {
            values.push(key.toString());
          }
        });
      });

    return (
      <Select2
        options={this.props.options || {}}
        onChange={(value) =>
          this.setState({ value: value }, () => this.props.onCommit())
        }
        multi={this.props.multi}
        value={values}
      />
    );
  }
}
export class ExcelActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.setShowConfirm = this.setShowConfirm.bind(this);
  }
  setShowConfirm(bool) {
    this.setState({ showConfirm: bool });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.showConfirm && (
          <ConfirmDelete
            confirmDeleteText={this.props.confirmDeleteText(
              this.props.value.row.id,
              this.props.value.row.name
            )}
            confirmCallback={() => this.props.onDelete(this.props.value.row.id)}
            setShowConfirm={this.setShowConfirm}
          />
        )}

        <div style={{ textAlign: "center" }}>
          {this.props.onDelete && !this.props.onSave ? (
            <span
              className="icon-delete"
              style={{ cursor: "pointer", padding: 5 }}
              onClick={() => this.setShowConfirm(true)}
            />
          ) : null}
          {this.props.onEnter && !this.props.onSave ? (
            <span
              onClick={() => this.props.onEnter(this.props.value.row.id)}
              className="icon-arrow-right-small"
              style={{ cursor: "pointer", padding: 5 }}
            />
          ) : null}
          {this.props.onSave ? (
            <span
              onClick={() => this.props.onSave(this.props.value.row)}
              className="fa fa-save"
              style={{ cursor: "pointer", padding: 5 }}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
SelectEditor.defaultProps = {
  onCommit: () => {},
};
SelectEditor.propTypes = {
  options: PropTypes.any.isRequired,
  onCommit: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
Select2Editor.propTypes = {
  options: PropTypes.any.isRequired,
  onCommit: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  multi: PropTypes.bool,
};
ExcelActions.propTypes = {
  options: PropTypes.any.isRequired,
  onCommit: PropTypes.func.isRequired,
  value: PropTypes.any,
  onDelete: PropTypes.func,
  onEnter: PropTypes.func,
  onSave: PropTypes.func,
};
