import React from "react";
import Search from "@layout/Search";
import { HeaderPagination } from "@layout/Pagination";
import Table from "@layout/Table";
import moment from "moment";
import Speeches from "./partials/Speeches";
import { insert, remove, isEmpty } from "@helpers";
import TableActions from "@layout/TableActions";
import SingleSession from "./partials/SingleSession/";
import PropTypes from "prop-types";
import Switch from "@layout/Switch";
import BulkImportSessions from "./partials/BulkImportSessions";
import UserSessions from "./partials/UserSessions";
import QuickChange from "@layout/QuickChange";
import ApiHelper from "@helpers/api-helper";
import KMBExcel from "@layout/KMBExcel";
import Select from "@layout/Select2";
import Breadcrumbs from "@layout/Breadcrumbs";

export default class Sessions extends React.Component {
  constructor(props) {
    super(props);
    [
      "onRender",
      "onSort",
      "toggleSession",
      "onRenderSpeech",
      "reToggle",
      "onQuickChange",
      "onSaveRow",
      "onSaveSpeech",
      "enterSession",
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));

    this.columns = {
      cosmetic: {
        name: "",
      },
      name: {
        name: "Name",
        sortable: true,
      },
      room: {
        name: "Room",
        sortable: true,
      },
      chairperson: {
        name: "Chairperson",
      },
      startDate: {
        name: "Start Date",
        sortable: true,
      },
      endDate: {
        name: "End Date",
        sortable: true,
      },
      status: {
        name: "Status",
      },

      usersPerSession: {
        name: "Reserved Seats",
      },
      actions: {
        name: "",
      },
    };

    this.state = {
      search: "",
      orderBy: null,
      toggledCells: [],
      sessions: props.sessions,
      usersLoaded: false,
      view: "list",
      type: "schedule",
      items: [],
      sessionId: null,
    };
  }

  componentDidMount() {
    this.props.loadSessions(this.props.eventId, {
      p: 1,
      rpp: 20,
    });
    this.props.loadRooms(this.props.eventId);
    this.props.loadPolicies(this.props.eventId);
    // todo: remove this and add the logic type and search like speakers
    this.props.loadPoliciesCrmUsers(this.props.eventId).then(() => {
      this.setState({ usersLoaded: true });
    });
    this.props.getAbstracts();
  }

  toggleSession(session, trIndex) {
    const toggledCells = [...this.state.toggledCells];
    const index = toggledCells.indexOf(session.id);
    let sessions = this.state.sessions;
    if (index !== -1) {
      toggledCells.splice(index, 1);
      sessions = remove(sessions, trIndex + 1);
    } else {
      toggledCells.push(session.id);
      sessions = insert(this.state.sessions, trIndex + 1, {
        colSpan: Object.keys(this.columns).length,
        session,
      });
    }

    this.setState({ toggledCells, sessions });
  }

  onRenderSpeech(item, trIndex) {
    return (
      <td key={`sessions-${trIndex}-colspan`} colSpan={item.colSpan}>
        <Speeches
          showModal={this.props.showModal}
          {...item}
          deleteItem={this.props.deleteItem}
          eventId={this.props.eventId}
          saveSpeech={this.props.saveSpeech}
          meta={{
            search: this.state.search,
            orderBy: this.state.orderBy ?? "eventRoomId",
          }}
        />
      </td>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.sessions.map((session) => {
      if (session.id === this.state.sessionId) {
        this.setState({ items: session.speeches });
      }
    });
    this.setState({ sessions: nextProps.sessions }, () => {
      this.reToggle();
    });
  }
  onSort(orderBy) {
    this.props.loadSessions(this.props.eventId, {
      orderBy,
    });
    this.setState({ orderBy: orderBy });
  }
  reToggle() {
    if (this.state.toggledCells.length === 0) return;

    let sessions = this.state.sessions;
    let internalCount = 0;

    this.state.sessions.map((session, trIndex) => {
      if (this.state.toggledCells.includes(session.id)) {
        sessions = insert(sessions, trIndex + internalCount + 1, {
          colSpan: Object.keys(this.columns).length,
          session,
        });
        internalCount++;
      }
    });

    this.setState({ sessions });
  }

  onQuickChange(originalDate, event, item, key) {
    const additionalKey = key === "startDate" ? "endDate" : "startDate";
    const { mode, minutes } = event;
    const m = moment(originalDate);
    const newDate = m[mode]("minutes", minutes).format(ApiHelper.dateFormat);
    this.props.saveSession(
      this.props.eventId,
      {
        [key]: newDate,
        [additionalKey]: moment(item[additionalKey].tz).format(
          ApiHelper.dateFormat
        ),
      },
      item.id
    );
  }

  onRender(col, item, trIndex) {
    switch (col.key) {
      case "chairperson": {
        let chairpersons = "";
        if (item.chairpersons.length > 0) {
          item.chairpersons.forEach((person, i) => {
            const name = person.firstName + " " + person.lastName;
            chairpersons = chairpersons + name;
            if (i + 1 < item.chairpersons.length) {
              chairpersons = chairpersons + ",";
            }
          });
        }
        return chairpersons;
      }
      case "cosmetic": {
        return (
          <span>
            <span
              className={`collapse-session ${
                this.state.toggledCells.includes(item.id)
                  ? "icon-mixxed collapsed"
                  : "icon-plus-calendar-date"
              }`}
              onClick={() => this.toggleSession(item, trIndex)}
            ></span>
          </span>
        );
      }
      case "usersPerSession": {
        return (
          <div>
            <button
              type="button"
              className={`btn transparent table-button`}
              style={{ paddingLeft: 17 }}
              onClick={() => {
                this.props.showModal(
                  <UserSessions sessionId={item.id} sessionName={item.name} />,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true
                );
              }}
            >
              {item.usersPerSession}
            </button>
          </div>
        );
      }

      case "room": {
        return col.value.name;
      }

      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SingleSession
                  meta={{
                    search: this.state.search,
                    orderBy: this.state.orderBy ?? "eventRoomId",
                  }}
                  sessionId={item.id}
                />
              )
            }
            onDelete={() => this.props.deleteItem(this.props.eventId, item.id)}
          />
        );
      }

      case "active": {
        return (
          <Switch
            id={`session-active-${item.id}`}
            isActive={col.value}
            onChange={(active) =>
              this.props.saveSession(
                this.props.eventId,
                { active: active ? 1 : 0 },
                item.id
              )
            }
          />
        );
      }

      case "videoFile":
      case "liveStream": {
        return isEmpty(col.value) ? "No" : "Yes";
      }

      case "sessionFiles": {
        return col.value.length ? "Yes" : "No";
      }

      case "startDate":
      case "endDate": {
        return (
          <div>
            <p>{moment.utc(col.value.tz).format("DD MMMM Y")}</p>
            <span className="hour">
              {moment.utc(col.value.tz).format("HH:mm")}
            </span>
            <QuickChange
              onChange={(event) =>
                this.onQuickChange(col.value.tz, event, item, col.key)
              }
            />
          </div>
        );
      }
    }
  }
  dataParser(params) {
    if (params.cellKey === "room") {
      return { eventRoomId: params.updated.value };
    } else if (params.cellKey === "chairpersons") {
      return { eventUsersIds: params.updated.value };
    } else if (params.cellKey === "speakers") {
      return { eventUsersIds: params.updated.value };
    } else {
      return params.updated;
    }
  }
  onSaveRow(row) {
    const data = {};
    const allUsers = {};
    const userIdsArray = [];
    if (this.state.type === "schedule") {
      this.props.allPolicyUsers.map((au) => {
        allUsers[`${au.info.firstName} ${au.info.lastName}`] = au.id;
      });

      data["endDate"] = row.endDate;
      data["startDate"] = row.startDate;
      data["name"] = row.name;
      data["description"] = row.description;
      data["eventRoomId"] = row.room;
      data["eventUsersIds"] = row.chairpersons;
      data["temp"] = row.id.toString();

      data["selfHostingVideo"] = row.selfHostingVideo || 0;
      data["videoThumbnail"] = row.videoThumbnail || "";
      data["videoUrl"] = row.videoUrl || "";
      data["urlStream"] = row.urlStream || "";
      data["thirdPartyHosting"] = row.thirdPartyHosting || 0;
      data["srcUrl"] = row.srcUrl || "";
      this.props.rooms.map((room) => {
        if (room.name === data.eventRoomId) {
          data.eventRoomId = room.id;
        }
      });
      data["eventUsersIds"].split(",").map((entry) => {
        if (allUsers[entry]) {
          userIdsArray.push(allUsers[entry]);
        }
      });
      data["eventUsersIds"] = userIdsArray;
      this.props.saveSession(this.props.eventId, data, false);
    }
  }
  onSaveSpeech(row) {
    const data = {};
    const allUsers = {};
    const userIdsArray = [];
    if (this.state.type === "speech") {
      this.props.allPolicyUsers.map((au) => {
        allUsers[`${au.info.firstName} ${au.info.lastName}`] = au.id;
      });
      data["endDate"] = row.endDate;
      data["startDate"] = row.startDate;
      data["name"] = row.name;
      data["eventUsersIds"] = row.speakers;
      data["videoThumbnail"] = row.videoThumbnail || "";
      data["videoUrl"] = row.videoUrl || "";
      data["temp"] = row.id.toString();

      if (data["eventUsersIds"]) {
        data["eventUsersIds"].split(",").map((entry) => {
          if (allUsers[entry]) {
            userIdsArray.push(allUsers[entry]);
          }
        });
        data["eventUsersIds"] = userIdsArray;
      } else {
        data["eventUsersIds"] = [];
      }
      this.props.saveSpeech(this.props.eventId, this.state.sessionId, data);
    }
  }
  enterSession(id) {
    this.setState({ type: "speech" });
    this.state.sessions.map((session) => {
      if (session.id === id) {
        this.setState({ items: session.speeches, sessionId: id });
      }
    });
  }
  render() {
    const parsedRooms = {};
    this.props.rooms.map((room) => {
      parsedRooms[room.id] = room.name;
    });
    const allUsers = {};
    this.props.allPolicyUsers.map((au) => {
      allUsers[au.id] = `${au.info.firstName} ${au.info.lastName}`;
    });

    const listViewIcon = (
      <span
        style={{ fontSize: 12 }}
        key="grid-view"
        className="icon-list-view"
      ></span>
    );
    const gridViewIcon = (
      <span
        style={{ fontSize: 12 }}
        key="grid-view"
        className="icon-grid-view"
      ></span>
    );
    const listViewSpan = (
      <span key="list-span">
        {listViewIcon}
        <span style={{ paddingLeft: 10 }}>List View</span>
      </span>
    );
    const gridViewSpan = (
      <span key="grid-span">
        {gridViewIcon}
        <span style={{ paddingLeft: 10 }}>Grid View</span>
      </span>
    );
    return (
      <>
        <div className="tab-header">
          <div className="left">
            <Select
              hoverBackgroundColor="#f0f0f4"
              color="var(--tab-header-menu)"
              placeholder={[
                <div
                  key="placeholder-select-group"
                  className="select-button-placeholder"
                >
                  <span className="icon-more-options"></span>
                </div>,
              ]}
              isSearchable={false}
              className="more-actions select-button"
              isDropDownButton={true}
              isClearable={false}
              placeholderInsideSelect={false}
              options={{
                importSessions: (
                  <span>
                    <span
                      style={{ paddingRight: 10 }}
                      className="icon-upload"
                    ></span>
                    Import Sessions
                  </span>
                ),
              }}
              onChange={(action) => {
                if (action === "importSessions") {
                  return this.props.showModal(<BulkImportSessions />);
                }
              }}
            />
            <div title={"Select view option"}>
              <Select
                hoverBackgroundColor="#f0f0f4"
                color="var(--tab-header-menu)"
                isClearable={false}
                isSearchable={false}
                isDropDownButton={true}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                placeholder={[
                  this.state.view == "grid" ? gridViewIcon : listViewIcon,
                ]}
                className={"select-button"}
                options={{ list: listViewSpan, grid: gridViewSpan }}
                hasIndicator={false}
                placeholderInsideSelect={false}
                onChange={(value) => {
                  const queryParameters = {
                    p: 1,
                    search: this.state.search, // always send the current search query
                  };

                  if (value === "grid") {
                    queryParameters.rpp = -1;
                  } else {
                    queryParameters.rpp = 20;
                  }

                  this.props.loadSessions(this.props.eventId, queryParameters);
                  this.setState({ view: value });
                }}
                value={this.state.view}
              />
            </div>

            <Search
              onSearch={(queryString) => {
                this.setState({ search: queryString });
                this.props.loadSessions(this.props.event.id, {
                  p: 1,
                  search: queryString,
                });
              }}
              visible={true}
              timeout={400}
            />
          </div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={this.props.meta.p}
              rpp={this.props.meta.rpp}
              totalRows={this.props.meta.totalRows}
              onChangePage={({ p, rpp }) =>
                this.props.loadSessions(this.props.eventId, {
                  p,
                  rpp,
                  search: this.state.search,
                })
              }
            />
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleSession
                    meta={{
                      search: this.state.search,
                      orderBy: this.state.orderBy ?? "eventRoomId",
                    }}
                  />
                )
              }
            >
              Create
            </button>
          </div>
        </div>
        <div
          className="tab-content .tab-sessions"
          style={this.state.view === "grid" ? { overflow: "hidden" } : {}}
        >
          {this.state.view === "list" ? (
            <Table
              updating={this.props.fetching}
              id={"sessions"}
              columns={this.columns}
              items={this.state.sessions}
              filtered={this.state.search}
              onSort={this.onSort}
              onRender={this.onRender}
              loadingRowsHeight={104}
              onRenderColSpan={this.onRenderSpeech}
              containerClassName={`table-container `}
            />
          ) : (
            <KMBExcel
              event={this.props.event}
              meta={this.props.meta}
              confirmDeleteText={(id, name) => (
                <div>
                  <p>
                    This action will permanently delete the{" "}
                    {this.state.type === "speech" ? "Speech" : "Session"} with
                    ID: <strong>{id}</strong>
                  </p>
                  <p>
                    And name: <span>{name}</span>
                  </p>
                  <p>
                    Are you sure that you want to delete this{" "}
                    {this.state.type === "speech" ? "Speech" : "Session"}?
                  </p>
                  <p>If yes, then please type: CONFIRM DELETE</p>
                </div>
              )}
              toolbarTop={{
                fullscreen: true,
                custom: {
                  back: (() =>
                    this.state.type === "speech" ? (
                      <Breadcrumbs
                        style={{ margin: 0 }}
                        options={[
                          {
                            name: "Back to Sessions",
                            action: () =>
                              this.setState({
                                type: "schedule",
                                sessionId: null,
                              }),
                          },
                          {
                            name: this.state.sessions.filter(
                              (session) => session.id == this.state.sessionId
                            )[0].name,
                          },
                        ]}
                      />
                    ) : null)(),
                },
              }}
              options={{
                room: parsedRooms,
                chairpersons: allUsers,
                speakers: allUsers,
              }}
              type={this.state.type}
              items={
                this.state.type === "speech"
                  ? this.state.items
                  : this.state.sessions
              }
              onChange={
                this.state.type === "schedule"
                  ? (from) => {
                      this.props.saveSession(
                        this.props.eventId,
                        this.dataParser(from),
                        from.toRowId
                      );
                    }
                  : (from) => {
                      this.props.saveSpeech(
                        this.props.eventId,
                        this.state.sessionId,
                        this.dataParser(from),
                        from.toRowId
                      );
                    }
              }
              eventId={this.props.eventId}
              onSaveRow={
                this.state.type === "schedule"
                  ? this.onSaveRow
                  : this.onSaveSpeech
              }
              onDeleteRow={
                this.state.type === "schedule"
                  ? (sessionId) =>
                      this.props.deleteItem(this.props.eventId, sessionId)
                  : (speechId) =>
                      this.props.deleteItem(
                        this.props.eventId,
                        this.state.sessionId,
                        speechId
                      )
              }
              onEnterRow={
                this.state.type === "schedule"
                  ? (sessionId) => this.enterSession(sessionId)
                  : false
              }
            />
          )}
        </div>
      </>
    );
  }
}

Sessions.propTypes = {
  sessions: PropTypes.array.isRequired,
  loadSessions: PropTypes.func.isRequired,
  loadPoliciesCrmUsers: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  undoModal: PropTypes.func,
  event: PropTypes.object.isRequired,
  searchUsers: PropTypes.func,
  policy: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  saveSession: PropTypes.func.isRequired,
  saveSpeech: PropTypes.func.isRequired,
  publishSchedule: PropTypes.number,
  updateEvent: PropTypes.func.isRequired,
  publishVideos: PropTypes.number,
  getAbstracts: PropTypes.func,
  rooms: PropTypes.array.isRequired,
  loadPolicies: PropTypes.func.isRequired,
  loadRooms: PropTypes.func.isRequired,
  allPolicyUsers: PropTypes.array.isRequired,
};
