import React from "react";
import Table from "@layout/Table";
import Select from "@layout/Select2";
import TagsInput from "@layout/TagsInput";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
const InformationFields = (props) => {
  const symbols = {
    text: (
      <div className="symbol">
        <span>T</span>
      </div>
    ),
    textarea: (
      <div className="symbol">
        <span>Ta</span>
      </div>
    ),
    dropdown: (
      <div className="symbol">
        <i className="fas fa-caret-down"></i>
      </div>
    ),
    email: (
      <div className="symbol">
        <i className="fas fa-at"></i>
      </div>
    ),
    yes_no: (
      <div className="symbol">
        <i className="far fa-check-circle"></i>
      </div>
    ),
    password: (
      <div className="symbol">
        <i className="fas fa-key"></i>
      </div>
    ),
    section: (
      <div className="symbol">
        <i className="fas fa-puzzle-piece"></i>
      </div>
    ),
  };

  const columns = {
    type: {
      name: "",
    },
    name: {
      name: "Field Name",
    },
    value: {
      name: "Options",
    },
    remove: {
      name: "",
    },
  };

  const addExtraField = (type) => {
    const newItems = [...props.items];
    const newField = {
      name: "",
      key: uuidv4(),
      value: type === "dropdown" ? [] : "",
      type,
    };

    newItems.push(newField);
    props.onChange(newItems);
  };

  const removeExtraField = (index) => {
    const newItems = [...props.items];
    newItems.filter((item) => !item.deleted && !item.hidden)[
      index
    ].deleted = true;
    props.onChange(newItems);
  };

  const updateItem = (itemKey, colKey, value) => {
    const newItems = [...props.items];
    const theItem = newItems.filter(
      (singleItem) => singleItem.key === itemKey
    )[0];
    theItem[colKey] = value;
    delete theItem.error;
    props.onChange(newItems);
  };

  const disabled = [
    "firstName",
    "lastName",
    "email",
    "speaker",
    "crmProfilePhoto",
  ];

  const onRender = (col, item, index) => {
    const isSection = item.type === "section";
    switch (col.key) {
      case "active": {
        return <span></span>;
      }
      case "name": {
        if (disabled.includes(item.key)) {
          return <div className="kmb-flex-center">{item.name}</div>;
        }
        return (
          <input
            className={`form-control${item.error ? " error" : ""}${
              isSection ? " bold" : ""
            }`}
            disabled={disabled.includes(item.key)}
            placeholder="Type..."
            value={item.name}
            onChange={(e) => updateItem(item.key, col.key, e.target.value)}
          />
        );
      }
      case "type": {
        if (isSection) return <span className="hidden"></span>;

        return <div className="kmb-flex-center">{symbols[item.type]}</div>;
      }

      case "value": {
        if (isSection) return <span className="hidden"></span>;

        return (
          <div className={`kmb-flex-center kmb-pointer`} onClick={() => {}}>
            {item.type == "dropdown" && (
              <TagsInput
                tags={item.value}
                onChange={(value) => updateItem(item.key, col.key, value)}
              />
            )}
          </div>
        );
      }
      case "remove": {
        if (disabled.includes(item.key) || item.pricing) return;

        return (
          <div className="remove" onClick={() => removeExtraField(index)}>
            <i className="icon-delete"></i>
          </div>
        );
      }
    }
  };

  return (
    <div className="information-fields crm">
      <div className="field-wrapper">
        <Table
          trClassNameFunc={(item) => {
            let className = "information-row ";
            if (item.type === "section") {
              className += "section-row";
            }
            return className;
          }}
          sortable={true}
          onSortEnd={(newIndex, item, newItems) => {
            const newSortList = newItems.map((sorted, index) => ({
              index,
              ...sorted,
            }));
            props.onChange(newSortList);
          }}
          id={"information-fields"}
          updating={false}
          columns={columns}
          items={props.items
            .filter((item) => {
              return !item.deleted && !item.hidden;
            })
            .map((item, index) => {
              const _static = ["firstName", "lastName", "email", "password"];
              if (_static.includes(item.key)) return item;
              return { id: index, ...item };
            })}
          onRender={onRender}
        />
      </div>
      <div className="add-fields">
        <Select
          menuPlacement="top"
          isSearchable={false}
          isClearable={false}
          color={"#2a79d1"}
          hoverColor={"#2a79d1"}
          className="select-button"
          options={{
            text: (
              <span className="symbol-wrapper">
                {symbols["text"]}Text Field
              </span>
            ),
            textarea: (
              <span className="symbol-wrapper">
                {symbols["textarea"]}Text Area
              </span>
            ),
            yes_no: (
              <span className="symbol-wrapper">{symbols["yes_no"]}Yes/No</span>
            ),
            dropdown: (
              <span className="symbol-wrapper">
                {symbols["dropdown"]}Dropdown
              </span>
            ),
          }}
          placeholder={[
            <div
              key="placeholder-add-field"
              className="select-button-placeholder"
            >
              <span className="icon-add-1"></span>
              <span>Add New Field</span>
            </div>,
          ]}
          hasIndicator={false}
          placeholderInsideSelect={false}
          isDropDownButton={true}
          onChange={(type) => addExtraField(type)}
        />
      </div>
    </div>
  );
};
InformationFields.defaultProps = {
  policy: {},
};
InformationFields.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  policy: PropTypes.object,
};
export default InformationFields;
