import { connect } from "react-redux";
import MinuteScanning from "./MinuteScanning";
import { checkUserMinutes, clearUserMinutes } from "@actions/users";
import { loadEvent } from "@actions";

let timeout = null;

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.eventId,
    user: state.rooms.minutesChecking.user,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => {
      dispatch(loadEvent(eventId));
    },

    checkUserMinutes: (eventId, userId) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      dispatch(checkUserMinutes(eventId, userId))
        .then(() => {
          timeout = setTimeout(() => dispatch(clearUserMinutes()), 3000);
        })
        .catch(() => {
          dispatch(clearUserMinutes());
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MinuteScanning);
