import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import KeynoteSpeakers from "./partials/KeynoteSpeakers";

const Videos = () => {
  const tabs = [{ key: "keynoteSpeakers", label: "Keynote Speakers" }];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <React.Fragment>
      <div className="top-menu">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </div>
      {tabs[activeTab].key === "keynoteSpeakers" && <KeynoteSpeakers />}
    </React.Fragment>
  );
};

Videos.propTypes = {};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

export default connect(mapStateToProps, {})(Videos);
