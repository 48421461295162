/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MatPagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import Select from "@layout/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0px 50px 0px ",
    "& *": {
      fontSize: 14,
    },
  },
}));

const Pagination = (props) => {
  const classes = useStyles();
  const { p, rpp, totalRows, onChangePage, rppSelect } = props;
  const pages = Math.ceil(totalRows / rpp);
  const selection = generateRppSelect(rpp, totalRows, onChangePage);
  const hasSelection = selection !== null;
  return (
    <div
      className={classes.root}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div style={{ width: hasSelection ? 155 : 0 }}>
        {hasSelection && selection}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.type === "header" ? (
          <div className="header-pagination">
            {(p - 1) * rpp + 1} - {(p - 1) * rpp + rpp} of {totalRows}
            <span className="fa fa-angle-left"></span>
            <span className="fa fa-angle-right"></span>
          </div>
        ) : (
          <MatPagination
            defaultPage={6}
            showFirstButton
            showLastButton
            count={pages <= 0 ? 1 : pages}
            color="primary"
            onChange={(e, v) => {
              onChangePage({ p: v });
            }}
            page={p || 1}
          />
        )}
      </div>
    </div>
  );
};

const HeaderPagination = (props) => {
  const [open, setOpen] = React.useState(false);
  const { p, rpp, totalRows, onChangePage } = props;
  const startRow = (p - 1) * rpp + 1;
  let endRow = (p - 1) * rpp + rpp;
  if (endRow > totalRows) {
    endRow = totalRows;
  }

  const details = startRow
    ? `${totalRows ? startRow : 0} - ${endRow} of ${totalRows}`
    : "Loading...";
  const onChange = (newPage) => {
    if (props.fetching) return;
    onChangePage({ p: newPage, rpp });
  };
  const onClickDetails = (newOpen) => setOpen(newOpen);
  return (
    <div className="header-pagination">
      {rpp === -1 && totalRows != 0 ? (
        "Viewing All"
      ) : (
        <button
          className="btn tab-header-btn details"
          onClick={() => onClickDetails(!open)}
          onBlur={() => onClickDetails(false)}
        >
          {details}
          <div className={`options ${!open ? "hidden" : ""}`}>
            <div onClick={() => onChangePage({ p: 1, rpp })}>First Page</div>
            {rpp !== 20 && (
              <div onClick={() => onChangePage({ p: 1, rpp: 20 })}>20 rows</div>
            )}

            {totalRows >= 50 && rpp !== 50 && (
              <div onClick={() => onChangePage({ p: 1, rpp: 50 })}>50 rows</div>
            )}
            {totalRows >= 100 && rpp !== 100 && (
              <div onClick={() => onChangePage({ p: 1, rpp: 100 })}>
                100 rows
              </div>
            )}
            {((totalRows > 10 && totalRows < 20) ||
              (totalRows > 20 && totalRows < 50) ||
              (totalRows > 50 && totalRows < 100)) &&
              rpp !== totalRows && (
                <div onClick={() => onChangePage({ p: 1, rpp: totalRows })}>
                  {totalRows} rows
                </div>
              )}
          </div>
        </button>
      )}

      <div
        className={`arrow ${
          startRow === 1 || !startRow || rpp === -1 ? " disabled" : ""
        }`}
        onClick={() => onChange(p - 1)}
      >
        <span className="fa fa-angle-left" style={{ left: -1 }}></span>
      </div>
      <div
        className={`arrow ${
          endRow === totalRows || rpp === -1 ? " disabled" : ""
        }`}
        onClick={() => onChange(p + 1)}
      >
        <span className="fa fa-angle-right" style={{ left: 1 }}></span>
      </div>
    </div>
  );
};

const generateMarkup = (i, p, onChangePage) => (
  <li key={`page-${i}`} className={`page${i === p ? " current-page" : ""}`}>
    <button onClick={() => onChangePage({ p: i })}>{i}</button>
  </li>
);

const generateNavigational = (direction, i, onChangePage) => (
  <li key={`${direction}-page`} className={`${direction}-page`}>
    <button onClick={() => onChangePage({ p: i })}>
      {direction.charAt(0).toUpperCase() + direction.slice(1)}
    </button>
  </li>
);

const generateRppSelect = (rpp, totalRows, onChangePage) => {
  //if not provided initialize
  if (!totalRows && !rpp) {
    totalRows = 5;
    rpp = 10;
  }
  if (totalRows < rpp) return null;

  const limit = 100;
  const options = {};
  if (totalRows >= 10) {
    options[10] = "10 results";
  }
  if (totalRows >= 20) {
    options[20] = "20 results";
  }
  if (totalRows >= 50) {
    options[50] = "50 results";
  }
  if (totalRows >= 100) {
    options[100] = "100 results";
  }
  if (totalRows < 10 || totalRows < 20 || totalRows < 50 || totalRows < 100) {
    options[totalRows] = `${totalRows} results`;
  }
  return (
    <Select
      style={{ maxWidth: 155, width: 155 }}
      options={options}
      placeholder={"Rows per page"}
      onChange={(rpp) => onChangePage({ rpp })}
      placeholderInsideSelect={false}
      value={rpp}
    />
  );
};

Pagination.defaultProps = {
  rppSelect: true,
};

Pagination.propTypes = {
  p: PropTypes.number,
  rpp: PropTypes.number,
  totalRows: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRpp: PropTypes.func,
  rppSelect: PropTypes.bool,
};

export { Pagination, HeaderPagination };
