import * as React from "react";
const SvgPrivatePolicyCrm = (props) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#private_policy_crm_svg__a)">
      <path
        d="M13.979 9.47V3.437L8.043 1.211 2.106 3.437v5.194c0 1.563.732 2.943 1.68 4.076"
        stroke="#005A97"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.526 6.762c0-.319.259-.577.578-.577h3.411a.577.577 0 0 1 0 1.154H8.104a.577.577 0 0 1-.578-.577Z"
        fill="#27303E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.345 6.212c.319 0 .577.258.577.577v1.294a.577.577 0 1 1-1.155 0V6.789c0-.319.259-.577.578-.577ZM6.303 5.612a1.155 1.155 0 1 0 0 2.31 1.155 1.155 0 0 0 0-2.31Zm-2.31 1.155a2.31 2.31 0 1 1 4.62 0 2.31 2.31 0 0 1-4.62 0Z"
        fill="#27303E"
      />
      <path
        d="M8.546 14.7c-.021.562-.153.988-.397 1.277-.243.289-.587.434-1.03.434-.467 0-.825-.183-1.074-.55-.247-.368-.371-.892-.371-1.571v-.83c0-.678.128-1.2.385-1.566.257-.368.614-.552 1.07-.552.45 0 .79.15 1.023.45.234.3.367.732.4 1.295h-.836c-.008-.348-.053-.588-.136-.719-.081-.133-.231-.2-.45-.2-.223 0-.38.094-.473.282-.093.185-.142.491-.148.918v.932c0 .49.046.826.136 1.01.093.182.25.274.473.274.22 0 .37-.063.453-.19.083-.129.13-.36.142-.694h.833ZM10.307 14.54h-.413v1.803H9.06V11.41h1.328c.418 0 .74.13.966.39.229.257.343.624.343 1.1 0 .655-.2 1.114-.598 1.376l.723 2.02v.047h-.896l-.62-1.803Zm-.413-.83h.473c.166 0 .29-.065.374-.196.083-.134.124-.31.124-.532 0-.495-.161-.742-.484-.742h-.487v1.47ZM13.358 11.41l.778 3.567.776-3.567H16v4.933h-.835v-1.335l.076-2.057-.824 3.392h-.567l-.824-3.392.077 2.057v1.335h-.833V11.41h1.088Z"
        fill="#27303E"
      />
    </g>
    <defs>
      <clipPath id="private_policy_crm_svg__a">
        <path fill="#fff" transform="translate(0 .41)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPrivatePolicyCrm;
