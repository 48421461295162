/* eslint-disable */
import {
  RECEIVED_POLICYGROUPS_POLICYLIST,
  LOAD_USERS,
  SETUP_USERS,
  SETUP_POLICY,
  CHANGE_FILTERS,
  SETUP_ROOMS,
  REQUEST_USERS_LIST,
  TOGGLE_HIT,
  CHANGE_ORDER_BY,
  REFRESH_MINUTES,
  ADVANCED_SEARCH_ACTIVE,
  SET_USERS_GROUP_ID,
  SHOW_USER_EVENTS,
  HIDE_USER_EVENTS,
  REORDER_FILTERS,
  RECEIVED_ADVANCED_LIST,
  REQUEST_ADVANCED_LIST,
  SELECT_USER,
  USER_EDIT_MODE,
  REQUEST_USERS_ALLUSERSLIST,
} from "@actions/users";
import {
  RECEIVED_USERS_LIST,
  RECEIVED_USERS_CREATE,
  RECEIVED_USERS_UPDATE,
  RECEIVED_USERS_GETHITS,
  REQUEST_USERS_GETHITS,
  RECEIVED_USERS_GET,
  RECEIVED_USERS_ADVANCEDLIST,
  RECEIVED_SESSIONUSERS_LIST,

} from "@actions/ajax";
import { ITEM_DELETED, CHANGE_PAGE, HIDE_MODAL } from "@actions";
import { CHANGE_TAB } from "@actions/editmenu";
import UsersHelper from "@helpers/users-helper";

import { CLEAR_SELECTED_USER } from "../actions/users";
import { CREATE_TEMP_VARS } from "../actions/presetEmails";

export const RECEIVED_USERS_ALLUSERSLIST = "RECEIVED_USERS_ALLUSERSLIST";
export const SET_COLUMN_FILTERS = "SET_COLUMN_FILTERS";
/* todo:Columns */
const initialState = {
  data: [],
  policyGroups: {
    data: [],
    ready: false,
  },
  advancedUsers: {
    data: [],
  },
  allPolicyUsers: {
    data: [],
  },
  userReservation: {},
  meta: {},
  fetching: false,
  policy: null,
  rooms: [],
  orderBy: "id",
  advancedSearch: false,
  groupId: {},
  selectedUser: {},
  tempContacts: [],
  hasUserBeenSelected: false,
  userEditMode: false,
  crmUsers: {
    data: [],
    meta: {},
    fetching: false,
  },
  userEvents: {
    data: [],
    meta: {},
    fetching: false,
    timestamp: null,
    active: false,
    userId: null,
  },
  sessionUsers: {
    data: [],
    meta: {},
    fetching: true,
  },
  columns: {
    default: {
      bulkSelect: {
        name: "",
      },
  quickEdit: {
         name:""
      },
      id: {
        name: "ID",
        sortable: true,
        filter: true,
      },
      lastName: {
        name: "Last Name",
        sortable: true,
        filter: true,
        editable: true,
      },
      firstName: {
        name: "First Name",
        sortable: true,
        filter: true,
        editable: true,
      },
      createdAt: {
        name: "Created At",
        sortable: true,
        filter: true,
        editable: true,
      },
      email: {
        name: "Email",
        sortable: true,
        filter: true,
        editable: true,
      },
      active: {
        name: "Active",
        sortable: true,
        filter: false,
        editable: true,
      },
      registered: {
        name: "Verified",
        sortable: true,
        filter: true,
        editable: true,
      },
    },
  },
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_USERS_GET: {
      if (action.response.data) {
        state.userReservation = action.response.data;
      }
      return Object.assign({}, state, {
        data: [...state.data].map((user) => {
          if (user.id === action.response.data.id) {
            return action.response.data;
          }
          return user;
        }),
        timestamp: Date.now(),
      });
    }
    case TOGGLE_HIT: {
      const newMeta = state.meta;
      newMeta["hasHit"] = action.hit;
      return Object.assign({}, state, {
        meta: newMeta,
      });
    }
    case RECEIVED_POLICYGROUPS_POLICYLIST: {
      return Object.assign({}, state, {
        policyGroups: Object.assign({}, state.policyGroups, {
          data: action.response.data,
          ready: true,
        }),
      });
    }
    case HIDE_USER_EVENTS: {
      return Object.assign({}, state, {
        userEvents: Object.assign({}, state.userEvents, {
          userId: null,
          active: false,
        }),
      });
    }
    case RECEIVED_USERS_ADVANCEDLIST: {
      return Object.assign({}, state, {
        advancedUsers: Object.assign({}, state.advancedUsers, {
          data: action.response.data,
        }),
      });
    }
    case REQUEST_USERS_ALLUSERSLIST: {
      return Object.assign({}, state, {
        fetching: true,
      });
    }
    case RECEIVED_SESSIONUSERS_LIST: {
      return Object.assign({}, state, {
        sessionUsers: Object.assign({}, state.sessionUsers, {
          data: action.response.data,
          fetching: false,
        }),
      });
    }

    case SHOW_USER_EVENTS: {
      return Object.assign({}, state, {
        userEvents: Object.assign({}, state.userEvents, {
          userId: action.userId,
          active: true,
        }),
      });
    }

    case ADVANCED_SEARCH_ACTIVE: {
      return Object.assign({}, state, {
        advancedSearch: action.active,
      });
    }
    case SET_USERS_GROUP_ID: {
      return Object.assign({}, state, {
        groupId: {
          ...state.groupId,
          [action.eventId]: {
            ...(state.groupId[action.eventId] || {}),
            [action.policyId]: action.groupId,
          },
        },
      });
    }
    case REQUEST_USERS_GETHITS: {
      return Object.assign({}, state, {
        userEvents: Object.assign({}, state.userEvents, {
          fetching: true,
          data: [],
          meta: {},
        }),
      });
    }

    case RECEIVED_USERS_GETHITS: {
      return Object.assign({}, state, {
        userEvents: Object.assign({}, state.userEvents, {
          data: action.response.data,
          meta: action.response.meta,
          fetching: false,
          timestamp: Date.now(),
        }),
      });
    }

    case ITEM_DELETED: {
      if (action.endpoint !== "users") {
        return state;
      }
      return Object.assign({}, state, {
        data: [...state.data].filter((u) => u.id !== parseInt(action.itemId)),
        timestamp: Date.now(),
      });
    }

    case CHANGE_PAGE: {
      const columns = state.columns;
      return Object.assign({}, state, initialState, { columns });
    }

    case LOAD_USERS:
    case REQUEST_USERS_LIST: {
      return Object.assign({}, state, {
        fetching: true,
      });
    }
    case RECEIVED_USERS_LIST: {
      return Object.assign({}, state, {
        fetching: false,
        data: action.response.data,
      });
    }
    case CHANGE_TAB: {
      return Object.assign({}, state, {
        allPolicyUsers: Object.assign({}, state.allPolicyUsers, {
          data: [],
        }),
        advancedUsers: Object.assign({}, state.advancedUsers, {
          data: [],
        }),
      });
    }

    case RECEIVED_USERS_ALLUSERSLIST: {
      return Object.assign(
        {},
        state,
        {
          allPolicyUsers: Object.assign({}, state.allPolicyUsers, {
            data: action.response.data,
          }),
        },
        { fetching: false }
      );
    }
    case REFRESH_MINUTES: {
      const minutesData = [...action.users.data];
      return Object.assign({}, state, {
        data: [...state.data].map((user) => {
          for (const [index, u] of minutesData.entries()) {
            if (u.id === user.id) {
              user.minutes = u.minutes;
              if (user.hasOwnProperty("points") && u.hasOwnProperty("points")) {
                user.points = u.points;
              }
              if (
                user.hasOwnProperty("currentRoom") &&
                u.hasOwnProperty("currentRoom")
              ) {
                user.currentRoom = u.currentRoom;
              }
              minutesData.splice(index, 1);
            }
          }
          return user;
        }),
      });
    }

    case CHANGE_ORDER_BY: {
      return Object.assign({}, state, {
        orderBy: action.orderBy,
      });
    }

    case SETUP_USERS: {
      return Object.assign({}, state, {
        data: action.users.data,
        meta: action.users.meta,
        fetching: false,
        timestamp: Date.now(),
      });
    }

    case SETUP_ROOMS: {
      return Object.assign({}, state, {
        rooms: action.rooms.data,
      });
    }

    case RECEIVED_USERS_CREATE: {
      return Object.assign({}, state, {
        data: [...[action.response.data], ...state.data],
        timestamp: Date.now(),
      });
    }

    case RECEIVED_USERS_UPDATE: {
      return Object.assign({}, state, {
        data: [...state.data].map((u) => {
          if (u.id === action.response.data.id) {
            return Object.assign({}, u, action.response.data);
          }
          return u;
        }),
        timestamp: Date.now(),
      });
    }

    case SETUP_POLICY: {
      const { eventId, id } = action.policy;
      const _columns = UsersHelper.adjustFilters(
        Object.assign({}, state.columns.default),
        action.policy,
        action.event
      );

      const newColumns = (() => {
        const response = { [eventId]: state.columns[eventId] || {} };
        response[eventId][id] = action.reset
          ? _columns
          : response[eventId][id] || _columns;
        return response;
      })();

      return Object.assign({}, state, {
        policy: action.policy,
        columns: Object.assign({}, state.columns, newColumns),
      });
    }

    case REORDER_FILTERS: {
      const { columns } = action;
      const { eventId, id } = state.policy;
      const _columns = Object.assign(
        {},
        {
          bulkSelect: {
            name: "",
          },
            quickEdit: {
             name: ""
           },
        },
        columns,
        {
          actions: {
            name: "",
          },
        }
      );

      const newColumns = (() => {
        const response = { [eventId]: state.columns[eventId] || {} };
        response[eventId][id] = _columns;
        return response;
      })();

      return Object.assign({}, state, {
        columns: Object.assign({}, state.columns),
      });
    }

    case CHANGE_FILTERS: {
      const { eventId, id } = state.policy;

      const columns = { ...state.columns[eventId][id] };

      Object.entries(columns).map(([k, v]) => {
        if (v.hasOwnProperty("filter")) {
          columns[k] = Object.assign({}, columns[k], {
            filter: action.filters.includes(k) ? true : false,
          });
        }
      });

      return Object.assign({}, state, {
        columns: Object.assign({}, state.columns, {
          [eventId]: { ...state.columns[eventId], [id]: columns },
        }),
      });
    }
    case REQUEST_ADVANCED_LIST: {
      return Object.assign({}, state, {
        crmUsers: Object.assign({}, state.crmUsers, {
          fetching: true,
        }),
      });
    }
    case RECEIVED_ADVANCED_LIST: {
      return Object.assign({}, state, {
        crmUsers: Object.assign({}, state.crmUsers, {
          data: action.response.data,
          meta: action.response.meta,
          fetching: false,
        }),
      });
    }
    case SELECT_USER: {
      return Object.assign({}, state, {
        selectedUser: action.info,
        hasUserBeenSelected: true,
      });
    }
    case CLEAR_SELECTED_USER: {
      return Object.assign({}, state, {
        selectedUser: {},
        hasUserBeenSelected: false,
      });
    }
    case HIDE_MODAL: {
      return Object.assign({}, state, {
        selectedUser: {},
        hasUserBeenSelected: false,
        userEditMode: false,
        userReservation: {},
      });
    }
    case CREATE_TEMP_VARS: {
      return Object.assign({}, state, {
        tempContacts: [action.contacts[1]],
      });
    }
    case USER_EDIT_MODE: {
      if (action.info === false) {
        return Object.assign({}, state, {
          userEditMode: false,
        });
      }
      return Object.assign({}, state, {
        userEditMode: !state.userEditMode,
      });
    }

    default: {
      return state;
    }
  }
};

export default users;
