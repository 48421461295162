import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import files from "@config/files";

export default class KMBDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.getFileInfoMessage = this.getFileInfoMessage.bind(this);
    this.dropzoneProps = {};

    this.state = {
      existingFiles: props.value || [],
      queuedFiles: [],
      disabled: props.max ? props.value.length >= props.max : false,
    };

    if (this.props.styleType === "simple") {
      this.promptMessage = [
        <span key="decorative" className="icon-cloud"></span>,
      ];
      this.dropzoneProps.style = {
        width: "100%",
        height: "auto",
        borderWidth: 1,
        borderColor: "var(--secondary)",
        borderStyle: "dashed",
        borderRadius: 4,
        backgroundImage: "none",
        padding: "25px 5px",
        backgroundColor: "#fff",
      };
      this.dropzoneProps.activeStyle = {
        borderStyle: "dashed",
        borderColor: "var(--secondary)",
      };
      this.dropzoneProps.acceptStyle = {
        borderStyle: "dashed",
        borderColor: "#14DA9E",
      };
      this.dropzoneProps.rejectStyle = {
        borderStyle: "dashed",
        borderColor: "var(--error-color)",
      };

      this.dropzoneProps.accept = this.props.accept;
    } else {
      this.dropzoneProps = {
        style: this.props.style,
        activeStyle: this.props.activeStyle,
        acceptStyle: this.props.acceptStyle,
        rejectStyle: this.props.rejectStyle,
        accept: this.props.accept,
      };
      this.promptMessage = this.props.promptMessage;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.areArraysEqual(this.props.value, nextProps.value)) {
      this.setState({
        existingFiles: nextProps.value || [],
        disabled: nextProps.max
          ? nextProps.value.length >= nextProps.max
          : false,
      });
    }
  }

  areArraysEqual(arr1, arr2) {
    if (!arr1 || !arr2) return false;
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  onDropAccepted(newFiles) {
    this.setState(
      (prevState) => ({
        queuedFiles: [...prevState.queuedFiles, ...newFiles],
      }),
      () => {
        if (this.props.onDropAccepted) {
          this.props.onDropAccepted(newFiles);
        }
      }
    );
  }

  onDropRejected() {
    this.props.onDropRejected(
      `Sorry but the file you tried to upload has an invalid file type. Accepted file types: ${this.props.accept}`
    );
  }

  removeFile(e, index) {
    e.stopPropagation();
    this.setState(
      (prevState) => {
        const isExistingFile = prevState.existingFiles.some(
          (file) => file.id == index
        );
        if (isExistingFile) {
          return {
            existingFiles: prevState.existingFiles.filter(
              (file) => file.id !== index
            ),
          };
        }

        return {
          queuedFiles: prevState.queuedFiles.filter((file, i) =>
            file.id ? file.id != index : i != index
          ),
        };
      },
      () => this.props.onFileRemove(index)
    );
  }

  getFileInfoMessage() {
    const { name } = this.props;
    const file =
      name && files.list.hasOwnProperty(name) ? files.list[name] : null;

    return (
      <div className="file-info">
        {file && <p className="dimensions">Dimensions: {file.dimensions}</p>}
        <p className="max-size">
          Max File Size:{" "}
          {(() => {
            if (
              this.props.id === "sessionFiles" ||
              this.props.id === "speechFiles" ||
              this.props.id === "speechFiles" ||
              this.props.id === "publishedAbstractThumbnail"
            )
              return 5;
            else if (
              this.props.id === "certificationImages" ||
              this.props.id === "cardPrintingBanners"
            )
              return 15;
            else if (this.props.id === "scheduleFile") return 20;
            else if (
              this.props.id === "publishedAbstractFile" ||
              this.props.id === "abstractFiles"
            )
              return 100;
            else if (this.props.id === "clientHomeJumbotron") return 10;
            else if (this.props.id === "exhibitorThumbnail") return 10;
            else if (this.props.id === "exhibitorsBucket") return 10;
            else return files.maxFileSize;
          })()}
          MB
        </p>
        <p className="file-name-restriction">{files.fileName}</p>
      </div>
    );
  }

  render() {
    const fileType =
      this.props.accept === "image/jpeg, image/png, image/gif, image/svg+xml"
        ? "image"
        : "document";

    return (
      <div
        className={`kmb-dropzone ${this.props.styleType}${
          this.props.active && !this.state.disabled ? "" : " disabled"
        }`}
      >
        {this.props.value.length > 0 && (
          <div className="selected-files">
            {this.props.value.map((f, index) => (
              <p key={`file-${index}`} className="file">
                {fileType === "image" ? (
                  <span>
                    <img src={f.url} alt={f.fileName} width="50" />
                    <i
                      className="fa fa-close delete-file"
                      aria-hidden="true"
                      onClick={() => this.props.onFileDelete(f)}
                    ></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                    <span className="file-name" title={f.fileName}>
                      {f.fileName}
                    </span>
                    <i
                      className="fa fa-close delete-file"
                      aria-hidden="true"
                      onClick={() => this.props.onFileDelete(f)}
                    ></i>
                  </span>
                )}
              </p>
            ))}
          </div>
        )}
        <Dropzone
          {...this.dropzoneProps}
          onDrop={this.onDrop}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          className="zone-area"
        >
          <div className="vertical-center">
            <div className="text-area">
              {this.props.children}
              <div className="wrap">
                {this.promptMessage !== "" && (
                  <p className="prompt-message">{this.promptMessage}</p>
                )}
                {this.props.description !== "" && (
                  <p className="prompt-description">{this.props.description}</p>
                )}
                <p className="details">
                  Drop your files here or <span>Browse</span>
                </p>
              </div>
              {this.getFileInfoMessage()}
              {this.state.queuedFiles.length > 0 && (
                <p className="file-names">
                  {this.state.queuedFiles.map((f, index) => (
                    <span key={`file-${index}`}>
                      {f?.name}
                      <span
                        className="fa fa-close"
                        onClick={(e) => this.removeFile(e, index)}
                      ></span>
                    </span>
                  ))}
                </p>
              )}
            </div>
          </div>
        </Dropzone>
        {this.state.disabled && (
          <p className="limit-reached">
            The limit of {this.props.max} files has been reached. Please delete
            a file, before you can upload a new one.
          </p>
        )}
      </div>
    );
  }
}

KMBDropzone.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  activeStyle: PropTypes.object,
  promptMessage: PropTypes.any,
  description: PropTypes.any,
  maxSize: PropTypes.number,
  accept: PropTypes.string,
  activeClassName: PropTypes.string,
  active: PropTypes.bool,
  styleType: PropTypes.string,
  acceptStyle: PropTypes.object,
  rejectStyle: PropTypes.object,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  children: PropTypes.element,
  multiple: PropTypes.bool,
  onFileRemove: PropTypes.func,
  onFileDelete: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
};

KMBDropzone.defaultProps = {
  style: {
    width: "100%",
    height: 500,
    borderWidth: 1,
    borderColor: "transparent",
    borderStyle: "dashed",
    borderRadius: 4,
    backgroundImage: "url( /images/cover-icon.png )",
  },
  description: "",
  activeStyle: {
    borderStyle: "dashed",
    borderColor: "var(--secondary)",
    backgroundColor: "#fff",
    backgroundImage: "url( /images/upload-icon.png )",
  },
  acceptStyle: {
    borderStyle: "dashed",
    borderColor: "#14DA9E",
    backgroundColor: "#fff",
  },
  rejectStyle: {
    borderStyle: "dashed",
    borderColor: "var(--error-color)",
    backgroundColor: "#fff",
  },
  maxSize: 1,
  accept: "image/jpeg, image/png, image/gif, image/svg+xml",
  onDropRejected: () => {},
  onDropAccepted: () => {},
  onFileRemove: () => {},
  onFileDelete: () => {},
  activeClassName: "",
  active: true,
  styleType: "advanced",
  multiple: false,
  value: [],
  max: false,
  name: "",
};
