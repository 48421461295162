/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

export default class Radio extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.getCheckedValue = this.getCheckedValue.bind(this);
    this.state = {
      checked: this.getCheckedValue(props.checked),
    };
  }
  getCheckedValue(value) {
    if (value === "no") return "no";
    if (value === "yes") return "yes";
    if (value == 0) return "no";
    if (value == 1) return "yes";
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.checked !== this.state.checked &&
      nextProps.valueFromState !== true
    ) {
      this.setState({ checked: this.getCheckedValue(nextProps.checked) });
    }
  }
  onChange(e) {
    this.setState(
      {
        checked: e.target.id.split("-").pop(),
      },
      () => this.props.onChange(this.state.checked)
    );
  }

  render() {
    const id = this.props.id;
    if (!this.props.type) {
      return (
        <div>
          {Object.entries(this.props.options).map(([k, v]) => {
            return (
              <div className="radio-wrapper" key={`wrapper-${k}`}>
                <input
                  name={this.props.name}
                  id={`${id}-${k}`}
                  type="radio"
                  value={k}
                  checked={k === this.state.checked}
                  disabled={this.props.disabled ? true : false}
                  onChange={this.onChange}
                />
                <label htmlFor={`${id}-${k}`}>{v}</label>
              </div>
            );
          })}
        </div>
      );
    } else if (this.props.type === "list") {
      return (
        <ul className={this.props.className}>
          {Object.entries(this.props.options).map(([k, v]) => {
            return (
              <li key={`${id}-${k}`}>
                <div className="radio-wrapper" key={`wrapper-${k}`}>
                  <input
                    name={this.props.name}
                    id={`${id}-${k}`}
                    type="radio"
                    value={k}
                    checked={k === this.state.checked}
                    disabled={this.props.disabled ? true : false}
                    onChange={this.onChange}
                  />
                  <label htmlFor={`${id}-${k}`}>{v}</label>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  }
}

Radio.propTypes = {
  options: PropTypes.object.isRequired,
  checked: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  checked: "no",
  options: {
    yes: "Yes",
    no: "No",
  },
  disabled: false,
  type: undefined,
  className: "",
  onChange: () => {},
};
