import * as React from "react";
const SvgReset = (props) => (
  <svg
    width={19}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.833 2.333v5m0 0h5m-5 0L4.7 3.7a7.5 7.5 0 1 1-1.775 7.8"
      stroke="#0079CA"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgReset;
