/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { isObject, isArray } from "@helpers";

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSelect = this.toggleSelect.bind(this);
    this.getPrompt = this.getPrompt.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleBody = this.handleBody.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.locateMe = this.locateMe.bind(this);
    this.scrollSelected = this.scrollSelected.bind(this);
    this.excludedArea = null;
    this.originalValue = props.value;

    this.state = {
      toggled: props.toggled,
      selections: props.value
        ? isArray(props.value)
          ? props.value
          : [props.value.toString()]
        : [],
      selectButton: "select",
      keySearch: "",
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleBody);
    document.body.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBody);
  }

  handleKeyDown(e) {
    if (!this.state.toggled) {
      return;
    }

    const key = e.key.toLowerCase();
    let found = false;
    let foundIndex = false;
    let index = 0;
    for (const [k, v] of Object.entries(this.props.options)) {
      index++;
      if (v.substring(0, 1).toLowerCase() === key) {
        found = k;
        foundIndex = index;
        break;
      }
    }
    if (found) {
      const scroll = this.optionHeight * foundIndex;
      this.optionsWrapper.scrollTop = scroll;
    }
  }

  handleBody(e) {
    if (!this.excludedArea.contains(e.target)) {
      this.setState({
        toggled: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        selections: nextProps.value
          ? isArray(nextProps.value)
            ? nextProps.value
            : [nextProps.value.toString()]
          : [],
      });
    }
  }

  scrollSelected() {
    let index = 0;
    let found = false;
    for (const [k] of Object.entries(this.props.options)) {
      index++;
      if (k === this.state.selections[0]) {
        found = index;
        break;
      }
    }
    if (found) {
      const scroll = this.optionHeight * found;
      this.optionsWrapper.scrollTop = scroll;
    }
  }

  toggleSelect() {
    this.setState(
      (prevState) => {
        return {
          toggled: !prevState.toggled,
        };
      },
      () => {
        if (this.state.toggled) {
          this.scrollSelected();

          if (this.props.onToggle) {
            this.props.onToggle(this.excludedArea);
          }
        }
      }
    );
  }

  locateMe() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.onChange(timezone);
  }

  onChange(key, event) {
    if (event && event.target.type) {
      // action on input
      // surpress closing
      return;
    }

    if (this.props.defaultAlways) {
      this.props.onChange(key);
      this.setState({
        selections: [],
        toggled: false,
      });
      return;
    }

    if (key === false || key === "") {
      this.setState({
        selections: [],
        toggled: false,
      });
      this.props.onChange(key);
      return;
    }

    if (!this.state.selections.includes(key)) {
      const selections = this.props.multi
        ? [...this.state.selections, ...[key]]
        : [key];
      this.setState(
        {
          selections: this.props.isDropDown ? [] : selections,
          toggled: false,
        },
        () =>
          this.props.multi
            ? this.props.onChange(this.state.selections)
            : this.props.onChange(key)
      );
    } else {
      this.setState(
        {
          toggled: false,
          selections: this.props.multi
            ? [...this.state.selections].filter((s) => s !== key)
            : this.state.selections,
        },
        () =>
          this.props.multi ? this.props.onChange(this.state.selections) : ""
      );
    }
  }

  getPrompt() {
    if (this.props.isDropDown || this.props.defaultAlways) {
      return this.props.placeholder;
    }

    if (this.state.selections.length > 0) {
      if (this.props.multi) {
        return [...this.state.selections]
          .map((s) => this.props.options[s])
          .join(", ");
      } else {
        return this.props.options[this.state.selections[0]];
      }
    }

    return this.props.placeholder;
  }

  render() {
    const className = `bottom-area${this.state.toggled ? "" : " hidden"}`;
    const button = "";

    let wrapperClass = "select-advanced multiselect";
    wrapperClass += this.props.className ? ` ${this.props.className}` : "";
    wrapperClass += this.props.disabled ? " disabled" : "";
    wrapperClass += this.state.selections.length === 0 ? " empty" : "";

    return (
      <div className={wrapperClass} ref={(ref) => (this.excludedArea = ref)}>
        {this.props.name === "timezone" && (
          <button
            type="button"
            className="btn dark locate-me"
            onClick={this.locateMe}
          >
            Locate me<span className="fa fa-map-marker"></span>
          </button>
        )}
        <div className="select-checkboxes-outer">
          <div className="upper-area" onClick={this.toggleSelect}>
            <span>{this.getPrompt()}</span>
            {this.props.hasIndicator && (
              <span className="fa fa-angle-down directional"></span>
            )}
          </div>
          {this.state.toggled && (
            <div className={className} style={{ zIndex: 2 }}>
              <div>
                {this.props.beforeRenderUl ? this.props.beforeRenderUl() : ""}
                <ul
                  className="options-wrapper"
                  ref={(ref) => {
                    if (ref) {
                      this.optionsWrapper = ref;
                      this.optionHeight = ref.firstChild.clientHeight;
                    }
                  }}
                >
                  {this.props.placeholderInsideSelect && (
                    <li
                      className="placeholder"
                      onClick={() => this.onChange("")}
                    >
                      {this.props.placeholder}
                    </li>
                  )}
                  {Object.entries(this.props.options).map(([key, obj]) => {
                    let label = isObject(obj) ? obj.label : obj;
                    const className =
                      !this.props.isDropDown &&
                      this.state.selections.includes(key)
                        ? { className: "selected" }
                        : {};
                    if (this.props.onRender) {
                      const jsx = this.props.onRender({ key, obj });
                      if (jsx !== undefined) {
                        // we have an override
                        label = jsx;
                      }
                    }
                    return (
                      <li
                        key={`li-${key}`}
                        {...className}
                        onClick={(e) => this.onChange(key, e)}
                      >
                        {label}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.any,
  options: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
  onChange: PropTypes.func,
  toggled: PropTypes.bool,
  defaultAlways: PropTypes.bool,
  forceClose: PropTypes.bool,
  multi: PropTypes.bool,
  hasIndicator: PropTypes.bool,
  placeholderInsideSelect: PropTypes.bool,
  isDropDown: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  beforeRenderUl: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  className: PropTypes.string,
  onRender: PropTypes.func,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  hasUserBeenSelected: PropTypes.bool,
  userEditMode: PropTypes.bool,
};

Select.defaultProps = {
  placeholder: "Select",
  onChange: () => {},
  hasUserBeenSelected: false,
  userEditMode: false,
  beforeRenderUl: false,
  selectAllButton: true,
  toggled: false,
  forceClose: false,
  multi: false,
  hasIndicator: true,
  placeholderInsideSelect: true,
  isDropDown: false,
  value: "",
  className: "",
  disabled: false,
};
