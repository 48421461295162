import { connect } from "react-redux";
import {
  loadCrmUsers,
  deleteCrmUser,
  updateCrmSchema,
  updateCrmSchemaFields,
  savePerson,
  exportAllUsers,
  changeCrmFilters,
  createCrmSchema,
} from "@actions/crm";

import Crm from "./Crm";
import { showModal, hideModal, addNotification } from "@actions";

const mapStateToProps = (state) => {
  const mode = "crm";
  return {
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
    data: state.api[mode].list.data,
    schemas: state.api["schemas"],
    columns: state.api.crmColumns,
    dataReady: state.api[mode].list.ready,
    fetching: state.api[mode].list.fetching,
    mode,
    activeSort: state.api[mode].list.meta.orderBy || "id",
    meta: state.api[mode].list.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (orgId, mode, meta = false, cache = false) => {
      // It enters here
      // and in the dispatch
      // the third parameter is equal to the endpoint when it tries to make the call
      dispatch(loadCrmUsers(orgId, meta, mode, cache));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    save: (
      orgId,
      data,
      personId = false,
      contentType = "json",
      endpoint,
      active
    ) => {
      dispatch(
        savePerson(orgId, data, personId, contentType, endpoint, active)
      );
    },
    updateSchema: (_request, orgId, schemaId) => {
      dispatch(updateCrmSchema(_request, orgId, schemaId));
    },
    changeCrmFilters: (filters) => {
      dispatch(changeCrmFilters(filters));
    },
    createSchema: (_request, updating, orgId) => {
      dispatch(createCrmSchema(_request, updating, orgId));
    },
    exportAllUsers: (orgId, searchTerm, meta) => {
      dispatch(exportAllUsers(orgId, searchTerm, meta));
    },
    delete: (orgId, itemId) => {
      dispatch(deleteCrmUser(orgId, itemId));
    },
    updateFields: (fields, updating) => {
      dispatch(updateCrmSchemaFields(fields, updating));
    },

    hideModal: () => {
      dispatch(hideModal());
    },
    addNotification: (message, status) => {
      dispatch(addNotification(message, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Crm);
