import React from "react";
import PropTypes from "prop-types";

export default class SideModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleEscape = this.handleEscape.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.handleEscape, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener("keypress", this.handleEscape, false);
  }

  handleEscape(e) {
    if (e.key === "Escape") {
      this.props.hideModal();
    }
  }

  render() {
    const { data } = this.props;
    if (data.type !== "sideModal") return null;

    return (
      <>
        {data.showModal && (
          <div
            onClick={() => {
              this.props.hideModal();
              document.body.focus();
            }}
            className="backdrop"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "#00000060",
              zIndex: 12,
            }}
          ></div>
        )}
        <div
          className={`side-modal${data.showModal ? " in" : ""}${
            data.large ? " large" : ""
          }${data.className ? ` ${data.className}` : ""}`}
        >
          <div className="side-modal-header">
            {/* <div className="close-modal right">
            <button
              className="btn transparent-dark"
              onClick={() => this.props.hideModal()}
            >
              <span className="icon-close"></span>
            </button>
          </div> */}
            {data.header}
          </div>
          <div className="side-modal-content">{data.content}</div>
        </div>
      </>
    );
  }
}

SideModal.propTypes = {
  hideModal: PropTypes.func,
  data: PropTypes.object,
};
