import React, { useState } from "react";
import Table from "@layout/Table";
import Checkbox from "@layout/Checkbox";
import Input from "@layout/Input";
import Select from "@layout/Select2";
import TagsInput from "@layout/TagsInput";
import PropTypes from "prop-types";
// import TableActions from "@layout/TableActions";
import { v4 as uuidv4 } from "uuid";
import SvgConditionEmpty from "@app_icons/ConditionEmpty";
import SvgConditionParent from "@app_icons/ConditionParent";
import SvgConditionChild from "@app_icons/ConditionChild";
import SvgConditionFull from "@app_icons/ConditionFull";
import SingleCondition from "./SingleCondition";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const initialStatic = ["firstName", "lastName", "email", "password"];
const InformationFields = (props) => {
  const symbols = {
    text: (
      <div className="symbol">
        <span>T</span>
      </div>
    ),
    crm: (
      <div className="symbol">
        <span style={{ width: "32px" }}>CRM</span>
      </div>
    ),
    textarea: (
      <div className="symbol">
        <span>Ta</span>
      </div>
    ),
    dropdown: (
      <div className="symbol">
        <i className="fas fa-caret-down"></i>
      </div>
    ),
    email: (
      <div className="symbol">
        <i className="fas fa-at"></i>
      </div>
    ),
    yes_no: (
      <div className="symbol">
        <i className="far fa-check-circle"></i>
      </div>
    ),
    password: (
      <div className="symbol">
        <i className="fas fa-key"></i>
      </div>
    ),
    section: (
      <div className="symbol">
        <i className="fas fa-puzzle-piece"></i>
      </div>
    ),
  };

  const columns = {
    type: {
      name: "",
    },
    name: {
      name: "Field Name",
    },
    askUser: {
      name: "Ask User",
    },
    required: {
      name: "Required",
    },
    pricing: {
      name: "Pricing",
    },
    condition: {
      name: "Condition",
    },
    value: {
      name: "Options",
    },
    remove: {
      name: "",
    },
  };
  if (props.policy.type === "public") delete columns.pricing;

  const addExtraField = (type, crmField) => {
    const newItems = [...props.items];
    let newField;
    if (type) {
      newField = {
        name: "",
        used: 1,
        required: 0,
        pricing: 0,
        key: uuidv4(),
        value: type === "dropdown" ? [] : "",
        type,
        askUser: 1,
        disabled: [],
        notUsed: [],
        conditionSchema: { filters: null },
      };
    } else {
      type = crmField.type;
      const key = crmField.key;
      // key the key value for the case that the extrafield is deleted
      // and then added without saving in the meantime
      // if (Object.keys(props.policy.extraSettings).includes(crmField.key)) {
      //   key = crmField.key;
      // } else {
      //   key = uuidv4();
      // }
      newField = {
        orgCrmSchemaMetaId: crmField.id,
        name: crmField.name,
        used: 1,
        pricing: 0,
        refKey: crmField.key,
        required: 0,
        key,
        crmKey: crmField.key,
        value: crmField?.value?.map?.((v) => ({
          id: uuidv4(),
          key: v.key,
          value: v.value,
          crm_value: v.crm_value,
        })),
        type: crmField.type,
        askUser: 1,
        disabled: ["name", "value"],
        notUsed: ["name", "value"],
        conditionSchema: { filters: null },
      };
    }

    if (type !== "dropdown") {
      newField.disabled.push("value");
    }

    newItems.push(newField);
    props.onChange(newItems);
  };

  const removeExtraField = (index) => {
    const newItems = [...props.items];
    const visibleItems = newItems.filter(
      (item) => !item.deleted && !item.hidden
    );
    if (visibleItems[index]) {
      visibleItems[index].deleted = true;
    }
    props.onChange(newItems);
  };

  const updateCheckbox = (itemKey, colKey) => {
    const newItems = [...props.items];
    const theItem = newItems.filter(
      (singleItem) => singleItem.key === itemKey
    )[0];
    const { askUser, required, pricing } = theItem;

    if (colKey === "askUser") {
      //case ask use is set to false
      if (askUser == 1) {
        theItem.askUser = 0;
        theItem.required = 0;
        theItem.pricing = 0;
      } else {
        theItem.askUser = 1;
      }
    } else if (colKey === "required") {
      //case ask user is set to 0
      if (required == 0) {
        theItem.askUser = 1;
        theItem.required = 1;
      } else {
        theItem.required = 0;
        theItem.pricing = 0;
      }
    } else if (colKey === "pricing") {
      //case ask user is set to 0
      if (pricing == 0) {
        theItem.askUser = 1;
        theItem.required = 1;
        theItem.pricing = 1;
      } else {
        theItem.pricing = 0;
      }
    }
    props.onChange(newItems);
  };

  const updateItem = (itemKey, colKey, value) => {
    const newItems = JSON.parse(JSON.stringify(props.items));
    const theItem = newItems.filter(
      (singleItem) => singleItem.key === itemKey
    )[0];
    theItem[colKey] = value;
    delete theItem.error;
    props.onChange(newItems);
  };

  const disabled = ["firstName", "lastName", "email", "password", "condition"];
  const disabledConditions = ["firstName", "lastName", "email", "password"];
  const disabledChildConditions = ["sponsored", "speakerChairperson"];
  const getChildDependencyKeys = (index) => {
    const itemsToCheck = props.items.filter((item) => !item.deleted);
    const looper =
      itemsToCheck?.[index]?.conditionSchema?.filters ||
      itemsToCheck?.[index]?.conditionSchema;
    return looper
      ?.map?.((group) => {
        const looper = group.conditions || group;
        return looper?.map((condition) => {
          return condition.key;
        });
      })
      .flat(2);
  };

  const getChildDependencyMinIndex = (index) => {
    const dependencyKeys = getChildDependencyKeys(index);

    const dependencyIndexes =
      (() => {
        return dependencyKeys?.map?.((key) => {
          return props.items.find((item) => item.key === key && !item.deleted)
            ?.index;
        });
      })() || [];

    return dependencyIndexes.length
      ? Math.max(...dependencyIndexes)
      : undefined;
  };

  const getParentDependentKeys = (index) => {
    return props.items
      .map((item) => {
        const looper = item?.conditionSchema?.filters || item?.conditionSchema;
        const dependsOnArray = looper
          ?.map?.((group) => {
            const looper = group.conditions || group;
            return looper?.map((condition) => {
              return condition.key;
            });
          })
          .flat(2);
        if (
          dependsOnArray?.includes(props.items?.[index]?.key) &&
          !item.deleted
        ) {
          return item.key;
        }
      })

      .filter((v) => {
        return v !== undefined;
      });
  };
  const getParentDependentMaxIndex = (index) => {
    const dependentKeys = getParentDependentKeys(index);
    const dependentIndexes =
      (() => {
        return dependentKeys?.map?.((key) => {
          return props.items.find((item) => item.key === key)?.index;
        });
      })() || [];
    return dependentIndexes.length ? Math.min(...dependentIndexes) : undefined;
  };
  const submitCondition = (fieldKey) => (conditionSchema) => {
    const newItems = [...props.items];
    const theItem = newItems.filter(
      (singleItem) => singleItem.key == fieldKey
    )[0];
    theItem.conditionSchema = conditionSchema;
    props.onChange(newItems);
  };
  const onRender = (col, item, index) => {
    const isSection = item.type === "section";
    let Comp = SvgConditionEmpty;
    const isParent = getParentDependentKeys((item.index || 0) + 2)?.length;
    const isChild = getChildDependencyKeys((item.index || 0) + 2)?.length;
    const checkDisabled =
      item.disabled.includes(col.key) ||
      disabledChildConditions.includes(item.key) ||
      props.disableCondition ||
      item.pricing;
    switch (col.key) {
      case "name": {
        if (disabled.includes(item.key)) {
          return <div className="kmb-flex-center">{item.name}</div>;
        }
        return (
          <>
            {item.orgCrmSchemaMetaId &&
              props.policy?.crmFields?.[item.key]?.name !== item.name && (
                <Tooltip
                  title={`The CRM name of this field is ${
                    props.policy?.crmFields?.[item.key]?.name
                  }`}
                >
                  <InfoIcon
                    style={{
                      cursor: "pointer",
                      paddingLeft: "5px",
                      width: "32px",
                    }}
                  />
                </Tooltip>
              )}
            <Input
              style={{ width: "100%" }}
              className={`${item.error ? " error" : ""}${
                isSection ? " bold" : ""
              }`}
              placeholder="Type..."
              value={item.name}
              onChange={(e) => {
                updateItem(item.key, col.key, e.target.value);
              }}
            />
          </>
        );
      }

      case "type": {
        if (isSection) return <span className="hidden"></span>;
        return (
          <div className="kmb-flex-center">
            {symbols[item.type]}
            {item.orgCrmSchemaMetaId && symbols["crm"]}
          </div>
        );
      }
      case "condition":
        if (disabledConditions.includes(item.key)) return;
        if (isChild && isParent) {
          Comp = SvgConditionFull;
        } else if (isChild) {
          Comp = SvgConditionChild;
        } else if (isParent) {
          Comp = SvgConditionParent;
        }
        return (
          <div
            className={`kmb-flex-center kmb-pointer${
              checkDisabled ? " disabled" : ""
            }`}
            onClick={() => {
              if (checkDisabled) {
                return;
              }
              props.showModal(
                <SingleCondition
                  {...props}
                  item={item}
                  group={item.conditionSchema}
                  edit={true}
                  onSubmit={submitCondition(item.key)}
                  excludeKeys={(() => {
                    const keys = Object.keys(props.policy.systemExtraSettings)
                      .concat(
                        Object.keys(props.policy.extraSettings).filter(
                          (key) => {
                            return (
                              key === item.key ||
                              ["text", "textarea", "section"].includes(
                                props.policy.extraSettings[key].type
                              )
                            );
                          }
                        )
                      )
                      .concat(
                        Object.values(props.items || {})
                          .filter((value) => {
                            return props.items.find(
                              (propItem) => propItem.key === value.key
                            )?.deleted;
                          })
                          .map((value) => value.key)
                      );
                    return keys;
                  })()}
                />
              );
            }}
          >
            <Comp
              id={`${col.key}-${item.key}`}
              className="no-pointer-events"
              checked={Boolean(item[col.key])}
              readOnly={true}
              disabled={item.disabled.includes(col.key)}
            ></Comp>
          </div>
        );
      case "pricing":
      case "required":
      case "askUser": {
        if (isSection) return <span className="hidden"></span>;

        if (!item.disabled.includes("pricing")) item.disabled.push("pricing");
        if (item.pricing) {
          item.disabled.push("askUser", "required");
        }
        if (item.conditionSchema?.filters?.length)
          item.disabled.push("pricing");

        return (
          <div
            className={`kmb-flex-center kmb-pointer${
              item.disabled.includes(col.key) ? " disabled" : ""
            }`}
            onClick={() => {
              if (item.disabled.includes(col.key)) {
                return;
              }
              updateCheckbox(item.key, col.key);
            }}
          >
            <Checkbox
              id={`${col.key}-${item.key}`}
              className="no-pointer-events"
              checked={Boolean(item[col.key])}
              readOnly={true}
              disabled={item.disabled.includes(col.key)}
            />
          </div>
        );
      }
      case "value": {
        if (isSection) return <span className="hidden"></span>;
        let crmTags;
        if (item.orgCrmSchemaMetaId && item.type === "dropdown") {
          crmTags = Object.values(props.policy?.crmFields || {}).find(
            (field) => {
              return field.id === item.orgCrmSchemaMetaId;
            }
          )?.value;
        }
        return (
          <div
            className={`kmb-flex-center
                      ${item.notUsed.includes(col.key) ? " not-used" : ""}
                `}
            onClick={() => {}}
          >
            {item.type == "dropdown" && (
              <TagsInput
                crmTags={crmTags}
                tags={item.value}
                onChange={(value) => updateItem(item.key, col.key, value)}
              />
            )}
            {item.type == "password" && !props.isParticipantPolicy && (
              <input
                className="form-control"
                placeholder="Type a password..."
                value={item.value}
                onChange={(e) => updateItem(item.key, col.key, e.target.value)}
              />
            )}
          </div>
        );
      }
      case "remove": {
        if (["speakerChairperson", "sponsored"].includes(item.key)) {
          return null;
        }
        if (disabled.includes(item.key) || item.pricing || isParent) return;

        return (
          <div className="remove" onClick={() => removeExtraField(index)}>
            <i className="icon-delete"></i>
          </div>
        );
      }
    }
  };

  const [state, setState] = React.useState({
    allItemKeys: [],
    crmFieldsOptions: {},
  });
  const [_static, setStatic] = useState(initialStatic);

  React.useEffect(() => {
    if (props.isParticipantPolicy && Object.keys(props.policy).length) {
      const allItemKeys = props.items
        .filter((item) => !item.deleted)
        .map((item) => item.refKey || item.key);

      const crmFieldsOptions = Object.keys(props.policy?.crmFields || {})
        .filter((key) => !allItemKeys.includes(key))
        .reduce(
          (prev, next) => ({
            ...prev,
            [next]: props.policy?.crmFields[next]?.name,
          }),
          {}
        );
      setState({ allItemKeys, crmFieldsOptions });
    }
  }, [props.items, props.policy]);

  return (
    <div className="information-fields">
      <div className="field-wrapper">
        <Table
          updating={props.updating}
          trClassNameFunc={(item) => {
            let className = "information-row ";
            if (item.type === "section") {
              className += "section-row";
            }
            return className;
          }}
          sortable={true}
          updateBeforeSortStart={({ index }) => {
            // get the dependencies index
            const minIndex = getChildDependencyMinIndex(index) ?? -Infinity;

            // get the dependents index
            const maxIndex = getParentDependentMaxIndex(index) ?? Infinity;

            // when no dependands nor dependacies
            if (minIndex === undefined && maxIndex === undefined) return false;

            // calculate the new available sorting options
            const keys = props.items.filter((item, i) => {
              if (i < 2) return false;
              const isLessThanMinIndex = item.index <= minIndex;
              const isMoreThanMaxIndex = item.index >= maxIndex;

              return isLessThanMinIndex || isMoreThanMaxIndex;
            });

            // apply the new static keys
            const newStaticKeys = [...initialStatic, ...keys.map((v) => v.key)];
            setStatic(newStaticKeys);
          }}
          onSortEnd={(newIndex, item, newItems) => {
            const newSortList = newItems.map((sorted, index) => ({
              ...sorted,
              index: index - 2,
              id: undefined,
            }));
            props.onChange(newSortList);
            setStatic(initialStatic);
          }}
          id={"information-fields"}
          columns={columns}
          items={props.items
            .filter((item) => {
              return !item.deleted && !item.hidden;
            })
            .map((item, index) => {
              const newItem = JSON.parse(JSON.stringify(item));
              if (item.id) {
                newItem.oldDBId = item.id;
              }
              if (_static?.includes(item.key)) return { ...newItem };
              return { id: index, ...newItem, index: index - 2 };
            })}
          onRender={onRender}
        />
      </div>
      <div className="add-fields">
        <Select
          isSearchable={false}
          isClearable={false}
          isDropDownButton={true}
          color={"#2a79d1"}
          hoverColor={"#2a79d1"}
          border={`1px solid #2a79d1`}
          hasIndicator={false}
          placeholderInsideSelect={false}
          className="select-button"
          options={{
            section: (
              <span className="symbol-wrapper">
                {symbols["section"]}Section
              </span>
            ),
            text: (
              <span className="symbol-wrapper">
                {symbols["text"]}Text Field
              </span>
            ),
            textarea: (
              <span className="symbol-wrapper">
                {symbols["textarea"]}Text Area
              </span>
            ),
            yes_no: (
              <span className="symbol-wrapper">{symbols["yes_no"]}Yes/No</span>
            ),
            dropdown: (
              <span className="symbol-wrapper">
                {symbols["dropdown"]}Dropdown
              </span>
            ),
          }}
          placeholder={[
            <div
              key="placeholder-add-field"
              className="select-button-placeholder"
            >
              <span className="icon-add-1"></span>
              <span>Add New Field</span>
            </div>,
          ]}
          onChange={(type) => addExtraField(type)}
        />
        {props.isParticipantPolicy && (
          <Select
            isSearchable={false}
            color={"#2a79d1"}
            className="select-button"
            options={state.crmFieldsOptions}
            placeholder={[
              <div
                key="placeholder-add-field"
                className="select-button-placeholder"
              >
                <span className="icon-add-1"></span>
                <span>Add CRM Field</span>
              </div>,
            ]}
            hasIndicator={false}
            placeholderInsideSelect={false}
            hoverColor={"#2a79d1"}
            border={`1px solid #2a79d1`}
            isDropDownButton={true}
            onChange={(index) => {
              return addExtraField(null, {
                ...(props.policy?.crmFields?.[index] || {}),
                key: index,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};
InformationFields.defaultProps = {
  policy: {},
};
InformationFields.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  isParticipantPolicy: PropTypes.bool,
  policy: PropTypes.object,
  updating: PropTypes.bool,
  disableCondition: PropTypes.bool,
};
export default InformationFields;
