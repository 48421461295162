import React from "react";
import Form from "@components/form";
import { isEmpty } from "@helpers";
import formFields from "@helpers/form-fields";
import PropTypes from "prop-types";
import Confirm from "@layout/Confirm";
import moment from "moment";
import ApiHelper from "@helpers/api-helper";
import momentTimezones from "moment-timezone";

export default class NewEvent extends React.Component {
  constructor(props) {
    super(props);
    formFields.forms.events.basicInfoCreate.duration.autoTime = false;
    formFields.forms.events.basicInfoCreate.timezone.options =
      this.addTimezones();
    this.openConfirm = this.openConfirm.bind(this);
    this.discard = this.discard.bind(this);
    this.state = {
      open: false,
      savedData: {},
      nameValue: "",
      descriptionValue: "",
    };
  }

  addTimezones() {
    const zones = {};
    momentTimezones.tz.names().map((z) => {
      zones[z] = `${z} ${momentTimezones().tz(z).format("Z")}`;
    });
    return zones;
  }

  openConfirm(savedData) {
    this.setState({
      open: true,
      savedData,
    });
  }

  discard() {
    this.setState({
      open: false,
      savedData: {},
    });
  }
  createEvent(value) {
    this.props.createEvent(value);
  }
  render() {
    const confirmBody = (
      <div>
        <p>The date you have selected is in the past.</p>
        <p>Are you sure this is correct?</p>
      </div>
    );

    return (
      <div className="new-event form-container">
        <h2>Create Event</h2>
        {this.state.open && (
          <div>
            <Confirm
              onConfirm={() => {
                this.createEvent(this.state.savedData);
                this.setState({
                  open: false,
                  savedData: {},
                });
              }}
              body={confirmBody}
              className=""
              confirmText="Confirm Old Date"
              title="Date is in the past!"
              visible={true}
              onClose={this.discard}
            ></Confirm>
          </div>
        )}
        <Form
          componentName="events"
          formName="basicInfoCreate"
          onSubmit={(data) => {
            if (isEmpty(data.errors)) {
              const startDate = data.value.duration.from;
              if (
                moment(startDate, ApiHelper.dateFormat).isBefore(
                  moment(),
                  "day"
                )
              ) {
                return this.openConfirm(data.value);
              }
              this.createEvent(data.value);
            }
          }}
          onCancel={() => {
            this.props.hideModal();
          }}
        />
      </div>
    );
  }
}

NewEvent.propTypes = {
  createEvent: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
};
