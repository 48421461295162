import React from "react";
import Table from "@layout/Table";
import Select from "@layout/Select2";
import TagsInput from "@layout/TagsInput";
import PropTypes from "prop-types";
import Checkbox from "@layout/Checkbox";
import { v4 as uuidv4 } from "uuid";
const AbstractFields = (props) => {
  const symbols = {
    text: (
      <div className="symbol">
        <span>T</span>
      </div>
    ),
    textarea: (
      <div className="symbol">
        <span>Ta</span>
      </div>
    ),
    dropdown: (
      <div className="symbol">
        <i className="fas fa-caret-down"></i>
      </div>
    ),
    yes_no: (
      <div className="symbol">
        <i className="far fa-check-circle"></i>
      </div>
    ),
    section: (
      <div className="symbol">
        <i className="fas fa-puzzle-piece"></i>
      </div>
    ),
  };

  const columns = {
    type: {
      name: "",
    },
    name: {
      name: "Field Name",
    },
    required: {
      name: "Required",
    },
    value: {
      name: "Options",
    },

    remove: {
      name: "",
    },
  };

  const addExtraField = (type) => {
    const newItems = [...props.items];
    const newField = {
      name: "",
      key: uuidv4(),
      value: type === "dropdown" ? [] : "",
      type,
    };
    newItems.push(newField);
    props.onChange(newItems);
  };

  const removeExtraField = (index) => {
    const newItems = [...props.items];
    newItems.filter((item) => !item.deleted)[index].deleted = true;
    props.onChange(newItems);
  };

  const updateItem = (itemKey, colKey, value) => {
    const newItems = [...props.items];
    const theItem = newItems.filter(
      (singleItem) => singleItem.key === itemKey
    )[0];
    theItem[colKey] = value;
    delete theItem.error;
    props.onChange(newItems);
  };
  const updateCheckbox = (itemKey, colKey) => {
    const newItems = [...props.items];
    const theItem = newItems.filter(
      (singleItem) => singleItem.key === itemKey
    )[0];
    theItem[colKey] = !theItem[colKey];

    props.onChange(newItems);
  };

  const onRender = (col, item, index) => {
    const isSection = item.type === "section";
    switch (col.key) {
      case "active": {
        return <span></span>;
      }
      case "name": {
        return (
          <input
            className={`form-control${item.error ? " error" : ""}${
              isSection ? " bold" : ""
            }`}
            placeholder="Type..."
            value={item.name}
            onChange={(e) => updateItem(item.key, col.key, e.target.value)}
          />
        );
      }
      case "type": {
        if (isSection) return <span className="hidden"></span>;
        return <div className="kmb-flex-center">{symbols[item.type]}</div>;
      }
      case "required": {
        if (isSection) return <span className="hidden"></span>;

        return (
          <div
            className={`kmb-flex-center kmb-pointer`}
            onClick={() => {
              updateCheckbox(item.key, col.key);
            }}
          >
            <Checkbox
              id={`${col.key}-${item.key}`}
              className="no-pointer-events"
              checked={Boolean(item[col.key])}
              onChange={() => {}}
            />
          </div>
        );
      }
      case "value": {
        if (isSection) return <span className="hidden"></span>;
        let tags;
        try {
          tags = item.value;
        } catch (e) {
          tags = [];
        }
        if (typeof tags !== "object") tags = [];
        return (
          <div className={`kmb-flex-center kmb-pointer`}>
            {item.type == "dropdown" && (
              <TagsInput
                tags={tags}
                onChange={(value) => {
                  updateItem(item.key, col.key, value);
                }}
              />
            )}
          </div>
        );
      }
      case "remove": {
        return (
          <div className="remove" onClick={() => removeExtraField(index)}>
            <i className="icon-delete"></i>
          </div>
        );
      }
    }
  };

  return (
    <div className="information-fields abstracts">
      <div className="field-wrapper">
        <Table
          containerClassName="table-responsive"
          trClassNameFunc={(item) => {
            let className = "information-row ";
            if (item.type === "section") {
              className += "section-row";
            }
            return className;
          }}
          id={"information-fields"}
          updating={false}
          columns={columns}
          items={props.items.filter((item) => {
            return !item.deleted;
          })}
          onRender={onRender}
        />
      </div>
      <div className="add-fields">
        <Select
          isSearchable={false}
          isClearable={false}
          isDropDownButton={true}
          color={"#2a79d1"}
          hoverColor={"#2a79d1"}
          border={`1px solid #2a79d1`}
          hasIndicator={false}
          placeholderInsideSelect={false}
          className="select-button"
          options={{
            text: (
              <span className="symbol-wrapper">
                {symbols["text"]}Text Field
              </span>
            ),
            textarea: (
              <span className="symbol-wrapper">
                {symbols["textarea"]}Text Area
              </span>
            ),
            yes_no: (
              <span className="symbol-wrapper">{symbols["yes_no"]}Yes/No</span>
            ),
            dropdown: (
              <span className="symbol-wrapper">
                {symbols["dropdown"]}Dropdown
              </span>
            ),
          }}
          placeholder={[
            <div
              key="placeholder-add-field"
              className="select-button-placeholder"
            >
              <span className="icon-add-1"></span>
              <span>Add New Field</span>
            </div>,
          ]}
          onChange={(type) => addExtraField(type)}
        />
      </div>
    </div>
  );
};
AbstractFields.defaultProps = {
  policy: {},
};
AbstractFields.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  policy: PropTypes.object,
};
export default AbstractFields;
