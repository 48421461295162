import * as React from "react";
const SvgConditionFull = (props) => (
  <svg
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#condition_full_svg__a)" fill="#57AEFF">
      <path
        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
        stroke="#fff"
        strokeWidth={2}
      />
      <path d="M20.5 6h-14a2.5 2.5 0 0 0 0 5h14a2.5 2.5 0 0 0 0-5ZM11.5 22h9a2.5 2.5 0 0 0 0-5h-9a2.5 2.5 0 0 0 0 5Z" />
      <path d="M14 19.5v-9a2.5 2.5 0 0 0-5 0v9a2.5 2.5 0 0 0 5 0Z" />
    </g>
    <defs>
      <clipPath id="condition_full_svg__a">
        <path fill="#fff" d="M0 0h23v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgConditionFull;
