import { connect } from "react-redux";
import {
  updateExhibitionDigitalCategories,
  loadExhibitionCategories,
} from "@actions/exhibitions";
import DigitalCategories from "./DigitalCategories";

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    categories: state.api.exhibitionHall.categories.data,
    updating:
      state.api.exhibitionHall.slots.fetching ||
      state.api.exhibitionHall.categories.fetching,
    exhibitors: state.api.exhibitionHall.exhibitors.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateExhibitionDigitalCategories: (eventId, data) =>
      dispatch(updateExhibitionDigitalCategories(eventId, data)),
    loadExhibitionCategories: (eventId) =>
      dispatch(loadExhibitionCategories(eventId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalCategories);
