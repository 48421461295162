import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@layout/Checkbox";

const ExhibitorSlots = (props) => {
  const [state, setState] = React.useState({
    slots: [],
    slotId: props.exhibitor.slotId,
    position: props.exhibitor.position,
    size: props.exhibitor.size,
  });

  React.useEffect(() => {
    const newState = {
      ...state,
      slotId: props.exhibitor.slotId,
      position: props.exhibitor.position,
      size: props.exhibitor.size,
    };
    newState.slots = getSlots(newState);
    setState(newState);
  }, [props.exhibitor, props.slots]);

  const getSlots = (newState) => {
    const slots = [];
    props.slots.forEach((slot) => {
      for (let i = 0; i < props.numberOfSlots; i++) {
        const checked = checkIfSlotIsChecked(newState, slot, i);
        //check other exhibitors
        let occupiedByExhibitor;
        for (const otherExhibitor of props.exhibitors) {
          if (otherExhibitor.id === props.exhibitor.id) continue;
          const checkIfOccupied = checkIfSlotIsChecked(
            {
              slotId: otherExhibitor.slotId,
              position: otherExhibitor.position,
              size: otherExhibitor.size,
            },
            slot,
            i
          );
          if (checkIfOccupied) {
            occupiedByExhibitor = otherExhibitor;
            break;
          }
        }
        slots.push({
          id: slot.id,
          price: slot.price,
          checked,
          occupiedByExhibitor,
        });
      }
    });
    for (let i = 0; i < slots.length; i++) {
      if ((slots[i + 1] || {}).checked && (slots[i - 1] || {}).checked)
        slots[i].disabled = true;
      else slots[i].disabled = false;
    }
    return slots;
  };

  const checkIfSlotIsChecked = (newState, slot, i) => {
    const { slotId, position, size } = newState;
    const isSelectedRow = slot.id == slotId;
    const isAfterStart = i >= position;
    const isBeforeEnd = i < position + size;
    if (isSelectedRow && isAfterStart && isBeforeEnd) return true;
    return false;
  };

  return (
    <div className="slot-container row">
      {state.slots.map((slot, i) => {
        return (
          <div
            className={`col-sm-${12 / props.numberOfSlots}`}
            key={`slot-${slot.id}-${i}`}
          >
            <div
              style={{ cursor: "pointer" }}
              className={`slot-item ${
                slot.occupiedByExhibitor ? "occupied" : ""
              } ${
                (slot.id != state.slotId && state.slotId) || props.disableAll
                  ? "disabled"
                  : ""
              }`}
              onClick={() =>
                !slot.occupiedByExhibitor &&
                props.onChange(slot, i % props.numberOfSlots)
              }
            >
              {slot.occupiedByExhibitor ? (
                <div className="occupied-exhibitor">
                  {slot.occupiedByExhibitor.exhibitorThumbnail.length ? (
                    <img
                      src={slot.occupiedByExhibitor.exhibitorThumbnail[0].url}
                    />
                  ) : (
                    <div>{slot.occupiedByExhibitor.name}</div>
                  )}
                </div>
              ) : (
                <Checkbox
                  id={`slot-${slot.id}-${i}`}
                  checked={slot.checked}
                  disabled={slot.disabled}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ExhibitorSlots.defaultProps = {
  slots: [],
  exhibitor: {},
};
ExhibitorSlots.propTypes = {
  slots: PropTypes.array.isRequired,
  exhibitor: PropTypes.object.isRequired,
  name: PropTypes.string,
  componentName: PropTypes.string,
  numberOfSlots: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  exhibitors: PropTypes.array,
  disableAll: PropTypes.bool,
};
export default ExhibitorSlots;
