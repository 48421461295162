import { connect } from "react-redux";
import { getEventRoles, getRoleTypes, changeRole } from "@actions/roles";
import { getPerson } from "@actions/roles";
import { loadPolicies, changePage } from "@actions";
import Roles from "./Roles";

const mapStateToProps = (state, ownProps) => {
  const roles = state.api.staff.roles;

  const roleTypes = {};
  if (state.api.staff.roleTypes.ready) {
    roleTypes["none"] = { label: "No Role" };
    Object.entries(state.api.staff.roleTypes.data).map(([k, v]) => {
      roleTypes[k] = {
        label: v,
      };
    });
  }

  return {
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
    personId: ownProps.staffId,
    events: roles.data.events,
    organisations: roles.data.organisations,
    page: state.page,
    fetching:
      !state.appuser.ready ||
      !roles.ready ||
      !state.api.staff.roleTypes.ready ||
      state.api.staff.roles.fetching,
    personFetching: !state.api.staff.single.ready,
    meta: state.api.staff.roles.meta,
    criteria: roles.criteria,
    doingAjax: state.api.doingAjax,
    router: state.page.router,
    person: state.api.staff.single.data,
    roleTypes,
    filtered: state.api.staff.roles.filtersActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (page) => {
      return dispatch(changePage(page));
    },
    getEventRoles: (orgId, id, criteria = false, filters = false) => {
      dispatch(getEventRoles(orgId, id, criteria, filters));
    },
    getRoleTypes: () => {
      dispatch(getRoleTypes());
    },
    changeRole: (eventId, orgId, personId, prevRole, newRole) => {
      return dispatch(changeRole(eventId, orgId, personId, prevRole, newRole));
    },
    getPerson: (orgId, personId, mode = "staff") => {
      return dispatch(getPerson(orgId, personId, mode));
    },
    loadPolicies: (eventId) => {
      return new Promise((resolve, reject) => {
        dispatch(loadPolicies(eventId))
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
