import { connect } from "react-redux";
import { addNotification } from "@actions";
import Cover from "./Cover";

const mapStateToProps = (state) => {
  const menu = state.editmenu;

  return {
    currentComponent: {
      name: menu.sections[menu.activeSection].items[menu.activeTab],
      key: menu.activeTab,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cover);
