import { connect } from "react-redux";
import { showModal } from "@actions";
import { dismissNotification } from "@actions/appuser";
import UserNotificationsArea from "./UserNotificationsArea";

const mapStateToProps = (state) => {
  return {
    notifications: state.appuser.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
          "user-notifications-modal"
        )
      );
    },
    dismissNotification: (key) => dispatch(dismissNotification(key)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNotificationsArea);
