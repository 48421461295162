import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";
import {
  updateClientPanelSettings,
  uploadFile,
  getClientPanelSettings,
  uploadWithUrl,
} from "@actions";
import ImageCover from "@layout/ImageCover";
import { addNotification } from "@actions";

const Logo = (props) => {
  const [imageUrl, setImageUrl] = React.useState(
    props.event.clientPanelSettings.header.banner[0]?.url || ""
  );
  const headerLogo = React.useRef();

  return (
    <div className="panel-content-card branding-content">
      <div className="logo-picker-wrapper">
        <ImageCover
          height={"300px"}
          imageUrl={imageUrl}
          name="Header Logo"
          onChange={(file, deletefile) => {
            if (deletefile) {
              setImageUrl("");
              headerLogo.current = [
                props.event.clientPanelSettings.header.banner[0]?.id,
              ];
            } else if (window.FileReader && file) {
              const fr = new FileReader();
              fr.onload = () => {
                headerLogo.current = [file];
                setImageUrl(fr.result);
              };
              fr.readAsDataURL(file);
            }
          }}
        />
      </div>
      <SubmitContainer
        fetching={props.fetching}
        onCancel={() => {
          setImageUrl(
            props.event.clientPanelSettings.header.banner[0]?.url || ""
          );
        }}
        onSubmit={() => {
          if (typeof headerLogo.current?.[0] === "number") {
            props.updateClientPanelSettings(
              props.event.id,
              { clientHeaderLogo: headerLogo.current[0] },
              "header-logo"
            );
            return;
          }
          props.uploadFile(
            props.event.id,
            "clientHeaderLogo",
            headerLogo.current[0],
            null,
            () => {
              props.getClientPanelSettings(props.event.id);
              props.addNotification(
                "Event logo updated successfully!",
                "success"
              );
            },
            null,
            headerLogo.current.length
          );
        }}
        disabled={false}
        cancelDisabled={false}
        submitText="Save Changes"
      />
    </div>
  );
};

Logo.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  getClientPanelSettings: PropTypes.func.isRequired,
  uploadWithUrl: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  addNotification: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  updateClientPanelSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    policies: state.api.accesspolicies?.list?.data,
    fetching: state.api.accesspolicies.list.fetching,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (eventId, orgId, action, file, params, callback, quantity) => {
      return dispatch(
        uploadFile(eventId, orgId, action, file, params, callback, quantity)
      );
    },
    getClientPanelSettings: (eventId) =>
      dispatch(getClientPanelSettings(eventId)),
    uploadWithUrl: (url, file) => {
      return dispatch(uploadWithUrl(url, file));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
