import { connect } from "react-redux";
import { loadPersons, savePerson } from "@actions/sessions";
import Staff from "./Staff";
import { showModal, deleteItem, hideModal } from "@actions";

const mapStateToProps = (state, ownProps) => {
  const mode =
    ownProps.pathname === "/administrator/persons" ? "persons" : "staff";
  return {
    orgId: state.appuser.ready ? state.appuser.data.orgId : null,
    data: state.api[mode].list.data,
    dataReady: state.api[mode].list.ready,
    fetching: state.api[mode].list.fetching,
    mode,
    page: state.page,
    activeSort: state.api[mode].list.meta.orderBy || "id",
    meta: state.api[mode].list.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: (orgId, mode, meta = false, cache = true) => {
      dispatch(loadPersons(orgId, meta, mode, cache));
    },
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    save: (orgId, data, personId = false, contentType = "json", endpoint) => {
      dispatch(savePerson(orgId, data, personId, contentType, endpoint, true));
    },
    deleteItem: (orgId, personId, endpoint) => {
      dispatch(deleteItem({ orgId, personId }, endpoint));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
