import React from "react";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import VideoSponsors from "./partials/VideoSponsors";
import SingleVideo from "./partials/SingleVideo";
import {
  createClientPanelVideoSponsor,
  updateClientPanelVideoSponsorOrder,
  showModal,
} from "@actions";
import { connect } from "react-redux";

const Videos = (props) => {
  const tabs = [{ key: "sponsors", label: "Sponsors" }];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <React.Fragment>
      <div className="top-menu">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
        {tabs[activeTab].key === "sponsors" && (
          <button
            type="button"
            className="btn create rounded"
            onClick={() =>
              props.showModal(
                <SingleVideo
                  item={{}}
                  mode="create"
                  onSave={(data) => {
                    return props.createClientPanelVideoSponsor(props.event.id, {
                      ...data,
                      index:
                        props.event.clientPanelSettings.videos.sponsorsSchema
                          .length,
                    });
                  }}
                />
              )
            }
          >
            Create
          </button>
        )}
      </div>
      {tabs[activeTab].key === "sponsors" && (
        <VideoSponsors
          updateClientPanelVideoSponsorOrder={
            props.updateClientPanelVideoSponsorOrder
          }
        />
      )}
    </React.Fragment>
  );
};

Videos.propTypes = {
  event: PropTypes.object,
  createClientPanelVideoSponsor: PropTypes.func,
  updateClientPanelVideoSponsorOrder: PropTypes.func,
  showModal: PropTypes.func,
  index: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content) => dispatch(showModal(content)),
    createClientPanelVideoSponsor: (eventId, data) => {
      return dispatch(createClientPanelVideoSponsor(eventId, data));
    },
    updateClientPanelVideoSponsorOrder: (eventId, data) => {
      return dispatch(updateClientPanelVideoSponsorOrder(eventId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
