/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import TableActions from "@layout/TableActions";
import Table from "@layout/Table";
import { showModal, hideModal } from "@actions";
import { connect } from "react-redux";
import KMBLoader from "@layout/KMBLoader";
import { getPersons, getSponsor, deletePerson } from "@actions/sponsors";
import SinglePerson from "./SinglePerson";
import Breadcrumbs from "@layout/Breadcrumbs";

class Persons extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.state = {
      sponsor: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getPersons(this.props.orgId, this.props.sponsorId);
    this.props
      .getSponsor(this.props.orgId, this.props.sponsorId)
      .then(({ data: sponsor }) => {
        this.setState({ sponsor });
      });
  }
  onRender(col, item) {
    switch (col.key) {
      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SinglePerson
                  value={item}
                  h2={"Edit"}
                  orgId={this.props.orgId}
                  sponsorId={this.props.sponsorId}
                  personId={item.id}
                />
              )
            }
            onDelete={() =>
              this.props.deletePerson(
                this.props.orgId,
                this.props.sponsorId,
                item.id
              )
            }
          />
        );
      }
    }
  }
  render() {
    return (
      <div className="list-sponsor-persons-main">
        <div className="top-bar">
          <div>
            <div className="left"></div>
          </div>
          <div>
            <div className="right">
              <div className="create-crm-user">
                <button
                  className="btn create"
                  onClick={() =>
                    this.props.showModal(
                      <SinglePerson
                        h2={"chris"}
                        orgId={this.props.orgId}
                        sponsorId={this.props.sponsorId}
                      />
                    )
                  }
                >
                  Create new Person
                </button>
              </div>
            </div>
          </div>
        </div>
        {!this.props.ready && <KMBLoader rows={15} padding={24} height={53} />}
        <div className="list-view">
          <Breadcrumbs
            options={[
              {
                name: "Sponsors",
                action: () =>
                  this.props.page.navigate("/administrator/sponsors"),
              },
              {
                name: this.state?.sponsor.name,
              },
              {
                name: "Persons",
              },
            ]}
          />

          <div className="main-wrapper">
            <div className="med-table-container">
              <Table
                columns={{
                  id: {
                    name: "ID",
                  },
                  firstName: {
                    name: "First Name",
                  },
                  lastName: {
                    name: "Last Name",
                  },
                  email: {
                    name: "Email",
                  },
                  phoneNumber: {
                    name: "Phone Number",
                  },
                  actions: {
                    name: "",
                  },
                }}
                onRender={this.onRender}
                items={this.props.personsList}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Persons.propTypes = {
  orgId: PropTypes.number.isRequired,
  getPersons: PropTypes.func.isRequired,
  personsList: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  sponsorId: PropTypes.any.isRequired,
  changeTab: PropTypes.func,
  ready: PropTypes.bool.isRequired,
  deletePerson: PropTypes.func.isRequired,
  getSponsor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.data.orgId,
    personsList: state.api.sponsors.persons.data,
    ready: state.api.sponsors.persons.ready,
    page: state.page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (content) => {
      dispatch(
        showModal(
          content,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    },
    getSponsor: (orgId, sponsorId) => {
      return dispatch(getSponsor(orgId, sponsorId));
    },
    getPersons: (orgId, sponsorId) => {
      return dispatch(getPersons(orgId, sponsorId));
    },
    deletePerson: (orgId, sponsorId, personId) => {
      dispatch(deletePerson(orgId, sponsorId, personId));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Persons);
