import * as XLSX from "xlsx";
import { generateFile } from "@helpers";

const XLSHelper = {
  read: (xls, ignoreFirstRow) => {
    const rows = ignoreFirstRow ? 2 : 1;
    const readtype = { type: "binary" };
    const workBook = XLSX.read(xls, readtype);
    const preview = [];
    workBook.SheetNames.forEach((sheetName) => {
      const XL_row_object = XLSX.utils.sheet_to_row_object_array(
        workBook.Sheets[sheetName],
        { header: 1 }
      );
      for (let i = 0; i <= rows; i++) {
        if (ignoreFirstRow && i === 0) continue;
        preview.push(XL_row_object[i]);
      }
    });
    return preview.filter((p) => p);
  },

  exportDefaultXLS: (data, fileName) => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != wbout.length; ++i)
      view[i] = wbout.charCodeAt(i) & 0xff;
    generateFile(fileName, "xlsx", buf);
  },
};

export default XLSHelper;
