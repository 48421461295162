import { api } from "@actions/ajax";
import { addNotification } from "@actions";

export const loadProducts = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "list",
      params: { eventId },
      cache: false,
      query: "?rpp=-1",
    };
    return dispatch(api(request));
  };
};
export const loadPrices = (eventId, productId, meta = { p: 1, rpp: 20 }) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "pricesList",
      params: { eventId, productId },
      cache: false,
      query: `?p=${meta.p}&rpp=${meta.rpp}`,
    };
    return dispatch(api(request));
  };
};
export const loadData = (
  eventId,
  productId,
  meta = { search: "", p: 1, rpp: 20 }
) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "dataList",
      params: { eventId, productId },
      cache: false,
      query: `?payments=true&users=true&search=${meta.search}&p=${meta.p}&rpp=${meta.rpp}`,
    };
    return dispatch(api(request));
  };
};
export const deleteProduct = (eventId, productId) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "delete",
      params: { eventId, productId },
      cache: false,
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Product deleted successfully", "success"))
      )
      .then(() => dispatch(loadProducts(eventId)));
  };
};
export const deletePrice = (eventId, productId, priceId) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "priceDelete",
      params: { eventId, productId, priceId },
      cache: false,
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Price deleted successfully", "success"))
      )
      .then(() => dispatch(loadPrices(eventId, productId)));
  };
};
export const deleteData = (eventId, productId, dataId) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "dataDelete",
      params: { eventId, productId, dataId },
      cache: false,
      body: {
        data: {},
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Data deleted successfully", "success"))
      )
      .then(() => dispatch(loadData(eventId, productId)));
  };
};
export const createProduct = (eventId, data) => {
  return (dispatch) => {
    data["targetEventUsers"] = true;
    data["type"] = "participation";
    const request = {
      endpoint: "products",
      action: "create",
      params: { eventId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request)).then(() => dispatch(loadProducts(eventId)));
  };
};
export const updateProduct = (eventId, productId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "edit",
      params: { eventId, productId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request)).then(() => dispatch(loadProducts(eventId)));
  };
};
export const createPrice = (eventId, productId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "priceCreate",
      params: { eventId, productId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request)).then(() => {
      dispatch(addNotification("Price created successfully!", "success"));
      dispatch(loadPrices(eventId, productId));
    });
  };
};
export const updatePrice = (eventId, productId, priceId, data) => {
  return (dispatch) => {
    const request = {
      endpoint: "products",
      action: "priceEdit",
      params: { eventId, productId, priceId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then(() => dispatch(loadPrices(eventId, productId)))
      .then(() =>
        dispatch(addNotification("Price updated successfully!", "success"))
      );
  };
};
export const createData = (eventId, productId, data, userId) => {
  return (dispatch) => {
    data["eventUserId"] = userId;
    const request = {
      endpoint: "products",
      action: "dataCreate",
      params: { eventId, productId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Data created successfully!", "success"))
      )
      .then(() => dispatch(loadData(eventId, productId)));
  };
};
export const updateData = (eventId, productId, dataId, data, userId) => {
  return (dispatch) => {
    data["eventUserId"] = userId;
    const request = {
      endpoint: "products",
      action: "dataEdit",
      params: { eventId, productId, dataId },
      cache: false,
      body: {
        data,
      },
    };
    return dispatch(api(request))
      .then(() =>
        dispatch(addNotification("Data updated successfully!", "success"))
      )
      .then(() => dispatch(loadData(eventId, productId)));
  };
};
