import React from "react";
import PropTypes from "prop-types";
import Period from "./partials/Period";
import SubscriptionsHead from "./partials/SubscriptionsHead";
import SubscriptionBody from "./partials/SubscriptionsBody";
import SubmitContainer from "@layout/SubmitContainer";

const PricingMain = (props) => {
  const [prices, setPrices] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [periods, setPeriods] = React.useState([]);
  const periodsRef = React.useRef(JSON.parse(JSON.stringify(props.periods)));
  React.useEffect(() => {
    if (!props.periods || !props.pricingGroups || !props.subscriptions) return;
    calculatePrices();
  }, [props.subsection]);

  React.useEffect(() => {
    if (!props.periods || !props.pricingGroups || !props.subscriptions) return;
    /* check if changes are about the period */
    if (periodsRef.current.length > props.periods.length) {
      removePeriod();
    } else if (
      JSON.stringify(props.periods) !== JSON.stringify(periodsRef.current)
    ) {
      calculatePrices();
    } else {
      calculatePrices(true);
    }
    periodsRef.current = JSON.parse(JSON.stringify(props.periods));
  }, [props.periods, props.pricingGroups, props.subscriptions]);

  const removePeriod = () => {
    const oldPeriodsId = Object.keys(prices);
    const newPeriodsId = props.periods.map((period) => String(period.id));
    const newPrices = { ...prices };
    const newPeriods = [...periods];
    for (const oldPeriodId of oldPeriodsId) {
      if (!newPeriodsId.includes(oldPeriodId)) {
        delete newPrices[oldPeriodId];
        let posInPeriodsArray = -1;
        for (const i in newPeriods) {
          if (newPeriods[i].eventPeriodId == oldPeriodId) {
            posInPeriodsArray = i;
            break;
          }
        }
        if (posInPeriodsArray >= 0) {
          newPeriods.splice(posInPeriodsArray, 1);
        }
      }
    }
    setPrices(newPrices);
    setPeriods(newPeriods);
    setErrors({});
  };

  const calculatePrices = (clearOldPrices) => {
    try {
      const subscriptionPrices = {};
      const subscriptionActive = {};
      for (const subscription of props.subscriptions) {
        const { eventSubscriptionPeriodId, eventPolicyGroupId, price, active } =
          subscription;

        subscriptionPrices[eventSubscriptionPeriodId] ??= {};
        subscriptionPrices[eventSubscriptionPeriodId][eventPolicyGroupId] =
          price;
        subscriptionActive[eventSubscriptionPeriodId] ??= {};
        subscriptionActive[eventSubscriptionPeriodId][eventPolicyGroupId] =
          active;
      }

      let newPrices = clearOldPrices ? {} : { ...prices };
      if (String(props.pricingGroups[0]?.id).includes("new-")) newPrices = {};
      const data = [];

      props.periods?.forEach((period) => {
        newPrices[period.id] ??= {};
        const group = {
          name: period.name,
          startDate: period.startDate,
          endDate: period.endDate,
          subsections: [],
          groups: [],
          initialGroups: [],
          eventPeriodId: period.id,
        };
        if (props.pricingGroups[0]?.systemKey === "[]") {
          group.groups.push("");
          group.initialGroups.push([props.pricingGroups[0].name]);
          subscriptionPrices[period.id] ??= {};
          subscriptionActive[period.id] ??= {};

          if (clearOldPrices) {
            newPrices[period.id][props.pricingGroups[0].id] = {
              id: props.pricingGroups[0].id,
              systemKey: props.pricingGroups[0].systemKey,
              uuid: props.pricingGroups[0].uuid,
              key: JSON.parse(props.pricingGroups[0].systemKey),
              value:
                subscriptionPrices[period.id][props.pricingGroups[0].id] ?? "",
              dropdown_value_id: props.pricingGroups[0].dropdown_value_id,
              active:
                subscriptionActive[period.id][props.pricingGroups[0].id] ?? 1,
            };
          } else {
            newPrices[period.id][props.pricingGroups[0].id] = {
              id: props.pricingGroups[0].id,
              systemKey: props.pricingGroups[0].systemKey,
              uuid: props.pricingGroups[0].uuid,
              key: JSON.parse(props.pricingGroups[0].systemKey),
              value:
                newPrices[period.id][props.pricingGroups[0].id]?.value ??
                subscriptionPrices[period.id][props.pricingGroups[0].id] ??
                "",
              active:
                subscriptionActive[period.id][props.pricingGroups[0].id] ?? 1,
              dropdown_value_id: props.pricingGroups[0].dropdown_value_id,
            };
          }
        } else {
          for (const pricingGroup of props.pricingGroups) {
            subscriptionPrices[period.id] ??= {};
            subscriptionActive[period.id] ??= {};

            if (clearOldPrices) {
              newPrices[period.id][pricingGroup.id] = {
                id: pricingGroup.id,
                uuid: pricingGroup.uuid,
                systemKey: pricingGroup.systemKey,
                key: JSON.parse(pricingGroup.systemKey),
                value: subscriptionPrices[period.id][pricingGroup.id] ?? "",
                active: subscriptionActive[period.id][pricingGroup.id] ?? 1,
                dropdown_value_id: pricingGroup.dropdown_value_id,
              };
            } else {
              newPrices[period.id][pricingGroup.id] = {
                id: pricingGroup.id,
                uuid: pricingGroup.uuid,
                systemKey: pricingGroup.systemKey,
                key: JSON.parse(pricingGroup.systemKey),
                active: subscriptionActive[period.id][pricingGroup.id] ?? 1,
                value:
                  (prices[period.id] || {})[pricingGroup.id]?.value ??
                  newPrices[period.id][pricingGroup.id]?.value ??
                  subscriptionPrices[period.id][pricingGroup.id] ??
                  "",
                dropdown_value_id: pricingGroup.dropdown_value_id,
              };
            }
            const groups = [];
            let arrayOfKeys = JSON.parse(pricingGroup.systemKey);
            if (pricingGroup.filter.includes(`"operator":"<>"`)) {
              try {
                const parsedFilter = JSON.parse(pricingGroup.filter);
                parsedFilter.filters[0].forEach((filter) => {
                  if (filter.operator === "<>") {
                    arrayOfKeys = arrayOfKeys.filter(
                      (key) => !key.startsWith(filter.label)
                    );
                    arrayOfKeys.push(`${filter.label}-All Others`);
                  }
                });
              } catch (e) {
                console.error(e);
              }
            }

            for (const i in arrayOfKeys) {
              if (arrayOfKeys[i] == null) continue;

              if (arrayOfKeys[i]?.startsWith(props.subsection)) {
                if (!group.subsections?.includes(arrayOfKeys[i])) {
                  group.subsections.push(arrayOfKeys[i]);
                }
                continue;
              }

              if (!groups.includes(arrayOfKeys[i])) {
                groups.push(arrayOfKeys[i]);
              }
            }

            const joint = [...groups].join(" | ");
            if (!group.groups.includes(joint)) {
              group.groups.push(joint);
              group.initialGroups.push(groups);
            }
          }
        }

        data.push(group);
      });
      setErrors({});
      setPeriods(data);
      setPrices(newPrices);
    } catch (e) {
      console.error("PRICING ERROR:", e);
    }
  };

  const onChangePrice = (e, periodId, groupId) => {
    if (!isFinite(e.target.value)) return;
    errors[`${periodId}-${groupId}`] = false;
    const newPrices = { ...prices };
    newPrices[periodId][groupId].value = e.target.value;
    setPrices(newPrices);
  };
  const onChangeActive = (value, periodId, groupId) => {
    const newPrices = { ...prices };
    newPrices[periodId][groupId].active = Number(value);
    setPrices(newPrices);
  };
  const onCancel = () => {
    calculatePrices(true);
    props.onCancel();
  };
  const onSave = () => {
    const newErrors = {};
    const data = [];
    for (const periodId in prices) {
      for (const groupId in prices[periodId]) {
        if (prices[periodId][groupId]?.value === "")
          newErrors[`${periodId}-${groupId}`] = true;
        data.push({
          eventSubscriptionPeriodId: periodId,
          eventPolicyGroupId: groupId,
          price: prices[periodId][groupId],
          active: prices[periodId][groupId].active,
        });
      }
    }
    if (Object.keys(newErrors).length) {
      return setErrors(newErrors);
    }
    return props.onSave(data);
  };
  return (
    <div className="pricing-main">
      {periods.map((group, index) => {
        return (
          <div
            key={"pricing-table-" + index}
            className={"pricing-period-wrapper form-group"}
          >
            <Period {...props} group={group} showDelete={periods.length > 1} />

            <div className="pricing-table-wrapper">
              <table className="pricing-table">
                <tbody>
                  <SubscriptionsHead
                    subsections={group.subsections}
                    eventPeriodId={group.eventPeriodId}
                    prices={prices}
                    errors={errors}
                    setErrors={setErrors}
                    setPrices={setPrices}
                  />
                  {group.groups.map((row, i) => {
                    return (
                      <SubscriptionBody
                        key={`row-${index}-${i}`}
                        prices={prices}
                        errors={errors}
                        {...props}
                        group={group}
                        row={row}
                        index={i}
                        onChangePrice={onChangePrice}
                        onChangeActive={onChangeActive}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="btn btn-white"
          style={{ width: 260, height: "36px" }}
          onClick={() => props.addPeriod()}
        >
          <span className="icon-add-1"></span>
          Add a Subscription Period
        </button>
      </div>
      <SubmitContainer onCancel={onCancel} onSubmit={onSave} />
    </div>
  );
};

PricingMain.propTypes = {
  periods: PropTypes.array,
  pricingGroups: PropTypes.array,
  subscriptions: PropTypes.array,
  pricingFields: PropTypes.array,
  subsection: PropTypes.string,
  addPeriod: PropTypes.func,
  event: PropTypes.object.isRequired,
  deletePeriod: PropTypes.func,
  onChangePeriod: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  clearOldPrices: PropTypes.bool,
};
export default PricingMain;
