import React from "react";
import PropTypes from "prop-types";
import Switch from "@layout/Switch";

const SubscriptionBody = (props) => {
  const {
    group,
    row,
    prices,
    errors,
    onChangePrice,
    onChangeActive,
    index,
    pricingFields,
    pricingGroups,
  } = props;

  return (
    <tr key={"row" + index}>
      <td className="row-header">
        {group.initialGroups[props.index].map((_item, j) => {
          const found = pricingFields.filter((pricingField) =>
            _item?.includes?.(pricingField.name)
          );
          _item ??= "";
          let fieldName = _item.split("-")[0];
          let fieldValue = _item.split("-")[1];
          // if pricing group is one then all participants
          if (pricingGroups.length > 1 && found[0]) {
            fieldName = _item.substring(0, found[0].name.length);
            fieldValue = _item.substring(fieldName.length + 1, _item.length);
          }

          return (
            <span key={`item-${props.index}-${j}`} className="group-tag">
              {fieldName}
              <span
                style={{
                  display: row === "" ? "none" : "unset",
                  background: "#15344736",
                  padding: "2px 6px",
                  borderRadius: "12px",
                  marginLeft: "6px",
                  marginRight: "-8px",
                  fontWeight: "400",
                  color: "var(--primary)",
                }}
              >
                {fieldValue}
              </span>
            </span>
          );
        })}
      </td>
      {!group.subsections.length &&
        (() => {
          const groupSystemKey =
            row === "" ? "[]" : JSON.stringify([...row.split(" | ")].sort());
          const item = props.pricingGroups.find((price_group) => {
            let replaced;
            try {
              let parsed = JSON.parse(price_group.systemKey);
              if (price_group.filter.includes(`"operator":"<>"`)) {
                try {
                  const parsedFilter = JSON.parse(price_group.filter);
                  parsedFilter.filters[0].forEach((filter) => {
                    if (filter.operator === "<>") {
                      parsed = parsed.filter(
                        (key) => !key.startsWith(filter.label)
                      );
                      parsed.push(`${filter.label}-All Others`);
                    }
                  });
                } catch (e) {
                  console.error(e);
                }
              }
              replaced = JSON.stringify(parsed.sort());
            } catch (e) {
              console.error(e);
            }
            return replaced === groupSystemKey;
          });
          if (!item) return;
          return (
            <td className="price-value">
              <div>
                <input
                  className={
                    errors[`${group.eventPeriodId}-${item.id}`] ? "error" : ""
                  }
                  value={prices[group.eventPeriodId][item.id]?.value}
                  id={`${group.eventPeriodId}-${item.id}`}
                  placeholder="Type a price value..."
                  onChange={(e) =>
                    onChangePrice(e, group.eventPeriodId, item.id)
                  }
                />
                <div className="field-type ">
                  <Switch
                    isDisabled={false}
                    isLive={true}
                    id={`display-sponsor-types`}
                    isActive={prices[group.eventPeriodId][item.id]?.active}
                    onChange={(value) => {
                      onChangeActive(value, group.eventPeriodId, item.id);
                    }}
                  />
                </div>
              </div>
            </td>
          );
        })()}
      {group.subsections.map((subsect) => {
        const groupSystemKey = JSON.stringify(
          [subsect, ...row.split(" | ")].sort()
        );
        const item = props.pricingGroups.find((price_group) => {
          // change multiple occurance of subsect to All Others
          let replaced;
          try {
            let parsed = JSON.parse(price_group.systemKey);
            if (price_group.filter.includes(`"operator":"<>"`)) {
              try {
                const parsedFilter = JSON.parse(price_group.filter);
                parsedFilter.filters[0].forEach((filter) => {
                  if (filter.operator === "<>") {
                    parsed = parsed.filter(
                      (key) => !key.startsWith(filter.label)
                    );
                    parsed.push(`${filter.label}-All Others`);
                  }
                });
              } catch (e) {
                console.error(e);
              }
            }
            replaced = JSON.stringify(parsed.sort());
          } catch (e) {
            console.error(e);
          }
          return replaced === groupSystemKey;
        });

        if (!item) {
          return;
        }
        return (
          <td key={`value-${subsect}`} className="price-value">
            <div>
              <input
                className={
                  errors[`${group.eventPeriodId}-${item.id}`] ? "error" : ""
                }
                value={prices[group.eventPeriodId][item.id]?.value}
                id={`${group.eventPeriodId}-${item.id}`}
                placeholder="Type a price value..."
                onChange={(e) => onChangePrice(e, group.eventPeriodId, item.id)}
              />
              <div className="field-type ">
                <Switch
                  isDisabled={false}
                  isLive={true}
                  id={`display-sponsor-types`}
                  isActive={prices[group.eventPeriodId][item.id]?.active}
                  onChange={(value) => {
                    onChangeActive(value, group.eventPeriodId, item.id);
                  }}
                />
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
};
SubscriptionBody.propTypes = {
  group: PropTypes.object,
  index: PropTypes.number,
  onChangePrice: PropTypes.func,
  onChangeActive: PropTypes.func,
  pricingGroups: PropTypes.array,
  row: PropTypes.any,
  errors: PropTypes.object,
  prices: PropTypes.object,
  pricingFields: PropTypes.array,
  pricingGrous: PropTypes.array,
};
export default SubscriptionBody;
