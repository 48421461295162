import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import { isEmpty } from "@helpers";
import { connect } from "react-redux";
import { hideModal } from "@actions";
import { savePerson } from "@actions/crm";
import { createPerson, updatePerson } from "@actions/sponsors";

class SinglePerson extends React.Component {
  render() {
    return (
      <div className="new-staff form-container">
        <h2>{this.props.value.id ? "Edit Person" : "Create Person"}</h2>
        <Form
          componentName={"sponsors"}
          formName="persons"
          value={this.props.value ? this.props.value : {}}
          onSubmit={(data) => {
            if (isEmpty(data.errors)) {
              if (this.props.h2 === "Edit") {
                this.props.updatePerson(
                  this.props.orgId,
                  this.props.sponsorId,
                  this.props.personId,
                  data.value
                );
              } else {
                this.props.createPerson(
                  this.props.orgId,
                  this.props.sponsorId,
                  data.value
                );
              }
            }
          }}
          onCancel={this.props.hideModal}
        />
      </div>
    );
  }
}

SinglePerson.propTypes = {
  value: PropTypes.any.isRequired,
  h2: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  updatePerson: PropTypes.func.isRequired,
  sponsorId: PropTypes.string,
  personId: PropTypes.number,
};

SinglePerson.defaultProps = {
  value: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    orgId: ownProps.orgId,
    id: ownProps.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    save: (orgId, data, personId = false, contentType = "json", endpoint) => {
      dispatch(savePerson(orgId, data, personId, contentType, endpoint));
    },
    createPerson: (orgId, sponsorId, data) => {
      dispatch(createPerson(orgId, sponsorId, data));
    },
    updatePerson: (orgId, sponsorId, personId, data) => {
      dispatch(updatePerson(orgId, sponsorId, personId, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SinglePerson);
