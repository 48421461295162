import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { api } from "@config/conf";
import SvgPublicPolicy from "@app_icons/PublicPolicy";
import SvgPrivatePolicy from "@app_icons/PrivatePolicy";
import SvgPrivatePolicyCrm from "@app_icons/PrivatePolicyCrm";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { addNotification } from "@actions";
import SvgCopy from "@app_icons/Copy";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const CopyInput = (props) => {
  const [copyMenuOpen, setCopyMenuOpen] = React.useState(false);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(copyMenuOpen);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setCopyMenuOpen(false);
  };

  const handleToggle = () => {
    if (props.disabled) return;
    setCopyMenuOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setCopyMenuOpen(false);
    }
  }

  React.useEffect(() => {
    if (prevOpen.current === true && copyMenuOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = copyMenuOpen;
  }, [copyMenuOpen]);

  const getPolicyIcon = (policy) => {
    if (policy.type === "private") {
      if (policy.saveUsersToCrm) {
        return <SvgPrivatePolicyCrm />;
      } else {
        return <SvgPrivatePolicy />;
      }
    }
    return <SvgPublicPolicy />;
  };

  return (
    <React.Fragment>
      {props.disabled ? (
        <Tooltip title="Save any changes you made to the urlAlias to copy url">
          <Button
            style={{
              minWidth: "40px",
              width: "40px",
            }}
            ref={anchorRef}
            aria-controls={copyMenuOpen ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <SvgCopy className={`copy-action-icon disabled`} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          style={{
            minWidth: "40px",
            width: "40px",
          }}
          ref={anchorRef}
          aria-controls={copyMenuOpen ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <SvgCopy className={`copy-action-icon`} />
        </Button>
      )}

      <Popper
        open={copyMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: { vertical: "top", horizontal: "right" },
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={copyMenuOpen}
                  onKeyDown={handleListKeyDown}
                >
                  {props.policies.map((policy) => {
                    return (
                      <MenuItem
                        key={`url-${policy.id}`}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            `${api.clientUrl}/${props.event.urlAlias}${
                              policy.default ? "" : `/${policy.id}`
                            }`
                          );
                          props.addNotification(
                            `${policy.name} link copied successfully`,
                            "success"
                          );

                          handleClose(e);
                        }}
                      >
                        <Tooltip
                          title={`${api.clientUrl}/${props.event.urlAlias}${
                            policy.default ? "" : `/${policy.id}`
                          }`}
                          placement="right"
                        >
                          <div className="link-copy-option">
                            {getPolicyIcon(policy)}
                            <span>{`Copy ${policy.name} link`}</span>
                          </div>
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

CopyInput.propTypes = {
  policies: PropTypes.array,
  addNotification: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    policies: state.api.accesspolicies?.list?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (msg, status) => dispatch(addNotification(msg, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyInput);
