import { connect } from "react-redux";
import {
  loadExhibitors,
  updateExhibitor,
  createExhibitor,
  deleteExhibitor,
  loadExhibitionDigitalSlots,
  loadExhibitionCategories,
  loadExhibitorPage,
  updateExhibitorPage,
  updateExhibitorBucket,
} from "@actions/exhibitions";
import { showModal, hideModal } from "@actions";
import { getSponsors } from "@actions/sponsors";
import { changeTab } from "@actions/editmenu";
import Exhibitors from "./Exhibitors";
import { formChanged, formCancelled } from "@actions";

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fakeChild: state.editmenu.fakeChild,
    categories: state.api.exhibitionHall.categories.data,
    slots: state.api.exhibitionHall.slots.data,
    sponsors: state.api.sponsors.data,
    exhibitors: state.api.exhibitionHall.exhibitors.data,
    updating: state.api.exhibitionHall.exhibitors.fetching,
    singleUpdating:
      state.api.exhibitionHall.exhibitors.fetching ||
      state.api.sponsors.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadExhibitors: (eventId) => dispatch(loadExhibitors(eventId)),
    updateExhibitor: (eventId, exhibitorId, data) =>
      dispatch(updateExhibitor(eventId, exhibitorId, data)),
    createExhibitor: (eventId, data) =>
      dispatch(createExhibitor(eventId, data)),
    deleteExhibitor: (eventId, exhibitorId) =>
      dispatch(deleteExhibitor(eventId, exhibitorId)),
    changeTab: (tab, fakeChild) => dispatch(changeTab(tab, fakeChild)),
    loadExhibitionDigitalSlots: (eventId) =>
      dispatch(loadExhibitionDigitalSlots(eventId)),
    loadExhibitionCategories: (eventId) =>
      dispatch(loadExhibitionCategories(eventId)),
    formChanged: () => dispatch(formChanged()),
    formCancelled: () => dispatch(formCancelled()),
    getSponsors: (orgId, meta) => dispatch(getSponsors(orgId, meta)),
    showModal: (content, header = "", onSave) => {
      const onExited = () => {};

      const save = () => {
        dispatch(onSave());
      };
      return dispatch(
        showModal(
          content,
          header,
          () => dispatch(hideModal()),
          save,
          onExited,
          undefined,
          true
        )
      );
    },

    hideModal: () => {
      return dispatch(hideModal(false));
    },
    loadExhibitorPage: (eventId, exhibitorId) =>
      dispatch(loadExhibitorPage(eventId, exhibitorId)),

    updateExhibitorPage: (eventId, exhibitorId, data) =>
      dispatch(updateExhibitorPage(eventId, exhibitorId, data)),
    updateExhibitorBucket: (eventId, exhibitorId, data) =>
      dispatch(updateExhibitorBucket(eventId, exhibitorId, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exhibitors);
