import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const MModal = (props) => {
  return (
    <div>
      <Modal show={props.show} onHide={props.onClose} onExited={props.onExited}>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
          <span className="icon-close" onClick={props.onClose}></span>
        </Modal.Header>
        <Modal.Body
          className={`${
            props.className ? `${props.className} ` : ""
          }normal-modal`}
        >
          {props.children}
        </Modal.Body>
        {props.footer && <Modal.Footer>{props.footer}</Modal.Footer>}
      </Modal>
    </div>
  );
};

MModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  onExited: PropTypes.func,
  header: PropTypes.any,
  content: PropTypes.any,
  onClose: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  footer: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
};

MModal.defaultProps = {
  show: false,
  header: "",
  content: "",
  footer: "",
  className: "",
};

export default MModal;
