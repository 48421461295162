import React from "react";
import Table from "@layout/Table";
import { isEmpty } from "@helpers";
import Switch from "@layout/Switch";
import TableActions from "@layout/TableActions";
import SingleSpeech from "./SingleSpeech/";
import PropTypes from "prop-types";
import moment from "moment";
import QuickChange from "@layout/QuickChange";
import ApiHelper from "@helpers/api-helper";

export default class Speeches extends React.Component {
  constructor(props) {
    super(props);
    this.speechesColumns = {
      name: {
        name: "Name",
      },
      speakers: {
        name: "Speaker",
      },
      startDate: {
        name: "Start Date",
      },
      endDate: {
        name: "End Date",
      },
      status: {
        name: "Status",
      },

      actions: {
        name: "",
      },
    };

    ["onRender", "onQuickChange"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return <span className="icon-sessions-1"></span>;
      }

      case "speechFiles": {
        return col.value.length ? "Yes" : "No";
      }

      case "videoFile":
      case "download": {
        return isEmpty(col.value) ? "No" : col.value;
      }

      case "active": {
        return (
          <Switch
            id={`speech-active-${item.id}`}
            isActive={col.value}
            onChange={(value) =>
              this.props.saveSpeech(
                this.props.eventId,
                this.props.session.id,
                { active: value },
                item.id,
                undefined,
                undefined,
                true
              )
            }
          />
        );
      }

      case "actions": {
        return (
          <TableActions
            entity="speech"
            item={item}
            onEdit={() =>
              this.props.showModal(
                <SingleSpeech
                  sessionId={this.props.session.id}
                  speechId={item.id}
                />
              )
            }
            onDelete={() =>
              this.props.deleteItem(
                this.props.eventId,
                this.props.session.id,
                item.id
              )
            }
          />
        );
      }
      case "speakers": {
        if (isEmpty(col.value)) {
          return "";
        }
        return (
          <p className="speaker">
            {col.value.map((s) => `${s.firstName} ${s.lastName}`).join(", ")}
          </p>
        );
      }
      case "startDate":
      case "endDate": {
        return (
          <div>
            <p>{moment.utc(col.value.tz).format("DD MMMM Y")}</p>
            <span className="hour">
              {moment.utc(col.value.tz).format("HH:mm")}
            </span>
            <QuickChange
              onChange={(event) =>
                this.onQuickChange(col.value.tz, event, item, col.key)
              }
            />
          </div>
        );
      }
    }
  }

  onQuickChange(originalDate, event, item, key) {
    const additionalKey = key === "startDate" ? "endDate" : "startDate";
    const { mode, minutes } = event;
    const m = moment(originalDate);
    const newDate = m[mode]("minutes", minutes).format(ApiHelper.dateFormat);
    this.props.saveSpeech(
      this.props.eventId,
      this.props.session.id,
      {
        [key]: newDate,
        [additionalKey]: moment(item[additionalKey].tz).format(
          ApiHelper.dateFormat
        ),
      },
      item.id,
      undefined,
      undefined,
      true
    );
  }

  render() {
    const speeches = this.props.session.speeches;

    return (
      <div className={`speeches${speeches.length === 0 ? " empty" : ""}`}>
        <div className="speech-header">
          <h3 className="title">Speeches</h3>
          <button
            className="btn table-header transparent"
            onClick={() =>
              this.props.showModal(
                <SingleSpeech
                  sessionId={this.props.session.id}
                  speechId={null}
                  meta={this.props.meta}
                />
              )
            }
          >
            <span className="icon-add-1"></span>Add new speech
          </button>
        </div>
        {
          <Table
            id={"speeches"}
            columns={this.speechesColumns}
            items={speeches}
            onRender={this.onRender}
            containerClassName={`table-container no-results-blue`}
          />
        }
      </div>
    );
  }
}

Speeches.propTypes = {
  eventId: PropTypes.number.isRequired,
  meta: PropTypes.object,
  session: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  saveSpeech: PropTypes.func.isRequired,
};
