import { connect } from "react-redux";
import Charts from "./Charts";
import { loadStatistics, addNotification } from "@actions";
import { loadRooms } from "@actions/users";

const mapStateToProps = (state) => {
  return {
    eventId: state.page.params.eventId,
    statistics: state.api.events.statistics,
    fetching: state.api.events.statistics.fetching || state.rooms.fetching,
    rooms: state.rooms,
    apiUrl: state.api.configuration.url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadStatistics: (eventId, socketFetch = false) => {
      return new Promise((resolve, reject) => {
        dispatch(loadStatistics(eventId, socketFetch))
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      });
    },

    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },

    loadRooms: (eventId) => {
      return new Promise((resolve, reject) => {
        dispatch(loadRooms(eventId))
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
