import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { rooms } = this.props;
    return (
      <div className="row">
        {rooms.map((r) => {
          return (
            <div key={r.id} className="room-wrapper col-sm-4">
              <div className={`inner${r.lockScanner ? " locked" : ""}`}>
                <h3>{r.name}</h3>
                <p>
                  Open Scanners: <span>{r.panelActive}</span>
                </p>
                <p>
                  Scanner Locked: <span>{r.lockScanner ? "Yes" : "No"}</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

Dashboard.propTypes = {
  rooms: PropTypes.array,
};

export default connect((state) => {
  return { rooms: state.rooms.data };
}, {})(Dashboard);
