import React from "react";
export default () => (
  <svg
    width="120"
    height="32"
    viewBox="0 0 120 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_861_71950)">
      <path
        d="M14.4209 20.535L17.306 18.9409L20.181 20.535L17.302 22.1234L14.4209 20.535Z"
        fill="#CA9E67"
      />
      <path
        d="M14.4209 28.6442V20.5352L17.302 22.1235V30.248L14.4209 28.6442Z"
        fill="#BB8954"
      />
      <path
        d="M20.1086 28.5923L20.1808 20.5352L17.3018 22.1235V30.248L20.1086 28.5923Z"
        fill="#3E2413"
      />
      <path
        d="M6.80078 11.9107L17.3158 7.15137L27.8008 11.9107L17.3018 16.6507L6.80078 11.9107Z"
        fill="#14A9ED"
      />
      <path
        d="M6.80078 21.6004V11.9106L17.3018 16.6507V26.3923L6.80078 21.6004Z"
        fill="#0A71B8"
      />
      <path
        d="M27.8008 21.6027V11.9102L17.3018 16.6507V26.3923L27.8008 21.6027Z"
        fill="#132B3B"
      />
      <path
        d="M10.8193 6.3268L17.3097 3.62891L23.7821 6.3268L17.3017 9.017L10.8193 6.3268Z"
        fill="#14A9ED"
      />
      <path
        d="M10.8193 11.8224V6.32666L17.3017 9.01686V14.5415L10.8193 11.8224Z"
        fill="#0A71B8"
      />
      <path
        d="M23.6176 11.734L23.7821 6.32666L17.3018 9.01686V14.5415L23.6176 11.734Z"
        fill="#132B3B"
      />
      <path
        d="M10.8193 6.3268L17.3097 3.62891L23.7821 6.3268L17.3017 9.017L10.8193 6.3268Z"
        fill="#14A9ED"
      />
      <path
        d="M10.8193 11.8224V6.32666L17.3017 9.01686V14.5415L10.8193 11.8224Z"
        fill="#0A71B8"
      />
      <path
        d="M23.6176 11.734L23.7821 6.32666L17.3018 9.01686V14.5415L23.6176 11.734Z"
        fill="#132B3B"
      />
      <path
        d="M14.0596 3.59991L17.3058 2.25L20.5399 3.59991L17.3018 4.94405L14.0596 3.59991Z"
        fill="#14A9ED"
      />
      <path
        d="M14.0596 6.34799V3.6001L17.3018 4.94424V7.70559L14.0596 6.34799Z"
        fill="#0A71B8"
      />
      <path
        d="M20.4597 6.30376L20.5399 3.6001L17.3018 4.94424V7.70559L20.4597 6.30376Z"
        fill="#132B3B"
      />
    </g>
    <path
      d="M48.5421 15.0984C49.2075 14.5475 49.5594 13.7201 49.5594 12.6766C49.5594 10.578 48.1352 9.32422 45.7283 9.32422H40.3487C40.2122 9.32422 40.0813 9.37611 39.9848 9.46848C39.8882 9.56085 39.834 9.68613 39.834 9.81676V22.0329C39.834 22.1636 39.8882 22.2888 39.9848 22.3812C40.0813 22.4736 40.2122 22.5255 40.3487 22.5255H45.9073C50.1393 22.5255 50.77 20.0453 50.77 18.5676C50.77 16.8992 50.003 15.7156 48.5421 15.0984ZM41.5349 10.9167H45.6367C47.126 10.9167 47.8198 11.4871 47.8198 12.7117C47.8198 13.9868 47.1627 14.5572 45.6916 14.5572H41.5349V10.9167ZM45.9073 20.933H41.5349V16.1322H45.7262C47.9521 16.1322 49.0325 16.9284 49.0325 18.5676C49.0325 20.2068 48.0701 20.933 45.9073 20.933Z"
      fill="white"
    />
    <path
      d="M52.337 8.91357H52.9881C53.1252 8.91357 53.2566 8.96567 53.3535 9.05841C53.4504 9.15114 53.5049 9.27692 53.5049 9.40806V22.473C53.5049 22.6037 53.4507 22.729 53.3541 22.8213C53.2576 22.9137 53.1267 22.9656 52.9901 22.9656H52.337C52.2694 22.9656 52.2025 22.9528 52.14 22.9281C52.0776 22.9033 52.0208 22.8671 51.973 22.8213C51.9252 22.7756 51.8873 22.7213 51.8614 22.6615C51.8356 22.6018 51.8223 22.5377 51.8223 22.473V9.40611C51.8223 9.34143 51.8356 9.27739 51.8614 9.21763C51.8873 9.15787 51.9252 9.10357 51.973 9.05784C52.0208 9.0121 52.0776 8.97582 52.14 8.95107C52.2025 8.92631 52.2694 8.91357 52.337 8.91357Z"
      fill="white"
    />
    <path
      d="M64.5622 12.6377H63.9111C63.774 12.6377 63.6426 12.6898 63.5457 12.7825C63.4488 12.8753 63.3943 13.001 63.3943 13.1322V20.4132C62.5805 21.0732 61.2641 21.4937 60.0169 21.4937C57.8724 21.4937 57.0748 20.6975 57.0748 18.556V13.1322C57.0748 13.001 57.0204 12.8753 56.9234 12.7825C56.8265 12.6898 56.6951 12.6377 56.558 12.6377H55.9191C55.7821 12.6377 55.6506 12.6898 55.5537 12.7825C55.4568 12.8753 55.4023 13.001 55.4023 13.1322V18.556C55.4023 21.6455 56.8551 23.0862 59.9741 23.0862C61.1709 23.0728 62.345 22.7715 63.3882 22.2101V22.471C63.3887 22.6018 63.4434 22.7271 63.5403 22.8194C63.6371 22.9117 63.7683 22.9635 63.905 22.9635H64.5561C64.6928 22.9635 64.8239 22.9117 64.9208 22.8194C65.0176 22.7271 65.0723 22.6018 65.0729 22.471V13.1263C65.0713 12.9972 65.017 12.8738 64.9215 12.7825C64.8261 12.6912 64.6971 12.6392 64.5622 12.6377Z"
      fill="white"
    />
    <path
      d="M71.8782 12.5171C68.6941 12.5171 66.3848 14.7209 66.3848 17.7579C66.3848 20.9448 68.6513 23.0863 72.0247 23.0863C74.2119 23.0863 75.687 22.4711 76.8142 21.0947C76.8977 20.9912 76.9351 20.8603 76.9183 20.7306C76.9016 20.6008 76.832 20.4826 76.7247 20.4016L76.2181 20.022C76.1113 19.942 75.976 19.9053 75.8413 19.9199C75.7065 19.9344 75.5832 19.9991 75.4978 20.0999C74.6677 21.0889 73.6667 21.4938 72.039 21.4938C69.9209 21.4938 68.4499 20.3588 68.1366 18.5113H76.8203C76.9567 18.5108 77.0873 18.4587 77.1837 18.3665C77.2801 18.2742 77.3345 18.1492 77.3351 18.0188V17.7929C77.3371 14.6372 75.1438 12.5171 71.8782 12.5171ZM68.163 16.948C68.4947 15.2348 69.9514 14.1037 71.8782 14.1037C73.8498 14.1037 75.2414 15.1862 75.571 16.948H68.163Z"
      fill="white"
    />
    <path
      d="M88.3555 9.76227H78.3859C78.2495 9.76278 78.1189 9.81484 78.0225 9.9071C77.926 9.99935 77.8716 10.1243 77.8711 10.2548V10.8622C77.8716 10.9927 77.926 11.1177 78.0225 11.2099C78.1189 11.3022 78.2495 11.3542 78.3859 11.3548H82.508V22.471C82.508 22.6016 82.5622 22.7269 82.6588 22.8193C82.7553 22.9116 82.8862 22.9635 83.0227 22.9635H83.7104C83.8471 22.9635 83.9783 22.9117 84.0751 22.8194C84.172 22.7271 84.2267 22.6018 84.2272 22.471V11.3548H88.3656C88.502 11.3542 88.6326 11.3022 88.729 11.2099C88.8254 11.1177 88.8799 10.9927 88.8804 10.8622V10.2548C88.8801 10.1894 88.8663 10.1246 88.8397 10.0644C88.8131 10.0041 88.7743 9.94945 88.7254 9.90363C88.6766 9.85782 88.6188 9.82174 88.5553 9.79747C88.4918 9.7732 88.4238 9.76124 88.3555 9.76227Z"
      fill="white"
    />
    <path
      d="M95.8411 14.016V13.3054C95.8413 13.2121 95.814 13.1208 95.7621 13.0418C95.7102 12.9629 95.636 12.8997 95.5481 12.8596C95.0073 12.6223 94.4175 12.5052 93.8227 12.5169C93.2954 12.511 92.7726 12.6105 92.2877 12.8089C91.8029 13.0074 91.3666 13.3005 91.0068 13.6694V13.1321C91.0068 13.0013 90.9527 12.8758 90.8562 12.7831C90.7597 12.6905 90.6288 12.6381 90.4921 12.6376H89.839C89.7023 12.6381 89.5713 12.6905 89.4749 12.7831C89.3784 12.8758 89.3242 13.0013 89.3242 13.1321V22.4767C89.3242 22.6074 89.3785 22.7327 89.475 22.825C89.5715 22.9174 89.7024 22.9693 89.839 22.9693H90.4921C90.6286 22.9693 90.7595 22.9174 90.8561 22.825C90.9526 22.7327 91.0068 22.6074 91.0068 22.4767V17.637C91.0068 15.6454 92.1869 14.1444 93.7515 14.1444C94.2227 14.1372 94.6878 14.2458 95.1025 14.4598C95.181 14.4961 95.2679 14.5127 95.3549 14.5081C95.4419 14.5034 95.5263 14.4777 95.6001 14.4334C95.6739 14.389 95.7348 14.3274 95.7769 14.2544C95.8191 14.1814 95.8411 14.0994 95.8411 14.016Z"
      fill="white"
    />
    <path
      d="M101.566 12.5171C98.3816 12.5171 96.0723 14.7209 96.0723 17.7579C96.0723 20.9448 98.3388 23.0863 101.712 23.0863C103.899 23.0863 105.375 22.4711 106.502 21.0947C106.585 20.9912 106.622 20.8607 106.606 20.7311C106.59 20.6015 106.521 20.4832 106.414 20.4016L105.906 20.022C105.799 19.942 105.664 19.9053 105.529 19.9199C105.394 19.9344 105.271 19.9991 105.185 20.0999C104.355 21.0889 103.354 21.4938 101.726 21.4938C99.6084 21.4938 98.1374 20.3588 97.8241 18.5113H106.508C106.644 18.5108 106.775 18.4587 106.871 18.3665C106.968 18.2742 107.022 18.1492 107.023 18.0188V17.7929C107.025 14.6372 104.831 12.5171 101.566 12.5171ZM97.8526 16.948C98.1822 15.2348 99.6389 14.1037 101.566 14.1037C103.537 14.1037 104.929 15.1862 105.259 16.948H97.8526Z"
      fill="white"
    />
    <path
      d="M113.802 12.5171C110.618 12.5171 108.309 14.7209 108.309 17.7579C108.309 20.9448 110.575 23.0863 113.949 23.0863C116.136 23.0863 117.611 22.4711 118.738 21.0947C118.821 20.9912 118.859 20.8603 118.842 20.7306C118.825 20.6008 118.756 20.4826 118.649 20.4016L118.142 20.022C118.035 19.9425 117.9 19.9061 117.765 19.9206C117.631 19.9352 117.507 19.9995 117.422 20.0999C116.592 21.0889 115.59 21.4938 113.963 21.4938C111.845 21.4938 110.374 20.3588 110.06 18.5113H118.742C118.879 18.5113 119.01 18.4595 119.107 18.3671C119.204 18.2748 119.258 18.1496 119.259 18.0188V17.7929C119.261 14.6372 117.068 12.5171 113.802 12.5171ZM110.089 16.948C110.418 15.2348 111.875 14.1037 113.802 14.1037C115.774 14.1037 117.165 15.1862 117.495 16.948H110.089Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_861_71950">
        <rect
          width="21"
          height="28"
          fill="white"
          transform="translate(6.80078 2.25)"
        />
      </clipPath>
    </defs>
  </svg>
);
