import React from "react";
import { connect } from "react-redux";
import KMBEditor from "@layout/KMBEditor";
import Recipients from "@layout/Recipients";
import Select2 from "@layout/Select2";
import Confirm from "@layout/Confirm";
import Confirmer from "@layout/Confirmer";
import PropTypes from "prop-types";
import DeleteIcon from "@app_icons/DeleteIcon";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { formChanged } from "@actions";
import { scheduledEmails } from "../../helpers/scheduled-email-helper";
import moment from "moment-timezone";
import Switch from "@layout/Switch";
import BottomLineInput from "@layout/BottomLineInput";
import { addNotification } from "../../actions";

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      schedulingWizard: false,
      wordConfirmed: false,
    };
  }

  deleteEmail(id) {
    const deleteBody = (
      <div>
        <p>
          This action will permanently delete the email with ID:{" "}
          <strong>{id}</strong>
        </p>
        <p>Are you sure that you want to delete this email?</p>
        <p>If yes, then please type: CONFIRM DELETE</p>
        <Confirmer
          word="confirm delete"
          onSuccess={() => {
            this.setState({ wordConfirmed: true });
          }}
          onFail={() => {
            if (this.state.wordConfirmed) {
              this.setState({ wordConfirmed: false });
            }
          }}
        />
      </div>
    );

    const deletedItem = (
      <Confirm
        onConfirm={() => {
          this.setState(
            {
              wordConfirmed: false,
            },
            () => {
              this.props.deleteEmail();
            }
          );
        }}
        body={deleteBody}
        className="delete-event"
        confirmText="Confirm Delete"
        disabled={this.state.wordConfirmed ? false : true}
        title="Delete Action! Are you sure?"
        onClose={() => {
          this.setState({ wordConfirmed: false });
        }}
      >
        <div
          style={{
            height: "40px",
            position: "absolute",
            right: "0px",
            top: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "18px",
          }}
        >
          <DeleteIcon
            style={{
              cursor: "pointer",
            }}
          ></DeleteIcon>
        </div>
      </Confirm>
    );

    return deletedItem;
  }

  computeLastScheduled(nextDate) {
    const timezoned_timestamp_now = moment()
      .utc()
      .tz(this.props.event.timezone);

    const timezonelessNextDate = moment(
      nextDate.format("Do MMMM  YY - HH:mm"),
      "Do MMMM  YY - HH:mm"
    );
    const timezonelessNow = moment(
      timezoned_timestamp_now.format("Do MMMM  YY - HH:mm"),
      "Do MMMM  YY - HH:mm"
    );

    const diff = timezonelessNextDate.diff(timezonelessNow);
    const m = moment.duration(diff);

    return `${nextDate.format("Do MMMM  YY - HH:mm")} (sent in ${
      m.years() > 0 ? `${m.years()} years,` : ``
    } ${
      m.months() > 0 ? `${m.months()} months,` : ``
    } ${m.days()} days, ${m.hours()} hours, and ${m.minutes()} minutes)`;
  }

  returnPastSchedulIndication() {
    let hasDateInPast = false;

    this.props.scheduling.forEach((scheduling_data) => {
      if (scheduledEmails.isDateInThePast(scheduling_data, this.props.event)) {
        hasDateInPast = true;
      }
    });
    return hasDateInPast ? (
      <Tooltip title="This email has invalid scheduled dates, because one or more of the scheduled dates are in the past. Please re-schedule those dates.">
        <span className="past-date-warning icon-notification-1 email-positioner" />
      </Tooltip>
    ) : (
      ""
    );
  }

  getExtraSettings() {
    return (
      this.props.accessPolicies?.find(
        (policy) => policy.id == this.props.template.eventPolicyId
      )?.extraSettings || {}
    );
  }

  validateEmailDetails = () => {
    const { subject } = this.props.template;
    const contacts = this.props.contacts;
    const errorMessages = [];

    if (!subject) {
      errorMessages.push("Error: Subject is required.");
    }
    if (!Array.isArray(contacts) || contacts.length === 0) {
      errorMessages.push("Error: At least one recipient is required.");
    }
    return {
      isValid: errorMessages.length === 0,
      errorMessages,
    };
  };

  handleSave = () => {
    const validation = this.validateEmailDetails();

    if (validation.isValid) {
      this.props.onSave();
    } else {
      validation.errorMessages.forEach((errorMessage) => {
        addNotification(errorMessage, "error");
      });
    }
  };

  render() {
    const {
      name = "",
      eventPolicyId = "",
      meta = [],
      subject = "",
      bccAddress = "",
      replyTo = "",
      html = "",
      id,
    } = this.props.template;

    const extraSettings = this.getExtraSettings();

    return (
      <div className="kmb-emails">
        <div className="header">
          {this.props.showName ? (
            <BottomLineInput
              className={"header-input"}
              value={name}
              placeholder=""
              placeholderPosition="end"
              placeholderClassName="icon icon-edit"
              onChange={(e) =>
                this.props.onChange({
                  ...this.props.template,
                  name: e.target.value,
                })
              }
            />
          ) : (
            <span>Email ID: {id}</span>
          )}
          {this.props.delete && this.deleteEmail(id)}
        </div>
        <div className="main">
          {typeof this.props.accessPolicies !== "undefined" && (
            <div className="email-row">
              <div className="row-container half-container select-container">
                <label className="row-title subject-title">
                  Select a policy
                </label>
                <Select2
                  options={this.props.accessPolicies
                    .map((policy) => ({ [policy.id]: policy.name }))
                    .reduce((obj, record) => ({ ...obj, ...record }), {})}
                  onChange={(val) => {
                    this.props.onChange({
                      ...this.props.template,
                      eventPolicyId: val,
                      meta: [],
                    });
                  }}
                  placeholder={"Select Policy"}
                  value={eventPolicyId}
                />
              </div>
              <div className="row-container half-container external-select-container">
                <label className="row-title subject-title">
                  Select fields to include in the questionnaire
                </label>

                <Select
                  labelId="demo-mutiple-chip-label"
                  IconComponent={ExpandMoreOutlinedIcon}
                  id="demo-mutiple-chip"
                  multiple
                  value={meta}
                  onChange={(val) => {
                    this.props.onChange({
                      ...this.props.template,
                      meta: val.target.value,
                    });
                    this.props.formChanged();
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => {
                    if (selected.target?.value) {
                      selected = selected.target.value;
                    }
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {selected.map((value) => {
                          if (!extraSettings[value]?.name) {
                            return null;
                          }
                          return (
                            <Chip
                              key={value}
                              label={extraSettings[value].name}
                              style={{
                                margin: 2,
                              }}
                            />
                          );
                        })}
                      </div>
                    );
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,

                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: 250,
                        overflow: "auto",
                      },
                    },
                  }}
                >
                  {Object.entries(extraSettings)
                    .map(([key, value]) => ({ [key]: value.name }))
                    .map((option) => {
                      return (
                        <MenuItem
                          key={Object.entries(option)[0][0]}
                          value={Object.entries(option)[0][0]}
                          style={{ fontSize: "14px", padding: "10px 16px" }}
                        >
                          {Object.entries(option)[0][1]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </div>
          )}
          <div className="email-row">
            <div className="row-container">
              <div className="row-title subject-title">Subject*</div>
              <input
                className="row-field subject-field"
                value={subject}
                placeholder="Please type a 'Subject' for the email (you can use variables)"
                onChange={(e) => {
                  this.props.onChange({
                    ...this.props.template,
                    subject: e.target.value,
                  });
                  this.props.formChanged();
                }}
              ></input>
            </div>
          </div>
          <div className="email-row">
            <Recipients
              addRecipient={() => {
                this.props.formChanged();
                this.props.addContact();
              }}
              editRecipient={(child, index) => {
                this.props.formChanged();
                this.props.editContact(
                  { ...this.props.template, contacts: this.props.contacts },
                  child,
                  index
                );
              }}
              removeRecipient={(index) => {
                this.props.formChanged();
                this.props.deleteContact(index);
              }}
              contactVariables={this.props.contactVariables}
              showDeleteModal={false}
              // eslint-disable-next-line react/no-children-prop
              children={this.props.contacts}
            />
          </div>
          <div className="email-row">
            <div className="row-container half-container">
              <div className="row-title subject-title">Bcc</div>
              <input
                className="row-field subject-field"
                value={bccAddress}
                placeholder="Bcc: Please type a 'Bcc address' to receive an exact copy for every email"
                onChange={(e) => {
                  this.props.formChanged();
                  this.props.onChange({
                    ...this.props.template,
                    bccAddress: e.target.value,
                  });
                }}
              ></input>
            </div>
            <div className="row-container half-container">
              <div className="row-title subject-title">Reply to</div>
              <input
                className="row-field subject-field"
                value={replyTo}
                placeholder={`Reply To: ${
                  this.props.defaultReplyTo
                    ? `Leave empty to use Event Support Email (${this.props.defaultReplyTo})`
                    : "Please type a 'Relpy To' email address "
                }`}
                onChange={(e) =>
                  this.props.onChange({
                    ...this.props.template,
                    replyTo: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
          <div className="divider"></div>
          <div className="editor-label">Preview content</div>
          <KMBEditor
            placeholder={
              "Type the content of the email. Note that you can use the 'Variables' Toolbar Button to add dynamic content "
            }
            rows={50}
            value={html}
            variables={this.props.variables}
            onChange={(data) => {
              this.props.formChanged();
              this.props.onChange({ ...this.props.template, html: data });
            }}
          />
          {this.props.questionnaire && (
            <div className="locked-danger-zone">
              <div className="danger-zone-title">Danger Zone</div>
              <div className="danger-zone-subtitle">
                Careful! Unlocking and editing this questionnaire may cause
                inconsistentency on the distributed emails. Emails that already
                received a copy of this event will be excluded from the updated
                version if you decide to send it again.
              </div>
              <div className="danger-zone-actions">
                <div className="form-group">
                  <div className="field-type type-switch kmb-switch clearfix ">
                    <label style={{ flex: 1, margin: 0 }}>
                      Unlock Questionnaire
                    </label>
                    <div className="switch-holder">
                      <Switch
                        isActive={this.props.locked}
                        onChange={() => {
                          this.props.formChanged();
                          this.props.toggleLocked();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="email-actions-wrapper" style={{ textAlign: "right" }}>
            <button
              type="submit"
              className="btn save rounded email-save-button"
              onClick={this.handleSave}
            >
              Save email
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Email.propTypes = {
  deleteEmail: PropTypes.func,
  showModal: PropTypes.func,
  template: PropTypes.object,
  showName: PropTypes.bool,
  onChange: PropTypes.func,
  toggleLocked: PropTypes.func,
  getPresetEmailScheduling: PropTypes.func,
  delete: PropTypes.bool,
  accessPolicies: PropTypes.array,
  contactVariables: PropTypes.object,
  contacts: PropTypes.array,
  addContact: PropTypes.func,
  deleteContact: PropTypes.func,
  editContact: PropTypes.func,
  defaultReplyTo: PropTypes.string,
  variables: PropTypes.array,
  onSave: PropTypes.func,
  locked: PropTypes.bool,
  showLocked: PropTypes.bool,
  preset: PropTypes.bool,
  questionnaire: PropTypes.bool,
  sendQuestionnaire: PropTypes.func,
  sendEmail: PropTypes.func,
  formChanged: PropTypes.func,
  eventId: PropTypes.number,
  orgId: PropTypes.number,
  scheduling: PropTypes.array,
  event: PropTypes.object,
};
Email.defaultProps = {
  placeholder: `Type the content of the email. Use any variables you want, for dynamic injection.`,
  variables: [],
  template: {},
  delete: true,
};

const mapStateToProps = (state) => {
  return {
    orgId: state.appuser.data.orgId,
    eventId: state.api.events.edit.data.id,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    formChanged: (form) => {
      dispatch(formChanged(form));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
