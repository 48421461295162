import React from "react";
import { connect } from "react-redux";
import { getJudgeAbstracts, viewAbstract } from "@actions/judge";
import Table from "@layout/Table";
import PropTypes from "prop-types";
import { getAbstractInternalStatus } from "@helpers";
import moment from "moment";
import Search from "@layout/Search";
import Select from "@layout/Select2";
import { HeaderPagination } from "@layout/Pagination";
const AWAITING_RESUBMISSION = "awaiting_resubmission";
const AWAITING_OR_UNDER_REVIEW = "awaiting_or_under_review";
const RATED = "rated";
const SUBMITTED = "submitted";

class Abstracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
    };
    this.columns = {
      id: {
        name: "ID",
        sortable: true,
      },
      name: {
        name: "Abstract Title",
        sortable: true,
      },
      eventName: {
        name: "Event",
      },
      deadline: {
        name: "Deadline Ends (UTC +00:00)",
      },
      internalStatus: {
        name: "Status",
      },
      score: {
        name: "Score",
      },
    };
  }

  componentDidMount() {
    const { getJudgeAbstracts } = this.props;
    ["onRender"].forEach((fn) => (this[fn] = this[fn].bind(this)));
    getJudgeAbstracts();
  }

  onRender(col, item) {
    switch (col.key) {
      case "cosmetic": {
        return <span className="icon-sessions-1"></span>;
      }

      case "score": {
        if (col.value) {
          return `${col.value} / ${item.maxScore}`;
        }
        return null;
      }

      case "internalStatus": {
        const status = getAbstractInternalStatus(item);
        const className = status
          .toLowerCase()
          .replace(/\//g, "")
          .replace(/ /g, "-");
        return <span className={`internal-status ${className}`}>{status}</span>;
      }

      case "deadline": {
        const diff = moment(item.judgingEndDate.utc).diff(moment.utc());
        const m = moment.duration(diff);
        return (
          <span className="deadline">
            {`${item.judgingEndDate.utc} (${m.days()}d ${m.hours()}h)`}
            {m.days() < 3 && <span className="icon-notification-1"></span>}
          </span>
        );
      }
    }
  }
  findIfDifferent(val, identifier) {
    let newVal = 0;
    if (identifier === "rpp") {
      if (this.props.meta.rpp !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.meta.rpp;
      }
    } else if (identifier === "p") {
      if (this.props.meta.p !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.meta.p;
      }
    } else if (identifier === "totalRows") {
      if (this.props.meta.totalRows !== val && val !== undefined) {
        newVal = val;
      } else {
        newVal = this.props.meta.totalRows;
      }
    }
    return newVal;
  }
  onTrClassName(className, item) {
    if (item.rated === 1) {
      className += " rated";
    }

    return className.trim();
  }
  getAllEvents() {
    return Object.entries(this.props?.events || {})
      .map(([eventId, accessEvent]) => ({
        value: eventId,
        label: accessEvent.name,
      }))
      .reduce((acc, cur) => {
        acc[cur.value] = cur.label;
        return acc;
      }, {});
  }
  handleStatusChange = (status) => {
    const searchParams = { status: "", rated: "0", p: 1 };
    // both awaiting_review and under_review should show the same list but we do a frontend check in the render
    if (status === AWAITING_OR_UNDER_REVIEW) {
      searchParams.status = SUBMITTED;
      searchParams.rated = "0";
    } else if (status === RATED) {
      searchParams.rated = "1";
    } else if (status === AWAITING_RESUBMISSION) {
      searchParams.status = AWAITING_RESUBMISSION;
    }

    return this.props.getJudgeAbstracts(searchParams);
  };

  getValueFromMeta = () => {
    if (
      this.props.meta.status[0] === SUBMITTED &&
      this.props.meta.rated == "0"
    ) {
      return AWAITING_OR_UNDER_REVIEW;
    } else if (this.props.meta.rated == "1") {
      return RATED;
    } else if (this.props.meta.status[0] === AWAITING_RESUBMISSION) {
      return AWAITING_RESUBMISSION;
    }
    return "";
  };

  render() {
    return (
      <div className="judge-listing">
        <div className="top-bar">
          <div>
            <div className="left">
              <Search
                onSearch={(search) => this.props.getJudgeAbstracts({ search })}
                timeout={200}
                queryString={this.props.meta.search}
                reset={this.state.reset}
              />
            </div>
          </div>
          <div>
            <div className="left">
              <Select
                isSearchable={false}
                border={"none"}
                placeholder={"Filter by status"}
                onChange={this.handleStatusChange}
                value={this.getValueFromMeta()}
                options={{
                  awaiting_or_under_review: "Awaiting or Under Review",
                  awaiting_resubmission: "Awaiting Resubmission",
                  rated: "Rated",
                }}
              />
              <Select
                isSearchable={false}
                border={"none"}
                placeholder={"Filter by event"}
                onChange={(eventId = "") =>
                  this.props.getJudgeAbstracts({ eventId, p: 1 })
                }
                value={this.props?.meta?.eventId}
                options={this.getAllEvents()}
              />
            </div>
            <div className="right">
              <HeaderPagination
                p={parseInt(this.props.meta.p)}
                rpp={parseInt(this.props.meta.rpp)}
                totalRows={this.props.meta.totalRows}
                onChangePage={({ p, rpp }) =>
                  this.props.getJudgeAbstracts({ p, rpp })
                }
              />
            </div>
          </div>
        </div>
        <div className="list-view">
          <div className="main-wrapper">
            <Table
              id={"abstracts"}
              items={this.props.abstracts}
              columns={this.columns}
              onRender={this.onRender}
              updating={this.props.fetching}
              onTrClick={(item) =>
                this.props.page.navigate(
                  `/judge/abstracts/${item.eventId}/${item.id}`
                )
              }
              onTrClassName={this.onTrClassName}
              onSort={(orderBy) => this.props.getJudgeAbstracts({ orderBy })}
              containerClassName={`table-container `}
            />
          </div>
        </div>
      </div>
    );
  }
}

Abstracts.propTypes = {
  getJudgeAbstracts: PropTypes.func,
  abstracts: PropTypes.array,
  fetching: PropTypes.bool,
  viewAbstract: PropTypes.func,
  meta: PropTypes.object,
  extra: PropTypes.string,
  events: PropTypes.object,
  page: PropTypes.object,
};

export default connect(
  (state) => {
    return {
      page: state.page,
      abstracts: state.api.judge.list.data,
      fetching: state.api.judge.list.fetching,
      meta: state.api.judge.list.meta,
      extra: state.api.judge.list.extra,
      events: state.appuser.data.accessEvents,
    };
  },
  { getJudgeAbstracts, viewAbstract }
)(Abstracts);
