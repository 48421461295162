import React from "react";
import PropTypes from "prop-types";
import Select2 from "@layout/Select2";
import WarningBar from "@layout/WarningBar";
import Checkbox from "../../../../../layout/Checkbox";

const PricingHeader = (props) => {
  const [allFields, setAllFields] = React.useState({});
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false);

  React.useEffect(() => {
    const newAllFields = {};
    for (const i in props.pricingFields) {
      if (
        props.selectedBypassGroups.includes(String(props.pricingFields[i].id))
      )
        continue;
      if (props.pricingFields[i].required && props.pricingFields[i].askUser)
        newAllFields[props.pricingFields[i].id] = props.pricingFields[i].name;
    }
    setAllFields(newAllFields);
  }, [props.pricingFields, props.selectedBypassGroups]);

  const setSelectedSubSection = (section) => {
    const newSectionOptions = { ...props.allOptions };
    delete newSectionOptions[section];

    props.onChangeSubsection(section, props.allOptions[section]);
  };

  const groupOptions = {};
  for (const key in allFields) {
    if (props.selectedPricingFields.includes(key)) {
      groupOptions[key] = allFields[key];
    }
  }
  const getOptions = (field) => {
    return field.type === "yes_no"
      ? [
          { key: 1, label: "Yes" },
          { key: 0, label: "No" },
        ]
      : field.value.map((value) => ({
          key: value.id,
          label: value.value,
        }));
  };

  const handleOnChangeCheckBox = (key) => {
    let newExceptions = [];
    if (!props.exceptions.includes(key)) {
      newExceptions = [...props.exceptions, key];
    } else {
      newExceptions = props.exceptions.filter((exception) => exception !== key);
    }

    props.calculateNewGroups(props.selectedPricingFields, newExceptions);
  };
  const selectedPricingFieldsValues = Object.values(
    props.selectedPricingFields || {}
  );
  return (
    <div className="pricing">
      {!props.hasBilling && (
        <WarningBar
          message="No payment method has been set. Please go to payments and select at least one payment method."
          buttonText={"Go to Payments"}
          onClick={() => {
            props.changeSection("payments");
            props.changeTab("PaymentOptions");
          }}
        />
      )}

      <h3>Apply zero Subscription Fees</h3>
      <div className="form-container">
        <div className="item form-group col-sm-12 col-xs-12 ">
          <label>
            {`Select fields of type 'yes/no' with zero subscription fees (surpasses
            the subscription fees model)`}
          </label>
          <Select2
            multi={true}
            isSearchable={false}
            isClearable={false}
            className="input "
            options={props.bypassGroups}
            onChange={(val) => props.calculateBypassGroups(val)}
            placeholder={"Select pricing fields..."}
            value={props.selectedBypassGroups}
          />
        </div>
      </div>
      <h3>Subscription Fees</h3>
      <div className="form-container" style={{ display: "flex", gap: 12 }}>
        <div
          className="item form-group col-sm-12 col-xs-12"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>
            {` Select one or more from 'required' fields to define a subscription
            pricing model`}
          </label>
          <Select2
            multi={true}
            isSearchable={false}
            isClearable={false}
            className="input "
            options={allFields}
            onChange={(val) => props.calculateNewGroups(val, props.exceptions)}
            placeholder={"Select pricing fields..."}
            value={props.selectedPricingFields}
          />
          <button
            className="search-advanced btn tab-header-btn"
            style={{
              marginTop: "10px",
              alignSelf: "flex-end",
            }}
            onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
          >
            {showAdvancedSettings ? "Hide " : "Show "}Advanced Settings
            <span className="icon-settings"></span>
          </button>
          {showAdvancedSettings && (
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  justifyContent: !selectedPricingFieldsValues.length
                    ? "center"
                    : "flex-start",
                }}
              >
                <label style={{ flexBasis: "100%" }}>
                  {` Select one or more from the available options that will participate in the subscription
            pricing model:`}
                </label>
                {!selectedPricingFieldsValues.length && (
                  <div>-No pricing fields selected-</div>
                )}
                {Object.values(props.selectedPricingFields || {}).map(
                  (selectedPricingField) => {
                    const theField = props.pricingFields.find(
                      (field) => field.id == selectedPricingField
                    );
                    const options = getOptions(theField);

                    return (
                      <div key={theField.id}>
                        <h5>{theField.name}</h5>
                        {options.map(({ key, label }) => {
                          return (
                            <Checkbox
                              key={key}
                              id={key}
                              label={label}
                              disabled={options.length <= 2}
                              checked={!props.exceptions?.includes(key)}
                              onChange={() => handleOnChangeCheckBox(key)}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="item form-group col-sm-12 col-xs-12 disabled">
        <label>Group subscription fees tables by</label>
        <Select2
          disabled={Object.keys(groupOptions).length <= 1}
          isClearable={false}
          className="input"
          options={groupOptions}
          onChange={setSelectedSubSection}
          placeholder={"Group Pricing by"}
          value={props.selectedSubsection}
        />
      </div>
    </div>
  );
};
PricingHeader.defaultProps = {
  selectedBypassGroups: [],
};
PricingHeader.propTypes = {
  calculateBypassGroups: PropTypes.func,
  calculateNewGroups: PropTypes.func,
  bypassGroups: PropTypes.object,
  selectedPricingFields: PropTypes.array,
  allOptions: PropTypes.object.isRequired,
  pricingFields: PropTypes.array,
  loading: PropTypes.bool,
  selectedSection: PropTypes.string,
  selectedSubsection: PropTypes.string,
  selectedBypassGroups: PropTypes.array,
  exceptions: PropTypes.object,
  showPrices: PropTypes.object,
  onChangeShowPrices: PropTypes.func,
  onChangeSubsection: PropTypes.func,
  changeSection: PropTypes.func,
  changeTab: PropTypes.func,
  hasBilling: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};
export default PricingHeader;
