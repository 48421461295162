/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import formFields from "@helpers/form-fields";
import { isEmpty, isArray } from "@helpers";
import Form from "@components/form";
import Switch from "@layout/Switch";
import { connect } from "react-redux";
import { getCrmUsersSearch } from "@actions/users";
import { clearSelectedUser, toggleUserEditMode } from "@actions/users";
import { checkIfConditionIsFulfilled } from "../../../../../../../../helpers/users-helper";

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.populateFields = this.populateFields.bind(this);
    this.getFieldType = this.getFieldType.bind(this);
    this.saveAndPrint = this.saveAndPrint.bind(this);
    this.onChange = this.onChange.bind(this);

    /**
     * Lets populate the forms
     * Dynamic fields.
     */
    this.populateFields(props.policy, props);
    this.saveAndPrintMode = false;
    this.state = {
      nativePrintActive: false,
      hasSaveBttnBeenPressed: false,
      inEditMode: false,
      firstNameValue: "",
      lastNameValue: "",
      emailValue: "",
      /**
       * This is a way to check for multiple clicks on submit
       * When you a have an onSubmit its better to use state to keep information
       * on whether the button is clicked, if the button is clicked set
       * the state on true, so every other click just returns null
       * as shown in the check on the form
       *
       */
    };
  }

  getFieldType(key, collection = "fields") {
    if (collection === "fields") {
      const textInputs = ["username", "firstName", "lastName", "accessCode"];

      if (key === "email") {
        return "email";
      } else if (key === "password" || key === "confirmPassword") {
        return "password";
      } else if (key === "company") {
        return "text";
      } else if (textInputs.includes(key)) {
        return "text";
      } else if (key === "resume") {
        return "textarea";
      } else if (key === "crmUserProfilePhoto") {
        return "dropzone";
      } else if (key === "sponsored" || key === "speakerChairperson") {
        return "yes_no";
      }
    }
    return false;
  }

  populateFields(policy) {
    let form = formFields.forms.accesspolicies.createuser;
    if (!isEmpty(form)) {
      form = formFields.forms.accesspolicies.createuser = {};
    }
    /**
     * First grab the used
     * fields of the settings
     */
    if (!isEmpty(policy.settings)) {
      Object.entries(policy.settings).forEach(([key, value]) => {
        if (value.editable !== false) {
          const type = this.getFieldType(key);
          if (
            policy.saveUsersToCrm &&
            isEmpty(this.props.user) &&
            (type === "email" || type === "text")
          ) {
            const type = "typeselect";
            form[key] = {
              name: key,
              type,
              required: value.required === 1 ? true : false,
              id: key,
              placeholder: value.name,
              label: value.name,
            };
          } else if (key === "speakerChairperson" || key === "sponsored") {
            form[key] = {
              name: key,
              type: "radio",
              options: {
                yes: "Yes",
                no: "No",
              },
              required: value.required === 1 ? true : false,
              id: key,
              placeholder: value.name,
              label: value.name,
            };
          } else if (key !== "speaker" && key !== "crmUserProfilePhoto") {
            form[key] = {
              name: key,
              type,
              required: value.required === 1 ? true : false,
              id: key,
              placeholder: value.name,
              label: value.name,
            };
          } else if (key === "crmUserProfilePhoto") {
            form[key] = {
              name: key,
              type: "dropzone",
              required: false,
              id: key,
              placeholder: value.name,
              label: value.name,
              styleType: "simple",
            };
          }
        }
        if (
          key === "password" ||
          key === "subscriptionPrice" ||
          key === "sumPaid" ||
          key === "sumDiscount" ||
          key === "sponsorAmount" ||
          key === "dueAmount" ||
          key === "sponsoredBy"
        ) {
          delete form[key];
        } else if (value.system_gen === 1) {
          delete form[key];
        }
      });
    }

    if (!isEmpty(policy.extraSettings)) {
      policy.extraSettings = Object.keys(policy.extraSettings)
        .filter((key) => !policy.extraSettings[key].system_gen)
        .sort(
          (a, b) =>
            policy.extraSettings[a].index - policy.extraSettings[b].index
        )
        .reduce(
          (_sortedObj, key) => ({
            ..._sortedObj,
            [key]: policy.extraSettings[key],
          }),
          {}
        );

      let index = 0;
      Object.entries(policy.extraSettings).map(([k, v]) => {
        let type = v.type,
          value = v.value,
          options = {};

        index++;

        if (type === "dropdown") {
          type = "select";

          if (v.value) {
            if (!isArray(v.value)) {
              value = JSON.parse(v.value);
            }
            value.map((v) => {
              options[v] = v;
            });
          }
        } else if (type === "yes_no") {
          type = "radio";
          options = {
            yes: "Yes",
            no: "No",
          };
        }
        if (v.orgCrmSchemaId && type === "text") {
          if (
            v.name === "firstName" ||
            v.name === "lastName" ||
            v.name === "email"
          ) {
            type = "typeselect";
          } else {
            type = "text";
          }
        }
        form[k] = {
          name: v.key,
          type,
          required: v.required === 1 ? true : false,
          id: `field-${index}`,
          placeholder: v.name,
          label: v.name,
          disabled: v.orgCrmSchemaId === 1 ? true : false,
          conditionSchema: v.conditionSchema,
        };
        if (type === "select" || type === "radio") {
          form[k].options = options;
        }
      });
    }
    if (policy.type !== "public" && form["email"]) {
      // allow empty emails for admin users
      form["email"].required = false;
    }
  }
  componentDidMount() {
    this.setState({ selectedUser: this.props.selectedUser });
  }
  saveAndPrint() {
    this.saveAndPrintMode = true;
  }
  searchFirstName(data) {
    this.setState({
      firstNameValue: data.value,
    });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.props.loadUsers(
        data.value,
        this.state.lastNameValue,
        this.state.emailValue,
        this.props.policy.id,
        this.props.policy.eventId
      );
    }, 500);
  }
  searchLastName(data) {
    this.setState(
      {
        lastNameValue: data.value,
      },
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.loadUsers(
            this.state.firstNameValue,
            data.value,
            this.state.emailValue,
            this.props.policy.id,
            this.props.policy.eventId
          );
        }, 500);
      }
    );
  }
  searchEmailValue(data) {
    this.setState(
      {
        emailValue: data.value,
      },
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.loadUsers(
            this.state.firstNameValue,
            this.state.lastNameValue,
            data.value,
            this.props.policy.id,
            this.props.policy.eventId
          );
        }, 500);
      }
    );
  }
  onChange(data) {
    // I call the loadusers in each if, so i can get the latest input value from the form,
    // if i pass it to state and then pass the whole state to loadusers i will lose the last
    // input entered and the form does not support on key down, if you find a better way
    // you can change it

    if (isEmpty(this.props.user)) {
      if (data.key === "firstName") {
        return this.searchFirstName(data);
      } else if (data.key === "lastName") {
        return this.searchLastName(data);
      } else if (data.key === "email") {
        return this.searchEmailValue(data);
      }
    }
  }

  render() {
    const policy = this.props.policy;
    const { user } = this.props;
    const userId = isEmpty(this.props.user) ? null : this.props.user.id;

    const form = formFields.forms.accesspolicies.createuser;
    Object.keys(form).forEach((key) => {
      if (form[key]["disabled"] !== undefined) {
        policy.extraSettings[key]?.orgCrmSchemaId &&
        this.props.hasUserBeenSelected &&
        !this.props.userEditMode
          ? (form[key]["disabled"] = true)
          : (form[key]["disabled"] = false);
      }
    });

    if (!isEmpty(this.props.user)) {
      this.props.users.map((correctUser) => {
        if (correctUser.id === this.props.user.id) {
          user["crmUserProfilePhoto"] = correctUser.crmUserProfilePhoto;
        }
      });
    }
    const theForm = (theUser) => (
      <Form
        componentName="accesspolicies"
        formName="createuser"
        value={theUser}
        saveAndPrint={this.saveAndPrint}
        type={policy.type}
        onSubmit={(data) => {
          const saveAndPrint = this.saveAndPrintMode;
          this.saveAndPrintMode = false;
          if (!isEmpty(data.errors)) {
            return;
          }

          Object.entries(data.value).map(([k, v]) => {
            if (
              policy.extraSettings &&
              policy.extraSettings[k] &&
              policy.extraSettings[k].type === "yes_no"
            ) {
              if (v === "yes") {
                data.value[k] = 1;
              } else if (v === "no") {
                data.value[k] = 0;
              }
            }
          });

          Object.entries(policy.extraSettings || {}).map(([k, v]) => {
            if (v.conditionSchema && !checkIfConditionIsFulfilled(v, data.value)) {
              data.value[k] = "";
            }
          });

          this.props.createUser(
            data.value,
            policy.id,
            policy.eventId,
            userId,
            saveAndPrint,
            this.props.selectedUser.id
          );
        }}
        onChange={(data) => {
          if (
            this.props.area !== "policies" &&
            this.props.policy.saveUsersToCrm
          )
            return this.onChange(data);

          if (data.key === "crmUserProfilePhoto") {
            this.props.createUser(
              data.value.id,
              policy.id,
              policy.eventId,
              userId,
              false,
              true
            );
          }
        }}
        onCancel={this.props.hideModal}
      />
    );

    return (
      <div className="form-container create-user">
        {!isEmpty(this.props.user) ? (
          <h2>Edit Participant</h2>
        ) : (
          <h2>Create Participant</h2>
        )}
        <p className="subtitle">for {policy.name} Policy</p>
        {this.props.area !== "policies" && isEmpty(this.props.user) && (
          <>
            {this.props.hasUserBeenSelected && (
              <div className="group-container">
                <div className="form-group" style={{ paddingTop: 20 }}>
                  <div className="type-switch">
                    <label>Enable editing for CRM details</label>
                    <div className="switch-holder">
                      <Switch
                        id={`staff-active-${this.props.user.id}`}
                        onChange={this.props.toggleEditMode}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn cancel"
                    onClick={this.props.clearUser}
                  >
                    Unselect User
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        <div>
          {/*
          The following is required for the form to update user fields,
          the form by of modals does not read values from props when they are updated
          thus we need to rerender the form with a new key so react understands that this
          is a new form and get the initial properties from the props
          */}
          {isEmpty(this.props.selectedUser) ? (
            <div key="form-1">{theForm(this.props.user)}</div>
          ) : (
            <div key="form-2">{theForm(this.props.selectedUser)}</div>
          )}
        </div>
      </div>
    );
  }
}

CreateUser.propTypes = {
  user: PropTypes.object,
  policy: PropTypes.object.isRequired,
  hideModal: PropTypes.func,
  createUser: PropTypes.func.isRequired,
  loadUsers: PropTypes.func,
  selectedUser: PropTypes.any,
  type: PropTypes.string,
  clearUser: PropTypes.func,
  toggleEditMode: PropTypes.func,
  userEditMode: PropTypes.bool,
  area: PropTypes.string,
  hasUserBeenSelected: PropTypes.bool,
  users: PropTypes.array,
  debounce: PropTypes.func,
};

CreateUser.defaultProps = {
  user: {},
  policy: {},
};

const mapStateToProps = (state) => {
  return {
    selectedUser: state.users.selectedUser,
    hasUserBeenSelected: state.users.hasUserBeenSelected,
    userEditMode: state.users.userEditMode,
    users: state.users.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (firstName, lastName, email, policyId, eventId) => {
      dispatch(
        getCrmUsersSearch(firstName, lastName, email, policyId, eventId)
      );
    },
    clearUser: () => {
      dispatch(clearSelectedUser());
    },
    toggleEditMode: () => {
      dispatch(toggleUserEditMode());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
