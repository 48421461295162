import React from "react";
import PropTypes from "prop-types";
import { patchSpeakers } from "@actions/patch";
import { loadUsers } from "@actions/users";
import { loadSpeakers, updateSpeaker } from "@actions/speakers";
import { connect } from "react-redux";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import { HeaderPagination } from "@layout/Pagination";
import { showModal } from "@actions";
import SingleSpeakerChairperson from "./SingleSpeakerChairperson";

class SpeakersChairpersons extends React.Component {
  constructor(props) {
    super(props);
    ["onRender"].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.state = {
      file: null,
      userId: null,
      userName: null,
    };
    this.columns = {
      profilePhoto: {
        name: "",
      },
      eventUserId: {
        name: "Participant ID",
      },
      firstName: {
        name: "First Name",
      },
      lastName: {
        name: "Last Name",
      },
      email: {
        name: "Email",
      },
      jobTitle: {
        name: "Job Title",
      },
      institution: {
        name: "Institution",
      },
      shortBio: {
        name: "Short Bio",
      },
      actions: {
        name: "",
      },
    };
  }

  componentDidMount() {
    this.props.loadSpeakers(this.props.eventId);
  }
  onRender(col, item) {
    switch (col.key) {
      case "shortBio": {
        return (
          <div
            style={{
              maxWidth: "700px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
            }}
          >
            {item.shortBio}
          </div>
        );
      }

      case "profilePhoto": {
        return (
          <div
            style={{
              width: 50,
              height: 50,
              overflow: "hidden",
              borderRadius: "100%",
            }}
          >
            <img
              src={
                (item.speakerThumbnail || [])[0]
                  ? item.speakerThumbnail[0].url
                  : "/images/medlive-noimage.jpg"
              }
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        );
      }
      case "actions": {
        return (
          <React.Fragment>
            <TableActions
              entity="speaker item"
              item={item}
              delete={false}
              onEdit={() => {
                return this.props.showModal(
                  <SingleSpeakerChairperson
                    item={item}
                    onSave={(data) => {
                      return this.props.updateSpeaker(
                        this.props.eventId,
                        item.id,
                        data
                      );
                    }}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      }
    }
  }

  render() {
    const needsPatch =
      this.props.speakers.filter((speaker) => !speaker.id).length > 0;

    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            <HeaderPagination
              fetching={this.props.fetching}
              p={this.props.meta.p}
              rpp={this.props.meta.rpp}
              totalRows={this.props.meta.totalRows}
              onChangePage={(data) => {
                this.props.loadSpeakers(this.props.eventId, {
                  ...this.props.meta,
                  p: data.p,
                  rpp: data.rpp,
                });
              }}
            />
          </div>
        </div>
        <div className="tab-content">
          {needsPatch ? (
            <div
              className="tab-content"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexFlow: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <p>
                This is a new feature. Please click the &quot;Patch&quot; button
                to enable this feature.
              </p>
              <button
                type="submit"
                className="btn save rounded"
                onClick={() =>
                  this.props
                    .patchSpeakers(this.props.eventId)
                    .then(() => this.props.loadSpeakers(this.props.eventId))
                }
              >
                Patch
              </button>
            </div>
          ) : (
            <Table
              id={"usersSession"}
              columns={this.columns}
              items={this.props.speakers}
              updating={this.props.fetching}
              onRender={this.onRender}
              containerClassName={`table-container `}
            />
          )}
        </div>
      </>
    );
  }
}

SpeakersChairpersons.propTypes = {
  eventId: PropTypes.number.isRequired,
  speakers: PropTypes.array.isRequired,
  searchUsers: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  loadSpeakers: PropTypes.func,
  showModal: PropTypes.func,
  updateSpeaker: PropTypes.func,
  meta: PropTypes.object.apply,
  patchSpeakers: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    eventId: Number(state.page.params.eventId),
    speakers: state.api.speakers.data,
    meta: state.api.speakers.meta,
    fetching: state.api.speakers.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSpeaker: (eventId, speakerId, data) => {
      dispatch(updateSpeaker(eventId, speakerId, data));
    },
    loadSpeakers: (eventId, meta) => {
      return dispatch(loadSpeakers(eventId, meta));
    },
    patchSpeakers: (eventId) => {
      return dispatch(patchSpeakers(eventId));
    },
    showModal: (modal) => {
      return dispatch(showModal(modal));
    },
    searchUsers: (queryString, policyId, eventId) => {
      dispatch(
        loadUsers(
          eventId,
          policyId,
          { search: queryString, policyTypes: "private,crm" },
          null,
          null,
          null,
          true,
          true
        )
      );
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeakersChairpersons);
