import React from "react";
import Form from "@components/form";
import { isEmpty } from "@helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOrgHotels, assignHotel, saveHotel } from "@actions/hotels";
import Confirm from "@layout/Confirm";
import { hideModal } from "@actions";

class SingleHotel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      hotelItem: this.props.item,
      showConfirm: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showConfirm: false,
      file: null,
      item: nextProps.item,
    });
  }

  render() {
    const props = this.props;
    const value = this.props.item
      ? {
          address: {
            hname: this.state.hotelItem.name,
            address: this.state.hotelItem.address,
            lat: this.state.hotelItem.latitude,
            lng: this.state.hotelItem.longitude,
          },
          facilities: this.state.hotelItem.facilities,
          description: this.state.hotelItem.description,
          rating: this.state.hotelItem.stars,
          active: this.state.hotelItem.active,
          hotelImages: this.state.hotelItem.hotelImages,
          notes: this.state.hotelItem.notes || null,
        }
      : {};

    const deletefileBody = this.state.showConfirm ? (
      <div>
        <p>
          This action will permanently delete the file with ID:{" "}
          <strong>{this.state.file.id}</strong>
        </p>
        <p>
          <span>{this.state.file.fileName}</span>
        </p>
        <p>Are you sure that you want to do this?</p>
      </div>
    ) : null;
    return (
      <div className="single-hotel">
        <Confirm
          onConfirm={() => {
            props.saveHotel(
              props.orgId,
              { hotelImages: [this.state.file.id] },
              props.item.orgHotelId || props.item.id,
              false, //dont assign hotel if in orgMode
              undefined, //endpoint
              false, //formdata
              false //keep modal open
            );
            props.hideModal();
          }}
          body={deletefileBody}
          className="delete-event"
          confirmText="Confirm File Deletion"
          disabled={false}
          visible={this.state.showConfirm}
          title="File Delete Action! Are you sure?"
        ></Confirm>
        <div className="form-container">
          <h2>{this.props.item ? "Edit Hotel" : "Create Hotel"}</h2>
          <Form
            componentName="hotels"
            formName={"singleOrg"}
            value={value}
            onSubmit={(data) => {
              if (isEmpty(data.errors)) {
                let formData = false;
                const value = data.value;
                const req = {
                  name: value.address.hname,
                  address: value.address.address,
                  stars: value.rating,
                  description: value.description,
                  facilities: value.facilities,
                  active: value.active,
                  longitude: value.address.lng,
                  latitude: value.address.lat,
                };
                if (value.notes) {
                  req["notes"] = value.notes;
                }

                if (value.hotelImages.length > 0) {
                  req["hotelImages"] = value.hotelImages;
                  formData = true;
                }
                props.saveHotel(
                  this.props.orgId,
                  req,
                  this.props.item ? props.item.id : false,
                  this.props.item ? false : true,
                  undefined,
                  formData,
                  false,
                  true,
                  this.props.item ? true : false,
                  props.eventHotelId
                );
              }
            }}
            onCancel={() => this.props.hideModal()}
            onChange={(data) => {
              if (data.key === "hotelImages") {
                this.setState(
                  {
                    file: data.value,
                  },
                  () => {
                    this.props.saveHotel(
                      this.props.orgId,
                      { hotelImages: [data.value.id] },
                      this.props.item ? props.item.id : false,
                      this.props.item ? false : true,
                      undefined,
                      false,
                      false,
                      true,
                      this.props.item ? true : false,
                      props.eventHotelId
                    );
                  }
                );
              }
            }}
          />
        </div>
      </div>
    );
  }
}

SingleHotel.propTypes = {
  item: PropTypes.object,
  saveHotel: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  getOrgHotels: PropTypes.func.isRequired,
  orgHotels: PropTypes.array.isRequired,
  hotels: PropTypes.array,
  assignHotel: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  mode: PropTypes.string,
  eventHotelId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  orgId: state.appuser.data.orgId,
  orgHotels: state.api.hotels.orgList.data,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgHotels: () => dispatch(getOrgHotels()),
  hideModal: () => dispatch(hideModal()),
  assignHotel: (hotelId) => dispatch(assignHotel(hotelId)),
  saveHotel: (
    orgId,
    data,
    hotelId = false,
    assign,
    endpoint = "hotels",
    formData,
    modalOpen = false,
    editMode
  ) => {
    dispatch(
      saveHotel(orgId, data, hotelId, endpoint, modalOpen, true, editMode)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleHotel);
