import {
  SETUP_ROOMS,
  REQUEST_ROOMS_LIST,
  ROOM_UPDATED,
  ROOMS_SAVED,
  REQUEST_ROOMS_CREATE,
  REQUEST_ROOMS_EDIT,
  SETUP_ROOM,
  USER_SCANNED,
  CLEAR_SCAN,
  USER_NOT_FOUND,
  SETUP_MASSIVE_EVENTS,
  CHECK_USER_MINUTES,
  CLEAR_USER_MINUTES,
  RECEIVED_USERS_MINUTESCANNING,
  USERS_ALL_OUT,
  UNDO_ALL_OUT,
  OFFLINE_SCAN_MODE,
} from "@actions/users";
import { UNSAVED_SCANS, ADJUST_ACTIVE_PANELS } from "@actions/appuser";
import { ITEM_DELETED } from "@actions";
import { isEmpty } from "@helpers";
import { FAILED_REQUEST } from "@actions/ajax";

const initialState = {
  data: [],
  meta: {},
  fetching: false,
  roomsScanning: {
    data: [],
    fetching: false,
    scans: {},
  },
  massiveEvents: [],
  minutesChecking: {
    scanningBanners: null,
    user: {},
  },
  offline: {
    active: false,
    data: {},
  },
};

const rooms = (state = initialState, action) => {
  switch (action.type) {
    case ADJUST_ACTIVE_PANELS: {
      return Object.assign({}, state, {
        data: [...state.data].map((r) => {
          if (
            r.eventId === parseInt(action.eventId) &&
            r.id === parseInt(action.roomId)
          ) {
            return Object.assign({}, r, {
              panelActive: action.activePanels,
            });
          }
          return r;
        }),
      });
    }

    case UNDO_ALL_OUT: {
      return Object.assign({}, state, {
        massiveEvents: [...state.massiveEvents].filter(
          (e) => e.id !== parseInt(action.id)
        ),
      });
    }

    case RECEIVED_USERS_MINUTESCANNING: {
      return Object.assign({}, state, {
        minutesChecking: Object.assign({}, state.minutesChecking, {
          scanningBanners: action.response.data.scanningBanners,
        }),
      });
    }

    case FAILED_REQUEST: {
      const { endpoint, actionKey } = action;
      if (endpoint === "rooms" && actionKey === "edit") {
        return Object.assign({}, state, {
          fetching: false,
        });
      }
      return state;
    }

    case CLEAR_USER_MINUTES: {
      return Object.assign({}, state, {
        minutesChecking: Object.assign({}, state.minutesChecking, {
          user: initialState.userChecking,
        }),
      });
    }

    case CHECK_USER_MINUTES: {
      return Object.assign({}, state, {
        minutesChecking: Object.assign({}, state.minutesChecking, {
          user: action.user.data,
        }),
      });
    }

    case ITEM_DELETED: {
      if (action.endpoint !== "rooms") {
        return state;
      }

      return Object.assign({}, state, {
        data: [...state.data].filter((d) => d.id !== parseInt(action.itemId)),
      });
    }

    case SETUP_MASSIVE_EVENTS: {
      return Object.assign({}, state, {
        massiveEvents: action.massiveEvents.data,
      });
    }

    case CLEAR_SCAN: {
      const scans = state.roomsScanning.scans.hasOwnProperty(action.roomId)
        ? [...state.roomsScanning.scans[action.roomId]]
        : [];
      return Object.assign({}, state, {
        roomsScanning: Object.assign({}, state.roomsScanning, {
          scans: Object.assign({}, state.roomsScanning.scans, {
            [action.roomId]: scans.filter((s, i) => i !== 0),
          }),
        }),
      });
    }

    case USER_NOT_FOUND: {
      const scans = state.roomsScanning.scans.hasOwnProperty(action.roomId)
        ? [...state.roomsScanning.scans[action.roomId]]
        : [];

      return Object.assign({}, state, {
        roomsScanning: Object.assign({}, state.roomsScanning, {
          scans: Object.assign({}, state.roomsScanning.scans, {
            [action.roomId]: [...scans, ...[{ id: -1 }]],
          }),
        }),
      });
    }

    case UNSAVED_SCANS: {
      return Object.assign({}, state, {
        offline: Object.assign({}, state.offline, {
          active: isEmpty(action.unsavedScans) ? false : true,
          data: action.unsavedScans,
        }),
      });
    }

    case OFFLINE_SCAN_MODE: {
      return Object.assign({}, state, {
        offline: Object.assign({}, state.offline, {
          active: true,
          data: Object.assign({}, state.offline.data, {
            [action.eventId]: Object.assign(
              {},
              state.offline.data.hasOwnProperty(action.eventId)
                ? state.offline.data[action.eventId]
                : {},
              {
                [action.roomId]: action.scans,
              }
            ),
          }),
        }),
      });
    }

    case USER_SCANNED: {
      const scans = state.roomsScanning.scans.hasOwnProperty(action.roomId)
        ? [...state.roomsScanning.scans[action.roomId]]
        : [];
      return Object.assign({}, state, {
        roomsScanning: Object.assign({}, state.roomsScanning, {
          scans: Object.assign({}, state.roomsScanning.scans, {
            [action.roomId]: [...scans, ...[action.currentScan.data]],
          }),
        }),
      });
    }

    case ROOMS_SAVED: {
      return Object.assign({}, state, {
        data: [...action.rooms, ...state.data],
        fetching: false,
      });
    }

    case SETUP_ROOM: {
      return Object.assign({}, state, {
        roomsScanning: Object.assign({}, state.roomsScanning, {
          data: [...state.roomsScanning.data, ...[action.room.data]],
          fetching: false,
          scans: Object.assign({}, state.roomsScanning.scans, {
            [action.room.data.id]: [],
          }),
        }),
      });
    }

    case REQUEST_ROOMS_LIST:
    case REQUEST_ROOMS_EDIT: {
      return Object.assign({}, state, {
        fetching: true,
      });
    }

    case SETUP_ROOMS: {
      return Object.assign({}, state, {
        data: action.rooms.data,
        meta: action.rooms.meta,
        fetching: false,
        timestamp: Date.now(),
      });
    }

    case REQUEST_ROOMS_CREATE: {
      return Object.assign({}, state, {
        fetching: true,
      });
    }

    case ROOM_UPDATED:
    case USERS_ALL_OUT: {
      return Object.assign({}, state, {
        data: [...state.data].map((r) => {
          if (action.room.data.id === r.id) {
            return action.room.data;
          }
          return r;
        }),
        roomsScanning: Object.assign({}, state.roomsScanning, {
          data: [...state.roomsScanning.data].map((r) => {
            if (action.room.data.id === r.id) {
              return action.room.data;
            }
            return r;
          }),
        }),
        fetching: false,
      });
    }

    default: {
      return state;
    }
  }
};

export default rooms;
