import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import { updateClientPanelSpeakersOrder } from "@actions";
import { connect } from "react-redux";

const sortSpeakers = (a, b) => {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
};

const Speakers = (props) => {
  const onRender = (col, item) => {
    switch (col.key) {
      case "speakerThumbnail": {
        return (
          <img
            key={`item-${item.id}`}
            src={
              (item.speakerThumbnail || [])[0]
                ? item.speakerThumbnail[0].url
                : "/images/medlive-noimage.jpg"
            }
            style={{
              objectFit: "cover",
              width: 50,
              height: 50,
              borderRadius: "100%",
            }}
          />
        );
      }
    }
  };

  return (
    <div className="speakers-container" key={`type-item-${props.index}`}>
      <Table
        sortable={true}
        onSortEnd={(newIndex, item, newItems) => {
          const newSortList = newItems.map((sorted, index) => ({
            id: sorted.id,
            index,
          }));

          return props.updateClientPanelSpeakersOrder(props.event.id, {
            speakersChairpersons: newSortList,
          });
        }}
        columns={{
          speakerThumbnail: {
            name: "Speaker Image",
          },
          lastName: {
            name: "Last Name",
          },
          firstName: {
            name: "First Name",
          },
        }}
        updating={false}
        items={props.event.clientPanelSettings.speakers.keynote.sort(
          sortSpeakers
        )}
        containerClassName={`table-container `}
        onRender={onRender}
      />
    </div>
  );
};

Speakers.propTypes = {
  event: PropTypes.object,
  updateClientPanelSpeakersOrder: PropTypes.func,
  index: PropTypes.number,
  updateClientPanelVideoSponsorOrder: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientPanelSpeakersOrder: (eventId, data) => {
      return dispatch(updateClientPanelSpeakersOrder(eventId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Speakers);
