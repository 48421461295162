import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import KMBEditor from "@layout/KMBEditor";
import { connect } from "react-redux";
import { updateClientPanelSettings } from "@actions";

const Home = (props) => {
  const [state, setState] = React.useState({
    descriptionValue: props.event.clientPanelSettings.home.homeDescription,
  });

  if (props.fetching) {
    return <KMBLoader rows={15} padding={24} height={53} />;
  }
  return (
    <div className="main-content-container form-container">
      <div className="form-group">
        <div className="form-label">Description</div>
        <div style={{ minHeight: 500, background: "whitesmoke" }}>
          <KMBEditor
            placeholder={`Type the content of the Event Description...`}
            rows={50}
            value={state.descriptionValue}
            onChange={(data) =>
              setState({
                descriptionValue: data,
              })
            }
          />
        </div>
      </div>
      <Form
        componentName="events"
        formName="home"
        value={props.event.clientPanelSettings.home}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length) return false;

          data.value.homeDescription = state.descriptionValue;
          props.updateClientPanelSettings(props.event.id, data.value, "home");
        }}
        onCancel={() => {
          setState({
            descriptionValue:
              props.event.clientPanelSettings.home.homeDescription,
          });
        }}
      />
    </div>
  );
};

Home.propTypes = {
  event: PropTypes.object.isRequired,
  updateClientPanelSettings: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
