import moment from "moment-timezone";

const timeFormat = "HH:mm";
const dateFormat = "YYYY-MM-DD";
const momentFormat = `${dateFormat} ${timeFormat}`;

const computeScheduledEstimate = (nextDate, event) => {
  const timezoned_timestamp_now = moment().utc().tz(event.timezone);

  const timezonelessNextDate = moment(
    nextDate.format("Do MMMM  YY - HH:mm"),
    "Do MMMM  YY - HH:mm"
  );
  const timezonelessNow = moment(
    timezoned_timestamp_now.format("Do MMMM  YY - HH:mm"),
    "Do MMMM  YY - HH:mm"
  );

  const diff = timezonelessNextDate.diff(timezonelessNow);
  const m = moment.duration(diff);

  return `${nextDate.format("Do MMMM  YY - HH:mm")} (sent in ${
    m.years() > 0 ? `${m.years()} years,` : ``
  } ${
    m.months() > 0 ? `${m.months()} months,` : ``
  } ${m.days()} days, ${m.hours()} hours, and ${m.minutes()} minutes)`;
};

const isStatic = (scheduled_date_data) => {
  if (
    (scheduled_date_data.numOfDays !== null &&
      scheduled_date_data.numOfDays !== undefined) ||
    (scheduled_date_data.numOfHours !== null &&
      scheduled_date_data.numOfHours !== undefined) ||
    (scheduled_date_data.numOfMins !== null &&
      scheduled_date_data.numOfMins !== undefined)
  ) {
    return false;
  }
  return true;
};

const parseDate = (scheduled_date_data, event) => {
  const scheduling_copy = JSON.parse(JSON.stringify(scheduled_date_data));
  let scheduledDate = moment();
  if (isStatic(scheduling_copy)) {
    if (!scheduling_copy.scheduledDatetime) {
      scheduling_copy.scheduledDatetime = moment(
        `${scheduling_copy.onDate} ${scheduling_copy.atTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    scheduledDate = moment(
      scheduling_copy.scheduledDatetime,
      "YYYY-MM-DD HH:mm:ss"
    );
  } else if (!isStatic(scheduling_copy)) {
    let referencePoint = moment();

    switch (scheduling_copy.timeEvent) {
      case "startOfEvent": {
        referencePoint = moment(event.startDate.tz);
        break;
      }
      case "endOfEvent": {
        referencePoint = moment(event.endDate.tz);
        break;
      }
    }

    const parsedStartDate = moment(
      `${referencePoint.format(
        scheduledEmails.dateFormat
      )} ${referencePoint.format("HH:mm:ss")}`,
      momentFormat
    );

    let resultDate = "";

    let time_unit = "";
    let time_value = 0;

    if (scheduling_copy.numOfDays) {
      time_unit = "days";
      time_value = scheduling_copy.numOfDays;
    } else if (scheduling_copy.numOfHours) {
      time_unit = "hours";
      time_value = scheduling_copy.numOfHours;
    } else if (scheduling_copy.numOfMins) {
      time_unit = "minutes";
      time_value = scheduling_copy.numOfMins;
    }

    if (scheduling_copy.sequence === "before") {
      resultDate = parsedStartDate.subtract(time_value, time_unit);
    } else if (scheduling_copy.sequence === "after") {
      resultDate = parsedStartDate.add(time_value, time_unit);
    } else {
      resultDate = parsedStartDate;
    }
    scheduledDate = resultDate;
  }
  return scheduledDate;
};

const getSoonestDate = (schedulingArr, event) => {
  let soonestDate = null;

  if (!schedulingArr) return "N/A";

  schedulingArr.forEach((scheduled_date_data) => {
    const scheduledDate = parseDate(scheduled_date_data, event);

    const timezonedDate = moment(
      moment().tz(event.timezone).format(momentFormat),
      momentFormat
    );

    if (soonestDate === null && timezonedDate.diff(scheduledDate) < 0) {
      soonestDate = scheduledDate;
    } else if (
      soonestDate !== null &&
      soonestDate.diff(scheduledDate) > 0 &&
      timezonedDate.diff(scheduledDate) < 0
    ) {
      soonestDate = scheduledDate;
    }
  });

  return soonestDate ? soonestDate : "N/A";
};

const getSoonestSchedulingData = (schedulingArr, event) => {
  let soonestDate = null;
  let soonestSchedulingData = null;

  if (!schedulingArr) return "N/A";

  schedulingArr.forEach((scheduled_date_data) => {
    const scheduledDate = parseDate(scheduled_date_data, event);

    const timezonedDate = moment(
      moment().tz(event.timezone).format(momentFormat),
      momentFormat
    );

    if (soonestDate === null && timezonedDate.diff(scheduledDate) < 0) {
      soonestDate = scheduledDate;
      soonestSchedulingData = scheduled_date_data;
    } else if (
      soonestDate !== null &&
      soonestDate.diff(scheduledDate) > 0 &&
      timezonedDate.diff(scheduledDate) < 0
    ) {
      soonestDate = scheduledDate;
      soonestSchedulingData = scheduled_date_data;
    }
  });

  return soonestSchedulingData;
};

const isDateInThePast = (scheduled_date_data, event) => {
  const date = parseDate(scheduled_date_data, event);

  const timezonedDate = moment(
    moment().tz(event.timezone).format(momentFormat),
    momentFormat
  );
  let output = true;
  if (timezonedDate.diff(date) < 0) {
    output = false;
  }
  return output;
};

export const scheduledEmails = {
  isStatic,
  isDateInThePast,
  timeFormat,
  dateFormat,
  momentFormat,
  parseDate,
  getSoonestDate,
  getSoonestSchedulingData,
  computeScheduledEstimate,
};
