import React from "react";
import PropTypes from "prop-types";
import ExhibitionCategories from "@layout/ExhibitionCategories";
import KMBLoader from "@layout/KMBLoader";
import SubmitContainer from "@layout/SubmitContainer";

const DigitalSettings = (props) => {
  const [state, setState] = React.useState({
    categories: [],
    newCategories: [],
  });
  const clone = (d) => JSON.parse(JSON.stringify(d));
  const makeCategoriesFromResponse = (categories) => {
    return {
      categories,
      newCategories: categories.concat([{ name: "" }]),
    };
  };

  React.useEffect(() => {
    Promise.all([props.loadExhibitionCategories(props.event.id)]);
  }, []);

  React.useEffect(() => {
    setState({
      ...state,
      loading: false,
      ...makeCategoriesFromResponse(clone(props.categories)),
    });
  }, [props]);

  const onDeleteCategory = (index) => {
    const newCategories = [...state.newCategories];
    newCategories.splice(index, 1);
    setState({ ...state, newCategories });
  };
  const onChangeCategories = (newCategories) => {
    const previous = newCategories[newCategories.length - 2] || {};
    if (previous.name == "" && !previous.id) newCategories.splice(-2, 1);
    setState({ ...state, newCategories });
  };

  const clearFields = () => {
    setState({
      ...state,
      newCategories: clone(props.categories),
      ...makeCategoriesFromResponse(clone(props.categories)),
    });
  };

  const onSave = () => {
    setState({ loading: true });
    props
      .updateExhibitionDigitalCategories(props.event.id, {
        allCategories: state.newCategories.slice(
          0,
          state.newCategories.length - 1
        ),
      })
      .catch(() => {
        setState({ ...state, loading: false });
      });
  };

  if (props.updating) {
    return <KMBLoader rows={15} padding={24} height={53} />;
  }
  return (
    <div className="tab-content slots-wrapper form-container">
      <ExhibitionCategories
        exhibitors={props.exhibitors}
        categories={state.newCategories}
        onChange={onChangeCategories}
        onDelete={onDeleteCategory}
      />
      <SubmitContainer onCancel={clearFields} onSubmit={onSave} />
    </div>
  );
};
DigitalSettings.defaultProps = {
  exhibitors: [],
};
DigitalSettings.propTypes = {
  event: PropTypes.object.isRequired,
  loadExhibitionCategories: PropTypes.func.isRequired,
  updateExhibitionDigitalCategories: PropTypes.func.isRequired,
  updating: PropTypes.bool,
  categories: PropTypes.array,
  exhibitors: PropTypes.array,
};

export default DigitalSettings;
