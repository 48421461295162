import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Search from "@layout/Search";

export default function SearchAndSelect(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [updating, setUpdating] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [focused, setFocused] = useState(false);

  const timeout = useRef(null);

  const debouncedCall = (query) => {
    setSearchQuery(query);
    setUpdating(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      props.onSearch(query).then(({ data }) => {
        setUpdating(false);
        setSearchOptions(data);
      });
    }, 400);
  };
  const renderSearchOptions = focused && searchQuery;
  const searchHasOptions = searchOptions.length > 0;
  return (
    <div
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      style={{ position: "relative" }}
    >
      <Search
        onSearch={(queryString) => {
          setSearchQuery(queryString);
          debouncedCall(queryString);
        }}
        visible={true}
        advancedSearch={true}
        placeholder={props.placeholder}
        value={searchQuery}
      />
      <div>
        <div
          className="autocomplete"
          style={{
            position: "absolute",
            padding: 0,
            margin: "5px 0px",
          }}
        >
          {renderSearchOptions &&
            !updating &&
            searchHasOptions &&
            searchOptions.slice(0, 4).map((item, index) => {
              return (
                <div
                  className="item"
                  key={index}
                  onMouseDown={() => {
                    if (props.selectAsPlaceholder) {
                      setSearchQuery("");
                    }
                    setSearchQuery(props.setName(item));

                    setFocused(false);
                    props.onSelectChange(item.id, props.setName(item));
                  }}
                >
                  {props.setName(item)}
                </div>
              );
            })}
          {renderSearchOptions && !searchHasOptions && !updating && (
            <div className="item" key={"no-results"}>
              -- No Results --
            </div>
          )}
          {renderSearchOptions && updating && (
            <div className="item" key={"loading"}>
              Loading...
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
SearchAndSelect.propTypes = {
  setName: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectAsPlaceholder: PropTypes.bool,
};
SearchAndSelect.defaultProps = {
  setName: (item) =>
    `${item.id} - ${item.info.lastName} ${item.info.firstName}`,
};
