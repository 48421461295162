import React from "react";
import PropTypes from "prop-types";
import Form from "@components/form";
import { isEmpty } from "@helpers";
import Checkboxes from "@layout/Checkboxes";
import { connect } from "react-redux";
import formFields from "@helpers/form-fields";
import { listGroups } from "@actions/policyGroups";
import { formChanged, clearChanges } from "@actions";
import {
  editContact,
  createCustomVars,
  updatePresetEmail,
  createVars,
} from "@actions/presetEmails";

class SingleContact extends React.Component {
  constructor(props) {
    super(props);
    const contact = (this.props || {}).contact || {};
    this.state = {
      title:
        this.props.contactAction === "editContact"
          ? "Edit Recipients"
          : "Add Recipients",
      radioValue: this.props.contact?.type || "to",
      groupId: this.props.contact?.groups || null,
      emailsValue: contact.emails || [],
      subjectObjectCategory: (() => {
        const arr = [];
        contact.contactSubject && arr.push("subject");
        contact.contactObject && arr.push("object");
        return arr;
      })(),
      category: (() => {
        const arr = [];
        if (contact.contactAdmin) arr.push("admins");
        if (contact.contactGroup) arr.push("groups");
        if (contact.contactEmail) arr.push("emails");

        return arr;
      })(),
      adminRoles: (contact.admins || {}).role || "",
      groupsCategory: (() => {
        const arr = [];
        contact.groups && arr.push(contact.groups);
        return arr;
      })(),
    };
    this.contactType = {
      options: {
        to: "To",
        cc: "Cc",
        bcc: "Bcc",
      },
      name: "watching",
    };
    this.onChange = this.onChange.bind(this);
    this.prepareValues = this.prepareValues.bind(this);
  }

  populateFields(data) {
    const fields = formFields.forms.contacts.single.groupsCategory;
    const fieldsToAdd = {};
    data.map((group) => {
      fieldsToAdd[group.id] = `${group.eventPolicyName} - ${group.name}`;
    });
    fields["options"] = fieldsToAdd;
  }

  onChange(data) {
    if (data.key === "emailsValue") {
      this.setState({ emailsValue: data.value });
    } else if (data.key === "subjectObject") {
      this.setState({ subjectObjectCategory: data.value });
    } else if (data.key === "watching") {
      this.setState({ subjectObjectCategory: data.value });
    } else if (data.key === "groupsCategory") {
      this.setState({ groupId: data.value });
    }
  }

  componentDidMount() {
    this.props.clearChanges();
  }

  prepareValues(data) {
    if (!isEmpty(data.errors)) {
      return;
    }

    const index = this.props.contactIndex;
    // tags input dont have a record in the form data
    // so we manually assign the value
    data.value.emailsValue = this.state.emailsValue;

    // only category will always have a value
    const {
      category,
      adminRoles = this.state.adminRoles,
      emailsValue = this.state.emailsValue,
    } = data.value;

    category.push(...this.state.subjectObjectCategory);

    const form = [...this.props.emails.contacts];
    if (this.props.contactAction === "createContact") {
      form.push({
        contactSubject: category.indexOf("subject") >= 0,
        contactObject: category.indexOf("object") >= 0,
        contactAdmin: category.indexOf("admins") >= 0,
        contactEmail: category.indexOf("emails") >= 0,
        contactGroup: category.indexOf("groups") >= 0,
        admins: {
          role: adminRoles,
        },
        groups: this.state.groupId,
        emails: emailsValue,
        type: this.state.radioValue,
      });
    } else {
      // editContact
      form[index] = {
        emailId: this.props.emailId,
        contactSubject: category.indexOf("subject") >= 0,
        contactObject: category.indexOf("object") >= 0,
        contactAdmin: category.indexOf("admins") >= 0,
        contactEmail: category.indexOf("emails") >= 0,
        contactGroup: category.indexOf("groups") >= 0,
        admins: {
          role: adminRoles,
        },
        groups: this.state.groupId,
        emails: emailsValue,
        type: this.state.radioValue,
      };
    }

    const contactIndex =
      this.props.contactAction == "createContact" ? undefined : index;
    if (this.props.emailType == "hook") {
      this.props.saveEmail(
        this.props.eventId,
        this.props.hookId,
        this.props.emailId,
        "edit",
        {
          data: {
            contacts: form,
            action: this.props.contactAction,
            contactIndex,
          },
        }
      );
    } else if (this.props.emailType == "preset") {
      this.props.saveEmail(
        this.props.orgId,
        this.props.emailId,
        {
          contacts: form,
          // contactIndex
        },
        this.props.eventId
      );
    } else if (this.props.emailType == "questionnaire") {
      this.props.saveEmail(
        {
          contacts: form,
        },
        this.props.eventId,
        this.props.emailId
      );
    }
  }

  render() {
    this.populateFields(this.props.policyGroups);
    let checkboxes = {};

    if (this.props.emailType == "hook") {
      checkboxes = {
        options: {
          subject: `Contact the ${this.props.innerHook.hook.subjectLabel} of the hook`,
          object: `Contact the ${this.props.innerHook.hook.objectLabel} of the hook`,
        },
        name: "subjectObject",
        value: this.state.subjectObjectCategory,
        onChange: (key, value) => this.onChange({ key, value }),
      };
      if (this.props.innerHook.hook.subjectLabel === null) {
        delete checkboxes.options.subject;
      }
      if (this.props.innerHook.hook.objectLabel === null) {
        delete checkboxes.options.object;
      }
    }

    return (
      <div className="form-container create-contact">
        <h2>{this.state.title}</h2>
        <p className="subtitle">for Email with id {this.props.emailId}</p>
        <label>Select one or more categories to define the contact</label>

        {Object.keys(checkboxes).length > 0 && <Checkboxes {...checkboxes} />}
        <Form
          triggerFormChanged={false}
          componentName="contacts"
          formName="single"
          onChange={(data) => {
            this.props.formChanged();
            this.onChange(data);
          }}
          value={this.state}
          onSubmit={(data) => {
            this.props.clearChanges();
            this.prepareValues(data);
          }}
          onCancel={this.props.hideModal}
        />
      </div>
    );
  }
}

SingleContact.propTypes = {
  contact: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  saveEmail: PropTypes.func.isRequired,
  hookLabel: PropTypes.string,
  eventId: PropTypes.number.isRequired,
  hookId: PropTypes.number,
  emailId: PropTypes.number,
  emails: PropTypes.object,
  contactAction: PropTypes.string,
  innerHook: PropTypes.object,
  orgId: PropTypes.any,
  type: PropTypes.string,
  tempContacts: PropTypes.any,
  createTempVars: PropTypes.func,
  arr: PropTypes.array,
  mode: PropTypes.any,
  createCustomTempVars: PropTypes.func,
  editContact: PropTypes.func,
  contactIndex: PropTypes.number,
  updateEmail: PropTypes.func,
  contacts: PropTypes.array,
  presetList: PropTypes.array,
  policyGroups: PropTypes.any,
  loadGroups: PropTypes.func,
  formChanged: PropTypes.func,
  clearChanges: PropTypes.func,
  emailType: PropTypes.string,
};
const mapStateToProps = (state) => {
  return {
    accessEvents: state.appuser.data.accessEvents,
    presetList: state.api.events.presetEmails.data,
    globalVars: state.api.events.presetEmails.meta.available_vars,
    tempContacts: state.users.tempContacts,
    eventId: state.api.events.edit.data.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createTempVars: (contacts, eventId, edit, contactIndex) => {
      dispatch(createVars(contacts, eventId, edit, contactIndex));
    },
    createCustomTempVars: (contacts, eventId, edit, contactIndex) => {
      dispatch(createCustomVars(contacts, eventId, edit, contactIndex));
    },
    editContact: (contacts, eventId, contactIndex, mode) => {
      dispatch(editContact(contacts, eventId, contactIndex, mode));
    },
    updateEmail: (orgId, presetEmailId, data) => {
      return dispatch(updatePresetEmail(orgId, presetEmailId, data));
    },
    loadGroups: (eventId) => {
      return dispatch(listGroups(eventId));
    },
    formChanged: (form) => {
      dispatch(formChanged(form));
    },
    clearChanges: (form) => {
      dispatch(clearChanges(form));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleContact);
