import { api } from "@actions/ajax";
import { addNotification } from "@actions";
import { isEmpty, downloadFile } from "@helpers";
import { uploadFile } from ".";

export const CHANGE_CRM_FILTERS = "CHANGE_CRM_FILTERS";
export const CRM_FIELD_CHANGE = "CRM_FIELD_CHANGE";

export const savePerson = (
  orgId,
  data,
  personId = false,
  _, // contentType = "json",
  endpoint = "crm",
  active = false,
  deletePhoto = false
) => {
  endpoint = "crm";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let crmUserProfilePhoto = undefined;
      let body = {};

      const filesToUpload = {
        crmUserProfilePhoto: Object.assign([], data.crmUserProfilePhoto),
      };
      if (
        data.crmUserProfilePhoto?.length > 0 &&
        typeof data.crmUserProfilePhoto[0] !== "number"
      ) {
        data.crmUserProfilePhoto[0] = data.crmUserProfilePhoto[0].name;
      }

      const filesToUploadProps = Object.keys(filesToUpload);
      let hasFiles = false;
      filesToUploadProps.forEach((key) => {
        if (filesToUpload[key]?.length > 0) hasFiles = true;
      });

      let deletionAction = false;
      data.crmUserProfilePhoto.forEach((file) => {
        if (typeof file === "number") {
          deletionAction = true;
        }
      });

      if (!isEmpty(data.crmUserProfilePhoto) && deletePhoto !== true) {
        crmUserProfilePhoto = data.crmUserProfilePhoto;
        delete data.crmUserProfilePhoto;
        body = {
          data: {
            info: data,
            crmUserProfilePhoto: crmUserProfilePhoto,
          },
        };
      } else if (deletePhoto) {
        body = {
          data: { ...data },
        };
      } else {
        if (data.speaker === "1" && deletePhoto !== true) {
          data.speaker = true;
        } else if (data.speaker === "0" && deletePhoto !== true) {
          data.speaker = false;
        }

        if (!active) {
          body = {
            data: {
              info: { ...data },
            },
          };
        } else {
          body = {
            data: { ...data },
          };
        }
      }
      const query = {
        endpoint,
        action: personId ? "edit" : "create",
        params: {
          orgId,
        },
        body,
      };
      if (personId) {
        query.params["userId"] = personId;
      }
      if (endpoint === "staff") {
        query.body.data = Object.assign({}, query.body.data, {
          verified: 1,
        });
      }

      const callback = () => {
        dispatch(loadCrmUsers(orgId, false, "crm", true));
      };

      if (!hasFiles || deletionAction) {
        // I dont want to refresh the whole page after someone hits the active switch,
        // so i dont dispatch another call when the request is send
        if (active === false) {
          dispatch(api(query))
            .then(() => {
              dispatch(loadCrmUsers(orgId, false, "crm", true));
            })
            .catch((err) => reject(err));
        } else {
          dispatch(api(query));
        }
      } else {
        if (active === false) {
          dispatch(api(query))
            .then((data) => {
              dispatch(
                uploadFile(
                  null,
                  "crmUserProfilePhoto",
                  filesToUpload.crmUserProfilePhoto[0],
                  { organisationId: orgId, crmUserId: data.data.id },
                  callback,
                  null,
                  filesToUpload.crmUserProfilePhoto.length,
                  true,
                  orgId
                )
              );
            })
            .catch((err) => reject(err));
        } else {
          dispatch(api(query)).then((data) => {
            dispatch(
              uploadFile(
                null,
                "crmUserProfilePhoto",
                filesToUpload.crmUserProfilePhoto[0],
                { organisationId: orgId, crmUserId: data.data.id },
                null,
                null,
                filesToUpload.crmUserProfilePhoto.length,
                true,
                orgId
              )
            );
          });
        }
      }
    });
  };
};
export const deleteCrmUser = (orgId, userId) => {
  const endpoint = "crm";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const query = {
        endpoint,
        action: "delete",
        params: {
          orgId,
          userId,
        },
      };

      dispatch(api(query))
        .then(() => {
          dispatch(loadCrmUsers(orgId, false, "crm", true));
        })
        .then(() => {
          dispatch(addNotification("successfully deleted user", "success"));
        })
        .catch((err) => reject(err));
    });
  };
};
export const loadCrmUsers = (orgId, meta = false, endpoint = "crm") => {
  return (dispatch) => {
    const request = {
      endpoint,
      action: "list",
      params: {
        orgId,
      },
    };

    const defaults = {
      rpp: 20,
      search: "",
      orderBy: "id",
      order: "DESC",
      p: 1,
    };

    const buildMeta = {};
    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    request.cache = false;
    dispatch(api(request));
  };
};
export const loadCrmSchemas = (orgId, meta = false) => {
  const endpoint = "schemas";
  return (dispatch, getState) => {
    // All org schemas
    const request = {
      endpoint,
      action: "list",
      params: {
        orgId,
      },
    };

    const defaults = {
      rpp: 20,
      search: "",
      orderBy: "id",
      order: "DESC",
      p: 1,
      dropdownValuesAsObject: false,
    };

    const state = getState();
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });

      if (
        meta.hasOwnProperty("orderBy") &&
        state.api[endpoint].list.meta.orderBy === buildMeta.orderBy
      ) {
        buildMeta.order =
          state.api[endpoint].list.meta.order === "ASC" ? "DESC" : "ASC";
      }
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    request.cache = false;
    return dispatch(api(request));
  };
};
export const loadCrmSchema = (orgId, meta = false, cache, schemaId) => {
  const endpoint = "schemas";
  return (dispatch, getState) => {
    // Specific schema from org
    const request = {
      endpoint,
      action: "orglist",
      params: {
        orgId,
        schemaId,
      },
    };

    const defaults = {
      dropdownValuesAsObject: false,
    };

    const state = getState();
    const buildMeta = {};

    if (meta) {
      Object.keys(defaults).forEach((key) => {
        if (key in meta) {
          buildMeta[key] = meta[key];
        } else {
          buildMeta[key] = defaults[key];
        }
      });

      if (
        meta.hasOwnProperty("orderBy") &&
        state.api[endpoint].list.meta.orderBy === buildMeta.orderBy
      ) {
        buildMeta.order =
          state.api[endpoint].list.meta.order === "ASC" ? "DESC" : "ASC";
      }
    }

    if (!Object.is(buildMeta, defaults)) {
      request.query = "?";
      Object.entries(buildMeta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }
    request.cache = false;
    dispatch(api(request));
  };
};

export const updateCrmSchema = (_request, orgId, schemaId) => (dispatch) => {
  const request = {
    endpoint: "schemas",
    action: "update",
    body: { data: _request },
    params: { orgId, schemaId },
  };

  dispatch(api(request))
    .then(() => {
      dispatch(
        loadCrmSchema(orgId, { dropdownValuesAsObject: true }, true, schemaId)
      );
    })
    .catch((err) => console.error(err));
};

export const createCrmSchema = (_request, orgId) => (dispatch) => {
  const request = {
    endpoint: "schemas",
    action: "create",
    body: { data: _request },
    params: { orgId },
  };

  dispatch(api(request))
    .then((schema) => {
      dispatch(loadCrmSchema(orgId, false, true, schema.data.id));
    })
    .catch((err) => console.error(err));
};

export const updateCrmSchemaFields =
  (fields, updating = false) =>
  (dispatch) => {
    dispatch({ type: CRM_FIELD_CHANGE, fields })
      .then(() => {
        if (updating) {
          dispatch(
            addNotification(`Abstract Schema updated successfully!`, "success")
          );
        }
      })
      .catch((err) => console.error(err));
  };
export const changeCrmFilters = (filters) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_CRM_FILTERS,
      filters,
    });
  };
};
export const exportAllUsers = (orgId, searchTerm, meta) => {
  return (dispatch, getState) => {
    let columns = [];
    const state = getState();
    Object.keys(state.api.crmColumns).forEach((k) => {
      if (k !== "company" && k !== "cosmetic" && k !== "resume") {
        if (state.api.crmColumns[k].filter === true) {
          columns = [...columns, k];
        }
      }
    });
    const request = {
      endpoint: "crm",
      action: "export",
      body: {
        data: {
          rpp: -1,
          info: true,
          p: 1,
          columns,
          order: meta.order,
          orderBy: meta.orderBy,
        },
      },
      params: { orgId },
      dataType: "buffer",
    };
    if (searchTerm !== null) {
      request.body.data["search"] = searchTerm;
    }
    dispatch(api(request)).then((file) => {
      const blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
      const objectUrl = URL.createObjectURL(blob);
      downloadFile(objectUrl, `crm-users.xlsx`);
    });
  };
};
