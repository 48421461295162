/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

export default class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: props.visible,
      queryString: props.queryString,
    };
    this.timeout = null;
    this.search = this.search.bind(this);
    this.inputElement = null;
  }

  search(e) {
    const queryString = e.target.value;
    this.setState(
      {
        queryString,
      },
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.onSearch(queryString);
        }, this.props.timeout);
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.setState({
        queryString: "",
      });
    }
  }

  render() {
    return (
      <div
        onClick={() => this.inputElement?.focus()}
        className={`${this.props.className || ""} search-wrapper`}
        style={this.props.advancedSearch && { width: "100%" }}
        ref={(ref) => (this.searchWrapper = ref)}
      >
        <div
          className={`search-input deploy${
            this.state.showSearch === true ? " in" : ""
          }`}
        >
          <input
            ref={(elem) => (this.inputElement = elem)}
            type="text"
            name="search-search"
            placeholder={this.props.placeholder}
            className="form-control"
            onChange={this.search}
            value={this.props.value ?? this.state.queryString}
          />
          <span className="icon-search" onClick={this.toggleSearch}></span>
        </div>
      </div>
    );
  }
}

Search.defaultProps = {
  visible: true,
  timeout: 0,
  queryString: "",
  placeholder: "Search...",
};

Search.propTypes = {
  visible: PropTypes.bool,
  reset: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  queryString: PropTypes.string,
  advancedSearch: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};
