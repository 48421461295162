import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
export default function Menu(props) {
  const user = props.user.data;
  const activeScreen = props.page.params?.["*"].split("/")[0];
  if (activeScreen === "judge" || activeScreen === "events") {
    return null;
  }
  return (
    <nav className="nav-primary vertical-center">
      <ul className="nav" role="nav">
        <li
          id="events-menu-item"
          className={props.selected === "events" ? "selected" : ""}
        >
          <Link
            className="header-link"
            onClick={(e) => {
              if (props.page.hasChanges) {
                e.preventDefault();
                props.changePage({
                  title: "events",
                  pathname: "/administrator/events",
                  params: {
                    "*": "administrator/events",
                  },
                  navigate: props.navigate,
                });
              }
            }}
            to={`/administrator/events`}
          >
            Events
          </Link>
        </li>

        {!!user.orgAdmin && (
          <li
            id="crm-menu-item"
            className={props.selected === "crm" ? "selected" : ""}
          >
            <Link
              className="header-link"
              onClick={(e) => {
                if (props.page.hasChanges) {
                  e.preventDefault();
                  props.changePage({
                    title: "crm",
                    pathname: "/administrator/crm",
                    params: {
                      "*": "administrator/crm",
                    },
                    navigate: props.navigate,
                  });
                }
              }}
              to={`/administrator/crm`}
            >
              CRM
            </Link>
          </li>
        )}
        {!!user.orgAdmin && (
          <li
            id="hotels-menu-item"
            className={props.selected === "hotels" ? "selected" : ""}
          >
            <Link
              className="header-link"
              onClick={(e) => {
                if (props.page.hasChanges) {
                  e.preventDefault();
                  props.changePage({
                    title: "hotels",
                    pathname: "/administrator/hotels",
                    params: {
                      "*": "administrator/hotels",
                    },
                    navigate: props.navigate,
                  });
                }
              }}
              to={`/administrator/hotels`}
            >
              Hotels
            </Link>
          </li>
        )}
        {!!user.orgAdmin && (
          <li
            id="sponsors-menu-item"
            className={props.selected === "sponsors" ? "selected" : ""}
          >
            <Link
              className="header-link"
              onClick={(e) => {
                if (props.page.hasChanges) {
                  e.preventDefault();
                  props.changePage({
                    title: "sponsors",
                    pathname: "/administrator/sponsors",
                    params: {
                      "*": "administrator/sponsors",
                    },
                    navigate: props.navigate,
                  });
                }
              }}
              to={`/administrator/sponsors`}
            >
              Sponsors
            </Link>
          </li>
        )}
        {!!user.orgAdmin && (
          <li
            id="staff-menu-item"
            className={props.selected === "staff" ? "selected" : ""}
          >
            <Link
              className="header-link"
              onClick={(e) => {
                if (props.page.hasChanges) {
                  e.preventDefault();
                  props.changePage({
                    title: "staff",
                    pathname: "/administrator/staff",
                    params: {
                      "*": "administrator/staff",
                    },
                    navigate: props.navigate,
                  });
                }
              }}
              to={`/administrator/staff`}
            >
              Staff
            </Link>
          </li>
        )}
        {!!user.orgAdmin && (
          <li
            id="bank-menu-item"
            className={props.selected === "bank-accounts" ? "selected" : ""}
          >
            <Link
              className="header-link"
              onClick={(e) => {
                if (props.page.hasChanges) {
                  e.preventDefault();
                  props.changePage({
                    title: "bank-accounts",
                    pathname: "/administrator/bank-accounts",
                    params: {
                      "*": "administrator/bank-accounts",
                    },
                    navigate: props.navigate,
                  });
                }
              }}
              to={`/administrator/bank-accounts`}
            >
              Bank Accounts
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}

Menu.propTypes = {
  smallScreen: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  navigate: PropTypes.func,
  user: PropTypes.object.isRequired,
  selected: PropTypes.string,
  page: PropTypes.object,
};
