import React from "react";
import PropTypes from "prop-types";
import SubmitContainer from "@layout/SubmitContainer";
import { connect } from "react-redux";
import { updateClientPanelSettings } from "@actions";
import Switch from "@layout/Switch";

const Exhibition = (props) => {
  const [viewExhibition, setViewExhibition] = React.useState(
    props.event.clientPanelSettings.exhibition.showExhibition
  );

  return (
    <div className="panel-content-card footer-content exhibition-panel">
      <div className="switch-holder">
        <Switch
          isActive={viewExhibition}
          isLive={true}
          onChange={() => setViewExhibition(!viewExhibition)}
        />
        View Exhibition Hall
      </div>
      <SubmitContainer
        fetching={props.fetching}
        onSubmit={() => {
          props.updateClientPanelSettings(
            props.event.id,
            {
              exhibitionShowExhibition:
                !props.event.clientPanelSettings.exhibition.showExhibition,
            },
            "exhibition"
          );
        }}
        onCancel={() => {
          setViewExhibition(
            props.event.clientPanelSettings.exhibition.showExhibition
          );
        }}
        disabled={
          props.fetching ||
          viewExhibition ===
            props.event.clientPanelSettings.exhibition.showExhibition
        }
        cancelDisabled={
          viewExhibition ===
          props.event.clientPanelSettings.exhibition.showExhibition
        }
        submitText="Save Changes"
      />
    </div>
  );
};

Exhibition.propTypes = {
  event: PropTypes.object.isRequired,
  updateClientPanelSettings: PropTypes.func,
  fetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.events.edit.data,
    fetching: state.api.events.edit.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientPanelSettings: (
      eventId,
      data,
      section,
      sectionId,
      preserveModal
    ) => {
      return dispatch(
        updateClientPanelSettings(
          eventId,
          data,
          section,
          sectionId,
          preserveModal
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exhibition);
