import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Block from "../PolicyGroups/Block";
import { loadPolicies } from "@actions";
import { addNotification } from "@actions";
import { addGroup, deleteGroup } from "@actions/policyGroups";
import SubmitContainer from "@layout/SubmitContainer";
import { createPolicyExtraSettingCondition } from "../../../../../../actions";

class CreateCondition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionType: "OR",
      nameValue: props.group ? props.group.name : "",
      errors: [],
    };
  }

  componentDidMount() {
    if (this.props.group?.filters) {
      for (let i = 0; i < this.props.group?.filters?.length; i++) {
        this.props.addGroup(this.props.group.filters[i]);
      }
    } else if (this.props.group) {
      for (let i = 0; i < this.props.group?.length; i++) {
        this.props.addGroup(this.props.group[i].conditions);
      }
    } else {
      this.addGroup();
    }
  }
  validateForm() {
    const errors = [];
    if (this.state.nameValue.length === 0) {
      errors.push("name");
    }
    return errors;
  }
  addGroup() {
    const check = this.validateGroup();
    const errors = [...this.state.errors];
    if (check.length === 0) {
      errors.splice(errors.indexOf("group"), 1);
      this.setState(errors);
      return this.props.addGroup();
    }
    return this.setState({ errors: errors.concat(check) });
  }
  componentWillUnmount() {
    this.props.deleteGroup("all");
  }
  validateGroup() {
    const errors = [];
    const errorIndexes = [];
    const emptyGroups = this.props.groups.filter((g, index) => {
      let isOK = true;
      for (const condition of g.conditions) {
        if (
          Object.keys(condition).length <= 1 ||
          !condition.hasOwnProperty("value") ||
          !condition.key
        ) {
          errorIndexes.push(index);
          isOK &&= false;
        }
        isOK &&= true;
      }
      return !isOK;
    });
    if (emptyGroups.length !== 0) {
      for (const i in errorIndexes) {
        errors.push("group" + errorIndexes[i]);
      }
    }
    return errors;
  }

  createFieldCondition() {
    const errors = this.validateGroup();
    if (errors.length !== 0) {
      return this.setState({ errors });
    }
    const update = [];
    if (this.props.group) {
      update.push(this.props.group.id, true);
    }
    this.props.onSubmit(this.props.groups);
    this.props.hideModal();
  }

  render() {
    const { errors } = this.state;
    const groups = JSON.parse(JSON.stringify(this.props.groups));
    return (
      <div className="form-container policy-group-container">
        <h2>{`${this.props.edit ? "Edit Condition" : "Create Condition"}`}</h2>
        <p className="subtitle">
          {`Field '${this.props.item.name}' will be visible when:`}
        </p>
        <>
          {groups.map((group, index) => {
            return (
              <div key={group.id || index}>
                {index > 0 && <p style={{ textAlign: "center" }}>OR</p>}
                <div
                  className={`form-group condition-block ${
                    errors.indexOf("group" + index) > -1 ? " has-error" : ""
                  }`}
                >
                  <Block
                    operators={{
                      "=": "=",
                      "<>": "is not",
                    }}
                    isRemovable={true}
                    groupIndex={index}
                    policy={this.props.policy}
                    {...this.props}
                    group={group}
                  />

                  {errors.indexOf("group" + index) > -1 && (
                    <div className="help-block">
                      Please add one or more conditions with a valid field-value
                      combination.
                    </div>
                  )}
                </div>
                {index === this.props.groups.length - 1 && (
                  <p className="faded" style={{ textAlign: "center" }}>
                    OR
                  </p>
                )}
              </div>
            );
          })}
        </>
        <div className="form-group">
          <button
            className="new-condition btn btn-white"
            onClick={() => this.addGroup()}
          >
            <span className="icon-add-1"></span>
            New Condition Block
          </button>
        </div>
        <SubmitContainer
          onCancel={() => this.props.hideModal()}
          onSubmit={() => this.createFieldCondition()}
          submitText="Apply"
        />
      </div>
    );
  }
}
CreateCondition.propTypes = {
  eventId: PropTypes.number,
  groups: PropTypes.array,
  group: PropTypes.object,
  policy: PropTypes.object.isRequired,
  changeTab: PropTypes.func,
  addGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  hideModal: PropTypes.func,
  createCondition: PropTypes.func,
  item: PropTypes.object,
  excludeKeys: PropTypes.array,
  onSubmit: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    accessPolicies: state.api.accesspolicies.list.data,
    groups: state.api.policyGroups.data,
    policy: state.api.accesspolicies.selectedPolicy,
  };
};
const mapDispatchToProps = (dispatch) => {
  const dispatcher = {
    loadPolicies: (eventId) => {
      return dispatch(loadPolicies(eventId));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    addGroup: (conditions) => {
      dispatch(addGroup(conditions));
    },
    deleteGroup: (index) => {
      dispatch(deleteGroup(index));
    },
    createCondition: (
      eventId,
      policyId,
      item,
      groups,
      name,
      groupId,
      update
    ) => {
      return dispatch(
        createPolicyExtraSettingCondition(
          eventId,
          policyId,
          item,
          groups,
          name,
          groupId,
          update
        )
      );
    },
  };
  return dispatcher;
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCondition);
