import React from "react";
import EditHeader from "./partials/EditHeader";
import EditMenu from "./partials/EditMenu";
import PropTypes from "prop-types";

export default class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    /**
     * if you pass a
     * component it will
     * override the normal
     * menu
     */
  }

  componentDidMount() {
    const { eventId, loadEvent } = this.props;
    loadEvent(eventId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.eventId !== this.props.eventId) {
      const { eventId, loadEvent } = nextProps;
      loadEvent(eventId);
    }
  }

  componentWillUnmount() {
    const { eventId, changeEvent } = this.props;
    changeEvent(eventId);
  }

  render() {
    return (
      <div className="event-edit">
        <EditHeader
          event={this.props.data}
          fetching={this.props.fetching}
          user={this.props.user}
        />
        <EditMenu
          activeTab={this.props.activeTab}
          event={this.props.data}
          override={this.props.override}
        />
      </div>
    );
  }
}

EditEvent.propTypes = {
  eventId: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  loadEvent: PropTypes.func.isRequired,
  changeEvent: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  override: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

EditEvent.defaultProps = {
  override: false,
};
